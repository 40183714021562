import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateQuestion,
  GetQuestionsList,
  StatusUpdateQuestion,
  UpdateQuestion,
} from "api/user-config/ConsultantConfigAPI";
import { get } from "lodash";

const initialState = {
  list: [],
  loading: false,
};

export const getQuestionsList = createAsyncThunk(
  "QAndA/getQuestionsList",
  async (data) => {
    return await GetQuestionsList(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const createQuestion = createAsyncThunk(
  "QAndA/createQuestion",
  async (data) => {
    return await CreateQuestion(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const updateQuestion = createAsyncThunk(
  "QAndA/updateQuestion",
  async (data) => {
    return await UpdateQuestion(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const statusUpdateQuestion = createAsyncThunk(
  "QAndA/statusUpdateQuestion",
  async (data) => {
    return await StatusUpdateQuestion(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const QAndASlice = createSlice({
  name: "QAndA",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionsList.fulfilled, (state, action) => {
        state.list = get(action.payload, "status", false)
          ? action.payload.data
          : [];
        state.loading = false;
      })
      .addCase(getQuestionsList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(createQuestion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createQuestion.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuestion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateQuestion.rejected, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusUpdateQuestion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateQuestion.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default QAndASlice.reducer;
