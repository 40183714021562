import { Formik } from 'formik';
import { filter, get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import MDButton from 'components/md-components/button/MDButton';
import MDDialog from 'components/md-components/dialog/MDDialog';
import QuestionDetail from 'components/pages/cms/user-config/consultant-config/component/QuestionDetail';

const SelectQuestion = ({ fieldValue, setFieldValue, open, closeModal }) => {
	const questionsList = useSelector((state) => state.QAndA.list);

	return (
		<MDDialog title='Add Question' open={open} close={closeModal} maxWidth='md' fullWidth={true}>
			<Formik
				initialValues={{
					questionId: {},
				}}
				onSubmit={(values, actions) => {
					const questionExist = filter(fieldValue, (item) => {
						return get(item, 'questionId._id', '') === get(values, 'questionId._id', '');
					})[0];

					if (!isEmpty(get(values, 'questionId', {}))) {
						if (!isEmpty(questionExist)) {
							actions.setFieldError('questionId', 'This question already added.');
						} else {
							setFieldValue('questions', fieldValue.concat(values));
							closeModal();
						}
					} else {
						actions.setFieldError('questionId', 'Please select question');
					}
				}}
			>
				{(props) => (
					<form onSubmit={props.handleSubmit} className='d-flex flex-column overflow-x-auto'>
						<DialogContent dividers={true}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Box className='mb-2'>
										<Autocomplete
											fullWidth={true}
											autoHighlight={true}
											disableClearable={true}
											size='small'
											id='questionId'
											options={!isEmpty(questionsList) ? questionsList : []}
											getOptionLabel={(option) => option.questionTitle ?? ''}
											isOptionEqualToValue={(option, value) =>
												option.questionTitle === value.questionTitle
											}
											name='questionId'
											value={props.values.questionId}
											onChange={(_event, value) => {
												props.setFieldValue('questionId', value);
											}}
											onBlur={props.handleBlur}
											renderInput={(params) => (
												<TextField
													{...params}
													label='Questions'
													error={
														props.errors.questionId && props.touched.questionId
															? true
															: false
													}
													helperText={
														props.errors.questionId && props.touched.questionId
															? props.errors.questionId
															: null
													}
													inputProps={params.inputProps}
												/>
											)}
										/>
									</Box>
								</Grid>
								{!isEmpty(props.values.questionId) ? (
									<Grid item xs={12}>
										<Box className='px-2'>
											<QuestionDetail
												questionIndex={get(props, 'values.questionId._id', '')}
												questionData={get(props, 'values.questionId', {})}
											/>
										</Box>
									</Grid>
								) : null}
							</Grid>
						</DialogContent>
						<DialogActions>
							<MDButton variant='outlined' onClick={closeModal} className='me-2'>
								Close
							</MDButton>
							<MDButton type='submit' variant='contained'>
								Add
							</MDButton>
						</DialogActions>
					</form>
				)}
			</Formik>
		</MDDialog>
	);
};

export default SelectQuestion;
