import React, { useEffect, useState } from "react";

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const TimeOutComponent = (props) => {
  const { delay, isEmpty, children, ...rest } = props;

  const [show, setShow] = useState(true);

  useEffect(() => {
    const dt = delay ?? 1;
    const timer = setTimeout(() => {
      setShow(false);
    }, dt * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return show ? (
    <Box {...rest}>
      <LinearProgress />
    </Box>
  ) : isEmpty ? (
    <Box px={1} {...rest}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar
          sx={{
            width: "26px",
            height: "26px",
            backgroundColor: ({ palette }) => alpha(palette.info.main, 0.15),
            border: ({ palette }) => `1px solid ${palette.info.main}`,
          }}
        >
          <ErrorOutlinedIcon color="info" fontSize="small" />
        </Avatar>
        <Typography variant="h6" fontWeight={600} color="info.main">
          No Data Found
        </Typography>
      </Stack>
    </Box>
  ) : (
    <Box {...rest}>{children}</Box>
  );
};

export default TimeOutComponent;
