import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	StatusUpdateConsultantUser,
	DeleteConsultantUser,
	GetVerifiedConsultantUsers,
	GetNonVerifiedConsultantUsers,
	GetVerifiedConsultantUserDetail,
	GetNonVerifiedConsultantUserDetail,
	UpdateConsultantUserVerification,
	GetConsultantUserDocumentVerification,
	OfflineConsultantUserVerification,
	GetAllConsultantUsers,
} from 'api/user-management/UserManagementAPI';
import { get } from 'lodash';

const initialState = {
	allUsers: [],
	verifiedUsers: [],
	noneVerifiedUsers: [],
	verifiedUserDetail: [],
	noneVerifiedUserDetail: [],
	offlineUsersDocVerification: [],
	loading: false,
	verifyLoading: false,
};

export const getVerifiedConsultantUsers = createAsyncThunk('consultantUser/getVerifiedConsultantUsers', async () => {
	return await GetVerifiedConsultantUsers()
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const getAllConsultantUsers = createAsyncThunk('consultantUser/getAllConsultantUsers', async () => {
	return await GetAllConsultantUsers()
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const getNonVerifiedConsultantUsers = createAsyncThunk('consultantUser/getNonVerifiedConsultantUsers', async () => {
	return await GetNonVerifiedConsultantUsers()
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const getConsultantUserDocumentVerification = createAsyncThunk(
	'consultantUser/getConsultantUserDocumentVerification',
	async () => {
		return await GetConsultantUserDocumentVerification()
			.then((result) => {
				if (get(result, 'data.status', false)) {
					return get(result, 'data', []);
				}
			})
			.catch((err) => {
				return err;
			});
	}
);
export const getVerifiedConsultantUserDetail = createAsyncThunk(
	'consultantUser/getVerifiedConsultantUserDetail',
	async (data) => {
		return await GetVerifiedConsultantUserDetail(data)
			.then((result) => {
				if (get(result, 'data.status', false)) {
					return get(result, 'data', []);
				}
			})
			.catch((err) => {
				return err;
			});
	}
);
export const getNonVerifiedConsultantUserDetail = createAsyncThunk(
	'consultantUser/getNonVerifiedConsultantUserDetail',
	async (data) => {
		return await GetNonVerifiedConsultantUserDetail(data)
			.then((result) => {
				if (get(result, 'data.status', false)) {
					return get(result, 'data', []);
				}
			})
			.catch((err) => {
				return err;
			});
	}
);
export const statusUpdateConsultantUser = createAsyncThunk('consultantUser/statusUpdateConsultantUser', async (data) => {
	return await StatusUpdateConsultantUser(data)
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const deleteConsultantUser = createAsyncThunk('consultantUser/deleteConsultantUser', async (data) => {
	return await DeleteConsultantUser(data)
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const updateConsultantUserVerification = createAsyncThunk(
	'consultantUser/updateConsultantUserVerification',
	async (data) => {
		return await UpdateConsultantUserVerification(data)
			.then((result) => {
				if (get(result, 'data.status', false)) {
					return get(result, 'data', []);
				}
			})
			.catch((err) => {
				return err;
			});
	}
);

export const offlineConsultantUserVerification = createAsyncThunk(
	'consultantUser/offlineConsultantUserVerification',
	async (data) => {
		console.log('data :>> ', data);
		return await OfflineConsultantUserVerification(data)
			.then((result) => {
				if (get(result, 'data.status', false)) {
					return get(result, 'data', []);
				}
			})
			.catch((err) => {
				return err;
			});
	}
);

const ConsultantUser = createSlice({
	name: 'consultantUser',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getVerifiedConsultantUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(getVerifiedConsultantUsers.fulfilled, (state, action) => {
				state.verifiedUsers = get(action.payload, 'status', false) ? action.payload.data : [];
				state.loading = false;
			})
			.addCase(getVerifiedConsultantUsers.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getNonVerifiedConsultantUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(getNonVerifiedConsultantUsers.fulfilled, (state, action) => {
				state.noneVerifiedUsers = get(action.payload, 'status', false) ? action.payload.data : [];
				state.loading = false;
			})
			.addCase(getNonVerifiedConsultantUsers.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getAllConsultantUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllConsultantUsers.fulfilled, (state, action) => {
				state.allUsers = get(action.payload, 'status', false) ? action.payload.data : [];
				state.loading = false;
			})
			.addCase(getAllConsultantUsers.rejected, (state) => {
				state.loading = false;
			})

			.addCase(getConsultantUserDocumentVerification.pending, (state) => {
				state.loading = true;
			})
			.addCase(getConsultantUserDocumentVerification.fulfilled, (state, action) => {
				state.offlineUsersDocVerification = get(action.payload, 'status', false) ? action.payload.data : [];
				state.loading = false;
			})
			.addCase(getConsultantUserDocumentVerification.rejected, (state) => {
				state.loading = false;
			})

			.addCase(getVerifiedConsultantUserDetail.pending, (state) => {
				state.verifiedUserDetail = [];
				state.loading = true;
			})
			.addCase(getVerifiedConsultantUserDetail.fulfilled, (state, action) => {
				state.verifiedUserDetail = get(action.payload, 'status', false) ? action.payload.data : [];
				state.loading = false;
			})
			.addCase(getVerifiedConsultantUserDetail.rejected, (state) => {
				state.loading = false;
			})
			.addCase(getNonVerifiedConsultantUserDetail.pending, (state) => {
				state.noneVerifiedUserDetail = [];
				state.loading = true;
			})
			.addCase(getNonVerifiedConsultantUserDetail.fulfilled, (state, action) => {
				state.noneVerifiedUserDetail = get(action.payload, 'status', false) ? action.payload.data : [];
				state.loading = false;
			})
			.addCase(getNonVerifiedConsultantUserDetail.rejected, (state) => {
				state.loading = false;
			})
			.addCase(statusUpdateConsultantUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(statusUpdateConsultantUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(statusUpdateConsultantUser.rejected, (state) => {
				state.loading = false;
			})
			.addCase(deleteConsultantUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(deleteConsultantUser.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deleteConsultantUser.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateConsultantUserVerification.pending, (state) => {
				state.verifyLoading = true;
			})
			.addCase(updateConsultantUserVerification.fulfilled, (state) => {
				state.verifyLoading = false;
			})
			.addCase(updateConsultantUserVerification.rejected, (state) => {
				state.verifyLoading = false;
			})
			.addCase(offlineConsultantUserVerification.pending, (state) => {
				state.verifyLoading = true;
			})
			.addCase(offlineConsultantUserVerification.fulfilled, (state) => {
				state.verifyLoading = false;
			})
			.addCase(offlineConsultantUserVerification.rejected, (state) => {
				state.verifyLoading = false;
			});
	},
});

export default ConsultantUser.reducer;
