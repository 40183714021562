import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  StatusUpdateCountryCity,
  UpdateCountryCity,
  DeleteCountryCity,
} from "api/country-profile/CountryProfileAPI";

const initialState = {
  loading: false,
};

export const updateCountryCity = createAsyncThunk(
  "countryCities/updateCountryCity",
  async (data) => {
    return await UpdateCountryCity(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error.response;
      });
  }
);
export const statusUpdateCountryCity = createAsyncThunk(
  "countryCities/statusUpdateCountryCity",
  async (data) => {
    return await StatusUpdateCountryCity(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error.response;
      });
  }
);
export const deleteCountryCity = createAsyncThunk(
  "countryCities/deleteCountryCity",
  async (data) => {
    return await DeleteCountryCity(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error.response;
      });
  }
);

export const CountryCitiesSlice = createSlice({
  name: "countryCities",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateCountryCity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCountryCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCountryCity.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateCountryCity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusUpdateCountryCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateCountryCity.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCountryCity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCountryCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCountryCity.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default CountryCitiesSlice.reducer;
