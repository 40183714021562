import React from "react";
import { styled } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";

const MuiAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const MDAccordionDetails = (props) => {
  const { children, ...rest } = props;
  return <MuiAccordionDetails {...rest}>{children}</MuiAccordionDetails>;
};

export default MDAccordionDetails;
