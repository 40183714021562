import AxiosDefault from "services/AxiosDefaultSetting";

export const GetCountryProfiles = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/country-profile/list`,
  });
  return response;
};

export const CreateCountryProfile = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/country-profile/create`,
    data: data,
  });
  return response;
};

export const UpdateCountryProfile = async ({ data, id }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/update/${id}`,
    data: data,
  });
  return response;
};

export const StatusUpdateCountryProfile = async ({ id, status }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/status-update/${id}`,
    data: { status: status },
  });
  return response;
};

export const DeleteCountryProfile = async ({ isDeleted, id }) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/country-profile/delete/${id}`,
    data: { isDeleted: isDeleted },
  });
  return response;
};

export const GetIdentityProofList = async ({ countryId }) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/country-profile/identity-proof/get?countryId=${countryId}`,
  });
  return response;
};

export const CreateIdentityProof = async ({ countryProfileId, data }) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/country-profile/identity-proof/create?countryId=${countryProfileId}`,
    data: data,
  });
  return response;
};

export const UpdateIdentityProof = async ({
  countryProfileId,
  identityProofId,
  data,
}) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/identity-proof/update?countryId=${countryProfileId}&identityId=${identityProofId}`,
    data: data,
  });
  return response;
};

export const StatusUpdateIdentityProof = async ({ countryProfileId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/identity-proof-list/status-update/${countryProfileId}`,
    data: data,
  });
  return response;
};

export const DeleteIdentityProof = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/identity-proof-list/delete/${id}`,
    data: data,
  });
  return response;
};

export const GetPolicies = async ({ countryId }) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/country-profile/policies/get?countryId=${countryId}`,
  });
  return response;
};

export const CreatePolicies = async ({ countryProfileId, data }) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/country-profile/policies/create?countryId=${countryProfileId}`,
    data: data,
  });
  return response;
};

export const UpdatePolicies = async ({ countryProfileId, policyId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/policies/update?countryId=${countryProfileId}&policyId=${policyId}`,
    data: data,
  });
  return response;
};

export const StatusUpdatePolicies = async ({ countryProfileId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/policies/status-update/${countryProfileId}`,
    data: data,
  });
  return response;
};

export const DeletePolicies = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/country-profile/policies/delete/${id}`,
    data: data,
  });
  return response;
};

export const GetCountries = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/countries`,
  });
  return response;
};

export const GetLanguages = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/countries/languages`,
  });
  return response;
};

export const GetTimezones = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/countries/timezone`,
  });
  return response;
};

export const UpdateCountryState = async ({ countryId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/countries/state-name-update/${countryId}`,
    data: data,
  });
  return response;
};
export const StatusUpdateCountryState = async ({ countryId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/countries/state-status-update/${countryId}`,
    data: data,
  });
  return response;
};
export const DeleteCountryState = async ({ countryId, data }) => {
  const response = await AxiosDefault({
    method: "Delete",
    url: `/admin/countries/state-delete/${countryId}`,
    data: data,
  });
  return response;
};

export const UpdateCountryCity = async ({ countryId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/countries/cities-name-update/${countryId}`,
    data: data,
  });
  return response;
};
export const StatusUpdateCountryCity = async ({ countryId, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/countries/cities-status-update/${countryId}`,
    data: data,
  });
  return response;
};
export const DeleteCountryCity = async ({ countryId, data }) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/countries/cities-delete/${countryId}`,
    data: data,
  });
  return response;
};
