export const COPYRIGHT_OWN = [
	'Yes',
	'No - someone else does, but they have authorized me to publish it',
	'No - this file does not have restrictions/copyright, so I am free to upload it',
	//   "No - this file does not have restrictions/copyright, so I am free to upload it",
];

export const Best_suitedArrList = [
	'Everyone',
	'Complete beginner',
	'Some prior experience is necessary (2 months or more)',
	'Extensive experience necessary (12 months or more)',
];

export const BEST_SUITED_EXTENSIVEEXPERIENCENECESSARY = ['Young Adult', 'Teenagers', 'Young children', 'Infants'];

export const ROLE_PERMISSIONS = [
	{
		id: 1,
		name: 'Country Profile',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 2,
		name: 'Language Config',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 3,
		name: 'SignUp Config',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 4,
		name: 'Pre Consultation Q&A',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 5,
		name: 'End Users',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 6,
		name: 'Consultant User',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 7,
		name: 'Consultant Verification',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 8,
		name: 'Consultant Document Verification',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 9,
		name: 'Business/Corporate User',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 10,
		name: 'Business/Corporate Verification',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 11,
		name: 'Admin Users List',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 12,
		name: 'Admin Roles List',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 13,
		name: 'Booking',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 14,
		name: 'Article / Blog',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 15,
		name: 'Press Release',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 16,
		name: 'Content',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
	{
		id: 17,
		name: 'Quotes-Management',
		read: false,
		write: false,
		update: false,
		delete: false,
	},
];
