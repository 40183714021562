import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ArchiveMeal,
  CreateUpdateMeal,
  DeleteMeal,
  GetMealDetails,
  GetMealList,
} from "api/health-food/MealAPIs";
import { get } from "lodash";

const initialState = {
  mealList: [],
  mealDetails: [],
  loading: false,
};

export const getMealList = createAsyncThunk(
  "healthMeal/getMealList",
  async (data) => {
    try {
      const response = await GetMealList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getMealDetails = createAsyncThunk(
  "healthMeal/getMealDetails",
  async (data) => {
    try {
      const response = await GetMealDetails(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const createUpdateMeal = createAsyncThunk(
  "healthMeal/createUpdateMeal",
  async (data) => {
    try {
      const response = await CreateUpdateMeal(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const archiveMeal = createAsyncThunk(
  "healthMeal/archiveMeal",
  async (data) => {
    try {
      const response = await ArchiveMeal(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const deleteMeal = createAsyncThunk(
  "healthMeal/deleteMeal",
  async (data) => {
    try {
      const response = await DeleteMeal(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const MealSlice = createSlice({
  name: "healthMeal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMealList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMealList.fulfilled, (state, { payload }) => {
        state.mealList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getMealList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMealDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMealDetails.fulfilled, (state, { payload }) => {
        state.mealDetails = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getMealDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateMeal.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUpdateMeal.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateMeal.rejected, (state) => {
        state.loading = false;
      })
      .addCase(archiveMeal.pending, (state) => {
        state.loading = true;
      })
      .addCase(archiveMeal.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(archiveMeal.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteMeal.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMeal.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteMeal.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default MealSlice.reducer;
