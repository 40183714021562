import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateQuestionTemplate,
  GetQuestionTemplateList,
  StatusUpdateQuestionTemplate,
  UpdateQuestionTemplate,
} from "api/user-config/ConsultantConfigAPI";
import { get } from "lodash";

const initialState = {
  list: [],
  loading: false,
};

export const getQuestionsTemplateList = createAsyncThunk(
  "QAndATemplate/getQuestionsTemplateList",
  async (data) => {
    return await GetQuestionTemplateList(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const createQuestionTemplate = createAsyncThunk(
  "QAndATemplate/createQuestionTemplate",
  async (data) => {
    return await CreateQuestionTemplate(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const updateQuestionTemplate = createAsyncThunk(
  "QAndATemplate/updateQuestionTemplate",
  async (data) => {
    return await UpdateQuestionTemplate(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const statusUpdateQuestionTemplate = createAsyncThunk(
  "QAndATemplate/statusUpdateQuestionTemplate",
  async (data) => {
    return await StatusUpdateQuestionTemplate(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const QAndATemplateSlice = createSlice({
  name: "QAndATemplate",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsTemplateList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionsTemplateList.fulfilled, (state, action) => {
        state.list = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getQuestionsTemplateList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createQuestionTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(createQuestionTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createQuestionTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateQuestionTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuestionTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateQuestionTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateQuestionTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusUpdateQuestionTemplate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateQuestionTemplate.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default QAndATemplateSlice.reducer;
