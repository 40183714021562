import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DetailWrapper, { DetailTitle, DetailValue } from 'components/common/DetailWrapper';
import MDCard from 'components/md-components/card/MDCard';
import DetailCardSkeleton from 'components/md-components/skeleton/DetailCardSkeleton';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const getCurrentModeString = (currentMode) => {
	if (currentMode === 100) {
		return 'Very Happy';
	}
	if (currentMode === 75) {
		return 'Happy';
	}
	if (currentMode === 50) {
		return 'Neutral';
	}
	if (currentMode === 25) {
		return 'Sad';
	}
	return 'Very Sad or Stressed';
};

const EndUserAboutMe = (props) => {
	const { t } = useTranslation();
	const { userDetails } = props;
	const isLoading = useSelector((state) => state.endUser.loading);

	if (isLoading) {
		return <DetailCardSkeleton />;
	}

	return (
		<MDCard>
			<Box className='detail-box-header'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
					<Typography variant='body1' className='detail-box-heading'>
						About Me
					</Typography>
				</Stack>
			</Box>
			<CardContent>
				<Box display='grid' gap='12px' gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}>
					<DetailWrapper>
						<DetailTitle title='Day Work Activity Level' colon={true} />
						<DetailValue>{userDetails?.workActivity}</DetailValue>
					</DetailWrapper>
					<DetailWrapper>
						<DetailTitle title='Leisure Time Activity Level' colon={true} />
						<DetailValue>{userDetails?.leisureTime}</DetailValue>
					</DetailWrapper>
					<DetailWrapper>
						<DetailTitle title='Specialization' colon={true} />
						<DetailValue>
							<Box display='flex' gap={1} flexWrap='wrap'>
								{userDetails?.specialization?.map((e) => (
									<Chip label={t(e.macroName)} size='small' />
								))}
							</Box>
						</DetailValue>
					</DetailWrapper>
					<DetailWrapper>
						<DetailTitle title='Goal' colon={true} />
						<DetailValue>
							<Box display='flex' gap={1} flexWrap='wrap'>
								{userDetails?.overallGoal?.map((e) => {
									if (!t(e.macroName)) {
										return null;
									}

									return <Chip label={t(e.macroName)} size='small' />;
								})}
							</Box>
						</DetailValue>
					</DetailWrapper>
					<DetailWrapper>
						<DetailTitle title='Current Mode' colon={true} />
						<DetailValue>
							<Box display='flex' gap={1} flexWrap='wrap'>
								{getCurrentModeString(userDetails?.currentMode)}
							</Box>
						</DetailValue>
					</DetailWrapper>
				</Box>
			</CardContent>
		</MDCard>
	);
};

export default EndUserAboutMe;
