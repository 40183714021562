import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';

import { updateApproveContent } from 'store/slice/content/ContentSlice';
import ContentDetail from 'components/pages/content/ContentDetail';

const ContentDetailView = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isNonApprove = get(location, 'state.isNonApprove', false);
	const isLoading = useSelector((state) => state.article.loading);

	const verifyStatus = [
		{
			value: 1,
			label: 'Approved',
		},
		{
			value: 2,
			label: 'Need To Update',
		},
	];

	const handleSubmit = (values) => {
		const apiData = {
			id: get(location, 'state.articleData._id'),
			data: { ...values },
		};
		dispatch(updateApproveContent(apiData))
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					toast.success(get(result, 'message', 'Content approve update successfully.'));
					navigate('/content', {
						state: {
							tabIndex: get(location, 'state.tabIndex', 0),
							apiType: get(location, 'state.apiType', 0),
						},
					});
				} else {
					toast.error(get(result, 'message', 'Something went wrong.'));
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<MDCard>
			<MDCardHeader title={`Content ${isNonApprove ? 'Approve' : 'Detail'} View`} />
			<CardContent>
				<Box px={1} pb={2}>
					<ContentDetail contentData={get(location, 'state.articleData')} />
					{isNonApprove ? (
						<>
							<Divider sx={{ mt: 3 }} />
							<Box my={3}>
								<Formik
									initialValues={{ isApprove: undefined }}
									validationSchema={Yup.object().shape({
										isApprove: Yup.number().required('Verify Status is required.'),
									})}
									onSubmit={(values) => {
										handleSubmit(values);
									}}
								>
									{(props) => (
										<form onSubmit={props.handleSubmit}>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={6}>
													<Box>
														<Autocomplete
															fullWidth={true}
															autoHighlight={true}
															size='small'
															id='isApprove'
															name='isApprove'
															value={props.values.isApprove}
															options={verifyStatus}
															getOptionLabel={(option) => option.label ?? option}
															isOptionEqualToValue={(option, value) => {
																if (
																	option?.value === value ||
																	value === undefined ||
																	option === ''
																) {
																	return true;
																}
															}}
															onChange={(_event, value) => {
																props.setFieldValue('isApprove', value.value);
															}}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label='Select Status'
																	error={
																		props.errors.isApprove && props.touched.isApprove
																			? true
																			: false
																	}
																	helperText={
																		props.errors.isApprove && props.touched.isApprove
																			? props.errors.isApprove
																			: null
																	}
																	onBlur={props.handleBlur}
																	inputProps={{
																		...params.inputProps,
																		autoComplete: 'verify-status',
																	}}
																/>
															)}
														/>
													</Box>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Box>
														<MDLoadingButton
															type='submit'
															label='Submit'
															variant='contained'
															fullWidth={true}
															loading={props.isSubmitting && isLoading}
														/>
													</Box>
												</Grid>
											</Grid>
										</form>
									)}
								</Formik>
							</Box>
						</>
					) : null}
				</Box>
			</CardContent>
		</MDCard>
	);
};

export default ContentDetailView;
