import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

export default function StatusMenu({ active, label, id, color, menu, onChange }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Chip
				aria-controls={open ? `basic-menu${id}` : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				label={label}
				color={color}
				size='small'
			/>
			<Menu id={`basic-menu${id}`} anchorEl={anchorEl} open={open} onClose={handleClose}>
				{menu?.map((item) => (
					<MenuItem
						selected={item.value === active}
						onClick={() => {
							onChange(item.value);
							handleClose();
						}}
					>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
