import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { toast } from "react-toastify";

import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import MDSwitch from "components/md-components/switch/MDSwitch";
import MDCard from "components/md-components/card/MDCard";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDButton from "components/md-components/button/MDButton";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCardHeader from "components/md-components/card/MDCardHeader";

import {
  getAdminUsers,
  statusUpdateAdminUser,
  deleteAdminUser,
} from "store/slice/role-management/AdminUserSlice";

const AdminUserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const usersList = useSelector((state) => state.adminUsers.list);

  const isLoading = useSelector((state) => state.adminUsers.loading);
  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    rowData: [],
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  useEffect(() => {
    dispatch(getAdminUsers());
  }, [dispatch]);

  const handleStatusUpdate = useCallback(
    (event, row) => {
      setDialogData({ ...dialogData, id: row?._id });
      const data = {
        id: row?._id,
        status: event.target.checked,
      };
      dispatch(statusUpdateAdminUser(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            dispatch(getAdminUsers());
            toast.success(
              get(result, "message", "Status updated successfully.")
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dialogData, dispatch]
  );

  const handleDelete = useCallback(
    ({ event, id }) => {
      const data = {
        id: id,
        isDeleted: event,
      };
      dispatch(deleteAdminUser(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            dispatch(getAdminUsers());
            toast.success(get(result, "message", "Deleted successfully."));
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch]
  );

  const columnsData = useMemo(
    () => [
      {
        field: "userName",
        headerName: "User Name",
        width: 150,
        editable: false,
        valueGetter: ({ row }) =>
          `${row?.firstName || ""} ${row?.lastName || ""}`,
      },
      {
        field: "role",
        headerName: "Role",
        width: 150,
        editable: false,
        valueGetter: ({ row }) =>
        `${row?.role?.title || ""}`,
      },
      {
        field: "createdBy",
        headerName: "Created By",
        width: 150,
        editable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: ({ row }) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3 mx-auto">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate("/role-management/admin-user-create-update", {
                    state: { userData: row, formType: "update" },
                  });
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={row?.status}
                onChange={(event) => {
                  handleStatusUpdate(event, row);
                }}
                inputProps={{ "aria-label": row?._id, role: "switch" }}
                color="success"
                disabled={get(dialogData, "id", "") === row?._id && isLoading}
              />
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    title: "User Delete Confirmation",
                    id: row?._id,
                    rowData: row,
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      dialogData,
      handleDeleteConfirmation,
      handleStatusUpdate,
      isLoading,
      navigate,
    ]
  );

  const CustomToolbar = () => {
    return (
      <MDDataGridToolbar align="right" divider={true}>
        <MDButton
          variant="contained"
          label="Create New User"
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            navigate("/role-management/admin-user-create-update", {
              state: { formType: "create" },
            });
          }}
        />
      </MDDataGridToolbar>
    );
  };

  return (
    <MDCard>
      <MDCardHeader title="Admin User's List" />
      <CardContent className="h-100">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MDDataGrid
              rows={usersList ?? []}
              columns={columnsData}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
            <DeleteConfirmDialog
              title="Admin User Delete Confirmation"
              open={deleteConfirmation}
              handleClose={handleDeleteConfirmationClose}
              deleteAction={() => {
                handleDelete({ event: true, id: get(dialogData, "id", "") });
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default AdminUserList;
