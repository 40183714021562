import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, filter } from "lodash";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import MDCard from "components/md-components/card/MDCard";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDButton from "components/md-components/button/MDButton";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDSwitch from "components/md-components/switch/MDSwitch";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCardHeader from "components/md-components/card/MDCardHeader";

import {
  getCountryProfiles,
  countryProfileStatusUpdate,
  countryProfileDelete,
} from "store/slice/country-profile/CountryProfileSlice";

const CountriesProfileList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getProfileList = useSelector((state) => state.countryProfile.list);
  const ProfileList = filter(getProfileList, function (item) {
    return item.isDeleted !== true;
  });
  const isLoading = useSelector((state) => state.countryProfile.loading);
  const [rowID, setRowID] = useState("");
  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    rowData: [],
  });

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const updateStatus = useCallback(
    (event, params) => {
      setRowID(params.id);
      const data = { id: params.row._id, status: event.target.checked };
      dispatch(countryProfileStatusUpdate(data))
        .unwrap()
        .then((result) => {
          toast.success(get(result, "message", "Status update successful"));
          dispatch(getCountryProfiles());
        })
        .catch((err) => {
          toast.error(get(err, "message", "Something went wrong"));
          throw err;
        });
    },
    [dispatch]
  );
  const handleDelete = useCallback(
    ({ event, id }) => {
      const data = {
        id: id,
        isDeleted: event,
      };
      dispatch(countryProfileDelete(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getCountryProfiles());
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getCountryProfiles());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 240,
        editable: false,
        renderCell: (params) => {
          return (
            <p>{params.row?.country?.flag + ' ' + params.row.country.name}</p>
          )
        }
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 280,
        renderCell: (params) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label={`${params.row.name}-states`}
                onClick={() => {
                  navigate(`state-profile`, {
                    state: {
                      countryProfileId: params.row._id,
                    },
                  });
                  localStorage.setItem("countryProfileId", params.row._id);
                  localStorage.setItem("countryId", params.row.country._id);
                }}
              >
                <LocationOnOutlinedIcon color="warning" />
              </IconButton>
              <IconButton
                aria-label={`${params.row.name}-edit-button`}
                color="primary"
                onClick={() => {
                  navigate(`/country-profile/country-profile-create-update`, {
                    state: {
                      formType: "update",
                      countryProfile: params.row,
                    },
                  });
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={params.row.status}
                onChange={(event) => {
                  updateStatus(event, params);
                }}
                inputProps={{
                  "aria-label": `${params.row.name}-status`,
                  role: "switch",
                }}
                color="success"
                disabled={rowID === params.id && isLoading}
              />
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: params.row._id,
                    title: "Country Profile Delete Confirmation",
                    rowData: params.row,
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      dialogData,
      handleDeleteConfirmation,
      isLoading,
      navigate,
      rowID,
      updateStatus,
    ]
  );

  const CustomToolbar = () => {
    return (
      <MDDataGridToolbar align="right" divider={true}>
        <MDButton
          variant="contained"
          label="Create Country Profile"
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() =>
            navigate(`/country-profile/country-profile-create-update`, {
              state: {
                formType: "create",
              },
            })
          }
        />
      </MDDataGridToolbar>
    );
  };

  return (
    <Box>
      <MDCard>
        <MDCardHeader title="Country Profile" />
        <CardContent className="h-100">
          <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
              <MDDataGrid
                rows={!isEmpty(ProfileList) ? ProfileList : []}
                columns={columnsData}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: EmptyTableRowOverlay,
                  LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                autoHeight
              />
              <DeleteConfirmDialog
                title="Country Delete Confirmation"
                open={deleteConfirmation}
                handleClose={handleDeleteConfirmationClose}
                deleteAction={() => {
                  handleDelete({ event: true, id: get(dialogData, "id", "") });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </MDCard>
    </Box>
  );
};

export default CountriesProfileList;
