import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, map, omit } from "lodash";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import LanguageTranslationForm, {
  languageInitialValue,
} from "components/common/LanguageTranslationForm";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";

import TimeOutComponent from "components/common/TimeOutComponent";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createPolicies,
  updatePolicies,
} from "store/slice/country-profile/CountryPoliciesSlice";
import { getLanguageConfigList } from "store/slice/language-config/LanguageConfigSlice";

const CountryPoliciesForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const policyData = get(location, "state.policyData", {});
  const countryProfileId = get(location, "state.countryProfileId", "");
  const countryId = get(location, "state.countryId", "");
  const formType = get(location, "state.formType", "");

  const isLoading = useSelector((state) => state.countryPolicies.loading);
  const languageConfigList = useSelector(
    (state) => state.languageConfig.languageList
  );

  const policiesType = [
    { label: "Terms & Conditions", value: "TERMS_AND_CONDITIONS" },
    { label: "Data Policy", value: "DATA_POLICY" },
    { label: "Privacy Policy", value: "PRIVACY_POLICY" },
    {
      label: "Appointment Terms & Condition",
      value: "APPOINTMENT_TERMS_AND_CONDITIONS",
    },
  ];

  const userType = [
    { label: "System 1 (Front)", value: "system_1" },
    { label: "System 2 (Consultant)", value: "system_2" },
  ];

  const validationSchema = Yup.object().shape({
    policy: Yup.string().required("Policy is required"),
    policyLanguages: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Value is required"),
      })
    ),
    descriptionLanguages: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Value is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    let payloadData = {
      countryProfileId: countryProfileId,
      data: {
        ...values,
        policyLanguages: map(values?.policyLanguages, (policyLang) => {
          return { ...policyLang, type: get(values, "userType", "") };
        }),
        descriptionLanguages: map(
          values?.descriptionLanguages,
          (descriptionLang) => {
            return { ...descriptionLang, type: get(values, "userType", "") };
          }
        ),
      },
    };

    if (formType === "create") {
      dispatch(createPolicies(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "Policy create successfully"));
            navigate("/country-profile/policy-list", {
              state: {
                countryProfileId: countryProfileId,
                countryId: countryId,
              },
            });
          } else {
            toast.error("Something went wrong.");
          }
        })
        .catch((err) => {
          throw err;
        });
    } else if (formType === "update") {
      payloadData = {
        ...payloadData,
        policyId: get(policyData, "_id", ""),
        data: {
          ...omit(payloadData.data, "userType"),
          policyLanguages: map(payloadData?.data?.policyLanguages, (item) =>
            omit(item, "type")
          ),
          descriptionLanguages: map(
            payloadData?.data?.descriptionLanguages,
            (item) => omit(item, "type")
          ),
        },
      };
      console.log("payloadData", payloadData);
      dispatch(updatePolicies(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "Policy update successfully"));
            navigate("/country-profile/policy-list", {
              state: {
                countryProfileId: countryProfileId,
                countryId: countryId,
              },
            });
          } else {
            toast.error("Something went wrong.");
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    dispatch(getLanguageConfigList());
  }, [dispatch]);

  return (
    <MDCard>
      <MDCardHeader title="Policy Create Update Form" />
      <CardContent>
        <TimeOutComponent delay={2} isEmpty={isEmpty(languageConfigList)}>
          <Formik
            initialValues={{
              userType: get(policyData, "userType", ""),
              policy: get(policyData, "policy", ""),
              policyLanguages: languageInitialValue(
                languageConfigList,
                get(policyData, "policyLanguages", "")
              ),
              descriptionLanguages: languageInitialValue(
                languageConfigList,
                get(policyData, "descriptionLanguages", "")
              ),
              status: get(policyData, "status", false),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formProps) => (
              <form
                onSubmit={formProps.handleSubmit}
                className="d-flex flex-column overflow-auto"
              >
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            User Type :
                          </FormLabel>
                        </Box>
                        <TextField
                          fullWidth={true}
                          select={true}
                          size="small"
                          id="userType-type-select"
                          placeholder="Select User Type"
                          name="userType"
                          value={formProps.values.userType}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={
                            formProps.errors.userType &&
                            formProps.touched.userType
                              ? true
                              : false
                          }
                          helperText={
                            formProps.errors.userType &&
                            formProps.touched.userType
                              ? formProps.errors.userType
                              : null
                          }
                        >
                          {map(userType, (option, index) => (
                            <MenuItem key={index} value={option?.value}>
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            Policy Type :
                          </FormLabel>
                        </Box>
                        <TextField
                          fullWidth={true}
                          select={true}
                          size="small"
                          id="policy-type-select"
                          placeholder="Select Policy Type"
                          name="policy"
                          value={formProps.values.policy}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          error={
                            formProps.errors.policy && formProps.touched.policy
                              ? true
                              : false
                          }
                          helperText={
                            formProps.errors.policy && formProps.touched.policy
                              ? formProps.errors.policy
                              : null
                          }
                        >
                          {map(policiesType, (option) => (
                            <MenuItem key={option?.value} value={option?.value}>
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            Policy Multi Languages :
                          </FormLabel>
                        </Box>
                        <LanguageTranslationForm
                          fieldName="policyLanguages"
                          formProps={formProps}
                          languageConfigList={languageConfigList}
                          inputField="text-input"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider flexItem={true} className="my-2" />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            Description Multi Languages :
                          </FormLabel>
                        </Box>
                        <LanguageTranslationForm
                          fieldName="descriptionLanguages"
                          formProps={formProps}
                          languageConfigList={languageConfigList}
                          inputField="text-editor"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="status"
                                checked={formProps.values.status}
                                value={formProps.values.status}
                                onChange={(e) => {
                                  formProps.setFieldValue(
                                    "status",
                                    e.target.checked
                                  );
                                }}
                                inputProps={{
                                  "aria-label": "status",
                                }}
                              />
                            }
                            label="Status"
                            sx={{ mr: 0 }}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Stack
                          direction={{ sm: "row", xs: "column" }}
                          className="gap-3"
                        >
                          <MDLoadingButton
                            label="Cancel"
                            variant="outlined"
                            fullWidth={true}
                            onClick={() => {
                              navigate("/country-profile/policy-list");
                            }}
                          />
                          <MDLoadingButton
                            type="submit"
                            label="Submit"
                            variant="contained"
                            fullWidth={true}
                            loading={formProps.isSubmitting && isLoading}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </TimeOutComponent>
      </CardContent>
    </MDCard>
  );
};

export default CountryPoliciesForm;
