import { get } from "lodash";
import { useSelector } from "react-redux";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";
import { useState } from "react";
import Lightbox from "components/utility/lightbox";

const OfflineUserInformation = (props) => {
  const { userDetails, serviceTypeOffline } = props;
  const isLoading = useSelector((state) => state.consultantUser.loading);

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxData, setLightBoxData] = useState([]);
  const handleOpenLightBox = (src, alt) => {
    setLightBoxOpen(true);
    setLightBoxData([
      {
        src: src,
        alt: alt,
        caption: {},
      },
    ]);
  };
  const handleCloseLightBox = () => {
    setLightBoxOpen(false);
    setLightBoxData([]);
  };

  // console.log('userDetails :>> ', userDetails);

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            User Information
          </Typography>
        </Stack>
      </Box>
      <CardContent>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="flex-start"
          spacing={{ xs: 2, sm: 5 }}
        >
          <Box>
            <Avatar
              src={get(userDetails, "profilePictureURL", "")}
              alt={
                get(userDetails, "firstName", "") +
                get(userDetails, "lastName", "")
              }
              variant="rounded"
              onClick={() => {
                handleOpenLightBox(
                  get(userDetails, "profilePictureURL", ""),
                  get(userDetails, "firstName", "") +
                    get(userDetails, "lastName", "")
                );
              }}
              sx={{
                width: 96,
                height: 96,
                border: "2px solid",
                borderColor: "primary.100",
                cursor: "pointer",
              }}
            />
          </Box>
          <Box>
            <Stack
              direction={{ md: "row", xs: "column" }}
              flexWrap={{ md: "nowrap", xs: "wrap" }}
              sx={{ gap: { lg: "24px", xs: "8px" } }}
            >
              <Box>
                <Stack direction="column" spacing={1}>
                  <DetailWrapper>
                    <DetailTitle title="Full Name" colon={true} />
                    <DetailValue>
                      {get(userDetails, "firstName", "") +
                        " " +
                        get(userDetails, "lastName", "")}
                    </DetailValue>
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailTitle title="E-mail" colon={true} />
                    <DetailValue>{get(userDetails, "email", "")}</DetailValue>
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailTitle title="Mobile No." colon={true} />
                    <DetailValue>
                      {get(userDetails, "code", "") +
                        " " +
                        get(userDetails, "mobile", "")}
                    </DetailValue>
                  </DetailWrapper>
                </Stack>
              </Box>
              <Box>
                <DetailWrapper>
                  <DetailTitle title="Verification Status" colon={true} />
                  <DetailValue>
                    <Chip
                      label={serviceTypeOffline === false ? "New" : "Approved"}
                      icon={
                        serviceTypeOffline === false ? (
                          <NewReleasesOutlinedIcon fontSize="small" />
                        ) : (
                          <CheckCircleOutlineIcon fontSize="small" />
                        )
                      }
                      color={
                        serviceTypeOffline === false ? "primary" : "success"
                      }
                      size="small"
                    />
                  </DetailValue>
                </DetailWrapper>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Lightbox
          open={lightBoxOpen}
          handleClose={handleCloseLightBox}
          imageData={lightBoxData}
        />
      </CardContent>
    </MDCard>
  );
};

export default OfflineUserInformation;
