import React from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ErrorMessage, FieldArray, getIn } from 'formik';
import { get, size } from 'lodash';
import { useTheme } from '@emotion/react';
import translate from 'hooks/translate';
import CMToolTip from 'components/common/tooltip/CMToolTip';
import CMRadio from 'components/utility/form-components/CMRadio';
import RichTextEditor from 'components/utility/rich-text-editor/RichTextEditor';
import FilePicker from 'components/utility/file-picker/v2/FilePicker';
import FilePickerPreview from 'components/utility/file-picker/v2/component/FilePickerPreview';
import VideoPicker from 'components/utility/file-picker/v2/video-picker/VideoPicker';
import AudioPicker from 'components/utility/file-picker/v2/audio-picker/AudioPicker';
import PDFPicker from 'components/utility/file-picker/v2/pdf-picker/PDFPicker';

const ContentSection = (props) => {
	const { formProps, handleAudioUpload, handlePDFUpload } = props;

	const theme = useTheme();
	return (
		<Grid item xs={12} className='content-design-blog'>
			<Stack direction='row' className='gap-1'>
				<Typography variant='h4' className='mb-2'>
					{translate('consultant_content_section')}
				</Typography>

				<CMToolTip
					icon={<HelpTwoToneIcon size='small' />}
					title='Please ensure that your content is in either MOV or MP4 format for video, and WAV or MP3 format for audio. Additionally, please note that the maximum supported file size is 2 GB.'
				/>
			</Stack>

			<FieldArray name='contentSection'>
				{({ remove, push }) => (
					<Box>
						{formProps.values.contentSection.length > 0 &&
							formProps.values.contentSection.map((item, index) => {
								const preferredOption = `contentSection[${index}].preferredOption`;
								const errorPreferredOption = getIn(formProps.errors, preferredOption);
								const touchedPreferredOption = getIn(formProps.touched, preferredOption);

								const title = `contentSection[${index}].title`;
								const errorImageVideoTitle = getIn(formProps.errors, title);
								const touchedImageVideoTitle = getIn(formProps.touched, title);

								const data = `contentSection[${index}].data`;
								const errorData = getIn(formProps.errors, data);
								const touchedData = getIn(formProps.touched, data);

								const dataType = `contentSection[${index}].dataType`;

								const isLargeContentSection = size(get(formProps, 'values.contentSection')) > 1;

								return (
									<Box key={index} className='mt-3'>
										{index !== 0 ? (
											<Box>
												<Stack
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													spacing={1}
												>
													<Divider
														orientation='horizontal'
														flexItem={true}
														light={true}
														sx={{
															width: `calc(100% - 48px)`,
															margin: 'auto 0 !important',
														}}
													/>
													{isLargeContentSection && (
														<IconButton color='error' onClick={() => remove(index)}>
															<ClearOutlinedIcon />
														</IconButton>
													)}
												</Stack>
											</Box>
										) : null}
										<Grid container spacing={2}>
											<>
												{formProps.values.contentSection.length > 1 && index !== 0 && (
													<Grid item xs={12}>
														<FormControl fullWidth>
															<FormLabel>{translate('common_select_option')}</FormLabel>
															<RadioGroup
																name={preferredOption}
																value={item.preferredOption}
																onChange={(_e, value) => {
																	formProps.setFieldValue(preferredOption, value);

																	let dataValue = 'TEXT';
																	if (value !== 'TEXT') {
																		dataValue = value + '_UPLOAD';
																	}

																	formProps.setFieldValue(dataType, dataValue);
																	formProps.setFieldValue(title, '');
																	formProps.setFieldValue(data, '');
																}}
																row
																sx={{
																	marginLeft: '10px',
																	marginTop: '10px',
																}}
															>
																<FormControlLabel
																	value='TEXT'
																	control={<CMRadio />}
																	label='Text'
																/>
																<FormControlLabel
																	value='IMAGE'
																	control={<CMRadio />}
																	label={translate('common_image')}
																/>
																<FormControlLabel
																	value='VIDEO'
																	control={<CMRadio />}
																	label={translate('common_video')}
																/>
															</RadioGroup>
															{errorPreferredOption && touchedPreferredOption && (
																<FormHelperText>
																	<ErrorMessage name={preferredOption} />
																</FormHelperText>
															)}
														</FormControl>
													</Grid>
												)}
											</>
											{item.preferredOption === 'TEXT' ? (
												<Grid item xs={12}>
													<Grid item xs={12}>
														<Typography
															variant='subtitle1'
															fontWeight={500}
															color='text.primary'
															mb={0.5}
														>
															Main Content
														</Typography>
														<RichTextEditor
															placeholder={'Type Something'}
															name={data}
															value={get(item, 'data', '')}
															setFieldValue={formProps.setFieldValue}
															error={errorData && touchedData ? true : false}
															helperText={errorData && touchedData ? errorData : null}
														/>
													</Grid>
												</Grid>
											) : null}

											{item.preferredOption === 'IMAGE' && !Array.isArray(item?.data) ? (
												<Grid item xs={12} pt={0}>
													<FormControl fullWidth>
														<FormLabel>{translate('consultant_image_type')}</FormLabel>
														<RadioGroup
															name={dataType}
															value={item.dataType}
															onChange={(_e, value) => {
																formProps.setFieldValue(dataType, value);
															}}
															row
															sx={{ marginLeft: '10px', marginTop: '10px' }}
														>
															<FormControlLabel
																value='IMAGE_UPLOAD'
																control={<CMRadio />}
																label={translate('consultant_image_upload')}
															/>
															<FormControlLabel
																value='IMAGE_LINK'
																control={<CMRadio />}
																label={translate('consultant_image_link')}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											) : null}

											{item.preferredOption === 'IMAGE' && item.dataType === 'IMAGE_UPLOAD' ? (
												<>
													{!Array.isArray(item?.data) && (
														<Grid item xs={12} md={6}>
															<FilePicker
																isPreviewOpen={false}
																label={translate('common_image_upload')}
																id='data'
																name={data}
																value={item?.data}
																onChange={(file) => formProps.setFieldValue(data, file)}
																error={errorData && touchedData ? true : false}
																helperText={errorData && touchedData ? errorData : false}
																dropBoxGridSize={12}
																previewGridSize={6}
																noPreviewImageBaseURL={true}
															/>
														</Grid>
													)}
													<Grid item xs={12} md={Array.isArray(item?.data) ? 12 : 6}>
														<TextField
															label='Title'
															name={title}
															value={item.title}
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
															helperText={
																errorImageVideoTitle && touchedImageVideoTitle
																	? errorImageVideoTitle
																	: null
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<Box>
															<FilePickerPreview
																name={data}
																value={item?.data}
																theme={theme}
																noPreviewImageBaseURL={true}
															/>
															{Array.isArray(item?.data) && (
																<Box mt={1} ml={1}>
																	<Button
																		variant='outlined'
																		onClick={() => formProps.setFieldValue(data, '')}
																	>
																		Replace image
																	</Button>
																</Box>
															)}
														</Box>
													</Grid>
												</>
											) : item.preferredOption === 'TEXT' || item.preferredOption === 'VIDEO' ? null : (
												<>
													<Grid item xs={12} spacing={2}>
														<TextField
															// label="Link"
															label={translate('consultant_image_link')}
															name={data}
															type='url'
															value={item.data[0]?.url ? item.data[0]?.url : item.data}
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															error={errorData && touchedData ? true : false}
															helperText={errorData && touchedData ? errorData : null}
															fullWidth={true}
															// multiline={true}
														/>
													</Grid>
													<Grid item xs={12} spacing={2}>
														<TextField
															label='Title'
															name={title}
															value={item.title}
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
															helperText={
																errorImageVideoTitle && touchedImageVideoTitle
																	? errorImageVideoTitle
																	: null
															}
														/>
													</Grid>
												</>
											)}
										</Grid>

										{/* ----------------VIDEO----------------- */}

										{item.preferredOption === 'VIDEO' ? (
											<Grid item xs={12}>
												<FormControl fullWidth>
													<FormLabel>{translate('consultant_video_type')}</FormLabel>
													<RadioGroup
														name={dataType}
														value={item.dataType}
														onChange={(_e, value) => {
															formProps.setFieldValue(dataType, value);
														}}
														row
														sx={{
															marginLeft: '10px',
															marginTop: '10px',
														}}
													>
														<FormControlLabel
															value='VIDEO_UPLOAD'
															control={<CMRadio />}
															label={translate('consultant_video_upload')}
														/>
														<FormControlLabel
															value='VIDEO_LINK'
															control={<CMRadio />}
															label={translate('consultant_video_link')}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										) : null}

										{item.preferredOption === 'VIDEO' && item.dataType === 'VIDEO_UPLOAD' ? (
											<Grid item xs={12}>
												<VideoPicker
													label={translate('common_video_upload')}
													id='data'
													name={data}
													value={item?.data}
													onChange={(file) => formProps.setFieldValue(data, file)}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : false}
													dropBoxGridSize={6}
													previewGridSize={6}
													noPreviewImageBaseURL={true}
												/>
											</Grid>
										) : item.preferredOption === 'TEXT' || item.preferredOption === 'IMAGE' ? null : (
											<>
												<Grid item xs={12} spacing={2}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
											</>
										)}

										{item.preferredOption === 'TEXT' || item.preferredOption === 'IMAGE' ? null : (
											<Grid item xs={12} spacing={2}>
												<TextField
													label='Title'
													name={title}
													value={item.title}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
													error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
													helperText={
														errorImageVideoTitle && touchedImageVideoTitle
															? errorImageVideoTitle
															: null
													}
												/>
											</Grid>
										)}

										{/* ------------AUDIO--------------- */}

										{item.preferredOption === 'AUDIO' ? (
											<Grid item xs={12}>
												<FormControl fullWidth>
													<FormLabel>{translate('consultant_audio_type')}</FormLabel>
													<RadioGroup
														name={dataType}
														value={item.dataType}
														onChange={(_e, value) => {
															formProps.setFieldValue(dataType, value);
														}}
														row
														sx={{
															marginLeft: '10px',
															marginTop: '10px',
														}}
													>
														<FormControlLabel
															value='AUDIO_LINK'
															control={<CMRadio />}
															label={translate('consultant_audio_link')}
														/>
														<FormControlLabel
															value='AUDIO_UPLOAD'
															control={<CMRadio />}
															label={translate('consultant_audio_upload')}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										) : null}

										{item.preferredOption === 'AUDIO' && item.dataType === 'AUDIO_UPLOAD' ? (
											<Grid item xs={12}>
												<AudioPicker
													label={translate('common_audio_upload')}
													id='data'
													name={data}
													value={item?.data}
													onChange={(file) => {
														handleAudioUpload(file, 'content', data, formProps.setFieldValue);
													}}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : false}
													dropBoxGridSize={6}
													previewGridSize={6}
													noPreviewImageBaseURL={true}
												/>
											</Grid>
										) : item.preferredOption === 'TEXT' ||
										  item.preferredOption === 'IMAGE' ||
										  item.preferredOption === 'VIDEO' ||
										  item.preferredOption === 'PDF' ? null : (
											<>
												<Grid item xs={12} spacing={2}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
											</>
										)}

										{item.preferredOption === 'TEXT' ||
										item.preferredOption === 'IMAGE' ||
										item.preferredOption === 'VIDEO' ||
										item.preferredOption === 'PDF' ? null : (
											<Grid item xs={12} spacing={2}>
												<TextField
													label='Title'
													name={title}
													value={item.title}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
													error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
													helperText={
														errorImageVideoTitle && touchedImageVideoTitle
															? errorImageVideoTitle
															: null
													}
												/>
											</Grid>
										)}

										{/* ------------PDF--------------- */}

										{item.preferredOption === 'PDF' ? (
											<Grid item xs={12}>
												<FormControl fullWidth>
													<FormLabel>{translate('consultant_pdf_type')}</FormLabel>
													<RadioGroup
														name={dataType}
														value={item.dataType}
														onChange={(_e, value) => {
															formProps.setFieldValue(dataType, value);
														}}
														row
														sx={{
															marginLeft: '10px',
															marginTop: '10px',
														}}
													>
														<FormControlLabel
															value='PDF_LINK'
															control={<CMRadio />}
															label={translate('consultant_pdf_link')}
														/>
														<FormControlLabel
															value='PDF_UPLOAD'
															control={<CMRadio />}
															label={translate('consultant_pdf_upload')}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
										) : null}

										{item.preferredOption === 'PDF' && item.dataType === 'PDF_UPLOAD' ? (
											<Grid item xs={12}>
												<PDFPicker
													label={translate('common_pdf_upload')}
													id='data'
													name={data}
													value={item?.data}
													onChange={(file) => {
														handlePDFUpload(file, 'content', data, formProps.setFieldValue);
													}}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : false}
													dropBoxGridSize={6}
													previewGridSize={6}
													noPreviewImageBaseURL={true}
												/>
											</Grid>
										) : item.preferredOption === 'TEXT' ||
										  item.preferredOption === 'IMAGE' ||
										  item.preferredOption === 'AUDIO' ||
										  item.preferredOption === 'VIDEO' ? null : (
											<>
												<Grid item xs={12} spacing={2}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
											</>
										)}
										{item.preferredOption === 'TEXT' ||
										item.preferredOption === 'IMAGE' ||
										item.preferredOption === 'VIDEO' ||
										item.preferredOption === 'AUDIO' ? null : (
											<Grid item xs={12} spacing={2}>
												<TextField
													label='Title'
													name={title}
													value={item.title}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
													error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
													helperText={
														errorImageVideoTitle && touchedImageVideoTitle
															? errorImageVideoTitle
															: null
													}
												/>
											</Grid>
										)}
									</Box>
								);
							})}
						<Button
							variant='outlined'
							sx={{ mr: 2, mt: 2 }}
							onClick={() =>
								push({
									data: '',
									preferredOption: 'TEXT',
									title: '',
									dataType: 'TEXT', //IMAGE_UPLOAD
								})
							}
							disabled={formProps.values.contentSection.length >= 3}
						>
							{translate('consultant_add_content')}
						</Button>
					</Box>
				)}
			</FieldArray>
		</Grid>
	);
};

export default ContentSection;
