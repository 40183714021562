import { configureStore, autoBatchEnhancer } from "@reduxjs/toolkit";
import rootReducer from "./RootReducer";

const store = configureStore({
  reducer: rootReducer,
  enhancers: (existingEnhancers) => {
    return existingEnhancers.concat(autoBatchEnhancer());
  },
});

export default store;
