import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import numToArray from "components/hooks/numToArray";

const RecipeFormLangAndServing = ({ formProps, languagesList, cuisineData }) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <Autocomplete
          options={languagesList ?? []}
          name="language"
          value={formProps.values.language}
          getOptionLabel={(option) => option?.title ?? ""}
          isOptionEqualToValue={(option, value) => {
            if (option?._id === value?._id || option === "") {
              return true;
            }
          }}
          onChange={(_e, value) => {
            formProps.setFieldValue("language", value);
          }}
          onBlur={formProps.handleBlur}
          noOptionsText={"No Data"}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Language"
              name="language"
              error={
                formProps.errors.language && formProps.touched.language
                  ? true
                  : false
              }
              helperText={
                formProps.errors.language && formProps.touched.language
                  ? formProps.errors.language
                  : null
              }
            />
          )}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Autocomplete
          options={cuisineData ?? []}
          name="cuisine"
          value={formProps.values.cuisine}
          getOptionLabel={(option) => option?.title ?? ""}
          isOptionEqualToValue={(option, value) => {
            if (option._id === value._id || option === "") {
              return true;
            }
          }}
          onChange={(_e, value) => {
            formProps.setFieldValue("cuisine", value);
          }}
          onBlur={formProps.handleBlur}
          noOptionsText={"No Data"}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cuisine"
              name="cuisine"
              error={
                formProps.errors.cuisine && formProps.touched.cuisine
                  ? true
                  : false
              }
              helperText={
                formProps.errors.cuisine && formProps.touched.cuisine
                  ? formProps.errors.cuisine
                  : null
              }
            />
          )}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Autocomplete
          options={numToArray(20)}
          name="recipeServingCount"
          value={formProps.values.recipeServingCount}
          getOptionLabel={(option) => option.toString() ?? ""}
          isOptionEqualToValue={(option, value) => {
            if (option === value || option === "") {
              return true;
            }
          }}
          onChange={(_e, value) => {
            formProps.setFieldValue("recipeServingCount", value);
          }}
          onBlur={formProps.handleBlur}
          noOptionsText={"No Data"}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Serving Count"
              name="recipeServingCount"
              error={
                formProps.errors.recipeServingCount &&
                formProps.touched.recipeServingCount
                  ? true
                  : false
              }
              helperText={
                formProps.errors.recipeServingCount &&
                formProps.touched.recipeServingCount
                  ? formProps.errors.recipeServingCount
                  : null
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default RecipeFormLangAndServing;
