import moment from "moment";
import { Cookies } from "react-cookie";

const cookie = new Cookies();

const cookieSet = (name, value, time, path) => {
  const expireTime = moment().add(time || 24, "h")?._d;

  const result = cookie.set(name, value, {
    expires: expireTime,
    path: path || "/",
  });

  return result;
};

const cookieGet = (name, path) => {
  const result = cookie.get(name, {
    path: path || "/",
  });

  return result;
};

export default cookie;
export { cookieSet, cookieGet };
