import React from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from "react-router-dom";

import Toolbar from '@mui/material/Toolbar';
// import Box from "@mui/material/Box";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

// import BrandLogo from "assets/images/logo/Inspiremind.png";

const DrawerHeader = (props) => {
	const { handleDrawerToggle } = props;
	const theme = useTheme();
	// const navigate = useNavigate();

	return (
		<Toolbar
			variant='dense'
			className='position-sticky top-0 left-0 right-0 zIndex-sticky'
			sx={{
				justifyContent: 'space-between',
				backgroundColor: '#07080C',
				border: '1px solid',
				borderImageSource: `linear-gradient(90deg, rgba(219, 64, 64, 0) 0%,  #ffffff40 48.96%, rgba(219, 64, 64, 0) 100%)`,
				borderImageSlice: 1,
				borderImageWidth: 1,
				'&.MuiToolbar-root': { p: 1 },
			}}
		>
			{/* <Box
        component="img"
        src={BrandLogo}
        className=" navbar-brand"
        onClick={() => navigate("/dashboard")}
        sx={{
          maxWidth: { sm: "175px", xs: "175px" },
          marginLeft: "25px",
        }}
      /> */}
			{/* <h1 onClick={() => navigate("/dashboard")} style={{ color: "#f5cc23" }}>
        InspireMind
      </h1> */}
			<IconButton
				aria-label='open drawer'
				edge='start'
				onClick={handleDrawerToggle}
				sx={{
					ml: 1,
					display: { lg: 'none' },
					color: theme.palette.common.white,
				}}
			>
				<MenuOpenIcon color='light' />
			</IconButton>
		</Toolbar>
	);
};

DrawerHeader.propTypes = {
	handleDrawerToggle: PropTypes.func.isRequired,
};

export default DrawerHeader;
