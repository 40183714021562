// MUI
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
// Custom
import LightBoxButton from "./LightBoxButton";

const ZoomControls = ({ maxZoom, activeZoomScale, swiperRef }) => {
  const swiper = swiperRef;

  const zoomIn = () => {
    if (activeZoomScale < maxZoom) {
      swiper?.zoom?.setZoom(activeZoomScale + 0.5);
    }
  };

  const zoomOut = () => {
    if (activeZoomScale > 1) {
      swiper?.zoom?.setZoom(activeZoomScale - 0.5);
    }
  };

  return (
    <>
      <LightBoxButton
        type="button"
        onClick={zoomIn}
        disabled={activeZoomScale >= maxZoom ? true : false}
      >
        <ZoomInIcon />
      </LightBoxButton>
      <LightBoxButton
        type="button"
        onClick={zoomOut}
        disabled={activeZoomScale <= 1 ? true : false}
      >
        <ZoomOutIcon />
      </LightBoxButton>
    </>
  );
};

export default ZoomControls;
