import { map, get } from "lodash";

export const FoodNutrientCalc = (props) => {
  const {
    measureType,
    volumeValue,
    volumeUnit,
    // portionValue,
    // tablespoonFull,
    // tablespoonParcel,
    amount,
  } = props;

  let result = 0;

  if (measureType === "volume") {
    let convertValue = 0;
    if (volumeUnit === "KG") {
      convertValue = volumeValue * 1000;
    } else if (volumeUnit === "OZ") {
      convertValue = volumeValue * 28.35;
    } else {
      convertValue = volumeValue;
    }
    result = (convertValue / 100) * amount;
  }

  return parseFloat(result).toFixed(2);
};

export const FoodTotalNutrientCalc = (props) => {
  const { array, key, operator, fdc_id, totalValue } = props;

  let result = 0;

  map(array, (data) => {
    if (operator === "+" || operator === "add") {
      if (key === "fat") {
        return (result += get(data, "fat", 0));
      } else if (key === "protein") {
        return (result += get(data, "protein", 0));
      } else if (key === "carbs") {
        return (result += get(data, "carbs", 0));
      } else if (key === "calories") {
        return (result += get(data, "calories", 0));
      }
    } else if (operator === "-" || operator === "subtract") {
      if (data?.fdc_id === fdc_id) {
        if (key === "fat") {
          return (result = totalValue - get(data, "fat", 0));
        } else if (key === "protein") {
          return (result = totalValue - get(data, "protein", 0));
        } else if (key === "carbs") {
          return (result = totalValue - get(data, "carbs", 0));
        } else if (key === "calories") {
          return (result = totalValue - get(data, "calories", 0));
        }
      }
    }
  });

  return parseFloat(result).toFixed(2);
};
