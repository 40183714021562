import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// Form
import { Formik } from 'formik';
import { get, omit } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
// MUI
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// Custom
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from 'components/common/MultiSelectSpecialization';
import TimeOutComponent from 'components/common/TimeOutComponent';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
// Pages
// Store
import { createUpdateArticle } from 'store/slice/quotes-management/QuotesSlice';
import { getSpecializationData } from 'store/slice/common/GeneralConfigDataSlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import QuotesFormSEOSection from 'components/pages/quotes-management/form-components/QuotesFormSEOSection';

const QuotesCreateUpdateForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const quotesDetail = get(location, 'state.articleDetail', {});
	const formType = get(location, 'state.formType', {});

	const languagesList = useSelector((state) => state.languageConfig.languageList);
	const specializationList = useSelector((state) => state.generalConfigData.specializationData);
	const isLoading = useSelector((state) => state.article.loading);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required'),
		language: Yup.object().shape().required('Language is required').nullable(),
		categories: Yup.array().min(1, 'Categories is required').required('Categories is required'),
		quote: Yup.string().required('Quote is required'),
		// description: Yup.string().required('Description is required')
	});

	const handleSubmit = (values) => {
		let payloadData = {
			...omit(values, ['subCategories']),
			language: get(values, 'language._id', ''),
			categories: getSpecializationSubmitData(
				values,
				values.subCategories,
				'categories',
				'subCategories'
			),
		};
		if (formType === 'update') {
			payloadData = {
				...payloadData,
				_id: get(quotesDetail, '_id', ''),
			};
		}

		dispatch(createUpdateArticle(payloadData))
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false)) {
					toast.success(get(result, 'message', 'Quotes created successfully.'));
					navigate('/quotes-management', {
						state: {
							tabIndex: get(location, 'state.tabIndex', 0),
							apiType: get(location, 'state.apiType', 'ALL'),
						},
					});
				} else {
					toast.error(get(result, 'message', 'Something went wrong.'));
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	useEffect(() => {
		dispatch(getSpecializationData());
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	return (
		<MDCard>
			<MDCardHeader title={`Quotes ${formType === 'update' ? "Detail's Update" : 'Create'}`} />
			<CardContent>
				<TimeOutComponent delay={2}>
					{specializationList.length > 0 && (
						<Formik
							initialValues={{
								title: get(quotesDetail, 'title', ''),
								language: get(quotesDetail, 'language', null),
								categories: getInitSpecialization(
									quotesDetail,
									specializationList,
									'categories'
								),
								subCategories: getInitSubSpecialization(
									quotesDetail,
									specializationList,
									'categories',
									'subCategories'
								),
								quote: get(quotesDetail, 'quote', ''),
								author: get(quotesDetail, 'author', ''),
								description: get(quotesDetail, 'description', ''),
								isPublished: get(quotesDetail, 'isPublished', false),
								comments: get(quotesDetail, 'comments', false),
								likeStatus: get(quotesDetail, 'likeStatus', false),
								ratingStatus: get(quotesDetail, 'ratingStatus', false),
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								handleSubmit(values);
							}}
						>
							{(formProps) => (
								<form onSubmit={formProps.handleSubmit}>
									<Grid container spacing={2}>
										<Grid item xs={12} container spacing={2}>
											<Grid item xs={12}>
												<Box>
													<TextField
														size='small'
														label='Title'
														name='title'
														value={formProps.values.title}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={
															formProps.errors.title && formProps.touched.title
																? true
																: false
														}
														helperText={
															formProps.errors.title && formProps.touched.title
																? formProps.errors.title
																: null
														}
														fullWidth={true}
														multiline={true}
													/>
												</Box>
											</Grid>
											<Grid item xs={12}>
												<Box>
													<Autocomplete
														options={languagesList ?? []}
														name='language'
														value={formProps.values.language}
														getOptionLabel={(option) => option?.title ?? ''}
														isOptionEqualToValue={(option, value) => {
															if (option?._id === value?._id || option === '') {
																return true;
															}
														}}
														onChange={(_e, value) => {
															formProps.setFieldValue('language', value);
														}}
														onBlur={formProps.handleBlur}
														disablePortal
														autoHighlight
														size='small'
														noOptionsText={'No Languages'}
														renderInput={(params) => (
															<TextField
																{...params}
																label='Select Language'
																name='language'
																error={
																	formProps.errors.language &&
																	formProps.touched.language
																		? true
																		: false
																}
																helperText={
																	formProps.errors.language &&
																	formProps.touched.language
																		? formProps.errors.language
																		: null
																}
															/>
														)}
													/>
												</Box>
											</Grid>
											<Grid item xs={12}>
												<MultiSelectSpecialization
													specializations={specializationList}
													fieldName='categories'
													subFieldName='subCategories'
													formProps={formProps}
													initialData={quotesDetail}
													isSubmit={false}
												/>
											</Grid>
										</Grid>

										<Grid item xs={12}>
											<Divider orientation='horizontal' flexItem />
										</Grid>
										<Grid item xs={12}>
											<QuotesFormSEOSection formProps={formProps} />
										</Grid>
										<Grid item xs={12} container spacing={2} className='mt-1 mb-3'>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='reset'
													variant='outlined'
													padding='6px 24px'
													fullWidth={true}
													onClick={(_event) => {
														formProps.resetForm();
													}}
												>
													Reset
												</MDLoadingButton>
											</Grid>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='submit'
													variant='contained'
													padding='6px 24px'
													fullWidth={true}
													loading={formProps.isSubmitting && isLoading}
												>
													Submit
												</MDLoadingButton>
											</Grid>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					)}
				</TimeOutComponent>
			</CardContent>
		</MDCard>
	);
};

export default QuotesCreateUpdateForm;
