import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  UpdateConsultantBasicInfo,
  UpdateConsultantBio,
  UpdateConsultantHighSchoolInfo,
  UpdateConsultantLicenseOrCertificate,
  UpdateConsultantPublicationOrLink,
  UpdateConsultantResidentialAddress,
  UpdateConsultantSocialMediaInfo,
  UpdateConsultantSpecialization,
  UpdateConsultantUniversityInfo,
  UpdateConsultantWorkInfo,
  UpdateRevenueShare,
} from "api/user-management/UserManagementAPI";
import { get } from "lodash";

const initialState = {
  loading: false,
};

export const updateConsultantBasicInfo = createAsyncThunk(
  "consultantProfile/updateConsultantBasicInfo",
  async (data) => {
    return await UpdateConsultantBasicInfo(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantBio = createAsyncThunk(
  "consultantProfile/updateConsultantBio",
  async (data) => {
    return await UpdateConsultantBio(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantResidentialAddress = createAsyncThunk(
  "consultantProfile/updateConsultantResidentialAddress",
  async (data) => {
    return await UpdateConsultantResidentialAddress(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantSpecialization = createAsyncThunk(
  "consultantProfile/updateConsultantSpecialization",
  async (data) => {
    return await UpdateConsultantSpecialization(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantWorkInfo = createAsyncThunk(
  "consultantProfile/updateConsultantWorkInfo",
  async (data) => {
    return await UpdateConsultantWorkInfo(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantUniversityInfo = createAsyncThunk(
  "consultantProfile/updateConsultantUniversityInfo",
  async (data) => {
    return await UpdateConsultantUniversityInfo(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantHighSchoolInfo = createAsyncThunk(
  "consultantProfile/updateConsultantHighSchoolInfo",
  async (data) => {
    return await UpdateConsultantHighSchoolInfo(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantLicenseOrCertificate = createAsyncThunk(
  "consultantProfile/updateConsultantLicenseOrCertificate",
  async (data) => {
    return await UpdateConsultantLicenseOrCertificate(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantPublicationOrLink = createAsyncThunk(
  "consultantProfile/updateConsultantPublicationOrLink",
  async (data) => {
    return await UpdateConsultantPublicationOrLink(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantSocialMediaInfo = createAsyncThunk(
  "consultantProfile/updateConsultantSocialMediaInfo",
  async (data) => {
    return await UpdateConsultantSocialMediaInfo(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateConsultantRevenueShare = createAsyncThunk(
  "consultantProfile/updateConsultantRevenueShare",
  async (data) => {
    return await UpdateRevenueShare(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);

const ConsultantProfileSlice = createSlice({
  name: "consultantProfile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateConsultantBasicInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantBasicInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantBasicInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantBio.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantBio.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantBio.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantResidentialAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantResidentialAddress.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantResidentialAddress.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantSpecialization.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantSpecialization.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantSpecialization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantWorkInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantWorkInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantWorkInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantUniversityInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantUniversityInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantUniversityInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantHighSchoolInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantHighSchoolInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantHighSchoolInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantLicenseOrCertificate.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantLicenseOrCertificate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantLicenseOrCertificate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantPublicationOrLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantPublicationOrLink.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantPublicationOrLink.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantSocialMediaInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantSocialMediaInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantSocialMediaInfo.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantRevenueShare.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateConsultantRevenueShare.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateConsultantRevenueShare.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default ConsultantProfileSlice.reducer;
