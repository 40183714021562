import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FieldArray, Formik, getIn } from 'formik';
import { get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import RemoveIcon from '@mui/icons-material/Remove';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import MDButton from 'components/md-components/button/MDButton';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import QuestionDetailAccordion from './component/QuestionDetailAccordion';
import SelectQuestion from './component/SelectQuestion';

import TimeOutComponent from 'components/common/TimeOutComponent';
import {
	getDietaryPreferenceData,
	getFoodAllergiesData,
	getGoalTypeData,
	getSpecializationData,
} from 'store/slice/common/GeneralConfigDataSlice';
import { getQuestionsList } from 'store/slice/consultant-config/QAndASlice';
import { createQuestionTemplate, updateQuestionTemplate } from 'store/slice/consultant-config/QAndATemplateSlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import QandALanguageTranslationForm from './QandALanguageTranslationForm';
import LanguageTranslationForm, { languageInitialValue } from 'components/common/LanguageTranslationForm';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from 'components/common/MultiSelectSpecialization';
import { Checkbox, FormControlLabel } from '@mui/material';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import DefaultSegmentSection from './default-sections/DefaultSegmentSection';

const ValidationSchema = Yup.object().shape({
	templateName: Yup.string().required('Template name is required.'),
	languages: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().required('Value is required'),
		})
	),
	description: Yup.string().required('Description is required.'),
	descriptionLanguages: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().required('Value is required'),
		})
	),
	categories: Yup.array().min(1, 'Categories is required'),
	sections: Yup.array().of(
		Yup.object().shape({
			sectionName: Yup.string().required('Segment name is required.'),
			languages: Yup.array().of(
				Yup.object().shape({
					value: Yup.string().required('Value is required'),
				})
			),
			questions: Yup.array().of(Yup.object()),
		})
	),
});

const QAndATemplateForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const type = location.state.type;
	const formType = location.state.formType;
	const rowData = location.state.rowData;

	const [defaultSegment, setDefaultSegment] = useState([]);
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.QAndATemplate.loading);
	const goalTypeList = useSelector((state) => state.generalConfigData.goalTypeData);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const specializationList = useSelector((state) => state.generalConfigData.specializationData);

	const [selectLanguageAlert, setSelectLanguageAlert] = useState(false);
	const [questionDialogName, setQuestionDialogName] = useState('');
	const [languageList, setLanguageList] = useState([]);
	const handleAddQuestionOpen = (fieldName) => {
		setQuestionDialogName(fieldName);
	};
	const handleAddQuestionClose = () => {
		setQuestionDialogName(false);
	};

	useEffect(() => {
		if (languageConfigList?.length) {
			setLanguageList(languageConfigList.filter((e) => e.status));
		} else {
			setLanguageList(languageConfigList);
		}
	}, [languageConfigList]);

	const handleSubmit = (values) => {
		console.log('values :>> ', values);
		let payloadData = {};
		payloadData = {
			...values,
			categories: getSpecializationSubmitData(values, values.subCategories, 'categories', 'subCategories'),
			sections: map(values.sections, (sec) => {
				return {
					languages: sec.languages,
					sectionName: sec.sectionName,
					questions: map(sec.questions, (que) => {
						return { questionId: que.questionId._id };
					}),
				};
			}),
		};
		payloadData.type = type;

		if (formType === 'create') {
			dispatch(createQuestionTemplate(payloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Question created successfully.'));
						if (type === 'pre') {
							navigate('/consultant-config/pre-consultant');
						} else {
							navigate('/consultant-config/post-consultant');
						}
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch((err) => {
					throw err;
				});
		} else {
			const updatePayloadData = {
				id: get(rowData, '_id', ''),
				data: payloadData,
			};

			dispatch(updateQuestionTemplate(updatePayloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Question updated successfully.'));
						if (type === 'pre') {
							navigate('/consultant-config/pre-consultant', {
								state: { index: 1 },
							});
						} else {
							navigate('/consultant-config/post-consultant', {
								state: { index: 1 },
							});
						}
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch((err) => {
					throw err;
				});
		}
	};

	const getDefaultSegmentQuestions = async () => {
		try {
			const res = await AxiosDefaultSetting({
				method: 'GET',
				url: '/admin/questions/template/get?type=default',
			});
			setDefaultSegment(
				get(res, 'data.data', []).map((e) => ({
					...e,
					questions: e.questions.map((question) => ({ ...question.questionId })),
				}))
			);
		} catch (error) {}
	};

	useEffect(() => {
		getDefaultSegmentQuestions();
		dispatch(getQuestionsList(type));
		dispatch(getGoalTypeData());
		dispatch(getDietaryPreferenceData());
		dispatch(getFoodAllergiesData());
		dispatch(getLanguageConfigList());
		dispatch(getSpecializationData());
	}, [dispatch, type]);

	return (
		<MDCard>
			<MDCardHeader title={`${formType === 'create' ? 'Create' : 'Update'} Question Template`} />
			<CardContent className='h-100 my-1'>
				<TimeOutComponent delay={2} isEmpty={isEmpty(goalTypeList)} px={1} pt={1}>
					{specializationList.length > 0 && (
						<Formik
							initialValues={{
								templateName: get(rowData, 'templateName', ''),
								languages: languageInitialValue(languageList, get(rowData, 'languages', false), 'common'),
								description: get(rowData, 'description', ''),
								descriptionLanguages: languageInitialValue(
									languageList,
									get(rowData, 'descriptionLanguages', false),
									'common'
								),
								categories: getInitSpecialization(rowData, specializationList, 'categories'),
								subCategories: getInitSubSpecialization(
									rowData,
									specializationList,
									'categories',
									'subCategories'
								),
								sections: get(rowData, 'sections', [
									{
										sectionName: '',
										languages: languageInitialValue(languageList, get(rowData, 'languages', false), 'common'),
										questions: [],
									},
								]),
								additionalComments: get(rowData, 'additionalComments', false),
							}}
							validationSchema={ValidationSchema}
							onSubmit={(values) => {
								handleSubmit(values);
							}}
						>
							{(formProps) => (
								<form onSubmit={formProps.handleSubmit}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<MDCard variant='outlined'>
												<CardContent>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<TextField
																label='Template Name'
																name='templateName'
																value={formProps.values.templateName}
																onChange={formProps.handleChange}
																onBlur={formProps.handleBlur}
																error={
																	formProps.errors.templateName &&
																	formProps.touched.templateName
																		? true
																		: false
																}
																helperText={
																	formProps.errors.templateName &&
																	formProps.touched.templateName
																		? formProps.errors.templateName
																		: null
																}
																fullWidth={true}
																multiline={true}
																size='small'
															/>
														</Grid>
														<Grid item xs={12}>
															<LanguageTranslationForm
																fieldName='languages'
																formProps={formProps}
																languageConfigList={languageList}
																inputField='text-input'
															/>
														</Grid>
													</Grid>
												</CardContent>
											</MDCard>
										</Grid>

										<Grid item xs={12}>
											<MDCard variant='outlined'>
												<CardContent>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<TextField
																label='Description'
																name='description'
																value={formProps.values.description}
																onChange={formProps.handleChange}
																onBlur={formProps.handleBlur}
																error={
																	formProps.errors.description && formProps.touched.description
																		? true
																		: false
																}
																helperText={
																	formProps.errors.description && formProps.touched.description
																		? formProps.errors.description
																		: null
																}
																fullWidth={true}
																// multiline={true}
																size='small'
																// minRows={3}
															/>
														</Grid>
														<Grid item xs={12}>
															<LanguageTranslationForm
																fieldName='descriptionLanguages'
																formProps={formProps}
																languageConfigList={languageList}
																inputField='text-input'
																multiline={true}
																minRows={3}
															/>
														</Grid>
													</Grid>
												</CardContent>
											</MDCard>
										</Grid>

										<Grid item xs={12}>
											<MultiSelectSpecialization
												specializations={specializationList}
												fieldName='categories'
												subFieldName='subCategories'
												formProps={formProps}
												initialData={rowData}
												isSubmit={false}
												label='specialization'
											/>
										</Grid>

										<Grid item xs={12}>
											<Box>
												<Stack direction='column' spacing={2}>
													{defaultSegment.map((item) => (
														<DefaultSegmentSection key={item._id} data={item} />
													))}
												</Stack>
											</Box>
										</Grid>

										<Grid item xs={12}>
											<FieldArray name='sections'>
												{({ remove, push }) => (
													<Box>
														{map(formProps.values.sections, (customSection, customSectionIndex) => {
															const sectionName = `sections[${customSectionIndex}].sectionName`;
															const errorSegmentName = getIn(formProps.errors, sectionName);
															const touchedSegmentName = getIn(formProps.touched, sectionName);
															const questionsField = `sections[${customSectionIndex}].questions`;
															const questionsFieldError = getIn(formProps.errors, questionsField);
															const questionsFieldTouched = getIn(
																formProps.touched,
																questionsField
															);

															const languages = `sections[${customSectionIndex}].languages`;

															return (
																<MDCard
																	key={customSectionIndex}
																	variant='outlined'
																	className={
																		customSectionIndex ===
																		formProps.values.sections.length - 1
																			? 'mb-0'
																			: 'mb-3'
																	}
																>
																	<Stack
																		direction='row'
																		justifyContent='space-between'
																		spacing={2}
																		className='px-3 py-2 border-bottom'
																	>
																		<Typography variant='body1' fontWeight={500}>
																			{customSection.sectionName}
																		</Typography>
																		<Button
																			variant='outlined'
																			type='button'
																			color='error'
																			onClick={() => remove(customSectionIndex)}
																			className='pt-0 pb-0 px-0'
																		>
																			<RemoveIcon />
																		</Button>
																	</Stack>
																	<CardContent>
																		<Grid container spacing={2}>
																			<Grid item xs={12} container spacing={2}>
																				<Grid item md={9} sm={8} xs={12}>
																					<TextField
																						label='Segment Name'
																						name={sectionName}
																						value={customSection.sectionName}
																						onChange={formProps.handleChange}
																						onBlur={formProps.handleBlur}
																						error={
																							errorSegmentName && touchedSegmentName
																								? true
																								: false
																						}
																						helperText={
																							errorSegmentName && touchedSegmentName
																								? errorSegmentName
																								: null
																						}
																						fullWidth={true}
																						size='small'
																					/>
																				</Grid>

																				<Grid item md={3} sm={4} xs={12}>
																					<MDButton
																						label='Add Question'
																						variant='contained'
																						padding='4px 16px'
																						fullWidth={true}
																						onClick={() => {
																							if (
																								isEmpty(
																									formProps.values.languages
																								)
																							) {
																								setSelectLanguageAlert(true);
																							} else {
																								handleAddQuestionOpen(
																									questionsField
																								);
																							}
																						}}
																					/>
																				</Grid>

																				{languages && (
																					<Grid item md={9} sm={8} xs={12}>
																						<QandALanguageTranslationForm
																							fieldName={languages}
																							section='SEGMENT_NAME'
																							customSectionIndex={
																								customSectionIndex
																							}
																							formProps={formProps}
																							languageConfigList={languageList}
																							inputField='text-input'
																						/>
																					</Grid>
																				)}
																				{selectLanguageAlert &&
																				isEmpty(formProps.values.languages) ? (
																					<Grid item xs={12}>
																						<Alert
																							severity='error'
																							onClose={() => {
																								setSelectLanguageAlert(false);
																							}}
																						>
																							First choose language — Then add
																							questions
																						</Alert>
																					</Grid>
																				) : null}
																				<SelectQuestion
																					fieldName={questionsField}
																					fieldValue={customSection.questions}
																					setFieldValue={formProps.setFieldValue}
																					open={Boolean(
																						questionDialogName === questionsField
																					)}
																					onClose={handleAddQuestionClose}
																					id={`select-question-dialog-${customSectionIndex}`}
																					type={type}
																					// languageId={
																					//   formProps.values.language?._id
																					// }
																				/>
																			</Grid>
																			<Grid item xs={12}>
																				<Box>
																					{map(
																						customSection.questions,
																						(
																							customSectionQuestion,
																							customSectionQuestionIndex
																						) => (
																							<Box
																								key={`custom-section-${customSectionQuestionIndex}`}
																							>
																								<QuestionDetailAccordion
																									sectionType='custom'
																									sectionsIndex={
																										customSectionIndex
																									}
																									questionIndex={
																										customSectionQuestionIndex
																									}
																									questionData={
																										customSectionQuestion.questionId
																									}
																									setFieldValue={
																										formProps.setFieldValue
																									}
																									fieldName={questionsField}
																									fieldValue={
																										customSection.questions
																									}
																								/>
																							</Box>
																						)
																					)}
																					{Boolean(
																						questionsFieldError &&
																							questionsFieldTouched
																					) ? (
																						<FormHelperText error={true}>
																							{questionsFieldError}
																						</FormHelperText>
																					) : null}
																				</Box>
																			</Grid>
																		</Grid>
																	</CardContent>
																</MDCard>
															);
														})}
														<Box my={2}>
															<MDButton
																label='Add Section'
																onClick={() =>
																	push({
																		sectionName: '',
																		languages: languageInitialValue(
																			languageList,
																			get(rowData, 'languages', false),
																			'common'
																		),
																		questions: [],
																	})
																}
																variant='contained'
																padding='4px 24px'
															/>
														</Box>
													</Box>
												)}
											</FieldArray>

											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Checkbox
															name='additionalComments'
															checked={formProps.values.additionalComments}
															value={formProps.values.additionalComments}
															onChange={(e) => {
																formProps.setFieldValue('additionalComments', e.target.checked);
															}}
															inputProps={{ 'aria-label': 'Status' }}
														/>
													}
													label='Additional Comments'
													sx={{ mr: 0 }}
												/>
											</Grid>
										</Grid>
										<Grid item xs={12} container spacing={2} className='mt-3 mb-3'>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='reset'
													variant='outlined'
													padding='6px 24px'
													fullWidth={true}
													onClick={(_event) => {
														formProps.resetForm();
													}}
												>
													Reset
												</MDLoadingButton>
											</Grid>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='submit'
													variant='contained'
													padding='6px 24px'
													fullWidth={true}
													loading={formProps.isSubmitting && isLoading}
												>
													Submit
												</MDLoadingButton>
											</Grid>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					)}
				</TimeOutComponent>
			</CardContent>
		</MDCard>
	);
};

export default QAndATemplateForm;
