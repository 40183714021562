import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FieldArray, Formik, getIn } from 'formik';
import { get, isEmpty, map, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';
import * as Yup from 'yup';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import MDButton from 'components/md-components/button/MDButton';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDCard from 'components/md-components/card/MDCard';

import TimeOutComponent from 'components/common/TimeOutComponent';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import { createQuestion, updateQuestion } from 'store/slice/consultant-config/QAndASlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import LanguageTranslationForm, { languageInitialValue } from 'components/common/LanguageTranslationForm';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from 'components/common/MultiSelectSpecialization';
import { getSpecializationData } from 'store/slice/common/GeneralConfigDataSlice';
import QandALanguageTranslationForm from './QandALanguageTranslationForm';

const ValidationSchema = Yup.object().shape({
	questionTitle: Yup.string().required('Question title is required.'),
	languages: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().required('Value is required'),
		})
	),
	categories: Yup.array().min(1, 'Categories is required'),
	inputType: Yup.string().required('Input type is required.'),
	options: Yup.array().when('inputType', {
		is: (value) => value === 'radio' || value === 'checkbox',
		then: Yup.array().of(
			Yup.object().shape({
				label: Yup.string().required('Label is required.'),
				value: Yup.string().required('Value is required.'),
				languages: Yup.array().of(
					Yup.object().shape({
						value: Yup.string().required('Value is required'),
					})
				),
				subQuestions: Yup.array().when('subQuestionStatus', {
					is: true,
					then: Yup.array().of(
						Yup.object().shape({
							questionTitle: Yup.string().required('Question title is required.'),
							languages: Yup.array().of(
								Yup.object().shape({
									value: Yup.string().required('Value is required'),
								})
							),
							inputType: Yup.string().required('Input type is required.'),
							options: Yup.array().when('inputType', {
								is: (value) => value === 'radio' || value === 'checkbox',
								then: Yup.array().of(
									Yup.object().shape({
										label: Yup.string().required('Label is required.'),
										value: Yup.string().required('Value is required.'),
										languages: Yup.array().of(
											Yup.object().shape({
												value: Yup.string().required('Value is required'),
											})
										),
									})
								),
								otherwise: Yup.array().of(Yup.object().notRequired()),
							}),
							minValue: Yup.number().when('inputType', {
								is: (value) => value === 'scale',
								then: Yup.number().required('Minimum value is required.'),
								otherwise: Yup.number().notRequired(),
							}),
							maxValue: Yup.number().when('inputType', {
								is: (value) => value === 'scale',
								then: Yup.number().required('Maximum value is required.'),
								otherwise: Yup.number().notRequired(),
							}),
							stepValue: Yup.number().when('inputType', {
								is: (value) => value === 'scale',
								then: Yup.number().required('Step value is required.'),
								otherwise: Yup.number().notRequired(),
							}),
						})
					),
					otherwise: Yup.array().notRequired(),
				}),
			})
		),
		otherwise: Yup.array().of(Yup.object()),
	}),
	minValue: Yup.number().when('inputType', {
		is: (value) => value === 'scale',
		then: Yup.number().required('Minimum value is required.'),
		otherwise: Yup.number().notRequired(),
	}),
	maxValue: Yup.number().when('inputType', {
		is: (value) => value === 'scale',
		then: Yup.number().required('Maximum value is required.'),
		otherwise: Yup.number().notRequired(),
	}),
	stepValue: Yup.number().when('inputType', {
		is: (value) => value === 'scale',
		then: Yup.number().required('Step value is required.'),
		otherwise: Yup.number().notRequired(),
	}),
});
const InputTypeList = [
	{ label: 'Text', value: 'text' },
	{ label: 'Radio', value: 'radio' },
	{ label: 'Checkbox', value: 'checkbox' },
	{ label: 'Scale', value: 'scale' },
];

const QAndAForm = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const type = location.state.type;
	const formType = location.state.formType;
	const rowData = location.state.rowData;

	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.QAndA.loading);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const specializationList = useSelector((state) => state.generalConfigData.specializationData);
	const [languageList, setLanguageList] = useState([]);

	useEffect(() => {
		if (languageConfigList?.length) {
			setLanguageList(languageConfigList.filter((e) => e.status));
		} else {
			setLanguageList([]);
		}
	}, [languageConfigList]);

	const handleSubmit = (values) => {
		let payloadData = {
			...values,
			categories: getSpecializationSubmitData(values, values.subCategories, 'categories', 'subCategories'),
		};

		if (values.inputType === 'text') {
			payloadData = omit(payloadData, 'options', 'maxValue', 'minValue', 'stepValue');
		} else if (payloadData.inputType === 'radio' || payloadData.inputType === 'checkbox') {
			payloadData = {
				...omit(payloadData, 'maxValue', 'minValue', 'stepValue'),
				options: map(payloadData.options, (option) => {
					if (option.subQuestionStatus === false) {
						return omit(option, 'subQuestionStatus', 'subQuestions', '_id');
					} else {
						return {
							label: option.label,
							value: option.value,
							languages: option.languages,
							subQuestionStatus: option.subQuestionStatus,
							subQuestions: map(option.subQuestions, (subQuestData) => {
								if (subQuestData.inputType === 'radio' || subQuestData.inputType === 'checkbox') {
									return {
										...omit(subQuestData, 'maxValue', 'minValue', 'stepValue', '_id'),
										options: map(subQuestData.options, (subOptions) => {
											return omit(subOptions, '_id');
										}),
									};
								} else if (subQuestData.inputType === 'scale') {
									return omit(subQuestData, 'options', '_id');
								} else if (subQuestData.inputType === 'text') {
									return omit(subQuestData, 'options', '_id');
								}
							}),
						};
					}
				}),
			};
		} else {
			payloadData = omit(payloadData, 'options');
		}
		payloadData.type = type;

		if (formType === 'create') {
			dispatch(createQuestion(payloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Question created successfully.'));
						if (type === 'pre') {
							navigate('/consultant-config/pre-consultant', {
								state: { index: 0 },
							});
						} else {
							navigate('/consultant-config/post-consultant', {
								state: { index: 0 },
							});
						}
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch((err) => {
					throw err;
				});
		} else {
			const updatePayloadData = {
				id: get(rowData, '_id', ''),
				data: payloadData,
			};

			dispatch(updateQuestion(updatePayloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Question updated successfully.'));
						if (type === 'pre') {
							navigate('/consultant-config/pre-consultant');
						} else {
							navigate('/consultant-config/post-consultant');
						}
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch((err) => {
					throw err;
				});
		}
	};

	useEffect(() => {
		dispatch(getLanguageConfigList());
		dispatch(getSpecializationData());
	}, [dispatch]);

	return (
		<MDCard>
			<MDCardHeader title='Create Question' />
			<CardContent>
				<TimeOutComponent delay={2} isEmpty={isEmpty(languageList)} px={1} pt={1}>
					{specializationList.length > 0 && (
						<Formik
							initialValues={{
								questionTitle: get(rowData, 'questionTitle', ''),
								languages: languageInitialValue(languageList, get(rowData, 'languages', false), 'common'),
								categories: getInitSpecialization(rowData, specializationList, 'categories'),
								subCategories: getInitSubSpecialization(
									rowData,
									specializationList,
									'categories',
									'subCategories'
								),
								inputType: get(rowData, 'inputType', ''),
								options: get(rowData, 'options', [
									{
										label: '',
										value: '',
										languages: languageInitialValue(languageList, get(rowData, 'languages', false), 'common'),
										subQuestionStatus: false,
										subQuestions: [
											{
												questionTitle: '',
												inputType: '',
												languages: languageInitialValue(
													languageList,
													get(rowData, 'languages', false),
													'common'
												),
												options: [
													{
														label: '',
														value: '',
														languages: languageInitialValue(
															languageList,
															get(rowData, 'languages', false),
															'common'
														),
													},
												],
												minValue: undefined,
												maxValue: undefined,
												stepValue: undefined,
											},
										],
									},
								]),
								minValue: get(rowData, 'minValue', undefined),
								maxValue: get(rowData, 'maxValue', undefined),
								stepValue: get(rowData, 'stepValue', undefined),
							}}
							validationSchema={ValidationSchema}
							onSubmit={(values) => {
								handleSubmit(values);
							}}
						>
							{(formProps) => (
								<form onSubmit={formProps.handleSubmit}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												fullWidth={true}
												multiline={true}
												size='small'
												label='Question Title'
												name='questionTitle'
												value={formProps.values.questionTitle}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={
													formProps.errors.questionTitle && formProps.touched.questionTitle
														? true
														: false
												}
												helperText={
													formProps.errors.questionTitle && formProps.touched.questionTitle
														? formProps.errors.questionTitle
														: null
												}
											/>
										</Grid>

										<Grid item xs={12}>
											<LanguageTranslationForm
												fieldName='languages'
												formProps={formProps}
												languageConfigList={languageList}
												inputField='text-input'
											/>
										</Grid>

										<Grid item xs={12}>
											<MultiSelectSpecialization
												specializations={specializationList}
												fieldName='categories'
												subFieldName='subCategories'
												formProps={formProps}
												initialData={rowData}
												isSubmit={false}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												size='small'
												fullWidth={true}
												select={true}
												id='input-type-select'
												label='Input Type'
												name='inputType'
												value={formProps.values.inputType}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={formProps.errors.inputType && formProps.touched.inputType ? true : false}
												helperText={
													formProps.errors.inputType && formProps.touched.inputType
														? formProps.errors.inputType
														: null
												}
											>
												{map(InputTypeList, (option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</TextField>
										</Grid>

										{/* ------------------RADIO/CHECKBOX--------------------- */}

										{formProps.values.inputType === 'radio' || formProps.values.inputType === 'checkbox' ? (
											<Fade in={true}>
												<Grid item xs={12}>
													<FieldArray name='options'>
														{({ remove, push }) => (
															<MDCard variant='outlined'>
																<Stack
																	direction='row'
																	justifyContent='space-between'
																	className='py-2 px-3 border-bottom'
																>
																	<Typography
																		variant='body1'
																		fontSize={{ md: 18, xs: 16 }}
																		fontWeight={500}
																		textTransform='capitalize'
																	>
																		Options {formProps.values.inputType}
																	</Typography>
																	<MDButton
																		label='Add Option'
																		onClick={() =>
																			push({
																				label: '',
																				value: '',
																				languages: languageInitialValue(
																					languageList,
																					get(rowData, 'languages', false),
																					'common'
																				),
																				subQuestionStatus: false,
																				subQuestions: [
																					{
																						questionTitle: '',
																						inputType: '',
																						languages: languageInitialValue(
																							languageList,
																							get(rowData, 'languages', false),
																							'common'
																						),
																						options: [
																							{
																								label: '',
																								value: '',
																								languages: languageInitialValue(
																									languageList,
																									get(
																										rowData,
																										'languages',
																										false
																									),
																									'common'
																								),
																							},
																						],
																						minValue: undefined,
																						maxValue: undefined,
																						stepValue: undefined,
																					},
																				],
																			})
																		}
																		variant='contained'
																		padding='3px 12px'
																		fontSize='15px'
																	/>
																</Stack>
																{/* ----------------INPUT TYPE------------------ */}
																<CardContent>
																	<TransitionGroup>
																		{map(
																			formProps.values.options,
																			(optionItem, optionIndex, { length }) => {
																				const optionLabel = `options[${optionIndex}].label`;
																				const errorOptionLabel = getIn(
																					formProps.errors,
																					optionLabel
																				);
																				const touchedOptionLabel = getIn(
																					formProps.touched,
																					optionLabel
																				);
																				const optionValue = `options[${optionIndex}].value`;
																				const errorOptionValue = getIn(
																					formProps.errors,
																					optionValue
																				);
																				const touchedOptionValue = getIn(
																					formProps.touched,
																					optionValue
																				);
																				const subQuestionStatus = `options[${optionIndex}].subQuestionStatus`;

																				const languages = `options[${optionIndex}].languages`;
																				const isNotLastIndex =
																					optionIndex !== length - 1 ? true : false;

																				return (
																					<Collapse key={optionIndex}>
																						<Grid
																							container
																							spacing={1}
																							sx={{
																								mb: isNotLastIndex ? 1.5 : 0,
																							}}
																						>
																							<Grid item sm={4} xs={12}>
																								<TextField
																									fullWidth={true}
																									multiline={true}
																									size='small'
																									label='Option Label'
																									name={optionLabel}
																									value={optionItem?.label}
																									onChange={
																										formProps.handleChange
																									}
																									onBlur={formProps.handleBlur}
																									helperText={
																										errorOptionLabel &&
																										touchedOptionLabel
																											? errorOptionLabel
																											: null
																									}
																									error={Boolean(
																										errorOptionLabel &&
																											touchedOptionLabel
																									)}
																								/>
																							</Grid>
																							<Grid item sm={4} xs={12}>
																								<TextField
																									fullWidth={true}
																									multiline={true}
																									size='small'
																									label='Option Value'
																									name={optionValue}
																									value={optionItem?.value}
																									onChange={
																										formProps.handleChange
																									}
																									onBlur={formProps.handleBlur}
																									helperText={
																										errorOptionValue &&
																										touchedOptionValue
																											? errorOptionValue
																											: null
																									}
																									error={Boolean(
																										errorOptionValue &&
																											touchedOptionValue
																									)}
																								/>
																							</Grid>
																							<Grid item sm={3} xs={6}>
																								<FormGroup className='w-max-content'>
																									<FormControlLabel
																										label='Sub Question'
																										name={subQuestionStatus}
																										value={
																											optionItem?.subQuestionStatus
																										}
																										checked={
																											optionItem?.subQuestionStatus
																										}
																										onChange={(e) => {
																											formProps.setFieldValue(
																												subQuestionStatus,
																												e.target.checked
																											);
																										}}
																										control={
																											<Checkbox
																												inputProps={{
																													'aria-label':
																														'sub-question-check',
																												}}
																											/>
																										}
																									/>
																								</FormGroup>
																							</Grid>

																							{optionIndex !== 0 && (
																								<Grid
																									item
																									sm={1}
																									xs={2}
																									sx={{
																										textAlign: 'end',
																									}}
																								>
																									<IconButton
																										type='button'
																										color='error'
																										onClick={() =>
																											remove(optionIndex)
																										}
																									>
																										<RemoveCircleOutlineOutlinedIcon />
																									</IconButton>
																								</Grid>
																							)}

																							{languages && (
																								<Grid item sm={8} xs={12}>
																									<QandALanguageTranslationForm
																										fieldName={languages}
																										section='QUESTION_OPTIONs'
																										formProps={formProps}
																										optionIndex={optionIndex}
																										languageConfigList={
																											languageList
																										}
																										inputField='text-input'
																									/>
																								</Grid>
																							)}

																							{/* ======================================== */}

																							{isNotLastIndex ? (
																								<Grid item xs={12}>
																									<Divider
																										light={true}
																										flexItem
																									/>
																								</Grid>
																							) : null}
																						</Grid>
																					</Collapse>
																				);
																			}
																		)}
																	</TransitionGroup>
																</CardContent>
															</MDCard>
														)}
													</FieldArray>
												</Grid>
											</Fade>
										) : null}
										{formProps.values.inputType === 'scale' ? (
											<Fade in={true}>
												<Grid item xs={12}>
													<Box className='mb-3 px-2'>
														<Typography variant='body1' fontWeight={500}>
															Scale Values
														</Typography>
													</Box>
													<Box>
														<Grid
															container
															spacing={2}
															sx={{
																mb: 1,
															}}
														>
															<Grid item sm={4} xs={12}>
																<TextField
																	fullWidth
																	size='small'
																	label='Minimum Value'
																	type='number'
																	name='minValue'
																	value={formProps.values.minValue}
																	onChange={formProps.handleChange}
																	onBlur={formProps.handleBlur}
																	helperText={
																		formProps.errors.minValue && formProps.touched.minValue
																			? formProps.errors.minValue
																			: null
																	}
																	error={Boolean(
																		formProps.errors.minValue && formProps.touched.minValue
																	)}
																/>
															</Grid>
															<Grid item sm={4} xs={12}>
																<TextField
																	fullWidth
																	size='small'
																	label='Maximum Value'
																	type='number'
																	name='maxValue'
																	value={formProps.values.maxValue}
																	onChange={formProps.handleChange}
																	onBlur={formProps.handleBlur}
																	helperText={
																		formProps.errors.maxValue && formProps.touched.maxValue
																			? formProps.errors.maxValue
																			: null
																	}
																	error={Boolean(
																		formProps.errors.maxValue && formProps.touched.maxValue
																	)}
																/>
															</Grid>
															<Grid item sm={4} xs={12}>
																<TextField
																	fullWidth
																	size='small'
																	label='Step Value'
																	type='number'
																	name='stepValue'
																	value={formProps.values.stepValue}
																	onChange={formProps.handleChange}
																	onBlur={formProps.handleBlur}
																	helperText={
																		formProps.errors.stepValue && formProps.touched.stepValue
																			? formProps.errors.stepValue
																			: null
																	}
																	error={Boolean(
																		formProps.errors.stepValue && formProps.touched.stepValue
																	)}
																/>
															</Grid>
														</Grid>
													</Box>
												</Grid>
											</Fade>
										) : null}

										{/* ------------SUB QUESTIONS-------------- */}

										{map(formProps.values.options, (option, optionIndex) => {
											return option.subQuestionStatus && formProps.values.inputType !== 'text' ? (
												<Fade in={true} key={optionIndex}>
													<Grid item xs={12}>
														<FieldArray name={`options.${optionIndex}.subQuestions`}>
															{({ remove, push }) => (
																<MDCard variant='outlined'>
																	<Box className='px-3 py-2 border-bottom'>
																		<Stack
																			direction='row'
																			alignItems='center'
																			justifyContent='space-between'
																			spacing={2}
																		>
																			<Typography
																				variant='body1'
																				fontSize={{ md: 18, xs: 16 }}
																				fontWeight={500}
																			>
																				Sub Questions Of Option
																				{' ' + option.label}
																			</Typography>
																			<MDButton
																				label='Add Question'
																				onClick={() =>
																					push({
																						questionTitle: '',
																						inputType: '',
																						languages: languageInitialValue(
																							languageList,
																							get(rowData, 'languages', false),
																							'common'
																						),
																						options: [
																							{
																								label: '',
																								value: '',
																								languages: languageInitialValue(
																									languageList,
																									get(
																										rowData,
																										'languages',
																										false
																									),
																									'common'
																								),
																							},
																						],
																						minValue: undefined,
																						maxValue: undefined,
																						stepValue: undefined,
																					})
																				}
																				variant='contained'
																				padding='4px 12px'
																				fontSize='15px'
																			/>
																		</Stack>
																	</Box>
																	<CardContent>
																		<TransitionGroup>
																			{map(
																				option.subQuestions,
																				(
																					subQuestionField,
																					subQuestionFieldIndex,
																					{ length }
																				) => {
																					const questionTitle = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].questionTitle`;
																					const errorQuestionTitle = getIn(
																						formProps.errors,
																						questionTitle
																					);
																					const touchedQuestionTitle = getIn(
																						formProps.touched,
																						questionTitle
																					);

																					const languages = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].languages`;

																					const inputType = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].inputType`;
																					const errorInputType = getIn(
																						formProps.errors,
																						inputType
																					);
																					const touchedInputType = getIn(
																						formProps.touched,
																						inputType
																					);

																					const minValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].minValue`;
																					const errorMinValue = getIn(
																						formProps.errors,
																						minValue
																					);
																					const touchedMinValue = getIn(
																						formProps.touched,
																						minValue
																					);

																					const maxValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].maxValue`;
																					const errorMaxValue = getIn(
																						formProps.errors,
																						maxValue
																					);
																					const touchedMaxValue = getIn(
																						formProps.touched,
																						maxValue
																					);

																					const stepValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].stepValue`;
																					const errorStepValue = getIn(
																						formProps.errors,
																						stepValue
																					);
																					const touchedStepValue = getIn(
																						formProps.touched,
																						stepValue
																					);

																					const isNotLastIndex =
																						subQuestionFieldIndex !== length - 1
																							? true
																							: false;

																					return (
																						<Collapse key={subQuestionFieldIndex}>
																							<Grid container spacing={2}>
																								<Grid
																									item
																									sm={11}
																									xs={12}
																									order={{
																										sm: 0,
																										xs: 1,
																									}}
																									container
																									spacing={2}
																								>
																									<Grid item xs={12}>
																										<TextField
																											fullWidth={true}
																											multiline={true}
																											size='small'
																											label='Question Title'
																											name={questionTitle}
																											value={
																												subQuestionField.questionTitle
																											}
																											onChange={
																												formProps.handleChange
																											}
																											onBlur={
																												formProps.handleBlur
																											}
																											error={
																												errorQuestionTitle &&
																												touchedQuestionTitle
																													? true
																													: false
																											}
																											helperText={
																												errorQuestionTitle &&
																												touchedQuestionTitle
																													? errorQuestionTitle
																													: null
																											}
																										/>
																									</Grid>
																									{languages && (
																										<Grid item xs={12}>
																											<QandALanguageTranslationForm
																												fieldName={
																													languages
																												}
																												section='SUB_QUESTION'
																												subQuestionFieldIndex={
																													subQuestionFieldIndex
																												}
																												formProps={
																													formProps
																												}
																												optionIndex={
																													optionIndex
																												}
																												languageConfigList={
																													languageList
																												}
																												inputField='text-input'
																											/>
																										</Grid>
																									)}
																									<Grid item xs={12}>
																										<TextField
																											size='small'
																											fullWidth={true}
																											select={true}
																											id='input-type-select'
																											label='Input Type'
																											name={inputType}
																											value={
																												subQuestionField.inputType
																											}
																											onChange={
																												formProps.handleChange
																											}
																											onBlur={
																												formProps.handleBlur
																											}
																											error={
																												errorInputType &&
																												touchedInputType
																													? true
																													: false
																											}
																											helperText={
																												errorInputType &&
																												touchedInputType
																													? errorInputType
																													: null
																											}
																										>
																											{InputTypeList.map(
																												(option) => (
																													<MenuItem
																														key={
																															option.value
																														}
																														value={
																															option.value
																														}
																													>
																														{
																															option.label
																														}
																													</MenuItem>
																												)
																											)}
																										</TextField>
																									</Grid>
																									{subQuestionField.inputType ===
																										'radio' ||
																									subQuestionField.inputType ===
																										'checkbox' ? (
																										<Grid item xs={12}>
																											<FieldArray
																												name={`options.${optionIndex}.subQuestions.${subQuestionFieldIndex}.options`}
																											>
																												{({
																													remove,
																													push,
																												}) => (
																													<MDCard variant='outlined'>
																														<Box className='px-2 py-2 border-bottom'>
																															<Stack
																																direction='row'
																																alignItems='center'
																																justifyContent='space-between'
																																spacing={
																																	2
																																}
																															>
																																<Typography
																																	variant='body1'
																																	fontSize={{
																																		sm: 18,
																																		xs: 15,
																																	}}
																																	fontWeight={
																																		500
																																	}
																																	textTransform='capitalize'
																																>
																																	{`Sub Question
                                                                    ${subQuestionField.inputType}
                                                                    Options`}
																																</Typography>
																																<MDButton
																																	label='Add Option'
																																	onClick={() =>
																																		push(
																																			{
																																				label: '',
																																				value: '',
																																				languages:
																																					languageInitialValue(
																																						languageList,
																																						get(
																																							rowData,
																																							'languages',
																																							false
																																						),
																																						'common'
																																					),
																																			}
																																		)
																																	}
																																	variant='contained'
																																	padding='2px 12px'
																																	fontSize='15px'
																																/>
																															</Stack>
																														</Box>
																														<CardContent>
																															<TransitionGroup>
																																{map(
																																	subQuestionField.options,
																																	(
																																		subQuestionFieldOption,
																																		subQuestionFieldOptionIndex,
																																		{
																																			length,
																																		}
																																	) => {
																																		const optionLabel = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].options[${subQuestionFieldOptionIndex}].label`;
																																		const errorOptionLabel =
																																			getIn(
																																				formProps.errors,
																																				optionLabel
																																			);
																																		const touchedOptionLabel =
																																			getIn(
																																				formProps.touched,
																																				optionLabel
																																			);

																																		const optionValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].options[${subQuestionFieldOptionIndex}].value`;
																																		const errorOptionValue =
																																			getIn(
																																				formProps.errors,
																																				optionValue
																																			);
																																		const touchedOptionValue =
																																			getIn(
																																				formProps.touched,
																																				optionValue
																																			);

																																		const languages = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].options[${subQuestionFieldOptionIndex}].languages`;

																																		const isNotLastIndex =
																																			subQuestionFieldOptionIndex !==
																																			length -
																																				1
																																				? true
																																				: false;

																																		return (
																																			<Collapse
																																				key={
																																					subQuestionFieldOptionIndex
																																				}
																																			>
																																				<Grid
																																					container
																																					spacing={
																																						1
																																					}
																																					sx={{
																																						mb: isNotLastIndex
																																							? 1.5
																																							: 0,
																																					}}
																																				>
																																					<Grid
																																						item
																																						sm={
																																							5.5
																																						}
																																						xs={
																																							12
																																						}
																																					>
																																						<TextField
																																							fullWidth={
																																								true
																																							}
																																							multiline={
																																								true
																																							}
																																							size='small'
																																							label='Option Label'
																																							name={
																																								optionLabel
																																							}
																																							value={
																																								subQuestionFieldOption.label
																																							}
																																							helperText={
																																								errorOptionLabel &&
																																								touchedOptionLabel
																																									? errorOptionLabel
																																									: null
																																							}
																																							error={Boolean(
																																								errorOptionLabel &&
																																									touchedOptionLabel
																																							)}
																																							onChange={
																																								formProps.handleChange
																																							}
																																							onBlur={
																																								formProps.handleBlur
																																							}
																																						/>
																																					</Grid>
																																					<Grid
																																						item
																																						sm={
																																							5.5
																																						}
																																						xs={
																																							12
																																						}
																																					>
																																						<TextField
																																							fullWidth={
																																								true
																																							}
																																							multiline={
																																								true
																																							}
																																							size='small'
																																							label='Option Value'
																																							name={
																																								optionValue
																																							}
																																							value={
																																								subQuestionFieldOption.value
																																							}
																																							helperText={
																																								errorOptionValue &&
																																								touchedOptionValue
																																									? errorOptionValue
																																									: null
																																							}
																																							error={Boolean(
																																								errorOptionValue &&
																																									touchedOptionValue
																																							)}
																																							onChange={
																																								formProps.handleChange
																																							}
																																							onBlur={
																																								formProps.handleBlur
																																							}
																																						/>
																																					</Grid>
																																					<Grid
																																						item
																																						sm={
																																							1
																																						}
																																						xs={
																																							12
																																						}
																																						sx={{
																																							textAlign:
																																								{
																																									sm: 'center',
																																								},
																																						}}
																																					>
																																						<IconButton
																																							type='button'
																																							color='error'
																																							onClick={() =>
																																								remove(
																																									subQuestionFieldOptionIndex
																																								)
																																							}
																																						>
																																							<RemoveCircleOutlineOutlinedIcon />
																																						</IconButton>
																																					</Grid>
																																					{languages && (
																																						<Grid
																																							item
																																							xs={
																																								12
																																							}
																																						>
																																							<QandALanguageTranslationForm
																																								fieldName={
																																									languages
																																								}
																																								section='SUB_QUESTION_OPTIONS'
																																								subQuestionFieldOptionIndex={
																																									subQuestionFieldOptionIndex
																																								}
																																								subQuestionFieldIndex={
																																									subQuestionFieldIndex
																																								}
																																								formProps={
																																									formProps
																																								}
																																								optionIndex={
																																									optionIndex
																																								}
																																								languageConfigList={
																																									languageList
																																								}
																																								inputField='text-input'
																																							/>
																																						</Grid>
																																					)}
																																				</Grid>
																																			</Collapse>
																																		);
																																	}
																																)}
																															</TransitionGroup>
																														</CardContent>
																													</MDCard>
																												)}
																											</FieldArray>
																										</Grid>
																									) : null}
																									{subQuestionField.inputType ===
																									'scale' ? (
																										<Grid item xs={12}>
																											<Box className='mb-2 px-2'>
																												<Typography
																													variant='body1'
																													fontWeight={
																														500
																													}
																												>
																													Scale Values
																												</Typography>
																											</Box>
																											<Box>
																												<Grid
																													container
																													spacing={2}
																													sx={{
																														alignItems:
																															'center',
																														mb: 1,
																													}}
																												>
																													<Grid
																														item
																														sm={4}
																														xs={12}
																													>
																														<TextField
																															fullWidth
																															size='small'
																															label='Minimum Value'
																															type='number'
																															name={
																																minValue
																															}
																															value={
																																subQuestionField.minValue
																															}
																															onChange={
																																formProps.handleChange
																															}
																															onBlur={
																																formProps.handleBlur
																															}
																															helperText={
																																errorMinValue &&
																																touchedMinValue
																																	? errorMinValue
																																	: null
																															}
																															error={Boolean(
																																errorMinValue &&
																																	touchedMinValue
																															)}
																														/>
																													</Grid>
																													<Grid
																														item
																														sm={4}
																														xs={12}
																													>
																														<TextField
																															fullWidth
																															size='small'
																															label='Maximum Value'
																															type='number'
																															name={
																																maxValue
																															}
																															value={
																																subQuestionField.maxValue
																															}
																															onChange={
																																formProps.handleChange
																															}
																															onBlur={
																																formProps.handleBlur
																															}
																															helperText={
																																errorMaxValue &&
																																touchedMaxValue
																																	? errorMaxValue
																																	: null
																															}
																															error={Boolean(
																																errorMaxValue &&
																																	touchedMaxValue
																															)}
																														/>
																													</Grid>
																													<Grid
																														item
																														sm={4}
																														xs={12}
																													>
																														<TextField
																															fullWidth
																															size='small'
																															label='Step Value'
																															type='number'
																															name={
																																stepValue
																															}
																															value={
																																subQuestionField.stepValue
																															}
																															onChange={
																																formProps.handleChange
																															}
																															onBlur={
																																formProps.handleBlur
																															}
																															helperText={
																																errorStepValue &&
																																touchedStepValue
																																	? errorStepValue
																																	: null
																															}
																															error={Boolean(
																																errorStepValue &&
																																	touchedStepValue
																															)}
																														/>
																													</Grid>
																												</Grid>
																											</Box>
																										</Grid>
																									) : null}
																								</Grid>
																								<Grid
																									item
																									sm={1}
																									xs={12}
																									textAlign='end'
																									order={{
																										sm: 1,
																										xs: 0,
																									}}
																								>
																									<IconButton
																										type='button'
																										color='error'
																										onClick={() =>
																											remove(
																												subQuestionFieldIndex
																											)
																										}
																									>
																										<DeleteOutlineOutlinedIcon />
																									</IconButton>
																								</Grid>
																								{isNotLastIndex ? (
																									<Grid
																										item
																										xs={12}
																										sx={{
																											pb: 2,
																										}}
																										order={2}
																									>
																										<Divider light />
																									</Grid>
																								) : null}
																							</Grid>
																						</Collapse>
																					);
																				}
																			)}
																		</TransitionGroup>
																	</CardContent>
																</MDCard>
															)}
														</FieldArray>
													</Grid>
												</Fade>
											) : null;
										})}
										<Grid item xs={12} container spacing={2} className='mt-3 mb-3'>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='reset'
													variant='outlined'
													padding='6px 24px'
													fullWidth={true}
													onClick={(_event) => {
														formProps.resetForm();
													}}
												>
													Reset
												</MDLoadingButton>
											</Grid>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='submit'
													variant='contained'
													padding='6px 24px'
													fullWidth={true}
													loading={formProps.isSubmitting && isLoading}
												>
													Submit
												</MDLoadingButton>
											</Grid>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					)}
				</TimeOutComponent>
			</CardContent>
		</MDCard>
	);
};

export default QAndAForm;
