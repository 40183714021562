import AxiosDefault from "services/AxiosDefaultSetting";

export const GetDietPlanList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/diet-plan/list`,
    data: data,
  });
  return response;
};

export const GetDietPlanDetails = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/diet-plan/details/${id}`,
    data: data,
  });
  return response;
};

export const CreateUpdateDietPlan = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/diet-plan/add-update`,
    data: data,
  });
  return response;
};

export const ArchiveDietPlan = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/health-food/diet-plan/archive/${id}`,
    data: data,
  });
  return response;
};

export const DeleteDietPlan = async (id) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/health-food/diet-plan/delete/${id}`,
  });
  return response;
};
