import { Add, Remove } from '@mui/icons-material';
import { Divider, FormControl, FormLabel, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import RichTextEditor from 'components/utility/rich-text-editor/RichTextEditor';
import { FieldArray, getIn } from 'formik';
import React, { useState } from 'react';

function PoliciesDetails({ policy, index, props }) {
	const [toggleButton, setToggleButton] = useState(false);

	return (
		<React.Fragment key={index}>
			<Grid item xs={12} mt={2}>
				<Divider orientation='horizontal' flexItem />
			</Grid>
			<Grid item xs={12}>
				<Stack direction='row' justifyContent='space-between' className='gap-1' mt={2}>
					<Typography variant='h6' className='mb-2'>
						{policy.policyName}
					</Typography>
					<IconButton onClick={() => setToggleButton((prev) => !prev)}>
						{toggleButton ? <Remove /> : <Add />}
					</IconButton>
				</Stack>
			</Grid>

			{toggleButton && (
				<FieldArray
					name={`policies.${index}.userType`}
					render={({ push, remove }) => (
						<>
							{props.values.policies[index].userType &&
								props.values.policies[index].userType.length > 0 &&
								props.values.policies[index].userType.map((item, idx, { length }) => {
									const description = `policies[${index}].userType[${idx}].description`;
									const errorContent = getIn(props?.errors, description);
									const touchedContent = getIn(props?.touched, description);
									// console.log('errorContent :>> ', errorContent);
									return (
										<Grid container spacing={2} key={idx} className={idx !== length - 1 ? 'mb-3' : ''}>
											<Grid item xs={12}>
												<FormControl className='mb-2'>
													<FormLabel id='demo-row-radio-buttons-group-label'>{item?.type}</FormLabel>
												</FormControl>
											</Grid>
											<Grid item xs={12}>
												<RichTextEditor
													id={`${item?.description}-value-item`}
													label='Description'
													name={`policies[${index}].userType[${idx}].description`}
													value={item?.description}
													setFieldValue={props.setFieldValue}
													onBlur={props?.handleBlur}
													error={errorContent && touchedContent ? true : false}
													helperText={errorContent && touchedContent ? errorContent : null}
												/>
											</Grid>
										</Grid>
									);
								})}
						</>
					)}
				/>
			)}
		</React.Fragment>
	);
}

export default PoliciesDetails;
