import axios from 'axios';
import { get, isEmpty } from 'lodash';
import cookie, { cookieGet, cookieSet } from 'components/hooks/cookie';

const AxiosDefaultSetting = async ({ method, url, data, contentType, ...props }) => {
	// const dispatch = useDispatch();
	const APIENDPOINT = process.env.REACT_APP_API_END_POINT;

	const AxiosDefault = axios.create({
		baseURL: APIENDPOINT,
		// timeout: 5000,
		headers: {
			'Content-Type': isEmpty(contentType) ? 'application/json' : contentType,
			Accept: 'application/json',
			'ngrok-skip-browser-warning': true,
		},
	});

	AxiosDefault.interceptors.request.use(
		async function (config) {
			try {
				const authToken = cookieGet('auth_token');
				config.headers.authorization = !isEmpty(authToken) ? authToken : null;
				// if (isString(authToken) && !isEmpty(authToken)) {
				//   config.headers.authorization = authToken;
				// }
			} catch (error) {
				// console.log(error);
			}
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	AxiosDefault.interceptors.response.use(
		function (response) {
			return response;
		},
		async function (error) {
			try {
				if (get(error, 'response.status', 0) === 401) {
					cookie.remove('auth_token');
					cookie.remove('role_type');
					cookie.remove('selectedMenuId');
					localStorage.clear();
					sessionStorage.clear();
					cookieSet('auth_token', '');
					window.location.replace('/');
					window.location = '/';
				}
			} catch (e) {
				return e;
			}
			return Promise.reject(error);
		}
	);

	return await AxiosDefault({
		method,
		url,
		data,
		contentType,
		...props,
	});
};

export default AxiosDefaultSetting;
