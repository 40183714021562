import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateUpdatePressRelease,
  DeletePressRelease,
  GetPressReleaseList,
  GetPressReleaseNonApprovedList,
  ImageUploadPressRelease,
  PublishPressRelease,
  UpdateApprovePressRelease,
} from "api/press-release/PressAPI";

import { get } from "lodash";

const initialState = {
  pressReleaseList: [],
  nonApprovePressList: [],
  loading: false,
};

export const getPressReleaseList = createAsyncThunk(
  "press/getPressReleaseList",
  async (data) => {
    try {
      const response = await GetPressReleaseList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getPressReleaseNonApproveList = createAsyncThunk(
  "press/getPressReleaseNonApproveList",
  async (data) => {
    try {
      const response = await GetPressReleaseNonApprovedList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const updateApprovePressRelease = createAsyncThunk(
  "press/updateApprovePressRelease",
  async (data) => {
    try {
      const response = await UpdateApprovePressRelease(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const imageUploadPressRelease = createAsyncThunk(
  "press/imageUploadPressRelease",
  async (data) => {
    try {
      const response = await ImageUploadPressRelease(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const createUpdatePressRelease = createAsyncThunk(
  "press/createUpdatePressRelease",
  async (data) => {
    try {
      const response = await CreateUpdatePressRelease(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const publishPressRelease = createAsyncThunk(
  "press/publishPressRelease",
  async (data) => {
    try {
      const response = await PublishPressRelease(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const deletePressRelease = createAsyncThunk(
  "press/deletePressRelease",
  async (data) => {
    try {
      const response = await DeletePressRelease(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const PressReleaseSlice = createSlice({
  name: "press",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPressReleaseList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPressReleaseList.fulfilled, (state, { payload }) => {
        state.pressReleaseList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getPressReleaseList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPressReleaseNonApproveList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPressReleaseNonApproveList.fulfilled,
        (state, { payload }) => {
          state.nonApprovePressList = get(payload, "data.list", []);
          state.loading = false;
        }
      )
      .addCase(getPressReleaseNonApproveList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateApprovePressRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateApprovePressRelease.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateApprovePressRelease.rejected, (state) => {
        state.loading = false;
      })
      .addCase(imageUploadPressRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(imageUploadPressRelease.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(imageUploadPressRelease.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUpdatePressRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUpdatePressRelease.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUpdatePressRelease.rejected, (state) => {
        state.loading = false;
      })
      .addCase(publishPressRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(publishPressRelease.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(publishPressRelease.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deletePressRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePressRelease.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deletePressRelease.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default PressReleaseSlice.reducer;
