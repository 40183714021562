import React from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";

import { Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import { toast } from "react-toastify";

import { updateConsultantPublicationOrLink } from "store/slice/user-management/ConsultantProfileSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";
import MDDialog from "components/md-components/dialog/MDDialog";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";

const PublicationOrLinkForm = (props) => {
  const { dialogOpen, handleDialogClose, dialogData, userDetails, userType } =
    props;
  const dispatch = useDispatch();

  const formValidations = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    publicationOrPublisher: Yup.string().required(
      "Publication or Publisher is required"
    ),
    credentialUrl: Yup.string().required("Credential Url is required"),
    issueDate: Yup.string().required("Issue Date is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = (values) => {
    const rowData =
      get(dialogData, "formType", "") === "update"
        ? { ...values, _id: get(dialogData, "data._id") }
        : values;
    const apiData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: rowData,
    };
    dispatch(updateConsultantPublicationOrLink(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(
            get(result, "message", "Publication or link update successfully")
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={get(dialogData, "title", "License Or Certificate")}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          title: get(dialogData, "data.title", ""),
          publicationOrPublisher: get(
            dialogData,
            "data.publicationOrPublisher",
            ""
          ),
          credentialUrl: get(dialogData, "data.credentialUrl", ""),
          issueDate: get(dialogData, "data.issueDate", ""),
          description: get(dialogData, "data.description", ""),
        }}
        validationSchema={formValidations}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="title"
                    label="Title"
                    name="title"
                    value={props.values.title}
                    error={
                      props.errors.title && props.touched.title ? true : false
                    }
                    helperText={
                      props.errors.title && props.touched.title
                        ? props.errors.title
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="publicationOrPublisher"
                    label="Publication/Publisher"
                    name="publicationOrPublisher"
                    value={props.values.publicationOrPublisher}
                    error={
                      props.errors.publicationOrPublisher &&
                      props.touched.publicationOrPublisher
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.publicationOrPublisher &&
                      props.touched.publicationOrPublisher
                        ? props.errors.publicationOrPublisher
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="credentialUrl"
                    label="Credential Url"
                    name="credentialUrl"
                    value={props.values.credentialUrl}
                    error={
                      props.errors.credentialUrl && props.touched.credentialUrl
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.credentialUrl && props.touched.credentialUrl
                        ? props.errors.credentialUrl
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MobileDatePicker
                    label="Issue Date"
                    name="issueDate"
                    value={props.values.issueDate}
                    onChange={(newValue) => {
                      const newDate = newValue.format("YYYY/MM/DD");
                      props.setFieldValue("issueDate", newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          props.errors.issueDate && props.touched.issueDate
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.issueDate && props.touched.issueDate
                            ? props.errors.issueDate
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle-start-date-datepicker"
                                edge="end"
                                onClick={params.inputProps.onClick}
                              >
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    value={props.values.description}
                    error={
                      props.errors.description && props.touched.description
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.description && props.touched.description
                        ? props.errors.description
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    multiline={true}
                    rows={3}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                loading={props.isSubmitting}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default PublicationOrLinkForm;
