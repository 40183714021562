import { filter, get, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";

import MDButton from "components/md-components/button/MDButton";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import DataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import MDSwitch from "components/md-components/switch/MDSwitch";

import {
  getIdentityProofList,
  statusUpdateIdentityProof,
} from "store/slice/country-profile/IdentityProofSlice";

const CountryIdentityProofList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countryProfileId = get(location, "state.countryProfileId", "");
  const countryId = get(location, "state.countryId", "");

  const isLoading = useSelector((state) => state.countryIdentityProof.loading);
  const identityProofList = useSelector(
    (state) => state.countryIdentityProof.list
  );

  const [itemId, setItemId] = useState("");

  const filterIdentityProofList = filter(identityProofList, function (item) {
    return item.isDeleted !== true;
  });

  const handleStatusUpdate = useCallback(
    (event, row) => {
      const apiData = {
        countryProfileId: countryProfileId,
        data: {
          _id: row?._id,
          status: event.target.checked,
        },
      };
      dispatch(statusUpdateIdentityProof(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getIdentityProofList({ id: countryId }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [countryId, countryProfileId, dispatch]
  );

  useEffect(() => {
    dispatch(getIdentityProofList({ countryId: countryId }));
  }, [countryId, dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        width: 240,
        editable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: ({ row }) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate("/country-profile/identity-proof-create-update", {
                    state: {
                      identityProofData: row,
                      countryProfileId: countryProfileId,
                      countryId: countryId,
                      formType: "update",
                    },
                  });
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={row?.status}
                onChange={(event) => {
                  setItemId(row?._id);
                  handleStatusUpdate(event, row);
                }}
                inputProps={{ "aria-label": row?._id, role: "switch" }}
                color="success"
                disabled={itemId === row?._id && isLoading}
              />
            </Box>
          );
        },
      },
    ],
    [
      countryId,
      countryProfileId,
      handleStatusUpdate,
      isLoading,
      itemId,
      navigate,
    ]
  );

  const CustomToolbar = () => {
    return (
      <DataGridToolbar align="right" divider={true}>
        <MDButton
          variant="contained"
          label={`Create Identity`}
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            navigate("/country-profile/identity-proof-create-update", {
              state: {
                identityProofData: [],
                countryProfileId: countryProfileId,
                countryId: countryId,
                formType: "create",
              },
            });
          }}
        />
      </DataGridToolbar>
    );
  };

  return (
    <MDCard>
      <MDCardHeader title="Identity Proof List" />
      <CardContent className="h-100">
        <Grid container spacing={0} className="h-100">
          <Grid item xs={12}>
            <MDDataGrid
              rows={
                !isEmpty(filterIdentityProofList) ? filterIdentityProofList : []
              }
              columns={columnsData}
              getRowId={(row) => row?._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default CountryIdentityProofList;
