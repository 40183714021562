import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, find, get, isEmpty, map, capitalize } from 'lodash';
// MUI
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// Custom
import MDAccordion from 'components/md-components/accordion/MDAccordion';
import MDAccordionDetails from 'components/md-components/accordion/MDAccordionDetails';
import MDAccordionSummary from 'components/md-components/accordion/MDAccordionSummary';

const MultiSelectSpecialization = (props) => {
	const { specializations, formProps, fieldName, subFieldName, isDefaultSEOmetaTag = false, label } = props;

	const [expanded, setExpanded] = useState(false);
	const handleChange = (panel) => (_event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleSubSpecialization = (child, checked, subFieldName, formProps) => {
		if (checked) {
			formProps.setFieldValue(subFieldName, [
				...formProps.values[subFieldName],
				{
					title: get(child, 'title', ''),
					foreignId: get(child, '_id', ''),
					foreignData: get(child, '_id', ''),
					parentId: get(child, 'parentId', ''),
				},
			]);
		} else {
			formProps.setFieldValue(
				subFieldName,
				filter(formProps.values[subFieldName], (item) => get(item, 'foreignId', '') !== get(child, '_id', ''))
			);
		}
	};
	const subSpecExist = (arr, id) => {
		if (!isEmpty(arr)) {
			return arr.some(function (el) {
				return get(el, 'foreignId', '') === id;
			});
		}
		return false;
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Autocomplete
					options={specializations || []}
					getOptionLabel={(option) => get(option, 'title', '') ?? ''}
					isOptionEqualToValue={(option, value) => get(option, '_id', '') === get(value, '_id', '')}
					id='specialization'
					name={fieldName}
					value={formProps.values[fieldName]}
					onChange={(_event, value) => {
						formProps.setFieldValue(fieldName, value);
						isDefaultSEOmetaTag &&
							formProps.setFieldValue(
								'seoMetaTags',
								value?.map((item) => item?.title)
							);
					}}
					fullWidth={true}
					autoHighlight={true}
					multiple={true}
					size='small'
					renderInput={(params) => (
						<TextField
							{...params}
							label={capitalize(label ? label : fieldName)}
							error={formProps.errors[fieldName] && formProps.touched[fieldName] ? true : false}
							helperText={
								formProps.errors[fieldName] && formProps.touched[fieldName] ? formProps.errors[fieldName] : null
							}
							onBlur={formProps.handleBlur}
							inputProps={{
								...params.inputProps,
								autoComplete: [fieldName],
							}}
							sx={{ '& .MuiFormHelperText-root': { mx: 1 } }}
						/>
					)}
				/>
			</Grid>
			{!isEmpty(formProps.values[fieldName]) && (
				<Grid item xs={12} container spacing={0.5}>
					{map(formProps.values[fieldName], (item, index) => {
						return (
							<Grid item xs={12} key={index}>
								<MDAccordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
									<MDAccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel${index}bh-content`}
										id={`panel${index}bh-header`}
										size='small'
									>
										<Typography>{get(item, 'title', '')}</Typography>
									</MDAccordionSummary>
									<MDAccordionDetails>
										{map(get(item, 'child'), (subItem, index) => {
											return (
												<FormGroup key={index}>
													<FormControlLabel
														control={
															<Checkbox
																value={formProps.values[subFieldName]?.includes(
																	get(subItem, '_id', '')
																)}
																checked={subSpecExist(
																	formProps.values[subFieldName],
																	get(subItem, '_id', '')
																)}
																onChange={(e) =>
																	handleSubSpecialization(
																		subItem,
																		e.target.checked,
																		subFieldName,
																		formProps
																	)
																}
																sx={{
																	'&.Mui-checked': {
																		'&, & + .MuiFormControlLabel-label': {
																			color: 'primary.main',
																		},
																	},
																}}
															/>
														}
														label={get(subItem, 'title', '')}
													/>
												</FormGroup>
											);
										})}
									</MDAccordionDetails>
								</MDAccordion>
							</Grid>
						);
					})}
					{formProps.errors[subFieldName] && formProps.touched[subFieldName] ? (
						<Grid item xs={12}>
							<FormHelperText error sx={{ mx: 2 }}>
								{formProps.errors[subFieldName]}
							</FormHelperText>
						</Grid>
					) : null}
				</Grid>
			)}
		</Grid>
	);
};

MultiSelectSpecialization.propTypes = {
	specialization: PropTypes.array.isRequired,
	initialData: PropTypes.object.isRequired,
	formProps: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired,
};

export default MultiSelectSpecialization;

export const getInitSpecialization = (initialData, specialization, fieldName) => {
	return map(get(initialData, fieldName, []), (item) => {
		return find(specialization, {
			_id: get(item, 'foreignId', ''),
		});
	}).filter((e) => Boolean(e));
};

export const getInitSubSpecialization = (initialData, specialization, fieldName, subFieldName) => {
	let subSpec = [];
	map(getInitSpecialization(initialData, specialization, fieldName), (spec) => {
		map(get(initialData, fieldName, []), (initSpec) => {
			if (get(initSpec, 'foreignId', '') === get(spec, '_id', '')) {
				if (!isEmpty(initSpec)) {
					subSpec.push(...get(initSpec, subFieldName, ''));
				}
			}
			return;
		});
		return;
	});
	return subSpec;
};

export const getSpecializationSubmitData = (values, subSpecializationData, fieldName, subFieldName) => {
	const spc = [];
	const data = [];
	map(values[fieldName], (item) => {
		spc.push({
			foreignId: get(item, '_id', ''),
			foreignData: get(item, '_id', ''),
			child: [],
		});
	});
	if (!isEmpty(subSpecializationData)) {
		map(spc, (item) => {
			const subEl = [];
			map(subSpecializationData, (subItem) => {
				if (get(subItem, 'parentId', '') === get(item, 'foreignId', '')) {
					subEl.push({
						foreignId: subItem.foreignId,
						foreignData: subItem.foreignId,
						parentId: subItem.parentId,
					});
				}
			});
			data.push({
				...item,
				[subFieldName]: !isEmpty(subEl) ? subEl : [],
			});
		});
		return data;
	} else {
		return spc;
	}
};
