import { get } from "lodash";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import DietPlanDetail from "components/pages/health-food/diet-plan/DietPlanDetail";

const DietPlanDetailView = () => {
  const location = useLocation();
  return (
    <MDCard>
      <MDCardHeader title={`Health Food Diet Plan Detail View`} />
      <CardContent>
        <Box px={1} pb={2}>
          <DietPlanDetail
            dietPlanDetail={get(location, "state.dietPlanDetail")}
          />
        </Box>
      </CardContent>
    </MDCard>
  );
};

export default DietPlanDetailView;
