import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteConfirmDialog from 'components/common/DeleteConfirmDialog';
import MDButton from 'components/md-components/button/MDButton';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import DataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import MDSwitch from 'components/md-components/switch/MDSwitch';
import GeneralConfigForm from './GeneralConfigForm';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';

const GeneralConfigList = (props) => {
	const { title, handleDialogOpen, sectionName } = props;
	const [state, setState] = useState([]);
	const [loading, setLoading] = useState(false);

	const [dialogData, setDialogData] = useState({
		id: '',
		title: '',
		rowData: [],
		formType: '',
	});
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const handleDeleteConfirmation = useCallback(() => {
		setDeleteConfirmation(true);
	}, []);
	const handleDeleteConfirmationClose = () => {
		setDeleteConfirmation(false);
	};

	async function getData() {
		setLoading(true);
		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `/admin/corporate-role/get?sectionName=${sectionName}`,
			});
			if (response.data.data) {
				setState(response.data.data);
			}
		} catch (error) {}
		setLoading(false);
	}
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateStatus = async (event, row) => {
		setLoading(true);
		setDialogData({ ...dialogData, id: row._id });

		const response = await AxiosDefaultSetting({
			method: 'PATCH',
			url: `/admin/corporate-role/status-update/${row._id}`,
			data: { status: event.target.checked },
		});
		const result = response.data;

		if (get(result, 'status', false) === true) {
			toast.success(get(result, 'message', 'Status update successful'));
		} else {
			toast.error(get(result, 'message', 'Something went wrong'));
		}

		setLoading(false);
		getData();
		handleDeleteConfirmationClose();
	};

	const handleDelete = async ({ event, id }) => {
		setLoading(true);

		try {
			const response = await AxiosDefaultSetting({
				method: 'DELETE',
				url: `/admin/corporate-role/delete/${id}`,
				data: { isDeleted: event },
			});
			const result = response.data;
			if (get(result, 'status', false) === true) {
				toast.success(get(result, 'message', 'Deleted successful'));
			} else {
				toast.error(get(result, 'message', 'Something went wrong'));
			}
		} catch (error) {}

		setLoading(false);
		getData();
		handleDeleteConfirmationClose();
	};

	const columnsData = [
		{
			field: 'title',
			headerName: 'Title',
			width: 240,
			editable: false,
		},
		{
			field: 'createdAt',
			headerName: 'Create Date',
			type: 'date',
			width: 150,
			editable: false,
			valueGetter: ({ row }) =>
				moment(get(row, 'createdAt', 'NA'), ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY'),
		},
		{
			field: 'actions',
			headerName: 'Actions',
			headerAlign: 'center',
			width: 240,
			renderCell: ({ row }) => {
				return (
					<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
						<IconButton
							aria-label='edit'
							color='primary'
							onClick={() => {
								setDialogData({
									...dialogData,
									id: row?._id,
									rowData: row,
									title: `${title} Update`,
									formType: 'update',
								});
								handleDialogOpen();
							}}
						>
							<EditIcon fontSize='14px' />
						</IconButton>
						<MDSwitch
							checked={row?.status}
							onChange={(event) => {
								updateStatus(event, row);
							}}
							inputProps={{ 'aria-label': row?._id, role: 'switch' }}
							color='success'
							disabled={get(dialogData, 'id', '') === row?._id && loading}
						/>
						<IconButton
							aria-label={`${row?._id}-delete`}
							onClick={() => {
								setDialogData({
									...dialogData,
									id: row?._id,
									rowData: row,
									title: `${title} Delete`,
								});
								handleDeleteConfirmation();
							}}
						>
							<DeleteIcon color='error' />
						</IconButton>
					</Box>
				);
			},
		},
	];

	const CustomToolbar = () => {
		return (
			<DataGridToolbar align='right' divider={true} DisableToolbarColumnsButton={true}>
				<MDButton
					variant='contained'
					label={`Create New`}
					startIcon={<AddCircleOutlineIcon />}
					fontSize='14px'
					onClick={() => {
						setDialogData({
							...dialogData,
							id: '',
							rowData: '',
							title: `${title} Create`,
							formType: 'create',
						});
						handleDialogOpen();
					}}
				/>
			</DataGridToolbar>
		);
	};

	return (
		<>
			<MDDataGrid
				rows={state}
				columns={columnsData}
				getRowId={(row) => row._id}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick={true}
				loading={loading}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
				autoHeight
			/>
			<GeneralConfigForm
				dialogData={dialogData}
				{...props}
				refresh={getData}
				loading={loading}
				setLoading={setLoading}
			/>
			<DeleteConfirmDialog
				title={`${title} Delete Confirmation`}
				open={deleteConfirmation}
				handleClose={handleDeleteConfirmationClose}
				deleteAction={() => {
					handleDelete({ event: true, id: get(dialogData, 'id', '') });
				}}
			/>
		</>
	);
};

export default GeneralConfigList;
