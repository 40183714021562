import { alpha } from "@mui/material/styles";

// Begin Light Mode Colors
const primaryColorsLight = {
  lighter: "#D9E3FE",
  light: "#8CA8FC",
  main: "#4166F5",
  dark: "#2038B0",
  darker: "#0C1975",
  contrastText: "#FFFFFF",
  50: "#E8EAFE",
  100: "#D9E3FE",
  200: "#B3C7FE",
  300: "#8CA8FC",
  400: "#6F8EF9",
  500: "#4166F5",
  600: "#2F4DD2",
  700: "#2038B0",
  800: "#14258E",
  900: "#0C1975",
};
const secondaryColorsLight = {
  lighter: "#FEFCE7",
  light: "#FCEA8D",
  main: "#F5D142",
  dark: "#B08D21",
  darker: "#75570C",
  contrastText: "#000000",
  50: "#E9ECF5",
  100: "#FEFAD9",
  200: "#FEF3B3",
  300: "#FCEA8D",
  400: "#F9E070",
  500: "#F5D142",
  600: "#D2AE30",
  700: "#B08D21",
  800: "#8E6E15",
  900: "#75570C",
};
// End Light Mode Colors

// Begin Dark Mode Colors
const primaryColorsDark = {
  darker: "#D9E3FE",
  dark: "#8CA8FC",
  main: "#4166F5",
  light: "#2038B0",
  lighter: "#0C1975",
  contrastText: "#FFFFFF",
  900: "#E3F3F0",
  800: "#D9E3FE",
  700: "#B3C7FE",
  600: "#8CA8FC",
  500: "#6F8EF9",
  400: "#4166F5",
  300: "#2F4DD2",
  200: "#2038B0",
  100: "#14258E",
  50: "#0C1975",
};
const secondaryColorsDark = {
  darker: "#FEFAD9",
  dark: "#FCEA8D",
  main: "#F5D142",
  light: "#B08D21",
  lighter: "#75570C",
  contrastText: "#000000",
  900: "#E9ECF5",
  800: "#FEFAD9",
  700: "#FEF3B3",
  600: "#FCEA8D",
  500: "#F9E070",
  400: "#F5D142",
  300: "#D2AE30",
  200: "#B08D21",
  100: "#8E6E15",
  50: "#75570C",
};
// End Dark Mode Colors

// Begin Accent Colors
const greyColors = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const successColors = {
  lighter: "#EAFBD6",
  light: "#ACEA81",
  main: "#3B9F23",
  dark: "#268518",
  darker: "#095909",
  contrastText: greyColors[0],
  100: "#EAFBD6",
  200: "#D1F8AE",
  300: "#ACEA81",
  400: "#86D55E",
  500: "#55BA30",
  600: "#3B9F23",
  700: "#268518",
  800: "#146B0F",
  900: "#095909",
};

const infoColors = {
  lighter: "#DAF3FF",
  light: "#90D0FF",
  main: "#337CDB",
  dark: "#235CB7",
  darker: "#0D2D7A",
  contrastText: greyColors[0],
  100: "#DAF3FF",
  200: "#B5E3FF",
  300: "#90D0FF",
  400: "#75BEFF",
  500: "#47A0FF",
  600: "#337CDB",
  700: "#235CB7",
  800: "#164193",
  900: "#0D2D7A",
};

const warningColors = {
  lighter: "#FFF7CC",
  light: "#FFE066",
  main: "#DB9E00",
  dark: "#B77F00",
  darker: "#7A4E00",
  contrastText: greyColors[0],
  100: "#FFF7CC",
  200: "#FFED99",
  300: "#FFE066",
  400: "#FFD33F",
  500: "#FFBF00",
  600: "#DB9E00",
  700: "#B77F00",
  800: "#936300",
  900: "#7A4E00",
};

const errorColors = {
  lighter: "#FFE6D7",
  light: "#FFA187",
  main: "#DB282E",
  dark: "#B71C2E",
  darker: "#7A0A2A",
  contrastText: greyColors[0],
  100: "#FFE6D7",
  200: "#FFC7AF",
  300: "#FFA187",
  400: "#FF7D69",
  500: "#FF4238",
  600: "#DB282E",
  700: "#B71C2E",
  800: "#93112C",
  900: "#7A0A2A",
};
// End Accent Colors

export {
  primaryColorsLight,
  secondaryColorsLight,
  primaryColorsDark,
  secondaryColorsDark,
  successColors,
  infoColors,
  warningColors,
  errorColors,
  greyColors,
};
