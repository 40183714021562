import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get, map, truncate } from 'lodash';
import moment from 'moment';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DetailWrapper, { DetailTitle, DetailValue } from 'components/common/DetailWrapper';
import MDCard from 'components/md-components/card/MDCard';
import DetailCardSkeleton from 'components/md-components/skeleton/DetailCardSkeleton';
import MDTooltip from 'components/md-components/tooltip/MDTooltip';
import LicenseOrCertificateForm from './licenseOrCertificateForm';
import copyContent from 'components/hooks/copyContent';

const ConsultantLicenseOrCertificate = (props) => {
	const { userDetails, userType } = props;
	const isLoading = useSelector((state) => state.consultantUser.loading);
	const [dialogData, setDialogData] = useState({
		title: '',
		data: [],
		formType: '',
	});
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleDialogOpen = () => {
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	return isLoading ? (
		<DetailCardSkeleton />
	) : (
		<MDCard>
			<Box className='detail-box-header'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
					<Typography variant='body1' className='detail-box-heading'>
						License or Certificate
					</Typography>
					<IconButton
						color='primary'
						onClick={() => {
							setDialogData({
								...dialogData,
								title: 'License Or Certificate Create',
								data: [],
								formType: 'create',
							});
							handleDialogOpen();
						}}
					>
						<AddCircleOutlineIcon />
					</IconButton>
				</Stack>
			</Box>
			<CardContent>
				{map(get(userDetails, 'licenseCertification', []), (item, index, { length }) => (
					<Box key={index} className={`border rounded-2 ${index === length - 1 ? '' : 'mb-3'}`}>
						<Box className='detail-box-header'>
							<Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
								<Typography className='detail-box-title'>{item.name}</Typography>
								<IconButton
									color='primary'
									onClick={() => {
										setDialogData({
											...dialogData,
											title: 'License Or Certificate Update',
											data: item,
											formType: 'update',
										});
										handleDialogOpen();
									}}
								>
									<EditIcon />
								</IconButton>
							</Stack>
						</Box>
						<Box display='grid' gap='12px' gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }} className='p-3'>
							<DetailWrapper fullWidth={true}>
								<DetailTitle title='Name' colon={true} />
								<DetailValue>{item?.name}</DetailValue>
							</DetailWrapper>
							<DetailWrapper fullWidth={true}>
								<DetailTitle title='Issuing Organization' colon={true} />
								<DetailValue>{item?.issuingOrganization}</DetailValue>
							</DetailWrapper>
							<DetailWrapper fullWidth={true}>
								<DetailTitle title='Credential Id' colon={true} />
								<DetailValue>{item?.credentialId}</DetailValue>
							</DetailWrapper>
							<DetailWrapper fullWidth={true}>
								<DetailTitle title='Credential Url' colon={true} />
								<DetailValue>
									<MDTooltip
										title={
											<IconButton onClick={() => copyContent(item?.credentialUrl)} color='inherit'>
												<ContentCopyRoundedIcon />
											</IconButton>
										}
										placement='right-start'
										padding='0px'
									>
										<Link href={item?.credentialUrl} target='_blank' underline='hover'>
											{truncate(item?.credentialUrl, {
												length: 30,
												omission: '...',
											})}
										</Link>
									</MDTooltip>
								</DetailValue>
							</DetailWrapper>
							<DetailWrapper fullWidth={true}>
								<DetailTitle title='Issue Date' colon={true} />
								<DetailValue>{moment(item?.issueDate).format('LL')}</DetailValue>
							</DetailWrapper>
						</Box>
					</Box>
				))}
				<LicenseOrCertificateForm
					dialogData={dialogData}
					dialogOpen={dialogOpen}
					handleDialogClose={handleDialogClose}
					userDetails={userDetails}
					userType={userType}
				/>
			</CardContent>
		</MDCard>
	);
};

export default ConsultantLicenseOrCertificate;
