import { FieldArray, getIn } from "formik";
import { get, map, size } from "lodash";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";

import MDButton from "components/md-components/button/MDButton";
import MDRadio from "components/md-components/form/MDRadio";

import FilePicker from "components/utility/file-picker/FilePicker";
import { Skeleton } from "@mui/material";
import FilePickerPreview from "components/utility/file-picker/component/FilePickerPreview";
import { useTheme } from "@emotion/react";

const PressReleasedTopSection = ({
  formProps,
  handleImageUpload,
  fileUploadLoading,
}) => {
  const theme = useTheme();
  const { palette } = theme;

  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        Top Section
      </Typography>
      <FieldArray name="topSection">
        {({ remove, push }) => (
          <Box className="pt-2">
            <TransitionGroup component="div" appear>
              {map(
                formProps.values.topSection,
                (topSectionItem, topSectionIndex) => {
                  const imageType = `topSection[${topSectionIndex}].imageType`;

                  const imageUrl = `topSection[${topSectionIndex}].imageUrl`;
                  const errorImageURL = getIn(formProps.errors, imageUrl);
                  const touchedImageURL = getIn(formProps.touched, imageUrl);

                  const title = `topSection[${topSectionIndex}].title`;
                  const errorImgTitle = getIn(formProps.errors, title);
                  const touchedImgTitle = getIn(formProps.touched, title);

                  const isLargeTopSection =
                    size(get(formProps, "values.topSection")) > 1;

                  return (
                    <Collapse key={topSectionIndex}>
                      <Box>
                        {topSectionIndex !== 0 ? (
                          <Box>
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                            >
                              <Divider
                                orientation="horizontal"
                                flexItem={true}
                                light={true}
                                sx={{
                                  width: `calc(100% - 48px)`,
                                  margin: "auto 0 !important",
                                }}
                              />
                              {isLargeTopSection && (
                                <IconButton
                                  color="error"
                                  onClick={() => remove(topSectionIndex)}
                                >
                                  <ClearOutlinedIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Box>
                        ) : null}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box className="mb-2">
                              <FormControl fullWidth className="gap-2">
                                <FormLabel>Image Type</FormLabel>
                                <RadioGroup
                                  name={imageType}
                                  value={topSectionItem?.imageType}
                                  onChange={(_e, value) => {
                                    formProps.setFieldValue(imageType, value);
                                  }}
                                  row
                                  sx={{
                                    marginLeft: 1,
                                  }}
                                >
                                  <FormControlLabel
                                    value="IMAGE_UPLOAD"
                                    control={<MDRadio />}
                                    label="Image Upload"
                                    className="ms-0"
                                  />
                                  <FormControlLabel
                                    value="IMAGE_LINK"
                                    control={<MDRadio />}
                                    label="Image Link"
                                    className="ms-0"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={
                              topSectionItem.imageType === "IMAGE_UPLOAD"
                                ? 6
                                : 12
                            }
                          >
                            {topSectionItem?.imageType === "IMAGE_UPLOAD" ? (
                              <Box>
                                <FilePicker
                                  label="Select Image"
                                  id={imageUrl}
                                  name={imageUrl}
                                  value={topSectionItem?.imageUrl}
                                  onChange={(file) => {
                                    handleImageUpload(
                                      file,
                                      "top",
                                      imageUrl,
                                      formProps.setFieldValue
                                    );
                                  }}
                                  error={
                                    errorImageURL && touchedImageURL
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorImageURL && touchedImageURL
                                      ? errorImageURL
                                      : false
                                  }
                                  isPreviewOpen={false}
                                  loading={Boolean(
                                    fileUploadLoading === imageUrl
                                  )}
                                  dropBoxGridSize={12}
                                  previewGridSize={6}
                                />
                              </Box>
                            ) : (
                              <Box>
                                <TextField
                                  size="small"
                                  label="Image Link"
                                  type="url"
                                  name={imageUrl}
                                  // value={topSectionItem?.imageUrl}
                                  value={
                                    topSectionItem.imageUrl[0]?.url
                                      ? topSectionItem.imageUrl[0]?.url
                                      : topSectionItem.imageUrl
                                  }
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={
                                    errorImageURL && touchedImageURL
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorImageURL && touchedImageURL
                                      ? errorImageURL
                                      : null
                                  }
                                  fullWidth={true}
                                  // multiline={true}
                                />
                              </Box>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={
                              topSectionItem.imageType === "IMAGE_UPLOAD"
                                ? 6
                                : 12
                            }
                          >
                            <TextField
                              size="small"
                              label="Image Title"
                              name={title}
                              value={topSectionItem?.title}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              error={
                                errorImgTitle && touchedImgTitle ? true : false
                              }
                              helperText={
                                errorImgTitle && touchedImgTitle
                                  ? errorImgTitle
                                  : null
                              }
                              fullWidth={true}
                              multiline={true}
                            />
                          </Grid>

                          {topSectionItem.imageType === "IMAGE_UPLOAD" && (
                            <Grid item xs={6}>
                              {Boolean(fileUploadLoading === imageUrl) ? (
                                <Box>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={3}
                                    className="w-100 p-2 rounded-2"
                                  >
                                    <Skeleton
                                      variant="rectangular"
                                      width={110}
                                      height={110}
                                    />
                                    <Skeleton
                                      variant="rectangular"
                                      width={110}
                                      height={110}
                                    />
                                    <Skeleton
                                      variant="rectangular"
                                      width={110}
                                      height={110}
                                    />
                                  </Stack>
                                </Box>
                              ) : (
                                <Box>
                                  <FilePickerPreview
                                    fileLabel={false}
                                    name={imageUrl}
                                    value={topSectionItem?.imageUrl}
                                    // previewImageStyle={previewImageStyle}
                                    theme={theme}
                                    // previewFileSize={previewFileSize}
                                    noPreviewImageBaseURL={true}
                                  />
                                </Box>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Collapse>
                  );
                }
              )}
            </TransitionGroup>
            {/* <Box sx={{ mr: 2, mt: 3, mb: 1 }}>
              <MDButton
                variant="outlined"
                onClick={() =>
                  push({
                    imageType: "IMAGE_UPLOAD",
                    imageUrl: "",
                    title: "",
                  })
                }
              >
                Add New
              </MDButton>
            </Box> */}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};

export default PressReleasedTopSection;
