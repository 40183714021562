import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const LightBoxButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[300],
  "&:hover": {
    color: theme.palette.grey[100],
    backgroundColor: alpha(theme.palette.grey[700], 0.5),
  },
  "&.Mui-disabled": {
    color: theme.palette.grey[500],
  },
}));
export default LightBoxButton;
