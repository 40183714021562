import React from 'react';
import { FieldArray } from 'formik';
import { map } from 'lodash';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { get } from 'lodash';
import FilePicker from './FilePicker';

export default function ContentForm({ formProps, openIndex, setOpenIndex }) {
	return (
		<FieldArray name='content'>
			{() => (
				<Box>
					{map(formProps?.values?.content, (field, index) => {
						const title = `content[${index}].title`;
						const subTitle = `content[${index}].subTitle`;
						const descriptionTitle = `content[${index}].descriptionTitle`;
						const descriptionUrl = `content[${index}].descriptionUrl`;
						const footerTitle = `content[${index}].footerTitle`;
						const footerSubTitle = `content[${index}].footerSubTitle`;
						const footerDescription = `content[${index}].footerDescription`;

						// const seoTitle = `content[${index}].seoTitle`;
						// const seoDescription = `content[${index}].seoDescription`;
						// const seoMetaTags = `content[${index}].seoMetaTags`;

						const errors = get(formProps, `errors.content[${index}]`, {});
						const touched = get(formProps, `touched.content[${index}]`, {});

						return (
							<React.Fragment key={index}>
								<Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
									<Typography variant='h6'>{field.languageName}</Typography>
									<IconButton onClick={() => setOpenIndex(index)}>
										<Box
											component={openIndex === index ? ExpandLessIcon : ExpandMoreIcon}
											color='inherit'
											sx={{ fontSize: '1.25rem' }}
										/>
									</IconButton>
								</Box>
								<Collapse in={openIndex === index}>
									<Grid container spacing={2} key={index} mb={3}>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Title'
												name={title}
												value={field.title}
												error={Boolean(errors.title && touched.title)}
												helperText={errors.title && touched.title ? errors.title : null}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Sub Title'
												name={subTitle}
												multiline
												rows={3}
												value={field.subTitle}
												error={Boolean(errors.subTitle && touched.subTitle)}
												helperText={errors.subTitle && touched.subTitle ? errors.subTitle : null}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label='Image Title'
														name={descriptionTitle}
														fullWidth
														value={field.descriptionTitle}
														error={Boolean(errors.descriptionTitle && touched.descriptionTitle)}
														helperText={
															errors.descriptionTitle && touched.descriptionTitle
																? errors.descriptionTitle
																: null
														}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
													/>
												</Grid>

												<Grid item xs={12}>
													<FilePicker
														label='Image Upload'
														id='data'
														name={descriptionUrl}
														value={field.descriptionUrl}
														onChange={(file) => formProps.setFieldValue(descriptionUrl, file)}
														error={Boolean(errors.descriptionUrl && touched.descriptionUrl)}
														helperText={
															errors.descriptionUrl && touched.descriptionUrl
																? errors.descriptionUrl
																: null
														}
														dropBoxGridSize={6}
														previewGridSize={6}
														noPreviewImageBaseURL={true}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Footer Title'
												name={footerTitle}
												value={field.footerTitle}
												error={Boolean(errors.footerTitle && touched.footerTitle)}
												helperText={errors.footerTitle && touched.footerTitle ? errors.footerTitle : null}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Footer Sub Title'
												name={footerSubTitle}
												multiline
												rows={3}
												value={field.footerSubTitle}
												error={Boolean(errors.footerSubTitle && touched.footerSubTitle)}
												helperText={
													errors.footerSubTitle && touched.footerSubTitle ? errors.footerSubTitle : null
												}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Footer Description'
												name={footerDescription}
												multiline
												rows={3}
												value={field.footerDescription}
												error={Boolean(errors.footerDescription && touched.footerDescription)}
												helperText={
													errors.footerDescription && touched.footerDescription
														? errors.footerDescription
														: null
												}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>

										{/* <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Seo Title"
                        name={seoTitle}
                        value={field.seoTitle}
                        error={Boolean(errors.seoTitle && touched.seoTitle)}
                        helperText={errors.seoTitle && touched.seoTitle ? errors.seoTitle : null}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Seo Description"
                        name={seoDescription}
                        value={field.seoDescription}
                        error={Boolean(errors.seoDescription && touched.seoDescription)}
                        helperText={errors.seoDescription && touched.seoDescription ? errors.seoDescription : null}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={[]}
                        name={seoMetaTags}
                        value={field.seoMetaTags}
                        onChange={(e, value) => {
                          formProps.setFieldValue(seoMetaTags, value);
                        }}
                        onBlur={formProps.handleBlur}
                        multiple
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Meta Tags"}
                            error={errors.seoMetaTags && touched.seoMetaTags ? true : false}
                            helperText={errors.seoMetaTags && touched.seoMetaTags ? errors.seoMetaTags : null}
                          />
                        )}
                      />
                    </Grid> */}
									</Grid>
								</Collapse>
							</React.Fragment>
						);
					})}
				</Box>
			)}
		</FieldArray>
	);
}
