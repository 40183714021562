import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/md-components/tab/TabPanel';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import QAndAList from './QAndAList';
import QAndASegmentList from './QAndASegmentList';

function a11yProps(index) {
	return {
		id: `consultant-config-tab-${index}`,
		'aria-controls': `consultant-config-tab-panel-${index}`,
	};
}

const DefaultQuestionList = () => {
	const theme = useTheme();
	const { palette } = theme;

	const [value, setValue] = useState(0);
	const handleChange = (_event, newValue) => {
		setValue(newValue);
	};

	return (
		<MDCard>
			<MDCardHeader title='Post Consultant' />
			<CardContent className='h-100'>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Box sx={{ bgcolor: palette.background.default }}>
							<Tabs value={value} onChange={handleChange} variant='fullWidth'>
								<Tab label='Q & A' {...a11yProps(0)} />
								<Tab label='Segment' {...a11yProps(1)} />
							</Tabs>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<TabPanel value={value} index={0}>
							<QAndAList />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<QAndASegmentList />
						</TabPanel>
					</Grid>
				</Grid>
			</CardContent>
		</MDCard>
	);
};

export default DefaultQuestionList;
