import React from "react";
import { get, map } from "lodash";
import moment from "moment";
// MUI
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Other
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";
import { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Custom
import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import stringToColor, {
  getContrastColor,
} from "components/hooks/stringToColor";
import EditorHTMLViewBox from "components/utility/rich-text-editor/EditorHTMLViewBox";

const PressReleasedDetail = (props) => {
  const { articleData } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h5"
              fontWeight={700}
              textTransform="capitalize"
            >
              {get(articleData, "title", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="body1"
              fontWeight={400}
              textTransform="capitalize"
            >
              {get(articleData, "subTitle", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="pt-1">
            <Stack
              direction={{ sm: "row", xs: "column" }}
              alignItems={{ sm: "center", xs: "flex-start" }}
              spacing={1}
            >
              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  className="gap-2"
                >
                  <Typography variant="body2" textTransform="capitalize">
                    Created By
                  </Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    fontWeight={600}
                  >
                    {get(articleData, "createdBy", "") === "MASTER_ADMIN" ? (
                      <>
                        {get(articleData, "approvedBy.firstName", "") +
                          " " +
                          get(articleData, "approvedBy.lastName", "")}
                      </>
                    ) : (
                      <>
                        {get(articleData, "consultantId.firstName", "") +
                          " " +
                          get(articleData, "consultantId.lastName", "")}
                      </>
                    )}
                  </Typography>
                  <Chip
                    label={get(articleData, "createdBy", "").replace("_", " ")}
                    color="primary"
                    size="small"
                  />
                </Stack>
              </Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ display: { sm: "block", xs: "none" } }}
              />
              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body2" textTransform="capitalize">
                    Published on
                  </Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    fontWeight={600}
                  >
                    {moment(get(articleData, "createdAt", ""), [
                      "MM-DD-YYYY",
                      "YYYY-MM-DD",
                    ]).format("LL")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="pt-1">
            <Stack direction="row" flexWrap="wrap" className="gap-2">
              <Chip
                label={get(articleData, "language.title", "NA")}
                size="small"
                sx={{
                  backgroundColor: stringToColor(
                    get(articleData, "language.title", "NA")
                  ),
                  color: getContrastColor(
                    stringToColor(get(articleData, "language.title", "NA"))
                  ),
                }}
              />
              {map(
                get(articleData, "categories", []),
                (category, categoryIndex) => {
                  return (
                    <React.Fragment key={categoryIndex}>
                      <Chip
                        key={categoryIndex}
                        label={get(category, "foreignData.title", "NA")}
                        size="small"
                        sx={{
                          backgroundColor: stringToColor(
                            get(category, "foreignData.title", "NA")
                          ),
                          color: getContrastColor(
                            stringToColor(
                              get(category, "foreignData.title", "NA")
                            )
                          ),
                        }}
                      />
                      {map(
                        get(category, "subCategories", []),
                        (subCategory, subCategoryIndex) => {
                          return (
                            <Chip
                              key={subCategoryIndex}
                              label={get(
                                subCategory,
                                "foreignData.title",
                                "NA"
                              )}
                              size="small"
                              sx={{
                                backgroundColor: stringToColor(
                                  get(subCategory, "foreignData.title", "NA")
                                ),
                                color: getContrastColor(
                                  stringToColor(
                                    get(subCategory, "foreignData.title", "NA")
                                  )
                                ),
                              }}
                            />
                          );
                        }
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Swiper
            modules={[Virtual, Pagination, Navigation]}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            virtual={true}
            spaceBetween={50}
            slidesPerView={1}
          >
            {map(
              get(articleData, "topSection", []),
              (topContent, index, { length }) => (
                <SwiperSlide
                  key={get(topContent, "_id", "")}
                  virtualIndex={index}
                  className={length > 1 ? "pb-4" : "pb-0"}
                >
                  <Box>
                    <LazyLoadImage
                      alt={get(topContent, "title", "NA")}
                      src={get(topContent, "imageUrl", "")}
                      effect="blur"
                      className="rounded-2"
                    />
                    <Typography
                      variant="caption"
                      component="p"
                      textAlign="center"
                      color="grey.700"
                      className="mt-1"
                    >
                      {get(topContent, "title", "")}
                    </Typography>
                  </Box>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          {map(
            get(articleData, "contentSection", []),
            (contentData, index, { length }) => (
              <Box key={index} mb={length - 1 !== index ? 3 : 0}>
                {get(contentData, "preferredOption", "TEXT") === "TEXT" ? (
                  <EditorHTMLViewBox data={get(contentData, "data", "NA")} />
                ) : get(contentData, "preferredOption", "IMAGE") === "IMAGE" ? (
                  <React.Fragment>
                    <LazyLoadImage
                      alt={get(contentData, "title", "NA")}
                      src={get(contentData, "data", "")}
                      effect="blur"
                      className="rounded-2"
                    />
                    <Typography
                      variant="caption"
                      component="p"
                      textAlign="center"
                      color="grey.700"
                      className="mt-1"
                    >
                      {get(contentData, "title", "NA")}
                    </Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box className="player-wrapper rounded-2">
                      <ReactPlayer
                        url={get(contentData, "data", "")}
                        controls={true}
                        width="100%"
                        height="100%"
                        className="react-player"
                      />
                    </Box>
                    <Typography
                      variant="caption"
                      component="p"
                      textAlign="center"
                      color="grey.700"
                      className="mt-1"
                    >
                      {get(contentData, "title", "NA")}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            )
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            Personal Information
          </Typography>
          <Box className="mt-3 px-1">
            <Stack
              direction={{ sm: "row", xs: "column" }}
              flexWrap="wrap"
              sx={{ rowGap: 1.5, columnGap: 4 }}
            >
              <DetailWrapper fullWidth={true}>
                <DetailTitle title="Name" colon={true} />
                <DetailValue>
                  <Stack direction="row" flexWrap="wrap" className="gap-2">
                    <Typography variant="body1">
                      {get(articleData, "createdBy", "") === "MASTER_ADMIN" ? (
                        <>
                          {get(articleData, "approvedBy.firstName", "") +
                            " " +
                            get(articleData, "approvedBy.lastName", "")}
                        </>
                      ) : (
                        <>
                          {get(articleData, "consultantId.firstName", "") +
                            " " +
                            get(articleData, "consultantId.lastName", "")}
                        </>
                      )}
                    </Typography>
                    <Chip
                      label={get(articleData, "createdBy", "").replace(
                        "_",
                        " "
                      )}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="ms-2"
                    />
                  </Stack>
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth={true}>
                <DetailTitle title="E-mail" colon={true} />
                <DetailValue>
                  {get(articleData, "createdBy", "") === "MASTER_ADMIN" ? (
                    <>{get(articleData, "approvedBy.email", "")}</>
                  ) : (
                    <>{get(articleData, "consultantId.email", "")}</>
                  )}
                </DetailValue>
              </DetailWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            SEO Information
          </Typography>
          <Box className="mt-3 px-1">
            <Stack direction="column" spacing={1.5}>
              <DetailWrapper>
                <DetailTitle title="Publish" colon={true} />
                <DetailValue>
                  {get(articleData, "isPublished", false) ? (
                    <CheckCircleIcon color="success" className="align-middle" />
                  ) : (
                    <CancelIcon color="error" className="align-middle" />
                  )}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle title="Comments" colon={true} />
                <DetailValue>
                  {get(articleData, "comments", false) ? (
                    <CheckCircleIcon color="success" className="align-middle" />
                  ) : (
                    <CancelIcon color="error" className="align-middle" />
                  )}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle title="Likes" colon={true} />
                <DetailValue>
                  {get(articleData, "likeStatus", false) ? (
                    <CheckCircleIcon color="success" className="align-middle" />
                  ) : (
                    <CancelIcon color="error" className="align-middle" />
                  )}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle title="Ratings" colon={true} />
                <DetailValue>
                  {get(articleData, "ratingStatus", false) ? (
                    <CheckCircleIcon color="success" className="align-middle" />
                  ) : (
                    <CancelIcon color="error" className="align-middle" />
                  )}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle title="SEO Title" colon={true} />
                <DetailValue>{get(articleData, "seoTitle", false)}</DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle title="SEO Description" colon={true} />
                <DetailValue>
                  {get(articleData, "seoDescription", false)}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle title="SEO Meta Tags" colon={true} />
                <DetailValue className="d-flex gap-2">
                  {map(
                    get(articleData, "seoMetaTags", false),
                    (tags, tagsIndex) => (
                      <Chip key={tagsIndex} label={tags} size="small" />
                    )
                  )}
                </DetailValue>
              </DetailWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PressReleasedDetail;
