import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const ActivityLoader = (props) => {
  const { loading } = props;

  return loading ? (
    <Box
      sx={{
        position: "fixed",
        zIndex: 9999,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#0000001a",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  ) : (
    <></>
  );
};
export default ActivityLoader;
