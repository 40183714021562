import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import DrawerHeader from "layout/header/DrawerHeader";
import SidebarMenus from "components/layout/SidebarMenus";
import DrawerFooter from "layout/footer/DrawerFooter";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

const SideBar = (props) => {
  const { window, drawerWidth, drawerOpen, handleDrawerToggle } = props;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
      aria-label="Dashboard Sidebar"
    >
      <Drawer
        variant="temporary"
        anchor="left"
        container={container}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          className: "scrollbar-none",
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
            backgroundColor: "#07080C",
          },
        }}
      >
        <DrawerHeader {...props} handleDrawerToggle={handleDrawerToggle} />
        <Box my={1} px={1}>
          <SimpleBar autoHide={true} style={{ overflowX: "hidden" }}>
            <SidebarMenus />
          </SimpleBar>
        </Box>
        <DrawerFooter />
      </Drawer>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{
          className: "scrollbar-none",
        }}
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
            backgroundColor: "#07080C",
          },
        }}
        open
      >
        <DrawerHeader {...props} handleDrawerToggle={handleDrawerToggle} />
        <Box my={1} px={1}>
          <SimpleBar autoHide={true} style={{ overflowX: "hidden" }}>
            <SidebarMenus />
          </SimpleBar>
        </Box>
        <DrawerFooter />
      </Drawer>
    </Box>
  );
};

SideBar.propTypes = {
  drawerWidth: PropTypes.number,
  drawerOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default SideBar;
