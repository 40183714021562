import AxiosDefault from "services/AxiosDefaultSetting";

export const GetDietaryPreferenceData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=DIETARY_PREFERENCE&level=1`,
  });
  return response;
};

export const GetGoalTypeData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=GOAL_TYPE&level=1`,
  });
  return response;
};

export const GetSpecializationData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=SPECIALIZATION&level=1`,
  });
  return response;
};

export const GetBenefits = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=BENEFITS&level=1`,
  });
  return response;
};

export const GetSocialMediaChannelData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=SOCIAL_MEDIA_CHANNELS&level=1`,
  });
  return response;
};

export const GetFoodAllergiesData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `admin/general-configuration/get?sectionName=FOOD_ALLERGIES&level=1`,
  });
  return response;
};

export const GetAllergiesTypeData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `admin/general-configuration/get?sectionName=ALLERGIES_TYPE&level=1`,
  });
  return response;
};
export const GetCuisineData = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `admin/general-configuration/get?sectionName=CUISINE&level=1`,
  });
  return response;
};
