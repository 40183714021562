import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetPolicies,
  CreatePolicies,
  UpdatePolicies,
  StatusUpdatePolicies,
  DeletePolicies,
} from "api/country-profile/CountryProfileAPI";
import { get } from "lodash";

const initialState = {
  list: [],
  loading: false,
};

export const getPolicies = createAsyncThunk(
  "countryPolicies/getPolicies",
  async (data) => {
    return await GetPolicies(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const createPolicies = createAsyncThunk(
  "countryPolicies/createPolicies",
  async (data) => {
    return await CreatePolicies(data)
      .then(function (response) {
        return get(response, "data", []);
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const updatePolicies = createAsyncThunk(
  "countryPolicies/updatePolicies",
  async (data) => {
    return await UpdatePolicies(data)
      .then(function (response) {
        return get(response, "data", []);
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const statusUpdatePolicies = createAsyncThunk(
  "countryPolicies/statusUpdatePolicies",
  async (data) => {
    return await StatusUpdatePolicies(data)
      .then(function (response) {
        return get(response, "data", []);
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const deletePolicies = createAsyncThunk(
  "countryPolicies/deletePolicies",
  async (data) => {
    return await DeletePolicies(data)
      .then(function (response) {
        return get(response, "data", []);
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const CountryPoliciesSlice = createSlice({
  name: "countryPolicies",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPolicies.fulfilled, (state, action) => {
      state.list = get(action.payload, "status", false)
        ? action.payload.data
        : [];
      state.loading = false;
    });
    builder.addCase(getPolicies.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createPolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPolicies.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPolicies.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePolicies.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePolicies.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdatePolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusUpdatePolicies.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdatePolicies.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePolicies.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePolicies.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePolicies.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default CountryPoliciesSlice.reducer;
