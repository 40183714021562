import AxiosDefault from 'services/AxiosDefaultSetting';

// Begin Customer API's
export const GetEndUsersList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/admin/user-management/end-user/get`,
	});
	return response;
};
export const GetEndUserDetail = async ({ endUserId }) => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/admin/user-management/end-user/details/${endUserId}`,
	});
	return response;
};
export const StatusUpdateEndUser = async ({ endUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/end-user/status-update/${endUserId}`,
		data: data,
	});
	return response;
};
export const DeleteEndUser = async ({ endUserId, data }) => {
	const response = await AxiosDefault({
		method: 'DELETE',
		url: `/admin/user-management/end-user/delete/${endUserId}`,
		data: data,
	});
	return response;
};

export const GetVerifiedConsultantUsers = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/admin/user-management/consultant/get`,
	});
	return response;
};
export const GetNonVerifiedConsultantUsers = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `admin/user-management/consultant/none-verified`,
	});
	return response;
};
export const GetAllConsultantUsers = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `admin/user-management/consultant/all-list`,
	});
	return response;
};
export const GetConsultantUserDocumentVerification = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `admin/user-management/consultant/offline/get`,
	});
	return response;
};

export const GetVerifiedConsultantUserDetail = async ({ consultantUserId }) => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/admin/user-management/consultant/details/${consultantUserId}`,
	});
	return response;
};
export const GetNonVerifiedConsultantUserDetail = async ({ consultantUserId }) => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/admin/user-management/consultant/details-none-verified/${consultantUserId}`,
	});
	return response;
};
export const StatusUpdateConsultantUser = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/status-update/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const DeleteConsultantUser = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'DELETE',
		url: `/admin/user-management/consultant/delete/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const UpdateConsultantUserVerification = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/verification-status-update/${consultantUserId}`,
		data: data,
	});
	return response;
};

export const OfflineConsultantUserVerification = async ({ data }) => {
	// console.log('data :>> ', data);
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/offline-verify/${data?.userId}`,
		data: data?.serviceTypeOffline,
	});
	return response;
};
// End Customer API's

// Begin Consultant API's
export const UpdateConsultantBasicInfo = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/basic-info/update/${consultantUserId}`,
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};
export const UpdateConsultantBio = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/bio/update/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const UpdateConsultantResidentialAddress = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/residential-address/update/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const UpdateConsultantSpecialization = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/specialization/update/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const UpdateConsultantWorkInfo = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/work/update/${consultantUserId}`,
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};
export const UpdateConsultantUniversityInfo = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/education-university/update/${consultantUserId}`,
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};
export const UpdateConsultantHighSchoolInfo = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/education-high-school/update/${consultantUserId}`,
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};
export const UpdateConsultantLicenseOrCertificate = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/license-certification/update/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const UpdateConsultantPublicationOrLink = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/publication-links/update/${consultantUserId}`,
		data: data,
	});
	return response;
};
export const UpdateConsultantSocialMediaInfo = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/social-media-info/update/${consultantUserId}`,
		data: data,
	});
	return response;
};

export const UpdateRevenueShare = async ({ consultantUserId, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/user-management/consultant/revenue-update/${consultantUserId}`,
		data: data,
	});
	return response;
};
// End Consultant API's
