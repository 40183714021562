import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteEndUser, getEndUsersList, statusUpdateEndUser } from 'store/slice/user-management/EndUserSlice';

import MDCard from 'components/md-components/card/MDCard';
import MDDataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import MDSwitch from 'components/md-components/switch/MDSwitch';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import DeleteConfirmDialog from 'components/common/DeleteConfirmDialog';
import MDCardHeader from 'components/md-components/card/MDCardHeader';

const UserManageEndUser = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isLoading = useSelector((state) => state.endUser.loading);
	const usersList = useSelector((state) => state.endUser.list);

	const [dialogData, setDialogData] = useState({
		id: '',
		title: '',
		rowData: [],
	});

	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const handleDeleteConfirmation = useCallback(() => {
		setDeleteConfirmation(true);
	}, []);
	const handleDeleteConfirmationClose = () => {
		setDeleteConfirmation(false);
	};

	const updateStatus = useCallback(
		(event, params) => {
			setDialogData({ ...dialogData, id: params.id });
			const apiData = {
				endUserId: params.row._id,
				data: { status: event.target.checked },
			};
			dispatch(statusUpdateEndUser(apiData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						dispatch(getEndUsersList());
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[dialogData, dispatch]
	);
	const handleDelete = useCallback(
		({ event, id }) => {
			const apiData = {
				endUserId: id,
				data: { isDeleted: event },
			};
			dispatch(deleteEndUser(apiData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						dispatch(getEndUsersList());
					}
				})
				.catch((err) => {
					console.log(err);
				});
			handleDeleteConfirmationClose();
		},
		[dispatch]
	);

	useEffect(() => {
		dispatch(getEndUsersList());
	}, [dispatch]);

	const columnsData = useMemo(
		() => [
			{
				field: 'name',
				headerName: 'Name',
				width: 240,
				editable: false,
				valueGetter: ({ row }) => row?.firstName + ' ' + row?.lastName,
			},
			{
				field: 'email',
				headerName: 'Email',
				width: 240,
				editable: false,
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				width: 240,
				renderCell: (params) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label={`${params.row.name}-view-button`}
								color='primary'
								onClick={() => {
									navigate(`/user-management/end-user-detail`, {
										state: {
											userId: params.row._id,
										},
									});
								}}
							>
								<VisibilityIcon sx={{ fontSize: 28 }} />
							</IconButton>
							<MDSwitch
								checked={params.row.status}
								onChange={(event) => {
									updateStatus(event, params);
								}}
								inputProps={{
									'aria-label': `${params.row.name}-status`,
									role: 'switch',
								}}
								color='success'
								disabled={get(dialogData, 'id', '') === params.id && isLoading}
							/>
							<IconButton
								aria-label='delete'
								onClick={() => {
									setDialogData({
										...dialogData,
										id: params.row._id,
										title: 'End User Delete Confirmation',
										rowData: params.row,
									});
									handleDeleteConfirmation();
								}}
							>
								<DeleteIcon color='error' />
							</IconButton>
						</Box>
					);
				},
			},
		],
		[dialogData, handleDeleteConfirmation, isLoading, navigate, updateStatus]
	);

	const CustomToolbar = () => {
		return <MDDataGridToolbar align='right' divider={true} />;
	};

	return (
		<Box>
			<MDCard>
				<MDCardHeader title="End User's" />
				<CardContent className='h-100'>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<MDDataGrid
								rows={!isEmpty(usersList) ? usersList : []}
								columns={columnsData}
								getRowId={(row) => row._id}
								pageSize={10}
								rowsPerPageOptions={[10]}
								disableSelectionOnClick
								loading={isLoading}
								components={{
									Toolbar: CustomToolbar,
									NoRowsOverlay: EmptyTableRowOverlay,
									LoadingOverlay: LinearProgress,
								}}
								componentsProps={{
									toolbar: {
										showQuickFilter: true,
										quickFilterProps: { debounceMs: 500 },
									},
								}}
								autoHeight
							/>
							<DeleteConfirmDialog
								title='End User Delete Confirmation'
								open={deleteConfirmation}
								handleClose={handleDeleteConfirmationClose}
								deleteAction={() => {
									handleDelete({ event: true, id: get(dialogData, 'id', '') });
								}}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</MDCard>
		</Box>
	);
};

export default UserManageEndUser;
