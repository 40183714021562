import { filter, get, isEmpty, map } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  CustomDropzone,
  CustomDropzoneLabel,
  CustomDropzoneLabelFieldset,
  CustomDropzoneLabelLegend,
  CustomDropzoneLabelText,
} from "../file-picker/component/FilePickerComponents";
import FilePickerPreview from "../file-picker/component/FilePickerPreview";
import { Skeleton, Stack } from "@mui/material";

const PDFPicker = (props) => {
  const dispatch = useDispatch();
  const {
    accept,
    dragAndDropPlaceholder,
    dropBoxGridSize,
    error,
    filePreviewSize,
    fileLabel = true,
    helperText,
    label,
    multiSelect,
    noFilePreview,
    name,
    onChange,
    previewImageStyle,
    previewGridSize,
    previewFileSize,
    setFieldValue,
    value,
    loading,
    className,
    noPreviewImageBaseURL,
    ...rest
  } = props;

  const theme = useTheme();
  const { palette } = theme;

  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      map(acceptedFiles, (file) => {
        if (file.type === "application/pdf") {
          const reader = new FileReader();
          reader.onload = (e) => {
            const checkDuplicateFiles = filter(files, (item) => {
              return item.name === file.name;
            });

            if (isEmpty(checkDuplicateFiles)) {
              if (multiSelect) {
                if (!onChange) {
                  setFiles((prevState) => [
                    ...prevState,
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    }),
                  ]);
                }
                if (setFieldValue) {
                  setFieldValue(name, value[name].concat(file));
                } else {
                  onChange(file);
                }
              } else {
                if (!onChange) {
                  setFiles([
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    }),
                  ]);
                }
                if (setFieldValue) {
                  setFieldValue(name, file);
                } else {
                  onChange(file);
                }
              }
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          // File type not allowed, display an error
          toast.error("Invalid file type. Only PDF files are allowed.");
        }

        return file;
      });
    },
    [files, multiSelect, name, onChange, setFieldValue, value]
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    // accept: accept ? accept : "video/*",
    accept: ".pdf",
    multiple: multiSelect ? multiSelect : false,
    onDrop,
  });

  const handleRemoveFile = (file) => {
    acceptedFiles.splice(files.indexOf(file), 1);
    setFiles((state) =>
      filter(state, (item) => {
        return item.path !== file.path;
      })
    );
    if (setFieldValue) {
      if (multiSelect) {
        setFieldValue(
          name,
          filter(value[name], (item) => {
            return item.path !== file.path;
          })
        );
      } else {
        setFieldValue(name, undefined);
      }
    }
  };

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(get(file, "preview")));
    };
  }, [files]);

  return (
    <Box className={className}>
      <Grid container spacing={{ md: 2, xs: 1 }} className="container">
        <Grid item md={dropBoxGridSize ? dropBoxGridSize : 12} xs={12}>
          <Box className="h-100 position-relative">
            <FormControl
              fullWidth={true}
              variant="outlined"
              error={error}
              className="h-100"
            >
              <CustomDropzoneLabel
                htmlFor="frontFilePicker"
                shrink={true}
                props={{
                  nativeProps: getRootProps({
                    isDragAccept,
                    isFocused,
                    isDragReject,
                  }),
                }}
              >
                {label}
              </CustomDropzoneLabel>
              <CustomDropzone
                {...getRootProps({ isDragAccept, isFocused, isDragReject })}
                props={{
                  nativeProps: {
                    isFocused: isFocused,
                    isDragAccept: isDragAccept,
                    isDragReject: isDragReject,
                  },
                  error: error,
                }}
                {...rest}
                className="h-100 justify-content-center"
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography
                    variant="subtitle1"
                    color="grey.600"
                    className="text-center"
                  >
                    Release to drop the file here
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle1"
                    color="grey.600"
                    className="text-center"
                  >
                    {dragAndDropPlaceholder
                      ? dragAndDropPlaceholder
                      : "Drag 'n' drop or click to select file"}
                  </Typography>
                )}
                <CustomDropzoneLabelFieldset
                  props={{
                    nativeProps: getRootProps({
                      isDragAccept,
                      isFocused,
                      isDragReject,
                    }),
                    error: error,
                  }}
                >
                  <CustomDropzoneLabelLegend>
                    <CustomDropzoneLabelText>{label}</CustomDropzoneLabelText>
                  </CustomDropzoneLabelLegend>
                </CustomDropzoneLabelFieldset>
              </CustomDropzone>
              {error ? (
                <FormHelperText error={error}>{helperText}</FormHelperText>
              ) : null}
            </FormControl>
            {loading ? (
              <Backdrop
                sx={{
                  color: "inherit",
                  backgroundColor: alpha(palette.common.black, 0.15),
                  position: "absolute",
                  zIndex: theme.zIndex.drawer + 1,
                }}
                open={true}
                className="rounded-2"
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : null}
          </Box>
        </Grid>
        {!noFilePreview ? (
          <Grid item md={previewGridSize ? previewGridSize : 12} xs={12}>
            {loading ? (
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  className="w-100 p-2 border rounded-2"
                >
                  <Skeleton variant="rounded" width={110} height={110} />
                  <Stack direction="column" spacing={1} className="w-75">
                    <Skeleton
                      variant="text"
                      width={"calc(100% - 180px)"}
                      height={20}
                    />
                    <Skeleton
                      variant="text"
                      width={"calc(100% - 80%)"}
                      height={10}
                    />
                  </Stack>
                </Stack>
              </Box>
            ) : (
              <Box>
                <FilePickerPreview
                  acceptedFiles={acceptedFiles}
                  files={files}
                  fileLabel={fileLabel}
                  handleRemoveFile={handleRemoveFile}
                  name={name}
                  value={value}
                  previewImageStyle={previewImageStyle}
                  theme={theme}
                  previewFileSize={previewFileSize}
                  noPreviewImageBaseURL={noPreviewImageBaseURL}
                />
              </Box>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default PDFPicker;
