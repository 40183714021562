import React from "react";
import PropTypes from "prop-types";
import { styled, alpha } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

const CustomStyleButton = styled(LoadingButton)(({ theme, other, color }) => ({
  height: other.size === "small" ? 36 : "auto",
  padding: other.padding && other.padding,
  fontSize: other.fontSize
    ? other.fontSize
    : other.size === "small"
    ? "14px"
    : "16px",
  fontWeight: other.fontWeight ? other.fontWeight : "500",
  textTransform: other.textTransform ? other.textTransform : "none",
  border:
    other.variant === "outlined"
      ? other.color
        ? `1px solid ${theme.palette[color].main}`
        : `1px solid ${theme.palette.primary.main}`
      : "none",

  boxShadow: "none",
  "&:hover": {
    boxShadow: other.color
      ? other.variant === "outlined"
        ? `0px -10px -13px -7px ${alpha(
            theme.palette[color].main,
            0.25
          )},-5px -5px -15px -5px ${alpha(theme.palette[color].main, 0)}`
        : `0px 10px 13px -7px ${alpha(
            theme.palette[color].main,
            0.25
          )},5px 5px 15px 5px ${alpha(theme.palette[color].main, 0)}`
      : other.variant === "outlined"
      ? `0px -10px -13px -7px ${alpha(
          theme.palette.primary.main,
          0.25
        )},-5px -5px -15px -5px ${alpha(theme.palette.primary.main, 0)}`
      : `0px 10px 13px -7px ${alpha(
          theme.palette.primary.main,
          0.25
        )}, 5px 5px 15px 5px ${alpha(theme.palette.primary.main, 0)}`,
    border:
      other.variant === "outlined"
        ? other.color
          ? `1px solid ${theme.palette[color].main}`
          : `1px solid ${theme.palette.primary.main}`
        : "none",
  },
  "&:active": {
    boxShadow: other.color
      ? `0px 10px 13px -7px ${alpha(
          theme.palette[color].main,
          0.25
        )},5px 5px 15px 5px ${alpha(theme.palette[color].main, 0)}`
      : `0px 10px 13px -7px ${alpha(
          theme.palette.primary.main,
          0.25
        )}, 5px 5px 15px 5px ${alpha(theme.palette.primary.main, 0)}`,
    border:
      other.variant === "outlined"
        ? other.color
          ? `1px solid ${theme.palette[color].main}`
          : `1px solid ${theme.palette.primary.main}`
        : "none",
  },
  "&:focus": {
    border:
      other.variant === "outlined"
        ? other.color
          ? `1px solid ${theme.palette[color].main}`
          : `1px solid ${theme.palette.primary.main}`
        : "none",
  },
}));

const MDLoadingButton = (props) => {
  const {
    variant,
    color,
    fontSize,
    fontWeight,
    textTransform,
    label,
    padding,
    children,
    ...rest
  } = props;

  return (
    <CustomStyleButton variant={variant} color={color} other={props} {...rest}>
      {label}
      {children}
    </CustomStyleButton>
  );
};

MDLoadingButton.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textTransform: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.any,
};

export default MDLoadingButton;
