import * as React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { map } from 'lodash';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const breadcrumbNameMap = {
	'/dashboard': 'Dashboard',

	'/user-management': 'User Management',
	'/user-management/end-user': 'End Users',
	'/user-management/end-user-detail': 'End User Detail',
	'/user-management/consultant-user': 'Consultant Users',
	'/user-management/consultant-user-detail': 'Consultant User Detail',
	'/user-management/consultant-verification-request': 'Consultant Users Verification',

	'/country-profile': 'Country Profile',
	'/country-profile/country-profile-create-update': 'Country Profile Create Update',
	'/country-profile/identity-proof-list': 'Identity Proof Listing',
	'/country-profile/identity-proof-create-update': 'Identity Proof Create Update',
	'/country-profile/policy-list': 'Country Policies Listing',
	'/country-profile/policy-create-update': 'Country Policy Create Update',
	'/country-profile/state-profile': 'State Profile',
	'/country-profile/state-profile/edit-state-profile': 'Edit State Profile',
	'/country-profile/state-profile/create-state-profile': 'Create State Profile',
	'/country-profile/state-profile/city-profile': 'City Profile',
	'/country-profile/state-profile/city-profile/edit-city-profile': 'Edit City Profile',
	'/country-profile/state-profile/city-profile/create-city-profile': 'Create City Profile',

	'/general-config': 'General Config',
	'/general-config/end-user': 'End User',
	'/general-config/consultant-user': 'Consultant User',
	'/general-config/consultant-user/sub-specialization': 'Sub Specialization',

	'/role-management': 'Role Management',
	'/role-management/admin-user-list': 'Admin User List',
	'/role-management/admin-user-create-update': 'Admin User Create Update',
	'/role-management/role-list': 'Role List',
	'/role-management/role-assign': 'Role Assign',

	'/consultant-config': 'Consultant Config',
	'/consultant-config/config': 'Consultant Config',
	'/consultant-config/pre-consultant': 'Pre Consultant Config',
	'/consultant-config/post-consultant': 'Post Consultant Config',
	'/consultant-config/create-question': 'Create Question',
	'/consultant-config/question-template-create-update': 'Create Questions Template',

	'/language-config': 'Language Config',
	'/language-config/language-listing': 'Language Listing',
	'/language-config/macro-listing': 'Macro Listing',

	'/booking': "Booking's",
	'/booking/booking-list': "Booking's List",
	'/booking/booking-detail': 'Booking Detail',
	'/booking/q-and-a-detail': 'Q & A Detail',

	'/article-blog': 'Article Blog',
	'/article-blog/article-create-update': 'Create - Update Article',
	'/article-blog/article-detail': 'Article Detail',

	'/knowledge-base': 'Knowledge Base',
	'/knowledge-base/category': 'Knowledge Base Config',

	'/content': 'Content Blog',
	'/content/content-create-update': 'Create - Update Content',
	'/content/content-detail': 'Content Detail',

	'/press-release-blog': 'Press Release',
	'/press-release-blog/press-release-create-update': 'Create - Update Press Release',
	'/press-release-blog/press-release-detail': 'Press Release Detail',

	'/quotes-management': 'Quotes',
	'/quotes-management/quotes-create-update': 'Create - Update Quotes',
	'/quotes-management/quotes-detail': 'Quotes Detail',

	'/health-food': 'Health Food',
	'/health-food/recipe-list': 'Recipes List',
	'/health-food/recipe-detail': 'Recipes Detail',
	'/health-food/recipe-create-update': 'Recipe Create Update',
	'/health-food/meal-list': 'Meal List',
	'/health-food/meal-detail': 'Meal Detail',
	'/health-food/meal-update': 'Meal Update',
	'/health-food/diet-plan-list': 'Diet Plan List',
	'/health-food/diet-plan-detail': 'Diet Plan Detail',
	'/health-food/diet-plan-update': 'Diet Plan Update',
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const PathBreadcrumbs = () => {
	const location = useLocation();
	const pathname = location.pathname.split('/').filter((x) => x);

	return (
		<Box px={2} py={2}>
			<Breadcrumbs aria-label='breadcrumb'>
				{map(pathname, (_value, index) => {
					const last = index === pathname.length - 1;
					const to = `/${pathname.slice(0, index + 1).join('/')}`;

					if (!breadcrumbNameMap[to]) {
						return;
					}
					return last ? (
						<Typography color='primary.dark' fontWeight={600} key={to}>
							{breadcrumbNameMap[to]}
						</Typography>
					) : (
						<LinkRouter underline='hover' color='inherit' fontWeight={500} to={to} key={to}>
							{breadcrumbNameMap[to]}
						</LinkRouter>
					);
				})}
			</Breadcrumbs>
		</Box>
	);
};

export default PathBreadcrumbs;
