import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  GetIdentityProofList,
  UpdateIdentityProof,
  CreateIdentityProof,
  StatusUpdateIdentityProof,
  DeleteIdentityProof,
} from "api/country-profile/CountryProfileAPI";

const initialState = {
  list: [],
  loading: false,
};

export const getIdentityProofList = createAsyncThunk(
  "countryIdentityProof/getIdentityProofList",
  async (data) => {
    return await GetIdentityProofList(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const createIdentityProof = createAsyncThunk(
  "countryIdentityProof/createIdentityProof",
  async (data) => {
    return await CreateIdentityProof(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const updateIdentityProof = createAsyncThunk(
  "countryIdentityProof/updateIdentityProof",
  async (data) => {
    return await UpdateIdentityProof(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const statusUpdateIdentityProof = createAsyncThunk(
  "countryIdentityProof/statusUpdateIdentityProof",
  async (data) => {
    return await StatusUpdateIdentityProof(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const deleteIdentityProof = createAsyncThunk(
  "countryIdentityProof/deleteIdentityProof",
  async (data) => {
    return await DeleteIdentityProof(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const IdentityProofSlice = createSlice({
  name: "countryIdentityProof",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getIdentityProofList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIdentityProofList.fulfilled, (state, { payload }) => {
        state.list = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getIdentityProofList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createIdentityProof.pending, (state) => {
        state.loading = true;
      })
      .addCase(createIdentityProof.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createIdentityProof.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateIdentityProof.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateIdentityProof.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateIdentityProof.rejected, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateIdentityProof.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusUpdateIdentityProof.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateIdentityProof.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteIdentityProof.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteIdentityProof.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteIdentityProof.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default IdentityProofSlice.reducer;
