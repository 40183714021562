import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { get, filter, map, cloneDeep, uniqueId } from 'lodash';
import * as Yup from 'yup';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { toast } from 'react-toastify';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import CardContent from '@mui/material/CardContent';
import ContentForm from './ContentForm';

const ValidationSchema = Yup.object().shape({
	content: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().min(3).max(1000).required('Title Required'),
			subTitle: Yup.string().min(3).max(1000).required('Sub Title Required'),
			descriptionTitle: Yup.string().min(3).max(1000).required('Description Title Required'),
			descriptionUrl: Yup.mixed().nullable(),
			footerTitle: Yup.string().min(3).max(1000).required('Footer Title Required'),
			footerSubTitle: Yup.string().min(3).max(1000).required('Footer Sub Title Required'),
			footerDescription: Yup.string().min(3).max(1000).required('Footer Description Required'),
			// seoTitle: Yup.string().min(8).max(60).required('Seo title required'),
			// seoDescription: Yup.string().min(8).max(155).required('Seo description required'),
			// seoMetaTags: Yup.array().min(3).max(10).required('Seo meta tags required'),
		})
	),
});

const getInitialValue = (data, languageList) => {
	const state = {};

	state.content = map(languageList, (language) => {
		const initialValue = filter(get(data, 'content', []), (content) => content?.language === language?._id)[0];

		return {
			title: initialValue?.title || '',
			subTitle: initialValue?.subTitle || '',
			descriptionTitle: initialValue?.descriptionTitle || '',
			descriptionUrl: initialValue?.descriptionUrl || '',
			footerTitle: initialValue?.footerTitle || '',
			footerSubTitle: initialValue?.footerSubTitle || '',
			footerDescription: initialValue?.footerDescription || '',
			seoTitle: initialValue?.seoTitle || '',
			seoDescription: initialValue?.seoDescription || '',
			seoMetaTags: initialValue?.seoMetaTags || [],
			language: language._id,
			languageName: language.title,
			languageId: language._id,
		};
	});

	return state;
};

const ProgrammaticSeoForm = () => {
	const dispatch = useDispatch();
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const [loading, setLoading] = useState(false);
	const [details, setDetails] = useState({});
	const [openIndex, setOpenIndex] = useState(0);

	async function getDetails() {
		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `/admin/programmatic-seo/get`,
			});
			if (response.status === 200) {
				setDetails(response.data.data);
				return;
			}
		} catch (error) {}
	}
	useEffect(() => {
		getDetails();
	}, []);

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const handleSubmit = async (values) => {
		setLoading(true);

		try {
			const data = { ...values, id: details?._id };
			const formData = new FormData();

			data.content.forEach((contentDetails) => {
				contentDetails.descriptionUrl.forEach((item) => {
					if (item.file) {
						const id = uniqueId();
						formData.append(id, item.file, `file.${item.file.type?.split('/').pop()}`);
						item.file = id;
					}
				});
			});
			formData.append('content', JSON.stringify(data));

			const response = await AxiosDefaultSetting({
				url: `/admin/programmatic-seo/add`,
				method: 'POST',
				data: formData,
				contentType: 'multipart/form-data',
				onUploadProgress: function (progressEvent) {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					const content = `Uploading files: ${percentCompleted}%`;
					console.log(content);
				},
			});
			if (response.status === 200) {
				toast.success(response.data.message);
			}
		} catch (error) {
			console.log(error);
		}

		setLoading(false);
	};

	return (
		<MDCard>
			<MDCardHeader title='Programmatic SEO' />
			<CardContent className='h-100'>
				{languageConfigList?.length > 0 && (
					<Formik
						initialValues={getInitialValue(details, languageConfigList)}
						validationSchema={ValidationSchema}
						onSubmit={(values, formikHelpers) => {
							handleSubmit(cloneDeep(values), formikHelpers);
						}}
						enableReinitialize
					>
						{(formProps) => (
							<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto pt-2'>
								<Grid container rowSpacing={2} columnSpacing={4}>
									<Grid item xs={12}>
										<ContentForm formProps={formProps} openIndex={openIndex} setOpenIndex={setOpenIndex} />
									</Grid>
								</Grid>
								<DialogActions>
									<MDLoadingButton type='submit' variant='contained' loading={loading}>
										Submit
									</MDLoadingButton>
								</DialogActions>
							</form>
						)}
					</Formik>
				)}
			</CardContent>
		</MDCard>
	);
};

export default ProgrammaticSeoForm;
