import { get, map } from "lodash";
import moment from "moment";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import stringToColor from "components/hooks/stringToColor";

const MealDetail = (props) => {
  const { mealDetail } = props;

  console.log("mealDetail", mealDetail);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box>
          <Stack direction="column" spacing={2}>
            <Box>
              <Typography
                variant="h5"
                fontWeight={700}
                textTransform="capitalize"
              >
                {get(mealDetail, "title", "")}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                fontWeight={400}
                textTransform="capitalize"
              >
                {get(mealDetail, "subTitle", "")}
              </Typography>
            </Box>
            <Box>
              <Stack
                direction={{ sm: "row", xs: "column" }}
                alignItems={{ sm: "center", xs: "flex-start" }}
                spacing={1}
              >
                <Box>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    className="gap-2"
                  >
                    <Typography variant="body2" textTransform="capitalize">
                      Created By
                    </Typography>
                    <Typography
                      variant="body2"
                      textTransform="capitalize"
                      fontWeight={600}
                    >
                      {get(mealDetail, "createdBy", "") === "MASTER_ADMIN" ? (
                        <>
                          {get(mealDetail, "adminId.firstName", "") +
                            " " +
                            get(mealDetail, "adminId.lastName", "")}
                        </>
                      ) : (
                        <>
                          {get(mealDetail, "consultantId.firstName", "") +
                            " " +
                            get(mealDetail, "consultantId.lastName", "")}
                        </>
                      )}
                    </Typography>
                    <Chip
                      label={get(mealDetail, "createdBy", "").replace("_", " ")}
                      variant="outlined"
                      color="primary"
                      size="small"
                    />
                  </Stack>
                </Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ display: { sm: "block", xs: "none" } }}
                />
                <Box>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    spacing={1}
                  >
                    <Typography variant="body2" textTransform="capitalize">
                      Published on
                    </Typography>
                    <Typography
                      variant="body2"
                      textTransform="capitalize"
                      fontWeight={600}
                    >
                      {moment(get(mealDetail, "createdAt", ""), [
                        "MM-DD-YYYY",
                        "YYYY-MM-DD",
                      ]).format("LL")}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack direction="row" flexWrap="wrap" className="gap-2">
                <Chip
                  label={get(mealDetail, "language.name", "NA")}
                  sx={{
                    backgroundColor: stringToColor(
                      get(mealDetail, "language.name", "NA")
                    ),
                    color: "primary.contrastText",
                  }}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Stack direction="column" spacing={1}>
            <DetailWrapper fullWidth="true">
              <DetailTitle
                title="Description"
                sx={{ color: "text.primary" }}
                colon={true}
              />
              <DetailValue>{get(mealDetail, "description", "")}</DetailValue>
            </DetailWrapper>
            <DetailWrapper fullWidth="true">
              <DetailTitle
                title="Dietary Preference"
                sx={{ color: "text.primary" }}
                colon={true}
              />
              <DetailValue>{get(mealDetail, "dietPreference", "")}</DetailValue>
            </DetailWrapper>
            <DetailWrapper fullWidth="true">
              <DetailTitle
                title="Additional Supplement / Nutrition"
                sx={{ color: "text.primary" }}
                colon={true}
              />
              <DetailValue>
                {get(mealDetail, "additionalSupplementOrNutrition", "") ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </DetailValue>
            </DetailWrapper>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Recipe :-
          </Typography>
          <Box>
            <Stack direction="column" spacing={1}>
              <DetailWrapper fullWidth="true">
                <DetailTitle
                  title="Recipe"
                  sx={{ color: "text.primary" }}
                  colon={true}
                />
                <DetailValue>
                  {get(mealDetail, "recipe.recipe", "")}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle
                  title="Amount"
                  sx={{ color: "text.primary" }}
                  colon={true}
                />
                <DetailValue>
                  {get(mealDetail, "recipe.amount", "")}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle
                  title="Size"
                  sx={{ color: "text.primary" }}
                  colon={true}
                />
                <DetailValue>{get(mealDetail, "recipe.size", "")}</DetailValue>
              </DetailWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Raw Food :-
          </Typography>
          {map(
            get(mealDetail, "rawFood", []),
            (rawFoodItem, rawFoodIndex, { length }) => {
              return (
                <Box
                  key={rawFoodIndex}
                  mb={rawFoodIndex !== length - 1 ? 1 : 0}
                  className="border-bottom border-light pb-2"
                >
                  <Stack direction="column">
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Food"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>{get(rawFoodItem, "food", "")}</DetailValue>
                    </DetailWrapper>
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Amount"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>
                        {get(rawFoodItem, "amount", "")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Size"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>{get(rawFoodItem, "size", "")}</DetailValue>
                    </DetailWrapper>
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Nutrition Value"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>
                        {get(rawFoodItem, "nutritionValue", "")}
                      </DetailValue>
                    </DetailWrapper>
                  </Stack>
                </Box>
              );
            }
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" fontWeight={600} gutterBottom>
            Supplement or Nutrition :-
          </Typography>
          {map(
            get(mealDetail, "supplementOrNutrition", []),
            (supplementItem, supplementIndex, { length }) => {
              return (
                <Box
                  key={supplementIndex}
                  mb={supplementIndex !== length - 1 ? 1 : 0}
                  className="border-bottom border-light pb-2"
                >
                  <Stack direction="column">
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Supplement"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>
                        {get(supplementItem, "supplement", "")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Amount"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>
                        {get(supplementItem, "amount", "")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper fullWidth="true">
                      <DetailTitle
                        title="Size"
                        sx={{ color: "text.primary" }}
                        colon={true}
                      />
                      <DetailValue>
                        {get(supplementItem, "size", "")}
                      </DetailValue>
                    </DetailWrapper>
                  </Stack>
                </Box>
              );
            }
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" fontWeight={500} className="mb-3">
            Personal Information
          </Typography>
          <Stack
            direction={{ sm: "row", xs: "column" }}
            flexWrap="wrap"
            justifyContent="space-between"
            sx={{ rowGap: 1.5, columnGap: 2 }}
          >
            <Box>
              <DetailWrapper>
                <DetailTitle title="Name" colon={true} />
                <DetailValue>
                  {get(mealDetail, "createdBy", "") === "MASTER_ADMIN" ? (
                    <>
                      {get(mealDetail, "adminId.firstName", "") +
                        " " +
                        get(mealDetail, "adminId.lastName", "")}
                    </>
                  ) : (
                    <>
                      {get(mealDetail, "consultantId.firstName", "") +
                        " " +
                        get(mealDetail, "consultantId.lastName", "")}
                    </>
                  )}
                  <Chip
                    label={get(mealDetail, "createdBy", "").replace("_", " ")}
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="ms-2"
                  />
                </DetailValue>
              </DetailWrapper>
            </Box>
            <Box>
              <DetailWrapper>
                <DetailTitle title="E-mail" colon={true} />
                <DetailValue>
                  {get(mealDetail, "createdBy", "") === "MASTER_ADMIN" ? (
                    <>{get(mealDetail, "adminId.email", "")}</>
                  ) : (
                    <>{get(mealDetail, "consultantId.email", "")}</>
                  )}
                </DetailValue>
              </DetailWrapper>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MealDetail;
