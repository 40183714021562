import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAllergiesTypeData,
  GetBenefits,
  GetCuisineData,
  GetDietaryPreferenceData,
  GetFoodAllergiesData,
  GetGoalTypeData,
  GetSocialMediaChannelData,
  GetSpecializationData,
} from "api/common/GeneralConfigDataAPI";
import { get } from "lodash";

const initialState = {
  dietaryPreferenceData: [],
  goalTypeData: [],
  specializationData: [],
  socialMediaChannelData: [],
  foodAllergiesData: [],
  allergiesTypeData: [],
  cuisineData: [],
  benefitsList: [],
  loading: false,
};

export const getDietaryPreferenceData = createAsyncThunk(
  "generalConfigData/getDietaryPreferenceData",
  async () => {
    return await GetDietaryPreferenceData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const getGoalTypeData = createAsyncThunk(
  "generalConfigData/getGoalTypeData",
  async () => {
    return await GetGoalTypeData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const getSpecializationData = createAsyncThunk(
  "generalConfigData/getSpecializationData",
  async () => {
    return await GetSpecializationData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);

export const getBenefits = createAsyncThunk(
  "generalConfigData/getBenefits",
  async () => {
    return await GetBenefits()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);

export const getSocialMediaChannelData = createAsyncThunk(
  "generalConfigData/getSocialMediaChannelData",
  async () => {
    return await GetSocialMediaChannelData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const getFoodAllergiesData = createAsyncThunk(
  "generalConfigData/getFoodAllergiesData",
  async () => {
    return await GetFoodAllergiesData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const getAllergiesTypeData = createAsyncThunk(
  "generalConfigData/getAllergiesTypeData",
  async () => {
    return await GetAllergiesTypeData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const getCuisineData = createAsyncThunk(
  "generalConfigData/getCuisineData",
  async () => {
    return await GetCuisineData()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response?.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);

export const GeneralConfigDataSlice = createSlice({
  name: "generalConfigData",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDietaryPreferenceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDietaryPreferenceData.fulfilled, (state, action) => {
        state.dietaryPreferenceData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getDietaryPreferenceData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getGoalTypeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGoalTypeData.fulfilled, (state, action) => {
        state.goalTypeData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getGoalTypeData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSpecializationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSpecializationData.fulfilled, (state, action) => {
        state.specializationData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getSpecializationData.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getBenefits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBenefits.fulfilled, (state, action) => {
        state.benefitsList = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getBenefits.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getSocialMediaChannelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSocialMediaChannelData.fulfilled, (state, action) => {
        state.socialMediaChannelData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getSocialMediaChannelData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFoodAllergiesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFoodAllergiesData.fulfilled, (state, action) => {
        state.foodAllergiesData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getFoodAllergiesData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllergiesTypeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllergiesTypeData.fulfilled, (state, action) => {
        state.allergiesTypeData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getAllergiesTypeData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCuisineData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCuisineData.fulfilled, (state, action) => {
        state.cuisineData = get(action.payload, "data", []);
        state.loading = false;
      })
      .addCase(getCuisineData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default GeneralConfigDataSlice.reducer;
