import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// Form
import { Formik } from 'formik';
import { get, map, omit, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
// MUI
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
// Custom
// Store
import { getSpecializationData } from 'store/slice/common/GeneralConfigDataSlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import { Button, Typography, alpha } from '@mui/material';
import translate from 'hooks/translate';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { useTheme } from '@emotion/react';
import TopSection from './TopSection';
import ContentSection from './ContentSection';
import SeoSection from './SeoSection';
import { cloneDeep } from 'lodash';
import { uniqueId } from 'lodash';
import axios from 'axios';
import { addJob, jobTypes } from 'components/common/JobQueue';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from 'components/common/v2/MultiSelectSpecialization';

const validationSchema = Yup.object().shape({
	title: Yup.string().min(3, 'Title is too short').max(1000, 'Title is too long').required('Title is required'),
	subTitle: Yup.string().min(3, 'Subtitle is too short').max(1000, 'Subtitle is too long'),
	language: Yup.object().shape().required('Language is required').nullable(),
	categories: Yup.array().min(1, 'Min 1 Category').max(3, 'Max 3 Category').nullable(),
	topSection: Yup.array().of(
		Yup.object().shape({
			title: Yup.string()
				.min(3, 'Image title is too short')
				.max(500, 'Image title is too long')
				.required('Image title is required'),
			imageUrl: Yup.mixed().when('imageType', {
				is: 'IMAGE_UPLOAD',
				then: Yup.array().required('Image is required'),
				otherwise: Yup.mixed().required('Image link is required'),
			}),
		})
	),
	contentSection: Yup.array().of(
		Yup.object().shape({
			data: Yup.string()
				.when(['preferredOption'], (preferredOption) => {
					if (preferredOption === 'TEXT') {
						return Yup.string().required('Content is required');
					}
				})
				.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
					if (preferredOption === 'IMAGE' && dataType === 'IMAGE_LINK') {
						return (
							Yup.mixed()
								// .url("please enter valid URL")
								.required('Image Link is required')
						);
					} else if (preferredOption === 'IMAGE' && dataType === 'IMAGE_UPLOAD') {
						return Yup.mixed().required('Image is required');
					}
				})
				.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
					if (preferredOption === 'VIDEO' && dataType === 'VIDEO_LINK') {
						return Yup.string().url('please enter valid URL').required('Video Link is required');
					} else if (preferredOption === 'VIDEO' && dataType === 'VIDEO_UPLOAD') {
						return Yup.string().required('Video is required');
					}
				}),
			title: Yup.string().when(['preferredOption'], (preferredOption) => {
				if (
					preferredOption === 'IMAGE' ||
					preferredOption === 'VIDEO' ||
					preferredOption === 'AUDIO' ||
					preferredOption === 'PDF'
				) {
					return Yup.string()
						.min(3, 'Title is very Short')
						.max(500, 'Title is very Long')
						.required('Title is required');
				}
			}),
		})
	),
	seoTitle: Yup.string().min(3, 'Title is too short').max(1000, 'Title is too long').required('Title is required'),
	seoDescription: Yup.string()
		.min(3, 'Discription is too short')
		.max(1000, 'Discription is too long')
		.required('Discription is required'),
	seoMetaTags: Yup.array()
		.min(3, 'Meta tag is too short (minimum 3 tags are required)')
		.max(10, 'Meta tag is too long (minimum 10 tags can be added)')
		.required('Meta tag is required'),
});

const LoadingOverlay = ({ jobId, setIsFormSubmitting }) => {
	const navigate = useNavigate();
	const { palette } = useTheme();
	const [content] = useState('Saving...');
	const [showBackgroundJobBox] = useState(false);

	return (
		<Box
			position='absolute'
			top={0}
			left={0}
			right={0}
			bottom={0}
			display='flex'
			justifyContent='center'
			alignItems='center'
			backgroundColor={alpha(palette.primary.main, 0.3)}
			sx={{ backdropFilter: 'blur(1px)' }}
			zIndex={99999}
		>
			<Box
				width='100%'
				maxWidth='650px'
				bgcolor='white'
				padding='10px'
				display='flex'
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				gap={1}
			>
				<Typography variant='h5' fontWeight='bold'>
					{content}
				</Typography>
				{showBackgroundJobBox && (
					<Button variant='outlined' onClick={() => navigate('/dashboard/article-blog')}>
						Process in background
					</Button>
				)}
			</Box>
		</Box>
	);
};

const ArticleCreateUpdateForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [LanguageList, setLanguageList] = useState([]);
	const [checkImage, setCheckImage] = useState(true);
	const articleDetail = get(location, 'state.articleDetail', {});
	const formType = get(location, 'state.formType', {});
	const isContentCreationPending = get(articleDetail, 'status', '') === 'pending';
	const [defaultLanguage, setDefaultLanguage] = useState(JSON.parse(localStorage.getItem('selectedHeaderLanguage')));

	const [jobId, setJobId] = useState(null);
	const specializationList = useSelector((state) => state.generalConfigData.specializationData);
	const selectedLanguage = localStorage.getItem('selectedHeaderLanguage');
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	useEffect(() => {
		selectedLanguage && setDefaultLanguage(JSON.parse(selectedLanguage));
	}, [selectedLanguage]);

	const handleSubmit = async (values, formikHelpers) => {
		const totalContentLength = get(values, 'contentSection', []).reduce((accumulator, currentValue) => {
			if (currentValue.preferredOption !== 'TEXT') return accumulator;

			const contentLength = currentValue.data_content_len;
			if (contentLength) return accumulator + contentLength;

			const textLength = get(currentValue, 'data', '').split(' ').length;
			return accumulator + textLength;
		}, 0);
		if (totalContentLength < 500) {
			toast.error('Content should be more than 500 words!');
			return;
		}
		if (totalContentLength > 3500) {
			toast.error('Content should be less than 3500 words!');
			return;
		}

		setIsFormSubmitting(true);
		const selectedImage = values?.topSection?.filter((item) => item.selectedImageIndex === 0);
		let check = values?.topSection.some((item) => item.selectedImageIndex === 0);
		setCheckImage(check);

		const thumbnailData =
			selectedImage[0]?.imageType === 'IMAGE_UPLOAD'
				? selectedImage.map((elem) => {
						const imageObject = elem.imageUrl.filter((item) => item.type === 'horizontal')[0];

						let imageUrlType = '';
						let imageUrl = imageObject.url;
						if (imageObject.file) {
							imageUrl = imageObject.file;
							imageUrlType = 'file';
						}
						return { ...elem, imageUrl: imageUrl, imageUrlType };
				  })
				: selectedImage;

		const data = {
			...omit(values, 'subCategories', 'contentSection'),
			language: get(values, 'language._id', ''),
			thumbnail: thumbnailData,
			categories: getSpecializationSubmitData(values, values.subCategories, 'categories', 'subCategories'),
			contentSection: map(get(values, 'contentSection', []), (contentSection) => {
				return omit(contentSection, 'mediaFile', '_id', 'data_content_len');
			}),
		};
		if (formType === 'update') {
			const newData = thumbnailData.map(({ _id, ...rest }) => rest);
			data._id = get(articleDetail, '_id', '');
			data.thumbnail = newData;
			data.topSection = map(get(values, 'topSection', []), (topSection) => omit(topSection, '_id'));
		}

		const fileMap = {};

		data.topSection.forEach((section) => {
			section.imageUrl.forEach((item) => {
				if (item.file) {
					const id = uniqueId();
					fileMap[id] = item.file;
					item.file = id;
				}
			});
		});
		data.thumbnail.forEach((item) => {
			if (item.imageUrlType === 'file') {
				const id = uniqueId();
				fileMap[id] = item.imageUrl;
				item.imageUrl = id;
				item.imageUrlType = 'file';
			}
		});
		data.contentSection.forEach((item) => {
			if (item.dataType === 'VIDEO_UPLOAD') {
				if (typeof item.data !== 'string') {
					const id = uniqueId();
					fileMap[id] = item.data;
					item.data = id;
					item.file = true;
				}
			} else if (item.dataType === 'IMAGE_UPLOAD') {
				item.data.forEach((item) => {
					if (item.file) {
						const id = uniqueId();
						fileMap[id] = item.file;
						item.file = id;
					}
				});
			}
		});

		if (!check) {
			setIsFormSubmitting(false);
			return;
		}
		const res = await axios.post('/admin/articles-blog/add', { content: data });
		if (res.status === 200 && Array.isArray(res.data)) {
			const jobs = res.data
				.map((item) => {
					const file = fileMap[item.data];
					if (!file) return null;
					return { file, meta: item };
				})
				.filter(Boolean);

			const jobIds = addJob({
				payload: jobs,
				jobType: jobTypes.BLOG_CREATE,
				title: `Uploading files for ${data.title}`,
				_id: uniqueId(),
			});
			setJobId(jobIds);
			navigate('/articles-blog');
		}
	};

	const getLanguageList = async () => {
		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `/public/translate/language`,
			});

			if (response.data.data?.length) {
				setLanguageList(response.data.data);
			}
		} catch (error) {}
	};

	useEffect(() => {
		dispatch(getSpecializationData());
		dispatch(getLanguageConfigList());
		getLanguageList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card>
			<CardContent>
				{specializationList.length > 0 && (
					<Formik
						initialValues={{
							title: get(articleDetail, 'title', ''),
							subTitle: get(articleDetail, 'subTitle', ''),
							language:
								formType === 'update'
									? get(articleDetail, 'language[0]', '')
									: isEmpty(defaultLanguage)
									? LanguageList[0]
									: defaultLanguage,
							categories: getInitSpecialization(articleDetail, specializationList, 'categories'),
							subCategories: getInitSubSpecialization(
								articleDetail,
								specializationList,
								'categories',
								'subCategories'
							),
							topSection:
								articleDetail?.topSection?.imageType === 'IMAGE_LINK'
									? get(articleDetail, 'topSection', [
											{
												imageType: 'IMAGE_LINK',
												title: '',
												imageUrl: '',
												selectedImageIndex: 0,
											},
									  ])
									: get(articleDetail, 'topSection', [
											{
												imageType: 'IMAGE_UPLOAD',
												title: '',
												imageUrl: '',
												selectedImageIndex: 0,
											},
									  ]),

							contentSection: get(articleDetail, 'contentSection', [
								{
									preferredOption: 'TEXT', //TEXT/IMAGE/VIDEO
									dataType: 'TEXT',
									data: '',
									title: '',
									data_content_len: 0,
								},
							]),

							isPublished: get(articleDetail, 'isPublished', false),
							ratingStatus: get(articleDetail, 'ratingStatus', false),
							likeStatus: get(articleDetail, 'likeStatus', false),
							comments: get(articleDetail, 'comments', false),
							seoTitle: get(articleDetail, 'seoTitle', ''),
							seoDescription: get(articleDetail, 'seoDescription', ''),
							seoMetaTags: get(articleDetail, 'seoMetaTags', []),
						}}
						validationSchema={validationSchema}
						onSubmit={(values, formikHelpers) => {
							handleSubmit(cloneDeep(values), formikHelpers);
						}}
					>
						{(props) => (
							<form onSubmit={props.handleSubmit}>
								{console.log(props.errors)}
								<Grid container spacing={2} justifyContent='center'>
									{isFormSubmitting && (
										<LoadingOverlay jobId={jobId} setIsFormSubmitting={setIsFormSubmitting} />
									)}
									<Grid item xs={12}>
										<TextField
											label={translate('common_title')}
											name='title'
											fullWidth
											multiline
											value={props.values.title}
											onChange={(e, value) => {
												props.setFieldValue('title', e.target.value);
												props.setFieldValue('seoTitle', e.target.value);
											}}
											onBlur={props.handleBlur}
											error={props.errors.title && props.touched.title ? true : false}
											helperText={props.errors.title && props.touched.title ? props.errors.title : null}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextField
											label={translate('common_subtitle')}
											name='subTitle'
											fullWidth
											multiline
											value={props.values.subTitle}
											onChange={(e) => {
												props.setFieldValue('subTitle', e.target.value);
											}}
											onBlur={props.handleBlur}
											error={props.errors.subTitle && props.touched.subTitle ? true : false}
											helperText={
												props.errors.subTitle && props.touched.subTitle ? props.errors.subTitle : null
											}
										/>
									</Grid>

									<Grid item xs={12}>
										<FormControl fullWidth>
											<Autocomplete
												getOptionLabel={(option) => {
													return option.title ?? '';
												}}
												options={LanguageList}
												name='language'
												value={props.values.language}
												isOptionEqualToValue={(option, value) => {
													if (value === '' || option._id === value._id) {
														return true;
													}
												}}
												onChange={(_e, value) => {
													props.setFieldValue('language', value);
												}}
												onBlur={props.handleBlur}
												disablePortal
												autoHighlight
												disableClearable
												noOptionsText={translate('common_no_data')}
												popupIcon={<KeyboardArrowDownSharpIcon />}
												renderInput={(params) => (
													<TextField
														{...params}
														label={translate('common_language')}
														placeholder={translate('consultant_select_language')}
														name='language'
														error={props.errors.language && props.touched.language ? true : false}
														helperText={
															props.errors.language && props.touched.language
																? props.errors.language
																: null
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={12}>
										<MultiSelectSpecialization
											isDefaultSEOmetaTag={true}
											specialization={specializationList}
											fieldName='categories'
											subFieldName='subCategories'
											formProps={props}
											initialData={articleDetail}
											isSubmit={false}
										/>
									</Grid>

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----TOP SECTION----- */}

									<TopSection
										formType={formType}
										formProps={props}
										checkImage={checkImage}
										setCheckImage={setCheckImage}
										isPreviewOpen={false}
									/>

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----CONTENT SECTION----- */}

									<ContentSection formProps={props} formType={formType} isPreviewOpen={false} />

									{/* -----CONFIGURATION----- */}
									{/* <Grid item xs={12}>
										<Stack direction='row' className='gap-1'>
											<Typography variant='h4' className='mb-2'>
												{translate('consultant_configuration_section')}
											</Typography>{' '}
											<CMToolTip
												icon={<HelpTwoToneIcon size='small' />}
												title=''
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_publish')}</Typography>
											<Switch
												color='primary'
												checked={props.values.isPublished}
												onChange={(e) => props.setFieldValue('isPublished', e.target.checked)}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_enabled_comments')}</Typography>
											<Switch
												color='primary'
												checked={props.values.comments}
												onChange={(e) => props.setFieldValue('comments', e.target.checked)}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_enabled_rating')}</Typography>
											<Switch
												color='primary'
												checked={props.values.ratingStatus}
												onChange={(e) => props.setFieldValue('ratingStatus', e.target.checked)}
											/>
										</Stack>
									</Grid>
									<Grid item xs={12} sm={6} lg={3}>
										<Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
											<Typography variant='body1'>{translate('consultant_enabled_like')}</Typography>
											<Switch
												color='primary'
												checked={props.values.likeStatus}
												onChange={(e) => props.setFieldValue('likeStatus', e.target.checked)}
											/>
										</Stack>
									</Grid> */}

									<Grid item xs={12}>
										<Divider orientation='horizontal' flexItem />
									</Grid>

									{/* -----SEO SECTION----- */}

									<SeoSection
										isContentCreationPending={isContentCreationPending}
										formProps={props}
										isFormSubmitting={isFormSubmitting}
									/>
								</Grid>
							</form>
						)}
					</Formik>
				)}
			</CardContent>
		</Card>
	);
};

export default ArticleCreateUpdateForm;
