import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EventIcon from "@mui/icons-material/Event";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";

import { Formik } from "formik";
import { find, get, isEmpty, map, omit, isString } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDDialog from "components/md-components/dialog/MDDialog";
import MDRadio from "components/md-components/form/MDRadio";
import FilePicker from "components/utility/file-picker/FilePicker";
import { getIdentityProofList } from "store/slice/country-profile/IdentityProofSlice";
import { updateConsultantBasicInfo } from "store/slice/user-management/ConsultantProfileSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";

const ConsultantBasicInfoForm = (props) => {
  const { title, dialogOpen, handleDialogClose, userDetails, userType } = props;
  const dispatch = useDispatch();

  // const isLoading = useSelector((state) => state.consultantProfile.loading);
  const countriesList = useSelector((state) => state.commonData.countryList);
  const identityProofList = useSelector(
    (state) => state.countryIdentityProof.list
  );
  const [countryId, setCountryId] = useState(
    get(
      find(countriesList, { _id: get(userDetails, "citizenship._id", "") }),
      "_id",
      ""
    )
  );

  const formValidations = Yup.object().shape({
    citizenship: Yup.object().nullable().required("Citizenship is required."),
    birthCitizenship: Yup.object()
      .nullable()
      .required("Birth citizenship is required."),
    DOB: Yup.string().required("Date of Birth is required."),
    expireDate: Yup.string().required("Expire date is required."),
    proofOfIdentity: Yup.string().required("Proof of Identity is required."),
    frontFile: Yup.mixed().required("Front file is required."),
    backFile: Yup.mixed().required("Back file is required."),
  });

  const handleSubmit = (values) => {
    let filterValue = values;
    if (isString(get(filterValue, "frontFile"))) {
      filterValue = omit(filterValue, "frontFile");
    }
    if (isString(get(filterValue, "backFile"))) {
      filterValue = omit(filterValue, "backFile");
    }

    const payloadData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: {
        ...filterValue,
        citizenship: get(filterValue, "citizenship._id", ""),
        birthCitizenship: get(filterValue, "birthCitizenship._id", ""),
      },
    };

    dispatch(updateConsultantBasicInfo(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(
            get(result, "message", "Basic info update successfully")
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    if (!isEmpty(countryId)) {
      dispatch(getIdentityProofList({ countryId: countryId }));
    }
  }, [countryId, dispatch]);

  return (
    <MDDialog
      title={title}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
      scroll="paper"
    >
      <Formik
        initialValues={{
          citizenship: get(userDetails, "citizenship", ""),
          birthCitizenship: get(userDetails, "birthCitizenship", ""),
          DOB: get(userDetails, "DOB", ""),
          proofOfIdentity: get(userDetails, "proofOfIdentity._id", ""),
          frontFile: get(userDetails, "identityFrontUrl", ""),
          backFile: get(userDetails, "identityBackUrl", ""),
          expireDate: get(userDetails, "expireDate", ""),
        }}
        validationSchema={formValidations}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    size="small"
                    id="citizenship"
                    name="citizenship"
                    value={props.values.citizenship}
                    options={!isEmpty(countriesList) ? countriesList : []}
                    getOptionLabel={(option) => option.name ?? option}
                    isOptionEqualToValue={(option, value) => {
                      if (option?._id === value?._id || option._id === "") {
                        return true;
                      }
                    }}
                    onChange={(_event, value) => {
                      props.setFieldValue("citizenship", value);
                      setCountryId(value?._id);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {`${option.flag} ${option.name}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Citizenship"
                        error={
                          props.errors.citizenship && props.touched.citizenship
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.citizenship && props.touched.citizenship
                            ? props.errors.citizenship
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "citizenship",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    size="small"
                    id="birthCitizenship"
                    name="birthCitizenship"
                    value={props.values.birthCitizenship}
                    options={!isEmpty(countriesList) ? countriesList : []}
                    getOptionLabel={(option) => option.name ?? option}
                    isOptionEqualToValue={(option, value) => {
                      if (option?._id === value?._id || option._id === "") {
                        return true;
                      }
                    }}
                    onChange={(_event, value) => {
                      props.setFieldValue("birthCitizenship", value);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {`${option.flag} ${option.name}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Birth Citizenship"
                        error={
                          props.errors.birthCitizenship &&
                          props.touched.birthCitizenship
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.birthCitizenship &&
                          props.touched.birthCitizenship
                            ? props.errors.birthCitizenship
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MobileDatePicker
                    label="Date of Birth"
                    name="DOB"
                    value={props.values.DOB}
                    onChange={(newValue) => {
                      props.setFieldValue("DOB", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          props.errors.DOB && props.touched.DOB ? true : false
                        }
                        helperText={
                          props.errors.DOB && props.touched.DOB
                            ? props.errors.DOB
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={params.inputProps.onClick}
                              >
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MobileDatePicker
                    label="Expiration Date"
                    name="expireDate"
                    value={props.values.expireDate}
                    onChange={(newValue) => {
                      props.setFieldValue("expireDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          props.errors.expireDate && props.touched.expireDate
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.expireDate && props.touched.expireDate
                            ? props.errors.expireDate
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle-expire-date"
                                edge="end"
                                onClick={params.inputProps.onClick}
                              >
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mb={3}>
                    <FormControl>
                      <FormLabel htmlFor="proofOfIdentity" className="mb-2">
                        Proof of Identity
                      </FormLabel>
                      <RadioGroup
                        id="proofOfIdentity"
                        aria-labelledby="proof-of-identity-group"
                        name="proofOfIdentity"
                        value={props.values.proofOfIdentity}
                        onChange={props.handleChange}
                        row={true}
                        className="gap-2"
                      >
                        {map(identityProofList, (item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              checked={
                                item?._id === props.values.proofOfIdentity
                              }
                              value={item?._id}
                              control={<MDRadio />}
                              label={item?.title}
                              className="ms-1 me-0"
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <FilePicker
                        label="Front Side"
                        fileLabel={true}
                        id="frontFilePicker"
                        name="frontFile"
                        value={props.values.frontFile}
                        setFieldValue={props.setFieldValue}
                        error={
                          props.errors.frontFile && props.touched.frontFile
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.frontFile && props.touched.frontFile
                            ? props.errors.frontFile
                            : false
                        }
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <FilePicker
                        label="Back Side"
                        fileLabel={true}
                        id="backFilePicker"
                        name="backFile"
                        value={props.values.backFile}
                        setFieldValue={props.setFieldValue}
                        error={
                          props.errors.backFile && props.touched.backFile
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.backFile && props.touched.backFile
                            ? props.errors.backFile
                            : false
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                // loading={props.isSubmitting || isLoading}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default ConsultantBasicInfoForm;
