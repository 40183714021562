import { FieldArray, Formik, getIn } from "formik";
import { find, get, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDDialog from "components/md-components/dialog/MDDialog";
import { addLanguageMacro } from "store/slice/language-config/LanguageConfigSlice";

const AddMicroForm = (props) => {
  const { title, open, handleClose } = props;

  const dispatch = useDispatch();
  const LanguageList = useSelector((state) => state.languageConfig.languageList);
  const isLoading = useSelector((state) => state.languageConfig.loading);

  const macroType = [
    { label: "Common", value: "common" },
    { label: "System 1 (Front)", value: "system_1" },
    { label: "System 2 (Consultant)", value: "system_2" },
  ];
  const pageNameList = [
    { label: "Home", value: "home" },
    { label: "Blog", value: "blog" },
    { label: "Blog Details", value: "blog_details" },
    { label: "Knowledge Base", value: "knowledge_base" },
    { label: "Knowledge Base Details", value: "knowledge_base_details" },
    { label: "Knowledge Base Content", value: "knowledge_base_content" },
    { label: "About Us", value: "about_us" },
    { label: "Consultant", value: "consultant" },
    { label: "Contact Us", value: "contact_us" },
    { label: "Corporate Solution", value: "corporate_solution" },
    { label: "Privacy Policy", value: "privacy_policy" },
    { label: "Programmatic Seo", value: "programmatic_seo" },
    { label: "Terms Of Use", value: "terms_use" },
    { label: "User", value: "user" },
  ];

  const macroArrayList = map(LanguageList, (language, _index) => {
    return { languageId: language._id, value: "", isTranslate: false };
  });

  const getLanguageTitle = (id) => {
    const data = find(LanguageList, (language) => language._id === id);
    return data?.title ?? "";
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    type: Yup.object().required("Type is required."),
    language: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Value is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    const apiData = {
      ...values,
      type: get(values, "type.value", ""),
      pageName: get(values, "pageName.value", ""),
    };

    dispatch(addLanguageMacro(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false) === true) {
          toast.success(get(result, "message", "Language added successfully"));
          handleClose();
        } else {
          toast.error(get(result, "message", "Something went wrong"));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={title}
      open={open}
      close={handleClose}
      scroll="paper"
      aria-labelledby="macro-form-dialog"
      aria-describedby="macro-form-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          name: "",
          type: { label: "Common", value: "common" },
          status: false,
          language: macroArrayList,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className="d-flex flex-column overflow-auto">
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Name"
                    name="name"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.errors.name && props.touched.name ? true : false}
                    helperText={props.errors.name && props.touched.name ? props.errors.name : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    size="small"
                    id="select-macro-type"
                    options={macroType}
                    getOptionLabel={(option) => option.label ?? ""}
                    isOptionEqualToValue={(option, value) => {
                      return option.value === value.value;
                    }}
                    name="type"
                    value={props.values.type}
                    onChange={(_event, newValue) => {
                      props.setFieldValue("type", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        error={props.errors.type && props.touched.type ? true : false}
                        helperText={props.errors.type && props.touched.type ? props.errors.type : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    size="small"
                    id="select-macro-page"
                    options={pageNameList}
                    getOptionLabel={(option) => option.label ?? ""}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    name="pageName"
                    value={props.values.pageName}
                    onChange={(_event, newValue) => {
                      props.setFieldValue("pageName", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Page Name"
                        error={props.errors.pageName && props.touched.pageName ? true : false}
                        helperText={props.errors.pageName && props.touched.pageName ? props.errors.pageName : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="status"
                            checked={props.values.status}
                            value={props.values.status}
                            onChange={(e) => {
                              props.setFieldValue("status", e.target.checked);
                            }}
                            inputProps={{ "aria-label": "Status" }}
                          />
                        }
                        label="Status"
                        sx={{ mr: 0 }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} className="mb-3">
                  <FieldArray name="language">
                    {() => (
                      <Box>
                        {map(props.values.language, (field, index) => {
                          const microValue = `language[${index}.value]`;
                          const errorMicroValue = getIn(props.errors, microValue);
                          const touchedMicroValue = getIn(props.touched, microValue);
                          const isTranslate = `language[${index}.isTranslate]`;

                          return (
                            <Grid container spacing={{ sm: 1.5, xs: 1 }} key={index} className="mb-2">
                              <Grid item xs={12} pt={{ sm: 2, xs: 0 }}>
                                <Divider light />
                              </Grid>
                              <Grid item md={3} sm={4} xs={12}>
                                <FormLabel
                                  htmlFor={`${field.languageId}-value-field`}
                                  className="fw-medium"
                                  sx={{ top: { sm: "8px", xs: 0 } }}
                                >
                                  {getLanguageTitle(field.languageId)}
                                </FormLabel>
                              </Grid>
                              <Grid item md={7} sm={5} xs={12}>
                                <TextField
                                  id={`${field.languageId}-value-field`}
                                  fullWidth={true}
                                  multiline={true}
                                  size="small"
                                  label="Value"
                                  name={microValue}
                                  value={field.value}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={errorMicroValue && touchedMicroValue ? true : false}
                                  helperText={errorMicroValue && touchedMicroValue ? errorMicroValue : null}
                                />
                              </Grid>
                              <Grid item md={2} sm={3} xs={12}>
                                <FormControl component="fieldset" variant="standard">
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={isTranslate}
                                          checked={field.isTranslate}
                                          value={field.isTranslate}
                                          onChange={(e) => {
                                            props.setFieldValue(isTranslate, e.target.checked);
                                          }}
                                          inputProps={{
                                            "aria-label": `is-language-status-${index}`,
                                          }}
                                        />
                                      }
                                      label="Is Translate"
                                      sx={{ mr: 0 }}
                                    />
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Box>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton type="submit" variant="contained" padding="6px 24px" loading={props.isSubmitting && isLoading}>
                Submit
              </MDLoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default AddMicroForm;
