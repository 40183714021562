import AxiosDefault from "services/AxiosDefaultSetting";

export const GetUserGeneralConfigList = async ({ sectionName, level }) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=${sectionName}&level=${level}`,
  });
  return response;
};

export const CreateUserGeneralConfig = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/general-configuration/create`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const UpdateUserGeneralConfig = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/general-configuration/update/${id}`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const StatusUpdateUserGeneralConfig = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/general-configuration/status-update/${id}`,
    data: data,
  });
  return response;
};

export const DeleteUserGeneralConfig = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/general-configuration/delete/${id}`,
    data: data,
  });
  return response;
};

export const IconImageUpload = async (data) => {
  const response = await AxiosDefault({
    url: "/admin/general-configuration/icon/upload",
    method: "POST",
    data: data,
    contentType: "multipart/form-data",
  });
  console.log("icon :>>", response);
  return response;
};
