import React from "react";
import PropTypes from "prop-types";
import { alpha, useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";

const MDCard = (props) => {
  const {
    variant,
    shadow,
    shadowColor,
    shadowColorOpacity,
    borderRadius,
    backgroundColor,
    backgroundColorOpacity,
    sx,
    children,
    ...rest
  } = props;

  const theme = useTheme();

  // const boxShadow = shadow
  //   ? shadow
  //   : shadowColor
  //   ? `0px 8px 24px ${alpha(shadowColor, 0.2)}`
  //   : shadowColorOpacity
  //   ? `0px 8px 24px ${alpha(shadowColor, shadowColorOpacity)}`
  //   : `0px 8px 24px rgba(149, 157, 165, 0.2)`;

  const bgColor = backgroundColor
    ? backgroundColor
    : backgroundColorOpacity
    ? `${alpha(backgroundColor, backgroundColorOpacity)}`
    : theme.palette.background.paper;

  return (
    <Card
      variant={variant ?? "elevated"}
      elevation={variant === "outlined" ? 0 : 5}
      sx={{
        boxShadow: "none",
        borderRadius: borderRadius ? borderRadius : "0.5rem",
        backgroundColor: bgColor,
        backgroundImage: "none",
        borderColor: variant === "outlined" ? "#e9ebec" : "transparent",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Card>
  );
};

MDCard.propTypes = {
  shadow: PropTypes.string,
  shadowColor: PropTypes.string,
  shadowColorOpacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundColorOpacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.any,
};

export default MDCard;
