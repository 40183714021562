import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";

import { ReactComponent as PageNotFoundImage } from "assets/images/page_not_found.svg";
import MDButton from "components/md-components/button/MDButton";
import MDCard from "components/md-components/card/MDCard";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <MDCard className="w-100 h-100 bg-white">
      <CardContent className="h-100">
        <Grid
          container
          className="h-100 justify-content-center align-content-center"
          spacing={4}
        >
          <Grid item lg={4} sm={8} xs={12}>
            <Box className="text-center">
              <PageNotFoundImage className="w-100 h-100" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="text-center mt-4">
              <MDButton
                label="Back To Dashboard"
                variant="contained"
                onClick={() => navigate("/")}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default PageNotFound;
