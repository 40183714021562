import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
	const date = new Date();
	const presentYear = date.getFullYear();

	return (
		<Box
			component='footer'
			sx={{
				backgroundColor: (theme) => theme.palette.background.paper,
			}}
		>
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					px: 3,
					py: 2,
				}}
			>
				<Typography
					variant='body1'
					sx={{
						color: (theme) => theme.palette.text.primary,
						fontSize: { sm: '1rem', xs: '0.875rem' },
					}}
				>
					&copy; {presentYear}, All Right Reserved by Inspiremind
				</Typography>
			</Box>
		</Box>
	);
};

export default Footer;
