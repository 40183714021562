import AxiosDefault from 'services/AxiosDefaultSetting';

export const GetDialingCodeList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/phone-code`,
	});
	return response;
};
export const GetCountryList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/country-list`,
	});
	return response;
};
export const GetCountryListForCountryProfile = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/country-profile/country-list`,
	});
	return response;
};
export const GetLanguagesList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/languages`,
	});
	return response;
};
export const GetTimezoneList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/timezone`,
	});
	return response;
};
export const GetCurrencyList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/currency`,
	});
	return response;
};
export const GetDocumentList = async () => {
	const response = await AxiosDefault({
		method: 'GET',
		url: `/public/general-configuration/document-list`,
	});
	return response;
};
