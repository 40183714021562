import React from "react";
import { Formik } from "formik";
import { map } from "lodash";
import { useTheme } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MDCard from "components/md-components/card/MDCard";
import MDButton from "components/md-components/button/MDButton";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import { ROLE_PERMISSIONS } from "components/utility/constant";

const AdminRoleAssign = () => {
  const { palette } = useTheme();

  return (
    <MDCard shadow="none" borderRadius="8px" className="h-100">
      <MDCardHeader title="Admin User Role Assign" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Formik
              enableReinitialize={true}
              initialValues={ROLE_PERMISSIONS}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Box sx={{ overflowX: "auto" }}>
                    <TableContainer
                      sx={{ border: `1px solid ${palette.grey[300]}` }}
                    >
                      <Table stickyHeader={true} aria-label="Role Assign Table">
                        <TableHead>
                          <TableRow
                            sx={{ "& .MuiTableCell-root": { padding: 1 } }}
                          >
                            <TableCell colSpan={3}>Roles</TableCell>
                            <TableCell align="center">Read</TableCell>
                            <TableCell align="center">Write</TableCell>
                            <TableCell align="center">Update</TableCell>
                            <TableCell align="center">Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {map(props.values, (item, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "& .MuiTableCell-root": { py: 0.5, px: 1 },
                              }}
                            >
                              <TableCell component="th" scope="row" colSpan={3}>
                                {item.name}
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={item.read}
                                  onChange={props.handleChange}
                                  inputProps={{
                                    "aria-label": "controlled",
                                    name: `${index}.read`,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={item.write}
                                  onChange={props.handleChange}
                                  inputProps={{
                                    "aria-label": "controlled",
                                    name: `${index}.write`,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={item.update}
                                  onChange={props.handleChange}
                                  inputProps={{
                                    "aria-label": "controlled",
                                    name: `${index}.update`,
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={item.delete}
                                  onChange={props.handleChange}
                                  inputProps={{
                                    "aria-label": "controlled",
                                    name: `${index}.delete`,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box mt={3} textAlign="center">
                      <MDButton
                        label="Submit"
                        type="submit"
                        variant="contained"
                      />
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default AdminRoleAssign;
