import { isEmpty, map } from "lodash";
import { useMemo, useState } from "react";
// MUI
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Other
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Keyboard, Lazy, Mousewheel, Navigation, Virtual, Zoom } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Custom
import Image from "components/utility/media/Image";
import ExtendedZoom from "./components/ExtendZoom";
import LightBoxButton from "./components/LightBoxButton";
import ZoomControls from "./components/ZoomControls";
import "./index.css";

const MAX_ZOOM = 4;

const SwiperModules = [
  Zoom,
  Navigation,
  Mousewheel,
  Keyboard,
  Virtual,
  Lazy,
  ExtendedZoom,
];

const Lightbox = (props) => {
  const { imageData, open, handleClose, imageGrid } = props;
  // const swiperRef = useRef(null);

  const [swiperRef, setSwiperRef] = useState(null);
  const [swipeNavEnabled, setSwipeNavEnabled] = useState(true);
  const [activeZoomScale, setActiveZoomScale] = useState(1);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const handleOpenLightBox = (index) => {
    setLightBoxOpen(true);
    setInitialSlide(index !== 0 ? index - 1 : 1);
  };
  const handleCloseLightBox = () => {
    setLightBoxOpen(false);
    setInitialSlide(0);
  };

  const modalOpen = open || lightBoxOpen;
  const handleCloseModal = handleClose || handleCloseLightBox;

  const zoomConfig = useMemo(
    () => ({
      containerClass: "swiper-zoom-container",
      minRatio: 1,
      maxRatio: activeZoomScale < MAX_ZOOM ? activeZoomScale + 1 : MAX_ZOOM,
    }),
    [activeZoomScale]
  );

  const onZoomChange = (swiper, scale) => {
    if (scale === 1 && !swipeNavEnabled) {
      setSwipeNavEnabled(true);
    }
    if (scale > 1 && swipeNavEnabled) {
      setSwipeNavEnabled(false);
    }
    setActiveZoomScale(scale);
  };

  return (
    <>
      {imageGrid ? (
        <Box component="div">
          {map(imageData, (image, index) => {
            return (
              <Button
                key={index}
                onClick={() => handleOpenLightBox(index)}
                className="p-1"
              >
                <LazyLoadImage
                  alt={image?.alt}
                  src={image?.src}
                  effect="blur"
                />
              </Button>
            );
          })}
        </Box>
      ) : null}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="lightbox-container">
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <ZoomControls
              maxZoom={MAX_ZOOM}
              activeZoomScale={activeZoomScale}
              swiperRef={swiperRef}
            />
            <LightBoxButton aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </LightBoxButton>
          </Stack>
          <Swiper
            onSwiper={setSwiperRef}
            modules={SwiperModules}
            slidesPerView={1}
            centeredSlides={true}
            initialSlide={initialSlide}
            spaceBetween={30}
            zoom={zoomConfig}
            allowTouchMove={swipeNavEnabled}
            navigation={true}
            mousewheel={true}
            keyboard={true}
            virtual={true}
            lazy={true}
            onZoomChange={onZoomChange}
            className="mySwiper"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
          >
            {map(imageData, (data, index) => {
              return (
                <SwiperSlide key={index} virtualIndex={index}>
                  <Box component="div" className="swiper-zoom-container">
                    <Image
                      src={data?.src}
                      alt={data?.alt}
                      className="swiper-lazy"
                    />
                    <Box component="div" className="swiper-lazy-preloader" />
                    {!isEmpty(data?.caption) ? (
                      <Box component="div" className="caption">
                        {data?.caption?.title ? (
                          <Typography variant="h5">
                            {data?.caption?.title}
                          </Typography>
                        ) : null}
                        {data?.caption?.subTitle ? (
                          <Typography variant="body1">
                            {data?.caption?.subTitle}
                          </Typography>
                        ) : null}
                      </Box>
                    ) : null}
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Modal>
    </>
  );
};

export default Lightbox;
