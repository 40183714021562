import AxiosDefault from "services/AxiosDefaultSetting";

export const GetMealList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/meal/list`,
    data: data,
  });
  return response;
};

export const GetMealDetails = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/meal/details/${id}`,
    data: data,
  });
  return response;
};

export const CreateUpdateMeal = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/meal/add-update`,
    data: data,
  });
  return response;
};

export const ArchiveMeal = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/health-food/meal/archive/${id}`,
    data: data,
  });
  return response;
};

export const DeleteMeal = async (id) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/health-food/meal/delete/${id}`,
  });
  return response;
};
