import React from "react";

import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";

const CustomTypography = styled(Typography)(({ theme, other }) => ({
  width: other?.fullWidth ? "100%" : "max-content",
  backgroundColor: other?.color
    ? alpha(theme.palette[other?.color].light, 0.25)
    : theme.palette.primary[50],
  color: other?.color
    ? other?.color === "grey"
      ? theme.palette.text.primary
      : theme.palette[other?.color].main
    : theme.palette.primary[700],
  padding: other?.padding
    ? other?.padding
    : `${theme.spacing(0.75)} ${theme.spacing(1.5)}`,
  borderRadius: other?.rounded ? other?.rounded : theme.spacing(1),
  border: other?.border ? other?.border : `1px solid`,
  borderColor: other?.color
    ? theme.palette[other?.color].main
    : theme.palette.primary[700],
}));

const MDTypography = (props) => {
  const { text, children, ...rest } = props;

  return (
    <CustomTypography other={props} {...rest}>
      {text || children}
    </CustomTypography>
  );
};

export default MDTypography;
