import { useState } from "react";
import { useSelector } from "react-redux";
import { get, map } from "lodash";

import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";
import ProfessionalDomainForm from "./ProfessionalDomainForm";

const ConsultantProfessionalDomain = (props) => {
  const { userDetails, userType } = props;
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            Professional Domain
          </Typography>
          <IconButton color="primary" onClick={() => handleDialogOpen()}>
            <EditIcon />
          </IconButton>
        </Stack>
      </Box>
      <CardContent>
        <Box>
          {map(
            get(userDetails, "specialization", []),
            (item, index, { length }) => {
              const lastIndex = index === length - 1;
              return (
                <Box
                  key={index}
                  mb={!lastIndex ? 1.5 : 0}
                  pb={!lastIndex ? 1.5 : 0}
                  className={!lastIndex ? "border-bottom" : ""}
                >
                  <DetailWrapper sx={{ mb: 1.5 }}>
                    <DetailTitle title="Specialization" colon={true} />
                    <DetailValue>
                      <Chip
                        key={index}
                        label={item?.foreignData?.title}
                        color="primary"
                      />
                    </DetailValue>
                  </DetailWrapper>
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="Sub Specialization" colon={true} />
                    <DetailValue className="d-flex flex-wrap gap-2">
                      {map(item.child, (subItem, subIndex) => {
                        return (
                          <Chip
                            key={subIndex}
                            label={subItem?.foreignData?.title}
                            variant="outlined"
                            color="primary"
                            size="small"
                          />
                        );
                      })}
                    </DetailValue>
                  </DetailWrapper>
                </Box>
              );
            }
          )}
        </Box>
        <ProfessionalDomainForm
          title="Professional Domain Update"
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          userDetails={userDetails}
          userType={userType}
        />
      </CardContent>
    </MDCard>
  );
};

export default ConsultantProfessionalDomain;
