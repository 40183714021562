import React from 'react';
import { get, map } from 'lodash';
import moment from 'moment';
// MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// Other
import ReactPlayer from 'react-player/lazy';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Custom
import DetailWrapper, { DetailTitle, DetailValue } from 'components/common/DetailWrapper';
import { extractTopContentImageUrl } from 'utils/content';

const SWIPER_MODULES_LIST = [Pagination, Autoplay];

function RenderContent({ content }) {
	if (content.preferredOption === 'TEXT') {
		return (
			<div
				key={content._id}
				dangerouslySetInnerHTML={{ __html: content.data }}
				className='blog-content-wrapper blog-image'
			/>
		);
	}

	if (content.preferredOption === 'IMAGE') {
		return (
			<div>
				<img
					src={extractTopContentImageUrl(content, 'horizontal')}
					width={780}
					height={487}
					alt={content.title}
					className='w-full h-full object-cover'
				/>
			</div>
		);
	}

	if (content.preferredOption === 'VIDEO') {
		return (
			<div className='my-4 p-4 md:p-5 rounded-xl shadow-2xl bg-[#f5f6fa] blog-content-wrapper'>
				<h3 className='text-base md:text-xl font-semibold text-neutral-900 mb-5'>{content.title}</h3>
				<div className='player-wrapper rounded-lg overflow-hidden mx-auto'>
					<ReactPlayer url={content.data} controls={true} width='100%' className='react-player' />
				</div>
			</div>
		);
	}

	if (content.preferredOption === 'AUDIO') {
		return (
			<div className='my-4 p-4 md:p-5 rounded-xl shadow-2xl bg-[#f5f6fa] blog-content-wrapper'>
				<h3 className='text-base md:text-xl font-semibold text-neutral-900 mb-1'>{content.title}</h3>
				<div className='player-wrapper rounded-lg overflow-hidden mx-auto'>
					<audio controls>
						<source height='360px' src={get(content, 'data', '')} type='audio/mpeg' />
						Your browser does not support the audio element.
					</audio>
				</div>
			</div>
		);
	}

	if (content.preferredOption === 'PDF') {
		return (
			<div className='my-4 p-4 md:p-5 rounded-xl shadow-2xl bg-[#f5f6fa] blog-content-wrapper'>
				<h3 className='text-base md:text-xl font-semibold text-neutral-900 mb-5'>{content.title}</h3>
				<embed src={get(content, 'data', '')} width='100%' height='360px' type='application/pdf' />
			</div>
		);
	}
	return <></>;
}

const ContentDetail = ({ contentData }) => {
	if (!contentData) {
		return null;
	}

	return (
		<>
			<Box>
				<Typography fontWeight={800} mb={1}>
					MetaData
				</Typography>
				{get(contentData, 'categories', []).length ? (
					<Card sx={{ p: 2, mb: 2 }}>
						<Typography fontSize='16px' color='#929292' fontWeight={600}>
							Categories
						</Typography>
						<Stack direction='row' flexWrap='wrap' className='gap-2'>
							{map(get(contentData, 'categories', []), (category, categoryIndex) => {
								return (
									<React.Fragment key={categoryIndex}>
										<Chip label={category?.foreignData?.title || 'NA'} size='small' />
										{map(category?.subCategories || [], (subCategory, subCategoryIndex) => {
											return (
												<Chip
													key={subCategoryIndex}
													label={subCategory?.foreignData?.title || 'NA'}
													size='small'
												/>
											);
										})}
									</React.Fragment>
								);
							})}
						</Stack>
					</Card>
				) : null}
				<Stack direction='row' gap='20px' width='100%'>
					<Stack>
						<Typography textTransform={'uppercase'} fontSize={'11px'} color='#707070'>
							Published on :
						</Typography>
						<Typography fontSize={'15.5px'} fontWeight={'bold'}>
							{moment(get(contentData, 'createdAt', ''), ['MM-DD-YYYY', 'YYYY-MM-DD']).format('LL')}
						</Typography>
					</Stack>
					<Stack>
						<Divider orientation='vertical' variant='middle' />
					</Stack>
					<Stack>
						<Typography textTransform={'uppercase'} fontSize={'11px'} color='#707070'>
							Created by :
						</Typography>
						<Typography fontSize={'15.5px'} fontWeight={'bold'}>
							Consultant
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Divider sx={{ my: 2 }} />
			<div>
				<Typography fontWeight={800} mb={1}>
					Content
				</Typography>
				<div className='blog-container' style={{ maxWidth: '990px', margin: 'auto' }}>
					<div className='blog-content'>
						<h1 className='text-2xl md:text-3xl font-semibold mb-4 md:mb-2'>{contentData.title}</h1>

						<div className='mb-3 md:mb-4 w-full relative'>
							<div className='relative'>
								<Swiper
									modules={SWIPER_MODULES_LIST}
									centeredSlides={true}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
									}}
									pagination={{ dynamicBullets: true }}
									spaceBetween={20}
									slidesPerView={1}
								>
									{contentData.topSection?.map((topContent, index) => (
										<SwiperSlide key={topContent._id} virtualIndex={index}>
											{/* <div> */}
											<img
												src={extractTopContentImageUrl(topContent, 'horizontal')}
												width={780}
												height={487}
												alt={topContent.title}
												className='w-full h-full object-cover rounded-2xl'
											/>
											{/* </div> */}
										</SwiperSlide>
									))}
								</Swiper>
								<div className='absolute top-0 z-10 bottom-0 rounded-2xl w-full bg-black-gradient1 flex flex-col pointer-events-none'>
									<div className='p-4'>
										<div className='flex flex-wrap gap-2 justify-end'>
											{contentData.language ? (
												<div className='bg-white py-2 px-4 rounded-full text-xs font-medium text-neutral-900 leading-none'>
													{contentData.language.title}
												</div>
											) : null}
										</div>
									</div>
									<div className='p-4 pt-0 mt-auto'>
										{/* <p className="text-base md:text-xl font-semibold text-white mb-3">Specialization</p> */}
										<div className='flex flex-wrap gap-2'>
											{contentData.categories.map((category) => (
												<div
													className='bg-white py-2 px-3 rounded-full text-xs font-medium text-neutral-900 leading-none'
													key={category._id}
												>
													{category.foreignData.title}
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>

						{contentData.contentSection.map((content) => (
							<RenderContent content={content} key={content._id} />
						))}
					</div>
				</div>
			</div>
			<Card sx={{ p: 2 }}>
				<DetailWrapper>
					<DetailTitle title='Best Suited For' colon={true} />
					<DetailValue>
						<Typography variant='body1' fontWeight={400} textTransform='capitalize'>
							{get(contentData, 'bestSuited', '')}
						</Typography>
					</DetailValue>
				</DetailWrapper>
				{get(contentData, 'preferredOption') === 'Free' ? (
					<DetailWrapper>
						<DetailTitle title='Preferred Option' colon={true} />
						<DetailValue>
							<Typography variant='body1' fontWeight={400} textTransform='capitalize'>
								{get(contentData, 'preferredOption', '')}
							</Typography>
						</DetailValue>
					</DetailWrapper>
				) : (
					<>
						<DetailWrapper>
							<DetailTitle title='Currency' colon={true} />
							<DetailValue>
								<Typography variant='body1' fontWeight={400} textTransform='capitalize'>
									<span>{get(contentData, 'currencyId.currencySymbol', '')}</span>
									{get(contentData, 'currencyId.currency', '')}
								</Typography>
							</DetailValue>
						</DetailWrapper>
						<DetailWrapper>
							<DetailTitle title='Amount' colon={true} />
							<DetailValue>
								<Typography variant='body1' fontWeight={400} textTransform='capitalize'>
									{get(contentData, 'amount', '')}
								</Typography>
							</DetailValue>
						</DetailWrapper>
					</>
				)}
			</Card>
		</>
	);
};

export default ContentDetail;
