import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import ProfileMenu from "components/layout/ProfileMenu";

const Header = (props) => {
  const { drawerOpen, handleDrawerToggle } = props;
  const theme = useTheme();

  return (
    <>
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{
          backgroundColor: "#07080C",
          backdropFilter: "saturate(200%) blur(1.875rem)",
          justifyContent: "center",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            "&.MuiToolbar-root": { px: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  color: theme.palette.common.white,
                  display: { lg: "none", visibility: drawerOpen && "hidden" },
                  mr: { sm: 2, xs: 1 },
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  drawerOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
