import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const ScaleForm = ({
	minValue,
	formProps,
	subQuestionField,
	errorMinValue,
	touchedMinValue,
	maxValue,
	errorMaxValue,
	touchedMaxValue,
	stepValue,
	errorStepValue,
	touchedStepValue,
}) => {
	return (
		<Grid item xs={12}>
			<Box className='mb-2 px-2'>
				<Typography variant='body1' fontWeight={500}>
					Scale Values
				</Typography>
			</Box>
			<Box>
				<Grid container spacing={2} sx={{ alignItems: 'center', mb: 1 }}>
					<Grid item sm={4} xs={12}>
						<TextField
							fullWidth
							size='small'
							label='Minimum Value'
							type='number'
							name={minValue}
							value={subQuestionField.minValue}
							onChange={formProps.handleChange}
							onBlur={formProps.handleBlur}
							helperText={errorMinValue && touchedMinValue ? errorMinValue : null}
							error={Boolean(errorMinValue && touchedMinValue)}
						/>
					</Grid>
					<Grid item sm={4} xs={12}>
						<TextField
							fullWidth
							size='small'
							label='Maximum Value'
							type='number'
							name={maxValue}
							value={subQuestionField.maxValue}
							onChange={formProps.handleChange}
							onBlur={formProps.handleBlur}
							helperText={errorMaxValue && touchedMaxValue ? errorMaxValue : null}
							error={Boolean(errorMaxValue && touchedMaxValue)}
						/>
					</Grid>
					<Grid item sm={4} xs={12}>
						<TextField
							fullWidth
							size='small'
							label='Step Value'
							type='number'
							name={stepValue}
							value={subQuestionField.stepValue}
							onChange={formProps.handleChange}
							onBlur={formProps.handleBlur}
							helperText={errorStepValue && touchedStepValue ? errorStepValue : null}
							error={Boolean(errorStepValue && touchedStepValue)}
						/>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
};

export default ScaleForm;
