import React, { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { getConsultantUserDocumentVerification } from 'store/slice/user-management/ConsultantUserSlice';
import MDCard from 'components/md-components/card/MDCard';
import MDDataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import toCapitalize from 'components/hooks/toCapitalize';
import MDCardHeader from 'components/md-components/card/MDCardHeader';

const ConsultantDocumentVerification = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const offlineUsersDocVerificationList = useSelector((state) => state.consultantUser.offlineUsersDocVerification);
	const isLoading = useSelector((state) => state.consultantUser.loading);

	const _docVerificationList =
		offlineUsersDocVerificationList?.length > 0 &&
		offlineUsersDocVerificationList
			?.map((item, index) => {
				return Object.keys(item).some((key) => key === 'offlineData') === true && item;
			})
			.filter((ele) => {
				return ele?._id;
			});

	useEffect(() => {
		dispatch(getConsultantUserDocumentVerification());
	}, [dispatch]);

	const columnsData = useMemo(
		() => [
			{
				field: 'name',
				headerName: 'Name',
				width: 240,
				editable: false,
				valueGetter: (params) => {
					return `${toCapitalize(params.row.consultantId?.firstName)} ${toCapitalize(
						params.row.consultantId?.lastName
					)}`;
				},
			},
			{
				field: 'email',
				headerName: 'E-mail',
				width: 240,
				editable: false,
				renderCell: (params) => {
					return <span>{params.row.consultantId?.email}</span>;
				},
			},
			{
				field: 'isVerified',
				headerName: 'Verify Status',
				headerAlign: 'center',
				align: 'center',
				width: 180,
				renderCell: (params) => {
					return (
						<Chip
							label={params.row.serviceTypeOffline === false ? 'New' : 'Approved'}
							icon={
								params.row.serviceTypeOffline === false ? (
									<NewReleasesOutlinedIcon fontSize='small' />
								) : (
									<CheckCircleOutlineIcon fontSize='small' />
								)
							}
							color={params.row.serviceTypeOffline === false ? 'info' : 'success'}
							size='small'
						/>
					);
				},
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				width: 180,
				renderCell: (params) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label={`${params.row.name}-view-button`}
								color='primary'
								onClick={() => {
									navigate(`/user-management/consultant-offline-user-detail`, {
										state: {
											userId: params.row._id,
											// userType: "none-verified",
											offlineUser: params.row,
										},
									});
								}}
							>
								<VisibilityIcon sx={{ fontSize: 28 }} />
							</IconButton>
						</Box>
					);
				},
			},
		],
		[navigate]
	);

	const CustomToolbar = () => {
		return <MDDataGridToolbar align='right' divider={true} />;
	};

	return (
		<Box>
			<MDCard>
				<MDCardHeader title='Consultant Document Verification' />
				<CardContent>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<MDDataGrid
								rows={_docVerificationList}
								columns={columnsData}
								getRowId={(row) => row._id}
								pageSize={10}
								rowsPerPageOptions={[10]}
								disableSelectionOnClick
								loading={isLoading}
								components={{
									Toolbar: CustomToolbar,
									NoRowsOverlay: EmptyTableRowOverlay,
									LoadingOverlay: LinearProgress,
								}}
								componentsProps={{
									toolbar: {
										showQuickFilter: true,
										quickFilterProps: { debounceMs: 500 },
									},
								}}
								autoHeight
							/>
						</Grid>
					</Grid>
				</CardContent>
			</MDCard>
		</Box>
	);
};

export default ConsultantDocumentVerification;
