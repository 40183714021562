import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import cookie, { cookieGet } from "components/hooks/cookie";
import { PostLogin } from "api/Seasons";

const authToken = cookieGet("auth_token");
const roleType = cookieGet("role_type");

const initialState = {
  authToken: !isEmpty(authToken) ? authToken : "",
  roleType: !isEmpty(roleType) ? roleType : 0,
  isAuthenticated: !isEmpty(authToken) ? true : false,
  loginWithOTP: false,
  blockLogin: false,
  loading: false,
};

export const postLogin = createAsyncThunk("auth/postLogin", async (data) => {
  return await PostLogin(data)
    .then(function (response) {
      return response?.data;
    })
    .catch(function (error) {
      return { status: false, message: error.response.data.message };
    });
});

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      cookie.remove("auth_token");
      cookie.remove("role_type");
      state.authToken = initialState.authToken;
      state.roleType = initialState.roleType;
      state.isAuthenticated = false;
      localStorage.clear();
      toast.success("Logged out successfully.");
    },
    setLoginWithOTP: (state, { payload }) => {
      state.loginWithOTP = payload;
    },
    setBlockLogin: (state, { payload }) => {
      state.blockLogin = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.isAuthenticated = false;
        state.loading = true;
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.authToken = get(action, "payload.data.token");
        state.roleType = get(action, "payload.data.roleType");
        state.isAuthenticated = !isEmpty(get(action.payload, "data.token", ""))
          ? true
          : false;
        state.loading = false;
      })
      .addCase(postLogin.rejected, (state) => {
        state.isAuthenticated = false;
        state.loading = false;
      });
  },
});

export const { logout } = AuthSlice.actions;

export default AuthSlice.reducer;
