import { get, isEmpty, map } from "lodash";

import CloseIcon from "@mui/icons-material/Close";
// import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AvatarInnerButtonsWrapper, ButtonBox } from "./FilePickerComponents";

const REACT_APP_BUCKET_END_POINT = process.env.REACT_APP_BUCKET_END_POINT;

const FilePickerPreview = (props) => {
  const {
    files,
    fileLabel,
    filePreviewSize,
    handleRemoveFile,
    value,
    name,
    theme,
    previewImageStyle,
    previewFileSize,    
    previewImageBaseURL,
  } = props;

  const { palette } = theme;
  const imageURL = previewImageBaseURL
    ? REACT_APP_BUCKET_END_POINT + value
    : value;

  return value !== [] && value?.length === 3 ? (
    <Grid item sm={previewFileSize ? previewFileSize : 12} xs={12}>
      <ListItem
        disablePadding
        sx={{
          columnGap: 2,
          padding: "4px",
          borderRadius: "6px",
          flexWrap: "wrap",
        }}
        className="position-relative"
      >
        {value.map((val, index) => (
          <ListItemIcon
            className="position-relative"
            sx={{
              "&:hover > .avatar-hover-buttons": {
                display: "inline-flex",
                transition: "all 3000ms ease",
              },
            }}
          >
            <Avatar
              src={val?.url}
              alt={name + "_image"}
              className="avtar-image-crop"
              variant="rounded"
              sx={{
                width: filePreviewSize ? filePreviewSize : 128,
                height: filePreviewSize ? filePreviewSize : 96,
                aspectRatio: "4/3",
                border: `2px solid ${palette.grey[200]}`,
                cursor: "pointer",
                previewImageStyle,
              }}
            />
            <AvatarInnerButtonsWrapper className="avatar-hover-buttons">
              <Button
                aria-label="view-file"
                size="small"
                className="min-w-auto p-1"
                component={"a"}
                href={val?.url}
                target="_blank"
              >
                <VisibilityIcon sx={{ color: "grey.200" }} />
              </Button>
              {/* <IconButton
                        aria-label="download-file"
                        size="small"
                        className="min-w-auto p-1"
                        component={"a"}
                        href={imageURL}
                        target="_blank"
                        value="download"
                        download={value.split("/").slice(-1).pop()}
                      >
                        <DownloadIcon sx={{ color: "grey.200" }} />
                      </IconButton> */}
            </AvatarInnerButtonsWrapper>
          </ListItemIcon>
        ))}
        {fileLabel ? (
          <ListItemText
            primary={value.split("/").pop()}
            primaryTypographyProps={{ fontSize: "14px" }}
            sx={{ wordWrap: "break-word" }}
          />
        ) : null}
      </ListItem>
    </Grid>
  ) : (
    <Grid item sm={previewFileSize ? previewFileSize : 12} xs={12}>
      {value && (
        <ListItem
          disablePadding
          sx={{
            columnGap: 2,
            padding: "4px",
            borderRadius: "6px",
            flexWrap: "wrap",
          }}
          className="position-relative"
        >
          <ListItemIcon
            className="position-relative"
            sx={{
              "&:hover > .avatar-hover-buttons": {
                display: "inline-flex",
                transition: "all 3000ms ease",
              },
            }}
          >
            <Avatar
              src={value}
              alt={name + "_image"}
              className="avtar-image-crop"
              variant="rounded"
              sx={{
                width: filePreviewSize ? filePreviewSize : 128,
                height: filePreviewSize ? filePreviewSize : 96,
                aspectRatio: "4/3",
                border: `2px solid ${palette.grey[200]}`,
                cursor: "pointer",
                previewImageStyle,
              }}
            />
            <AvatarInnerButtonsWrapper className="avatar-hover-buttons">
              <Button
                aria-label="view-file"
                size="small"
                className="min-w-auto p-1"
                component={"a"}
                href={value}
                target="_blank"
              >
                <VisibilityIcon sx={{ color: "grey.200" }} />
              </Button>
              {/* <IconButton
                    aria-label="download-file"
                    size="small"
                    className="min-w-auto p-1"
                    component={"a"}
                    href={imageURL}
                    target="_blank"
                    value="download"
                    download={value.split("/").slice(-1).pop()}
                  >
                    <DownloadIcon sx={{ color: "grey.200" }} />
                  </IconButton> */}
            </AvatarInnerButtonsWrapper>
          </ListItemIcon>

          {fileLabel ? (
            <ListItemText
              primary={value.split("/").pop()}
              primaryTypographyProps={{ fontSize: "14px" }}
              sx={{ wordWrap: "break-word" }}
            />
          ) : null}
        </ListItem>
      )}
    </Grid>
  );
};

export default FilePickerPreview;
