import React, { useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import Stack from '@mui/material/Stack';

import { getEndUserDetail } from 'store/slice/user-management/EndUserSlice';

import EndUserInfo from 'components/pages/user-management/end-user/EndUserInfo';
import EndUserBasicInfo from 'components/pages/user-management/end-user/EndUserBasicInfo';
import EndUserAboutMe from 'components/pages/user-management/end-user/EndUserAboutMe';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import EndUserAppointments from 'components/pages/user-management/end-user/EndUserAppointments';

const EndUserDetail = () => {
	const location = useLocation();
	const locationUserId = get(location, 'state.userId');
	const [searchParams] = useSearchParams();
	const paramsUserId = searchParams.get('userId');

	const userId = useMemo(() => {
		if (Boolean(paramsUserId)) {
			return paramsUserId;
		}

		return locationUserId;
	}, [locationUserId, paramsUserId]);

	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.endUser.userDetail);

	useEffect(() => {
		if (userId) {
			dispatch(getEndUserDetail({ endUserId: userId }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	console.log(userDetails);

	return (
		<Stack direction='column' spacing={3}>
			<MDCard>
				<MDCardHeader title='End User Profile' />
			</MDCard>
			<EndUserInfo userDetails={userDetails} />
			<EndUserBasicInfo userDetails={userDetails} />
			<EndUserAboutMe userDetails={userDetails} />
			<EndUserAppointments list={userDetails.appointmentList} />
		</Stack>
	);
};

export default EndUserDetail;
