import { useState } from 'react';
import { useSelector } from 'react-redux';
import { get, map } from 'lodash';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DetailWrapper, { DetailTitle, DetailValue } from 'components/common/DetailWrapper';
import MDCard from 'components/md-components/card/MDCard';
import DetailCardSkeleton from 'components/md-components/skeleton/DetailCardSkeleton';
import ResidentialAddressForm from './ResidentialAddressForm';

const ConsultantResidentialAddress = (props) => {
	const { userDetails, userType } = props;
	const [dialogOpen, setDialogOpen] = useState(false);
	const isLoading = useSelector((state) => state.consultantUser.loading);
	const handleDialogOpen = () => {
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	return isLoading ? (
		<DetailCardSkeleton />
	) : (
		<MDCard>
			<Box className='detail-box-header'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
					<Typography variant='body1' className='detail-box-heading'>
						Residential Address
					</Typography>
					<IconButton color='primary' onClick={() => handleDialogOpen()}>
						<EditIcon />
					</IconButton>
				</Stack>
			</Box>
			<CardContent>
				<Box display='grid' gap='12px' gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='Mobile Number' colon={true} />
						<DetailValue value={get(userDetails, 'code', '') + get(userDetails, 'mobile', '')} />
					</DetailWrapper>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='Address' colon={true} />
						<DetailValue value={get(userDetails, 'addressLine', '')} />
					</DetailWrapper>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='City' colon={true} />
						<DetailValue value={get(userDetails, 'city', '')} />
					</DetailWrapper>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='Zip Code' colon={true} />
						<DetailValue value={get(userDetails, 'zipCode', '')} />
					</DetailWrapper>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='State' colon={true} />
						<DetailValue value={get(userDetails, 'state', '')} />
					</DetailWrapper>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='Country' colon={true} />
						<DetailValue value={get(userDetails, 'country.name', '')} />
					</DetailWrapper>
					<DetailWrapper fullWidth={true}>
						<DetailTitle title='Language' colon={true} />
						<DetailValue className='d-flex flex-wrap gap-2'>
							{map(get(userDetails, 'language', []), (item, index) => (
								<Chip key={index} label={item.name} variant='outlined' color='primary' />
							))}
						</DetailValue>
					</DetailWrapper>
				</Box>
				<ResidentialAddressForm
					title='Residential Address Update'
					dialogOpen={dialogOpen}
					handleDialogClose={handleDialogClose}
					userDetails={userDetails}
					userType={userType}
				/>
			</CardContent>
		</MDCard>
	);
};

export default ConsultantResidentialAddress;
