import React from "react";
import { Outlet } from "react-router-dom";

const PageOutlet = ({ path, previousLocationData }) => {
  // const location = useLocation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (location?.pathname === path) {
  //     navigate(previousLocationData?.pathname, {
  //       state: previousLocationData?.state,
  //     });
  //   }
  // }, [
  //   location?.pathname,
  //   navigate,
  //   path,
  //   previousLocationData?.pathname,
  //   previousLocationData?.state,
  // ]);

  return <Outlet />;
};

export default PageOutlet;
