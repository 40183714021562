import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetBookingDetail, GetBookingList, GetQAndAData } from "api/booking/BookingAPI";
import { get } from "lodash";

const initialState = {
  bookingList: [],
  bookingDetail: {},
  QAndAData: [],
  loading: false,
};

export const getBookingList = createAsyncThunk(
  "booking/getBookingList",
  async (data) => {
    try {
      const response = await GetBookingList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const getBookingDetail = createAsyncThunk(
  "booking/getBookingDetail",
  async (data) => {
    try {
      const response = await GetBookingDetail(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getQAndAData = createAsyncThunk(
  "booking/getQAndAData",
  async (data) => {
    try {
      const response = await GetQAndAData(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBookingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBookingList.fulfilled, (state, { payload }) => {
        state.bookingList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getBookingList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBookingDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBookingDetail.fulfilled, (state, { payload }) => {
        state.bookingDetail = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getBookingDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQAndAData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQAndAData.fulfilled, (state, { payload }) => {
        state.QAndAData = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getQAndAData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default BookingSlice.reducer;
