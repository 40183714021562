import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { get } from "lodash";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

import { updateCountryState } from "store/slice/country-profile/CountryStatesSlice";
import { getCountryProfiles } from "store/slice/country-profile/CountryProfileSlice";
import MDDialog from "components/md-components/dialog/MDDialog";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";

const StateProfileForm = (props) => {
  const { title, dialogOpen, handleDialogClose, dialogData } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.countryStates.loading);
  const localStoreCountryId = localStorage.getItem("countryId");
  const localStoreStateName = localStorage.getItem("stateName");

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

  const handleSubmit = (values) => {
    const apiData = {
      countryId: get(dialogData, "countryId", localStoreCountryId),
      data: {
        ...values,
        stateName: get(dialogData, "rowData.name", localStoreStateName),
      },
    };
    dispatch(updateCountryState(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false) === true) {
          toast.success(get(result, "message", "State update successfully"));
          dispatch(getCountryProfiles());
          handleDialogClose();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <>
      <MDDialog
        title={
          get(dialogData, "formType", "") === "update"
            ? `${title} Update`
            : `${title} Create`
        }
        open={dialogOpen}
        close={handleDialogClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <Formik
          initialValues={{
            name: get(dialogData, "rowData.name", ""),
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              className="d-flex flex-column overflow-auto"
            >
              <DialogContent dividers={true}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Name"
                      name="name"
                      value={props.values.name}
                      error={
                        props.errors.name && props.touched.name ? true : false
                      }
                      helperText={
                        props.errors.name && props.touched.name
                          ? props.errors.name
                          : null
                      }
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDLoadingButton
                  type="submit"
                  label={
                    get(dialogData, "formType", "") === "update"
                      ? "Update"
                      : "Submit"
                  }
                  loading={props.isSubmitting || isLoading}
                  variant="contained"
                />
              </DialogActions>
            </form>
          )}
        </Formik>
      </MDDialog>
    </>
  );
};

export default StateProfileForm;
