import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { map, get, isEmpty } from "lodash";

import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import QAndABox from "components/pages/booking/QAndABox";

import { getQAndAData } from "store/slice/booking/BookingSlice";
import QAndASkeleton from "components/md-components/skeleton/QAndASkeleton";
import NoDataOverlay from "components/md-components/empty-overlay/NoDataOverlay";
import StaticQAndAFormData from "components/pages/booking/StaticQAndAFormData";
import MDTypography from "components/md-components/typography/MDTypography";
import indexToAlpha from "components/hooks/indexToAlpha";

const QAndADataDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.booking.loading);
  const QAndAFormData = useSelector((state) => state.booking.QAndAData);

  const [emptyData, setEmptyData] = useState(false);

  useEffect(() => {
    dispatch(getQAndAData(get(location, "state.id", "")))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (isEmpty(get(result, "data", {}))) {
            setEmptyData(true);
          }
        } else {
          setEmptyData(true);
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [dispatch, location]);

  return (
    <MDCard className="w-100 h-100">
      <MDCardHeader title="Q & A Detail's" />
      <CardContent className="w-100 h-100">
        {isLoading ? (
          <QAndASkeleton skeletons={5} />
        ) : emptyData ? (
          <NoDataOverlay />
        ) : (
          <Box className="px-1">
            <StaticQAndAFormData QAndAFormData={QAndAFormData} />
            <Box>
              {map(
                get(QAndAFormData, "sections", []),
                (sectionData, sectionIndex) => {
                  return (
                    <Box key={sectionIndex}>
                      <MDTypography
                        variant="h6"
                        fontWeight={500}
                        rounded="4px"
                        gutterBottom={true}
                        fullWidth={true}
                      >
                        {indexToAlpha(sectionIndex + 2)}&#46; &nbsp; Section{" "}
                        {sectionIndex + 1}
                      </MDTypography>
                      <Box px={2}>
                        {map(
                          get(sectionData, "questions", []),
                          (questionsData, questionsIndex) => {
                            return (
                              <QAndABox
                                key={questionsIndex}
                                number={questionsIndex + 1}
                                question={get(
                                  questionsData,
                                  "questionId.questionTitle",
                                  "NA"
                                )}
                                answer={get(questionsData, "answer", "NA")}
                                sx={{ mb: 3 }}
                              >
                                {map(
                                  get(questionsData, "options", []),
                                  (optionsData, _optionsIndex) => {
                                    return map(
                                      get(optionsData, "subQuestions", []),
                                      (subQuestionsData, subQuestionsIndex) => {
                                        return (
                                          <QAndABox
                                            key={subQuestionsIndex}
                                            number={subQuestionsIndex + 1}
                                            question={get(
                                              subQuestionsData,
                                              "questionId.questionTitle",
                                              "NA"
                                            )}
                                            answer={get(
                                              subQuestionsData,
                                              "answer",
                                              "NA"
                                            )}
                                            sx={{
                                              mb: subQuestionsIndex - 1 ? 0 : 3,
                                            }}
                                          />
                                        );
                                      }
                                    );
                                  }
                                )}
                              </QAndABox>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        )}
      </CardContent>
    </MDCard>
  );
};

export default QAndADataDetail;
