import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

import MDSwitch from 'components/md-components/switch/MDSwitch';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import MDDataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import MDButton from 'components/md-components/button/MDButton';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import toCapitalize from 'components/hooks/toCapitalize';

import { getQuestionsList, statusUpdateQuestion } from 'store/slice/consultant-config/QAndASlice';
import MDDialog from 'components/md-components/dialog/MDDialog';
import QuestionDetail from './component/QuestionDetail';
import DeleteConfirmDialog from 'components/common/DeleteConfirmDialog';

const QAndAList = (props) => {
	const { type } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const questionsListData = useSelector((state) => state.QAndA.list);
	const isLoading = useSelector((state) => state.QAndA.loading);

	const [dialogData, setDialogData] = useState({
		id: '',
		title: '',
		rowData: [],
	});
	const [openQuestionDetail, setOpenQuestionDetail] = useState(false);

	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const handleDeleteConfirmation = useCallback(() => {
		setDeleteConfirmation(true);
	}, []);
	const handleDeleteConfirmationClose = () => {
		setDeleteConfirmation(false);
	};

	const handleOpenQuestionDetail = useCallback(() => {
		setOpenQuestionDetail(true);
	}, []);
	const handleCloseQuestionDetail = () => {
		setOpenQuestionDetail(false);
	};

	const handleStatusUpdate = useCallback(
		(event, row) => {
			setDialogData({ ...dialogData, id: row._id });
			const apiData = {
				id: row._id,
				data: {
					status: event.target.checked,
				},
			};
			dispatch(statusUpdateQuestion(apiData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						dispatch(getQuestionsList(type));
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[dialogData, dispatch, type]
	);

	useEffect(() => {
		dispatch(getQuestionsList(type));
	}, [dispatch, type]);

	const columnsData = useMemo(
		() => [
			{
				field: 'questionTitle',
				headerName: 'Question Title',
				width: 540,
				editable: false,
			},
			{
				field: 'language',
				headerName: 'Language',
				width: 160,
				editable: false,
				headerAlign: 'center',
				align: 'center',
				valueGetter: ({ row }) => row?.language?.title,
			},
			{
				field: 'inputType',
				headerName: 'Input Type',
				width: 160,
				editable: false,
				headerAlign: 'center',
				align: 'center',
				valueGetter: ({ row }) => toCapitalize(row.inputType),
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				width: 240,
				renderCell: ({ row }) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label='edit'
								color='primary'
								onClick={() => {
									setDialogData({ ...dialogData, id: row._id, rowData: row });
									handleOpenQuestionDetail();
								}}
							>
								<VisibilityIcon fontSize='14px' />
							</IconButton>
							<IconButton
								aria-label='edit'
								color='primary'
								onClick={() => {
									navigate('/consultant-config/create-question', {
										state: {
											formType: 'update',
											type: type,
											rowData: row,
										},
									});
								}}
							>
								<EditIcon fontSize='14px' />
							</IconButton>
							<MDSwitch
								checked={row.status ?? false}
								onChange={(event) => {
									handleStatusUpdate(event, row);
								}}
								inputProps={{
									'aria-label': `${row._id}-status`,
									role: 'switch',
								}}
								color='success'
								disabled={get(dialogData, 'id', '') === row._id && isLoading}
							/>
							<IconButton
								aria-label='delete'
								onClick={() => {
									setDialogData({
										...dialogData,
										id: row._id,
										title: 'Q&A Delete Confirmation',
										rowData: row,
									});
									handleDeleteConfirmation();
								}}
							>
								<DeleteIcon color='error' />
							</IconButton>
						</Box>
					);
				},
			},
		],
		[dialogData, handleDeleteConfirmation, handleOpenQuestionDetail, handleStatusUpdate, isLoading, navigate, type]
	);

	const CustomToolbar = () => {
		return (
			<MDDataGridToolbar align='right' divider={true} ToolbarQuickFilter={true}>
				<MDButton
					variant='contained'
					label='Create New'
					startIcon={<AddCircleOutlineIcon />}
					fontSize='14px'
					onClick={() => {
						navigate('/consultant-config/create-question', {
							state: {
								formType: 'create',
								type: type,
								row: [],
							},
						});
					}}
				/>
			</MDDataGridToolbar>
		);
	};

	const handleDelete = (id) => {
		const apiData = {
			id: id,
			data: {
				status: false,
				isDeleted: true,
			},
		};
		dispatch(statusUpdateQuestion(apiData))
			.unwrap()
			.then((result) => {
				if (get(result, 'status', false) === true) {
					dispatch(getQuestionsList(type));
				}
			})
			.catch((err) => {
				console.log(err);
			});
		handleDeleteConfirmationClose();
		setDialogData({
			id: '',
			title: '',
			rowData: [],
		});
	};

	return (
		<>
			<MDDataGrid
				rows={!isEmpty(questionsListData) ? questionsListData : []}
				columns={columnsData}
				getRowId={(row) => row._id}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick={true}
				loading={isLoading}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
				autoHeight
			/>
			<MDDialog
				title='Question Details'
				open={openQuestionDetail}
				close={handleCloseQuestionDetail}
				maxWidth='md'
				fullWidth={true}
				aria-labelledby='question-detail-view-dialog'
				aria-describedby='question-detail-view-dialog'
			>
				<DialogContent dividers={true}>
					<QuestionDetail
						questionIndex={get(dialogData, '_id', '')}
						questionData={get(dialogData, 'rowData', {})}
						questionTitle={true}
					/>
				</DialogContent>
				<DialogActions>
					<Box className='d-flex gap-3'>
						<MDButton label='Close' variant='contained' size='small' onClick={() => handleCloseQuestionDetail()} />
					</Box>
				</DialogActions>
			</MDDialog>
			<DeleteConfirmDialog
				title='Q&A Delete Confirmation'
				open={deleteConfirmation}
				handleClose={handleDeleteConfirmationClose}
				deleteAction={() => handleDelete(get(dialogData, 'id', ''))}
			/>
		</>
	);
};

export default QAndAList;
