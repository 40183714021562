import { get } from 'lodash';

export function getApproveLabel(row) {
	const status = get(row, 'isApproved', 0);

	if (status === 0) {
		return 'New';
	}
	if (status === 1) {
		return 'Approved';
	}
	return 'Resubmit';
}

export function getCellColor(row) {
	const status = get(row, 'isApproved', 0);

	if (status === 0) {
		return 'info';
	}
	if (status === 1) {
		return 'success';
	}
	return 'warning';
}
