import React from "react";
import { get, map, isEmpty, capitalize } from "lodash";
import moment from "moment";
// MUI
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Other
import { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";
// Custom
import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import createMarkup from "components/hooks/createMarkup";
import stringToColor, {
  getContrastColor,
} from "components/hooks/stringToColor";

const RecipeDetail = (props) => {
  const { recipeDetail } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h5"
              fontWeight={700}
              textTransform="capitalize"
            >
              {get(recipeDetail, "title", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="body1"
              fontWeight={400}
              textTransform="capitalize"
            >
              {get(recipeDetail, "subTitle", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="pt-1">
            <Stack
              direction={{ sm: "row", xs: "column" }}
              alignItems={{ sm: "center", xs: "flex-start" }}
              spacing={1}
            >
              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  className="gap-2"
                >
                  <Typography variant="body2" textTransform="capitalize">
                    Created By
                  </Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    fontWeight={600}
                  >
                    {get(recipeDetail, "createdBy", "") === "MASTER_ADMIN" ? (
                      <>
                        {get(recipeDetail, "adminId.firstName", "") +
                          " " +
                          get(recipeDetail, "adminId.lastName", "")}
                      </>
                    ) : (
                      <>
                        {get(recipeDetail, "consultantId.firstName", "") +
                          " " +
                          get(recipeDetail, "consultantId.lastName", "")}
                      </>
                    )}
                  </Typography>
                  <Chip
                    label={get(recipeDetail, "createdBy", "").replace("_", " ")}
                    color="primary"
                    size="small"
                  />
                </Stack>
              </Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ display: { sm: "block", xs: "none" } }}
              />
              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body2" textTransform="capitalize">
                    Published on
                  </Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    fontWeight={600}
                  >
                    {moment(get(recipeDetail, "createdAt", ""), [
                      "MM-DD-YYYY",
                      "YYYY-MM-DD",
                    ]).format("LL")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="pt-1">
            <Stack direction="row" flexWrap="wrap" className="gap-2">
              {get(recipeDetail, "language.title", "") ? (
                <Chip
                  label={get(recipeDetail, "language.title", "NA")}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor(
                      get(recipeDetail, "language.title", "NA")
                    ),
                    color: getContrastColor(
                      stringToColor(get(recipeDetail, "language.title", "NA"))
                    ),
                  }}
                />
              ) : null}
              {get(recipeDetail, "glutenFree", false) ? (
                <Chip
                  label={"Gluten Free"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Gluten Free"),
                    color: getContrastColor(stringToColor("Gluten Free")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "nutsFree", false) ? (
                <Chip
                  label={"Nuts Free"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Nuts Free"),
                    color: getContrastColor(stringToColor("Nuts Free")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "dairyFree", false) ? (
                <Chip
                  label={"Dairy Free"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Dairy Free"),
                    color: getContrastColor(stringToColor("Dairy Free")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "sugarFree", false) ? (
                <Chip
                  label={"Sugar Free"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Sugar Free"),
                    color: getContrastColor(stringToColor("Sugar Free")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "salads", false) ? (
                <Chip
                  label={"Salads"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Salads"),
                    color: getContrastColor(stringToColor("Salads")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "highProtein", false) ? (
                <Chip
                  label={"High Protein"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("High Protein"),
                    color: getContrastColor(stringToColor("High Protein")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "pregnancyFriendly", false) ? (
                <Chip
                  label={"Pregnancy Friendly"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Pregnancy Friendly"),
                    color: getContrastColor(
                      stringToColor("Pregnancy Friendly")
                    ),
                  }}
                />
              ) : null}
              {get(recipeDetail, "lowSugar", false) ? (
                <Chip
                  label={"Low Sugar"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Low Sugar"),
                    color: getContrastColor(stringToColor("Low Sugar")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "lowGIDiet", false) ? (
                <Chip
                  label={"Low GI Diet"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Low GI Diet"),
                    color: getContrastColor(stringToColor("Low GI Diet")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "lowFODMAPDiet", false) ? (
                <Chip
                  label={"Low Fodmap Diet"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Low Fodmap Diet"),
                    color: getContrastColor(stringToColor("Low Fodmap Diet")),
                  }}
                />
              ) : null}
              {get(recipeDetail, "intermittentDiet", false) ? (
                <Chip
                  label={"Intermittent Diet"}
                  size="small"
                  sx={{
                    backgroundColor: stringToColor("Intermittent Diet"),
                    color: getContrastColor(stringToColor("Intermittent Diet")),
                  }}
                />
              ) : null}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Swiper
            modules={[Virtual, Pagination, Navigation]}
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}
            virtual={true}
            spaceBetween={50}
            slidesPerView={1}
          >
            {map(get(recipeDetail, "topSection", []), (topContent, index) => (
              <SwiperSlide
                key={get(topContent, "_id", "")}
                virtualIndex={index}
                className="pb-4"
              >
                <Box>
                  <LazyLoadImage
                    alt={get(topContent, "title", "NA")}
                    src={get(topContent, "imageUrl", "")}
                    effect="blur"
                    className="rounded-2"
                  />
                  <Typography
                    variant="caption"
                    component="p"
                    textAlign="center"
                    color="grey.700"
                    className="mt-1"
                  >
                    {get(topContent, "title", "")}
                  </Typography>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            Ingredients
          </Typography>
          <Box className="mt-3 px-1">
            {map(get(recipeDetail, "ingredients", []), (ingredient, index) => (
              <Box key={index} className="border-bottom py-2 px-1">
                <DetailWrapper
                  sx={{
                    gridTemplateColumns: { sm: "calc(100% - 280px) 280px" },
                  }}
                >
                  <DetailTitle
                    title={capitalize(ingredient?.description)}
                    sx={{ color: "grey.700", textTransform: "capitalize" }}
                  />
                  <DetailValue
                    sx={{
                      textAlign: { sm: "right" },
                    }}
                  >
                    {ingredient?.measureType === "volume"
                      ? ingredient?.volume?.value +
                        " " +
                        ingredient?.volume?.unit
                      : ingredient?.measureType === "tablespoon"
                      ? ingredient?.tablespoon?.full ||
                        ingredient?.tablespoon?.parcel
                      : ingredient?.measureType === "portion"
                      ? ingredient?.portion?.value
                      : null}
                  </DetailValue>
                </DetailWrapper>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      {!isEmpty(get(recipeDetail, "recipeVideoLink", "")) ? (
        <Grid item xs={12}>
          <Box className="p-3 text-center">
            <Box className="player-wrapper rounded-2">
              <ReactPlayer
                url={get(recipeDetail, "recipeVideoLink", "")}
                controls={true}
                width="100%"
                height="100%"
                className="react-player"
              />
            </Box>
            <Typography mt={1} variant="caption">
              {get(recipeDetail, "recipeVideoTitle", "NA")}
            </Typography>
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            Recipe Method
          </Typography>
          <Box mt={2}>
            <Box
              component="div"
              dangerouslySetInnerHTML={createMarkup(
                get(recipeDetail, "recipeContent", "NA")
              )}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="rounded-2 overflow-auto">
          <Box sx={{ p: { md: 2, xs: 1 }, bgcolor: "primary.lighter" }}>
            <Typography variant="h6" fontWeight={600} gutterBottom={true}>
              Recipe Hot Tips
            </Typography>
            <Box className="mt-3 p-2 bg-white rounded-1">
              <Typography variant="body1">
                {get(recipeDetail, "recipeHotTip", "NA")}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ p: { md: 2, xs: 1 }, bgcolor: "grey.100" }}>
            <Box
              className="d-flex flex-wrap px-1"
              sx={{ rowGap: "16px", columnGap: "32px" }}
            >
              <DetailWrapper>
                <DetailTitle
                  title={"Prep Time"}
                  colon={true}
                  sx={{ color: "grey.700" }}
                />
                <DetailValue sx={{ textAlign: { sm: "right" } }}>
                  {get(recipeDetail, "prepTime.hour", "00") +
                    " : " +
                    get(recipeDetail, "prepTime.min", "00")}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle
                  title={"Cook Time"}
                  colon={true}
                  sx={{ color: "grey.700" }}
                />
                <DetailValue sx={{ textAlign: { sm: "right" } }}>
                  {get(recipeDetail, "cookTime.hour", "00") +
                    " : " +
                    get(recipeDetail, "cookTime.min", "00")}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle
                  title={"Total Time"}
                  colon={true}
                  sx={{ color: "grey.700" }}
                />
                <DetailValue sx={{ textAlign: { sm: "right" } }}>
                  {get(recipeDetail, "totalTime.hour", "00") +
                    " : " +
                    get(recipeDetail, "totalTime.min", "00")}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle
                  title={"Category"}
                  colon={true}
                  sx={{ color: "grey.700" }}
                />
                <DetailValue sx={{ textAlign: { sm: "right" } }}>
                  {get(recipeDetail, "preferredTime.breakFast", false)
                    ? "Breakfast"
                    : get(recipeDetail, "preferredTime.lunch", false)
                    ? "Lunch"
                    : get(recipeDetail, "preferredTime.dinner", false)
                    ? "Dinner"
                    : get(recipeDetail, "preferredTime.midMorningSnack", false)
                    ? "Mid Morning Snack"
                    : get(
                        recipeDetail,
                        "preferredTime.midAfternoonStatus",
                        false
                      )
                    ? "Mid Afternoon Snack"
                    : null}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle
                  title={"Cuisine"}
                  colon={true}
                  sx={{ color: "grey.700" }}
                />
                <DetailValue sx={{ textAlign: { sm: "right" } }}>
                  {get(recipeDetail, "cuisine.title", false)}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle
                  title={"Diet Preference"}
                  colon={true}
                  sx={{ color: "grey.700" }}
                />
                <DetailValue sx={{ textAlign: { sm: "right" } }}>
                  {get(recipeDetail, "dietPreference.title", false)}
                </DetailValue>
              </DetailWrapper>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            Nutrition
          </Typography>
          <Box
            className="d-flex flex-wrap mt-3 px-1"
            sx={{ rowGap: "16px", columnGap: "32px" }}
          >
            <DetailWrapper>
              <DetailTitle
                title={"Serving Size"}
                colon={true}
                sx={{ color: "grey.700" }}
              />
              <DetailValue sx={{ textAlign: { sm: "right" } }}>
                {get(recipeDetail, "recipeServingCount", "NA")}
              </DetailValue>
            </DetailWrapper>
            <DetailWrapper>
              <DetailTitle
                title={"Fat"}
                colon={true}
                sx={{ color: "grey.700" }}
              />
              <DetailValue sx={{ textAlign: { sm: "right" } }}>
                {get(recipeDetail, "totalFat", "NA") + " g"}
              </DetailValue>
            </DetailWrapper>
            <DetailWrapper>
              <DetailTitle
                title={"Protein"}
                colon={true}
                sx={{ color: "grey.700" }}
              />
              <DetailValue sx={{ textAlign: { sm: "right" } }}>
                {get(recipeDetail, "totalProtein", "NA") + " g"}
              </DetailValue>
            </DetailWrapper>
            <DetailWrapper>
              <DetailTitle
                title={"Carbohydrates"}
                colon={true}
                sx={{ color: "grey.700" }}
              />
              <DetailValue sx={{ textAlign: { sm: "right" } }}>
                {get(recipeDetail, "totalCarbs", "NA") + " g"}
              </DetailValue>
            </DetailWrapper>
            <DetailWrapper>
              <DetailTitle
                title={"Calories"}
                colon={true}
                sx={{ color: "grey.700" }}
              />
              <DetailValue sx={{ textAlign: { sm: "right" } }}>
                {get(recipeDetail, "totalCalories", "NA")}
              </DetailValue>
            </DetailWrapper>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            Personal Information
          </Typography>
          <Box className="mt-3 px-1">
            <Stack
              direction={{ sm: "row", xs: "column" }}
              flexWrap="wrap"
              sx={{ rowGap: 1.5, columnGap: 4 }}
            >
              <DetailWrapper>
                <DetailTitle title="Name" colon={true} />
                <DetailValue>
                  <Stack direction="row" flexWrap="wrap" className="gap-2">
                    <Typography variant="body1">
                      {get(recipeDetail, "createdBy", "") === "MASTER_ADMIN" ? (
                        <>
                          {get(recipeDetail, "adminId.firstName", "") +
                            " " +
                            get(recipeDetail, "adminId.lastName", "")}
                        </>
                      ) : (
                        <>
                          {get(recipeDetail, "consultantId.firstName", "") +
                            " " +
                            get(recipeDetail, "consultantId.lastName", "")}
                        </>
                      )}
                    </Typography>
                    <Chip
                      label={get(recipeDetail, "createdBy", "").replace(
                        "_",
                        " "
                      )}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="ms-2"
                    />
                  </Stack>
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle title="E-mail" colon={true} />
                <DetailValue>
                  {get(recipeDetail, "createdBy", "") === "MASTER_ADMIN" ? (
                    <>{get(recipeDetail, "adminId.email", "")}</>
                  ) : (
                    <>{get(recipeDetail, "consultantId.email", "")}</>
                  )}
                </DetailValue>
              </DetailWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            SEO Information
          </Typography>
          <Box className="mt-3 px-1">
            <Stack direction="column" spacing={1.5}>
              <DetailWrapper>
                <DetailTitle title="Publish" colon={true} />
                <DetailValue>
                  {get(recipeDetail, "isPublished", false) ? (
                    <CheckCircleIcon color="success" className="align-middle" />
                  ) : (
                    <CancelIcon color="error" className="align-middle" />
                  )}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper>
                <DetailTitle title="Comments Enabled" colon={true} />
                <DetailValue>
                  {get(recipeDetail, "comments", false) ? (
                    <CheckCircleIcon color="success" className="align-middle" />
                  ) : (
                    <CancelIcon color="error" className="align-middle" />
                  )}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle title="Title" colon={true} />
                <DetailValue>
                  {get(recipeDetail, "seoTitle", false)}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle title="Description" colon={true} />
                <DetailValue>
                  {get(recipeDetail, "description", false)}
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth="true">
                <DetailTitle title="Meta Tags" colon={true} />
                <DetailValue>
                  {get(recipeDetail, "metaTags", false)}
                </DetailValue>
              </DetailWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecipeDetail;
