// MUI
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// Custom
import MDCard from "components/md-components/card/MDCard";

const Dashboard = () => {
  return (
    <MDCard className="h-100">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h4" color="grey.700" fontWeight={700}>
                Dashboard
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}></Box>
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default Dashboard;
