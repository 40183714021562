const ExtendedZoom = (_ref) => {
  const { swiper } = _ref;
  const gesture = {};

  const setZoom = (scale) => {
    gesture.slideEl = swiper.slides[swiper.activeIndex];

    if (!swiper.params || typeof swiper.params.zoom !== "object") return;

    gesture.imageEl = gesture.slideEl.querySelector(
      `.${swiper.params.zoom.containerClass} img`
    );
    gesture.imageWrapEl = gesture.imageEl.closest(
      `.${swiper.params.zoom.containerClass}`
    );

    if (swiper.params.zoom.zoomedSlideClass) {
      gesture.slideEl.classList.add(swiper.params.zoom.zoomedSlideClass);
    }

    swiper.zoom.scale = scale;

    gesture.imageWrapEl.style.transition = "0.3s";
    gesture.imageWrapEl.style.transform = "translate(0, 0)";

    gesture.imageEl.style.transition = "0.3s";
    gesture.imageEl.style.transform = `translate(0, 0) scale(${swiper.zoom.scale})`;
  };

  swiper.zoom.setZoom = setZoom;
};

export default ExtendedZoom;
