import AxiosDefault from "services/AxiosDefaultSetting";

export const GetArticleList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/quotes/list`,
    data: data,
  });
  return response;
};

export const GetArticleNonApprovedList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/quotes/non-approved/list`,
    data: data,
  });
  return response;
};

export const UpdateApproveArticle = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/quotes/approve/${id}`,
    data: data,
  });
  return response;
};

export const ImageUploadArticle = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/quotes/image/upload`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const CreateUpdateArticle = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/quotes/add-update`,
    data: data,
  });
  return response;
};

export const PublishArticle = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/quotes/publish/${id}`,
    data: data,
  });
  return response;
};

export const DeleteArticle = async (id) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/quotes/delete/${id}`,
  });
  return response;
};
