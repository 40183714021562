import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DetailWrapper, { DetailTitle, DetailValue } from 'components/common/DetailWrapper';
import MDCard from 'components/md-components/card/MDCard';
import DetailCardSkeleton from 'components/md-components/skeleton/DetailCardSkeleton';

const EndUserBasicInfo = (props) => {
	const { userDetails } = props;
	const isLoading = useSelector((state) => state.endUser.loading);

	if (isLoading) {
		return <DetailCardSkeleton />;
	}
	return (
		<MDCard>
			<Box className='detail-box-header'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
					<Typography variant='body1' className='detail-box-heading'>
						Basic Information
					</Typography>
				</Stack>
			</Box>
			<CardContent>
				<Box display='grid' gap='12px' gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}>
					<DetailWrapper>
						<DetailTitle title='Gender' colon={true} />
						<DetailValue>{userDetails?.gender}</DetailValue>
					</DetailWrapper>
					<DetailWrapper>
						<DetailTitle title='Age' colon={true} />
						<DetailValue>{userDetails?.age}</DetailValue>
					</DetailWrapper>
				</Box>
			</CardContent>
		</MDCard>
	);
};

export default EndUserBasicInfo;
