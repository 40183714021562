import { Formik } from "formik";
import { get, isString, omit } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import EventIcon from "@mui/icons-material/Event";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDDialog from "components/md-components/dialog/MDDialog";
import FilePicker from "components/utility/file-picker/FilePicker";

import { updateConsultantHighSchoolInfo } from "store/slice/user-management/ConsultantProfileSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";

const HighSchoolInfoForm = (props) => {
  const { dialogOpen, handleDialogClose, dialogData, userDetails, userType } =
    props;
  const dispatch = useDispatch();

  const formValidations = Yup.object().shape({
    schoolName: Yup.string().required("Title is required"),
    fieldOfStudy: Yup.string().required("Employment Type is required"),
    location: Yup.string().required("Location is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    file: Yup.mixed().required("Document is required"),
  });

  const handleSubmit = (values) => {
    const rowData =
      get(dialogData, "formType", "") === "update"
        ? { ...values, _id: get(dialogData, "data._id") }
        : values;

    let filterValue = rowData;
    if (isString(get(filterValue, "file"))) {
      filterValue = omit(filterValue, "file");
    }

    const payloadData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: filterValue,
    };

    dispatch(updateConsultantHighSchoolInfo(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(
            get(result, "message", "High school info update successfully")
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={get(dialogData, "title", "High School Information")}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          schoolName: get(dialogData, "data.schoolName", ""),
          fieldOfStudy: get(dialogData, "data.fieldOfStudy", ""),
          location: get(dialogData, "data.location", ""),
          startDate: get(dialogData, "data.startDate", ""),
          endDate: get(dialogData, "data.endDate", ""),
          file: get(dialogData, "data.documentUrl", undefined),
        }}
        validationSchema={formValidations}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formProps) => (
          <form
            onSubmit={formProps.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="schoolName"
                    label="School Name"
                    name="schoolName"
                    value={formProps.values.schoolName}
                    error={
                      formProps.errors.schoolName &&
                      formProps.touched.schoolName
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.schoolName &&
                      formProps.touched.schoolName
                        ? formProps.errors.schoolName
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="fieldOfStudy"
                    label="Field of Study"
                    name="fieldOfStudy"
                    value={formProps.values.fieldOfStudy}
                    error={
                      formProps.errors.fieldOfStudy &&
                      formProps.touched.fieldOfStudy
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.fieldOfStudy &&
                      formProps.touched.fieldOfStudy
                        ? formProps.errors.fieldOfStudy
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="location"
                    label="Location"
                    name="location"
                    value={formProps.values.location}
                    error={
                      formProps.errors.location && formProps.touched.location
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.location && formProps.touched.location
                        ? formProps.errors.location
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MobileDatePicker
                    label="Start Date"
                    name="startDate"
                    value={formProps.values.startDate}
                    onChange={(newValue) => {
                      const newDate = newValue.format("YYYY/MM/DD");
                      formProps.setFieldValue("startDate", newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          formProps.errors.startDate &&
                          formProps.touched.startDate
                            ? true
                            : false
                        }
                        helperText={
                          formProps.errors.startDate &&
                          formProps.touched.startDate
                            ? formProps.errors.startDate
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle-start-date-datepicker"
                                edge="end"
                                onClick={params.inputProps.onClick}
                              >
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <MobileDatePicker
                    label="End Date"
                    name="endDate"
                    value={formProps.values.endDate}
                    onChange={(newValue) => {
                      const newDate = newValue.format("YYYY/MM/DD");
                      formProps.setFieldValue("endDate", newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          formProps.errors.endDate && formProps.touched.endDate
                            ? true
                            : false
                        }
                        helperText={
                          formProps.errors.endDate && formProps.touched.endDate
                            ? formProps.errors.endDate
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle-start-date-datepicker"
                                edge="end"
                                onClick={params.inputProps.onClick}
                              >
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FilePicker
                    label="Add Document"
                    fileLabel={true}
                    id="documentFilePicker"
                    name="file"
                    value={formProps.values.file}
                    setFieldValue={formProps.setFieldValue}
                    error={
                      formProps.errors.file && formProps.touched.file
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.file && formProps.touched.file
                        ? formProps.errors.file
                        : false
                    }
                    dropBoxGridSize={6}
                    previewGridSize={6}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                loading={formProps.isSubmitting}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default HighSchoolInfoForm;
