import React from 'react';
import { map } from 'lodash';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import MDCard from 'components/md-components/card/MDCard';
import QuestionDetailAccordion from '../component/QuestionDetailAccordion';
import MDCardHeader from 'components/md-components/card/MDCardHeader';

const DefaultSegmentSection = ({ data }) => {
	return (
		<MDCard variant='outlined'>
			<MDCardHeader title={data.sectionName} />
			<CardContent>
				<Box className='mb-2'>
					<Typography variant='body1' className='fw-medium' sx={{ color: 'grey.800' }}>
						Questions :
					</Typography>
				</Box>
				{map(data.questions, (defaultSectionQuestion, defaultSectionQuestionIndex) => (
					<Box key={`default-section-${defaultSectionQuestionIndex}`}>
						<QuestionDetailAccordion
							sectionType='default'
							customSectionsIndex={defaultSectionQuestionIndex}
							questionIndex={defaultSectionQuestionIndex}
							questionData={defaultSectionQuestion}
						/>
					</Box>
				))}
			</CardContent>
		</MDCard>
	);
};

export default DefaultSegmentSection;
