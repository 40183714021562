import { FieldArray, getIn } from "formik";
import { get, map, size } from "lodash";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";

import MDButton from "components/md-components/button/MDButton";
import MDRadio from "components/md-components/form/MDRadio";

import FilePicker from "components/utility/file-picker/FilePicker";

const RecipeFormTopSection = ({ formProps, handleImageUpload }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <TextField
            label="Title"
            name="title"
            value={formProps.values.title}
            onChange={formProps.handleChange}
            onBlur={formProps.handleBlur}
            error={
              formProps.errors.title && formProps.touched.title ? true : false
            }
            helperText={
              formProps.errors.title && formProps.touched.title
                ? formProps.errors.title
                : null
            }
            fullWidth={true}
            multiline={true}
            size="small"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TextField
            label="Subtitle"
            name="subTitle"
            value={formProps.values.subTitle}
            onChange={formProps.handleChange}
            onBlur={formProps.handleBlur}
            error={
              formProps.errors.subTitle && formProps.touched.subTitle
                ? true
                : false
            }
            helperText={
              formProps.errors.subTitle && formProps.touched.subTitle
                ? formProps.errors.subTitle
                : null
            }
            fullWidth={true}
            multiline={true}
            size="small"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6" gutterBottom={true}>
            Top Section
          </Typography>
          <FieldArray name="topSection">
            {({ remove, push }) => (
              <Box>
                <TransitionGroup component="div" appear>
                  {map(
                    formProps.values.topSection,
                    (topSectionItem, topSectionIndex) => {
                      const imageType = `topSection[${topSectionIndex}].imageType`;

                      const imageUrl = `topSection[${topSectionIndex}].imageUrl`;
                      const errorImageUrl = getIn(formProps.errors, imageUrl);
                      const touchedImageUrl = getIn(
                        formProps.touched,
                        imageUrl
                      );

                      const title = `topSection[${topSectionIndex}].title`;
                      const errorImgTitle = getIn(formProps.errors, title);
                      const touchedImgTitle = getIn(formProps.touched, title);

                      const isLargeTopSectionArray =
                        size(get(formProps, "values.topSection")) > 1;

                      return (
                        <Collapse key={topSectionIndex}>
                          <Box>
                            {topSectionIndex !== 0 ? (
                              <Box>
                                <Stack
                                  direction="row"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Divider
                                    orientation="horizontal"
                                    flexItem={true}
                                    light={true}
                                    sx={{
                                      width: `calc(100% - 48px)`,
                                      margin: "auto 0 !important",
                                    }}
                                  />
                                  {isLargeTopSectionArray && (
                                    <IconButton
                                      color="error"
                                      onClick={() => remove(topSectionIndex)}
                                    >
                                      <ClearOutlinedIcon />
                                    </IconButton>
                                  )}
                                </Stack>
                              </Box>
                            ) : null}
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <FormControl fullWidth className="gap-2">
                                  <FormLabel id="image-type-group">
                                    Image Type
                                  </FormLabel>
                                  <RadioGroup
                                    name={imageType}
                                    value={topSectionItem?.imageType}
                                    onChange={(_e, value) => {
                                      formProps.setFieldValue(imageType, value);
                                    }}
                                    aria-label="image-type-group"
                                    row={true}
                                    sx={{
                                      marginLeft: 1,
                                    }}
                                  >
                                    <FormControlLabel
                                      value="IMAGE_UPLOAD"
                                      control={<MDRadio />}
                                      label="Image Upload"
                                      className="ms-0"
                                    />
                                    <FormControlLabel
                                      value="IMAGE_LINK"
                                      control={<MDRadio />}
                                      label="Image Link"
                                      className="ms-0"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <Box className="mt-1">
                                  {topSectionItem?.imageType ===
                                  "IMAGE_UPLOAD" ? (
                                    <FilePicker
                                      label="Select Image"
                                      id={imageUrl}
                                      name={imageUrl}
                                      value={topSectionItem?.imageUrl}
                                      onChange={(file) => {
                                        handleImageUpload(
                                          file,
                                          "top",
                                          imageUrl,
                                          formProps.setFieldValue
                                        );
                                      }}
                                      error={
                                        errorImageUrl && touchedImageUrl
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errorImageUrl && touchedImageUrl
                                          ? errorImageUrl
                                          : null
                                      }
                                      dropBoxGridSize={6}
                                      previewGridSize={6}
                                      
                                    />
                                  ) : (
                                    <TextField
                                      label="Image Link"
                                      name={imageUrl}
                                      value={topSectionItem?.imageUrl}
                                      onChange={formProps.handleChange}
                                      onBlur={formProps.handleBlur}
                                      error={
                                        errorImageUrl && touchedImageUrl
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errorImageUrl && touchedImageUrl
                                          ? errorImageUrl
                                          : null
                                      }
                                      fullWidth={true}
                                      multiline={true}
                                      size="small"
                                    />
                                  )}
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  label="Image Title"
                                  name={title}
                                  value={topSectionItem?.title}
                                  onChange={formProps.handleChange}
                                  onBlur={formProps.handleBlur}
                                  error={
                                    errorImgTitle && touchedImgTitle
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errorImgTitle && touchedImgTitle
                                      ? errorImgTitle
                                      : null
                                  }
                                  fullWidth={true}
                                  multiline={true}
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Collapse>
                      );
                    }
                  )}
                </TransitionGroup>
                <Box sx={{ mt: 3 }}>
                  <MDButton
                    variant="outlined"
                    onClick={() =>
                      push({
                        imageType: "IMAGE_UPLOAD",
                        imageUrl: "",
                        title: "",
                      })
                    }
                  >
                    Add New
                  </MDButton>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecipeFormTopSection;
