import { combineReducers } from '@reduxjs/toolkit';

import articleReducer from 'store/slice/article-blog/ArticleSlice';
import authReducer from 'store/slice/auth/AuthSlice';
import bookingReducer from 'store/slice/booking/BookingSlice';
import commonDataReducer from 'store/slice/common/CommonDataSlice';
import generalConfigDataReducer from 'store/slice/common/GeneralConfigDataSlice';
import QAndAReducer from 'store/slice/consultant-config/QAndASlice';
import QAndATemplateReducer from 'store/slice/consultant-config/QAndATemplateSlice';
import countriesReducer from 'store/slice/country-profile/CountriesSlice';
import countryCitiesReducer from 'store/slice/country-profile/CountryCitiesSlice';
import countryPoliciesReducer from 'store/slice/country-profile/CountryPoliciesSlice';
import countryProfileReducer from 'store/slice/country-profile/CountryProfileSlice';
import countryStatesReducer from 'store/slice/country-profile/CountryStatesSlice';
import countryIdentityProofReducer from 'store/slice/country-profile/IdentityProofSlice';
import recipeReducer from 'store/slice/health-food/RecipeSlice';
import mealReducer from 'store/slice/health-food/MealSlice';
import dietPlanReducer from 'store/slice/health-food/DietPlanSlice';
import languageConfigReducer from 'store/slice/language-config/LanguageConfigSlice';
import adminRoleReducer from 'store/slice/role-management/AdminRoleSlice';
import adminUsersReducer from 'store/slice/role-management/AdminUserSlice';
import generalConfigReducer from 'store/slice/user-config/GeneralConfigSlice';
import consultantProfileReducer from 'store/slice/user-management/ConsultantProfileSlice';
import consultantUserReducer from 'store/slice/user-management/ConsultantUserSlice';
import endUserReducer from 'store/slice/user-management/EndUserSlice';
import ContentReducer from 'store/slice/content/ContentSlice';
import PressReducer from 'store/slice/press-release/PresReleaseSlice';
import QuotesReducer from 'store/slice/quotes-management/QuotesSlice';
import common from 'store/slice/common';
const combinedReducer = combineReducers({
	auth: authReducer,
	adminUsers: adminUsersReducer,
	adminRoles: adminRoleReducer,
	article: articleReducer,
	booking: bookingReducer,
	common: common,
	commonData: commonDataReducer,
	countryProfile: countryProfileReducer,
	countries: countriesReducer,
	countryStates: countryStatesReducer,
	countryCities: countryCitiesReducer,
	countryIdentityProof: countryIdentityProofReducer,
	countryPolicies: countryPoliciesReducer,
	consultantUser: consultantUserReducer,
	consultantProfile: consultantProfileReducer,
	endUser: endUserReducer,
	generalConfigData: generalConfigDataReducer,
	generalConfig: generalConfigReducer,
	healthRecipe: recipeReducer,
	healthMeal: mealReducer,
	healthDietPlan: dietPlanReducer,
	languageConfig: languageConfigReducer,
	QAndA: QAndAReducer,
	QAndATemplate: QAndATemplateReducer,
	Content: ContentReducer,
	PressRelease: PressReducer,
	quotes: QuotesReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'auth/logout') {
		state = undefined;
	}
	return combinedReducer(state, action);
};
export default rootReducer;
