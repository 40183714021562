import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import urlToFile from "components/hooks/urlToFile";
import MDButton from "components/md-components/button/MDButton";
import MDDialog from "components/md-components/dialog/MDDialog";
import AvatarPicker from "./index";

const DialogAvatarPicker = (props) => {
  const {
    title,
    open,
    handleClose,
    imagePreview,
    setImagePreview,
    name,
    setFieldValue,
  } = props;

  const [editor, setEditor] = useState(null);

  let avatarRef = useRef(null);

  avatarRef = (editor) => {
    setEditor(editor);
  };

  const handleSave = () => {
    const canvas = editor.getImage().toDataURL();

    urlToFile(
      canvas,
      get(editor, "props.image.name", "profile_pic"),
      get(editor, "props.image.type", "image/jpeg")
    ).then(function (file) {
      setFieldValue(name, file);
    });

    let imageURL = "";

    fetch(canvas)
      .then((res) => res.blob())
      .then((blob) => {
        imageURL = window.URL.createObjectURL(blob);
        imageURL && setImagePreview(imageURL);
      });

    handleClose();
  };

  return (
    <MDDialog
      title={title ?? "Avatar Picker"}
      open={open}
      close={handleClose}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="avatar-picker-dialog"
      aria-describedby="avatar-picker-dialog"
    >
      <DialogContent dividers={true}>
        <AvatarPicker
          ref={avatarRef}
          selectedImage={imagePreview}
          setImagePreview={setImagePreview}
        />
      </DialogContent>
      <DialogActions>
        <MDButton label="Save" variant="contained" onClick={handleSave} />
      </DialogActions>
    </MDDialog>
  );
};

DialogAvatarPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  imagePreview: PropTypes.string.isRequired,
  setImagePreview: PropTypes.func.isRequired,
};

export default DialogAvatarPicker;
