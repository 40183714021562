import { map } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
// material
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
//

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: 500,
  height: 36,
  position: "relative",
  textTransform: "capitalize",
  paddingTop: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  color: theme.palette.grey[300],
  borderRadius: theme.spacing(0.5),
  "&:hover": {
    color: "primary.contrastText",
    backgroundColor: theme.palette.primary.main,
  },
  "&.Mui-selected": {
    color: "primary.contrastText",
    backgroundImage: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: "42px",
    marginRight: theme.spacing(1),
    justifyContent: "flex-end",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "inherit",
});

// ----------------------------------------------------------------------

const NavItem = (props) => {
  const { item, active, ...rest } = props;

  const isActiveRoot = active(item?.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.contrastText",
    fontWeight: "fontWeightMedium",
    backgroundColor: "primary.main",
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
          {...rest}
        >
          <ListItemIconStyle>
            <Box
              component={icon && icon}
              color="inherit"
              sx={{ fontSize: "1.25rem" }}
            />
          </ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info}
          <Box
            component={open ? ExpandLessIcon : ExpandMoreIcon}
            color="inherit"
            sx={{ fontSize: "1.25rem" }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {map(children, (item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={path ? RouterLink : "li"}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={path ? RouterLink : "li"}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      {...rest}
    >
      <ListItemIconStyle>
        <Box
          component={icon && icon}
          color="inherit"
          sx={{ fontSize: "1.25rem" }}
        />
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

export default NavItem;
