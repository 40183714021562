import * as React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        px: 2,
        py: 1.5,
        position: "relative",
        fontSize: { sm: "1.125rem", xs: "0.875rem" },
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "5px",
            top: "50%",
            transform: "translate(-5px,-50%)",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const DialogContentFooter = (props) => {
  const { padding, children, ...rest } = props;

  return (
    <Box
      className="position-absolute bottom-0 left-0 border-top w-100"
      sx={{ p: padding ?? 1, bgcolor: "#fff", zIndex: 9 }}
      {...rest}
    >
      {children}
    </Box>
  );
};

const MDDialog = (props) => {
  const {
    open,
    close,
    title,
    content,
    action,
    children,
    sx,
    maxWidth,
    dividers,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <>
      <BootstrapDialog
        open={open}
        onClose={close}
        maxWidth={maxWidth ? maxWidth : "lg"}
        sx={{
          "& .MuiDialog-paper": {
            [theme.breakpoints.down("sm")]: {
              margin: { xs: 2 },
            },
          },
          ...sx,
        }}
        {...rest}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={close}>
          {title}
        </BootstrapDialogTitle>
        {content && (
          <DialogContent dividers={dividers}>{content}</DialogContent>
        )}
        {action && <DialogActions>{action}</DialogActions>}
        {children}
      </BootstrapDialog>
    </>
  );
};
export default MDDialog;
