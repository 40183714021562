import { filter, get } from "lodash";
import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import MDAccordion from "components/md-components/accordion/MDAccordion";
import MDAccordionDetails from "components/md-components/accordion/MDAccordionDetails";
import MDAccordionSummary from "components/md-components/accordion/MDAccordionSummary";
import QuestionDetail from "./QuestionDetail";

const QuestionDetailAccordion = (props) => {
  const {
    sectionType,
    sectionIndex,
    questionIndex,
    questionData,
    setFieldValue,
    fieldName,
    fieldValue,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <MDAccordion
      key={sectionType + "_" + questionIndex}
      expanded={
        expanded ===
        `${sectionType}-sections-${sectionIndex}-panel-${questionIndex}`
      }
      onChange={handleChange(
        `${sectionType}-sections-${sectionIndex}-panel-${questionIndex}`
      )}
      className="mb-2"
    >
      <MDAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sectionType}-sections-${sectionIndex}-panel-${questionIndex}-content`}
        id={`${sectionType}-sections-${sectionIndex}-panel-${questionIndex}-header`}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          className="w-100"
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" fontWeight={500}>
              {questionIndex + 1}.
            </Typography>
            <Typography variant="body1" className="text-break">
              {get(questionData, "questionTitle", "")}
            </Typography>
          </Stack>
          {sectionType !== "default" ? (
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                setFieldValue(
                  fieldName,
                  filter(fieldValue, (data) => {
                    return data.questionId._id !== questionData._id;
                  })
                );
              }}
              className="me-1"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          ) : null}
        </Stack>
      </MDAccordionSummary>
      <MDAccordionDetails>
        <QuestionDetail
          questionIndex={questionIndex}
          questionData={questionData}
        />
      </MDAccordionDetails>
    </MDAccordion>
  );
};

export default QuestionDetailAccordion;
