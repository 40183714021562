import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import MDSwitch from 'components/md-components/switch/MDSwitch';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import MDDataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import MDButton from 'components/md-components/button/MDButton';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import {
	getQuestionsTemplateList,
	statusUpdateQuestionTemplate,
} from 'store/slice/consultant-config/QAndATemplateSlice';

const CustomToolbar = () => {
	const navigate = useNavigate();

	return (
		<MDDataGridToolbar align='right' divider={true} ToolbarQuickFilter={true}>
			<MDButton
				variant='contained'
				label='Create New'
				startIcon={<AddCircleOutlineIcon />}
				fontSize='14px'
				onClick={() => {
					navigate('/consultant-config/default-question-segment-create-update', {
						state: {
							formType: 'create',
							rowData: [],
						},
					});
				}}
			/>
		</MDDataGridToolbar>
	);
};
const QAndASegmentList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const questionTemplateListData = useSelector((state) => state.QAndATemplate.list);

	const isLoading = useSelector((state) => state.QAndATemplate.loading);

	const [dialogData, setDialogData] = useState({
		id: '',
		title: '',
		rowData: [],
	});

	const handleStatusUpdate = useCallback(
		(event, row) => {
			setDialogData({ ...dialogData, id: row._id });
			const apiData = {
				id: row._id,
				data: { status: event.target.checked },
			};
			dispatch(statusUpdateQuestionTemplate(apiData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						dispatch(getQuestionsTemplateList('default'));
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[dialogData, dispatch]
	);

	useEffect(() => {
		dispatch(getQuestionsTemplateList('default'));
	}, [dispatch]);

	const columnsData = useMemo(
		() => [
			{
				field: 'sectionName',
				headerName: 'Segment Name',
				width: 380,
				editable: false,
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				width: 220,
				renderCell: ({ row }) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label='edit'
								color='primary'
								onClick={() => {
									navigate('/consultant-config/default-question-segment-create-update', {
										state: {
											formType: 'update',
											rowData: row,
										},
									});
								}}
							>
								<EditIcon fontSize='14px' />
							</IconButton>
							<MDSwitch
								checked={row.status}
								onChange={(event) => {
									handleStatusUpdate(event, row);
								}}
								inputProps={{ 'aria-label': row.id, role: 'switch' }}
								color='success'
								disabled={get(dialogData, 'id', '') === row._id && isLoading}
							/>
						</Box>
					);
				},
			},
		],
		[dialogData, handleStatusUpdate, isLoading, navigate]
	);

	return (
		<>
			<MDDataGrid
				rows={!isEmpty(questionTemplateListData) ? questionTemplateListData : []}
				columns={columnsData}
				getRowId={(row) => row._id}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick={true}
				loading={isLoading}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
				autoHeight
			/>
		</>
	);
};

export default QAndASegmentList;
