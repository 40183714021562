import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { Formik } from "formik";
import { get, omit } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { cookieSet } from "components/hooks/cookie";
import MDButton from "components/md-components/button/MDButton";
import OTPInput from "components/md-components/form/OTPInput";
import { postLogin } from "store/slice/auth/AuthSlice";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const loginWithOTP = useSelector((state) => state.auth.loginWithOTP);

  const handleClickShowPassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email("Enter valid E-Mail ID")
      .required("E-Mail is required"),
    password: Yup.string().required("Password is required"),
    otp: loginWithOTP
      ? Yup.string().length(6, "Enter valid OTP").required("OTP is required")
      : Yup.string(),
  });

  const handleLogin = async (values) => {
    if (!executeRecaptcha) {
      toast.error("You are not authorized to login");
      return;
    }

    const recaptchaToken = await executeRecaptcha("yourAction");

    const payloadData = !loginWithOTP
      ? {
          ...omit(values, "otp"),
          googleRecaptchaToken: recaptchaToken,
        }
      : {
          ...omit(values, "otp"),
          googleRecaptchaToken: recaptchaToken,
        };

    const res = await dispatch(postLogin(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false) === true) {
          cookieSet("auth_token", get(result, "data.token"), 24, "/");
          cookieSet("role_type", get(result, "data.roleType"), 24, "/");
          cookieSet("selectedMenuId", 1, 24, "/");
          navigate("/dashboard");
          toast.success(get(result, "message", "Login successfully"));
        } else {
          toast.error(get(result, "message", "Something went wrong"));
        }
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        otp: "",
      }}
      validationSchema={Schema}
      onSubmit={(values) => {
        handleLogin(values);
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={3}>
            {!loginWithOTP ? (
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    label="E-Mail ID"
                    name="email"
                    value={props.values.email}
                    error={
                      props.errors.email && props.touched.email ? true : false
                    }
                    helperText={
                      props.errors.email && props.touched.email
                        ? props.errors.email
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ position: "relative" }}>
                    <TextField
                      fullWidth={true}
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={props.values.password}
                      error={
                        props.errors.password && props.touched.password
                          ? true
                          : false
                      }
                      helperText={
                        props.errors.password && props.touched.password
                          ? props.errors.password
                          : null
                      }
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              className="pass-visibility-button"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="body1" className="text-center">
                    One Time Password sent to :{" "}
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      component="span"
                    >
                      {props.values.email}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <OTPInput
                    value={props.values.otp}
                    valueLength={6}
                    onChange={(newValue) => {
                      props.setFieldValue("otp", newValue);
                    }}
                    onBlur={props.handleBlur}
                    error={Boolean(props.errors.otp && true)}
                    helperText={
                      props.errors.otp && true ? props.errors.otp : null
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className="mt-2 text-center">
                <MDButton
                  type="submit"
                  variant="contained"
                  size="large"
                  fontSize={"18px"}
                  padding="8px 64px"
                >
                  Login
                </MDButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
