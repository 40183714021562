import { useDispatch } from "react-redux";
import { get, omit, isString } from "lodash";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

import EventIcon from "@mui/icons-material/Event";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { MobileDatePicker } from "@mui/x-date-pickers";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDDialog from "components/md-components/dialog/MDDialog";
import FilePicker from "components/utility/file-picker/FilePicker";

import { updateConsultantWorkInfo } from "store/slice/user-management/ConsultantProfileSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";

const WorkInfoForm = (props) => {
  const { dialogOpen, handleDialogClose, dialogData, userDetails, userType } =
    props;
  const dispatch = useDispatch();

  const formValidations = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    employmentType: Yup.string().required("Employment Type is required"),
    companyName: Yup.string().required("Company Name is required"),
    location: Yup.string().required("Location is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().when("isCurrent", {
      is: false,
      then: Yup.string().required("End Date is required"),
      otherwise: Yup.string().notRequired(),
    }),
    file: Yup.mixed().required("Document is required."),
  });

  const handleSubmit = (values) => {
    const rowData =
      get(dialogData, "formType", "") === "update"
        ? { ...values, _id: get(dialogData, "data._id") }
        : values;

    let filterValue = rowData;
    if (isString(get(filterValue, "file"))) {
      filterValue = omit(filterValue, "file");
    }
    if (filterValue?.isCurrent) {
      filterValue = omit(filterValue, "endDate");
    }

    const payloadData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: filterValue,
    };

    dispatch(updateConsultantWorkInfo(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(
            get(result, "message", "Work info update successfully")
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={get(dialogData, "title", "Work Information")}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          title: get(dialogData, "data.title", ""),
          employmentType: get(dialogData, "data.employmentType", ""),
          companyName: get(dialogData, "data.companyName", ""),
          location: get(dialogData, "data.location", ""),
          isCurrent: get(dialogData, "data.isCurrent", false),
          startDate: get(dialogData, "data.startDate", ""),
          endDate: get(dialogData, "data.endDate", ""),
          file: get(dialogData, "data.documentUrl", undefined),
        }}
        validationSchema={formValidations}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formProps) => (
          <form
            onSubmit={formProps.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="title"
                    label="Title"
                    name="title"
                    value={formProps.values.title}
                    error={
                      formProps.errors.title && formProps.touched.title
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.title && formProps.touched.title
                        ? formProps.errors.title
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="employmentType"
                    label="Employment Type"
                    name="employmentType"
                    value={formProps.values.employmentType}
                    error={
                      formProps.errors.employmentType &&
                      formProps.touched.employmentType
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.employmentType &&
                      formProps.touched.employmentType
                        ? formProps.errors.employmentType
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="companyName"
                    label="Company Name"
                    name="companyName"
                    value={formProps.values.companyName}
                    error={
                      formProps.errors.companyName &&
                      formProps.touched.companyName
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.companyName &&
                      formProps.touched.companyName
                        ? formProps.errors.companyName
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="location"
                    label="Location"
                    name="location"
                    value={formProps.values.location}
                    error={
                      formProps.errors.location && formProps.touched.location
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.location && formProps.touched.location
                        ? formProps.errors.location
                        : null
                    }
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    size="small"
                    fullWidth={true}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider light />
                  <FormControl fullWidth={true} className="mt-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isCurrent"
                          checked={formProps.values.isCurrent}
                          value={formProps.values.isCurrent}
                          onChange={(e) => {
                            formProps.setFieldValue(
                              "isCurrent",
                              e.target.checked
                            );
                          }}
                          inputProps={{
                            "aria-label": `isCurrent-status`,
                          }}
                        />
                      }
                      label="I am currently working here."
                      sx={{ mr: 0 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <MobileDatePicker
                    label="Start Date"
                    name="startDate"
                    value={formProps.values.startDate}
                    onChange={(newValue) => {
                      const newDate = newValue.format("YYYY/MM/DD");
                      formProps.setFieldValue("startDate", newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          formProps.errors.startDate &&
                          formProps.touched.startDate
                            ? true
                            : false
                        }
                        helperText={
                          formProps.errors.startDate &&
                          formProps.touched.startDate
                            ? formProps.errors.startDate
                            : null
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle-start-date-datepicker"
                                edge="end"
                                onClick={params.inputProps.onClick}
                              >
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {!formProps.values.isCurrent ? (
                  <Grid item md={6} xs={12}>
                    <MobileDatePicker
                      label="End Date"
                      name="endDate"
                      value={formProps.values.endDate}
                      onChange={(newValue) => {
                        const newDate = newValue.format("YYYY/MM/DD");
                        formProps.setFieldValue("endDate", newDate);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth={true}
                          error={
                            formProps.errors.endDate &&
                            formProps.touched.endDate
                              ? true
                              : false
                          }
                          helperText={
                            formProps.errors.endDate &&
                            formProps.touched.endDate
                              ? formProps.errors.endDate
                              : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle-start-date-datepicker"
                                  edge="end"
                                  onClick={params.inputProps.onClick}
                                >
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FilePicker
                    label="Add Document"
                    fileLabel={true}
                    id="documentFilePicker"
                    name="file"
                    value={formProps.values.file}
                    setFieldValue={formProps.setFieldValue}
                    error={
                      formProps.errors.file && formProps.touched.file
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.file && formProps.touched.file
                        ? formProps.errors.file
                        : false
                    }
                    dropBoxGridSize={6}
                    previewGridSize={6}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                // loading={formProps.isSubmitting}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default WorkInfoForm;
