import React, { useCallback, useEffect, useMemo, useState } from "react";
import { get, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

import useFakeMutation from "components/hooks/useFakeMutation";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import TabPanel from "components/md-components/tab/TabPanel";
import {
  getLanguageMacro,
  valueUpdateLanguageMacro,
} from "store/slice/language-config/LanguageConfigSlice";

function a11yProps(index) {
  return {
    id: `language-macros-list-tab-${index}`,
    "aria-controls": `language-macros-list-tab-panel-${index}`,
  };
}

const MacroList = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const breakpointSM = useMediaQuery(theme.breakpoints.up("sm"));

  const languageId = get(location, "state.languageId", "");
  const languageTitle = get(location, "state.languageTitle", "");

  const isLoading = useSelector((state) => state.languageConfig.loading);
  const LanguageMacroListData = useSelector(
    (state) => state.languageConfig.macroList
  );

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(0);

  const [sortModel, setSortModel] = useState({
    sortModel: [
      {
        field: "isTranslated",
        sort: "desc",
      },
    ],
  });
  const [filterModel, setFilterModel] = useState({
    filterModel: {
      items: [
        {
          columnField: "",
          id: 38876,
          operatorValue: "contains",
          value: "",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  });
  const [apiType, setApiType] = useState("system_1");
  const [rowModesModel, setRowModesModel] = useState({});

  const [value, setValue] = useState(0);

  const tabs = ["System 1 (Front)", "System 2 (Consultant)", "Common"];

  const handleTabChange = (event, newValue) => {
    setFilterModel({
      filterModel: { ...filterModel?.filterModel, quickFilterValues: [] },
    });
    setValue(newValue);
    setPageNo(0);
    if (event?.target?.textContent === "System 1 (Front)") {
      setApiType("system_1");
    } else if (event?.target?.textContent === "System 2 (Consultant)") {
      setApiType("system_2");
    } else if (event?.target?.textContent === "Common") {
      setApiType("common");
    }
  };

  const handlePageChange = useCallback((newPage) => {
    setPageNo(newPage);
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    setSortModel({ sortModel: [...sortModel] });
  }, []);

  const handleFilterChange = useCallback((filterModel) => {
    setFilterModel({ filterModel: { ...filterModel } });
    setPageNo(0);
  }, []);

  const getMacrosListData = useCallback(
    (data) => {
      dispatch(getLanguageMacro(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            const totalMacros = get(result, "data.totalMacros", 0);
            setRowCountState(totalMacros);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    [dispatch]
  );

  const handleRowEditStart = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (_params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = useCallback(
    (id) => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id) => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCancelClick = useCallback(
    (id) => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const mutateRow = useFakeMutation();

  const processRowUpdate = React.useCallback(
    async (newRow) => {
      const response = await mutateRow("value", newRow);
      const apiData = {
        id: get(newRow, "_id", ""),
        data: { languageId: languageId, value: get(newRow, "value", "") },
      };
      dispatch(valueUpdateLanguageMacro(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            toast.success(get(result, "message", "Macro update successfully."));
            const payloadData = {
              langId: languageId,
              data: {
                search: get(filterModel, "filterModel.quickFilterValues", "")
                  .toString()
                  .replace(/,/g, " "),
                type: apiType ?? "",
                sortField: get(sortModel, "sortModel.0.field", "isTranslated"),
                sortValue: get(sortModel, "sortModel.0.sort", "desc"),
                pageNo: pageNo,
              },
            };
            getMacrosListData(payloadData);
          } else {
            toast.error(get(result, "message", "Something went wrong."));
          }
        })
        .catch((err) => {
          throw err;
        });
      return response;
    },
    [
      apiType,
      dispatch,
      filterModel,
      getMacrosListData,
      languageId,
      mutateRow,
      pageNo,
      sortModel,
    ]
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error(error.message);
  }, []);

  useEffect(() => {
    const payloadData = {
      langId: languageId,
      data: {
        search: get(filterModel, "filterModel.quickFilterValues", "")
          .toString()
          .replace(/,/g, " "),
        type: apiType ?? "",
        sortField: get(sortModel, "sortModel.0.field", "isTranslated"),
        sortValue: get(sortModel, "sortModel.0.sort", "desc"),
        pageNo: pageNo,
      },
    };
    if (!isEmpty(languageId)) {
      getMacrosListData(payloadData);
    }
  }, [
    apiType,
    dispatch,
    filterModel,
    getMacrosListData,
    languageId,
    pageNo,
    sortModel,
  ]);

  const columnsData = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 320,
        editable: false,
      },
      {
        field: "type",
        headerName: "Type",
        width: 160,
        editable: false,
      },
      {
        field: "value",
        headerName: "Value",
        width: 320,
        editable: true,
      },
      {
        field: "isTranslated",
        headerName: "Is Translated ?",
        type: "boolean",
        width: 140,
        editable: false,
        renderCell: ({ row }) => {
          return row.isTranslated ? (
            <CheckIcon color="success" />
          ) : (
            <CloseIcon color="error" />
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        type: "actions",
        width: 140,
        getActions: ({ row }) => {
          const isInEditMode =
            rowModesModel[row._id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon color="primary" />}
                label="Save"
                onClick={() => {
                  handleSaveClick(row._id);
                }}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<CloseIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={() => {
                  handleCancelClick(row._id);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon color="primary" />}
              label="Edit"
              onClick={() => {
                handleEditClick(row._id);
              }}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [handleCancelClick, handleEditClick, handleSaveClick, rowModesModel]
  );

  const CustomToolbar = () => {
    return (
      <MDDataGridToolbar
        DisableToolbarColumnsButton={true}
        ToolbarQuickFilter={true}
        divider={true}
      />
    );
  };

  return (
    <MDCard>
      <MDCardHeader
        title={`Macros Listing Translate ${languageTitle} Language `}
      />
      <CardContent className="h-100">
        <Grid container spacing={0} className="h-100 align-content-start">
          <Grid item xs={12}>
            <Box sx={{ bgcolor: theme.palette.background.default }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                variant={breakpointSM ? "fullWidth" : "scrollable"}
                scrollButtons={breakpointSM ? false : true}
                aria-label="signup config tabs"
              >
                {map(tabs, (item, index) => (
                  <Tab label={item} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {map(tabs, (_item, index) => (
                <TabPanel key={index} value={value} index={index}>
                  <MDDataGrid
                    rows={LanguageMacroListData ?? []}
                    rowCount={rowCountState}
                    columns={columnsData}
                    getRowId={(row) => row._id}
                    editMode="cell"
                    rowModesModel={rowModesModel}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    disableSelectionOnClick
                    loading={isLoading}
                    pagination
                    page={pageNo}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    filterMode="server"
                    onFilterModelChange={handleFilterChange}
                    components={{
                      Toolbar: CustomToolbar,
                      NoRowsOverlay: EmptyTableRowOverlay,
                      LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      sorting: sortModel,
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    autoHeight
                  />
                </TabPanel>
              ))}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default MacroList;
