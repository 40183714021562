import React from "react";

import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material";

const CustomCardHeader = styled(CardHeader)(({ theme, other }) => ({
  borderBottomWidth: other?.border ? other?.border : "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "#e9ebec",
  backgroundColor:
    other?.bg_color || other?.bg_color_variant
      ? theme.palette[other?.bg_color][other?.bg_color_variant]
      : theme.palette.background.paper,
  ".MuiCardHeader-title": {
    fontSize: other.title_size ?? "1.25rem",
    fontWeight: other.title_weight ?? 600,
    color:
      other?.title_color && other?.title_color_variant
        ? theme.palette[other?.title_color][other?.title_color_variant]
        : theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: other.title_size ?? "1.125rem",
    },
  },
}));

const MDCardHeader = (props) => {
  const { ...rest } = props;

  return <CustomCardHeader other={props} {...rest} />;
};

export default MDCardHeader;
