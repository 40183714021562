import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const ScaleForm = ({ formProps }) => {
	return (
		<Fade in={true}>
			<Grid item xs={12}>
				<Box className='mb-3 px-2'>
					<Typography variant='body1' fontWeight={500}>
						Scale Values
					</Typography>
				</Box>
				<Box>
					<Grid container spacing={2} sx={{ mb: 1 }}>
						<Grid item sm={4} xs={12}>
							<TextField
								fullWidth
								size='small'
								label='Minimum Value'
								type='number'
								name='minValue'
								value={formProps.values.minValue}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								helperText={
									formProps.errors.minValue && formProps.touched.minValue
										? formProps.errors.minValue
										: null
								}
								error={Boolean(
									formProps.errors.minValue && formProps.touched.minValue
								)}
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								fullWidth
								size='small'
								label='Maximum Value'
								type='number'
								name='maxValue'
								value={formProps.values.maxValue}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								helperText={
									formProps.errors.maxValue && formProps.touched.maxValue
										? formProps.errors.maxValue
										: null
								}
								error={Boolean(
									formProps.errors.maxValue && formProps.touched.maxValue
								)}
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								fullWidth
								size='small'
								label='Step Value'
								type='number'
								name='stepValue'
								value={formProps.values.stepValue}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								helperText={
									formProps.errors.stepValue && formProps.touched.stepValue
										? formProps.errors.stepValue
										: null
								}
								error={Boolean(
									formProps.errors.stepValue && formProps.touched.stepValue
								)}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Fade>
	);
};

export default ScaleForm;
