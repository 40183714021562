import React from "react";

import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const MDTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ color, padding, theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: getBGColor(color, theme),
    color: getTextColor(color, theme),
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: padding ? padding : "4px 8px",
  },
}));
export default MDTooltip;

const getBGColor = (color, theme) => {
  if (color === "white") {
    return theme.palette.common.white;
  } else if (color === "black") {
    return theme.palette.common.black;
  } else if (color === "primary") {
    return theme.palette.primary.main;
  } else if (color === "secondary") {
    return theme.palette.secondary.main;
  } else if (color === "success") {
    return theme.palette.success.main;
  } else if (color === "error") {
    return theme.palette.error.main;
  } else if (color === "warning") {
    return theme.palette.warning.main;
  } else if (color === "info") {
    return theme.palette.info.main;
  }
  return theme.palette.common.white;
};

const getTextColor = (color, theme) => {
  if (color === "white") {
    return "rgba(0, 0, 0, 0.87)";
  } else if (color === "black") {
    return "rgba(255, 255, 255, 0.87)";
  } else if (color === "primary") {
    return theme.palette.primary.contrastText;
  } else if (color === "secondary") {
    return theme.palette.secondary.contrastText;
  } else if (color === "success") {
    return theme.palette.success.contrastText;
  } else if (color === "error") {
    return theme.palette.error.contrastText;
  } else if (color === "warning") {
    return theme.palette.warning.contrastText;
  } else if (color === "info") {
    return theme.palette.info.contrastText;
  }
  return "rgba(0, 0, 0, 0.87)";
};
