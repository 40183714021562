import { toast } from "react-toastify";

const copyContent = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied!", {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  } catch (err) {
    toast.error(err, {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  }
};
export default copyContent;
