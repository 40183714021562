import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDDialog from 'components/md-components/dialog/MDDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const getShape = (languageList) => {
	const shape = {
		title: Yup.string().min(4).required('Title is required.'),
	};
	languageList?.forEach((language) => {
		shape[language._id] = Yup.string()
			.min(4, 'Description must be at least 4 characters')
			.required('Description is required.');
	});

	return shape;
};

const getInitialValue = (dialogData) => {
	const state = {
		title: get(dialogData, 'title', ''),
	};

	get(dialogData, 'content', []).forEach((content) => {
		const languageId = get(content, 'language._id', null);
		if (languageId) {
			state[languageId] = content.value;
		}
	});

	return state;
};

const KnowledgeBaseCategoryConfigForm = ({ dialogOpen, handleDialogClose, dialogData, getList }) => {
	const { categoryId } = useParams();
	const dispatch = useDispatch();
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const ValidationSchema = Yup.object().shape(getShape(languageConfigList));

	const handleSubmit = async (values, { resetForm }) => {
		setLoading(true);

		const { title, ...content } = values;
		const data = {
			title: title,
			category: categoryId,
			content: Object.keys(content).map((e) => ({
				language: e,
				value: content[e],
			})),
		};
		if (dialogData?._id) {
			data.id = dialogData._id;
		}

		try {
			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/knowledge-base/segment/add`,
				data: data,
			});
			if (response.status === 200) {
				toast.success(response.data.message);
				getList();
				handleDialogClose();
				resetForm();
				setLoading(false);
				return;
			}
		} catch (error) {}

		setLoading(false);
	};

	return (
		<MDDialog
			title={`${dialogOpen?._id ? 'Update' : 'Create'} Segment`}
			open={dialogOpen}
			close={handleDialogClose}
			maxWidth='md'
			fullWidth={true}
		>
			{languageConfigList?.length > 0 && (
				<Formik
					initialValues={getInitialValue(dialogData)}
					validationSchema={ValidationSchema}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{(formProps) => (
						<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto'>
							<DialogContent dividers={true}>
								<Grid container rowSpacing={2} columnSpacing={4}>
									<Grid item xs={12}>
										<TextField
											fullWidth
											label='Title'
											name='title'
											value={formProps.values.title}
											error={Boolean(formProps.errors.title && formProps.touched.title)}
											helperText={
												formProps.errors.title && formProps.touched.title
													? formProps.errors.title
													: null
											}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
										/>
									</Grid>

									{languageConfigList?.map((language) => (
										<Grid item xs={12} key={language._id}>
											<TextField
												fullWidth
												multiline
												rows={3}
												label={`Description (${language.title})`}
												name={language._id}
												value={formProps.values[language._id]}
												error={Boolean(formProps.errors[language._id])}
												helperText={formProps.errors[language._id]}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
									))}
								</Grid>
							</DialogContent>
							<DialogActions>
								<MDLoadingButton type='submit' variant='contained' loading={loading}>
									Submit
								</MDLoadingButton>
							</DialogActions>
						</form>
					)}
				</Formik>
			)}
		</MDDialog>
	);
};

export default KnowledgeBaseCategoryConfigForm;
