import React from 'react';
import { map } from 'lodash';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

import MDCard from 'components/md-components/card/MDCard';
import numToArray from 'components/hooks/numToArray';

export const DetailCardHeaderSkeleton = (props) => {
	const { disableHeaderButton } = props;

	return (
		<Box className='px-3 py-2 border-bottom'>
			<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
				<Skeleton variant='text' animation='wave' width='20%' height={20} />
				{disableHeaderButton ? null : <Skeleton variant='circular' animation='wave' width={32} height={32} />}
			</Stack>
		</Box>
	);
};
export const DetailCardContentSkeleton = (props) => {
	const { rows } = props;

	const arrayValue = rows ? numToArray(rows) : [1, 2, 3, 4, 5];

	return (
		<Box display='grid' gap='16px' gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}>
			{map(arrayValue, (item) => (
				<Stack direction='row' alignItems='center' spacing={2} key={item}>
					<Skeleton variant='text' animation='wave' width='30%' height={20} />
					<Skeleton variant='text' animation='wave' width='30%' height={20} />
				</Stack>
			))}
		</Box>
	);
};

const DetailCardSkeleton = (props) => {
	const { skeletons } = props;

	const arrayValue = skeletons ? numToArray(skeletons) : [1];

	return (
		<Stack direction='column' spacing={2}>
			{map(arrayValue, (item) => (
				<MDCard key={item}>
					<DetailCardHeaderSkeleton {...props} />
					<CardContent>
						<DetailCardContentSkeleton {...props} />
					</CardContent>
				</MDCard>
			))}
		</Stack>
	);
};
export default DetailCardSkeleton;
