import { map } from "lodash";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import numToArray from "components/hooks/numToArray";
import MDChipCheckBox from "components/md-components/form/MDChipCheckbox";

const RecipeFormTimeSection = ({ formProps }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={6} xs={12}>
        <Box>
          <FormLabel>Recipe Preparation Time</FormLabel>
          <Stack direction="row" spacing={2} className="mt-2">
            <Autocomplete
              options={numToArray(12)}
              name="prepTime.hour"
              value={formProps.values.prepTime?.hour}
              getOptionLabel={(option) => option.toString() ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (option === value || option === 0) {
                  return true;
                }
              }}
              onChange={(_e, value) => {
                formProps.setFieldValue("prepTime.hour", value);
              }}
              onBlur={formProps.handleBlur}
              noOptionsText="No Data"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="prepTime.hour"
                  label="Hour"
                  error={
                    formProps.errors.prepTime?.hour &&
                    formProps.touched.prepTime?.hour
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.prepTime?.hour &&
                    formProps.touched.prepTime?.hour
                      ? formProps.errors.prepTime?.hour
                      : null
                  }
                />
              )}
            />
            <Autocomplete
              options={numToArray(60)}
              name="prepTime.min"
              value={formProps.values.prepTime?.min}
              getOptionLabel={(option) => option.toString() ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (option === value || option === 0) {
                  return true;
                }
              }}
              onChange={(_e, value) => {
                formProps.setFieldValue("prepTime.min", value);
              }}
              onBlur={formProps.handleBlur}
              noOptionsText="No Data"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="prepTime.min"
                  label="Minute"
                  error={
                    formProps.errors.prepTime?.min &&
                    formProps.touched.prepTime?.min
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.prepTime?.min &&
                    formProps.touched.prepTime?.min
                      ? formProps.errors.prepTime?.min
                      : null
                  }
                />
              )}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Box>
          <FormLabel>Recipe Cook Time</FormLabel>
          <Stack direction="row" spacing={2} className="mt-2">
            <Autocomplete
              options={numToArray(12)}
              name="cookTime.hour"
              value={formProps.values.cookTime?.hour}
              getOptionLabel={(option) => option.toString() ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (option === value || option === 0) {
                  return true;
                }
              }}
              onChange={(_e, value) => {
                formProps.setFieldValue("cookTime.hour", value);
              }}
              onBlur={formProps.handleBlur}
              noOptionsText="No Data"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="cookTime.hour"
                  label="Hour"
                  error={
                    formProps.errors.cookTime?.hour &&
                    formProps.touched.cookTime?.hour
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.cookTime?.hour &&
                    formProps.touched.cookTime?.hour
                      ? formProps.errors.cookTime?.hour
                      : null
                  }
                />
              )}
            />
            <Autocomplete
              options={numToArray(60)}
              name="cookTime.min"
              value={formProps.values.cookTime?.min}
              getOptionLabel={(option) => option.toString() ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (option === value || option === 0) {
                  return true;
                }
              }}
              onChange={(_e, value) => {
                formProps.setFieldValue("cookTime.min", value);
              }}
              onBlur={formProps.handleBlur}
              noOptionsText="No Data"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="cookTime.min"
                  label="Minute"
                  error={
                    formProps.errors.cookTime?.min &&
                    formProps.touched.cookTime?.min
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.cookTime?.min &&
                    formProps.touched.cookTime?.min
                      ? formProps.errors.cookTime?.min
                      : null
                  }
                />
              )}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Box>
          <FormLabel>Recipe Total Time</FormLabel>
          <Stack direction="row" spacing={2} className="mt-2">
            <Autocomplete
              options={numToArray(12)}
              name="totalTime.hour"
              value={formProps.values.totalTime?.hour}
              getOptionLabel={(option) => option.toString() ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (option === value || option === 0) {
                  return true;
                }
              }}
              onChange={(_e, value) => {
                formProps.setFieldValue("totalTime.hour", value);
              }}
              onBlur={formProps.handleBlur}
              noOptionsText="No Data"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="totalTime.hour"
                  label="Hour"
                  error={
                    formProps.errors.totalTime?.hour &&
                    formProps.touched.totalTime?.hour
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.totalTime?.hour &&
                    formProps.touched.totalTime?.hour
                      ? formProps.errors.totalTime?.hour
                      : null
                  }
                />
              )}
            />
            <Autocomplete
              options={numToArray(60)}
              name="totalTime.min"
              value={formProps.values.totalTime?.min}
              getOptionLabel={(option) => option.toString() ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (option === value || option === 0) {
                  return true;
                }
              }}
              onChange={(_e, value) => {
                formProps.setFieldValue("totalTime.min", value);
              }}
              onBlur={formProps.handleBlur}
              noOptionsText="No Data"
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="totalTime.min"
                  label="Minute"
                  error={
                    formProps.errors.totalTime?.min &&
                    formProps.touched.totalTime?.min
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.totalTime?.min &&
                    formProps.touched.totalTime?.min
                      ? formProps.errors.totalTime?.min
                      : null
                  }
                />
              )}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <FormControl
            fullWidth={true}
            error={Boolean(
              formProps.errors.preferredTime && formProps.touched.preferredTime
            )}
          >
            <FormLabel>Preferred Time</FormLabel>
            <FormGroup row={true} className="mt-2 ms-2 gap-3">
              {map(eatPreferredTimeList, (item, index) => (
                <FormControlLabel
                  key={index}
                  label={item.title}
                  name={`preferredTime.${item?.value}`}
                  checked={formProps.values.preferredTime[item?.value]}
                  onChange={formProps.handleChange}
                  control={<MDChipCheckBox />}
                  className="ms-0 me-0"
                />
              ))}
            </FormGroup>
            {Boolean(
              formProps.errors.preferredTime && formProps.touched.preferredTime
            ) ? (
              <FormHelperText>{formProps.errors.preferredTime}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RecipeFormTimeSection;

const eatPreferredTimeList = [
  { title: "Break fast", value: "breakFast" },
  { title: "Lunch", value: "lunch" },
  { title: "Dinner", value: "dinner" },
  { title: "Mid Morning Snack", value: "midMorningSnack" },
  { title: "Mid Afternoon Snack", value: "midAfternoonStatus" },
];
