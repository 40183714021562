import { uniqWith, isEqual } from "lodash";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import MDSwitch from "components/md-components/switch/MDSwitch";

const PressReleasedSEOSection = ({ formProps }) => {
  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        SEO Section
      </Typography>
      <Box className="mb-3">
        <Grid container spacing={2}>
          <Grid item xs={12} container columnGap={2}>
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.isPublished}
                      onChange={(e) =>
                        formProps.setFieldValue("isPublished", e.target.checked)
                      }
                    />
                  }
                  label="Publish"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.comments}
                      onChange={(e) =>
                        formProps.setFieldValue("comments", e.target.checked)
                      }
                    />
                  }
                  label="Comments"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.likeStatus}
                      onChange={(e) =>
                        formProps.setFieldValue("likeStatus", e.target.checked)
                      }
                    />
                  }
                  label="Likes"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.ratingStatus}
                      onChange={(e) =>
                        formProps.setFieldValue(
                          "ratingStatus",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Ratings"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Title"
              name="seoTitle"
              value={formProps.values.seoTitle}
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={
                formProps.errors.seoTitle && formProps.touched.seoTitle
                  ? true
                  : false
              }
              helperText={
                formProps.errors.seoTitle && formProps.touched.seoTitle
                  ? formProps.errors.seoTitle
                  : null
              }
              fullWidth={true}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Description"
              name="seoDescription"
              value={formProps.values.seoDescription}
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={
                formProps.errors.seoDescription &&
                formProps.touched.seoDescription
                  ? true
                  : false
              }
              helperText={
                formProps.errors.seoDescription &&
                formProps.touched.seoDescription
                  ? formProps.errors.seoDescription
                  : null
              }
              fullWidth={true}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="tags-outlined"
              options={[]}
              name="seoMetaTags"
              value={formProps.values.seoMetaTags}
              onChange={(_event, newValue) => {
                formProps.setFieldValue(
                  "seoMetaTags",
                  uniqWith(newValue, isEqual)
                );
              }}
              onBlur={formProps.handleBlur}
              multiple={true}
              freeSolo={true}
              fullWidth={true}
              disableClearable={true}
              includeInputInList={true}
              filterSelectedOptions={true}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meta Tags"
                  placeholder="After Enter Value Press Enter Key To Add Tags"
                  name="seoMetaTags"
                  error={
                    formProps.errors.seoMetaTags &&
                    formProps.touched.seoMetaTags
                      ? true
                      : false
                  }
                  helperText={
                    formProps.errors.seoMetaTags &&
                    formProps.touched.seoMetaTags
                      ? formProps.errors.seoMetaTags
                      : null
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "seo-tags",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PressReleasedSEOSection;
