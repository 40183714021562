import { useDispatch } from "react-redux";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PercentIcon from "@mui/icons-material/Percent";

import { Formik } from "formik";
import { get } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDDialog from "components/md-components/dialog/MDDialog";
import { updateConsultantRevenueShare } from "store/slice/user-management/ConsultantProfileSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";

const RevenueShareForm = (props) => {
  const { title, dialogOpen, handleDialogClose, userDetails, userType } = props;
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const apiData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: values,
    };
    dispatch(updateConsultantRevenueShare(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(get(result, "message", "Bio Update Successfully"));
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={title}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="sm"
      fullWidth={true}
    >
      <Formik
        initialValues={{ revenueShare: get(userDetails, "revenueShare", "") }}
        validationSchema={Yup.object().shape({
          revenueShare: Yup.number()
            .positive("Enter only positive value")
            .max(100, "Max value is 100%")
            .required("Revenue share field is required"),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="revenueShare"
                    type="number"
                    label="Revenue Share"
                    name="revenueShare"
                    value={props.values.revenueShare}
                    error={
                      props.errors.revenueShare && props.touched.revenueShare
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.revenueShare && props.touched.revenueShare
                        ? props.errors.revenueShare
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PercentIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                loading={props.isSubmitting}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default RevenueShareForm;
