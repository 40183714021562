import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAdminRoles,
  CreateAdminRole,
  StatusUpdateAdminRole,
  DeleteAdminRole,
  UpdateAdminRole,
} from "api/admin-user-management/AdminRoleManagementAPI";
import { get } from "lodash";

const initialState = {
  list: [],
  loading: false,
};

export const getAdminRoles = createAsyncThunk(
  "adminRole/getAdminRoles",
  async () => {
    return await GetAdminRoles()
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        console.log("ERROR >>>", err);
      });
  }
);
export const createAdminRole = createAsyncThunk(
  "adminRole/createAdminRole",
  async (data) => {
    return await CreateAdminRole(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        console.log("ERROR >>>", err);
      });
  }
);
export const updateAdminRole = createAsyncThunk(
  "adminUser/updateAdminRole",
  async (data) => {
    return await UpdateAdminRole(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return err;
      });
  }
);
export const statusUpdateAdminRole = createAsyncThunk(
  "adminUser/statusUpdateAdminRole",
  async (data) => {
    return await StatusUpdateAdminRole(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        console.log("ERROR >>>", err);
      });
  }
);
export const deleteAdminRole = createAsyncThunk(
  "adminUser/deleteAdminRole",
  async (data) => {
    return await DeleteAdminRole(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        console.log("ERROR >>>", err);
      });
  }
);

const AdminRole = createSlice({
  name: "adminRole",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAdminRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdminRoles.fulfilled, (state, action) => {
      state.list = get(action.payload, "status", false)
      ? action.payload.data
      : [];
      state.loading = false;
    });
    builder.addCase(getAdminRoles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdminRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdminRole.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdminRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdminRole.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateAdminRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusUpdateAdminRole.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateAdminRole.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAdminRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAdminRole.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAdminRole.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default AdminRole.reducer;
