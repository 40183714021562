import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	CreateUpdateContent,
	DeleteContent,
	GetContentList,
	ImageUploadContent,
	PublishContent,
	UpdateApproveArticle,
	UpdateApproveContent,
} from 'api/content/ContentAPI';

import { get } from 'lodash';

const initialState = {
	contentList: [],
	nonApproveArticleList: [],
	loading: false,
};

export const getContentList = createAsyncThunk('content/getContentList', async (data) => {
	try {
		const response = await GetContentList(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const updateApproveContent = createAsyncThunk('content/updateApproveContent', async (data) => {
	try {
		const response = await UpdateApproveContent(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});
export const imageUploadContent = createAsyncThunk('content/imageUploadContent', async (data) => {
	try {
		const response = await ImageUploadContent(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});
export const createUpdateContent = createAsyncThunk('article/createUpdateContent', async (data) => {
	try {
		const response = await CreateUpdateContent(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});
export const publishContent = createAsyncThunk('article/publishContent', async (data) => {
	try {
		const response = await PublishContent(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});
export const deleteContent = createAsyncThunk('article/deleteContent', async (data) => {
	try {
		const response = await DeleteContent(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});
export const updateApproveArticle = createAsyncThunk('article/updateApproveArticle', async (data) => {
	try {
		const response = await UpdateApproveArticle(data);
		return response.data;
	} catch (error) {
		return { status: false, message: error.response.data.message };
	}
});

export const ContentSlice = createSlice({
	name: 'article',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getContentList.pending, (state) => {
				state.loading = true;
			})
			.addCase(getContentList.fulfilled, (state, { payload }) => {
				state.contentList = get(payload, 'data.list', []);
				state.loading = false;
			})
			.addCase(getContentList.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateApproveArticle.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateApproveArticle.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateApproveArticle.rejected, (state) => {
				state.loading = false;
			})
			.addCase(updateApproveContent.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateApproveContent.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(updateApproveContent.rejected, (state) => {
				state.loading = false;
			})
			.addCase(imageUploadContent.pending, (state) => {
				state.loading = true;
			})
			.addCase(imageUploadContent.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(imageUploadContent.rejected, (state) => {
				state.loading = false;
			})
			.addCase(createUpdateContent.pending, (state) => {
				state.loading = true;
			})
			.addCase(createUpdateContent.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(createUpdateContent.rejected, (state) => {
				state.loading = false;
			})
			.addCase(publishContent.pending, (state) => {
				state.loading = true;
			})
			.addCase(publishContent.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(publishContent.rejected, (state) => {
				state.loading = false;
			})
			.addCase(deleteContent.pending, (state) => {
				state.loading = true;
			})
			.addCase(deleteContent.fulfilled, (state) => {
				state.loading = false;
			})
			.addCase(deleteContent.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default ContentSlice.reducer;
