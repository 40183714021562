import React, { useEffect } from "react";

import { Formik } from "formik";
import { get, isEmpty, map, omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import TimeOutComponent from "components/common/TimeOutComponent";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";

import RecipeFormIngredientSelection from "components/pages/health-food/recipe/form-components/RecipeFormIngredientSelection";
import RecipeFormDietAndCategory from "components/pages/health-food/recipe/form-components/RecipeFormDietAndCategory";
import RecipeFormTopSection from "components/pages/health-food/recipe/form-components/RecipeFormTopSection";
import RecipeFormSEOSection from "components/pages/health-food/recipe/form-components/RecipeFormSEOSection";
import RecipeFormMethodSection from "components/pages/health-food/recipe/form-components/RecipeFormMethodSection";
import RecipeFormTimeSection from "components/pages/health-food/recipe/form-components/RecipeFormTimeSection";
import RecipeFormLangAndServing from "components/pages/health-food/recipe/form-components/RecipeFormLangAndServing";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import {
  getCuisineData,
  getDietaryPreferenceData,
} from "store/slice/common/GeneralConfigDataSlice";
import {
  createUpdateRecipe,
  imageUploadRecipe,
} from "store/slice/health-food/RecipeSlice";
import { getLanguageConfigList } from "store/slice/language-config/LanguageConfigSlice";

const RecipeCreateUpdateForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const recipeDetail = get(location, "state.recipeDetail", {});
  const formType = get(location, "state.formType", {});

  const languagesList = useSelector(
    (state) => state.languageConfig.languageList
  );
  const dietaryPreferenceData = useSelector(
    (state) => state.generalConfigData.dietaryPreferenceData
  );
  const cuisineData = useSelector(
    (state) => state.generalConfigData.cuisineData
  );
  const isLoading = useSelector((state) => state.healthRecipe.loading);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    subTitle: Yup.string().required("Subtitle is required"),
    topSection: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Image title is required"),
        imageUrl: Yup.string()
          .when("imageType", {
            is: (value) => value === "IMAGE_UPLOAD",
            then: Yup.string().required("Please upload an image."),
            otherwise: Yup.string(),
          })
          .when("imageType", {
            is: (value) => value === "IMAGE_LINK",
            then: Yup.string()
              .url("Please enter valid url")
              .required("Please enter a image link"),
            otherwise: Yup.string(),
          }),
      })
    ),

    language: Yup.object().shape().required("Language is required").nullable(),
    cuisine: Yup.object().shape().required("Cuisine is required").nullable(),
    dietPreference: Yup.string().required("Diet preference is required"),
    recipeServingCount: Yup.number().required("Serving count is required"),

    prepTime: Yup.object().shape({
      hour: Yup.number().required("Hour is required"),
      min: Yup.number().required("Minute is required"),
    }),
    cookTime: Yup.object().shape({
      hour: Yup.number().required("Hour is required"),
      min: Yup.number().required("Minute is required"),
    }),
    totalTime: Yup.object().shape({
      hour: Yup.number().required("Hour is required"),
      min: Yup.number().required("Minute is required"),
    }),

    preferredTime: Yup.mixed().test(
      "preferredTime",
      "Preferred time is required",
      (value) => {
        if (
          value?.breakFast ||
          value?.dinner ||
          value?.lunch ||
          value?.midAfternoonStatus ||
          value?.midMorningSnack
        ) {
          return true;
        } else {
          return false;
        }
      }
    ),

    ingredients: Yup.array().min(
      1,
      "Ingredients are required, Please add ingredients"
    ),

    recipeContent: Yup.string().required("Recipe content is required"),
    recipeHotTip: Yup.string().required("Recipe hot tip is required"),

    seoTitle: Yup.string().required("SEO title is required"),
    seoDescription: Yup.string().required("SEO description is required"),
    seoMetaTags: Yup.array().min(1, "SEO meta tags is required"),
  });

  const handleImageUpload = (file, section, fieldName, setFieldValue) => {
    const payloadData = {
      file: file,
      sectionName: section,
    };
    dispatch(imageUploadRecipe(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          setFieldValue(fieldName, get(result, "data.URL", ""));
        } else {
          toast.error(
            get(result, "message", "Something went wrong in upload image")
          );
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const handleSubmit = (values) => {
    let payloadData = {
      ...omit(values, ["ingredientSearch", "description", "metaTags"]),
      language: get(values, "language._id", ""),
      cuisine: get(values, "cuisine._id", ""),
      topSection: map(get(values, "topSection", []), (data) =>
        omit(data, ["_id", "link"])
      ),
      ingredients: map(get(values, "ingredients", []), (data) => {
        if (get(data, "measureType", "") === "volume") {
          return {
            ...omit(data, "tablespoon", "portion", "_id"),
          };
        }
      }),
    };

    if (formType === "update") {
      payloadData = {
        ...payloadData,
        _id: get(recipeDetail, "_id", ""),
      };
    }

    dispatch(createUpdateRecipe(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          toast.success(
            get(result, "message", "Recipe created/updated successfully.")
          );
          navigate("/health-food/recipe-list", {
            state: {
              tabIndex: get(location, "state.tabIndex", 0),
              apiType: get(location, "state.apiType", "ALL"),
            },
          });
        } else {
          toast.error(get(result, "message", "Something went wrong."));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    dispatch(getDietaryPreferenceData());
    dispatch(getCuisineData());
    dispatch(getLanguageConfigList());
  }, [dispatch]);

  return (
    <React.Fragment>
      <MDCard className="mb-3">
        <MDCardHeader title="Health Food Recipe Create / Update" />
      </MDCard>
      <TimeOutComponent delay={2} isEmpty={isEmpty(dietaryPreferenceData)}>
        <Formik
          initialValues={{
            title: get(recipeDetail, "title", ""),
            subTitle: get(recipeDetail, "subTitle", ""),
            language: get(recipeDetail, "language", undefined),
            cuisine: get(recipeDetail, "cuisine", undefined),
            dietPreference: get(recipeDetail, "dietPreference._id", ""),
            recipeServingCount: get(recipeDetail, "recipeServingCount", ""),

            glutenFree: get(recipeDetail, "glutenFree", false),
            nutsFree: get(recipeDetail, "nutsFree", false),
            dairyFree: get(recipeDetail, "dairyFree", false),
            sugarFree: get(recipeDetail, "sugarFree", false),
            salads: get(recipeDetail, "salads", false),
            highProtein: get(recipeDetail, "highProtein", false),
            pregnancyFriendly: get(recipeDetail, "pregnancyFriendly", false),
            lowSugar: get(recipeDetail, "lowSugar", false),
            lowFODMAPDiet: get(recipeDetail, "lowFODMAPDiet", false),
            lowGIDiet: get(recipeDetail, "lowGIDiet", false),
            intermittentDiet: get(recipeDetail, "intermittentDiet", false),

            prepTime: get(recipeDetail, "prepTime", {
              hour: "",
              min: "",
            }),
            cookTime: get(recipeDetail, "cookTime", {
              hour: "",
              min: "",
            }),
            totalTime: get(recipeDetail, "totalTime", {
              hour: "",
              min: "",
            }),

            ingredientSearch: {
              ingredient: {},
              ingredientRequired: true,
              measureType: "volume",
              volume: {
                value: "",
                unit: "gm",
              },
              portion: {
                value: "small",
              },
              tablespoon: {
                full: "",
                parcel: "",
              },
            },

            ingredients: get(recipeDetail, "ingredients", []),

            totalFat: get(recipeDetail, "totalFat", 0),
            totalProtein: get(recipeDetail, "totalProtein", 0),
            totalCarbs: get(recipeDetail, "totalCarbs", 0),
            totalCalories: get(recipeDetail, "totalCalories", 0),

            topSection: get(recipeDetail, "topSection", [
              {
                imageType: "IMAGE_UPLOAD",
                imageUrl: "",
                title: "",
              },
            ]),

            preferredTime: get(recipeDetail, "preferredTime", {
              breakFast: false,
              midMorningSnack: false,
              midAfternoonStatus: false,
              dinner: false,
              lunch: false,
            }),

            recipeVideoLink: get(recipeDetail, "recipeVideoLink", ""),
            recipeVideoTitle: get(recipeDetail, "recipeVideoTitle", ""),
            recipeContent: get(recipeDetail, "recipeContent", ""),
            recipeHotTip: get(recipeDetail, "recipeHotTip", ""),

            isPublished: get(recipeDetail, "isPublished", false),
            comment: get(recipeDetail, "comment", false),
            likeStatus: get(recipeDetail, "likeStatus", false),
            ratingStatus: get(recipeDetail, "ratingStatus", false),
            seoTitle: get(recipeDetail, "seoTitle", ""),
            seoDescription: get(recipeDetail, "seoDescription", ""),
            seoMetaTags: get(recipeDetail, "seoMetaTags", []),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              {/* {console.log("formProps ::>>", formProps)} */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormTopSection
                        formProps={formProps}
                        handleImageUpload={handleImageUpload}
                      />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormLangAndServing
                        formProps={formProps}
                        languagesList={languagesList}
                        cuisineData={cuisineData}
                      />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormTimeSection formProps={formProps} />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormDietAndCategory
                        formProps={formProps}
                        dietaryPreferenceData={dietaryPreferenceData}
                      />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormIngredientSelection formProps={formProps} />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormMethodSection formProps={formProps} />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent>
                      <RecipeFormSEOSection formProps={formProps} />
                    </CardContent>
                  </MDCard>
                </Grid>
                <Grid item xs={12}>
                  <MDCard>
                    <CardContent sx={{ mt: 1, mx: { sm: 1 } }}>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <MDLoadingButton
                            type="reset"
                            variant="outlined"
                            padding="6px 24px"
                            fullWidth={true}
                            onClick={(_event) => {
                              formProps.resetForm();
                            }}
                          >
                            Reset
                          </MDLoadingButton>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <MDLoadingButton
                            type="submit"
                            variant="contained"
                            padding="6px 24px"
                            fullWidth={true}
                            loading={formProps.isSubmitting && isLoading}
                          >
                            Submit
                          </MDLoadingButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </MDCard>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </TimeOutComponent>
    </React.Fragment>
  );
};

export default RecipeCreateUpdateForm;
