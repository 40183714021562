import AxiosDefault from "services/AxiosDefaultSetting";

export const GetBookingList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/consultation/booking/list`,
    data: data,
  });
  return response;
};

export const GetBookingDetail = async (id) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/consultation/booking-details/${id}`,
  });
  return response;
};

export const GetQAndAData = async (id) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/consultation/pre-questions-answers/${id}`,
  });
  return response;
};
