import { find, isEmpty, map } from 'lodash';
import { useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/x-tree-view/TreeView';
import Box from '@mui/material/Box';

import { cookieGet, cookieSet } from 'components/hooks/cookie';
import MDTreeItem from 'components/md-components/tree-item/MDTreeItem';
import MenuList from './MenuList';

const SidebarMenus = () => {
	const selectedMenuId = cookieGet('selectedMenuId');
	const [expanded, setExpanded] = useState(['1']);
	const [selected, setSelected] = useState(!isEmpty(selectedMenuId) ? [selectedMenuId] : ['1']);
	// const match = (path) =>
	//   path ? !!matchPath({ path, end: false }, pathname) : false;

	const handleToggle = (_event, nodeIds) => {
		setExpanded(nodeIds);
	};
	const handleSelect = (_event, nodeIds) => {
		const getItem = find(MenuList, { nodeId: nodeIds });
		setSelected(getItem?.path);
		setSelected(nodeIds);
		cookieSet('selectedMenuId', nodeIds);
		cookieSet('selectedPath', getItem?.path);
	};

	return (
		<TreeView
			aria-label='Sidebar-Menu'
			expanded={expanded}
			selected={selected}
			defaultSelected={selected}
			onNodeToggle={handleToggle}
			onNodeSelect={handleSelect}
			defaultCollapseIcon={<ExpandLessIcon />}
			defaultExpandIcon={<ExpandMoreIcon />}
			defaultEndIcon={<Box />}
		>
			{map(MenuList, (menu, menuIndex) => {
				return (
					<Box key={menuIndex} className='my-1'>
						{menu.type === 'main-menu' ? (
							<MDTreeItem
								nodeId={menu.nodeId}
								labelText={menu.label}
								labelIcon={menu.icon}
								to={menu.path}
								type={menu.type}
							/>
						) : null}
						{menu.type === 'sub-menu' ? (
							<MDTreeItem
								nodeId={menu.nodeId}
								labelText={menu.label}
								labelIcon={menu.icon}
								to={menu.path}
								type={menu.type}
							>
								{map(menu.subMenu, (subMenu, subMenuIndex) => {
									return (
										<Box key={subMenuIndex} className='my-1'>
											{subMenu.type === 'main-menu' ? (
												<MDTreeItem
													nodeId={subMenu.nodeId}
													labelText={subMenu.label}
													labelIcon={subMenu.icon}
													to={subMenu.path}
													type={subMenu.type}
												/>
											) : null}
											{subMenu.type === 'sub-menu' ? (
												<MDTreeItem
													nodeId={subMenu.nodeId}
													labelText={subMenu.label}
													labelIcon={subMenu.icon}
													to={subMenu.path}
													type={subMenu.type}
												>
													{map(subMenu.subMenu, (subMenu2, subMenuIndex2) => {
														return (
															<Box key={subMenuIndex2} className='my-1'>
																{subMenu2.type === 'main-menu' ? (
																	<MDTreeItem
																		nodeId={subMenu2.nodeId}
																		labelText={subMenu2.label}
																		labelIcon={subMenu2.icon}
																		to={subMenu2.path}
																		type={subMenu2.type}
																	/>
																) : null}
																{subMenu2.type === 'sub-menu' ? (
																	<MDTreeItem
																		nodeId={subMenu2.nodeId}
																		labelText={subMenu2.label}
																		labelIcon={subMenu2.icon}
																		to={subMenu2.path}
																		type={subMenu2.type}
																	>
																		{map(
																			subMenu2.subMenu,
																			(subMenu3, subMenuIndex3) => {
																				return (
																					<Box
																						key={subMenuIndex3}
																						className='my-1'
																					>
																						<MDTreeItem
																							nodeId={
																								subMenu3.nodeId
																							}
																							labelText={
																								subMenu3.label
																							}
																							labelIcon={
																								subMenu3.icon
																							}
																							to={subMenu3.path}
																							type={
																								subMenu3.type
																							}
																						/>
																					</Box>
																				);
																			}
																		)}
																	</MDTreeItem>
																) : null}
															</Box>
														);
													})}
												</MDTreeItem>
											) : null}
										</Box>
									);
								})}
							</MDTreeItem>
						) : null}
					</Box>
				);
			})}
		</TreeView>
	);
};
export default SidebarMenus;
