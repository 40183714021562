import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(backend)
	.use(initReactI18next)
	.init({
		lng: 'en',
		fallbackLng: 'en',
		preload: ['en'],
		backend: {
			loadPath: function (langs, namespaces) {
				return '/lang/en.json';
			},
		},
	});
