import { FieldArray, getIn } from 'formik';
import { find, map } from 'lodash';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import RichTextEditor from 'components/utility/rich-text-editor/RichTextEditor';

const QandALanguageTranslationForm = (props) => {
	const {
		fieldName,
		formProps,
		languageConfigList,
		inputField = 'text-input',
		borderOnBottom,
		optionIndex = 0,
		subQuestionFieldIndex = 0,
		subQuestionFieldOptionIndex = 0,
		customSectionIndex = 0,
		section = '',
	} = props;

	const getLanguageTitle = (id) => {
		const data = find(languageConfigList, (language) => language?._id === id);
		return data?.title ?? '';
	};

	let _temp;
	section === 'SUB_QUESTION_OPTIONS'
		? (_temp =
				formProps?.values?.options[optionIndex].subQuestions[subQuestionFieldIndex].options[
					subQuestionFieldOptionIndex
				].languages)
		: section === 'SUB_QUESTION'
		? (_temp = formProps?.values?.options[optionIndex].subQuestions[subQuestionFieldIndex].languages)
		: section === 'SEGMENT_NAME'
		? (_temp = formProps?.values?.sections?.[customSectionIndex]?.languages)
		: (_temp = formProps?.values?.options[optionIndex].languages);

	return (
		<FieldArray name={fieldName ? fieldName : 'languages'}>
			{() => (
				<Box className={borderOnBottom ? 'border-bottom ' : ''}>
					{map(_temp, (field, index, { length }) => {
						const name = fieldName ? fieldName : 'languages';

						const microValue = `[${name}][${index}].value`;
						const errorMicroValue = getIn(formProps?.errors, microValue);
						const touchedMicroValue = getIn(formProps?.touched, microValue);

						const isTranslate = `[${name}][${index}].isTranslate`;

						return (
							<Grid
								container
								spacing={2}
								key={index}
								className={index !== length - 1 ? 'mb-3' : ''}
							>
								<Grid item md={2} xs={12}>
									<FormLabel
										htmlFor={`${field?.languageId}-value-field`}
										className='fw-medium'
										sx={{ top: { md: '8px' }, color: 'text.primary' }}
									>
										{getLanguageTitle(field?.languageId)}
									</FormLabel>
								</Grid>
								{inputField !== 'text-editor' ? (
									<Grid item md={8} xs={12}>
										<TextField
											id={`${field?.languageId}-value-field`}
											fullWidth={true}
											multiline={true}
											size='small'
											label='Value'
											name={microValue}
											value={field?.value}
											onChange={formProps?.handleChange}
											onBlur={formProps?.handleBlur}
											error={errorMicroValue && touchedMicroValue ? true : false}
											helperText={
												errorMicroValue && touchedMicroValue ? errorMicroValue : null
											}
										/>
									</Grid>
								) : (
									<Grid item md={8} xs={12}>
										<RichTextEditor
											id={`${field.languageId}-value-field`}
											label='Value'
											name={microValue}
											value={field?.value}
											setFieldValue={formProps.setFieldValue}
											onBlur={formProps?.handleBlur}
											error={errorMicroValue && touchedMicroValue ? true : false}
											helperText={
												errorMicroValue && touchedMicroValue ? errorMicroValue : null
											}
										/>
									</Grid>
								)}
								<Grid item md={2} xs={12}>
									<FormControl component='fieldset' variant='standard'>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														name={isTranslate}
														checked={field?.isTranslate}
														value={field?.isTranslate}
														onChange={(e) => {
															formProps.setFieldValue(
																isTranslate,
																e.target.checked
															);
														}}
														inputProps={{
															'aria-label': `is-language-status-${index}`,
														}}
													/>
												}
												label='Is Translate'
												sx={{ mr: 0 }}
											/>
										</FormGroup>
									</FormControl>
								</Grid>
								{index !== length - 1 ? (
									<Grid item xs={12} pt={{ sm: 2, xs: 0 }}>
										<Divider light={true} flexItem={true} />
									</Grid>
								) : null}
							</Grid>
						);
					})}
				</Box>
			)}
		</FieldArray>
	);
};

export default QandALanguageTranslationForm;

// export const languageInitialValue = (languageList, preFilledData, type) => {
//   return map(languageList, (language) => {
//     const initialValue = filter(
//       preFilledData,
//       (data) => data?.languageId === language?._id
//     )[0];

//     return isEmpty(preFilledData)
//       ? {
//           languageId: initialValue?.languageId || language?._id,
//           value: initialValue?.value || "",
//           type: type,
//           isTranslate: initialValue?.isTranslated || false,
//         }
//       : {
//           languageId: initialValue?.languageId || language?._id,
//           value: initialValue?.value || "",
//           isTranslate: initialValue?.isTranslated || false,
//         };
//   });
// };
