import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateUpdateRecipe,
  DeleteRecipe,
  GetRecipesNonApproveList,
  GetRecipeList,
  UpdateApproveRecipe,
  PublishRecipe,
  ImageUploadRecipe,
  GetFDCIngredientsList,
  GetFDCIngredientDetail,
} from "api/health-food/RecipeAPIs";
import { get } from "lodash";

const initialState = {
  recipeList: [],
  recipeNonApproveList: [],
  fdcIngredients: [],
  fdcIngredientDetail: {},
  getFDCIngredientDetailLoading: false,
  loading: false,
};

export const getRecipesList = createAsyncThunk(
  "healthRecipe/getRecipesList",
  async (data) => {
    try {
      const response = await GetRecipeList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getRecipesNonApproveList = createAsyncThunk(
  "healthRecipe/getRecipesNonApproveList",
  async (data) => {
    try {
      const response = await GetRecipesNonApproveList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const updateApproveRecipe = createAsyncThunk(
  "healthRecipe/updateApproveRecipe",
  async (data) => {
    try {
      const response = await UpdateApproveRecipe(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const imageUploadRecipe = createAsyncThunk(
  "healthRecipe/imageUploadRecipe",
  async (data) => {
    try {
      const response = await ImageUploadRecipe(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getFDCIngredientsList = createAsyncThunk(
  "healthRecipe/getFDCIngredientsList",
  async (data) => {
    try {
      const response = await GetFDCIngredientsList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getFDCIngredientDetail = createAsyncThunk(
  "healthRecipe/getFDCIngredientDetail",
  async (data) => {
    try {
      const response = await GetFDCIngredientDetail(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const createUpdateRecipe = createAsyncThunk(
  "healthRecipe/createUpdateRecipe",
  async (data) => {
    try {
      const response = await CreateUpdateRecipe(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const publishRecipe = createAsyncThunk(
  "healthRecipe/publishRecipe",
  async (data) => {
    try {
      const response = await PublishRecipe(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const deleteRecipe = createAsyncThunk(
  "healthRecipe/deleteRecipe",
  async (data) => {
    try {
      const response = await DeleteRecipe(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const RecipeSlice = createSlice({
  name: "healthRecipe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecipesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecipesList.fulfilled, (state, { payload }) => {
        state.recipeList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getRecipesList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRecipesNonApproveList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecipesNonApproveList.fulfilled, (state, { payload }) => {
        state.recipeNonApproveList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getRecipesNonApproveList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateApproveRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateApproveRecipe.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateApproveRecipe.rejected, (state) => {
        state.loading = false;
      })
      .addCase(imageUploadRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(imageUploadRecipe.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(imageUploadRecipe.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFDCIngredientsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFDCIngredientsList.fulfilled, (state, { payload }) => {
        state.fdcIngredients = get(payload, "data", []);
        state.loading = false;
      })
      .addCase(getFDCIngredientsList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFDCIngredientDetail.pending, (state) => {
        state.loading = true;
        state.getFDCIngredientDetailLoading = true;
      })
      .addCase(getFDCIngredientDetail.fulfilled, (state, { payload }) => {
        state.fdcIngredientDetail = get(payload, "data", []);
        state.getFDCIngredientDetailLoading = false;
        state.loading = false;
      })
      .addCase(getFDCIngredientDetail.rejected, (state) => {
        state.loading = false;
        state.getFDCIngredientDetailLoading = false;
      })
      .addCase(createUpdateRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUpdateRecipe.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateRecipe.rejected, (state) => {
        state.loading = false;
      })
      .addCase(publishRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(publishRecipe.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(publishRecipe.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteRecipe.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRecipe.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteRecipe.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default RecipeSlice.reducer;
