import { useLocation } from 'react-router-dom';

import { FieldArray } from 'formik';
import { get, map } from 'lodash';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import MDButton from 'components/md-components/button/MDButton';
import MDCard from 'components/md-components/card/MDCard';

import { languageInitialValue } from 'components/common/LanguageTranslationForm';
import QuestionForm from './QuestionForm';

const SubQuestions = ({ optionIndex, option, formProps }) => {
	const location = useLocation();
	const rowData = location.state.rowData;
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);

	const getQuestionPayload = () => {
		return {
			questionTitle: '',
			inputType: '',
			languages: languageInitialValue(languageConfigList, get(rowData, 'languages', false), 'common'),
			options: [
				{
					label: '',
					value: '',
					languages: languageInitialValue(
						languageConfigList,
						get(rowData, 'languages', false),
						'common'
					),
				},
			],
			minValue: undefined,
			maxValue: undefined,
			stepValue: undefined,
		};
	};

	return (
		<Fade in={true} key={optionIndex}>
			<Grid item xs={12}>
				<FieldArray name={`options.${optionIndex}.subQuestions`}>
					{({ remove, push }) => (
						<MDCard variant='outlined'>
							<Box className='px-3 py-2 border-bottom'>
								<Stack
									direction='row'
									alignItems='center'
									justifyContent='space-between'
									spacing={2}
								>
									<Typography
										variant='body1'
										fontSize={{ md: 18, xs: 16 }}
										fontWeight={500}
									>
										Sub Questions Of Option {option.label}
									</Typography>
									<MDButton
										label='Add Question'
										onClick={() => push(getQuestionPayload())}
										variant='contained'
										padding='4px 12px'
										fontSize='15px'
									/>
								</Stack>
							</Box>
							<CardContent>
								<TransitionGroup>
									{map(
										option.subQuestions,
										(subQuestionField, subQuestionFieldIndex, { length }) => (
											<Collapse key={subQuestionFieldIndex}>
												<QuestionForm
													optionIndex={optionIndex}
													remove={remove}
													formProps={formProps}
													subQuestionField={subQuestionField}
													subQuestionFieldIndex={subQuestionFieldIndex}
													length={length}
													rowData={rowData}
												/>
											</Collapse>
										)
									)}
								</TransitionGroup>
							</CardContent>
						</MDCard>
					)}
				</FieldArray>
			</Grid>
		</Fade>
	);
};

export default SubQuestions;
