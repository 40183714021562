import { FieldArray, getIn } from 'formik';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import MDButton from 'components/md-components/button/MDButton';
import MDCard from 'components/md-components/card/MDCard';
import { getAddOptionObject } from './helpers';
import QandALanguageTranslationForm from 'components/pages/cms/user-config/consultant-config/QandALanguageTranslationForm';

const FormField = ({ formProps, optionIndex, optionItem, remove, languageConfigList, length }) => {
	const optionLabel = `options[${optionIndex}].label`;
	const errorOptionLabel = getIn(formProps.errors, optionLabel);
	const touchedOptionLabel = getIn(formProps.touched, optionLabel);
	const optionValue = `options[${optionIndex}].value`;
	const errorOptionValue = getIn(formProps.errors, optionValue);
	const touchedOptionValue = getIn(formProps.touched, optionValue);
	const subQuestionStatus = `options[${optionIndex}].subQuestionStatus`;

	const languages = `options[${optionIndex}].languages`;
	const isNotLastIndex = optionIndex !== length - 1 ? true : false;

	return (
		<Grid container spacing={1} sx={{ mb: isNotLastIndex ? 1.5 : 0 }}>
			<Grid item sm={4} xs={12}>
				<TextField
					fullWidth={true}
					multiline={true}
					size='small'
					label='Option Label'
					name={optionLabel}
					value={optionItem?.label}
					onChange={formProps.handleChange}
					onBlur={formProps.handleBlur}
					helperText={errorOptionLabel && touchedOptionLabel ? errorOptionLabel : null}
					error={Boolean(errorOptionLabel && touchedOptionLabel)}
				/>
			</Grid>
			<Grid item sm={4} xs={12}>
				<TextField
					fullWidth={true}
					multiline={true}
					size='small'
					label='Option Value'
					name={optionValue}
					value={optionItem?.value}
					onChange={formProps.handleChange}
					onBlur={formProps.handleBlur}
					helperText={errorOptionValue && touchedOptionValue ? errorOptionValue : null}
					error={Boolean(errorOptionValue && touchedOptionValue)}
				/>
			</Grid>
			<Grid item sm={3} xs={6}>
				<FormGroup className='w-max-content'>
					<FormControlLabel
						label='Sub Question'
						name={subQuestionStatus}
						value={optionItem?.subQuestionStatus}
						checked={optionItem?.subQuestionStatus}
						onChange={(e) => {
							formProps.setFieldValue(subQuestionStatus, e.target.checked);
						}}
						control={
							<Checkbox
								inputProps={{
									'aria-label': 'sub-question-check',
								}}
							/>
						}
					/>
				</FormGroup>
			</Grid>

			{optionIndex !== 0 && (
				<Grid item sm={1} xs={2} sx={{ textAlign: 'end' }}>
					<IconButton type='button' color='error' onClick={() => remove(optionIndex)}>
						<RemoveCircleOutlineOutlinedIcon />
					</IconButton>
				</Grid>
			)}

			{languages && (
				<Grid item sm={8} xs={12}>
					<QandALanguageTranslationForm
						fieldName={languages}
						section='QUESTION_OPTIONs'
						formProps={formProps}
						optionIndex={optionIndex}
						languageConfigList={languageConfigList}
						inputField='text-input'
					/>
				</Grid>
			)}
			{isNotLastIndex ? (
				<Grid item xs={12}>
					<Divider light={true} flexItem />
				</Grid>
			) : null}
		</Grid>
	);
};

const RadioCheckboxForm = ({ formProps, rowData }) => {
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);

	return (
		<Fade in={true}>
			<Grid item xs={12}>
				<FieldArray name='options'>
					{({ remove, push }) => (
						<MDCard variant='outlined'>
							<Stack
								direction='row'
								justifyContent='space-between'
								className='py-2 px-3 border-bottom'
							>
								<Typography
									variant='body1'
									fontSize={{ md: 18, xs: 16 }}
									fontWeight={500}
									textTransform='capitalize'
								>
									Options {formProps.values.inputType}
								</Typography>
								<MDButton
									label='Add Option'
									onClick={() => push(getAddOptionObject(languageConfigList, rowData))}
									variant='contained'
									padding='3px 12px'
									fontSize='15px'
								/>
							</Stack>
							<CardContent>
								<TransitionGroup>
									{map(formProps.values.options, (optionItem, optionIndex, { length }) => {
										return (
											<Collapse key={optionIndex}>
												<FormField
													formProps={formProps}
													optionIndex={optionIndex}
													optionItem={optionItem}
													remove={remove}
													languageConfigList={languageConfigList}
													length={length}
												/>
											</Collapse>
										);
									})}
								</TransitionGroup>
							</CardContent>
						</MDCard>
					)}
				</FieldArray>
			</Grid>
		</Fade>
	);
};

export default RadioCheckboxForm;
