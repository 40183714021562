import { Formik } from 'formik';
import { get, cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import LanguageTranslationForm, { languageInitialValue } from './LanguageTranslationForm';
import { useEffect, useState } from 'react';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { toast } from 'react-toastify';

const getInitialData = (dialogData, languageConfigList) => {
	const contentList = get(dialogData, 'content', []);

	return {
		languages: languageInitialValue(languageConfigList, contentList),
	};
};

const ValidationSchema = Yup.object().shape({
	languages: Yup.array().of(
		Yup.object().shape({
			content: Yup.string().required('Content is required'),
		})
	),
});

export default function HomeTab() {
	const [loading, setLoading] = useState(false);
	const [dialogData, setDialogData] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);

	const getData = async () => {
		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: '/admin/app-config/get?sectionName=home',
			});
			if (response.status === 200) {
				setDialogData(response.data[0]);
			} else {
				toast.error('Data not get!');
			}

			setDataLoaded(true);
		} catch (error) {}
	};

	useEffect(() => {
		getData();
	}, []);

	const handleSubmit = async (formValues) => {
		const values = cloneDeep(formValues);

		setLoading(true);

		try {
			const payload = {
				content: {
					sectionName: 'home',
					content: values.languages.map((e) => ({
						content: e.content,
						language: e.langID,
					})),
				},
			};
			if (dialogData?._id) {
				payload.id = dialogData._id;
			}

			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/app-config/update`,
				data: payload,
			});
			if (response.status === 200) {
				getData();
				toast.success('Updated!');
			} else {
				toast.error('Not Updated!');
			}
		} catch (error) {}

		setLoading(false);
	};

	return languageConfigList?.length && dataLoaded ? (
		<Formik
			initialValues={getInitialData(dialogData, languageConfigList)}
			validationSchema={ValidationSchema}
			onSubmit={handleSubmit}
		>
			{(formProps) => (
				<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto'>
					<DialogContent dividers={true}>
						<LanguageTranslationForm formProps={formProps} />
					</DialogContent>
					<DialogActions>
						<MDLoadingButton type='submit' variant='contained' padding='6px 24px' loading={loading}>
							Submit
						</MDLoadingButton>
					</DialogActions>
				</form>
			)}
		</Formik>
	) : null;
}
