import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import LoginBgDark from 'assets/images/login-bg-dark.webp';
import LoginBgLight from 'assets/images/login-bg-light.webp';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import ActivityLoader from 'components/md-components/loader/ActivityLoader';
import LoginForm from 'components/pages/login/LoginForm';

const Login = () => {
	const theme = useTheme();

	const googleRecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
	const isLoading = useSelector((state) => state.auth.loading);

	return (
		<GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaKey}>
			<Box component={'main'} sx={{ width: '100%', height: '100vh', position: 'relative' }}>
				<Box sx={{ width: '100%', height: '100vh' }}>
					<img
						src={theme.palette.mode === 'light' ? LoginBgLight : LoginBgDark}
						alt='Food Background'
						className='login_bg_cover'
					/>
				</Box>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 2,
						width: '100%',
						height: '100%',
						backgroundColor: '#ffffff1A',
					}}
				/>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 3,
						width: '100%',
						height: '100%',
						p: 3,
					}}
				>
					<Grid
						container
						spacing={2}
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
							<MDCard>
								<MDCardHeader title='Inspiremind' title_size='24px' className='text-center' />
								<CardContent>
									<Box className='my-3 px-2'>
										<LoginForm />
									</Box>
								</CardContent>
							</MDCard>
						</Grid>
					</Grid>
				</Box>
				<ActivityLoader loading={isLoading} />
			</Box>
		</GoogleReCaptchaProvider>
	);
};

export default Login;
