import React from 'react';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ErrorMessage, FieldArray, getIn } from 'formik';
import { get, size } from 'lodash';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Autocomplete, FormLabel } from '@mui/material';
import CMRadio from 'components/utility/form-components/CMRadio';
import CMToolTip from 'components/common/tooltip/CMToolTip';
import translate from 'hooks/translate';
import VideoPicker from 'components/utility/file-picker/v2/video-picker/VideoPicker';
import AudioPicker from 'components/utility/file-picker/v2/audio-picker/AudioPicker';
import PDFPicker from 'components/utility/file-picker/v2/pdf-picker/PDFPicker';

const ConsultantContentSection = (props) => {
	const { formProps, contentConfigList } = props;
	return (
		<Grid item xs={12} className='content-consult-section'>
			<Stack direction='row' className='gap-1'>
				<Typography variant='h4' className='mb-2'>
					{translate('consultant_content_section')}
				</Typography>

				<CMToolTip
					icon={<HelpTwoToneIcon size='small' />}
					title='Ensure your video content is in MOV or MP4 format, and for images, we support PNG, JPEG, etc. Please note, the maximum supported video file size is 2 GB.'
				/>
			</Stack>

			<FieldArray name='contentSection'>
				{({ remove }) => (
					<Box>
						{formProps.values.contentSection.length > 0 &&
							formProps.values.contentSection.map((item, index) => {
								const preferredOption = `contentSection[${index}].preferredOption`;
								const errorPreferredOption = getIn(formProps.errors, preferredOption);
								const touchedPreferredOption = getIn(formProps.touched, preferredOption);
								const content = `contentSection[${index}].content`;
								const errorContentData = getIn(formProps.errors, content);
								const touchedContentData = getIn(formProps.touched, content);

								const title = `contentSection[${index}].title`;
								const errorImageVideoTitle = getIn(formProps.errors, title);
								const touchedImageVideoTitle = getIn(formProps.touched, title);

								const data = `contentSection[${index}].data`;
								const errorData = getIn(formProps.errors, data);
								const touchedData = getIn(formProps.touched, data);

								const dataType = `contentSection[${index}].dataType`;

								const isLargeContentSection = size(get(formProps, 'values.contentSection')) > 1;

								return (
									<Grid container spacing={2} key={index}>
										{index !== 0 ? (
											<Grid item xs={12}>
												<Stack
													direction='row'
													justifyContent='flex-start'
													alignItems='center'
													spacing={1}
												>
													<Divider
														orientation='horizontal'
														flexItem={true}
														light={true}
														sx={{
															width: `calc(100% - 48px)`,
															margin: 'auto 0 !important',
														}}
													/>
													{isLargeContentSection && (
														<IconButton color='error' onClick={() => remove(index)}>
															<ClearOutlinedIcon />
														</IconButton>
													)}
												</Stack>
											</Grid>
										) : null}
										<Grid item xs={12}>
											<FormControl fullWidth>
												<FormLabel>{translate('common_preferred_option')}</FormLabel>
												<RadioGroup
													name={preferredOption}
													value={item.preferredOption}
													onChange={(_e, value) => {
														formProps.setFieldValue(preferredOption, value);
														if (value === 'VIDEO' || value === 'AUDIO' || value === 'PDF') {
															formProps.setFieldValue(dataType, `${value}_UPLOAD`);
														}

														if (value !== 'PDF' && formProps.values.preferredOption === 'Paid') {
															formProps.setFieldValue('preferredOption', 'Free');
														}

														formProps.setFieldValue(title, '');
														formProps.setFieldValue(title, '');
														formProps.setFieldValue(data, '');
														formProps.setFieldValue(content, {});
													}}
													row
													sx={{
														marginLeft: '10px',
														marginTop: '10px',
													}}
												>
													<FormControlLabel
														value='VIDEO'
														control={<CMRadio />}
														label={translate('common_video')}
													/>
													<FormControlLabel
														value='AUDIO'
														control={<CMRadio />}
														label={translate('common_audio')}
													/>
													{/* <FormControlLabel
															value='PDF'
															control={<CMRadio />}
															label={('common_PDF')}
														/> */}
												</RadioGroup>
												{errorPreferredOption && touchedPreferredOption && (
													<FormHelperText>
														<ErrorMessage name={preferredOption} />
													</FormHelperText>
												)}
											</FormControl>
										</Grid>
										{item.preferredOption === 'VIDEO' ||
										item.preferredOption === 'AUDIO' ||
										item.preferredOption === 'PDF' ? null : (
											<>
												<Grid item xs={12}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														label='Title'
														name={title}
														value={item.title}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
														helperText={
															errorImageVideoTitle && touchedImageVideoTitle
																? errorImageVideoTitle
																: null
														}
														fullWidth
													/>
												</Grid>
											</>
										)}

										{/* ------VIDEO------ */}
										{/* {item.preferredOption === 'VIDEO' ? (
												<Grid item xs={12}>
													<FormControl fullWidth>
														<FormLabel>{('consultant_video_type')}</FormLabel>
														<RadioGroup
															name={dataType}
															value={item.dataType}
															onChange={(_e, value) => {
																formProps.setFieldValue(dataType, value);
															}}
															row
															sx={{
																marginLeft: '10px',
																marginTop: '10px',
															}}
														>
															<FormControlLabel
																value='VIDEO_UPLOAD'
																control={<CMRadio />}
																label={('consultant_video_upload')}
															/>
															<FormControlLabel
																value='VIDEO_LINK'
																control={<CMRadio />}
																label={('consultant_video_link')}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											) : null} */}

										{item.preferredOption === 'VIDEO' && item.dataType === 'VIDEO_UPLOAD' ? (
											<Grid item xs={12}>
												<VideoPicker
													label={translate('common_video_upload')}
													id='data'
													name={data}
													value={item?.data}
													onChange={(file) => formProps.setFieldValue(data, file)}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : false}
													dropBoxGridSize={6}
													previewGridSize={6}
													noPreviewImageBaseURL={true}
												/>
											</Grid>
										) : item.preferredOption === 'AUDIO' || item.preferredOption === 'PDF' ? null : (
											<>
												<Grid item xs={12}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
											</>
										)}
										{item.preferredOption === 'PDF' || item.preferredOption === 'AUDIO' ? null : (
											<>
												<Grid item xs={12}>
													<TextField
														label='Title'
														name={title}
														value={item.title}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
														helperText={
															errorImageVideoTitle && touchedImageVideoTitle
																? errorImageVideoTitle
																: null
														}
														fullWidth
													/>
												</Grid>
												{/* -------------DROPDOWN FOR VIDEO------------- */}

												<Grid item xs={12}>
													<Autocomplete
														getOptionLabel={(option) => option.title ?? ''}
														options={contentConfigList || []}
														name={content}
														value={item?.content}
														isOptionEqualToValue={(option, value) => option._id === value._id}
														onChange={(_e, value) => {
															formProps?.setFieldValue(content, value);
														}}
														onBlur={formProps.handleBlur}
														disablePortal
														autoHighlight
														disableClearable
														noOptionsText={translate('common_no_data')}
														popupIcon={<KeyboardArrowDownSharpIcon />}
														renderInput={(params) => (
															<TextField
																{...params}
																placeholder='Genre'
																label='Genre'
																name={content}
																error={errorContentData && touchedContentData ? true : false}
																helperText={
																	errorContentData && touchedContentData
																		? errorContentData
																		: null
																}
															/>
														)}
													/>
												</Grid>
											</>
										)}

										{/* -----AUDIO------ */}
										{/* {item.preferredOption === 'AUDIO' ? (
												<Grid item xs={12}>
													<FormControl fullWidth>
														<FormLabel>{('consultant_audio_type')}</FormLabel>
														<RadioGroup
															name={dataType}
															value={item.dataType}
															onChange={(_e, value) => {
																formProps.setFieldValue(dataType, value);
															}}
															row
															sx={{
																marginLeft: '10px',
																marginTop: '10px',
															}}
														>
															<FormControlLabel
																value='AUDIO_UPLOAD'
																control={<CMRadio />}
																label={('consultant_audio_upload')}
															/>
															<FormControlLabel
																value='AUDIO_LINK'
																control={<CMRadio />}
																label={('consultant_audio_link')}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											) : null} */}

										{item.preferredOption === 'AUDIO' && item.dataType === 'AUDIO_UPLOAD' ? (
											<Grid item xs={12}>
												<AudioPicker
													label={translate('common_audio_upload')}
													id='data'
													name={data}
													value={item?.data}
													onChange={(file) => formProps.setFieldValue(data, file)}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : false}
													dropBoxGridSize={6}
													previewGridSize={6}
													noPreviewImageBaseURL={true}
												/>
											</Grid>
										) : item.preferredOption === 'VIDEO' || item.preferredOption === 'PDF' ? null : (
											<>
												<Grid item xs={12}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
											</>
										)}

										{item.preferredOption === 'VIDEO' || item.preferredOption === 'PDF' ? null : (
											<>
												<Grid item xs={12}>
													<TextField
														label='Title'
														name={title}
														value={item.title}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
														helperText={
															errorImageVideoTitle && touchedImageVideoTitle
																? errorImageVideoTitle
																: null
														}
													/>
												</Grid>

												{/* -------------DROPDOWN FOR VIDEO------------- */}
												<Grid item xs={12}>
													<Autocomplete
														getOptionLabel={(option) => {
															return option.title ?? '';
														}}
														options={contentConfigList || []}
														name={content}
														value={item?.content}
														isOptionEqualToValue={(option, value) => {
															if (value === '' || option._id === value._id) {
																return true;
															}
														}}
														onChange={(_e, value) => {
															formProps.setFieldValue(content, value);
														}}
														onBlur={formProps.handleBlur}
														disablePortal
														autoHighlight
														disableClearable
														noOptionsText={translate('common_no_data')}
														popupIcon={<KeyboardArrowDownSharpIcon />}
														renderInput={(params) => (
															<TextField
																{...params}
																placeholder={
																	'select'
																	// "consultant_select_language"
																}
																name={content}
																error={errorContentData && touchedContentData ? true : false}
																helperText={
																	errorContentData && touchedContentData
																		? errorContentData
																		: null
																}
															/>
														)}
													/>
												</Grid>
											</>
										)}

										{/* --------PDF-------- */}
										{/* {item.preferredOption === 'PDF' ? (
												<Grid item xs={12}>
													<FormControl fullWidth>
														<FormLabel>{('consultant_pdf_type')}</FormLabel>
														<RadioGroup
															name={dataType}
															value={item.dataType}
															onChange={(_e, value) => {
																formProps.setFieldValue(dataType, value);
															}}
															row
															sx={{
																marginLeft: '10px',
																marginTop: '10px',
															}}
														>
															<FormControlLabel
																value='PDF_UPLOAD'
																control={<CMRadio />}
																label={('consultant_pdf_upload')}
															/>
															<FormControlLabel
																value='PDF_LINK'
																control={<CMRadio />}
																label={('consultant_pdf_link')}
															/>
														</RadioGroup>
													</FormControl>
												</Grid>
											) : null} */}

										{item.preferredOption === 'PDF' && item.dataType === 'PDF_UPLOAD' ? (
											<Grid item xs={12}>
												<PDFPicker
													label={translate('common_pdf_upload')}
													id='data'
													name={data}
													value={item?.data}
													onChange={(file) => formProps.setFieldValue(data, file)}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : false}
													dropBoxGridSize={6}
													previewGridSize={6}
													noPreviewImageBaseURL={true}
												/>
											</Grid>
										) : item.preferredOption === 'AUDIO' || item.preferredOption === 'VIDEO' ? null : (
											<>
												<Grid item xs={12}>
													<TextField
														label='Link'
														name={data}
														value={item.data}
														onChange={formProps.handleChange}
														onBlur={formProps.handleBlur}
														error={errorData && touchedData ? true : false}
														helperText={errorData && touchedData ? errorData : null}
														fullWidth={true}
														multiline={true}
													/>
												</Grid>
											</>
										)}
										{item.preferredOption === 'VIDEO' || item.preferredOption === 'AUDIO' ? null : (
											<Grid item xs={12}>
												<TextField
													label='Title'
													name={title}
													value={item.title}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
													error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
													helperText={
														errorImageVideoTitle && touchedImageVideoTitle
															? errorImageVideoTitle
															: null
													}
												/>
											</Grid>
										)}
									</Grid>
								);
							})}
						{/* <Button
                variant="outlined"
                sx={{ mr: 2, mt: 2 }}
                onClick={() =>
                  push({
                    data: "",
                    preferredOption: "VIDEO",
                    title: "",
                    dataType: "VIDEO_LINK", 
                  })
                }
                disabled={formProps.values.contentSection.length >= 3}
              >
                {translate("consultant_add_content")}
              </Button> */}
					</Box>
				)}
			</FieldArray>
		</Grid>
	);
};

export default ConsultantContentSection;
