import React, { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { getAllConsultantUsers } from 'store/slice/user-management/ConsultantUserSlice';
import MDCard from 'components/md-components/card/MDCard';
import MDDataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import toCapitalize from 'components/hooks/toCapitalize';
import MDCardHeader from 'components/md-components/card/MDCardHeader';

const UserManageConsultantVerifyRequest = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const allUsersList = useSelector((state) => state.consultantUser.allUsers);
	const isLoading = useSelector((state) => state.consultantUser.loading);

	useEffect(() => {
		dispatch(getAllConsultantUsers());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columnsData = useMemo(
		() => [
			{
				field: 'name',
				headerName: 'Name',
				width: 240,
				editable: false,
				valueGetter: (params) => {
					return `${toCapitalize(params.row.firstName)} ${toCapitalize(params.row.lastName)}`;
				},
			},
			{
				field: 'email',
				headerName: 'E-mail',
				width: 240,
				editable: false,
			},
			{
				field: 'isVerified',
				headerName: 'Verify Status',
				headerAlign: 'center',
				align: 'center',
				width: 180,
				renderCell: (params) => {
					return (
						<Chip
							label={
								params.row.isVerified === 0
									? 'New'
									: params.row.isVerified === 1
									? 'Approved'
									: params.row.isVerified === 2
									? 'Resubmit'
									: params.row.isVerified === 3
									? 'Rejected'
									: ''
							}
							icon={
								params.row.isVerified === 0 ? (
									<NewReleasesOutlinedIcon fontSize='small' />
								) : params.row.isVerified === 1 ? (
									<CheckCircleOutlineIcon fontSize='small' />
								) : params.row.isVerified === 2 ? (
									<AutorenewIcon fontSize='small' />
								) : params.row.isVerified === 3 ? (
									<CancelOutlinedIcon fontSize='small' />
								) : (
									<></>
								)
							}
							color={
								params.row.isVerified === 0
									? 'info'
									: params.row.isVerified === 1
									? 'success'
									: params.row.isVerified === 2
									? 'warning'
									: params.row.isVerified === 3
									? 'error'
									: 'info'
							}
							size='small'
						/>
					);
				},
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				width: 180,
				renderCell: (params) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label={`${params.row.name}-view-button`}
								color='primary'
								onClick={() => {
									navigate(`/user-management/consultant-user-detail`, {
										state: {
											userId: params.row._id,
											userType: 'none-verified',
										},
									});
								}}
							>
								<VisibilityIcon sx={{ fontSize: 28 }} />
							</IconButton>
						</Box>
					);
				},
			},
		],
		[navigate]
	);

	const CustomToolbar = () => {
		return <MDDataGridToolbar align='right' divider={true} />;
	};

	return (
		<Box>
			<MDCard>
				<MDCardHeader title="Consultant User's Verification" />
				<CardContent>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<MDDataGrid
								rows={allUsersList}
								columns={columnsData}
								getRowId={(row) => row._id}
								pageSize={10}
								rowsPerPageOptions={[10]}
								disableSelectionOnClick
								loading={isLoading}
								components={{
									Toolbar: CustomToolbar,
									NoRowsOverlay: EmptyTableRowOverlay,
									LoadingOverlay: LinearProgress,
								}}
								componentsProps={{
									toolbar: {
										showQuickFilter: true,
										quickFilterProps: { debounceMs: 500 },
									},
								}}
								autoHeight
							/>
						</Grid>
					</Grid>
				</CardContent>
			</MDCard>
		</Box>
	);
};

export default UserManageConsultantVerifyRequest;
