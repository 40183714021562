import AxiosDefault from "services/AxiosDefaultSetting";

export const GetRecipeList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/recipe/list`,
    data: data,
  });
  return response;
};

export const GetRecipesNonApproveList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/recipe/non-approved/list`,
    data: data,
  });
  return response;
};

export const UpdateApproveRecipe = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/health-food/recipe/approve/${id}`,
    data: data,
  });
  return response;
};

export const ImageUploadRecipe = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/recipe/image/upload`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const GetFDCIngredientsList = async (searchKey) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/health-food/fdc/list?search=${searchKey}`,
  });
  return response;
};
export const GetFDCIngredientDetail = async (fdcID) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/health-food/fdc/ingredient-details?fdcId=${fdcID}`,
  });
  return response;
};

export const CreateUpdateRecipe = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/health-food/recipe/add-update`,
    data: data,
  });
  return response;
};

export const PublishRecipe = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/health-food/recipe/publish/${id}`,
    data: data,
  });
  return response;
};

export const DeleteRecipe = async (id) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/health-food/recipe/delete/${id}`,
  });
  return response;
};
