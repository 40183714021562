import PoliciesDetails from "./PoliciesDetails";

const Policies = ({ props }) => {
  //   console.log("props :>> ", props.values.policies);

  return (
    <>
      {props.values.policies &&
        props.values.policies.length > 0 &&
        props.values.policies.map((policy, index) =>
          //   console.log("policy :>> ", policy);
          <PoliciesDetails props={props} policy={policy} index={index} />
        )}
    </>
  );
};

export default Policies;
