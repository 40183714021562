import axios from 'axios';
import { cookieGet } from 'components/hooks/cookie';

const BASE_URL = process.env.REACT_APP_API_END_POINT;

export function jwtInterceptor() {
	axios.interceptors.request.use((request) => {
		// add auth header with jwt if account is logged in and request is to the api url

		const isApiUrl = request.baseURL?.startsWith(BASE_URL);

		const authToken = cookieGet('auth_token');

		if (authToken && isApiUrl && !request.headers.authorization) {
			request.headers.authorization = authToken;
		}

		return request;
	});
}
