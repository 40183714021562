import { useSelector } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DetailWrapper, { DetailTitle, DetailValue } from 'components/common/DetailWrapper';
import MDCard from 'components/md-components/card/MDCard';
import DetailCardSkeleton from 'components/md-components/skeleton/DetailCardSkeleton';

const EndUserInfo = ({ userDetails }) => {
	const isLoading = useSelector((state) => state.endUser.loading);

	if (isLoading) {
		return <DetailCardSkeleton />;
	}

	return (
		<MDCard>
			<Box className='detail-box-header'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
					<Typography variant='body1' className='detail-box-heading'>
						User Information
					</Typography>
				</Stack>
			</Box>
			<CardContent>
				<Stack direction={{ xs: 'column', sm: 'row' }} alignItems='flex-start' spacing={{ xs: 2, sm: 5 }}>
					<Box>
						<Avatar
							src={userDetails?.profilePictureURL}
							alt={userDetails?.firstName}
							variant='rounded'
							sx={{
								width: 96,
								height: 96,
								border: '2px solid',
								borderColor: 'primary.100',
							}}
						/>
					</Box>
					<Box>
						<Stack direction='column' spacing={1}>
							<DetailWrapper>
								<DetailTitle title='Full Name' colon={true} />
								<DetailValue>
									{[userDetails?.firstName, userDetails?.lastName].filter(Boolean).join(' ')}
								</DetailValue>
							</DetailWrapper>
							<DetailWrapper>
								<DetailTitle title='E-mail' colon />
								<DetailValue>{userDetails?.email}</DetailValue>
							</DetailWrapper>
							{userDetails?.mobile ? (
								<DetailWrapper>
									<DetailTitle title={'Mobile Number'} colon />
									<DetailValue>{userDetails?.code + ' ' + userDetails?.mobile}</DetailValue>
								</DetailWrapper>
							) : null}
						</Stack>
					</Box>
				</Stack>
			</CardContent>
		</MDCard>
	);
};

export default EndUserInfo;
