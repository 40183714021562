import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDDialog from 'components/md-components/dialog/MDDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { toast } from 'react-toastify';
import { Avatar, Box, FormControl, FormLabel, IconButton, Stack } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import ImageUpload from 'components/common/ImageUpload';

const getShape = (languageList) => {
	const shape = {
		title: Yup.string().min(4).required('Title is required.'),
	};
	languageList?.forEach((language) => {
		shape['d' + language._id] = Yup.string()
			.min(4, 'Description must be at least 4 characters')
			.required('Description is required.');
		shape['m' + language._id] = Yup.string()
			.min(4, 'Description must be at least 4 characters')
			.required('Description is required.');
	});

	return shape;
};

const getInitialValue = (dialogData, iconURL) => {
	const state = {
		title: get(dialogData, 'rowData.title', ''),
		icon: iconURL || get(dialogData, 'rowData.icon', ''),
	};

	get(dialogData, 'rowData.multiTitle', []).forEach((multiTitle) => {
		const languageId = get(multiTitle, 'language._id', null);
		if (languageId) {
			state['m' + languageId] = multiTitle.value;
		}
	});
	get(dialogData, 'rowData.description', []).forEach((description) => {
		const languageId = get(description, 'language._id', null);
		if (languageId) {
			state['d' + languageId] = description.value;
		}
	});

	return state;
};

const KnowledgeBaseCategoryConfigForm = ({ dialogOpen, handleDialogClose, dialogData, getList }) => {
	const dispatch = useDispatch();
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const [loading, setLoading] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const [open, setOpen] = useState(false);
	const [iconURL, setIconURL] = useState('');

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const ValidationSchema = Yup.object().shape(getShape(languageConfigList));

	const handleSubmit = async (values, { resetForm }) => {
		setLoading(true);

		const { title, macroTitle, icon, ...description } = values;
		const data = { title, icon };
		data.multiTitle = Object.keys(description)
			.filter((e) => e.startsWith('m'))
			.map((e) => ({
				language: e.slice(1),
				value: description[e],
			}));
		data.description = Object.keys(description)
			.filter((e) => e.startsWith('d'))
			.map((e) => ({
				language: e.slice(1),
				value: description[e],
			}));

		if (dialogData?.rowData?._id) {
			data.id = dialogData.rowData._id;
		}

		try {
			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/knowledge-base/add`,
				data: data,
			});
			if (response.status === 200) {
				toast.success(response.data.message);
				getList();
				handleDialogClose();
				resetForm();
				setLoading(false);
				return;
			}
		} catch (error) {}

		setLoading(false);
	};

	const handleDialogOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<MDDialog title={dialogData.title} open={dialogOpen} close={handleDialogClose} maxWidth='md' fullWidth={true}>
			{languageConfigList?.length > 0 && isRefresh && (
				<Formik
					initialValues={getInitialValue(dialogData, iconURL)}
					validationSchema={ValidationSchema}
					onSubmit={handleSubmit}
					enableReinitialize
				>
					{(formProps) => (
						<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto'>
							<DialogContent dividers={true}>
								<Grid container rowSpacing={2} columnSpacing={4}>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<FormLabel htmlFor='age' className='mb-1'>
												Upload Icon
											</FormLabel>
											<Stack direction='row' alignItems='center' spacing={2}>
												<Box className='position-relative'>
													<Avatar
														name='icon'
														variant='square'
														src={formProps.values.icon}
														alt={get(dialogData, 'icon', '')}
														sx={{
															width: 96,
															height: 96,
															bgcolor: 'primary.main',
														}}
													/>
													<IconButton
														onClick={handleDialogOpen}
														className='position-absolute right-0 bottom-0'
														sx={{
															backgroundColor: 'common.white',
															'&:hover': {
																backgroundColor: 'grey.200',
															},
														}}
													>
														<PhotoCamera />
													</IconButton>
												</Box>
												<ImageUpload
													formProps={formProps}
													open={open}
													setOpen={setOpen}
													handleClose={handleClose}
													setIsRefresh={setIsRefresh}
													dialogData={dialogData}
													setIconURL={setIconURL}
												/>
											</Stack>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											label='Title'
											name='title'
											value={formProps.values.title}
											error={Boolean(formProps.errors.title && formProps.touched.title)}
											helperText={
												formProps.errors.title && formProps.touched.title ? formProps.errors.title : null
											}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
										/>
									</Grid>

									{languageConfigList?.map((language) => (
										<React.Fragment key={language._id}>
											<Grid item xs={12}>
												<TextField
													fullWidth
													multiline
													rows={3}
													label={`Title (${language.title})`}
													name={'m' + language._id}
													value={formProps.values['m' + language._id]}
													error={Boolean(formProps.errors['m' + language._id])}
													helperText={formProps.errors['m' + language._id]}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													fullWidth
													multiline
													rows={3}
													label={`Description (${language.title})`}
													name={'d' + language._id}
													value={formProps.values['d' + language._id]}
													error={Boolean(formProps.errors['d' + language._id])}
													helperText={formProps.errors['d' + language._id]}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
												/>
											</Grid>
										</React.Fragment>
									))}
								</Grid>
							</DialogContent>
							<DialogActions>
								<MDLoadingButton type='submit' variant='contained' loading={loading}>
									Submit
								</MDLoadingButton>
							</DialogActions>
						</form>
					)}
				</Formik>
			)}
		</MDDialog>
	);
};

export default KnowledgeBaseCategoryConfigForm;
