import React from 'react';
import { ErrorMessage, FieldArray, getIn } from 'formik';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import RichTextEditor from 'components/utility/rich-text-editor/RichTextEditor';
import { Button, FormHelperText, FormLabel, RadioGroup, Stack } from '@mui/material';
import { get } from 'lodash';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CMRadio from 'components/utility/form-components/CMRadio';
import FilePicker from './FilePicker';
import VideoPicker from './VideoPicker';

function RemoveLine({ remove, index }) {
	return (
		<Box>
			<Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
				<Divider
					orientation='horizontal'
					flexItem={true}
					light={true}
					sx={{
						width: `calc(100% - 48px)`,
						margin: 'auto 0 !important',
					}}
				/>
				<IconButton color='error' onClick={() => remove(index)}>
					<ClearOutlinedIcon />
				</IconButton>
			</Stack>
		</Box>
	);
}

export default function DescriptionForm({ formProps, value, name }) {
	const updateOption = (value, dataType, preferredOption, title, data) => {
		formProps.setFieldValue(preferredOption, value);

		let subType = value === 'TEXT' ? '' : value + '_UPLOAD';

		formProps.setFieldValue(dataType, subType);
		formProps.setFieldValue(title, '');
		formProps.setFieldValue(data, '');
	};

	return (
		<FieldArray name={name}>
			{({ remove, push }) => (
				<Box>
					{value?.map((item, index) => {
						const preferredOption = `${name}[${index}].preferredOption`;
						const errorPreferredOption = getIn(formProps.errors, preferredOption);
						const touchedPreferredOption = getIn(formProps.touched, preferredOption);

						const title = `${name}[${index}].title`;
						const errorImageVideoTitle = getIn(formProps.errors, title);
						const touchedImageVideoTitle = getIn(formProps.touched, title);

						const data = `${name}[${index}].data`;
						const errorData = getIn(formProps.errors, data);
						const touchedData = getIn(formProps.touched, data);

						const dataType = `${name}[${index}].dataType`;

						return (
							<Box key={index} className='mt-3'>
								{index !== 0 ? <RemoveLine index={index} remove={remove} /> : null}

								<Grid container spacing={2}>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<FormLabel>Preferred Option</FormLabel>
											<RadioGroup
												name={preferredOption}
												value={item.preferredOption}
												onChange={(_, value) => {
													updateOption(value, dataType, preferredOption, title, data);
												}}
												row
												sx={{ marginLeft: '10px', marginTop: '10px' }}
											>
												<FormControlLabel value='TEXT' control={<CMRadio />} label='Text' />
												<FormControlLabel value='IMAGE' control={<CMRadio />} label={'Image'} />
												<FormControlLabel value='VIDEO' control={<CMRadio />} label={'Video'} />
											</RadioGroup>
											{errorPreferredOption && touchedPreferredOption && (
												<FormHelperText>
													<ErrorMessage name={preferredOption} />
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									{item.preferredOption === 'TEXT' && (
										<Grid item xs={12}>
											<RichTextEditor
												name={data}
												value={get(item, 'data', '')}
												setFieldValue={formProps.setFieldValue}
												error={errorData && touchedData ? true : false}
												helperText={errorData && touchedData ? errorData : null}
											/>
										</Grid>
									)}

									{item.preferredOption === 'IMAGE' && (
										<Grid item xs={12}>
											<FormControl fullWidth>
												<FormLabel>Image Type</FormLabel>
												<RadioGroup
													name={dataType}
													value={item.dataType}
													onChange={(_e, value) => {
														formProps.setFieldValue(dataType, value);
													}}
													row
													sx={{ marginLeft: '10px', marginTop: '10px' }}
												>
													<FormControlLabel
														value='IMAGE_UPLOAD'
														control={<CMRadio />}
														label='Image Upload'
													/>
													<FormControlLabel
														value='IMAGE_LINK'
														control={<CMRadio />}
														label='Image Link'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									)}

									{item.preferredOption === 'IMAGE' && item.dataType === 'IMAGE_UPLOAD' ? (
										<Grid item xs={12}>
											<FilePicker
												label='Image Upload'
												id='data'
												name={data}
												value={item?.data}
												onChange={(file) => formProps.setFieldValue(data, file)}
												error={errorData && touchedData ? true : false}
												helperText={errorData && touchedData ? errorData : false}
												dropBoxGridSize={6}
												previewGridSize={6}
												noPreviewImageBaseURL={true}
											/>
										</Grid>
									) : item.preferredOption === 'TEXT' || item.preferredOption === 'VIDEO' ? null : (
										<Grid item xs={12}>
											<TextField
												label='Link'
												name={data}
												value={item.data}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={errorData && touchedData ? true : false}
												helperText={errorData && touchedData ? errorData : null}
												fullWidth={true}
												multiline={true}
											/>
										</Grid>
									)}

									{item.preferredOption === 'VIDEO' ? (
										<Grid item xs={12}>
											<FormControl fullWidth>
												<FormLabel>Video Type</FormLabel>
												<RadioGroup
													name={dataType}
													value={item.dataType}
													onChange={(_e, value) => {
														formProps.setFieldValue(dataType, value);
													}}
													row
													sx={{
														marginLeft: '10px',
														marginTop: '10px',
													}}
												>
													<FormControlLabel
														value='VIDEO_UPLOAD'
														control={<CMRadio />}
														label='Video Upload'
													/>
													<FormControlLabel
														value='VIDEO_LINK'
														control={<CMRadio />}
														label='Video Link'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
									) : null}

									{item.preferredOption === 'VIDEO' && item.dataType === 'VIDEO_UPLOAD' ? (
										<Grid item xs={12}>
											<VideoPicker
												label='Video Upload'
												id='data'
												name={data}
												value={item?.data}
												onChange={(file) => formProps.setFieldValue(data, file)}
												error={errorData && touchedData ? true : false}
												helperText={errorData && touchedData ? errorData : false}
												dropBoxGridSize={6}
												previewGridSize={6}
												noPreviewImageBaseURL={true}
											/>
										</Grid>
									) : item.preferredOption === 'TEXT' || item.preferredOption === 'IMAGE' ? null : (
										<>
											<Grid item xs={12}>
												<TextField
													label='Link'
													name={data}
													value={item.data}
													onChange={formProps.handleChange}
													onBlur={formProps.handleBlur}
													error={errorData && touchedData ? true : false}
													helperText={errorData && touchedData ? errorData : null}
													fullWidth={true}
													multiline={true}
												/>
											</Grid>
										</>
									)}

									{item.preferredOption !== 'TEXT' && (
										<Grid item xs={12}>
											<TextField
												label='Title'
												name={title}
												value={item.title}
												fullWidth
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={errorImageVideoTitle && touchedImageVideoTitle ? true : false}
												helperText={
													errorImageVideoTitle && touchedImageVideoTitle ? errorImageVideoTitle : null
												}
											/>
										</Grid>
									)}
								</Grid>
							</Box>
						);
					})}
					<Button
						variant='outlined'
						sx={{ mr: 2, mt: 2 }}
						onClick={() =>
							push({
								data: '',
								preferredOption: 'TEXT',
								title: '',
								dataType: 'TEXT',
							})
						}
					>
						Add Content
					</Button>
				</Box>
			)}
		</FieldArray>
	);
}
