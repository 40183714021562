import { Formik } from 'formik';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import LanguageTranslationForm, { languageInitialValue } from 'components/common/LanguageTranslationForm';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDDialog from 'components/md-components/dialog/MDDialog';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';

const ValidationSchema = Yup.object().shape({
	title: Yup.string().required('Title is required.'),
	languages: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().required('Value is required'),
		})
	),
});

const GeneralConfigForm = (props) => {
	const { dialogOpen, handleDialogClose, dialogData, sectionName, refresh, loading, setLoading } = props;

	const dispatch = useDispatch();
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const handleSubmit = async (values, resetForm) => {
		setLoading(true);
		const data = {
			title: values.title,
			languages: values.languages,
			status: values.status,
			sectionName: sectionName,
		};

		try {
			if (dialogData.formType === 'create') {
				const response = await AxiosDefaultSetting({
					method: 'POST',
					url: `/admin/corporate-role/create`,
					data: data,
				});
				const result = response.data;

				if (get(result, 'status', false) === true) {
					toast.success('Data submitted successfully');
					refresh();
					handleDialogClose();
					resetForm();
				} else {
					toast.error('Something went wrong');
				}
			} else if (dialogData.formType === 'update') {
				const response = await AxiosDefaultSetting({
					method: 'PATCH',
					url: `/admin/corporate-role/update/${get(dialogData, 'id', '')}`,
					data: data,
				});
				const result = response.data;

				if (get(result, 'status', false) === true) {
					toast.success('Data update successfully');
					refresh();
					handleDialogClose();
					resetForm();
				} else {
					toast.error('Something went wrong');
				}
			}
		} catch (error) {}

		setLoading(false);
	};

	return (
		<MDDialog
			title={dialogData.title}
			open={dialogOpen}
			close={handleDialogClose}
			maxWidth='md'
			fullWidth={true}
		>
			<Formik
				initialValues={{
					title: get(dialogData, 'rowData.title', ''),
					status: get(dialogData, 'rowData.status', false),
					languages: languageInitialValue(
						languageConfigList,
						get(dialogData, 'rowData.languages', false),
						'common'
					),
				}}
				validationSchema={ValidationSchema}
				onSubmit={(values, { resetForm }) => {
					handleSubmit(values, resetForm);
				}}
			>
				{(formProps) => (
					<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto'>
						<DialogContent dividers={true}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										size='small'
										label='Title'
										name='title'
										value={formProps.values.title}
										error={
											formProps.errors.title && formProps.touched.title ? true : false
										}
										helperText={
											formProps.errors.title && formProps.touched.title
												? formProps.errors.title
												: null
										}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
									/>
								</Grid>

								<Grid item xs={6}>
									<FormControl component='fieldset' variant='standard'>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														name='status'
														checked={formProps.values.status}
														value={formProps.values.status}
														onChange={(e) => {
															formProps.setFieldValue(
																'status',
																e.target.checked
															);
														}}
														inputProps={{ 'aria-label': 'Status' }}
													/>
												}
												label='Status'
												sx={{ mr: 0 }}
											/>
										</FormGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<LanguageTranslationForm
										fieldName='languages'
										formProps={formProps}
										languageConfigList={languageConfigList}
										inputField='text-input'
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<MDLoadingButton
								type='submit'
								variant='contained'
								padding='6px 24px'
								loading={props.isSubmitting && loading}
							>
								Submit
							</MDLoadingButton>
						</DialogActions>
					</form>
				)}
			</Formik>
		</MDDialog>
	);
};

export default GeneralConfigForm;
//
