import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { GetCountries } from "api/country-profile/CountryProfileAPI";

const initialState = {
  list: [],
  loading: false,
};

export const getCountries = createAsyncThunk(
  "countries/getCountries",
  async () => {
    return await GetCountries()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const CountriesSlice = createSlice({
  name: "countries",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.list = get(action.payload, "status", false)
        ? action.payload.data
        : [];
      state.loading = false;
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default CountriesSlice.reducer;
