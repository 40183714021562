import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { filter, get, isEmpty, find } from "lodash";

import MDButton from "components/md-components/button/MDButton";
import MDCard from "components/md-components/card/MDCard";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import DataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import MDSwitch from "components/md-components/switch/MDSwitch";
import {
  getPolicies,
  statusUpdatePolicies,
} from "store/slice/country-profile/CountryPoliciesSlice";

const PoliciesList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countryProfileId = get(location, "state.countryProfileId", "");
  const countryId = get(location, "state.countryId", "");

  const isLoading = useSelector((state) => state.countryPolicies.loading);
  const getPoliciesList = useSelector((state) => state.countryPolicies.list);

  const [itemId, setItemId] = useState("");

  const filterPoliciesList = filter(getPoliciesList, function (item) {
    return item.isDeleted !== true;
  });

  const userType = useMemo(() => {
    return [
      { label: "System 1 (Front)", value: "system_1" },
      { label: "System 2 (Consultant)", value: "system_2" },
    ];
  }, []);

  const getUserTypeLabel = useCallback(
    (value) => {
      const result = find(userType, (item) => item?.value === value);
      return get(result, "label", "NA");
    },
    [userType]
  );

  const handleStatusUpdate = useCallback(
    (event, row) => {
      const apiData = {
        countryProfileId: countryProfileId,
        data: {
          _id: row?._id,
          status: event.target.checked,
        },
      };
      dispatch(statusUpdatePolicies(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getPolicies({ id: countryId }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [countryId, countryProfileId, dispatch]
  );

  useEffect(() => {
    dispatch(getPolicies({ countryId: countryId }));
  }, [countryId, dispatch]);

  const policiesType = useMemo(
    () => [
      { label: "Terms & Conditions", value: "TERMS_AND_CONDITIONS" },
      { label: "Data Policy", value: "DATA_POLICY" },
      { label: "Privacy Policy", value: "PRIVACY_POLICY" },
      {
        label: "Appointment Terms & Condition",
        value: "APPOINTMENT_TERMS_AND_CONDITIONS",
      },
    ],
    []
  );

  const getPolicyTypeLabel = useCallback(
    (policy) => {
      const result = filter(policiesType, { value: policy });
      return get(result, "[0].label", "");
    },
    [policiesType]
  );

  const columnsData = useMemo(
    () => [
      {
        field: "policy",
        headerName: "Policy",
        width: 360,
        editable: false,
        valueGetter: ({ row }) => getPolicyTypeLabel(row?.policy),
      },
      {
        field: "userType",
        headerName: "User Type",
        width: 260,
        editable: false,
        valueGetter: ({ row }) => getUserTypeLabel(row?.userType),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: ({ row }) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  navigate("/country-profile/policy-create-update", {
                    state: {
                      policyData: row,
                      countryProfileId: countryProfileId,
                      countryId: countryId,
                      formType: "update",
                    },
                  });
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={row.status}
                onChange={(event) => {
                  setItemId(row?._id);
                  handleStatusUpdate(event, row);
                }}
                inputProps={{ "aria-label": row?._id, role: "switch" }}
                color="success"
                disabled={itemId === row?._id && isLoading}
              />
            </Box>
          );
        },
      },
    ],
    [
      countryId,
      countryProfileId,
      getPolicyTypeLabel,
      getUserTypeLabel,
      handleStatusUpdate,
      isLoading,
      itemId,
      navigate,
    ]
  );

  const CustomToolbar = () => {
    return (
      <DataGridToolbar align="right" divider={true}>
        <MDButton
          variant="contained"
          label={`Create Policy`}
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            navigate("/country-profile/policy-create-update", {
              state: {
                policyData: [],
                countryProfileId: countryProfileId,
                countryId: countryId,
                formType: "create",
              },
            });
          }}
        />
      </DataGridToolbar>
    );
  };

  return (
    <MDCard>
      <CardContent className="h-100">
        <Grid container spacing={0} className="h-100">
          <Grid item xs={12}>
            <Box className="mb-2">
              <Typography variant="h6" fontWeight={700}>
                Country Policies Listing
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <MDDataGrid
              rows={!isEmpty(filterPoliciesList) ? filterPoliciesList : []}
              columns={columnsData}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default PoliciesList;
