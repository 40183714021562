import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get, map } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { getGridDateOperators } from "@mui/x-data-grid";

import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import QuantityOnlyOperator from "components/md-components/data-table/filters/QuantityFilter";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import TabPanel from "components/md-components/tab/TabPanel";

import {
  archiveDietPlan,
  deleteDietPlan,
  getDietPlanList
} from "store/slice/health-food/DietPlanSlice";

function a11yProps(index) {
  return {
    id: `health-food-diet-plan-tab-${index}`,
    "aria-controls": `health-food-diet-plan-tab-panel-${index}`,
  };
}

const DietPlanList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const isLoading = useSelector((state) => state.healthDietPlan.loading);
  const dietPlanList = useSelector(
    (state) => state.healthDietPlan.dietPlanList
  );

  const breakpointSM = useMediaQuery(theme.breakpoints.up("sm"));

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(0);
  const [sortModal, setSortModal] = useState({
    sortModel: [
      {
        field: "createdAt",
        sort: "desc",
      },
    ],
  });
  const [filterModal, setFilterModal] = useState({
    filterModel: {
      items: [
        {
          columnField: "",
          id: 38876,
          operatorValue: "contains",
          value: "",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    title: true,
    language: true,
    dietPreference: true,
    createdBy: true,
    isArchived: true,
    isDeleted: false,
    createdAt: true,
    updatedAt: false,
    actions: true,
  });
  const [apiType, setApiType] = useState("ALL");

  const getAPIPayload = useMemo(() => {
    return {
      pageNo: page,
      search: get(filterModal, "filterModel.quickFilterValues", "")
        .toString()
        .replace(/,/g, " "),
      type: apiType ?? "",
      sortField: get(sortModal, "sortModel.0.field", "createdAt"),
      sortValue: get(sortModal, "sortModel.0.sort", "asc"),
    };
  }, [apiType, filterModal, page, sortModal]);

  const [rowId, setRowId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (event?.target?.textContent === "All") {
      setApiType("ALL");
    } else if (event?.target?.textContent === "Archived / Deleted") {
      setApiType("ARCHIVED");
    }
  };

  const tabs = [
    {
      title: "All",
    },
    {
      title: "Archived / Deleted",
    },
  ];

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    setSortModal({ sortModel: [...sortModel] });
  }, []);

  const handleFilterChange = useCallback((filterModel) => {
    setFilterModal({ filterModel: { ...filterModel } });
  }, []);

  const getHealthFoodDietPlanList = useCallback(
    (data) => {
      dispatch(getDietPlanList(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            const totalBooking = get(result, "data.totalBookings", 0);
            setRowCountState(totalBooking);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    [dispatch]
  );

  const handleArchiveDietPlan = useCallback(
    (event, row) => {
      const payloadData = {
        id: row?._id,
        data: {
          isArchived: event.target.checked,
        },
      };
      dispatch(archiveDietPlan(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            toast.success(get(result, "message", "Publish successfully."));
            getHealthFoodDietPlanList(getAPIPayload);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch, getAPIPayload, getHealthFoodDietPlanList]
  );

  const handleDeleteConfirmation = useCallback((rowId) => {
    setRowId(rowId);
    setDeleteConfirmation(true);
  }, []);

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const handleDeleteDietPlan = useCallback(
    (id) => {
      dispatch(deleteDietPlan(id))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            toast.success(get(result, "message", "Deleted successfully."));
            getHealthFoodDietPlanList(getAPIPayload);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch, getAPIPayload, getHealthFoodDietPlanList]
  );

  useEffect(() => {
    getHealthFoodDietPlanList(getAPIPayload);
  }, [getAPIPayload, getHealthFoodDietPlanList]);

  const columnsData = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        width: 320,
        editable: false,
      },
      {
        field: "language",
        headerName: "Language",
        width: 150,
        editable: false,
        valueGetter: ({ row }) => row?.language?.name,
      },
      {
        field: "dietPreference",
        headerName: "Dietary Preference",
        width: 150,
        editable: false,
        valueGetter: ({ row }) => row?.dietPreference?.title,
      },
      {
        field: "createdBy",
        headerName: "Created By",
        width: 150,
        editable: false,
      },
      {
        field: "isArchived",
        headerName: "Is Archived",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 text-center">
            <Checkbox
              icon={<CancelIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={get(row, "isArchived", false)}
              onChange={(event) => {
                handleArchiveDietPlan(event, row);
              }}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                color: "error.main",
                "&.Mui-checked": {
                  color: "success.main",
                },
              }}
            />
          </Box>
        ),
      },
      {
        field: "isDeleted",
        headerName: "Is Deleted",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "createdAt",
        headerName: "Create Date",
        type: "date",
        width: 150,
        editable: false,
        filterOperators: getGridDateOperators().concat(
          ...QuantityOnlyOperator({ inputType: "date" })
        ),
        valueGetter: ({ row }) =>
          moment(get(row, "createdAt", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "updatedAt",
        headerName: "Update Date",
        type: "date",
        width: 150,
        editable: false,
        filterOperators: getGridDateOperators().concat(
          ...QuantityOnlyOperator({ inputType: "date" })
        ),
        valueGetter: ({ row }) =>
          moment(get(row, "updatedAt", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        headerAlign: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 d-flex justify-content-center align-items-center gap-2">
            <IconButton
              aria-label={`${row._id}-view`}
              color="primary"
              onClick={() => {
                console.log("Navigate to Customer Site");
                navigate("/health-food/diet-plan-detail", {
                  state: {
                    dietPlanDetail: row,
                    index: tabValue,
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              aria-label={`${row._id}-edit`}
              color="primary"
              onClick={() => {
                navigate("/health-food/diet-plan-update", {
                  state: {
                    dietPlanDetail: row,
                    formType: "update",
                    index: tabValue,
                  },
                });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label={`${row._id}-delete`}
              color="error"
              onClick={() => {
                handleDeleteConfirmation(row._id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleArchiveDietPlan, handleDeleteConfirmation, navigate, tabValue]
  );

  const CustomToolbar = () => {
    return (
      <MDDataGridToolbar
        ToolbarQuickFilter={true}
        align="right"
        divider={true}
      />
    );
  };

  return (
    <MDCard>
      <MDCardHeader title="Health Diet Plan Listing" />
      <CardContent>
        <Grid container spacing={0} className="h-100 align-content-start">
          <Grid item xs={12}>
            <Box sx={{ bgcolor: theme.palette.background.default }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                variant={breakpointSM ? "fullWidth" : "scrollable"}
                scrollButtons={breakpointSM ? false : true}
                aria-label="signup config tabs"
              >
                {map(tabs, (item, index) => (
                  <Tab label={item.title} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {map(tabs, (_item, index) => (
                <TabPanel key={index} value={tabValue} index={index}>
                  <MDDataGrid
                    rows={dietPlanList ?? []}
                    rowCount={rowCountState}
                    columns={columnsData}
                    getRowId={(row) => row._id}
                    disableSelectionOnClick={true}
                    loading={isLoading}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    filterMode="server"
                    onFilterModelChange={handleFilterChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    components={{
                      Toolbar: CustomToolbar,
                      NoRowsOverlay: EmptyTableRowOverlay,
                      LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      sorting: sortModal,
                    }}
                    autoHeight
                  />
                </TabPanel>
              ))}
            </Box>
          </Grid>
        </Grid>
        <DeleteConfirmDialog
          title="Recipe Delete Confirmation"
          open={deleteConfirmation}
          handleClose={handleDeleteConfirmationClose}
          deleteAction={() => {
            handleDeleteDietPlan(rowId);
          }}
        />
      </CardContent>
    </MDCard>
  );
};

export default DietPlanList;
