import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CreateUpdateArticle,
  DeleteArticle,
  GetArticleList,
  GetArticleNonApprovedList,
  ImageUploadArticle,
  PublishArticle,
  UpdateApproveArticle,
} from "api/quotes-management/quotesAPI";

import { get } from "lodash";

const initialState = {
  quotesList: [],
  nonApproveArticleList: [],
  loading: false,
};

export const getArticleList = createAsyncThunk(
  "article/getArticleList",
  async (data) => {
    try {
      const response = await GetArticleList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getArticleNonApproveList = createAsyncThunk(
  "article/getArticleNonApproveList",
  async (data) => {
    try {
      const response = await GetArticleNonApprovedList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const updateApproveArticle = createAsyncThunk(
  "article/updateApproveArticle",
  async (data) => {
    try {
      const response = await UpdateApproveArticle(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const imageUploadArticle = createAsyncThunk(
  "article/imageUploadArticle",
  async (data) => {
    try {
      const response = await ImageUploadArticle(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const createUpdateArticle = createAsyncThunk(
  "article/createUpdateArticle",
  async (data) => {
    try {
      const response = await CreateUpdateArticle(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const publishArticle = createAsyncThunk(
  "article/publishArticle",
  async (data) => {
    try {
      const response = await PublishArticle(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const deleteArticle = createAsyncThunk(
  "article/deleteArticle",
  async (data) => {
    try {
      const response = await DeleteArticle(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const QuotesSlice = createSlice({
  name: "Quotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getArticleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getArticleList.fulfilled, (state, { payload }) => {
        state.quotesList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getArticleList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getArticleNonApproveList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getArticleNonApproveList.fulfilled, (state, { payload }) => {
        state.nonApproveArticleList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getArticleNonApproveList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateApproveArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateApproveArticle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateApproveArticle.rejected, (state) => {
        state.loading = false;
      })
      .addCase(imageUploadArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(imageUploadArticle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(imageUploadArticle.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUpdateArticle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateArticle.rejected, (state) => {
        state.loading = false;
      })
      .addCase(publishArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(publishArticle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(publishArticle.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteArticle.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteArticle.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteArticle.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default QuotesSlice.reducer;
