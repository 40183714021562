import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  GetCountryProfiles,
  StatusUpdateCountryProfile,
  CreateCountryProfile,
  UpdateCountryProfile,
  DeleteCountryProfile,
} from "api/country-profile/CountryProfileAPI";

const initialState = {
  list: [],
  loading: false,
};

export const getCountryProfiles = createAsyncThunk(
  "countryProfile/getCountryProfiles",
  async () => {
    return await GetCountryProfiles()
      .then(function (response) {
        if (get(response, "status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const countryProfileCreate = createAsyncThunk(
  "countryProfile/countryProfileCreate",
  async (data) => {
    return await CreateCountryProfile(data)
      .then(function (response) {
        if (get(response, "status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const countryProfileUpdate = createAsyncThunk(
  "countryProfile/countryProfileUpdate",
  async (data) => {
    return await UpdateCountryProfile(data)
      .then(function (response) {
        if (get(response, "status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }
);
export const countryProfileStatusUpdate = createAsyncThunk(
  "countryProfile/countryProfileStatusUpdate",
  async (data) => {
    return await StatusUpdateCountryProfile(data)
      .then(function (response) {
        if (get(response, "status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const countryProfileDelete = createAsyncThunk(
  "countryProfile/countryProfileDelete",
  async (data) => {
    return await DeleteCountryProfile(data)
      .then(function (response) {
        if (get(response, "status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);

export const CountryProfileSlice = createSlice({
  name: "countryProfile",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCountryProfiles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCountryProfiles.fulfilled, (state, action) => {
      state.list = get(action.payload, "status", false)
        ? action.payload.data
        : [];
      state.loading = false;
    });
    builder.addCase(getCountryProfiles.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileStatusUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(countryProfileStatusUpdate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileStatusUpdate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileCreate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(countryProfileCreate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileCreate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(countryProfileUpdate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileUpdate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileDelete.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(countryProfileDelete.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(countryProfileDelete.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default CountryProfileSlice.reducer;
