import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { Formik } from "formik";
import * as Yup from "yup";
import { isEmpty, get, map } from "lodash";
import { toast } from "react-toastify";

import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";
import { updateConsultantResidentialAddress } from "store/slice/user-management/ConsultantProfileSlice";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDDialog from "components/md-components/dialog/MDDialog";

const ResidentialAddressForm = (props) => {
  const { title, dialogOpen, handleDialogClose, userDetails, userType } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.consultantProfile.loading);
  const countriesList = useSelector((state) => state.commonData.countryList);
  const languagesList = useSelector((state) => state.commonData.languageList);
  const dialingCodeList = useSelector(
    (state) => state.commonData.dialingCodeList
  );

  const [stateList, setStateList] = useState([]);
  const handleCountryChange = (data) => {
    setStateList(get(data, "states", []));
    setCityList([]);
  };

  const [cityList, setCityList] = useState([]);
  const handleStateChange = (data) => {
    setCityList(get(data, "cities", []));
  };

  const formValidations = Yup.object().shape({
    country: Yup.object().nullable().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    addressLine: Yup.string().required("Address Line is required"),
    zipCode: Yup.string().required("Zip Code is required"),
    code: Yup.string().required("Code is required"),
    mobile: Yup.string().required("Phone number is required"),
    language: Yup.array()
      .min(1, "Language is required")
      .max(3, "Max 3 Languages"),
  });

  const handleSubmit = (values) => {
    const languageName = map(values.language, (option) => {
      return { name: option.name };
    });
    const apiData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: {
        ...values,
        country: values?.country?._id,
        language: languageName,
      },
    };
    dispatch(updateConsultantResidentialAddress(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(
            get(result, "message", "Residential Address Update Successfully")
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={title}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          country: get(userDetails, "country", ""),
          state: get(userDetails, "state", ""),
          city: get(userDetails, "city", ""),
          addressLine: get(userDetails, "addressLine", ""),
          zipCode: get(userDetails, "zipCode", ""),
          code: get(userDetails, "code", ""),
          mobile: get(userDetails, "mobile", ""),
          language: get(userDetails, "language", []),
        }}
        validationSchema={formValidations}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    size="small"
                    id="country"
                    name="country"
                    value={props.values.country}
                    options={!isEmpty(countriesList) ? countriesList : []}
                    getOptionLabel={(option) => option?.name ?? option}
                    isOptionEqualToValue={(option, value) => {
                      if (option?._id === value?._id || option._id === "") {
                        return true;
                      }
                    }}
                    onChange={(_event, value) => {
                      props.setFieldValue("country", value);
                      handleCountryChange(value);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {`${option?.flag} ${option?.name}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        error={
                          props.errors.country && props.touched.country
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.country && props.touched.country
                            ? props.errors.country
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "country",
                        }}
                        sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    size="small"
                    id="state"
                    name="state"
                    value={props.values.state}
                    options={!isEmpty(stateList) ? stateList : []}
                    getOptionLabel={(option) => option.name ?? option}
                    isOptionEqualToValue={(option, value) => {
                      return option.name === value;
                    }}
                    onChange={(_event, value) => {
                      props.setFieldValue("state", value.name);
                      handleStateChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        error={
                          props.errors.state && props.touched.state
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.state && props.touched.state
                            ? props.errors.state
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "state",
                        }}
                        sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    size="small"
                    id="city"
                    name="city"
                    value={props.values.city}
                    options={!isEmpty(cityList) ? cityList : []}
                    getOptionLabel={(option) => option.name ?? option}
                    isOptionEqualToValue={(option, value) => {
                      return option.name === value;
                    }}
                    onChange={(_event, value) => {
                      props.setFieldValue("city", value.name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="City"
                        error={
                          props.errors.city && props.touched.city ? true : false
                        }
                        helperText={
                          props.errors.city && props.touched.city
                            ? props.errors.city
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "city",
                        }}
                        sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    multiline={true}
                    size="small"
                    id="addressLine"
                    label="Address Line"
                    name="addressLine"
                    value={props.values.addressLine}
                    error={
                      props.errors.addressLine && props.touched.addressLine
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.addressLine && props.touched.addressLine
                        ? props.errors.addressLine
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    id="zipCode"
                    label="Zip Code / Postal Code"
                    name="zipCode"
                    value={props.values.zipCode}
                    error={
                      props.errors.zipCode && props.touched.zipCode
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.zipCode && props.touched.zipCode
                        ? props.errors.zipCode
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    className="d-grid gap-2"
                    sx={{ gridTemplateColumns: "1fr 3fr" }}
                  >
                    <Autocomplete
                      fullWidth={true}
                      autoHighlight={true}
                      disableClearable={true}
                      size="small"
                      id="code"
                      name="code"
                      value={props.values.code}
                      options={dialingCodeList}
                      getOptionLabel={(option) => option.dialingcode ?? option}
                      isOptionEqualToValue={(option, value) =>
                        option.dialingcode === value
                      }
                      onChange={(_event, value) => {
                        props.setFieldValue("code", value.dialingcode);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {`${option.flag} ${option.dialingcode}`}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Code"
                          error={
                            props.errors.code && props.touched.code
                              ? true
                              : false
                          }
                          helperText={
                            props.errors.code && props.touched.code
                              ? props.errors.code
                              : null
                          }
                          onBlur={props.handleBlur}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "code",
                          }}
                          sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                        />
                      )}
                    />
                    <TextField
                      fullWidth={true}
                      size="small"
                      id="mobile"
                      label="Phone Number"
                      name="mobile"
                      value={props.values.mobile}
                      error={
                        props.errors.mobile && props.touched.mobile
                          ? true
                          : false
                      }
                      helperText={
                        props.errors.mobile && props.touched.mobile
                          ? props.errors.mobile
                          : null
                      }
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    multiple={true}
                    disableCloseOnSelect={true}
                    size="small"
                    id="language"
                    name="language"
                    value={props.values.language}
                    options={languagesList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    onChange={(_event, value) => {
                      props.setFieldValue("language", value);
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Languages"
                        error={
                          props.errors.language && props.touched.language
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.language && props.touched.language
                            ? props.errors.language
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "languages",
                        }}
                        sx={{ "& .MuiFormHelperText-root": { mx: 1 } }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                loading={props.isSubmitting || isLoading}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default ResidentialAddressForm;
