import { languageInitialValue } from 'components/common/LanguageTranslationForm';
import { get } from 'lodash';

export function getAddOptionObject(languageConfigList, rowData) {
	return {
		label: '',
		value: '',
		languages: languageInitialValue(languageConfigList, get(rowData, 'languages', false), 'common'),
		subQuestionStatus: false,
		subQuestions: [
			{
				questionTitle: '',
				inputType: '',
				languages: languageInitialValue(
					languageConfigList,
					get(rowData, 'languages', false),
					'common'
				),
				options: [
					{
						label: '',
						value: '',
						languages: languageInitialValue(
							languageConfigList,
							get(rowData, 'languages', false),
							'common'
						),
					},
				],
				minValue: undefined,
				maxValue: undefined,
				stepValue: undefined,
			},
		],
	};
}
