import { useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import fileURLGenerator from "components/hooks/urlGenerator";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";
import ConsultantBasicInfoForm from "./ConsultantBasicInfoForm";
import Lightbox from "components/utility/lightbox";
import Image from "components/utility/media/Image";

const ConsultantBasicInfo = (props) => {
  const { userDetails, userType } = props;
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxData, setLightBoxData] = useState([]);
  const handleOpenLightBox = (src, alt) => {
    setLightBoxOpen(true);
    setLightBoxData([
      {
        src: src,
        alt: alt,
        caption: [],
      },
    ]);
  };
  const handleCloseLightBox = () => {
    setLightBoxOpen(false);
    setLightBoxData([]);
  };

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            Basic Information
          </Typography>
          <IconButton color="primary" onClick={() => handleDialogOpen()}>
            <EditIcon />
          </IconButton>
        </Stack>
      </Box>
      <CardContent>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={{ md: 2, xs: 1.5 }}
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "12px",
            }}
          >
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Country of Citizenship" colon={true} />
              <DetailValue value={get(userDetails, "citizenship.name", "NA")} />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Country of Birth" colon={true} />
              <DetailValue
                value={get(userDetails, "birthCitizenship.name", "NA")}
              />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Date of Birth" colon={true} />
              <DetailValue
                value={moment(get(userDetails, "DOB", "NA")).format("LL")}
              />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Expiration Date" colon={true} />
              <DetailValue
                value={moment(get(userDetails, "expireDate", "NA")).format(
                  "LL"
                )}
              />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Proof of Identity" colon={true} />
              <DetailValue
                value={get(userDetails, "proofOfIdentity.title", "NA")}
              />
            </DetailWrapper>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridRowGap: "8px",
            }}
          >
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Front Image" colon={true} />
              <DetailValue>
                <Button
                  sx={{ maxWidth: 180 }}
                  onClick={() => {
                    handleOpenLightBox(
                      fileURLGenerator(
                        get(userDetails, "identityFrontUrl", "")
                      ),
                      "identity_front_image"
                    );
                  }}
                  className="p-1"
                >
                  <Image
                    alt="identity_front_image"
                    src={fileURLGenerator(
                      get(userDetails, "identityFrontUrl", "")
                    )}
                  />
                </Button>
              </DetailValue>
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Back Image" colon={true} />
              <DetailValue>
                <Button
                  sx={{ maxWidth: 180 }}
                  onClick={() => {
                    handleOpenLightBox(
                      fileURLGenerator(get(userDetails, "identityBackUrl", "")),
                      "identity_front_image"
                    );
                  }}
                  className="p-1"
                >
                  <Image
                    alt="identity_back_image"
                    src={fileURLGenerator(
                      get(userDetails, "identityBackUrl", "")
                    )}
                  />
                </Button>
              </DetailValue>
            </DetailWrapper>
          </Box>
        </Stack>
        
        <Lightbox
          open={lightBoxOpen}
          handleClose={handleCloseLightBox}
          imageData={lightBoxData}
        />
        <ConsultantBasicInfoForm
          title="Basic Information Update"
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          userDetails={userDetails}
          userType={userType}
        />
      </CardContent>
    </MDCard>
  );
};

export default ConsultantBasicInfo;
