import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Stack from "@mui/material/Stack";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";

import ConsultantBasicInfo from "components/pages/user-management/consultant-user/basic-info";
import ConsultantHighSchoolInfo from "components/pages/user-management/consultant-user/high-school-info";
import ConsultantLicenseOrCertificate from "components/pages/user-management/consultant-user/license-or-certificate";
import ConsultantProfessionalDomain from "components/pages/user-management/consultant-user/professional-domain";
import ConsultantProfessionalInfo from "components/pages/user-management/consultant-user/professional-info";
import ConsultantPublicationOrLink from "components/pages/user-management/consultant-user/publication-or-links";
import ConsultantResidentialAddress from "components/pages/user-management/consultant-user/residential-address";
import ConsultantSocialMediaInfo from "components/pages/user-management/consultant-user/social-media-info";
import ConsultantUniversityInfo from "components/pages/user-management/consultant-user/university-info";
import ConsultantUserInfo from "components/pages/user-management/consultant-user/user-info";
import ConsultantUserVerify from "components/pages/user-management/consultant-user/verify-user/index.jsx";
import ConsultantWorkInfo from "components/pages/user-management/consultant-user/work-info";

import {
  getCountryList,
  getDialingCodeList,
  getLanguageList,
} from "store/slice/common/CommonDataSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";
import { getSpecializationData } from "store/slice/common/GeneralConfigDataSlice";
import ConsultantRevenueShareInfo from "components/pages/user-management/consultant-user/revenue-info";

const ConsultantUserDetail = () => {
  const location = useLocation();
  const userId = get(location, "state.userId");
  const userType = get(location, "state.userType");
  const dispatch = useDispatch();

  const verifiedUserDetails = useSelector(
    (state) => state.consultantUser.verifiedUserDetail
  );
  const noneVerifiedUserDetails = useSelector(
    (state) => state.consultantUser.noneVerifiedUserDetail
  );
  const userDetails =
    userType === "verified"
      ? verifiedUserDetails
      : userType === "none-verified"
      ? noneVerifiedUserDetails
      : [];

  useEffect(() => {
    if (userType === "verified") {
      dispatch(getVerifiedConsultantUserDetail({ consultantUserId: userId }));
    } else if (userType === "none-verified") {
      dispatch(
        getNonVerifiedConsultantUserDetail({ consultantUserId: userId })
      );
    }
    dispatch(getCountryList());
    dispatch(getLanguageList());
    dispatch(getDialingCodeList());
    dispatch(getSpecializationData());
  }, [dispatch, userId, userType]);

  return (
    <Stack direction="column" spacing={3}>
      <MDCard>
        <MDCardHeader title="Consultant User Profile" />
      </MDCard>
      <ConsultantUserInfo userDetails={userDetails} userType={userType} />
      <ConsultantBasicInfo userDetails={userDetails} userType={userType} />
      <ConsultantResidentialAddress
        userDetails={userDetails}
        userType={userType}
      />
      <ConsultantProfessionalDomain
        userDetails={userDetails}
        userType={userType}
      />
      <ConsultantProfessionalInfo
        userDetails={userDetails}
        userType={userType}
      />
      <ConsultantWorkInfo userDetails={userDetails} userType={userType} />
      <ConsultantUniversityInfo userDetails={userDetails} userType={userType} />
      <ConsultantHighSchoolInfo userDetails={userDetails} userType={userType} />
      <ConsultantLicenseOrCertificate
        userDetails={userDetails}
        userType={userType}
      />
      <ConsultantPublicationOrLink
        userDetails={userDetails}
        userType={userType}
      />
      <ConsultantSocialMediaInfo
        userDetails={userDetails}
        userType={userType}
      />
      <ConsultantRevenueShareInfo
        userDetails={userDetails}
        userType={userType}
      />
      {userType === "none-verified" && (
        <ConsultantUserVerify userDetails={userDetails} userType={userType} />
      )}
    </Stack>
  );
};

export default ConsultantUserDetail;
