import { getIn } from 'formik';
import { useSelector } from 'react-redux';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import QandALanguageTranslationForm from 'components/pages/cms/user-config/consultant-config/QandALanguageTranslationForm';
import RadioCheckboxForm from './RadioCheckboxForm';
import ScaleForm from './ScaleForm';

const InputTypeList = [
	{ label: 'Text', value: 'text' },
	{ label: 'Radio', value: 'radio' },
	{ label: 'Checkbox', value: 'checkbox' },
	{ label: 'Scale', value: 'scale' },
];

const QuestionForm = ({
	optionIndex,
	remove,
	formProps,
	subQuestionField,
	subQuestionFieldIndex,
	length,
	rowData,
}) => {
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const questionTitle = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].questionTitle`;
	const errorQuestionTitle = getIn(formProps.errors, questionTitle);
	const touchedQuestionTitle = getIn(formProps.touched, questionTitle);

	const languages = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].languages`;

	const inputType = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].inputType`;
	const errorInputType = getIn(formProps.errors, inputType);
	const touchedInputType = getIn(formProps.touched, inputType);

	const minValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].minValue`;
	const errorMinValue = getIn(formProps.errors, minValue);
	const touchedMinValue = getIn(formProps.touched, minValue);

	const maxValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].maxValue`;
	const errorMaxValue = getIn(formProps.errors, maxValue);
	const touchedMaxValue = getIn(formProps.touched, maxValue);

	const stepValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].stepValue`;
	const errorStepValue = getIn(formProps.errors, stepValue);
	const touchedStepValue = getIn(formProps.touched, stepValue);

	const isNotLastIndex = subQuestionFieldIndex !== length - 1 ? true : false;

	return (
		<Grid container spacing={2}>
			<Grid item sm={11} xs={12} order={{ sm: 0, xs: 1 }} container spacing={2}>
				<Grid item xs={12}>
					<TextField
						fullWidth={true}
						multiline={true}
						size='small'
						label='Question Title'
						name={questionTitle}
						value={subQuestionField.questionTitle}
						onChange={formProps.handleChange}
						onBlur={formProps.handleBlur}
						error={errorQuestionTitle && touchedQuestionTitle ? true : false}
						helperText={errorQuestionTitle && touchedQuestionTitle ? errorQuestionTitle : null}
					/>
				</Grid>
				{languages && (
					<Grid item xs={12}>
						<QandALanguageTranslationForm
							fieldName={languages}
							section='SUB_QUESTION'
							subQuestionFieldIndex={subQuestionFieldIndex}
							formProps={formProps}
							optionIndex={optionIndex}
							languageConfigList={languageConfigList}
							inputField='text-input'
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<TextField
						size='small'
						fullWidth={true}
						select={true}
						id='input-type-select'
						label='Input Type'
						name={inputType}
						value={subQuestionField.inputType}
						onChange={formProps.handleChange}
						onBlur={formProps.handleBlur}
						error={errorInputType && touchedInputType ? true : false}
						helperText={errorInputType && touchedInputType ? errorInputType : null}
					>
						{InputTypeList.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				{subQuestionField.inputType === 'radio' || subQuestionField.inputType === 'checkbox' ? (
					<RadioCheckboxForm
						optionIndex={optionIndex}
						formProps={formProps}
						subQuestionField={subQuestionField}
						subQuestionFieldIndex={subQuestionFieldIndex}
						rowData={rowData}
					/>
				) : null}

				{subQuestionField.inputType === 'scale' ? (
					<ScaleForm
						minValue={minValue}
						formProps={formProps}
						subQuestionField={subQuestionField}
						errorMinValue={errorMinValue}
						touchedMinValue={touchedMinValue}
						maxValue={maxValue}
						errorMaxValue={errorMaxValue}
						touchedMaxValue={touchedMaxValue}
						stepValue={stepValue}
						errorStepValue={errorStepValue}
						touchedStepValue={touchedStepValue}
					/>
				) : null}
			</Grid>
			<Grid item sm={1} xs={12} textAlign='end' order={{ sm: 1, xs: 0 }}>
				<IconButton type='button' color='error' onClick={() => remove(subQuestionFieldIndex)}>
					<DeleteOutlineOutlinedIcon />
				</IconButton>
			</Grid>
			{isNotLastIndex ? (
				<Grid item xs={12} sx={{ pb: 2 }} order={2}>
					<Divider light />
				</Grid>
			) : null}
		</Grid>
	);
};

export default QuestionForm;
