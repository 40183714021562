import { FieldArray, getIn } from 'formik';
import { get, map } from 'lodash';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import MDButton from 'components/md-components/button/MDButton';
import MDCard from 'components/md-components/card/MDCard';

import { languageInitialValue } from 'components/common/LanguageTranslationForm';
import QandALanguageTranslationForm from 'components/pages/cms/user-config/consultant-config/QandALanguageTranslationForm';

const RadioCheckboxForm = ({
	optionIndex,
	formProps,
	subQuestionField,
	subQuestionFieldIndex,
	rowData,
}) => {
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const fieldArrayName = `options.${optionIndex}.subQuestions.${subQuestionFieldIndex}.options`;

	return (
		<Grid item xs={12}>
			<FieldArray name={fieldArrayName}>
				{({ remove, push }) => (
					<MDCard variant='outlined'>
						<Box className='px-2 py-2 border-bottom'>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								spacing={2}
							>
								<Typography
									variant='body1'
									fontSize={{ sm: 18, xs: 15 }}
									fontWeight={500}
									textTransform='capitalize'
								>
									{`Sub Question
                                ${subQuestionField.inputType}
                                Options`}
								</Typography>
								<MDButton
									label='Add Option'
									onClick={() =>
										push({
											label: '',
											value: '',
											languages: languageInitialValue(
												languageConfigList,
												get(rowData, 'languages', false),
												'common'
											),
										})
									}
									variant='contained'
									padding='2px 12px'
									fontSize='15px'
								/>
							</Stack>
						</Box>
						<CardContent>
							<TransitionGroup>
								{map(
									subQuestionField.options,
									(
										subQuestionFieldOption,
										subQuestionFieldOptionIndex,
										{ length }
									) => {
										const optionLabel = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].options[${subQuestionFieldOptionIndex}].label`;
										const errorOptionLabel = getIn(
											formProps.errors,
											optionLabel
										);
										const touchedOptionLabel = getIn(
											formProps.touched,
											optionLabel
										);

										const optionValue = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].options[${subQuestionFieldOptionIndex}].value`;
										const errorOptionValue = getIn(
											formProps.errors,
											optionValue
										);
										const touchedOptionValue = getIn(
											formProps.touched,
											optionValue
										);

										const languages = `options.${optionIndex}.subQuestions[${subQuestionFieldIndex}].options[${subQuestionFieldOptionIndex}].languages`;

										const isNotLastIndex =
											subQuestionFieldOptionIndex !== length - 1
												? true
												: false;

										return (
											<Collapse key={subQuestionFieldOptionIndex}>
												<Grid
													container
													spacing={1}
													sx={{
														mb: isNotLastIndex ? 1.5 : 0,
													}}
												>
													<Grid item sm={5.5} xs={12}>
														<TextField
															fullWidth={true}
															multiline={true}
															size='small'
															label='Option Label'
															name={optionLabel}
															value={subQuestionFieldOption.label}
															helperText={
																errorOptionLabel &&
																touchedOptionLabel
																	? errorOptionLabel
																	: null
															}
															error={Boolean(
																errorOptionLabel &&
																	touchedOptionLabel
															)}
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
														/>
													</Grid>
													<Grid item sm={5.5} xs={12}>
														<TextField
															fullWidth={true}
															multiline={true}
															size='small'
															label='Option Value'
															name={optionValue}
															value={subQuestionFieldOption.value}
															helperText={
																errorOptionValue &&
																touchedOptionValue
																	? errorOptionValue
																	: null
															}
															error={Boolean(
																errorOptionValue &&
																	touchedOptionValue
															)}
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
														/>
													</Grid>
													<Grid
														item
														sm={1}
														xs={12}
														sx={{
															textAlign: {
																sm: 'center',
															},
														}}
													>
														<IconButton
															type='button'
															color='error'
															onClick={() =>
																remove(subQuestionFieldOptionIndex)
															}
														>
															<RemoveCircleOutlineOutlinedIcon />
														</IconButton>
													</Grid>
													{languages && (
														<Grid item xs={12}>
															<QandALanguageTranslationForm
																fieldName={languages}
																section='SUB_QUESTION_OPTIONS'
																subQuestionFieldOptionIndex={
																	subQuestionFieldOptionIndex
																}
																subQuestionFieldIndex={
																	subQuestionFieldIndex
																}
																formProps={formProps}
																optionIndex={optionIndex}
																languageConfigList={
																	languageConfigList
																}
																inputField='text-input'
															/>
														</Grid>
													)}
												</Grid>
											</Collapse>
										);
									}
								)}
							</TransitionGroup>
						</CardContent>
					</MDCard>
				)}
			</FieldArray>
		</Grid>
	);
};

export default RadioCheckboxForm;
