import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const DetailCardLabel = ({ label, sx, className, ...rest }) => {
  return (
    <Stack
      direction="row"
      justifyContent={{
        sm: "space-between",
        xs: "flex-start",
      }}
      spacing={2}
      className={`me-3 ${className}`}
      sx={{
        width: { md: "25%", sm: "30%", xs: "auto" },
        ...sx,
      }}
      {...rest}
    >
      <Typography variant="body1" fontWeight={500} color="grey.800">
        {label}
      </Typography>
      <Typography variant="body1" fontWeight={500} color="grey.800">
        :
      </Typography>
    </Stack>
  );
};

export default DetailCardLabel;
