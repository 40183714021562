import AxiosDefault from "services/AxiosDefaultSetting";

export const GetAdminUsers = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/admin-user/get`,
  });
  return response;
};

export const CreateAdminUser = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/admin-user/add-update`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const UpdateAdminUser = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/admin-user/update/${id}`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const StatusUpdateAdminUser = async ({ id, status }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/admin-user/status-update/${id}`,
    data: { status: status },
  });
  return response;
};

export const DeleteAdminUser = async ({ id, isDeleted }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/admin-user/delete-status-update/${id}`,
    data: { isDeleted: isDeleted },
  });
  return response;
};
