import { FieldArray, getIn } from 'formik';
import { find, map } from 'lodash';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const QandALanguageTranslationForm = ({ formProps, languageConfigList }) => {
	const name = 'languages';
	const getLanguageTitle = (id) => {
		const data = find(languageConfigList, (language) => language?._id === id);
		return data?.title ?? '';
	};

	return (
		<FieldArray name={name}>
			{() => (
				<Box>
					{map(formProps.values.languages, (field, index, { length }) => {
						const microValue = `[${name}][${index}].value`;
						const errorMicroValue = getIn(formProps?.errors, microValue);
						const touchedMicroValue = getIn(formProps?.touched, microValue);

						const isTranslate = `[${name}][${index}].isTranslate`;

						return (
							<Grid
								container
								spacing={2}
								key={index}
								className={index !== length - 1 ? 'mb-3' : ''}
							>
								<Grid item md={2} xs={12}>
									<FormLabel
										htmlFor={`${field?.languageId}-value-field`}
										className='fw-medium'
										sx={{ top: { md: '8px' }, color: 'text.primary' }}
									>
										{getLanguageTitle(field?.languageId)}
									</FormLabel>
								</Grid>
								<Grid item md={8} xs={12}>
									<TextField
										id={`${field?.languageId}-value-field`}
										fullWidth={true}
										multiline={true}
										size='small'
										label='Value'
										name={microValue}
										value={field?.value}
										onChange={formProps?.handleChange}
										onBlur={formProps?.handleBlur}
										error={errorMicroValue && touchedMicroValue ? true : false}
										helperText={
											errorMicroValue && touchedMicroValue
												? errorMicroValue
												: null
										}
									/>
								</Grid>
								<Grid item md={2} xs={12}>
									<FormControl component='fieldset' variant='standard'>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														name={isTranslate}
														checked={field?.isTranslate}
														value={field?.isTranslate}
														onChange={(e) => {
															formProps.setFieldValue(
																isTranslate,
																e.target.checked
															);
														}}
														inputProps={{
															'aria-label': `is-language-status-${index}`,
														}}
													/>
												}
												label='Is Translate'
												sx={{ mr: 0 }}
											/>
										</FormGroup>
									</FormControl>
								</Grid>
								{index !== length - 1 ? (
									<Grid item xs={12} pt={{ sm: 2, xs: 0 }}>
										<Divider light={true} flexItem={true} />
									</Grid>
								) : null}
							</Grid>
						);
					})}
				</Box>
			)}
		</FieldArray>
	);
};

export default QandALanguageTranslationForm;
