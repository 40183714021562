
import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    questionTitle: Yup.string().required('Question title is required.'),
    languages: Yup.array().of(
        Yup.object().shape({
            value: Yup.string().required('Value is required'),
        })
    ),
    categories: Yup.array().min(1, 'Categories is required'),
    inputType: Yup.string().required('Input type is required.'),
    options: Yup.array().when('inputType', {
        is: (value) => value === 'radio' || value === 'checkbox',
        then: Yup.array().of(
            Yup.object().shape({
                label: Yup.string().required('Label is required.'),
                value: Yup.string().required('Value is required.'),
                languages: Yup.array().of(
                    Yup.object().shape({
                        value: Yup.string().required('Value is required'),
                    })
                ),
                subQuestions: Yup.array().when('subQuestionStatus', {
                    is: true,
                    then: Yup.array().of(
                        Yup.object().shape({
                            questionTitle: Yup.string().required('Question title is required.'),
                            languages: Yup.array().of(
                                Yup.object().shape({
                                    value: Yup.string().required('Value is required'),
                                })
                            ),
                            inputType: Yup.string().required('Input type is required.'),
                            options: Yup.array().when('inputType', {
                                is: (value) => value === 'radio' || value === 'checkbox',
                                then: Yup.array().of(
                                    Yup.object().shape({
                                        label: Yup.string().required('Label is required.'),
                                        value: Yup.string().required('Value is required.'),
                                        languages: Yup.array().of(
                                            Yup.object().shape({
                                                value: Yup.string().required(
                                                    'Value is required'
                                                ),
                                            })
                                        ),
                                    })
                                ),
                                otherwise: Yup.array().of(Yup.object().notRequired()),
                            }),
                            minValue: Yup.number().when('inputType', {
                                is: (value) => value === 'scale',
                                then: Yup.number().required('Minimum value is required.'),
                                otherwise: Yup.number().notRequired(),
                            }),
                            maxValue: Yup.number().when('inputType', {
                                is: (value) => value === 'scale',
                                then: Yup.number().required('Maximum value is required.'),
                                otherwise: Yup.number().notRequired(),
                            }),
                            stepValue: Yup.number().when('inputType', {
                                is: (value) => value === 'scale',
                                then: Yup.number().required('Step value is required.'),
                                otherwise: Yup.number().notRequired(),
                            }),
                        })
                    ),
                    otherwise: Yup.array().notRequired(),
                }),
            })
        ),
        otherwise: Yup.array().of(Yup.object()),
    }),
    minValue: Yup.number().when('inputType', {
        is: (value) => value === 'scale',
        then: Yup.number().required('Minimum value is required.'),
        otherwise: Yup.number().notRequired(),
    }),
    maxValue: Yup.number().when('inputType', {
        is: (value) => value === 'scale',
        then: Yup.number().required('Maximum value is required.'),
        otherwise: Yup.number().notRequired(),
    }),
    stepValue: Yup.number().when('inputType', {
        is: (value) => value === 'scale',
        then: Yup.number().required('Step value is required.'),
        otherwise: Yup.number().notRequired(),
    }),
});