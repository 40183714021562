import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { get, isEmpty, filter } from "lodash";
import { toast } from "react-toastify";

import {
  deleteCountryState,
  statusUpdateCountryState,
} from "store/slice/country-profile/CountryStatesSlice";
import { getCountryProfiles } from "store/slice/country-profile/CountryProfileSlice";

import MDCard from "components/md-components/card/MDCard";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import MDSwitch from "components/md-components/switch/MDSwitch";
import StateProfileForm from "components/pages/cms/country-profile/StateProfileForm";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCardHeader from "components/md-components/card/MDCardHeader";

const StateProfileList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.countryStates.loading);
  const isCountryProfileLoading = useSelector(
    (state) => state.countryProfile.loading
  );
  const countryProfileList = useSelector((state) => state.countryProfile.list);
  const countryProfileId = get(location, "state.countryProfileId", "");
  const localStoreCountryProfileId = localStorage.getItem("countryProfileId");
  const localStoreCountryId = localStorage.getItem("countryId");

  const filterCountryProfile = filter(
    countryProfileList,
    (item) =>
      get(item, "_id", "") ===
      (!isEmpty(countryProfileId)
        ? countryProfileId
        : localStoreCountryProfileId)
  )[0];

  const [dialogData, setDialogData] = useState({
    title: "",
    formType: "",
    countryId: get(filterCountryProfile, "country._id", localStoreCountryId),
    rowData: [],
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const updateStatus = useCallback(
    (event, params) => {
      setDialogData({ ...dialogData, id: params.id });
      const apiData = {
        countryId: get(
          filterCountryProfile,
          "country._id",
          localStoreCountryId
        ),
        data: {
          name: params.row.name,
          status: event.target.checked,
        },
      };
      dispatch(statusUpdateCountryState(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getCountryProfiles());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dialogData, dispatch, filterCountryProfile, localStoreCountryId]
  );
  const handleDelete = useCallback(
    ({ event }) => {
      const apiData = {
        countryId: get(
          filterCountryProfile,
          "country._id",
          localStoreCountryId
        ),
        data: {
          name: get(dialogData, "rowData.name"),
          isDeleted: event,
        },
      };
      dispatch(deleteCountryState(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "City delete successfully"));
            dispatch(getCountryProfiles());
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          throw err;
        });
      handleDeleteConfirmationClose();
    },
    [dialogData, dispatch, filterCountryProfile, localStoreCountryId]
  );

  useEffect(() => {
    dispatch(getCountryProfiles());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 180,
        editable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: (params) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label="states"
                onClick={() => {
                  navigate("city-profile", {
                    state: {
                      countryProfileId: !isEmpty(countryProfileId)
                        ? countryProfileId
                        : localStoreCountryProfileId,
                      countryId: get(
                        filterCountryProfile,
                        "country._id",
                        localStoreCountryId
                      ),
                      stateName: params.row.name,
                    },
                  });
                  localStorage.setItem("countryStateName", params.row.name);
                }}
              >
                <LocationOnOutlinedIcon color="warning" />
              </IconButton>
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setDialogData({
                    data: params.row,
                    formType: "update",
                    rowData: params.row,
                  });
                  handleDialogOpen();
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={params.row.status}
                onChange={(event) => {
                  updateStatus(event, params);
                }}
                inputProps={{ "aria-label": params.row.id, role: "switch" }}
                color="success"
                disabled={
                  get(dialogData, "id", "") === params.id &&
                  (isLoading || isCountryProfileLoading)
                }
              />
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    title: "State Delete Confirmation",
                    rowData: params.row,
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      countryProfileId,
      dialogData,
      filterCountryProfile,
      handleDeleteConfirmation,
      handleDialogOpen,
      isCountryProfileLoading,
      isLoading,
      localStoreCountryId,
      localStoreCountryProfileId,
      navigate,
      updateStatus,
    ]
  );

  const CustomToolbar = () => {
    return <MDDataGridToolbar align="right" divider={true} />;
  };

  return (
    <MDCard>
      <MDCardHeader title="States Profile" />
      <CardContent className="h-100">
        <Grid container spacing={0} className="h-100">
          <Grid item xs={12}>
            <MDDataGrid
              rows={get(filterCountryProfile, "country.states", [])}
              columns={columnsData}
              getRowId={(row) => row.name}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading || isCountryProfileLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
            <StateProfileForm
              title="State"
              dialogData={dialogData}
              dialogOpen={dialogOpen}
              handleDialogOpen={handleDialogOpen}
              handleDialogClose={handleDialogClose}
            />
            <DeleteConfirmDialog
              title="State Delete Confirmation"
              open={deleteConfirmation}
              handleClose={handleDeleteConfirmationClose}
              deleteAction={() => {
                handleDelete({ event: true, id: get(dialogData, "id", "") });
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default StateProfileList;
