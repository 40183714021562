import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { get, cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import LanguageTranslationForm, { languageInitialValue } from './LanguageTranslationForm';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';

const getInitialData = (dialogData, languageConfigList) => {
	const contentList = get(dialogData, 'content', []);

	return {
		title: dialogData.title || '',
		languages: languageInitialValue(languageConfigList, contentList),
	};
};

const ValidationSchema = Yup.object().shape({
	languages: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().required('Title is required'),
			writer: Yup.string().required('Writer is required'),
		})
	),
});

export default function WelcomeTab() {
	const dispatch = useDispatch();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [dialogData, setDialogData] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const getData = async () => {
		// try {
		// 	const response = await AxiosDefaultSetting({
		// 		method: 'GET',
		// 		url: '/admin/app-config/get?sectionName=welcome',
		// 	});
		// 	if (response.status === 200) {
		// 		setDialogData(response.data[0]);
		// 	} else {
		// 		toast.error('Data not get!');
		// 	}

		// 	setDataLoaded(true);
		// } catch (error) {}
		try {
			if (location.state) {
				setDialogData(location.state);
			}
		} catch (error) {}
		setDataLoaded(true);
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async (formValues) => {
		const values = cloneDeep(formValues);

		setLoading(true);

		try {
			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/app-config/update`,
				data: {
					id: dialogData._id,
					content: {
						title: values.title,
						sectionName: 'welcome',
						content: values.languages.map((e) => ({
							title: e.title,
							writer: e.writer,
							language: e.langID,
						})),
					},
				},
			});
			if (response.status === 200) {
				toast.success('Updated!');
			} else {
				toast.error('Not Updated!');
			}
		} catch (error) {}

		setLoading(false);
	};

	return languageConfigList?.length && dataLoaded ? (
		<MDCard>
			<MDCardHeader title='App config' />
			<CardContent>
				<Formik
					initialValues={getInitialData(dialogData, languageConfigList)}
					validationSchema={ValidationSchema}
					onSubmit={handleSubmit}
				>
					{(formProps) => (
						<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto'>
							<DialogContent dividers={true}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label='Title'
										name='title'
										size='small'
										sx={{ mb: 2 }}
										value={formProps.values.title}
										error={Boolean(formProps.errors.title && formProps.touched.title)}
										helperText={
											formProps.errors.title && formProps.touched.title ? formProps.errors.title : null
										}
										onChange={formProps.handleChange}
										onBlur={formProps.handleBlur}
									/>
								</Grid>
								<LanguageTranslationForm formProps={formProps} />
							</DialogContent>
							<DialogActions>
								<MDLoadingButton type='submit' variant='contained' padding='6px 24px' loading={loading}>
									Submit
								</MDLoadingButton>
							</DialogActions>
						</form>
					)}
				</Formik>
			</CardContent>
		</MDCard>
	) : null;
}
