import AxiosDefault from "services/AxiosDefaultSetting";

export const GetPressReleaseList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/press-release/list`,
    data: data,
  });
  return response;
};

export const GetPressReleaseNonApprovedList = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/articles-blog/non-approved/list`,
    data: data,
  });
  return response;
};

export const UpdateApprovePressRelease = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/articles-blog/approve/${id}`,
    data: data,
  });
  return response;
};

export const ImageUploadPressRelease = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/press-release/image/upload`,
    data: data,
    contentType: "multipart/form-data",
  });
  return response;
};

export const CreateUpdatePressRelease = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/press-release/add-update`,
    data: data,
  });
  return response;
};

export const PublishPressRelease = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/press-release/publish/${id}`,
    data: data,
  });
  return response;
};

export const DeletePressRelease = async (id) => {
  const response = await AxiosDefault({
    method: "DELETE",
    url: `/admin/press-release/delete/${id}`,
  });
  return response;
};
