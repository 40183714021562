import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import RichTextEditor from "components/utility/rich-text-editor/RichTextEditor";

const RecipeFormMethodSection = ({ formProps }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6">Recipe Method</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <RichTextEditor
            name="recipeContent"
            value={formProps.values.recipeContent}
            setFieldValue={formProps.setFieldValue}
            error={
              formProps.errors.recipeContent && formProps.touched.recipeContent
                ? true
                : false
            }
            helperText={
              formProps.errors.recipeContent && formProps.touched.recipeContent
                ? formProps.errors.recipeContent
                : null
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Recipe Video Link"
          name="recipeVideoLink"
          value={formProps.values.recipeVideoLink}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          error={
            formProps.errors.recipeVideoLink &&
            formProps.touched.recipeVideoLink
              ? true
              : false
          }
          helperText={
            formProps.errors.recipeVideoLink &&
            formProps.touched.recipeVideoLink
              ? formProps.errors.recipeVideoLink
              : null
          }
          fullWidth={true}
          multiline={true}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Recipe Video Title"
          name="recipeVideoTitle"
          value={formProps.values.recipeVideoTitle}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          error={
            formProps.errors.recipeVideoTitle &&
            formProps.touched.recipeVideoTitle
              ? true
              : false
          }
          helperText={
            formProps.errors.recipeVideoTitle &&
            formProps.touched.recipeVideoTitle
              ? formProps.errors.recipeVideoTitle
              : null
          }
          fullWidth={true}
          multiline={true}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Recipe Hot Tip"
          name="recipeHotTip"
          value={formProps.values.recipeHotTip}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          error={
            formProps.errors.recipeHotTip && formProps.touched.recipeHotTip
              ? true
              : false
          }
          helperText={
            formProps.errors.recipeHotTip && formProps.touched.recipeHotTip
              ? formProps.errors.recipeHotTip
              : null
          }
          fullWidth={true}
          multiline={true}
          rows={3}
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default RecipeFormMethodSection;
