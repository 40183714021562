import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MDDialog from 'components/md-components/dialog/MDDialog';
import MDButton from 'components/md-components/button/MDButton';

const DeleteConfirmDialog = (props) => {
	const { title, open, handleClose, dialogContent, deleteAction } = props;

	return (
		<MDDialog
			title={title}
			open={open}
			close={handleClose}
			maxWidth='sm'
			fullWidth={true}
			aria-labelledby='delete-confirmation-dialog'
			aria-describedby='delete-confirmation-dialog'
		>
			<DialogContent dividers={true}>
				<Box minWidth={320}>
					<Typography variant='subtitle1'>
						{dialogContent ? dialogContent : 'Are you sure you want to delete ?'}
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Box className='d-flex gap-3'>
					<MDButton label='Cancel' variant='outlined' size='small' onClick={handleClose} />
					<MDButton
						label='Delete'
						variant='contained'
						color='error'
						size='small'
						className='ml-3'
						onClick={deleteAction}
					/>
				</Box>
			</DialogActions>
		</MDDialog>
	);
};

export default DeleteConfirmDialog;
