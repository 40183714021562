import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import {
	GetCountryList,
	GetCurrencyList,
	GetDialingCodeList,
	GetLanguagesList,
	GetTimezoneList,
	GetDocumentList,
	GetCountryListForCountryProfile,
} from 'api/common/PublicDataAPI';

const initialState = {
	dialingCodeList: [],
	countryList: [],
	languageList: [],
	timezoneList: [],
	currencyList: [],
	documentList: [],
	loading: false,
};

export const getDialingCodeList = createAsyncThunk('commonData/getDialingCodeList', async () => {
	return await GetDialingCodeList()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});
export const getCountryList = createAsyncThunk('commonData/getCountryList', async () => {
	return await GetCountryList()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});

export const getCountryListForCountryProfile = createAsyncThunk('commonData/getCountryListForCountryProfile', async () => {
	return await GetCountryListForCountryProfile()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});

export const getLanguageList = createAsyncThunk('commonData/getLanguageList', async () => {
	return await GetLanguagesList()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});
export const getTimezoneList = createAsyncThunk('commonData/getTimezoneList', async () => {
	return await GetTimezoneList()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});

export const getCurrencyList = createAsyncThunk('commonData/getCurrencyList', async () => {
	return await GetCurrencyList()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});

export const getDocumentList = createAsyncThunk('commonData/getDocumentList', async () => {
	return await GetDocumentList()
		.then(function (response) {
			if (get(response, 'data.status', false)) {
				return response.data;
			}
		})
		.catch(function (error) {
			return { status: false, message: error.response.data.message };
		});
});

export const CommonDataSlice = createSlice({
	name: 'commonData',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(getDialingCodeList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getDialingCodeList.fulfilled, (state, action) => {
			state.dialingCodeList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getDialingCodeList.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getCountryList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getCountryList.fulfilled, (state, action) => {
			state.countryList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getCountryList.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getCountryListForCountryProfile.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getCountryListForCountryProfile.fulfilled, (state, action) => {
			state.countryList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getCountryListForCountryProfile.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getLanguageList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getLanguageList.fulfilled, (state, action) => {
			state.languageList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getLanguageList.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getTimezoneList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getTimezoneList.fulfilled, (state, action) => {
			state.timezoneList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getTimezoneList.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getCurrencyList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getCurrencyList.fulfilled, (state, action) => {
			state.currencyList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getCurrencyList.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getDocumentList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getDocumentList.fulfilled, (state, action) => {
			state.documentList = get(action.payload, 'data', []);
			state.loading = false;
		});
		builder.addCase(getDocumentList.rejected, (state) => {
			state.loading = false;
		});
	},
});

export default CommonDataSlice.reducer;
