import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import moment from "moment";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PreviewIcon from "@mui/icons-material/Preview";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import {
  getGridDateOperators,
  getGridNumericOperators,
} from "@mui/x-data-grid";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import QuantityOnlyOperator from "components/md-components/data-table/filters/QuantityFilter";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";

import { getBookingList } from "store/slice/booking/BookingSlice";

const BookingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.booking.loading);
  const bookingList = useSelector((state) => state.booking.bookingList);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(0);
  const [sortModal, setSortModal] = useState({
    sortModel: [
      {
        field: "createdAt",
        sort: "desc",
      },
    ],
  });
  const [filterModal, setFilterModal] = useState({
    filterModel: {
      items: [
        {
          columnField: "",
          id: 38876,
          operatorValue: "contains",
          value: "",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  });
  console.log("SORT_MODEL :::>>>", sortModal);
  console.log("FILTER_MODEL :::>>>", filterModal);

  const handleSortModelChange = useCallback((sortModel) => {
    setSortModal({ sortModel: [...sortModel] });
  }, []);

  const handleFilterChange = useCallback((filterModel) => {
    setFilterModal({ filterModel: { ...filterModel } });
  }, []);

  const getBookingsData = useCallback(
    (pageNo) => {
      dispatch(getBookingList({ pageNo: pageNo }))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            const totalBooking = get(result, "data.totalBookings", 0);
            setRowCountState(totalBooking);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    [dispatch]
  );

  useEffect(() => {
    getBookingsData(page);
  }, [dispatch, getBookingsData, page]);

  const columnsData = useMemo(
    () => [
      {
        field: "bookingId",
        headerName: "Booking ID",
        width: 180,
        editable: false,
      },
      {
        field: "consultantId",
        headerName: "Consultant Name",
        width: 180,
        editable: false,
        valueGetter: ({ row }) =>
          get(row, "consultantId.firstName", "NA") +
          " " +
          get(row, "consultantId.lastName", "NA"),
      },
      {
        field: "consultationDate",
        headerName: "Consultation Date",
        type: "date",
        width: 180,
        editable: false,
        filterOperators: getGridDateOperators().concat(
          ...QuantityOnlyOperator({ inputType: "date" })
        ),
        valueGetter: ({ row }) =>
          moment(get(row, "consultationDate", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "amount",
        headerName: "Amount",
        type: "number",
        width: 120,
        editable: false,
        filterOperators: getGridNumericOperators().concat(
          ...QuantityOnlyOperator({ inputType: "number" })
        ),
        renderCell: ({ row }) => (
          <Box fontWeight={600} className="MuiDataGrid-cellContent">
            {get(row, "rawPlanData.currencyId.currencySymbol", "NA") +
              get(row, "rawPlanData.amount", "NA")}
          </Box>
        ),
      },
      {
        field: "paymentStatus",
        headerName: "Payment Status",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Chip
            label={get(row, "paymentStatus", "NA")}
            color={
              row.paymentStatus === "PAID"
                ? "success"
                : row.paymentStatus === "UNPAID"
                ? "warning"
                : "error"
            }
            size="small"
          />
        ),
      },
      {
        field: "bookingStatus",
        headerName: "Booking Status",
        type: "boolean",
        width: 150,
        editable: false,
        renderCell: ({ row }) => (
          <Box className="w-100 text-center">
            {get(row, "bookingStatus", false) ? (
              <CheckCircleIcon color="success" />
            ) : (
              <CancelIcon color="error" />
            )}
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 120,
        headerAlign: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 text-center">
            <IconButton
              aria-label={`${row._id}-view`}
              color="primary"
              onClick={() =>
                navigate("/booking/booking-detail", { state: { id: row?._id } })
              }
            >
              <PreviewIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [navigate]
  );

  const CustomToolbar = () => {
    return <MDDataGridToolbar />;
  };

  return (
    <MDCard>
      <MDCardHeader title="Booking's Listing" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDDataGrid
              rows={bookingList ?? []}
              rowCount={rowCountState}
              columns={columnsData}
              getRowId={(row) => row._id}
              disableSelectionOnClick={true}
              loading={isLoading}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              filterMode="server"
              onFilterModelChange={handleFilterChange}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              autoHeight
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default BookingList;
