import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Form
import { Formik } from "formik";
import { get, map, omit } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";
// MUI
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// Custom
import MultiSelectSpecialization, {
  getInitSpecialization,
  getInitSubSpecialization,
  getSpecializationSubmitData,
} from "components/common/MultiSelectSpecialization";
import TimeOutComponent from "components/common/TimeOutComponent";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
// Pages
import {
  createUpdatePressRelease,
  imageUploadPressRelease,
} from "store/slice/press-release/PresReleaseSlice";
import { getSpecializationData } from "store/slice/common/GeneralConfigDataSlice";
import { getLanguageConfigList } from "store/slice/language-config/LanguageConfigSlice";
import PressReleasedTopSection from "components/pages/press-release-blog/form-components/PressReleasedTopSection";
import PressReleasedFormContent from "components/pages/press-release-blog/form-components/PressReleasedFormContent";
import PressReleasedSEOSection from "components/pages/press-release-blog/form-components/PressReleasedSEOSection";
import cookie, { cookieGet } from "components/hooks/cookie";
import axios from "axios";

const PressRealeasedBlogCreateUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const articleDetail = get(location, "state.articleDetail", {});
  const formType = get(location, "state.formType", {});

  const languagesList = useSelector(
    (state) => state.languageConfig.languageList
  );
  const specializationList = useSelector(
    (state) => state.generalConfigData.specializationData
  );
  const isLoading = useSelector((state) => state.article.loading);
  const [fileUploadLoading, setFileUploadLoading] = useState("");
  const [checkImage, setCheckImage] = useState(true);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    subTitle: Yup.string().required("Subtitle is required"),
    language: Yup.object().shape().required("Language is required").nullable(),
    categories: Yup.array().min(1, "Categories is required"),
    // subCategories: Yup.array().min(1, "Sub categories is required"),
    topSection: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .min(3, "Image title is too short")
          .max(500, "Image title is too long")
          .required("Image title is required"),
        imageUrl: Yup.mixed().when("imageType", {
          is: "IMAGE_UPLOAD",
          then: Yup.array().required("Image is required"),
          otherwise: Yup.mixed()
            // .url(translate("consultant_error_enter_valid_url"))
            .required("Image link is required"),
        }),
      })
    ),
    contentSection: Yup.array().of(
      Yup.object().shape({
        preferredOption: Yup.string().required("Preferred option is required"),
        data: Yup.string()
          .when("dataType", {
            is: (value) => value === "TEXT",
            then: Yup.string().required("Content is required"),
            otherwise: Yup.string(),
          })
          .when("dataType", {
            is: (value) => value === "PDF_UPLOAD",
            then: Yup.string().required("PDF is required"),
            otherwise: Yup.string(),
          }).when("dataType", {
            is: (value) => value === "PDF_LINK",
            then: Yup.string().required("PDF link is required"),
            otherwise: Yup.string(),
          }),
        title: Yup.string()
          .when("dataType", {
            is: (value) => value === "TEXT" || "PDF_UPLOAD" || "PDF_LINK",
            then: Yup.string().required("Title is required"),
            otherwise: Yup.string(),
          })
      })
    ),
    seoTitle: Yup.string().required("SEO title is required"),
    seoDescription: Yup.string().required("SEO description is required"),
    seoMetaTags: Yup.array().min(1, "SEO meta tags is required"),
  });

  const handleImageUpload = (file, section, fieldName, setFieldValue) => {
    setFileUploadLoading(fieldName);
    const payloadData = {
      files: file,
      sectionName: section,
    };
    dispatch(imageUploadPressRelease(payloadData))
      .unwrap()
      .then((result) => {
        console.log("RESULT ::>", result);
        let temp;
        if (result !== undefined) {
          const is5mb = !result?.data[0][0]?.url?.includes(
            "resized_1mb" || "resized_3mb" || "resized_5mb"
          );

          if (is5mb) {
            temp = result?.data?.map((item, index) => {
              return result?.data[index]?.map((dataItem) => {
                return dataItem;
              })[0];
            });
          } else {
            temp = result?.data?.map((item, index) => {
              return result?.data[index]
                ?.map((dataItem) => {
                  return dataItem;
                })
                .find((item) => item.url.includes("resized_3mb"));
            });
          }
        }
        if (get(result, "status", false)) {
          setFieldValue(fieldName, temp);
          setFileUploadLoading("");
        } else {
          toast.error(
            get(result, "message", "Something went wrong in upload image")
          );
          setFileUploadLoading("");
        }
      })
      .catch((err) => {
        console.log("ERR ::>", err);
        setFileUploadLoading("");
        throw err;
      });
  };

  const handlePDFUpload = useCallback(
    async (file, section, fieldName, setFieldValue) => {
      setFileUploadLoading(fieldName);
      const payloadData = {
        file: file,
        sectionName: section,
      };

      try {
        const authToken = cookieGet("auth_token");
        const userData = cookie.get("consultant_data");
        const authorization = authToken;

        await axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/admin/content/pdf/upload`,
            payloadData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: authorization,
              },
            }
          )
          .then((result) => {
            if (get(result, "status", false)) {
              setFieldValue(fieldName, get(result, "data.data.URL", ""));
              setFileUploadLoading("");
            } else {
              toast.error(
                get(result, "message", "Something went wrong in upload image")
              );
              setFileUploadLoading("");
            }
          });
      } catch (error) {
        setFileUploadLoading("");
      }
    },
    []
  );

  const handleSubmit = (values) => {
    let payloadData = {
      ...omit(values, ["subCategories"]),
      language: get(values, "language._id", ""),
      categories: getSpecializationSubmitData(
        values,
        values.subCategories,
        "categories",
        "subCategories"
      ),
      topSection: map(get(values, "topSection", []), (topSection) => {
        return omit(topSection, ["_id", "preferredOption", "link"]);
      }),
      contentSection: map(
        get(values, "contentSection", []),
        (contentSection) => {
          return omit(contentSection, ["_id", "mediaFile", "content"]);
        }
      ),
    };
    if (formType === "update") {
      payloadData = {
        ...payloadData,
        _id: get(articleDetail, "_id", ""),
      };
    }

    dispatch(createUpdatePressRelease(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          toast.success(get(result, "message", "Press created successfully."));
          navigate("/press-release-blog", {
            state: {
              tabIndex: get(location, "state.tabIndex", 0),
              apiType: get(location, "state.apiType", "ALL"),
            },
          });
        } else {
          toast.error(get(result, "message", "Something went wrong."));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    dispatch(getSpecializationData());
    dispatch(getLanguageConfigList());
  }, [dispatch]);

  return (
    <MDCard>
      <MDCardHeader
        title={`Press ${formType === "update" ? "Detail's Update" : "Create"}`}
      />
      <CardContent>
        <TimeOutComponent delay={2}>
         {specializationList.length > 0 && <Formik
            initialValues={{
              title: get(articleDetail, "title", ""),
              subTitle: get(articleDetail, "subTitle", ""),
              language: get(articleDetail, "language", null),
              categories: getInitSpecialization(
                articleDetail,
                specializationList,
                "categories"
              ),
              subCategories: getInitSubSpecialization(
                articleDetail,
                specializationList,
                "categories",
                "subCategories"
              ),
              topSection: get(articleDetail, "topSection", [
                {
                  imageType: "IMAGE_UPLOAD",
                  imageUrl: "",
                  title: "",
                },
              ]),
              contentSection: get(articleDetail, "contentSection", [
                {
                  preferredOption: "TEXT",
                  dataType: "TEXT",
                  data: "",
                  title: "",
                },
                {
                  preferredOption: "PDF",
                  dataType: "PDF_UPLOAD",
                  data: "",
                  title: "",
                },
              ]),
              isPublished: get(articleDetail, "isPublished", false),
              comments: get(articleDetail, "comments", false),
              likeStatus: get(articleDetail, "likeStatus", false),
              ratingStatus: get(articleDetail, "ratingStatus", false),
              seoTitle: get(articleDetail, "seoTitle", ""),
              seoDescription: get(articleDetail, "seoDescription", ""),
              seoMetaTags: get(articleDetail, "seoMetaTags", []),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <TextField
                          size="small"
                          label="Title"
                          name="title"
                          value={formProps.values.title}
                          // onChange={formProps.handleChange}
                          onChange={(e, value) => {
                            formProps.setFieldValue("title", e.target.value);
                            formProps.setFieldValue("seoTitle", e.target.value);
                            // props.handleChange()
                          }}
                          onBlur={formProps.handleBlur}
                          error={
                            formProps.errors.title && formProps.touched.title
                              ? true
                              : false
                          }
                          helperText={
                            formProps.errors.title && formProps.touched.title
                              ? formProps.errors.title
                              : null
                          }
                          fullWidth={true}
                          multiline={true}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <TextField
                          size="small"
                          label="Subtitle"
                          name="subTitle"
                          value={formProps.values.subTitle}
                          // onChange={formProps.handleChange}
                          onChange={(e, value) => {
                            formProps.setFieldValue("subTitle", e.target.value);
                            formProps.setFieldValue(
                              "seoDescription",
                              e.target.value
                            );
                            // props.handleChange()
                          }}
                          onBlur={formProps.handleBlur}
                          error={
                            formProps.errors.subTitle &&
                            formProps.touched.subTitle
                              ? true
                              : false
                          }
                          helperText={
                            formProps.errors.subTitle &&
                            formProps.touched.subTitle
                              ? formProps.errors.subTitle
                              : null
                          }
                          fullWidth={true}
                          multiline={true}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Autocomplete
                          options={languagesList ?? []}
                          name="language"
                          value={formProps.values.language}
                          getOptionLabel={(option) => option?.title ?? ""}
                          isOptionEqualToValue={(option, value) => {
                            if (option?._id === value?._id || option === "") {
                              return true;
                            }
                          }}
                          onChange={(_e, value) => {
                            formProps.setFieldValue("language", value);
                          }}
                          onBlur={formProps.handleBlur}
                          disablePortal
                          autoHighlight
                          size="small"
                          noOptionsText={"No Languages"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Language"
                              name="language"
                              error={
                                formProps.errors.language &&
                                formProps.touched.language
                                  ? true
                                  : false
                              }
                              helperText={
                                formProps.errors.language &&
                                formProps.touched.language
                                  ? formProps.errors.language
                                  : null
                              }
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <MultiSelectSpecialization
                        specializations={specializationList}
                        fieldName="categories"
                        subFieldName="subCategories"
                        formProps={formProps}
                        initialData={articleDetail}
                        isSubmit={false}
                        isDefaultSEOmetaTag={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <PressReleasedTopSection
                      formType={formType}
                      formProps={formProps}
                      handleImageUpload={handleImageUpload}
                      fileUploadLoading={fileUploadLoading}
                      checkImage={checkImage}
                      setCheckImage={setCheckImage}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <PressReleasedFormContent
                      formProps={formProps}
                      fileUploadLoading={fileUploadLoading}
                      formType={formType}
                      handlePDFUpload={handlePDFUpload}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem />
                  </Grid>

                  <Grid item xs={12}>
                    <PressReleasedSEOSection formProps={formProps} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    className="mt-1 mb-3"
                  >
                    <Grid item sm={6} xs={12}>
                      <MDLoadingButton
                        type="reset"
                        variant="outlined"
                        padding="6px 24px"
                        fullWidth={true}
                        onClick={(_event) => {
                          formProps.resetForm();
                        }}
                      >
                        Reset
                      </MDLoadingButton>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <MDLoadingButton
                        type="submit"
                        variant="contained"
                        padding="6px 24px"
                        fullWidth={true}
                        loading={formProps.isSubmitting && isLoading}
                      >
                        Submit
                      </MDLoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>}
        </TimeOutComponent>
      </CardContent>
    </MDCard>
  );
};

export default PressRealeasedBlogCreateUpdate;
