import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";

import {
  getLanguageConfigList,
  statusUpdateLanguage,
} from "store/slice/language-config/LanguageConfigSlice";

import MDCard from "components/md-components/card/MDCard";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDButton from "components/md-components/button/MDButton";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDSwitch from "components/md-components/switch/MDSwitch";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import AddLanguageForm from "components/pages/cms/language-config/AddLanguageForm";
import AddMicroForm from "components/pages/cms/language-config/AddMacroForm";
import MDCardHeader from "components/md-components/card/MDCardHeader";

const LanguageConfigList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.languageConfig.loading);
  const languageListData = useSelector(
    (state) => state.languageConfig.languageList
  );

  const [dialogData, setDialogData] = useState({
    id: "",
    rowData: [],
    dialogTitle: "",
  });
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const handleLanguageDialogOpen = useCallback(() => {
    setLanguageDialogOpen(true);
  }, []);
  const handleLanguageDialogClose = () => {
    setLanguageDialogOpen(false);
  };
  const [microDialogOpen, setMicroDialogOpen] = useState(false);
  const handleMicroDialogOpen = useCallback(() => {
    setMicroDialogOpen(true);
  }, []);
  const handleMicroDialogClose = () => {
    setMicroDialogOpen(false);
  };

  const handleStatusUpdate = useCallback(
    (event, params) => {
      setDialogData({ ...dialogData, id: params.row._id });
      const apiData = {
        id: params.row._id,
        data: { status: event.target.checked },
      };
      dispatch(statusUpdateLanguage(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            toast.success(get(result, "message", "Status update successful"));
            dispatch(getLanguageConfigList());
          } else {
            toast.error(get(result, "message", "Something went wrong"));
          }
        })
        .catch((err) => {
          toast.error(get(err, "message", "Something went wrong"));
          throw err;
        });
    },
    [dialogData, dispatch]
  );

  useEffect(() => {
    dispatch(getLanguageConfigList());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        width: 240,
        editable: false,
      },
      {
        field: "displayTitle",
        headerName: "Display Title",
        width: 240,
        editable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: (params) => {
          return (
            <Box className="w-100 d-flex justify-content-evenly align-item-center gao-3">
              <MDButton
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  navigate(`/language-config/macro-listing`, {
                    state: {
                      languageId: params.row?._id,
                      languageTitle: params.row?.title,
                    },
                  });
                }}
                aria-label={`${params.row.title}-edit-button`}
              >
                Translate
              </MDButton>
              <MDSwitch
                checked={params.row.status}
                onChange={(event) => {
                  handleStatusUpdate(event, params);
                }}
                inputProps={{
                  "aria-label": `${params.row.title}-status`,
                  role: "switch",
                }}
                color="success"
                disabled={dialogData.id === params.id && isLoading}
              />
            </Box>
          );
        },
      },
    ],
    [dialogData.id, handleStatusUpdate, isLoading, navigate]
  );

  const CustomToolbar = () => {
    return (
      <MDDataGridToolbar divider={true}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="flex-end"
          spacing={{ sm: 2, xs: 1 }}
        >
          <MDButton
            variant="contained"
            label="Add Language"
            startIcon={<AddCircleOutlineIcon />}
            fontSize="14px"
            className="text-nowrap"
            onClick={() => {
              setDialogData({ ...dialogData, dialogTitle: "Add Language" });
              handleLanguageDialogOpen();
            }}
          />
          <MDButton
            variant="contained"
            label="Add Micro"
            startIcon={<AddCircleOutlineIcon />}
            fontSize="14px"
            className="text-nowrap"
            onClick={() => {
              setDialogData({ ...dialogData, dialogTitle: "Add Micro" });
              handleMicroDialogOpen();
            }}
          />
        </Stack>
      </MDDataGridToolbar>
    );
  };

  return (
    <MDCard>
      <MDCardHeader title="Language Config" />
      <CardContent className="h-100">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MDDataGrid
              rows={!isEmpty(languageListData) ? languageListData : []}
              columns={columnsData}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              autoHeight
            />
            <AddLanguageForm
              title={dialogData.dialogTitle}
              dialogData={dialogData}
              open={languageDialogOpen}
              handleClose={handleLanguageDialogClose}
            />
            <AddMicroForm
              title={dialogData.dialogTitle}
              dialogData={dialogData}
              open={microDialogOpen}
              handleClose={handleMicroDialogClose}
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default LanguageConfigList;
