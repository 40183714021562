import { Formik } from 'formik';
import { get, isEmpty, cloneDeep, uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDDialog from 'components/md-components/dialog/MDDialog';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import {
	createUserGeneralConfig,
	getUserGeneralConfig,
	updateUserGeneralConfig,
} from 'store/slice/user-config/GeneralConfigSlice';
import { Avatar, Box, FormLabel, IconButton, Stack } from '@mui/material';
import ImageUpload from 'components/common/ImageUpload';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from 'components/common/MultiSelectSpecialization';
import { getSpecializationData } from 'store/slice/common/GeneralConfigDataSlice';
import LanguageTranslationForm, { languageInitialValue } from './LanguageTranslationForm';

const getInitialData = (sectionName, dialogData, iconURL, languageConfigList, specializationList) => {
	const isPermitted = ['GOAL_TYPE', 'SPECIALIZATION', 'SOCIAL_MEDIA_CHANNELS', 'BENEFITS', 'CONTENT'].includes(sectionName);
	const languageList = get(dialogData, 'rowData.languages', []);
	const contentList = get(dialogData, 'rowData.content', []);

	return {
		icon:
			isPermitted && !isEmpty(dialogData?.rowData?.icon)
				? get(dialogData, 'rowData.icon', '')
				: !isEmpty(iconURL) && iconURL,
		title: get(dialogData, 'rowData.title', ''),
		slug: get(dialogData, 'rowData.slug', ''),
		displayTitle: get(dialogData, 'rowData.displayTitle', ''),
		status: get(dialogData, 'rowData.status', false),
		languages: languageInitialValue(languageConfigList, languageList, 'common', contentList),
		categories: sectionName === 'GOAL_TYPE' && getInitSpecialization(dialogData?.rowData, specializationList, 'categories'),
		subCategories:
			sectionName === 'GOAL_TYPE' &&
			getInitSubSpecialization(dialogData?.rowData, specializationList, 'categories', 'subCategories'),
	};
};

const GeneralConfigForm = (props) => {
	const { dialogOpen, handleDialogClose, subSpecializationField, dialogData, sectionName, level, parentId } = props;

	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.generalConfig.loading);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const specializationList = useSelector((state) => state.generalConfigData.specializationData);

	const [open, setOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const [iconURL, setIconURL] = useState('');
	const [languageList, setLanguageList] = useState([]);

	useEffect(() => {
		if (languageConfigList?.length) {
			setLanguageList(languageConfigList.filter((e) => e.status));
		} else {
			setLanguageList(languageConfigList);
		}
	}, [languageConfigList]);

	const ValidationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required.'),
		displayTitle: subSpecializationField && Yup.string().required('Display title is required.'),
		languages: Yup.array().of(
			Yup.object().shape({
				value: Yup.string().required('Value is required'),
				description: Yup.string().required('Value is required'),
				footerTitle: Yup.string().required('Value is required'),
				footerDescription: Yup.string().required('Value is required'),
				seoTitle: Yup.string().required('Value is required'),
				seoDescription: Yup.string().required('Value is required'),
				seoMetaTags: Yup.array().of(Yup.string().required('Value is required')),
			})
		),
		categories: sectionName === 'GOAL_TYPE' && Yup.array().min(1, 'Categories is required'),
	});

	const handleDialogOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (formValues, { resetForm }) => {
		const values = cloneDeep(formValues);

		const getQuery = {
			sectionName: sectionName,
			level: level,
		};

		const data = {
			title: values.title,
			languages: values.languages.map((e) => ({
				value: e.value,
				languageId: e.languageId,
				type: e.type,
				isTranslate: e.isTranslate,
			})),
			status: values.status,
			slug: values.slug,
			sectionName: sectionName,
			level: level,
			content: values.languages.map((e) => ({
				title: e.value,
				description: e.description,
				footerTitle: e.footerTitle,
				footerSubTitle: e.footerSubTitle,
				footerDescription: e.footerDescription,
				seoTitle: e.seoTitle,
				seoDescription: e.seoDescription,
				seoMetaTags: e.seoMetaTags,
				descriptionImgTitle: e.descriptionImgTitle,
				descriptionImgUrl: e.descriptionImgUrl,
				addInFooter: Boolean(e.addInFooter),
				addBlogPage: Boolean(e.addBlogPage),
				language: e.languageId,
			})),
		};

		if (!isEmpty(values?.icon)) {
			data.icon = values.icon;
		}
		if (!isEmpty(values.categories)) {
			data.categories = getSpecializationSubmitData(values, values.subCategories, 'categories', 'subCategories');
		}

		if (subSpecializationField === true) {
			data.displayTitle = values.displayTitle;
			data.parentId = !isEmpty(parentId) ? parentId : '';
		}

		const formData = new FormData();
		data.content.forEach((contentDetails) => {
			contentDetails.descriptionImgUrl?.forEach((item) => {
				if (item.file) {
					const id = uniqueId();
					formData.append(id, item.file, `file.${item.file.type?.split('/').pop()}`);
					item.file = id;
				}
			});
		});
		formData.append('content', JSON.stringify(data));

		if (dialogData.formType === 'create') {
			dispatch(createUserGeneralConfig(formData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						toast.success('Data submitted successfully');
						dispatch(getUserGeneralConfig(getQuery));
						handleDialogClose();
						resetForm();
						setIconURL('');
					} else {
						toast.error('Something went wrong');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (dialogData.formType === 'update') {
			const updateData = {
				id: get(dialogData, 'id', ''),
				data: formData,
			};
			dispatch(updateUserGeneralConfig(updateData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						toast.success('Data update successfully');
						dispatch(getUserGeneralConfig(getQuery));
						handleDialogClose();
						resetForm();
						setIconURL('');
					} else {
						toast.error('Something went wrong');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	useEffect(() => {
		dispatch(getLanguageConfigList());
		dispatch(getSpecializationData());
	}, [dispatch]);

	return (
		<MDDialog title={dialogData.title} open={dialogOpen} close={handleDialogClose} maxWidth='md' fullWidth={true}>
			{isRefresh && (
				<Formik
					initialValues={getInitialData(sectionName, dialogData, iconURL, languageList, specializationList)}
					validationSchema={ValidationSchema}
					onSubmit={handleSubmit}
				>
					{(formProps) => (
						<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto'>
							<DialogContent dividers={true}>
								<Grid container spacing={2}>
									{(sectionName === 'GOAL_TYPE' ||
										sectionName === 'SPECIALIZATION' ||
										sectionName === 'SOCIAL_MEDIA_CHANNELS' ||
										sectionName === 'BENEFITS' ||
										sectionName === 'CONTENT') && (
										<Grid item xs={12} sm={3}>
											<FormControl fullWidth>
												<FormLabel htmlFor='age' className='mb-1'>
													Upload Icon
												</FormLabel>
												<Stack direction='row' alignItems='center' spacing={2}>
													<Box className='position-relative'>
														<Avatar
															name='icon'
															variant='square'
															src={formProps.values.icon}
															alt={get(dialogData, 'icon', '')}
															sx={{
																width: 96,
																height: 96,
																bgcolor: 'primary.main',
															}}
														/>
														<IconButton
															onClick={handleDialogOpen}
															className='position-absolute right-0 bottom-0'
															sx={{
																backgroundColor: 'common.white',
																'&:hover': {
																	backgroundColor: 'grey.200',
																},
															}}
														>
															<PhotoCamera />
														</IconButton>
													</Box>
													<ImageUpload
														formProps={formProps}
														open={open}
														setOpen={setOpen}
														handleClose={handleClose}
														setIsRefresh={setIsRefresh}
														dialogData={dialogData}
														setIconURL={setIconURL}
													/>
												</Stack>
											</FormControl>
										</Grid>
									)}

									{sectionName === 'GOAL_TYPE' && (
										<Grid item xs={12}>
											<MultiSelectSpecialization
												specializations={specializationList}
												fieldName='categories'
												subFieldName='subCategories'
												formProps={formProps}
												initialData={dialogData}
												isSubmit={false}
											/>
										</Grid>
									)}

									<Grid item xs={12}>
										<TextField
											fullWidth
											size='small'
											label='Title'
											name='title'
											value={formProps.values.title}
											error={formProps.errors.title && formProps.touched.title ? true : false}
											helperText={
												formProps.errors.title && formProps.touched.title ? formProps.errors.title : null
											}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											size='small'
											label='Slug'
											name='slug'
											value={formProps.values.slug}
											error={formProps.errors.slug && formProps.touched.slug ? true : false}
											helperText={
												formProps.errors.slug && formProps.touched.slug ? formProps.errors.slug : null
											}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
										/>
									</Grid>
									{subSpecializationField && (
										<Grid item xs={12}>
											<TextField
												fullWidth={true}
												size='small'
												label='Display Title'
												name='displayTitle'
												value={formProps.values.displayTitle}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={
													formProps.errors.displayTitle && formProps.touched.displayTitle ? true : false
												}
												helperText={
													formProps.errors.displayTitle && formProps.touched.displayTitle
														? formProps.errors.displayTitle
														: null
												}
											/>
										</Grid>
									)}
									<Grid item xs={6}>
										<FormControl component='fieldset' variant='standard'>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															name='status'
															checked={formProps.values.status}
															value={formProps.values.status}
															onChange={(e) => {
																formProps.setFieldValue('status', e.target.checked);
															}}
															inputProps={{ 'aria-label': 'Status' }}
														/>
													}
													label='Status'
													sx={{ mr: 0 }}
												/>
											</FormGroup>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<LanguageTranslationForm formProps={formProps} languageConfigList={languageList} />
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<MDLoadingButton
									type='submit'
									variant='contained'
									padding='6px 24px'
									loading={props.isSubmitting && isLoading}
								>
									Submit
								</MDLoadingButton>
							</DialogActions>
						</form>
					)}
				</Formik>
			)}
		</MDDialog>
	);
};

export default GeneralConfigForm;
