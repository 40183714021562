import { map } from "lodash";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

import MDRadio from "components/md-components/form/MDRadio";
import MDSwitch from "components/md-components/switch/MDSwitch";

const RecipeFormDietAndCategory = ({ formProps, dietaryPreferenceData }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <FormControl
            fullWidth
            className="gap-2"
            error={Boolean(
              formProps.errors.dietPreference &&
                formProps.touched.dietPreference
            )}
          >
            <FormLabel id="dietary-preference-group-label">
              Dietary Preference
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="dietary-preference-group-label"
              name="dietPreference"
              value={formProps.values.dietPreference}
              onChange={formProps.handleChange}
              className="ms-2 gap-3"
            >
              {map(dietaryPreferenceData, (data, index) => (
                <FormControlLabel
                  key={index}
                  value={data?._id}
                  control={<MDRadio />}
                  label={data?.title}
                  className="ms-0 me-0"
                />
              ))}
            </RadioGroup>
            <FormHelperText>{formProps.errors.dietPreference}</FormHelperText>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} rowSpacing={0}>
          <Typography variant="subtitle1" fontSize={18} fontWeight={500}>
            Category
          </Typography>
        </Grid>
        {map(categoryList, (item, index) => {
          return (
            <Grid item md={3} sm={4} xs={6} key={index}>
              <FormControl fullWidth={true} className="gap-1">
                <FormLabel id={item?.name}>{item?.label}</FormLabel>
                <MDSwitch
                  checked={formProps.values[item?.name]}
                  onChange={(event) => {
                    formProps.setFieldValue(item?.name, event.target.checked);
                  }}
                  inputProps={{
                    "aria-label": `${item?.name}-status`,
                    role: "switch",
                  }}
                />
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default RecipeFormDietAndCategory;

const categoryList = [
  { name: "glutenFree", label: "Gluten Free" },
  { name: "nutsFree", label: "Nuts Free" },
  { name: "dairyFree", label: "Dairy Free" },
  { name: "sugarFree", label: "Sugar Free" },
  { name: "lowFODMAPDiet", label: "Low FODMAP Diet" },
  { name: "salads", label: "Salads" },
  { name: "pregnancyFriendly", label: "Pregnancy Friendly" },
  { name: "lowSugar", label: "Low Sugar" },
  { name: "highProtein", label: "High Protein" },
  { name: "lowGIDiet", label: "Low GI Diet" },
  { name: "intermittentDiet", label: "Intermittent Diet" },
];
