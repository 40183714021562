import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { filter, get, isEmpty } from "lodash";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDButton from "components/md-components/button/MDButton";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import DataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import MDSwitch from "components/md-components/switch/MDSwitch";
import {
  deleteUserGeneralConfig,
  getUserGeneralConfig,
  statusUpdateUserGeneralConfig,
} from "store/slice/user-config/GeneralConfigSlice";
import GeneralConfigForm from "./GeneralConfigForm";
import { Avatar, Stack } from "@mui/material";

const GeneralConfigList = (props) => {
  const { title, handleDialogOpen, sectionName, level, parentId } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.generalConfig.loading);
  const getDataList = useSelector((state) => state.generalConfig.list);

  const filterData = filter(getDataList, (item) => {
    if (!isEmpty(parentId)) {
      return item.parentId === parentId && item.isDeleted !== true;
    } else {
      return item.isDeleted !== true;
    }
  });

  const getAPIPayload = useMemo(() => {
    return {
      sectionName: sectionName,
      level: level,
    };
  }, [level, sectionName]);
  const [columnVisibilityModel] = useState({
    title: true,
    createdAt: true,
    subSpecialization: title === "Specialization" ? true : false,
    actions: true,
  });
  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    rowData: [],
    formType: "",
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const updateStatus = useCallback(
    (event, row) => {
      setDialogData({ ...dialogData, id: row._id });

      const updateValues = {
        id: row._id,
        data: { status: event.target.checked },
      };
      dispatch(statusUpdateUserGeneralConfig(updateValues))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "Status update successful"));
            dispatch(getUserGeneralConfig(getAPIPayload));
          } else {
            toast.error(get(result, "message", "Something went wrong"));
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    [dialogData, dispatch, getAPIPayload]
  );

  const handleDelete = useCallback(
    ({ event, id }) => {
      const updateValues = { id: id, data: { isDeleted: event } };
      dispatch(deleteUserGeneralConfig(updateValues))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "Deleted successful"));
            dispatch(getUserGeneralConfig(getAPIPayload));
          } else {
            toast.error(get(result, "message", "Something went wrong"));
          }
        })
        .catch((err) => {
          throw err;
        });
      handleDeleteConfirmationClose();
    },
    [dispatch, getAPIPayload]
  );

  useEffect(() => {
    dispatch(getUserGeneralConfig(getAPIPayload));
  }, [dispatch, getAPIPayload]);

  const columnsData = useMemo(
    () => [
      // {
      //   field: "icon",
      //   headerName: "Icon",
      //   width: 250,
      //   editable: false,
      //   renderCell: ({ row }) => (
      //     <Stack direction="row" spacing={2} alignItems="center">
      //       <Avatar
      //         alt={row?.title}
      //         src={row?.icon}
      //         sx={{
      //           // backgroundColor: stringToColor(
      //           //   get(row, "title", "NA")
      //           // ),
      //           width: 38,
      //           height: 38,
      //         }}
      //       />
      //     </Stack>
      //   ),
      // },
      {
        field: "title",
        headerName: "Title",
        width: 240,
        editable: false,
      },
      {
        field: "createdAt",
        headerName: "Create Date",
        type: "date",
        width: 150,
        editable: false,
        valueGetter: ({ row }) =>
          moment(get(row, "createdAt", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "subSpecialization",
        headerName: "Sub Specialization",
        width: 180,
        renderCell: ({ row }) => {
          return (
            <Box className="w-100 text-center">
              <MDButton
                label="Sub Specialization"
                type="Button"
                variant="contained"
                padding="4px 12px"
                fontSize={14}
                onClick={() => {
                  navigate("sub-specialization", {
                    state: { id: row._id },
                  });
                }}
              />
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: ({ row }) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: row?._id,
                    rowData: row,
                    title: `${title} Update`,
                    formType: "update",
                  });
                  handleDialogOpen();
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={row?.status}
                onChange={(event) => {
                  updateStatus(event, row);
                }}
                inputProps={{ "aria-label": row?._id, role: "switch" }}
                color="success"
                disabled={get(dialogData, "id", "") === row?._id && isLoading}
              />
              <IconButton
                aria-label={`${row?._id}-delete`}
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: row?._id,
                    rowData: row,
                    title: `${title} Delete`,
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      dialogData,
      handleDeleteConfirmation,
      handleDialogOpen,
      isLoading,
      navigate,
      title,
      updateStatus,
    ]
  );

  const addIconCol = {
    field: "icon",
    headerName: "Icon",
    width: 200,
    editable: false,
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar
          alt={row?.title}
          src={row?.icon}
          variant="square"
          sx={{
            width: 38,
            height: 38,
          }}
        />
      </Stack>
    ),
  };
  // eslint-disable-next-line no-unused-expressions
  (sectionName === "GOAL_TYPE" ||
    sectionName === "SPECIALIZATION" ||
    sectionName === "SOCIAL_MEDIA_CHANNELS" ||
    sectionName === "BENEFITS" ||
    sectionName === "CONTENT") &&
    columnsData.unshift(addIconCol);

  const CustomToolbar = () => {
    return (
      <DataGridToolbar
        align="right"
        divider={true}
        DisableToolbarColumnsButton={true}
      >
        <MDButton
          variant="contained"
          label={`Create New`}
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            setDialogData({
              ...dialogData,
              id: "",
              rowData: "",
              title: `${title} Create`,
              formType: "create",
            });
            handleDialogOpen();
          }}
        />
      </DataGridToolbar>
    );
  };

  return (
    <>
      <MDDataGrid
        rows={!isEmpty(filterData) ? filterData : []}
        columns={columnsData}
        getRowId={(row) => row._id}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick={true}
        loading={isLoading}
        columnVisibilityModel={columnVisibilityModel}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: EmptyTableRowOverlay,
          LoadingOverlay: LinearProgress,
        }}
        autoHeight
      />
      <GeneralConfigForm dialogData={dialogData} {...props} />
      <DeleteConfirmDialog
        title={`${title} Delete Confirmation`}
        open={deleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteAction={() => {
          handleDelete({ event: true, id: get(dialogData, "id", "") });
        }}
      />
    </>
  );
};

export default GeneralConfigList;
