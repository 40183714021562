import { map } from "lodash";
import { useMemo } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

const OTPInput = (props) => {
  const {
    value,
    valueLength,
    onChange,
    onBlur,
    error,
    helperText,
    fieldProps,
  } = props;

  const RE_DIGIT = useMemo(() => new RegExp(/^\d+$/), []);

  const valueItems = useMemo(() => {
    const valueArray = value?.split("");
    const items = [];

    const length = valueLength || 6;

    if (valueArray) {
      for (let i = 0; i < length; i++) {
        const char = valueArray[i];

        if (RE_DIGIT.test(char)) {
          items.push(char);
        } else {
          items.push("");
        }
      }
    }

    return items;
  }, [RE_DIGIT, value, valueLength]);

  const focusToNextInput = (idx) => {
    const nextElementSibling = document.querySelector(
      `input[name=OTP_Input_${idx + 1}]`
    );
    if (nextElementSibling !== null) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (idx) => {
    const previousElementSibling = document.querySelector(
      `input[name=OTP_Input_${idx - 1}]`
    );
    if (previousElementSibling !== null) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (event, idx) => {
    const target = event.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(idx);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);

      target.blur();
    }
  };

  const inputOnKeyDown = (event, idx) => {
    const { key } = event;
    const target = event.target;

    if (key === "ArrowRight" || key === "ArrowDown") {
      event.preventDefault();
      return focusToNextInput(idx);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      event.preventDefault();
      return focusToPrevInput(idx);
    }

    const targetValue = target.value;

    if (key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(idx);
  };

  const inputOnFocus = (event) => {
    const { target } = event;

    const prevInputEl = target.previousElementSibling || null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={{ sm: 2, xs: 1.5 }}
        justifyContent="center"
      >
        {map(valueItems, (field, idx) => {
          return (
            <Box key={idx}>
              <TextField
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                pattern="\d{1}"
                maxLength={valueLength}
                name={`OTP_Input_${idx}`}
                value={field}
                onChange={(event) => {
                  inputOnChange(event, idx);
                }}
                onKeyDown={(event) => {
                  inputOnKeyDown(event, idx);
                }}
                onFocus={inputOnFocus}
                onBlur={onBlur}
                error={error}
                sx={{
                  width: { md: 60, sm: 48, xs: 32 },
                }}
                inputProps={{
                  className: "text-center",
                  sx: {
                    padding: {
                      md: "16.5px 14px",
                      sm: "12px 12px",
                      xs: "6px 8px",
                    },
                  },
                }}
                {...fieldProps}
              />
            </Box>
          );
        })}
      </Stack>
      <FormHelperText error={error} className="text-center mt-2">
        {helperText}
      </FormHelperText>
    </Box>
  );
};

export default OTPInput;
