import React from "react";
import Button from "@mui/material/Button";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get, omit } from "lodash";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { map } from "lodash";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  createAdminRole,
  getAdminRoles,
  updateAdminRole,
} from "store/slice/role-management/AdminRoleSlice";
import { ROLE_PERMISSIONS } from "components/utility/constant";
import { isEmpty } from "lodash";

const AddRoleForm = (props) => {
  const { open, onClose, dialogData, handleDialogClose } = props;
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isLoading = useSelector((state) => state.adminRoles.loading);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    description: Yup.string().required("Description is required."),
  });

  const handleSubmit = (values) => {
    console.log("values :>> ", values);
    if (dialogData.formType === "create") {
      dispatch(createAdminRole(values))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "Role created successfully"));
            dispatch(getAdminRoles());
            handleDialogClose();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          throw err;
        });
    } else if (dialogData.formType === "update") {
      const updateData = {
        id: get(dialogData, "id", ""),
        data: omit(values, "status", "isDeleted"),
      };
      dispatch(updateAdminRole(updateData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "User updated successfully"));
            dispatch(getAdminRoles());
            handleDialogClose();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  return (
    <>
      <Drawer anchor="right" variant="temporary" open={open} onClose={onClose}>
        <div className="service-plan-add-container">
          <div className="service-plan-add-header">
            <Button onClick={onClose}>
              <ArrowBackIosIcon />
            </Button>
            <h2 style={{ marginRight: "20px" }}>Create Role</h2>
          </div>
          <Formik
            initialValues={{
              title: get(dialogData, "rowData.title", ""),
              description: get(dialogData, "rowData.description", ""),
              status: get(dialogData, "rowData.status", false),
              // rolePermission: get(dialogData, "rowData.rolePermission", []),
              rolePermission: !isEmpty(dialogData?.rowData)
                ? get(dialogData, "rowData.rolePermission", [])
                : ROLE_PERMISSIONS,
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                className="d-flex flex-column overflow-auto"
              >
                <DialogContent dividers={true}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Title"
                        name="title"
                        value={props.values.title}
                        error={
                          props.errors.title && props.touched.title
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.title && props.touched.title
                            ? props.errors.title
                            : null
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth={true}
                        multiline={true}
                        minRows={3}
                        size="small"
                        label="Description"
                        name="description"
                        value={props.values.description}
                        error={
                          props.errors.description && props.touched.description
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.description && props.touched.description
                            ? props.errors.description
                            : null
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="status"
                                checked={props.values.status}
                                value={props.values.status}
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "status",
                                    e.target.checked
                                  );
                                }}
                                inputProps={{ "aria-label": "Status" }}
                              />
                            }
                            label="Status"
                            sx={{ mr: 0 }}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ overflowX: "auto" }}>
                      <TableContainer
                        sx={{ border: `1px solid ${palette.grey[300]}` }}
                      >
                        <Table
                          stickyHeader={true}
                          aria-label="Role Assign Table"
                        >
                          <TableHead>
                            <TableRow
                              sx={{ "& .MuiTableCell-root": { padding: 1 } }}
                            >
                              <TableCell colSpan={3}>Roles</TableCell>
                              <TableCell align="center">Read</TableCell>
                              <TableCell align="center">Write</TableCell>
                              <TableCell align="center">Update</TableCell>
                              <TableCell align="center">Delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {map(
                              props?.values?.rolePermission,
                              (item, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    "& .MuiTableCell-root": { py: 0.5, px: 1 },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    colSpan={3}
                                  >
                                    {item.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      checked={item.read}
                                      onChange={props.handleChange}
                                      inputProps={{
                                        "aria-label": "controlled",
                                        name: `rolePermission.${index}.read`,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      checked={item.write}
                                      onChange={props.handleChange}
                                      inputProps={{
                                        "aria-label": "controlled",
                                        name: `rolePermission.${index}.write`,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      checked={item.update}
                                      onChange={props.handleChange}
                                      inputProps={{
                                        "aria-label": "controlled",
                                        name: `rolePermission.${index}.update`,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      checked={item.delete}
                                      onChange={props.handleChange}
                                      inputProps={{
                                        "aria-label": "controlled",
                                        name: `rolePermission.${index}.delete`,
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <MDLoadingButton
                    type="submit"
                    variant="contained"
                    padding="6px 24px"
                    loading={props.isSubmitting && isLoading}
                  >
                    Submit
                  </MDLoadingButton>
                </DialogActions>
              </form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};

export default AddRoleForm;
