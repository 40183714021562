import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { get, isEmpty } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import LanguageTranslationForm, {
  languageInitialValue,
} from "components/common/LanguageTranslationForm";
import TimeOutComponent from "components/common/TimeOutComponent";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";

import {
  createIdentityProof,
  updateIdentityProof,
} from "store/slice/country-profile/IdentityProofSlice";
import { getLanguageConfigList } from "store/slice/language-config/LanguageConfigSlice";

const CountryIdentityProofForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.countryIdentityProof.loading);
  const languageConfigList = useSelector(
    (state) => state.languageConfig.languageList
  );

  const identityProofData = get(location, "state.identityProofData", {});
  const formType = get(location, "state.formType", "");
  const countryProfileId = get(location, "state.countryProfileId", "");
  const countryId = get(location, "state.countryId", "");

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    titleLanguages: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Value is required"),
      })
    ),
    descriptionLanguages: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("Value is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    let payloadData = {
      countryProfileId: countryProfileId,
      data: values,
    };

    if (formType === "create") {
      dispatch(createIdentityProof(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(
              get(result, "message", "Identity create successfully")
            );
            navigate("/country-profile/identity-proof-list", {
              state: {
                countryProfileId: countryProfileId,
                countryId: countryId,
              },
            });
          } else {
            toast.error(get(result, "message", "Something went wrong."));
          }
        })
        .catch((err) => {
          throw err;
        });
    } else if (formType === "update") {
      payloadData = {
        ...payloadData,
        identityProofId: get(identityProofData, "_id", ""),
      };

      dispatch(updateIdentityProof(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(
              get(result, "message", "Identity update successfully")
            );
            navigate("/country-profile/identity-proof-list", {
              state: {
                countryProfileId: countryProfileId,
                countryId: countryId,
              },
            });
          } else {
            toast.error(get(result, "message", "Something went wrong."));
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  useEffect(() => {
    dispatch(getLanguageConfigList());
  }, [dispatch]);

  return (
    <MDCard>
      <MDCardHeader title="Identity Proof Create Update Form" />
      <CardContent>
        <TimeOutComponent delay={2} isEmpty={isEmpty(languageConfigList)}>
          <Formik
            initialValues={{
              title: get(identityProofData, "title", ""),
              titleLanguages: languageInitialValue(
                languageConfigList,
                get(identityProofData, "titleLanguages", []),
                "common"
              ),
              descriptionLanguages: languageInitialValue(
                languageConfigList,
                get(identityProofData, "descriptionLanguages", []),
                "common"
              ),
              status: get(identityProofData, "status", false),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formProps) => (
              <form
                onSubmit={formProps.handleSubmit}
                className="d-flex flex-column overflow-auto"
              >
                {console.log("FORM_PROPS ::>>", formProps)}
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            Title :
                          </FormLabel>
                        </Box>
                        <TextField
                          fullWidth
                          size="small"
                          label="Title"
                          name="title"
                          value={formProps.values.title}
                          helperText={
                            formProps.errors.title && formProps.touched.title
                              ? formProps.errors.title
                              : null
                          }
                          error={
                            formProps.errors.title && formProps.touched.title
                              ? true
                              : false
                          }
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            Title Multi Languages :
                          </FormLabel>
                        </Box>
                        <LanguageTranslationForm
                          fieldName="titleLanguages"
                          formProps={formProps}
                          languageConfigList={languageConfigList}
                          inputField="text-input"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider flexItem={true} className="my-2" />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Box mb={1.5}>
                          <FormLabel sx={{ color: "grey.800" }}>
                            Description Multi Languages :
                          </FormLabel>
                        </Box>
                        <LanguageTranslationForm
                          fieldName="descriptionLanguages"
                          formProps={formProps}
                          languageConfigList={languageConfigList}
                          inputField="text-editor"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="status"
                                checked={formProps.values.status}
                                value={formProps.values.status}
                                onChange={(e) => {
                                  formProps.setFieldValue(
                                    "status",
                                    e.target.checked
                                  );
                                }}
                                inputProps={{
                                  "aria-label": "status",
                                }}
                              />
                            }
                            label="Status"
                            sx={{ mr: 0 }}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Stack
                          direction={{ sm: "row", xs: "column" }}
                          className="gap-3"
                        >
                          <MDLoadingButton
                            label="Cancel"
                            variant="outlined"
                            fullWidth={true}
                            onClick={() => {
                              navigate("/country-profile/identity-proof-list");
                            }}
                          />
                          <MDLoadingButton
                            type="submit"
                            label="Submit"
                            variant="contained"
                            fullWidth={true}
                            loading={formProps.isSubmitting && isLoading}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </TimeOutComponent>
      </CardContent>
    </MDCard>
  );
};

export default CountryIdentityProofForm;
