import { useState } from "react";
import { map } from "lodash";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import TabPanel from "components/md-components/tab/TabPanel";
import GeneralConfigList from "components/pages/cms/user-config/general-config/GeneralConfigList";

function a11yProps(index) {
  return {
    id: `general-config-tab-${index}`,
    "aria-controls": `general-config-tab-panel-${index}`,
  };
}

const GeneralConfig = () => {
  const theme = useTheme();
  const { palette } = theme;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const tabs = [
    // {
    //   title: "Dietary Preference",
    //   dialogOpen: dialogOpen,
    //   handleDialogOpen: handleDialogOpen,
    //   handleDialogClose: handleDialogClose,
    //   sectionName: "DIETARY_PREFERENCE",
    //   level: 1,
    // },
    {
      title: "Goal Type",
      dialogOpen: dialogOpen,
      handleDialogOpen: handleDialogOpen,
      handleDialogClose: handleDialogClose,
      sectionName: "GOAL_TYPE",
      level: 1,
    },
    {
      title: "Support Ticket Options",
      dialogOpen: dialogOpen,
      handleDialogOpen: handleDialogOpen,
      handleDialogClose: handleDialogClose,
      sectionName: "SUPPORT_TICKET_OPTION",
      level: 1,
    },
    {
      title: "Specialization",
      dialogOpen: dialogOpen,
      handleDialogOpen: handleDialogOpen,
      handleDialogClose: handleDialogClose,
      sectionName: "SPECIALIZATION",
      level: 1,
    },
    {
      title: "Social Media Channels",
      dialogOpen: dialogOpen,
      handleDialogOpen: handleDialogOpen,
      handleDialogClose: handleDialogClose,
      sectionName: "SOCIAL_MEDIA_CHANNELS",
      level: 1,
    },
    {
      title: "Benefits",
      dialogOpen: dialogOpen,
      handleDialogOpen: handleDialogOpen,
      handleDialogClose: handleDialogClose,
      sectionName: "BENEFITS",
      level: 1,
    },{
      title: "Content",
      dialogOpen: dialogOpen,
      handleDialogOpen: handleDialogOpen,
      handleDialogClose: handleDialogClose,
      sectionName: "CONTENT",
      level: 1,
    }
    // {
    //   title: "Allergies Type",
    //   dialogOpen: dialogOpen,
    //   handleDialogOpen: handleDialogOpen,
    //   handleDialogClose: handleDialogClose,
    //   sectionName: "ALLERGIES_TYPE",
    //   level: 1,
    // },
    // {
    //   title: "Food Allergies / Intolerance",
    //   dialogOpen: dialogOpen,
    //   handleDialogOpen: handleDialogOpen,
    //   handleDialogClose: handleDialogClose,
    //   sectionName: "FOOD_ALLERGIES",
    //   level: 1,
    // },
    // {
    //   title: "Cuisine",
    //   dialogOpen: dialogOpen,
    //   handleDialogOpen: handleDialogOpen,
    //   handleDialogClose: handleDialogClose,
    //   sectionName: "CUISINE",
    //   level: 1,
    // },
  ];

  return (
    <MDCard>
      <MDCardHeader title="General Config" />
      <CardContent className="h-100">
        <Grid container spacing={0} className="h-100 align-content-start">
          <Grid item xs={12}>
            <Box sx={{ bgcolor: palette.background.default }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="signup config tabs"
              >
                {map(tabs, (item, index) => (
                  <Tab label={item.title} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {map(tabs, (item, index) => (
                <TabPanel key={index} value={value} index={index}>
                  <GeneralConfigList {...item} index={index} />
                </TabPanel>
              ))}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default GeneralConfig;
