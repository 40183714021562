import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { get } from "lodash";
import {
  deleteConsultantUser,
  getVerifiedConsultantUsers,
  statusUpdateConsultantUser,
} from "store/slice/user-management/ConsultantUserSlice";

import MDCard from "components/md-components/card/MDCard";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDSwitch from "components/md-components/switch/MDSwitch";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import toCapitalize from "components/hooks/toCapitalize";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCardHeader from "components/md-components/card/MDCardHeader";

const UserManageConsultantUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const usersList = useSelector((state) => state.consultantUser.verifiedUsers);

  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    rowData: [],
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const handleStatusUpdate = useCallback(
    (event, params) => {
      setDialogData({ ...dialogData, id: params.id });
      const apiData = {
        consultantUserId: params.row._id,
        data: { status: event.target.checked },
      };
      dispatch(statusUpdateConsultantUser(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getVerifiedConsultantUsers());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dialogData, dispatch]
  );
  const handleDelete = useCallback(
    ({ event, id }) => {
      const apiData = {
        consultantUserId: id,
        data: { isDeleted: event },
      };
      dispatch(deleteConsultantUser(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getVerifiedConsultantUsers());
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getVerifiedConsultantUsers());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "firstName lastName",
        headerName: "Name",
        width: 240,
        editable: false,
        valueGetter: (params) =>
          `${toCapitalize(params.row.firstName)} ${toCapitalize(
            params.row.lastName
          )}`,
      },
      {
        field: "email",
        headerName: "Email",
        width: 240,
        editable: false,
      },
      {
        field: "isVerified",
        headerName: "Verify Status",
        headerAlign: "center",
        align: "center",
        width: 240,
        editable: false,
        renderCell: (params) => {
          return (
            <Chip
              label="Approved"
              icon={<CheckCircleOutlineIcon fontSize="small" />}
              color="success"
              size="small"
            />
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: (params) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label={`${params.row.name}-view-button`}
                color="primary"
                onClick={() => {
                  navigate(`/user-management/consultant-user-detail`, {
                    state: {
                      userId: params.row._id,
                      userType: "verified",
                    },
                  });
                }}
              >
                <VisibilityIcon sx={{ fontSize: 28 }} />
              </IconButton>
              <MDSwitch
                checked={params.row.status}
                onChange={(event) => {
                  handleStatusUpdate(event, params);
                }}
                inputProps={{
                  "aria-label": `${params.row.name}-status`,
                  role: "switch",
                }}
                color="success"
                disabled={
                  get(dialogData, "id", "") === params.row.id && isLoading
                }
              />
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: params.row._id,
                    title: "Consultant User Delete Confirmation",
                    rowData: params.row,
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      dialogData,
      handleDeleteConfirmation,
      handleStatusUpdate,
      isLoading,
      navigate,
    ]
  );

  const CustomToolbar = () => {
    return <MDDataGridToolbar align="right" divider={true} />;
  };

  return (
    <Box>
      <MDCard>
        <MDCardHeader title="Consultant User's" />
        <CardContent className="h-100">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDDataGrid
                rows={usersList}
                columns={columnsData}
                getRowId={(row) => row._id}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                loading={isLoading}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: EmptyTableRowOverlay,
                  LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                autoHeight
              />
              <DeleteConfirmDialog
                title="Consultant User Delete Confirmation"
                open={deleteConfirmation}
                handleClose={handleDeleteConfirmationClose}
                deleteAction={() => {
                  handleDelete({ event: true, id: get(dialogData, "id", "") });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </MDCard>
    </Box>
  );
};

export default UserManageConsultantUser;
