import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetEndUsersList, GetEndUserDetail, StatusUpdateEndUser, DeleteEndUser } from 'api/user-management/UserManagementAPI';
import { get } from 'lodash';

const initialState = {
	list: [],
	userDetail: [],
	loading: false,
};

export const getEndUsersList = createAsyncThunk('endUser/getEndUsersList', async () => {
	return await GetEndUsersList()
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const getEndUserDetail = createAsyncThunk('endUser/getEndUserDetail', async (data) => {
	return await GetEndUserDetail(data)
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const statusUpdateEndUser = createAsyncThunk('endUser/statusUpdateEndUser', async (data) => {
	return await StatusUpdateEndUser(data)
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});
export const deleteEndUser = createAsyncThunk('endUser/deleteEndUser', async (data) => {
	return await DeleteEndUser(data)
		.then((result) => {
			if (get(result, 'data.status', false)) {
				return get(result, 'data', []);
			}
		})
		.catch((err) => {
			return err;
		});
});

const EndUser = createSlice({
	name: 'endUser',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(getEndUsersList.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getEndUsersList.fulfilled, (state, action) => {
			state.list = get(action.payload, 'status', false) ? action.payload.data : [];
			state.loading = false;
		});
		builder.addCase(getEndUsersList.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getEndUserDetail.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getEndUserDetail.fulfilled, (state, action) => {
			state.userDetail = get(action.payload, 'status', false)
				? { ...action.payload.data, appointmentList: action.payload.appointmentList }
				: [];
			state.loading = false;
		});
		builder.addCase(getEndUserDetail.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(statusUpdateEndUser.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(statusUpdateEndUser.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(statusUpdateEndUser.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(deleteEndUser.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(deleteEndUser.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(deleteEndUser.rejected, (state) => {
			state.loading = false;
		});
	},
});

export default EndUser.reducer;
