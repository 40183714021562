import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { get, filter, map, uniqueId, cloneDeep } from 'lodash';
import * as Yup from 'yup';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { toast } from 'react-toastify';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from 'react-router-dom';
import ContentForm from './ContentForm';
import { isEmpty } from 'lodash';

const ValidationSchema = Yup.object().shape({
	displayTitle: Yup.string().min(4).required('Title is required.'),
	content: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().min(3).max(1000).required('Title Required'),
			subTitle: Yup.string().min(3).max(1000).required('Title Required'),
			description: Yup.array().of(
				Yup.object().shape({
					data: Yup.mixed()
						.when(['preferredOption'], (preferredOption) => {
							if (preferredOption === 'TEXT') {
								return Yup.string().required('Content Required');
							}
						})
						.when(['dataType', 'preferredOption'], (dataType, preferredOption) => {
							if (preferredOption === 'IMAGE' && dataType === 'IMAGE_LINK') {
								return Yup.mixed().required('Image link required');
							} else if (preferredOption === 'IMAGE' && dataType === 'IMAGE_UPLOAD') {
								return Yup.mixed().required('image required');
							}
						})
						.when(['dataType', 'preferredOption'], (dataType, preferredOption, _, data) => {
							if (preferredOption === 'VIDEO') {
								if (typeof data.originalValue === 'string') {
									return Yup.string().url().required('video links required');
								}

								if (isEmpty(data.originalValue)) {
									return Yup.string().url().required('Video links required');
								}
							}
						}),
					title: Yup.string().when(['preferredOption'], (preferredOption) => {
						if (
							preferredOption === 'IMAGE' ||
							preferredOption === 'VIDEO' ||
							preferredOption === 'AUDIO' ||
							preferredOption === 'PDF'
						) {
							return Yup.string()
								.min(3, 'Title is very Short')
								.max(500, 'Title is very Long')
								.required('Title is required');
						}
					}),
				})
			),
			seoTitle: Yup.string().min(8).max(60).required('Seo title required'),
			seoDescription: Yup.string().min(8).max(155).required('Seo description required'),
			seoMetaTags: Yup.array().min(3).max(10).required('Seo meta tags required'),
		})
	),
});

const getInitialValue = (data, languageList, categoryId, segmentId) => {
	const state = {
		displayTitle: get(data, 'displayTitle', ''),
		category: get(data, 'category', categoryId),
		segment: get(data, 'segment', segmentId),
	};

	state.content = map(languageList, (language) => {
		const initialValue = filter(get(data, 'content', []), (content) => content?.language === language?._id)[0];

		return {
			title: initialValue?.title || '',
			subTitle: initialValue?.subTitle || '',
			language: language._id,
			languageName: language.title,
			languageId: language._id,
			description: initialValue?.description || [
				{
					data: '',
					preferredOption: 'TEXT',
					title: '',
					dataType: 'TEXT',
				},
			],
			seoTitle: initialValue?.seoTitle || '',
			seoDescription: initialValue?.seoDescription || '',
			seoMetaTags: initialValue?.seoMetaTags || [],
		};
	});

	return state;
};

const KnowledgeBaseDetailsForm = () => {
	let { qnaId, categoryId, segmentId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const [loading, setLoading] = useState(false);
	const [details, setDetails] = useState({});
	const [showForm, setShowForm] = useState(false);
	const [openIndex, setOpenIndex] = useState(0);

	async function getDetails(id) {
		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `/admin/knowledge-base-details/get/${id}`,
			});
			if (response.status === 200) {
				setDetails(response.data.data);
				setShowForm(true);
				return;
			}
		} catch (error) {}

		navigate('/knowledge-base/qna', { replace: true });
	}
	useEffect(() => {
		if (!qnaId) {
			setShowForm(true);
			return;
		}
		getDetails(qnaId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const handleSubmit = async (values) => {
		setLoading(true);

		try {
			let data = { ...values, id: details?._id };
			const formData = new FormData();
			data.content.forEach((contentDetails) => {
				contentDetails.description.forEach((item) => {
					if (item.dataType === 'VIDEO_UPLOAD') {
						if (typeof item.data !== 'string') {
							const id = uniqueId();
							formData.append(id, item.data);
							item.data = id;
							item.file = true;
						}
					} else if (item.dataType === 'IMAGE_UPLOAD') {
						item.data.forEach((item) => {
							if (item.file) {
								const id = uniqueId();
								formData.append(id, item.file, `file.${item.file.type?.split('/').pop()}`);
								item.file = id;
							}
						});
					}
				});
			});
			formData.append('content', JSON.stringify(data));

			const response = await AxiosDefaultSetting({
				url: `/admin/knowledge-base-details/add`,
				method: 'POST',
				data: formData,
				contentType: 'multipart/form-data',
				onUploadProgress: function (progressEvent) {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					const content = `Uploading files: ${percentCompleted}%`;
					// const value = parseInt(percentCompleted);
					console.log(content);
				},
			});
			if (response.status === 200) {
				toast.success(response.data.message);
				setLoading(false);
				navigate(`/knowledge-base/category/${categoryId}/${segmentId}`, { replace: true });
				return;
			}
		} catch (error) {
			console.log(error);
		}

		setLoading(false);
	};

	return (
		<MDCard>
			<MDCardHeader title='Knowledge Base Q&A' />
			<CardContent className='h-100'>
				{languageConfigList?.length > 0 && showForm && (
					<Formik
						initialValues={getInitialValue(details, languageConfigList, categoryId, segmentId)}
						validationSchema={ValidationSchema}
						onSubmit={(values, formikHelpers) => {
							handleSubmit(cloneDeep(values), formikHelpers);
						}}
						enableReinitialize
					>
						{(formProps) => (
							<form onSubmit={formProps.handleSubmit} className='d-flex flex-column overflow-auto pt-2'>
								<Grid container rowSpacing={2} columnSpacing={4}>
									<Grid item xs={12}>
										<TextField
											fullWidth
											label='Title'
											name='displayTitle'
											value={formProps.values.displayTitle}
											error={Boolean(formProps.errors.displayTitle && formProps.touched.displayTitle)}
											helperText={
												formProps.errors.displayTitle && formProps.touched.displayTitle
													? formProps.errors.displayTitle
													: null
											}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
										/>
									</Grid>
									<Grid item xs={12}>
										<ContentForm formProps={formProps} openIndex={openIndex} setOpenIndex={setOpenIndex} />
									</Grid>
								</Grid>
								<DialogActions>
									<MDLoadingButton type='submit' variant='contained' loading={loading}>
										Submit
									</MDLoadingButton>
								</DialogActions>
							</form>
						)}
					</Formik>
				)}
			</CardContent>
		</MDCard>
	);
};

export default KnowledgeBaseDetailsForm;
