import { get } from "lodash";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import MealDetail from "components/pages/health-food/meal/MealDetail";

const MealDetailView = () => {
  const location = useLocation();

  const isNonApprove = get(location, "state.isNonApprove", false);

  return (
    <MDCard>
      <MDCardHeader
        title={`Health Food Meal ${isNonApprove ? "Approve" : "Detail"} View`}
      />
      <CardContent>
        <Box px={1} pb={2}>
          <MealDetail mealDetail={get(location, "state.mealDetail")} />
        </Box>
      </CardContent>
    </MDCard>
  );
};

export default MealDetailView;
