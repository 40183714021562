import React, { useState } from 'react';
import { FieldArray } from 'formik';
import { map, filter, get } from 'lodash';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Collapse, IconButton, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LanguageTranslationForm = ({ formProps }) => {
	const [openIndex, setOpenIndex] = useState(0);

	return (
		<FieldArray name='languages'>
			<Box>
				{map(formProps?.values?.languages, (field, index, { length }) => {
					const title = `languages[${index}].title`;
					const writer = `languages[${index}].writer`;

					const errors = get(formProps, `errors.languages[${index}]`, {});
					const touched = get(formProps, `touched.languages[${index}]`, {});

					return (
						<React.Fragment key={index}>
							<Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
								<Typography variant='h6'>{field?.lang}</Typography>
								<IconButton onClick={() => setOpenIndex(index)}>
									<Box
										component={openIndex === index ? ExpandLessIcon : ExpandMoreIcon}
										color='inherit'
										sx={{ fontSize: '1.25rem' }}
									/>
								</IconButton>
							</Box>
							<Collapse in={openIndex === index}>
								<Grid container spacing={2} key={index} className={index !== length - 1 ? 'mb-3' : ''}>
									<Grid item xs={12}>
										<TextField
											fullWidth
											size='small'
											label='Title'
											multiline
											rows={3}
											name={title}
											value={field.title}
											onChange={formProps?.handleChange}
											onBlur={formProps?.handleBlur}
											error={Boolean(errors.title && touched.title)}
											helperText={errors.title && touched.title ? errors.title : null}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											size='small'
											label='Writer'
											name={writer}
											value={field.writer}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
											error={Boolean(errors.writer && touched.writer)}
											helperText={errors.writer && touched.writer ? errors.writer : null}
										/>
									</Grid>
								</Grid>
							</Collapse>
							{index !== length - 1 && <Divider light={true} flexItem={true} sx={{ pt: { sm: 2, xs: 0 } }} />}
						</React.Fragment>
					);
				})}
			</Box>
		</FieldArray>
	);
};

export default LanguageTranslationForm;

export const languageInitialValue = (languageList, contentList) => {
	return map(languageList, (language) => {
		const initialValue2 = filter(contentList, (data) => data?.language === language?._id)[0];

		const value = {
			lang: language.title,
			langID: language._id,
			title: initialValue2?.title || '',
			writer: initialValue2?.writer || '',
		};

		return value;
	});
};
