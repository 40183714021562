import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConsultantUserVerification } from "store/slice/user-management/ConsultantUserSlice";
import { get } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MDCard from "components/md-components/card/MDCard";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ConsultantUserVerify = (props) => {
  const { userDetails } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verifyLoading = useSelector(
    (state) => state.consultantUser.verifyLoading
  );

  const verifyStatus = [
    {
      value: 1,
      label: "Approved",
    },
    {
      value: 2,
      label: "Resubmit",
    },
    {
      value: 3,
      label: "Rejected",
    },
  ];

  const handleSubmit = (values) => {
    const apiData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: values,
    };
    dispatch(updateConsultantUserVerification(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          toast.success(get(result, "message", "User verify successfully"));
          navigate("/user-management/consultant-verification-request");
        } else {
          toast.error(get(result, "message", "Something went wrong"));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDCard>
      <Box className="px-3 py-2 border-bottom">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            Verify User
          </Typography>
        </Stack>
      </Box>
      <Formik
        initialValues={{ isVerified: undefined, message: "" }}
        validationSchema={Yup.object().shape({
          isVerified: Yup.number().required("Verify Status is required."),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    autoHighlight={true}
                    size="small"
                    id="isVerified"
                    name="isVerified"
                    value={props.values.isVerified}
                    options={verifyStatus}
                    getOptionLabel={(option) => option.label ?? option}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value
                    }
                    onChange={(_event, value) => {
                      props.setFieldValue("isVerified", value.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Verification Status"
                        error={
                          props.errors.isVerified && props.touched.isVerified
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.isVerified && props.touched.isVerified
                            ? props.errors.isVerified
                            : null
                        }
                        onBlur={props.handleBlur}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "verify-status",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="message"
                    label="Message"
                    name="message"
                    type="textarea"
                    value={props.values.message}
                    error={
                      props.errors.message && props.touched.message
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.message && props.touched.message
                        ? props.errors.message
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    multiline
                    rows={4}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className="justify-content-center">
              <Box className="text-center px-3 pb-2">
                <MDLoadingButton
                  type="submit"
                  label="Verify"
                  variant="contained"
                  loading={props.isSubmitting && verifyLoading}
                />
              </Box>
            </CardActions>
          </form>
        )}
      </Formik>
    </MDCard>
  );
};

export default ConsultantUserVerify;
