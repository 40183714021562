import { get, map } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import Image from "components/utility/media/Image";
import fileURLGenerator from "components/hooks/urlGenerator";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";
import Lightbox from "components/utility/lightbox";
import HighSchoolInfoForm from "./HighSchoolInfoForm";

const ConsultantHighSchoolInfo = (props) => {
  const { userDetails, userType } = props;
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const [dialogData, setDialogData] = useState({
    title: "",
    data: [],
    formType: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxData, setLightBoxData] = useState([]);
  const handleOpenLightBox = (src, alt) => {
    setLightBoxOpen(true);
    setLightBoxData([
      {
        src: src,
        alt: alt,
        caption: [],
      },
    ]);
  };
  const handleCloseLightBox = () => {
    setLightBoxOpen(false);
    setLightBoxData([]);
  };

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header border-top">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            High School Information
          </Typography>
          <IconButton
            color="primary"
            onClick={() => {
              setDialogData({
                ...dialogData,
                title: "High School Information Create",
                data: [],
                formType: "create",
              });
              handleDialogOpen();
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Stack>
      </Box>
      <CardContent>
        {map(
          get(userDetails, "educationHighSchool", []),
          (item, index, { length }) => (
            <Box
              key={index}
              className={`border rounded-2 ${
                index === length - 1 ? "" : "mb-3"
              }`}
            >
              <Box className="detail-box-header">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Typography className="detail-box-title">
                    {item.fieldOfStudy}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        title: "High School Information Update",
                        data: item,
                        formType: "update",
                      });
                      handleDialogOpen();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Stack>
              </Box>
              <Stack
                direction={{ md: "row", xs: "column" }}
                spacing={{ md: 2, xs: 1.5 }}
                justifyContent="space-between"
                className="p-3"
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "12px",
                  }}
                >
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="School Name" colon={true} />
                    <DetailValue value={item?.schoolName} />
                  </DetailWrapper>
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="Location" colon={true} />
                    <DetailValue value={item?.location} />
                  </DetailWrapper>
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="Field Of Study" colon={true} />
                    <DetailValue value={item?.fieldOfStudy} />
                  </DetailWrapper>
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="Start Date" colon={true} />
                    <DetailValue value={moment(item?.startDate).format("LL")} />
                  </DetailWrapper>
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="End Date" colon={true} />
                    <DetailValue value={moment(item?.endDate).format("LL")} />
                  </DetailWrapper>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "12px",
                  }}
                >
                  <DetailWrapper fullWidth={true}>
                    <DetailTitle title="Document" colon={true} />
                    <DetailValue>
                      <Button
                        sx={{ maxWidth: 180 }}
                        onClick={() => {
                          handleOpenLightBox(
                            fileURLGenerator(
                              item?.documentUrl
                            ),
                            `document_image_${index}`
                          );
                        }}
                        className="p-1"
                      >
                        <Image
                          alt="document_image"
                          src={fileURLGenerator(item?.documentUrl)}
                        />
                      </Button>
                    </DetailValue>
                  </DetailWrapper>
                </Box>
              </Stack>
            </Box>
          )
        )}
        <Lightbox
          open={lightBoxOpen}
          handleClose={handleCloseLightBox}
          imageData={lightBoxData}
        />
        <HighSchoolInfoForm
          dialogData={dialogData}
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          userDetails={userDetails}
          userType={userType}
        />
      </CardContent>
    </MDCard>
  );
};

export default ConsultantHighSchoolInfo;
