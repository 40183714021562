import React, { useState } from "react";
import { FieldArray, getIn } from "formik";
import { find, map, filter, isEmpty } from "lodash";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import RichTextEditor from "components/utility/rich-text-editor/RichTextEditor";
import { Collapse, IconButton, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LanguageTranslationForm = (props) => {
  const {
    fieldName,
    formProps,
    languageConfigList,
    inputField = "text-input",
    borderOnBottom,
    minRows,
    multiline = false,
  } = props;

  const [openIndex, setOpenIndex] = useState(0);

  const getLanguageTitle = (id) => {
    const data = find(languageConfigList, (language) => language?._id === id);
    return data?.title ?? "";
  };

  return (
    <FieldArray name={fieldName ? fieldName : "languages"}>
      {() => (
        <Box className={borderOnBottom ? "border-bottom " : ""}>
          {map(formProps?.values?.[fieldName], (field, index, { length }) => {
            const name = fieldName ? fieldName : "languages";

            const microValue = `[${name}][${index}].value`;
            const errorMicroValue = getIn(formProps?.errors, microValue);
            const touchedMicroValue = getIn(formProps?.touched, microValue);

            const isTranslate = `[${name}][${index}].isTranslate`;

            return (
              <React.Fragment key={index}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="h6">{getLanguageTitle(field?.languageId)}</Typography>
                  <IconButton onClick={() => setOpenIndex(index)}>
                    <Box
                      component={openIndex === index ? ExpandLessIcon : ExpandMoreIcon}
                      color="inherit"
                      sx={{ fontSize: "1.25rem" }}
                    />
                  </IconButton>
                </Box>
                <Collapse in={openIndex === index}>
                  <Grid container spacing={2} key={index} className={index !== length - 1 ? "mb-3" : ""}>
                    {inputField !== "text-editor" ? (
                      <Grid item md={8} xs={12}>
                        <TextField
                          id={`${field?.languageId}-value-field`}
                          fullWidth={true}
                          size="small"
                          label="Value"
                          name={microValue}
                          value={field?.value}
                          onChange={formProps?.handleChange}
                          onBlur={formProps?.handleBlur}
                          multiline={multiline}
                          minRows={minRows}
                          error={errorMicroValue && touchedMicroValue ? true : false}
                          helperText={errorMicroValue && touchedMicroValue ? errorMicroValue : null}
                        />
                      </Grid>
                    ) : (
                      <Grid item md={8} xs={12}>
                        <RichTextEditor
                          id={`${field.languageId}-value-field`}
                          label="Value"
                          name={microValue}
                          value={field?.value}
                          setFieldValue={formProps.setFieldValue}
                          onBlur={formProps?.handleBlur}
                          error={errorMicroValue && touchedMicroValue ? true : false}
                          helperText={errorMicroValue && touchedMicroValue ? errorMicroValue : null}
                        />
                      </Grid>
                    )}
                    <Grid item md={2} xs={12}>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={isTranslate}
                                checked={field?.isTranslate}
                                value={field?.isTranslate}
                                onChange={(e) => {
                                  formProps.setFieldValue(isTranslate, e.target.checked);
                                }}
                                inputProps={{
                                  "aria-label": `is-language-status-${index}`,
                                }}
                              />
                            }
                            label="Is Translate"
                            sx={{ mr: 0 }}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {index !== length - 1 ? (
                      <Grid item xs={12} pt={{ sm: 2, xs: 0 }}>
                        <Divider light={true} flexItem={true} />
                      </Grid>
                    ) : null}
                  </Grid>
                </Collapse>
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </FieldArray>
  );
};

export default LanguageTranslationForm;

export const languageInitialValue = (languageList, preFilledData, type) => {
  return map(languageList, (language) => {
    const initialValue = filter(preFilledData, (data) => data?.languageId === language?._id)[0];

    return isEmpty(preFilledData)
      ? {
          languageId: initialValue?.languageId || language?._id,
          value: initialValue?.value || "",
          type: type,
          isTranslate: initialValue?.isTranslate || false,
        }
      : {
          languageId: initialValue?.languageId || language?._id,
          value: initialValue?.value || "",
          isTranslate: initialValue?.isTranslate || false,
        };
  });
};
