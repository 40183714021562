import React from "react";

import { alpha, styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const CustomizeCheckbox = styled(Checkbox)(({ other, theme }) => ({
  display: "none",
  "& + .MuiFormControlLabel-label": {
    width: other?.width ?? "100%",
    padding: other?.padding ? other?.padding : "3px 10px",
    fontSize: other?.fontSize ?? "14px",
    fontWeight: other?.fontWeight ?? "500",
    backgroundColor: other?.color
      ? `${alpha(theme.palette[other?.color][100], 0.5)}`
      : theme.palette.primary[100],
    color: other?.color
      ? `${alpha(theme.palette[other?.color].dark, 1)}`
      : theme.palette.primary.dark,
    border: `1px solid`,
    borderColor: other?.color
      ? alpha(theme.palette[other?.color][100], 0.5)
      : alpha(theme.palette.primary[100], 1),
    borderRadius: other?.borderRadius ?? "50px",
    transition: "all 300ms ease-in-out",
    "& + :hover": {
      backgroundColor: other?.color
        ? `${alpha(theme.palette[other?.color][200], 0.25)}`
        : theme.palette.primary[200],
      color: other?.color
        ? `${alpha(theme.palette[other?.color].dark, 1)}`
        : theme.palette.primary.dark,
    },
    position: "relative",
    paddingRight: "28px",
  },
  "&.Mui-checked": {
    "& + .MuiFormControlLabel-label": {
      backgroundColor: other?.color
        ? `${alpha(theme.palette[other?.color][200], 0.75)}`
        : theme.palette.primary[200],
      color: other?.color
        ? `${alpha(theme.palette[other?.color][900], 1)}`
        : theme.palette.primary[900],
      borderColor: other?.color
        ? `${alpha(theme.palette[other?.color].dark, 1)}`
        : theme.palette.primary.dark,
      "& + :hover": {
        backgroundColor: other?.color
          ? `${alpha(theme.palette[other?.color][300], 1)}`
          : theme.palette.primary[300],
        fontWeight: "500",
      },
      "&:after": {
        content: `url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:currentColor;stroke:${
          theme.palette[other?.color]?.main.replace("#", "%23") ?? "%23435ead"
        };stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='checkmark'%3E%3Cline class='cls-1' x1='3' x2='12' y1='16' y2='25'/%3E%3Cline class='cls-1' x1='12' x2='29' y1='25' y2='7'/%3E%3C/g%3E%3C/svg%3E")`,
        display: "block",
        width: 18,
        height: 18,
        position: "absolute",
        zIndex: "inherit",
        top: "50%",
        right: "3%",
        transform: "translate(-3%, -50%)",
        borderRadius: "50%",
      },
    },
  },
  "&.MuiCheckbox-root.Mui-checked": {
    "& + .MuiFormControlLabel-label": {
      animation:
        other?.animated === "true" ? "flash 300ms ease alternate" : "none",
    },
  },
}));

const MDChipCheckbox = (props) => {
  const { ...rest } = props;

  return <CustomizeCheckbox other={props} {...rest} />;
};

export default MDChipCheckbox;
