import * as React from "react";

import Box from "@mui/material/Box";

const style = {
  width: "100%",
  height: "auto",
  display: "block",
  borderRadius: "inherit",
};

const Image = ({ sx, ...rest }) => {
  React.useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      var lazyImages = [].slice.call(document.getElementById("img-tag"));

      if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              let lazyImage = entry.target;
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.srcset = lazyImage.dataset.srcset;
              lazyImage.classList.add("lazy-load");
              lazyImageObserver.unobserve(lazyImage);
            }
          });
        });

        lazyImages.forEach(function (lazyImage) {
          lazyImageObserver.observe(lazyImage);
        });
      } else {
        // Possibly fall back to event handlers here
      }
    });
  }, []);

  return (
    <Box component="img" id="img-tag blur-up" {...rest} sx={{ style, sx }} />
  );
};

export default Image;
