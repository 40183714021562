import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { get, map } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import { useMediaQuery, useTheme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { getGridDateOperators } from "@mui/x-data-grid";
import TabPanel from "components/md-components/tab/TabPanel";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDButton from "components/md-components/button/MDButton";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import QuantityOnlyOperator from "components/md-components/data-table/filters/QuantityFilter";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import cookie, { cookieGet, cookieSet } from "../../../components/hooks/cookie";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  deletePressRelease,
  getPressReleaseList,
  publishPressRelease,
} from "store/slice/press-release/PresReleaseSlice";

function a11yProps(index) {
  return {
    id: `article-list-tab-${index}`,
    "aria-controls": `article-list-tab-panel-${index}`,
  };
}

const PressReleasedList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const breakpointSM = useMediaQuery(theme.breakpoints.up("sm"));

  const isLoading = useSelector((state) => state.PressRelease.loading);
  const PressReleaseList = useSelector(
    (state) => state.PressRelease.pressReleaseList
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCountState, setRowCountState] = useState(0);

  const [sortModal, setSortModal] = useState({
    sortModel: [
      {
        field: "createdAt",
        sort: "desc",
      },
    ],
  });

  const [filterModal, setFilterModal] = useState({
    filterModel: {
      items: [
        {
          columnField: "",
          id: 38876,
          operatorValue: "contains",
          value: "",
        },
      ],
      linkOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    },
  });

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    title: true,
    language: true,
    createdBy: true,
    isPublished: true,
    isArchived: false,
    isDeleted: false,
    comments: false,
    isApproved: true,
    createdAt: true,
    updatedAt: false,
    approveDate: false,
    actions: true,
  });

  const [apiType, setApiType] = useState(get(location, "state.apiType", "ALL"));
  const [tabValue, setTabValue] = useState(get(location, "state.tabIndex", 0));

  const [rowId, setRowId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const getAPIPayload = useMemo(() => {
    return {
      pageNo: page,
      search: get(filterModal, "filterModel.quickFilterValues", "")
        .toString()
        .replace(/,/g, " "),
      type: apiType ?? "",
      sortField: get(sortModal, "sortModel.0.field", "createdAt"),
      sortValue: get(sortModal, "sortModel.0.sort", "asc"),
    };
  }, [apiType, filterModal, page, sortModal]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (event?.target?.textContent === "All") {
      setApiType("ALL");
    } else if (event?.target?.textContent === "Published") {
      setApiType("PUBLISH");
    } else if (event?.target?.textContent === "Unpublished / Pending") {
      setApiType("UNPUBLISH");
    } else if (event?.target?.textContent === "Archived / Deleted") {
      setApiType("ARCHIVED");
    }
  };

  const tabs = [
    {
      title: "All",
    },
    {
      title: "Published",
    },
    {
      title: "Unpublished / Pending",
    },
    {
      title: "Archived / Deleted",
    },
  ];

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    setSortModal({ sortModel: [...sortModel] });
  }, []);

  const handleFilterChange = useCallback((filterModel) => {
    setFilterModal({ filterModel: { ...filterModel } });
  }, []);

  const getArticlesListData = useCallback(
    (data) => {
      dispatch(getPressReleaseList(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            const totalBooking = get(result, "data.totalBookings", 0);
            setRowCountState(totalBooking);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    [dispatch]
  );

  const handlePublishRecipe = useCallback(
    (event, row) => {
      const payloadData = {
        id: row?._id,
        data: {
          isPublished: event.target.checked,
        },
      };
      dispatch(publishPressRelease(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            toast.success(get(result, "message", "Publish successfully."));
            dispatch(getArticlesListData(getAPIPayload));
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch, getAPIPayload, getArticlesListData]
  );

  const handleDownload = useCallback(
    async (event, row) => {
      try {
        const authToken = cookieGet("auth_token");
        const payloadData = {
          id: row?._id,
          data: {
            isPublished: event.target.checked,
          },
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_END_POINT}/admin/press-release/download-pdf/${row?._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
              Authorization: authToken,
            },
          }
        );

        if (response.ok) {
          // console.log('response :>> ', response);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "press_release.pdf";
          link.click();
        } else {
          console.error("Failed to download the PDF");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, getAPIPayload, getArticlesListData]
  );
  const handleDeleteConfirmation = useCallback((rowId) => {
    setRowId(rowId);
    setDeleteConfirmation(true);
  }, []);

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const handleDeleteArticle = useCallback(
    (id) => {
      dispatch(deletePressRelease(id))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            toast.success(get(result, "message", "Deleted successfully."));
            dispatch(getArticlesListData(getAPIPayload));
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch, getAPIPayload, getArticlesListData]
  );

  useEffect(() => {
    getArticlesListData(getAPIPayload);
  }, [getAPIPayload, getArticlesListData]);

  const columnsData = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        width: 320,
        editable: false,
      },
      {
        field: "language",
        headerName: "Language",
        width: 150,
        editable: false,
        valueGetter: ({ row }) => row?.language?.title,
      },
      {
        field: "createdBy",
        headerName: "Created By",
        width: 150,
        editable: false,
      },
      {
        field: "isPublished",
        headerName: "Is Published",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 text-center">
            <Checkbox
              icon={<CancelIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={get(row, "isPublished", false)}
              onChange={(event) => {
                handlePublishRecipe(event, row);
              }}
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                color: "error.main",
                "&.Mui-checked": {
                  color: "success.main",
                },
              }}
            />
          </Box>
        ),
      },
      {
        field: "isArchived",
        headerName: "Is Archived",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "isDeleted",
        headerName: "Is Deleted",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "comments",
        headerName: "Comments",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "isApproved",
        headerName: "Is Approved",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Chip
            label={
              get(row, "isApproved", 0) === 0
                ? "New"
                : get(row, "isApproved", 0) === 1
                ? "Approved"
                : "Resubmit"
            }
            color={
              get(row, "isApproved", 0) === 0
                ? "info"
                : get(row, "isApproved", 0) === 1
                ? "success"
                : "warning"
            }
            size="small"
          />
        ),
      },
      {
        field: "createdAt",
        headerName: "Create Date",
        type: "date",
        width: 150,
        editable: false,
        filterOperators: getGridDateOperators().concat(
          ...QuantityOnlyOperator({ inputType: "date" })
        ),
        valueGetter: ({ row }) =>
          moment(get(row, "createdAt", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "updatedAt",
        headerName: "Update Date",
        type: "date",
        width: 150,
        editable: false,
        filterOperators: getGridDateOperators().concat(
          ...QuantityOnlyOperator({ inputType: "date" })
        ),
        valueGetter: ({ row }) =>
          moment(get(row, "updatedAt", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "approveDate",
        headerName: "Approve Date",
        type: "date",
        width: 150,
        editable: false,
        filterOperators: getGridDateOperators().concat(
          ...QuantityOnlyOperator({ inputType: "date" })
        ),
        valueGetter: ({ row }) =>
          moment(get(row, "approveDate", "NA"), [
            "MM-DD-YYYY",
            "YYYY-MM-DD",
          ]).format("MM/DD/YYYY"),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        headerAlign: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 d-flex justify-content-center align-items-center gap-2">
            <IconButton
              aria-label={`${row._id}-view`}
              color="primary"
              onClick={() => {
                navigate("/press-release-blog/press-release-detail", {
                  state: {
                    articleData: row,
                    isNonApprove: apiType === "UNPUBLISH",
                    tabIndex: tabValue,
                    apiType: apiType,
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              aria-label={`${row._id}-edit`}
              color="primary"
              onClick={() => {
                navigate("/press-release-blog/press-release-create-update", {
                  state: {
                    articleDetail: row,
                    formType: "update",
                    tabIndex: tabValue,
                    apiType: apiType,
                  },
                });
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label={`${row._id}-delete`}
              color="error"
              onClick={() => {
                handleDeleteConfirmation(row._id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
      {
        field: "Download",
        headerName: "Download",
        type: "boolean",
        width: 150,
        editable: false,
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
          <Box className="w-100 text-center">
            <IconButton
              aria-label={`${row._id}-edit`}
              color="primary"
              onClick={(event) => {
                handleDownload(event, row);
              }}
            >
              <FileDownloadIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [apiType, handleDeleteConfirmation, handlePublishRecipe, navigate, tabValue]
  );

  const CustomToolbar = () => {
    return (
      <MDDataGridToolbar ToolbarQuickFilter={true} align="right" divider={true}>
        <MDButton
          variant="contained"
          label="Create Press"
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            navigate("/press-release-blog/press-release-create-update");
          }}
        />
      </MDDataGridToolbar>
    );
  };

  return (
    <MDCard>
      <MDCardHeader title="Press Released / Listing" />
      <CardContent>
        <Grid container spacing={0} className="h-100 align-content-start">
          <Grid item xs={12}>
            <Box sx={{ bgcolor: theme.palette.background.default }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant={breakpointSM ? "fullWidth" : "scrollable"}
                scrollButtons={breakpointSM ? false : true}
                aria-label="signup config tabs"
              >
                {map(tabs, (item, index) => (
                  <Tab label={item.title} {...a11yProps(index)} key={index} />
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              {map(tabs, (_item, index) => (
                <TabPanel key={index} value={tabValue} index={index}>
                  <MDDataGrid
                    rows={PressReleaseList ?? []}
                    rowCount={rowCountState}
                    columns={columnsData}
                    getRowId={(row) => row._id}
                    disableSelectionOnClick={true}
                    loading={isLoading}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    filterMode="server"
                    onFilterModelChange={handleFilterChange}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setColumnVisibilityModel(newModel)
                    }
                    components={{
                      Toolbar: CustomToolbar,
                      NoRowsOverlay: EmptyTableRowOverlay,
                      LoadingOverlay: LinearProgress,
                    }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    initialState={{
                      sorting: sortModal,
                    }}
                    autoHeight
                  />
                </TabPanel>
              ))}
            </Box>
          </Grid>
        </Grid>
        <DeleteConfirmDialog
          title="Press Delete Confirmation"
          open={deleteConfirmation}
          handleClose={handleDeleteConfirmationClose}
          deleteAction={() => {
            handleDeleteArticle(rowId);
          }}
        />
      </CardContent>
    </MDCard>
  );
};

export default PressReleasedList;
