import { useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue
} from "components/common/DetailWrapper";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";
import ProfessionalInfoForm from "./ProfessionalInfoForm";

const ConsultantProfessionalInfo = (props) => {
  const { userDetails, userType } = props;
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            Professional Information
          </Typography>
          <IconButton color="primary" onClick={() => handleDialogOpen()}>
            <EditIcon />
          </IconButton>
        </Stack>
      </Box>
      <CardContent>
        <Box>
          <DetailWrapper fullWidth={true}>
            <DetailTitle title="Bio" colon={true} />
            <DetailValue>{get(userDetails, "bio", "")}</DetailValue>
          </DetailWrapper>
        </Box>
        <ProfessionalInfoForm
          title="Professional Information Update"
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          userDetails={userDetails}
          userType={userType}
        />
      </CardContent>
    </MDCard>
  );
};

export default ConsultantProfessionalInfo;
