import { FieldArray, getIn } from "formik";
import { get, map, size } from "lodash";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { TransitionGroup } from "react-transition-group";
import MDRadio from "components/md-components/form/MDRadio";
import RichTextEditor from "components/utility/rich-text-editor/RichTextEditor";
import PDFPicker from "components/utility/pdf-picker/PDFPicker";

const PressReleasedFormContent = ({
  formProps,
  fileUploadLoading,
  handlePDFUpload,
  formType
}) => {
  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        Content Section
      </Typography>
      <FieldArray name="contentSection">
        {({ remove, push }) => (
          <Box className="pt-2">
            <TransitionGroup component="div" appear>
              {map(
                formProps.values.contentSection,
                (contentSectionItem, contentSectionIndex) => {
                  const preferredOption = `contentSection[${contentSectionIndex}].preferredOption`;
                  const errorPreferredOption = getIn(
                    formProps.errors,
                    preferredOption
                  );
                  const touchedPreferredOption = getIn(
                    formProps.touched,
                    preferredOption
                  );

                  const dataType = `contentSection[${contentSectionIndex}].dataType`;

                  const data = `contentSection[${contentSectionIndex}].data`;
                  const errorData = getIn(formProps.errors, data);
                  const touchedData = getIn(formProps.touched, data);

                  const title = `contentSection[${contentSectionIndex}].title`;
                  const errorImageVideoTitle = getIn(formProps.errors, title);
                  const touchedImageVideoTitle = getIn(
                    formProps.touched,
                    title
                  );

                  const isLargeContentSection =
                    size(get(formProps, "values.contentSection")) > 1;

                  return (
                    <Collapse key={contentSectionIndex}>
                      <Box>
                        {contentSectionIndex !== 0 ? (
                          <Box mt={1}>
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                            >
                              <Divider
                                orientation="horizontal"
                                flexItem={true}
                                light={true}
                                sx={{
                                  width: `calc(100% - 40px)`,
                                  margin: "auto 0 !important",
                                }}
                              />
                            </Stack>
                          </Box>
                        ) : null}
                        <Grid container spacing={2}>
                          {/* <Grid item xs={12}>
                            <Box>
                              <FormControl fullWidth className="gap-2">
                                <FormLabel>Preferred option</FormLabel>
                                <RadioGroup
                                  name={preferredOption}
                                  value={contentSectionItem?.preferredOption}
                                  onChange={(_e, value) => {
                                    formProps.setFieldValue(
                                      preferredOption,
                                      value
                                    );
                                    if (value === "TEXT") {
                                      formProps.setFieldValue(dataType, "TEXT");
                                    } else if (value === "IMAGE") {
                                      formProps.setFieldValue(
                                        dataType,
                                        "IMAGE_UPLOAD"
                                      );
                                    } else if (value === "VIDEO") {
                                      formProps.setFieldValue(
                                        dataType,
                                        "VIDEO_LINK"
                                      );
                                    }
                                  }}
                                  row
                                  sx={{
                                    marginLeft: 1,
                                  }}
                                >
                                  <FormControlLabel
                                    value="TEXT"
                                    control={<MDRadio />}
                                    label="Text"
                                    className="ms-0"
                                  />
                                  <FormControlLabel
                                    value="IMAGE"
                                    control={<MDRadio />}
                                    label="Image"
                                    className="ms-0"
                                  />
                                  <FormControlLabel
                                    value="VIDEO"
                                    control={<MDRadio />}
                                    label="Video"
                                    className="ms-0"
                                  />
                                </RadioGroup>
                                {errorPreferredOption &&
                                  touchedPreferredOption && (
                                    <FormHelperText>
                                      {errorPreferredOption}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Box>
                          </Grid> */}
                          {contentSectionItem?.preferredOption === "TEXT" ? (
                            <>
                              <Grid item xs={12}>
                                <Box>
                                  <RichTextEditor
                                    name={data}
                                    value={contentSectionItem?.data}
                                    setFieldValue={formProps.setFieldValue}
                                    error={
                                      errorData && touchedData ? true : false
                                    }
                                    helperText={
                                      errorData && touchedData
                                        ? errorData
                                        : null
                                    }
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box>
                                  <TextField
                                    size="small"
                                    label={"Title"}
                                    name={title}
                                    value={contentSectionItem?.title}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                    error={
                                      errorImageVideoTitle &&
                                      touchedImageVideoTitle
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errorImageVideoTitle &&
                                      touchedImageVideoTitle
                                        ? errorImageVideoTitle
                                        : null
                                    }
                                    fullWidth={true}
                                    multiline={true}
                                  />
                                </Box>
                              </Grid>
                            </>
                          ) : null}

                          {contentSectionItem?.preferredOption === "PDF" ? (
                            <>
                              <Grid item xs={12} container spacing={2}>
                                {contentSectionItem?.preferredOption ===
                                "PDF" ? (
                                  <Grid item xs={12}>
                                    <Box>
                                      <Typography variant="h6" className="my-2">
                                        Attachment
                                      </Typography>
                                      <FormControl fullWidth className="gap-2">
                                        <FormLabel>Data Type</FormLabel>
                                        <RadioGroup
                                          name={dataType}
                                          value={contentSectionItem?.dataType}
                                          onChange={(_e, value) => {
                                            formProps.setFieldValue(
                                              dataType,
                                              value
                                            );
                                          }}
                                          row
                                          sx={{
                                            marginLeft: 1,
                                          }}
                                        >
                                          <FormControlLabel
                                            value="PDF_UPLOAD"
                                            control={<MDRadio />}
                                            label="PDF Upload"
                                            className="ms-0"
                                          />
                                          <FormControlLabel
                                            value="PDF_LINK"
                                            control={<MDRadio />}
                                            label="PDF Link"
                                            className="ms-0"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Box>
                                  </Grid>
                                ) : null}
                                {contentSectionItem?.dataType ===
                                "PDF_UPLOAD" ? (
                                  <Grid item xs={12}>
                                    <Box>
                                      <PDFPicker
                                        l
                                        label="Select PDF"
                                        // fileLabel={true}
                                        id={data}
                                        name={data}
                                        value={contentSectionItem?.data}
                                        onChange={(file) => {
                                          handlePDFUpload(
                                            file,
                                            "content",
                                            data,
                                            formProps.setFieldValue
                                          );
                                        }}
                                        error={
                                          errorData && touchedData
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          errorData && touchedData
                                            ? errorData
                                            : false
                                        }
                                        loading={Boolean(
                                          fileUploadLoading === data
                                        )}
                                        dropBoxGridSize={6}
                                        previewGridSize={6}
                                        noPreviewImageBaseURL={true}
                                      />
                                    </Box>
                                  </Grid>
                                ) : null}
                                {contentSectionItem.dataType === "PDF_LINK" ? (
                                  <Grid item xs={12}>
                                    <Box>
                                      <TextField
                                        size="small"
                                        type="url"
                                        label={
                                          contentSectionItem?.preferredOption ===
                                          "PDF"
                                            ? "PDF Link"
                                            : null
                                        }
                                        name={data}
                                        value={contentSectionItem?.data}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        error={
                                          errorData && touchedData
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          errorData && touchedData
                                            ? errorData
                                            : null
                                        }
                                        fullWidth={true}
                                        // multiline={true}
                                      />
                                    </Box>
                                  </Grid>
                                ) : null}
                                <Grid item xs={12}>
                                  <Box>
                                    <TextField
                                      size="small"
                                      // label={
                                      //     "PDF Title"
                                      // }
                                      label={
                                        contentSectionItem?.preferredOption ===
                                        "PDF"
                                          ? "PDF Title"
                                          : null
                                      }
                                      name={title}
                                      value={contentSectionItem?.title}
                                      onChange={formProps.handleChange}
                                      onBlur={formProps.handleBlur}
                                      error={
                                        errorImageVideoTitle &&
                                        touchedImageVideoTitle
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errorImageVideoTitle &&
                                        touchedImageVideoTitle
                                          ? errorImageVideoTitle
                                          : null
                                      }
                                      fullWidth={true}
                                      multiline={true}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          ) : null}
                        </Grid>
                      </Box>
                    </Collapse>
                  );
                }
              )}
            </TransitionGroup>
            {/* <Box sx={{ mr: 2, mt: 3, mb: 1 }}>
              <MDButton
                variant="outlined"
                onClick={() =>
                  push({
                    preferredOption: "TEXT",
                    dataType: "TEXT",
                    data: "",
                    title: "",
                  })
                }
              >
                Add New Content
              </MDButton>
            </Box> */}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};

export default PressReleasedFormContent;
