import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get, map } from 'lodash';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import WelcomeTab from './WelcomeTab/WelcomeTabContentList';
import HomeTab from './HomeTab';
import { useDispatch } from 'react-redux';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';

const tabs = [{ title: 'Welcome' }, { title: 'Home' }];
const TabMap = {
	Welcome: <WelcomeTab />,
	Home: <HomeTab />,
};

const AppContent = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [tabValue, setTabValue] = useState(get(location, 'state.tabIndex', 0));

	useEffect(() => {
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<MDCard>
			<MDCardHeader title='App config' />
			<CardContent>
				<Grid container spacing={0} className='h-100 align-content-start'>
					<Grid item xs={12}>
						<Box sx={{ bgcolor: (theme) => theme.palette.background.default }}>
							<Tabs value={tabValue} onChange={handleTabChange} variant='scrollable'>
								{map(tabs, (item, index) => (
									<Tab label={item.title} key={index} />
								))}
							</Tabs>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box>{TabMap[tabs[tabValue].title]}</Box>
					</Grid>
				</Grid>
			</CardContent>
		</MDCard>
	);
};

export default AppContent;
