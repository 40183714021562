import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import MDCard from 'components/md-components/card/MDCard';
import DetailCardSkeleton from 'components/md-components/skeleton/DetailCardSkeleton';
import { Avatar } from '@mui/material';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import translate from 'hooks/translate';
import moment from 'moment';
import { get } from 'lodash';
import stringToColor from 'components/hooks/stringToColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CMToolTip from 'components/common/tooltip/CMToolTip';
import Label from 'components/common/Label';

export const COLUMNS_DATA = [
	{
		field: 'bookingId',
		headerName: translate('consultant_booking_id'),
		width: 180,
		editable: false,
	},
	{
		field: 'consultationDate',
		headerName: translate('consultant_consultation_date'),
		type: 'date',
		width: 150,
		editable: false,
		valueGetter: ({ row }) => moment(get(row, 'consultationDate', 'NA'), ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY'),
	},
	{
		field: 'userDetail',
		headerName: 'User Detail',
		width: 250,
		editable: false,
		renderCell: ({ row }) => (
			<Stack direction='row' spacing={2} alignItems='center'>
				<Avatar
					sx={{
						backgroundColor: stringToColor(get(row, 'userId.firstName', 'NA')),
						width: 38,
						height: 38,
					}}
					alt={get(row, 'userId.firstName', '')}
					src={get(row, 'userId.profilePictureURL', 'NA')}
				/>

				<Stack direction='column'>
					<Typography variant='subtitle2'>
						{get(row, 'userId.firstName', '') + ' ' + get(row, 'userId.lastName', '')}
					</Typography>
					<Typography variant='caption' color='text.secondary'>
						{get(row, 'primaryBookingId.email', '')}
					</Typography>
				</Stack>
			</Stack>
		),
	},
	{
		field: 'serviceType',
		headerName: 'Appointment Type',
		width: 200,
		renderCell: ({ row }) => (
			<Box width='100%' textTransform='capitalize'>
				{get(row, 'primaryBookingId.serviceType', '').replace('Consultation', '')}
			</Box>
		),
	},
	{
		field: 'preQAStatus',
		headerName: 'Pre Q/A Template',
		type: 'action',
		width: 170,
		renderCell: ({ row }) => (
			<Stack direction='column' spacing={0} alignItems='center'>
				{row.preQuestionFormId?.templateName ? (
					<Box display='flex' flexDirection='row' spacing={1} alignItems='center'>
						<CMToolTip
							icon={
								<Label
									variant='ghost'
									color='success'
									sx={{
										maxWidth: '110px',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: 'block',
										height: 'auto',
										lineHeight: 1,
										py: 0.8,
									}}
								>
									{row.preQuestionFormId?.templateName}
								</Label>
							}
							title={row.preQuestionFormId?.templateName}
						/>
					</Box>
				) : (
					<Stack direction='row' spacing={1} alignItems='center'>
						<CMToolTip
							icon={
								<Label variant='ghost' color='warning'>
									Not Created
								</Label>
							}
							title='You are not created default template for user, click side icon and go to create default template first.'
						/>
					</Stack>
				)}
			</Stack>
		),
	},
	{
		field: 'paymentStatus',
		headerName: translate('consultant_payment_status'),
		width: 150,
		editable: false,
		headerAlign: 'center',
		align: 'center',
		renderCell: ({ row }) => {
			const paymentStatus = get(row, 'primaryBookingId.paymentStatus', '');
			return (
				<Label color={paymentStatus === 'PAID' ? 'success' : paymentStatus === 'UNPAID' ? 'warning' : 'error'}>
					{paymentStatus}
				</Label>
			);
		},
	},
	{
		field: 'bookingStatus',
		headerName: translate('consultant_booking_status'),
		type: 'boolean',
		width: 150,
		editable: false,
		renderCell: ({ row }) => (
			<Box className='w-100 text-center'>
				{get(row, 'primaryBookingId.bookingStatus', false) ? (
					<CheckCircleIcon color='success' />
				) : (
					<CancelIcon color='error' />
				)}
			</Box>
		),
	},
];

const EndUserAppointments = ({ list }) => {
	const isLoading = useSelector((state) => state.endUser.loading);

	if (isLoading) {
		return <DetailCardSkeleton />;
	}

	return (
		<MDCard>
			<Box className='detail-box-header'>
				<Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
					<Typography variant='body1' className='detail-box-heading'>
						Appointments
					</Typography>
				</Stack>
			</Box>
			<CardContent>
				<Box>
					<MDDataGrid
						rows={list ?? []}
						columns={COLUMNS_DATA}
						getRowId={(row) => row._id}
						pagination
						disableSelectionOnClick={true}
						loading={isLoading}
						autoHeight
						sx={{ border: 'none' }}
					/>
				</Box>
			</CardContent>
		</MDCard>
	);
};

export default EndUserAppointments;
