import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import forgetPasswordImg from "../../../assets/images/forget-password.svg";
import ForgetPassword from "../../../components/pages/password-reset/Password-ResetPage";

const ForgetPasswordReset = () => {
  const GOOGLE_RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
  return (
    <>
      <Box
        component="section"
        sx={{ py: { sm: 6.4, xs: 4 } }}
        className="d-flex align-items-center justify-content-center w-100"
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={8} md={7} xl={6}>
              <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
                <ForgetPassword />
              </GoogleReCaptchaProvider>
            </Grid>

            <Grid item xs={12} sm={8} md={5} lg={5} xl={4}>
              <Box
                component="img"
                src={forgetPasswordImg}
                className="img-fluid"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ForgetPasswordReset;
