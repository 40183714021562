import AxiosDefault from "services/AxiosDefaultSetting";

export const GetLanguageConfigList = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/language/get`,
  });
  return response;
};
export const AddLanguage = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/language/add`,
    data: data,
  });
  return response;
};
export const StatusUpdateLanguage = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/language/status-update/${id}`,
    data: data,
  });
  return response;
};
export const GetLanguageMacro = async ({ langId, data }) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/language/macro/get/${langId}`,
    data: data,
  });
  return response;
};
export const AddLanguageMacro = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/language/macro/add`,
    data: data,
  });
  return response;
};
export const ValueUpdateLanguageMacro = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/language/macro-value-update/${id}`,
    data: data,
  });
  return response;
};
export const StatusUpdateLanguageMacro = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/language/macro-status-update/${id}`,
    data: data,
  });
  return response;
};
