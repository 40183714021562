import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetUserGeneralConfigList,
  CreateUserGeneralConfig,
  DeleteUserGeneralConfig,
  StatusUpdateUserGeneralConfig,
  UpdateUserGeneralConfig,
  IconImageUpload,
} from "api/user-config/GeneralConfigAPI";
import { get } from "lodash";

const initialState = {
  list: [],
  iconURL:"",
  loading: false,
};

export const getUserGeneralConfig = createAsyncThunk(
  "generalConfig/getUserGeneralConfig",
  async (data) => {
    return await GetUserGeneralConfigList(data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const iconImageUpload = createAsyncThunk(
  "common/iconImageUpload",
  async (data) => {
    try {
      const response = await IconImageUpload(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const createUserGeneralConfig = createAsyncThunk(
  "generalConfig/createUserGeneralConfig",
  async (data) => {
    return await CreateUserGeneralConfig(data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const updateUserGeneralConfig = createAsyncThunk(
  "generalConfig/updateUserGeneralConfig",
  async (data) => {
    return await UpdateUserGeneralConfig(data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const statusUpdateUserGeneralConfig = createAsyncThunk(
  "generalConfig/statusUpdateUserGeneralConfig",
  async (data) => {
    return await StatusUpdateUserGeneralConfig(data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const deleteUserGeneralConfig = createAsyncThunk(
  "generalConfig/deleteUserGeneralConfig",
  async (data) => {
    return await DeleteUserGeneralConfig(data)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);

const GeneralConfigSlice = createSlice({
  name: "generalConfig",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserGeneralConfig.pending, (state) => {
      state.list = [];
      state.loading = true;
    });
    builder.addCase(getUserGeneralConfig.fulfilled, (state, { payload }) => {
      state.list = get(payload, "data", []);
      state.loading = false;
    });
    builder.addCase(getUserGeneralConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createUserGeneralConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUserGeneralConfig.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createUserGeneralConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserGeneralConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserGeneralConfig.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserGeneralConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateUserGeneralConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusUpdateUserGeneralConfig.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateUserGeneralConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserGeneralConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUserGeneralConfig.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUserGeneralConfig.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(iconImageUpload.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(iconImageUpload.fulfilled, (state, { payload }) => {
      state.iconURL = get(payload, "data", "");
      state.loading = false;
    })
    builder.addCase(iconImageUpload.rejected, (state) => {
      state.loading = false;
    })
  },
});

export default GeneralConfigSlice.reducer;
