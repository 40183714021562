import React, { useState } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import { offlineConsultantUserVerification } from "store/slice/user-management/ConsultantUserSlice";
import OfflineUserDetails from "components/pages/user-management/consultant-user/doc-verification/OfflineUserDetails";
import OfflineUserInformation from "components/pages/user-management/consultant-user/doc-verification/OfflineUserInformation";
import { Box, CardActions } from "@mui/material";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import axios from "axios";
import cookie, { cookieGet } from "components/hooks/cookie";
import { toast } from "react-toastify";

const ConsultantDocumentVerificationDetails = () => {
  const location = useLocation();
  const userId = get(location, "state.userId");
  const userType = get(location, "state.userType");
  const offlineUser = get(location, "state.offlineUser");
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState(false);

  const handleOfflineUserVerification = async () => {
    const payload = {
      serviceTypeOffline: true,
    };
    // if (payload && offlineUser?.serviceTypeOffline === false) {
    // }
    if (payload) {
      try {
        const authToken = cookieGet("auth_token");
        const userData = cookie.get("consultant_data");
        const authorization = authToken;

        await axios
          .patch(
            `${process.env.REACT_APP_API_END_POINT}/admin/user-management/consultant/offline-verify/${userId}`,
            payload,
            {
              headers: {
                authorization: authorization,
              },
            }
          )
          .then((result) => {
            if (get(result, "status", false)) {
              console.log("result :>> ", result);
              toast.success(result.data.message);
              setIsVerified(true);
            } else {
              toast.error(
                get(result, "message", "Something went wrong in upload image")
              );
            }
          });
      } catch (error) {}
    }
  };

  return (
    <Stack direction="column" spacing={3}>
      <MDCard>
        <MDCardHeader title="Consultant User Profile" />
      </MDCard>
      <OfflineUserInformation
        serviceTypeOffline={offlineUser?.serviceTypeOffline}
        userDetails={offlineUser?.consultantId}
        userType={userType}
      />
      <OfflineUserDetails
        userDetails={offlineUser?.offlineData[0]}
        userType={userType}
      />
      {/* <ConsultantUserVerify userDetails={userDetails} userType={userType} /> */}

      {!offlineUser?.serviceTypeOffline && !isVerified && (
        <CardActions className="justify-content-center">
          <Box className="text-center px-3 pb-2">
            <MDLoadingButton
              type="button"
              label="Verify"
              variant="contained"
              onClick={handleOfflineUserVerification}
              //   loading={props.isSubmitting && verifyLoading}
            />
          </Box>
        </CardActions>
      )}
    </Stack>
  );
};

export default ConsultantDocumentVerificationDetails;
