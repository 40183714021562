import { get, isEmpty, map } from "lodash";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import DetailCardLabel from "./DetailCardLabel";

const QuestionDetail = (props) => {
  const { questionIndex, questionData, questionTitle = false } = props;

  return (
    <Box component="div">
      <Stack direction="column" spacing={1.5}>
        {questionTitle ? (
          <Box className="px-2">
            <Stack direction="row" flexWrap="wrap" spacing={1}>
              <DetailCardLabel label="Question" />
              <Typography
                variant="body1"
                className="text-capitalize fw-regular"
                color="black"
              >
                {get(questionData, "questionTitle", "")}
              </Typography>
            </Stack>
          </Box>
        ) : null}
        {!isEmpty(questionData) ? (
          <Box className="px-2">
            <Stack direction="row" flexWrap="wrap" spacing={1}>
              <DetailCardLabel label="Input Type" />
              <Typography
                variant="body1"
                className="text-capitalize fw-regular"
                color="black"
              >
                {get(questionData, "inputType", "")}
              </Typography>
            </Stack>
          </Box>
        ) : null}
        {get(questionData, "inputType", "") === "scale" ? (
          <Box className="px-2">
            <Stack direction="column" spacing={1}>
              <Box>
                <Stack direction="row" flexWrap="wrap" spacing={1}>
                  <DetailCardLabel label="Minimum Value" />
                  <Typography
                    variant="body1"
                    className="text-capitalize fw-regular"
                    color="black"
                  >
                    {get(questionData, "minValue", "")}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack direction="row" flexWrap="wrap" spacing={1}>
                  <DetailCardLabel label="Maximum Value" />
                  <Typography
                    variant="body1"
                    className="text-capitalize fw-regular"
                    color="black"
                  >
                    {get(questionData, "maxValue", "")}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack direction="row" flexWrap="wrap" spacing={1}>
                  <DetailCardLabel label="Step Value" />
                  <Typography
                    variant="body1"
                    className="text-capitalize fw-regular"
                    color="black"
                  >
                    {get(questionData, "stepValue", "")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        ) : null}
        {get(questionData, "inputType", "") === "radio" ||
        get(questionData, "inputType", "") === "checkbox" ? (
          <Box className="px-2">
            <Stack direction={{ sm: "row", xs: "column" }} spacing={1}>
              <DetailCardLabel label="Options" />
              <Box overflow="auto">
                <Table
                  size="small"
                  stickyHeader={true}
                  aria-label={`question-options-table-${questionIndex}`}
                  className="border"
                  sx={{
                    minWidth: 240,
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: 1,
                        },
                      }}
                    >
                      <TableCell>Label</TableCell>
                      <TableCell align="right">Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(
                      get(questionData, "options", []),
                      (option, optionIndex) => (
                        <TableRow
                          key={optionIndex}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            "& .MuiTableCell-root": {
                              py: 0.5,
                              px: 1,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {option.label}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {option.value}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Stack>
          </Box>
        ) : null}
        {map(get(questionData, "options", []), (option) => {
          if (get(option, "subQuestionStatus", false)) {
            return map(
              get(option, "subQuestions", []),
              (subQuestion, subQuestionIndex) => {
                return (
                  <Stack
                    direction="column"
                    spacing={1.5}
                    key={subQuestionIndex}
                    className="border-top border-light pt-2"
                  >
                    <Box className="px-2">
                      <Stack direction="row" flexWrap="wrap" spacing={1}>
                        <Typography
                          variant="body1"
                          fontSize="18px"
                          color="grey.800"
                          className="text-capitalize fw-medium"
                        >
                          Sub Question Option {get(option, "label", "")}
                        </Typography>
                      </Stack>
                    </Box>
                    {!isEmpty(subQuestion) ? (
                      <Box className="px-2">
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                          <DetailCardLabel label="Question Title" />
                          <Typography
                            variant="body1"
                            className="text-capitalize fw-regular"
                            color="black"
                          >
                            {get(subQuestion, "questionTitle", "")}
                          </Typography>
                        </Stack>
                      </Box>
                    ) : null}
                    {!isEmpty(subQuestion) ? (
                      <Box className="px-2">
                        <Stack direction="row" flexWrap="wrap" spacing={1}>
                          <DetailCardLabel label="Input Type" />
                          <Typography
                            variant="body1"
                            className="text-capitalize fw-regular"
                            color="black"
                          >
                            {get(subQuestion, "inputType", "")}
                          </Typography>
                        </Stack>
                      </Box>
                    ) : null}
                    {get(subQuestion, "inputType", "") === "scale" ? (
                      <Box className="px-2">
                        <Stack direction="column" spacing={1}>
                          <Box>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              <DetailCardLabel label="Minimum Value" />
                              <Typography
                                variant="body1"
                                className="text-capitalize fw-regular"
                                color="black"
                              >
                                {get(subQuestion, "minValue", "")}
                              </Typography>
                            </Stack>
                          </Box>
                          <Box>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              <DetailCardLabel label="Maximum Value" />
                              <Typography
                                variant="body1"
                                className="text-capitalize fw-regular"
                                color="black"
                              >
                                {get(subQuestion, "maxValue", "")}
                              </Typography>
                            </Stack>
                          </Box>
                          <Box>
                            <Stack direction="row" flexWrap="wrap" spacing={1}>
                              <DetailCardLabel label="Step Value" />
                              <Typography
                                variant="body1"
                                className="text-capitalize fw-regular"
                                color="black"
                              >
                                {get(subQuestion, "stepValue", "")}
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                    ) : null}
                    {get(subQuestion, "inputType", "") === "radio" ||
                    get(subQuestion, "inputType", "") === "checkbox" ? (
                      <Box className="px-2">
                        <Stack
                          direction={{ sm: "row", xs: "column" }}
                          spacing={1}
                        >
                          <DetailCardLabel label="Options" />
                          <Box overflow="auto">
                            <Table
                              size="small"
                              stickyHeader={true}
                              aria-label={`question-option-sub-question-options-table-${subQuestionIndex}`}
                              className="border"
                              sx={{
                                minWidth: 240,
                              }}
                            >
                              <TableHead>
                                <TableRow
                                  sx={{
                                    "& .MuiTableCell-root": {
                                      padding: 1,
                                    },
                                  }}
                                >
                                  <TableCell>Label</TableCell>
                                  <TableCell>Value</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {map(
                                  get(subQuestion, "options", []),
                                  (
                                    subQuestionOption,
                                    subQuestionOptionIndex
                                  ) => (
                                    <TableRow
                                      key={subQuestionOptionIndex}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                        "& .MuiTableCell-root": {
                                          py: 0.5,
                                          px: 1,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {subQuestionOption.label}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="right"
                                      >
                                        {subQuestionOption.value}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Stack>
                      </Box>
                    ) : null}
                  </Stack>
                );
              }
            );
          }
        })}
      </Stack>
    </Box>
  );
};

export default QuestionDetail;
