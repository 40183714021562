import React from "react";
import { get, map } from "lodash";
import moment from "moment";

// MUI
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Other
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player/lazy";
import { Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Custom
import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import stringToColor, {
  getContrastColor,
} from "components/hooks/stringToColor";
import EditorHTMLViewBox from "components/utility/rich-text-editor/EditorHTMLViewBox";

const QuotesDetail = (props) => {
  const { quotesData } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="h5"
              fontWeight={700}
              textTransform="capitalize"
            >
              {get(quotesData, "title", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography
              variant="body1"
              fontWeight={400}
              textTransform="capitalize"
            >
              {get(quotesData, "subTitle", "")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="pt-1">
            <Stack
              direction={{ sm: "row", xs: "column" }}
              alignItems={{ sm: "center", xs: "flex-start" }}
              spacing={1}
            >
              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  className="gap-2"
                >
                  <Typography variant="body2" textTransform="capitalize">
                    Created By
                  </Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    fontWeight={600}
                  >
                    {get(quotesData, "createdBy", "") === "MASTER_ADMIN" ? (
                      <>
                        {get(quotesData, "approvedBy.firstName", "") +
                          " " +
                          get(quotesData, "approvedBy.lastName", "")}
                      </>
                    ) : (
                      <>
                        {get(quotesData, "consultantId.firstName", "") +
                          " " +
                          get(quotesData, "consultantId.lastName", "")}
                      </>
                    )}
                  </Typography>
                  <Chip
                    label={get(quotesData, "createdBy", "").replace("_", " ")}
                    color="primary"
                    size="small"
                  />
                </Stack>
              </Box>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ display: { sm: "block", xs: "none" } }}
              />
              <Box>
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="body2" textTransform="capitalize">
                    Published on
                  </Typography>
                  <Typography
                    variant="body2"
                    textTransform="capitalize"
                    fontWeight={600}
                  >
                    {moment(get(quotesData, "createdAt", ""), [
                      "MM-DD-YYYY",
                      "YYYY-MM-DD",
                    ]).format("LL")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className="pt-1">
            <Stack direction="row" flexWrap="wrap" className="gap-2">
              <Chip
                label={get(quotesData, "language.title", "NA")}
                size="small"
                sx={{
                  backgroundColor: stringToColor(
                    get(quotesData, "language.title", "NA")
                  ),
                  color: getContrastColor(
                    stringToColor(get(quotesData, "language.title", "NA"))
                  ),
                }}
              />
              {map(
                get(quotesData, "categories", []),
                (category, categoryIndex) => {
                  return (
                    <React.Fragment key={categoryIndex}>
                      <Chip
                        key={categoryIndex}
                        label={get(category, "foreignData.title", "NA")}
                        size="small"
                        sx={{
                          backgroundColor: stringToColor(
                            get(category, "foreignData.title", "NA")
                          ),
                          color: getContrastColor(
                            stringToColor(
                              get(category, "foreignData.title", "NA")
                            )
                          ),
                        }}
                      />
                      {map(
                        get(category, "subCategories", []),
                        (subCategory, subCategoryIndex) => {
                          return (
                            <Chip
                              key={subCategoryIndex}
                              label={get(
                                subCategory,
                                "foreignData.title",
                                "NA"
                              )}
                              size="small"
                              sx={{
                                backgroundColor: stringToColor(
                                  get(subCategory, "foreignData.title", "NA")
                                ),
                                color: getContrastColor(
                                  stringToColor(
                                    get(subCategory, "foreignData.title", "NA")
                                  )
                                ),
                              }}
                            />
                          );
                        }
                      )}
                    </React.Fragment>
                  );
                }
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
           Quotes Type
          </Typography>
          <Box>
            {
              <React.Fragment>
                <EditorHTMLViewBox
                  data={get(quotesData, "description", "NA")}
                />
                <Typography
                  variant="caption"
                  component="p"
                  textAlign="center"
                  color="grey.700"
                  className="mt-1"
                >
                  {get(quotesData, "author", "NA")}
                </Typography>
              </React.Fragment>
            }
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ p: { md: 2, xs: 1 } }}>
          <Typography variant="h6" fontWeight={600} gutterBottom={true}>
            Personal Information
          </Typography>
          <Box className="mt-3 px-1">
            <Stack
              direction={{ sm: "row", xs: "column" }}
              flexWrap="wrap"
              sx={{ rowGap: 1.5, columnGap: 4 }}
            >
              <DetailWrapper fullWidth={true}>
                <DetailTitle title="Name" colon={true} />
                <DetailValue>
                  <Stack direction="row" flexWrap="wrap" className="gap-2">
                    <Typography variant="body1">
                      {get(quotesData, "createdBy", "") === "MASTER_ADMIN" ? (
                        <>
                          {get(quotesData, "approvedBy.firstName", "") +
                            " " +
                            get(quotesData, "approvedBy.lastName", "")}
                        </>
                      ) : (
                        <>
                          {get(quotesData, "consultantId.firstName", "") +
                            " " +
                            get(quotesData, "consultantId.lastName", "")}
                        </>
                      )}
                    </Typography>
                    <Chip
                      label={get(quotesData, "createdBy", "").replace("_", " ")}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className="ms-2"
                    />
                  </Stack>
                </DetailValue>
              </DetailWrapper>
              <DetailWrapper fullWidth={true}>
                <DetailTitle title="E-mail" colon={true} />
                <DetailValue>
                  {get(quotesData, "createdBy", "") === "MASTER_ADMIN" ? (
                    <>{get(quotesData, "approvedBy.email", "")}</>
                  ) : (
                    <>{get(quotesData, "consultantId.email", "")}</>
                  )}
                </DetailValue>
              </DetailWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider light={true} flexItem />
      </Grid>
    </Grid>
  );
};

export default QuotesDetail;
