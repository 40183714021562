import React, { useState } from "react";
import { FieldArray } from "formik";
import { find, map, filter, isEmpty, get } from "lodash";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Autocomplete, Chip, Collapse, IconButton, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RichTextEditor from "components/utility/rich-text-editor/RichTextEditor";
import FormCheckbox from "./FormCheckbox";
import FilePicker from "./FilePicker";

const LanguageTranslationForm = ({ formProps, languageConfigList }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const getLanguageTitle = (id) => {
    const data = find(languageConfigList, (language) => language?._id === id);
    return data?.title ?? "";
  };

  return (
    <FieldArray name="languages">
      {() => (
        <Box>
          {map(formProps?.values?.languages, (field, index, { length }) => {
            const title = `languages[${index}].value`;
            const description = `languages[${index}].description`;
            const descriptionImgTitle = `languages[${index}].descriptionImgTitle`;
            const descriptionImgUrl = `languages[${index}].descriptionImgUrl`;
            const footerTitle = `languages[${index}].footerTitle`;
            const footerSubTitle = `languages[${index}].footerSubTitle`;
            const footerDescription = `languages[${index}].footerDescription`;
            const seoTitle = `languages[${index}].seoTitle`;
            const seoDescription = `languages[${index}].seoDescription`;
            const seoMetaTags = `languages[${index}].seoMetaTags`;
            const isTranslate = `languages[${index}].isTranslate`;
            const addInFooter = `languages[${index}].addInFooter`;
            const addBlogPage = `languages[${index}].addBlogPage`;

            const errors = get(formProps, `errors.languages[${index}]`, {});
            const touched = get(formProps, `touched.languages[${index}]`, {});

            return (
              <React.Fragment key={index}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="h6">{getLanguageTitle(field?.languageId)}</Typography>
                  <IconButton onClick={() => setOpenIndex(index)}>
                    <Box
                      component={openIndex === index ? ExpandLessIcon : ExpandMoreIcon}
                      color="inherit"
                      sx={{ fontSize: "1.25rem" }}
                    />
                  </IconButton>
                </Box>
                <Collapse in={openIndex === index}>
                  <Grid container spacing={2} key={index} className={index !== length - 1 ? "mb-3" : ""}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Title"
                        name={title}
                        value={field.value}
                        onChange={formProps?.handleChange}
                        onBlur={formProps?.handleBlur}
                        error={Boolean(errors.value && touched.value)}
                        helperText={errors.value && touched.value ? errors.value : null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        label="Description"
                        name={description}
                        value={field.description ?? ""}
                        setFieldValue={formProps.setFieldValue}
                        onBlur={formProps.handleBlur}
                        error={Boolean(errors.description && touched.description)}
                        helperText={errors.description && touched.description ? errors.description : null}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Image Title"
                        name={descriptionImgTitle}
                        value={field.descriptionImgTitle}
                        onChange={formProps?.handleChange}
                        onBlur={formProps?.handleBlur}
                        error={Boolean(errors.descriptionImgTitle && touched.descriptionImgTitle)}
                        helperText={errors.descriptionImgTitle && touched.descriptionImgTitle ? errors.descriptionImgTitle : null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FilePicker
                        label="Image Upload"
                        id="data"
                        name={descriptionImgUrl}
                        value={field.descriptionImgUrl}
                        onChange={(file) => formProps.setFieldValue(descriptionImgUrl, file)}
                        error={Boolean(errors.descriptionImgUrl && touched.descriptionImgUrl)}
                        helperText={errors.descriptionImgUrl && touched.descriptionImgUrl ? errors.descriptionImgUrl : null}
                        dropBoxGridSize={6}
                        previewGridSize={6}
                        noPreviewImageBaseURL={true}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth={true}
                        size="small"
                        label="Footer Title"
                        name={footerTitle}
                        value={field.footerTitle}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={Boolean(errors.footerTitle && touched.footerTitle)}
                        helperText={errors.footerTitle && touched.footerTitle ? errors.footerTitle : null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth={true}
                        size="small"
                        label="Footer Sub Title"
                        multiline
                        rows={3}
                        name={footerSubTitle}
                        value={field.footerSubTitle}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={Boolean(errors.footerSubTitle && touched.footerSubTitle)}
                        helperText={errors.footerSubTitle && touched.footerSubTitle ? errors.footerSubTitle : null}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        label="Footer Description"
                        name={footerDescription}
                        value={field.footerDescription ?? ""}
                        setFieldValue={formProps.setFieldValue}
                        onBlur={formProps.handleBlur}
                        error={Boolean(errors.footerDescription && touched.footerDescription)}
                        helperText={errors.footerDescription && touched.footerDescription ? errors.footerDescription : null}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Seo Title"
                        name={seoTitle}
                        value={field.seoTitle}
                        error={Boolean(errors.seoTitle && touched.seoTitle)}
                        helperText={errors.seoTitle && touched.seoTitle ? errors.seoTitle : null}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Seo Description"
                        name={seoDescription}
                        value={field.seoDescription}
                        error={Boolean(errors.seoDescription && touched.seoDescription)}
                        helperText={errors.seoDescription && touched.seoDescription ? errors.seoDescription : null}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        options={[]}
                        name={seoMetaTags}
                        value={field.seoMetaTags}
                        onChange={(e, value) => {
                          formProps.setFieldValue(seoMetaTags, value);
                        }}
                        onBlur={formProps.handleBlur}
                        multiple
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Meta Tags"}
                            error={errors.seoMetaTags && touched.seoMetaTags ? true : false}
                            helperText={errors.seoMetaTags && touched.seoMetaTags ? errors.seoMetaTags : null}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} display="flex" gap={2} flexWrap="wrap" justifyContent="space-between">
                      <FormCheckbox
                        name={isTranslate}
                        checked={field.isTranslate}
                        onChange={(e) => formProps.setFieldValue(isTranslate, e.target.checked)}
                        label="Is Translate"
                      />
                      <FormCheckbox
                        name={addInFooter}
                        checked={field.addInFooter}
                        onChange={(e) => formProps.setFieldValue(addInFooter, e.target.checked)}
                        label="Add in footer"
                      />
                      <FormCheckbox
                        name={addBlogPage}
                        checked={field.addBlogPage}
                        onChange={(e) => formProps.setFieldValue(addBlogPage, e.target.checked)}
                        label="Add blog page"
                      />
                    </Grid>
                  </Grid>
                </Collapse>
                {index !== length - 1 && <Divider light={true} flexItem={true} sx={{ pt: { sm: 2, xs: 0 } }} />}
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </FieldArray>
  );
};

export default LanguageTranslationForm;

export const languageInitialValue = (languageList, preFilledData, type, contentList) => {
  return map(languageList, (language) => {
    const initialValue = filter(preFilledData, (data) => data?.languageId === language?._id)[0];
    const initialValue2 = filter(contentList, (data) => data?.language === language?._id)[0];

    const value = {
      languageId: initialValue?.languageId || language?._id,
      value: initialValue?.value || "",
      isTranslate: initialValue?.isTranslate || false,
      description: initialValue2?.description || "",
      descriptionImgTitle: initialValue2?.descriptionImgTitle || "",
      descriptionImgUrl: initialValue2?.descriptionImgUrl || "",
      footerTitle: initialValue2?.footerTitle || "",
      footerSubTitle: initialValue2?.footerSubTitle || "",
      footerDescription: initialValue2?.footerDescription || "",
      seoTitle: initialValue2?.seoTitle || "",
      seoDescription: initialValue2?.seoDescription || "",
      seoMetaTags: initialValue2?.seoMetaTags || [],
      addInFooter: initialValue2?.addInFooter || "",
      addBlogPage: initialValue2?.addBlogPage || "",
    };

    if (isEmpty(preFilledData)) {
      value.type = type;
    }
    return value;
  });
};
