import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

import Header from "layout/header/Header";
import SideBar from "layout/SideBar";
import Footer from "layout/footer/Footer";
import PathBreadcrumbs from "components/layout/PathBreadcrumbs";

const DashboardLayout = (props) => {
  const drawerWidth = 300;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <React.Fragment>
      <Box component="div" sx={{ display: "flex" }}>
        <SideBar
          {...props}
          drawerWidth={drawerWidth}
          drawerOpen={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            position: "relative",
            width: { lg: `calc(100% - ${drawerWidth}px)` },
            minHeight: "100vh",
            display: "grid",
            gridTemplateRows: "55px calc(100% - 55px) 40px",
            // gap: "0.5rem",
          }}
        >
          <Header
            drawerWidth={drawerWidth}
            drawerOpen={drawerOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Box
            component="main"
            sx={{
              padding: "0 15px 0 15px",
              marginBottom: 1,
              overflowX: "hidden",
            }}
          >
            <PathBreadcrumbs />
            <Box sx={{ height: "calc(100% - 60px)" }}>
              <Outlet />
            </Box>
          </Box>
          <Footer />
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default DashboardLayout;
