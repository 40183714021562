import { Formik } from "formik";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
// MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MDDialog from "components/md-components/dialog/MDDialog";
// Custom
import MultiSelectSpecialization, {
  getInitSpecialization,
  getInitSubSpecialization,
  getSpecializationSubmitData,
} from "components/common/MultiSelectSpecialization";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
// Store
import { updateConsultantSpecialization } from "store/slice/user-management/ConsultantProfileSlice";
import { getVerifiedConsultantUserDetail } from "store/slice/user-management/ConsultantUserSlice";

const ProfessionalDomainForm = (props) => {
  const { title, dialogOpen, handleDialogClose, userDetails } = props;

  const dispatch = useDispatch();
  const specializationList = useSelector(
    (state) => state.generalConfigData.specializationData
  );
  const isLoading = useSelector((state) => state.consultantProfile.loading);

  const formValidations = Yup.object().shape({
    specialization: Yup.array()
      .min(1, "Please Select Specializations")
      .max(3, "Max 3 Specializations"),
    subSpecialization: Yup.array()
      .min(1, "Please Select Specializations")
      .max(3, "Max 3 Specializations"),
  });

  const handleSubmit = (values) => {
    const apiData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: {
        specialization: getSpecializationSubmitData(
          values,
          values?.child,
          "specialization",
          "child"
        ),
      },
    };
    dispatch(updateConsultantSpecialization(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          dispatch(
            getVerifiedConsultantUserDetail({
              consultantUserId: get(userDetails, "_id", ""),
            })
          );
          toast.success(
            get(result, "message", "Specialization Update Successfully")
          );
          handleDialogClose();
        } else {
          toast.error(get(result, "message", "Something went wrong"));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={title}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          specialization: getInitSpecialization(
            userDetails,
            specializationList,
            "specialization"
          ),
          child: getInitSubSpecialization(
            userDetails,
            specializationList,
            "specialization",
            "child"
          ),
        }}
        validationSchema={formValidations}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(formProps) => (
          <form
            onSubmit={formProps.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <MultiSelectSpecialization
                initialData={userDetails}
                specializations={specializationList}
                formProps={formProps}
                fieldName="specialization"
                subFieldName="child"
              />
            </DialogContent>
            <DialogActions>
              <Box>
                <MDLoadingButton
                  type="submit"
                  label="Submit"
                  variant="contained"
                  loading={isLoading || props.isSubmitting}
                />
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default ProfessionalDomainForm;
