import { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteConfirmDialog from 'components/common/DeleteConfirmDialog';
import MDButton from 'components/md-components/button/MDButton';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import DataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import MDSwitch from 'components/md-components/switch/MDSwitch';
import KnowledgeBaseCategoryConfigForm from './KnowledgeBaseCategoryConfigForm';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { Avatar, Button, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const KnowledgeBaseCategoryConfigList = () => {
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);
	const [dialogData, setDialogData] = useState({
		id: '',
		title: '',
		formType: '',
	});

	async function getList() {
		setLoading(true);

		try {
			const response = await AxiosDefaultSetting({
				method: 'GET',
				url: `/admin/knowledge-base/get`,
			});
			if (response.status === 200) {
				setList(response.data.data);
			}
			console.log(response);
		} catch (error) {}

		setLoading(false);
	}
	useEffect(() => {
		getList();
	}, []);

	const handleDialogOpen = useCallback(() => {
		setDialogOpen(true);
	}, []);
	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const updateStatus = useCallback(async (event, row) => {
		setLoading(true);

		const updateValues = {
			id: row._id,
			status: event.target.checked,
		};
		try {
			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/knowledge-base/status-update`,
				data: updateValues,
			});
			if (response.status === 200) {
				await getList();
			}
		} catch (error) {}

		setLoading(false);
	}, []);
	const handleDelete = useCallback(async (id) => {
		setLoading(true);

		try {
			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/knowledge-base/delete`,
				data: { id },
			});
			if (response.status === 200) {
				await getList();
			}
		} catch (error) {}

		setLoading(false);
		setDeleteConfirmation(false);
	}, []);

	const columnsData = useMemo(
		() => [
			{
				field: 'icon',
				headerName: 'Icon',
				width: 200,
				editable: false,
				renderCell: ({ row }) => (
					<Stack direction='row' spacing={2} alignItems='center'>
						<Avatar alt={row?.title} src={row?.icon} variant='square' sx={{ width: 38, height: 38 }} />
					</Stack>
				),
			},
			{
				field: 'title',
				headerName: 'Title',
				width: 240,
				editable: false,
			},
			{
				field: 'createdAt',
				headerName: 'Create Date',
				type: 'date',
				width: 150,
				editable: false,
				valueGetter: ({ row }) => moment(get(row, 'createdAt', 'NA'), ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY'),
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				minWidth: 300,
				renderCell: ({ row }) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label='edit'
								color='primary'
								onClick={() => {
									setDialogData({ rowData: row });
									handleDialogOpen();
								}}
							>
								<EditIcon fontSize='14px' />
							</IconButton>
							<MDSwitch
								checked={row?.status}
								onChange={(event) => {
									updateStatus(event, row);
								}}
								inputProps={{ 'aria-label': row?._id, role: 'switch' }}
								color='success'
								disabled={get(dialogData, 'id', '') === row?._id}
							/>
							<IconButton
								aria-label={`${row?._id}-delete`}
								onClick={() => {
									setDialogData(row);
									setDeleteConfirmation(true);
								}}
							>
								<DeleteIcon color='error' />
							</IconButton>
							<Button component={Link} to={row?._id} variant='contained'>
								Add
							</Button>
						</Box>
					);
				},
			},
		],
		[dialogData, setDeleteConfirmation, handleDialogOpen, updateStatus]
	);

	const CustomToolbar = () => {
		return (
			<DataGridToolbar align='right' divider={true} DisableToolbarColumnsButton={true}>
				<MDButton
					variant='contained'
					label='Create New'
					startIcon={<AddCircleOutlineIcon />}
					fontSize='14px'
					onClick={() => {
						setDialogData({
							...dialogData,
							id: '',
							title: 'Knowledge Base Category Confirmation',
							formType: 'create',
						});
						handleDialogOpen();
					}}
				/>
			</DataGridToolbar>
		);
	};

	return (
		<>
			<MDDataGrid
				rows={list}
				columns={columnsData}
				getRowId={(row) => row._id}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick={true}
				loading={loading}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
				autoHeight
			/>
			<KnowledgeBaseCategoryConfigForm
				dialogData={dialogData}
				dialogOpen={dialogOpen}
				handleDialogClose={handleDialogClose}
				getList={getList}
			/>
			<DeleteConfirmDialog
				title='Knowledge Base Category Confirmation'
				open={deleteConfirmation}
				handleClose={() => setDeleteConfirmation(false)}
				deleteAction={() => handleDelete(get(dialogData, '_id', ''))}
			/>
		</>
	);
};

export default KnowledgeBaseCategoryConfigList;
