import EventSubject from 'services/EventSubject';
import uploadImage from '../common/image';
import uploadVideo from '../common/video';

const create = async (list, jobID, updateJob) => {
	const totalSize = list.reduce((accumulator, currentValue) => accumulator + (currentValue.file?.size || 0), 0);
	let completedSize = 0;

	for (let index = 0; index < list.length; index++) {
		const data = list[index];
		try {
			const fileType = data.meta?.type;
			let result = {};

			switch (fileType) {
				case 'image':
					result = await uploadImage(data, jobID, updateJob, totalSize, completedSize);
					break;
				case 'video':
					result = await uploadVideo(data, jobID, updateJob, totalSize, completedSize);
					break;
				default:
					break;
			}

			if (result.status === 200) {
				completedSize += data.file?.size || 0;
				const percentage = Math.min(parseInt((completedSize / totalSize) * 100), 100);
				const content = `Uploading files: ${percentage}%`;

				EventSubject.next({
					id: jobID,
					content: content,
					value: percentage,
				});
				updateJob(jobID, { value: percentage, content: content });
			} else {
				EventSubject.next({
					id: jobID,
					content: result.message ?? 'File Not Uploaded.',
					completed: false,
					error: true,
				});
				updateJob(jobID, { completed: false, error: true, content: result.message ?? 'File not Uploaded.' });
				return;
			}
		} catch (error) {
			EventSubject.next({
				id: jobID,
				content: error.response?.data?.message ?? 'File Not Uploaded.',
				completed: false,
				error: true,
			});
			updateJob(jobID, { completed: false, error: true, content: error.response?.data?.message ?? 'File not Uploaded.' });
			return;
		}
	}

	EventSubject.next({
		id: jobID,
		content: 'File Uploaded Successfully.',
		value: 100,
		completed: true,
	});
	updateJob(jobID, { value: 100, completed: true, content: 'File Uploaded Successfully.' });
};

export default create;
