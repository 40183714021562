import React from 'react';
// MUI
import CssBaseline from '@mui/material/CssBaseline';
// Other
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css/bundle';
import 'react-lazy-load-image-component/src/effects/blur.css';
// Custom
import ScrollToTop from 'components/common/ScrollToTop';
import ThemeContextProvider from 'theme';
// App
import AppRoutes from './AppRoutes';
import './styles/style.css';

import './i18n';

const App = (props) => {
	return (
		<>
			<ThemeContextProvider>
				<ToastContainer />
				<ScrollToTop />
				<CssBaseline enableColorScheme={true} />
				<AppRoutes {...props} />
			</ThemeContextProvider>
		</>
	);
};

export default App;
