import React from "react";
import { styled } from "@mui/material/styles";
import AccordionSummary from "@mui/material/AccordionSummary";

const MuiAccordionSummary = styled(AccordionSummary)(({ theme, params }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : theme.palette.grey[100],
  flexDirection: params.flexDirection ?? "row",
  ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  ".MuiAccordionSummary-content": {
    margin: theme.spacing(0),
  },
}));

const MDAccordionSummary = (props) => {
  const { children, ...rest } = props;
  return (
    <MuiAccordionSummary {...rest} params={props}>
      {children}
    </MuiAccordionSummary>
  );
};

export default MDAccordionSummary;
