import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { cookieGet } from 'components/hooks/cookie';

import MacroList from 'components/pages/cms/language-config/MacroList';
import QAndAForm from 'components/pages/cms/user-config/consultant-config/QAndAForm';
import QAndATemplateForm from 'components/pages/cms/user-config/consultant-config/QAndATemplateForm';
import DashboardLayout from 'layout/DashboardLayout';
import PageOutlet from 'layout/PageOutlet';

import CountryIdentityProofForm from 'components/pages/cms/country-profile/CountryIdentityProofForm';
import CountryPoliciesForm from 'components/pages/cms/country-profile/CountryPoliciesForm';
// import CountryProfileForm from "components/pages/cms/country-profile/CountryProfileForm";
import AdminUserForm from 'components/pages/role-management/AdminUserForm';
import ArticleCreateUpdateForm from 'pages/private/article-blog/ArticleCreateUpdateForm';
import ArticleDetailView from 'pages/private/article-blog/ArticleDetailView';
import ArticlesList from 'pages/private/article-blog/ArticlesList';
import AppContent from 'pages/private/app-content';
import WelcomeTabForm from 'pages/private/app-content/WelcomeTab/Form';
import BookingDetail from 'pages/private/booking/BookingDetail';
import BookingList from 'pages/private/booking/BookingList';
import QAndADataDetail from 'pages/private/booking/QAndADataDetail';
import CitiesProfileList from 'pages/private/cms/country-profile/CitiesProfileList';
import CountriesProfileList from 'pages/private/cms/country-profile/CountriesProfileList';
import CountryIdentityProofList from 'pages/private/cms/country-profile/CountryIdentityProofList';
import CountryPoliciesList from 'pages/private/cms/country-profile/CountryPoliciesList';
import StatesProfileList from 'pages/private/cms/country-profile/StatesProfileList';
import LanguageList from 'pages/private/cms/language-config/LanguageConfigList';
import PostConsultantQAndA from 'pages/private/cms/user-config/consultant-config/PostConsultantQAndA';
import PreConsultantQAndA from 'pages/private/cms/user-config/consultant-config/PreConsultantQAndA';
import DefaultQuestionList from 'pages/private/cms/user-config/consultant-config/DefaultQuestionList';
import GeneralConfig from 'pages/private/cms/user-config/general-config/GeneralConfig';
import KnowledgeBaseCategoryConfig from 'pages/private/cms/knowledge-base-config/KnowledgeBaseCategoryConfig';
import OrgConfig from 'pages/private/cms/user-config/org-config/OrgConfig';
import SubSpecialization from 'pages/private/cms/user-config/general-config/SubSpecialization';
import Dashboard from 'pages/private/dashboard/Dashboard';
import DietPlanCreateUpdateForm from 'pages/private/health-food/diet-plan/DietPlanCreateUpdateForm';
import DietPlanDetailView from 'pages/private/health-food/diet-plan/DietPlanDetailView';
import DietPlanList from 'pages/private/health-food/diet-plan/DietPlanList';
import MealCreateUpdateForm from 'pages/private/health-food/meal/MealCreateUpdateForm';
import MealDetailView from 'pages/private/health-food/meal/MealDetailView';
import MealList from 'pages/private/health-food/meal/MealList';
import RecipeCreateUpdateForm from 'pages/private/health-food/recipe/RecipeCreateUpdateForm';
import RecipeDetailView from 'pages/private/health-food/recipe/RecipeDetailView';
import RecipesList from 'pages/private/health-food/recipe/RecipesList';
import Profile from 'pages/private/profile/Profile';
import AdminRoleAssign from 'pages/private/role-management/AdminRoleAssign';
import AdminRoleList from 'pages/private/role-management/AdminRoleList';
import AdminUserList from 'pages/private/role-management/AdminUserList';
import ConsultantUserDetail from 'pages/private/user-management/ConsultantUserDetail';
import ConsultantUserList from 'pages/private/user-management/ConsultantUserList';
import ConsultantVerifyRequest from 'pages/private/user-management/ConsultantVerifyRequest';
import EndUserDetail from 'pages/private/user-management/EndUserDetail';
import EndUserList from 'pages/private/user-management/EndUserList';
import PageNotFound from 'pages/public/error/PageNotFound';
import Login from 'pages/public/login/index';
import ContentDetailView from 'pages/private/content/ContentDetailView';
import ContentCreateUpdateForm from 'pages/private/content/ContentCreateUpdateForm';
import ContentList from 'pages/private/content/ContentList';
import PressReleasedList from 'pages/private/press-released/PressReleasedList';
import PressReleasedDetailView from 'pages/private/press-released/PressReleasedDetailView';
import PressRealeasedBlogCreateUpdate from 'pages/private/press-released/PressRealeasedBlogCreateUpdate';
import ForgetPasswordReset from 'pages/public/passwordreset/PasswordReset';
import QuotesDetailView from 'pages/private/quotes-management/QuotesDetailView';
import QuotesList from 'pages/private/quotes-management/QuotesList';
import QuotesCreateUpdateForm from 'pages/private/quotes-management/QuotesCreateUpdateForm';
import ConsultantDocumentVerification from 'pages/private/user-management/ConsultantDocumentVerification';
import ConsultantDocumentVerificationDetails from 'pages/private/user-management/ConsultantDocumentVerificationDetails';
import AddEditCompanyProfile from 'components/pages/cms/country-profile/AddEditCompanyProfile';
import DefaultQAndAForm from 'pages/private/cms/user-config/consultant-config/DefaultQuestionList/DefaultQAndAForm';
import QAndASegmentForm from 'pages/private/cms/user-config/consultant-config/QAndASegmentForm';
import KnowledgeBaseList from 'pages/private/cms/knowledge-base-config/KnowledgeBaseDetailsList';
import KnowledgeBaseDetailsForm from 'pages/private/cms/knowledge-base-config/KnowledgeBaseDetailsForm';
import KnowledgeBaseCategorySegment from 'pages/private/cms/knowledge-base-config/segment/KnowledgeBaseCategorySegment';
import ProgrammaticSeoForm from 'pages/private/cms/ProgrammaticSeoForm';

const AppRoutes = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const selectedPath = cookieGet('selectedPath') || '';

	const [previousLocationData] = useState();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	useEffect(() => {
		if (location.pathname === '/') {
			const path = !isEmpty(selectedPath) && selectedPath !== 'undefined' ? selectedPath : '/dashboard';
			navigate(path);
		}
		// setPreviousLocationData(location);
	}, [location.pathname, navigate, selectedPath]);

	// console.log(isAuthenticated,location, "isAuthenticated");

	return (
		<Routes>
			<Route path='password-reset' element={<ForgetPasswordReset />} />

			<Route path='login' element={!isAuthenticated ? <Login /> : <Navigate replace to='/dashboard' />} />
			<Route path='/' element={isAuthenticated ? <DashboardLayout /> : <Navigate replace to='/login' />}>
				<Route index path='dashboard' element={<Dashboard />} />
				<Route path='profile' element={<Profile />} />
				<Route
					path='user-management'
					element={<PageOutlet path='/user-management' previousLocationData={previousLocationData} />}
				>
					<Route index path='end-user' element={<EndUserList />} />
					<Route index path='end-user-detail' element={<EndUserDetail />} />
					<Route path='consultant-user' element={<ConsultantUserList />} />
					<Route path='consultant-user-detail' element={<ConsultantUserDetail />} />

					<Route path='consultant-verification-request' element={<ConsultantVerifyRequest />} />
					<Route path='consultant-document-verification' element={<ConsultantDocumentVerification />} />
					<Route path='consultant-offline-user-detail' element={<ConsultantDocumentVerificationDetails />} />
				</Route>
				<Route path='country-profile' element={<PageOutlet />}>
					<Route index element={<CountriesProfileList />} />
					<Route
						path='country-profile-create-update'
						// element={<CountryProfileForm />}
						element={<AddEditCompanyProfile />}
					/>

					<Route path='identity-proof-list' element={<CountryIdentityProofList />} />
					<Route path='identity-proof-create-update' element={<CountryIdentityProofForm />} />
					<Route path='policy-list' element={<CountryPoliciesList />} />
					<Route path='policy-create-update' element={<CountryPoliciesForm />} />
					<Route path='state-profile' element={<PageOutlet />}>
						<Route index element={<StatesProfileList />} />
						<Route path='city-profile' element={<PageOutlet />}>
							<Route index element={<CitiesProfileList />} />
						</Route>
					</Route>
				</Route>
				<Route path='org-config' element={<PageOutlet path='/org-config' previousLocationData={previousLocationData} />}>
					<Route index element={<OrgConfig />} />
				</Route>
				<Route
					path='general-config'
					element={<PageOutlet path='/general-config' previousLocationData={previousLocationData} />}
				>
					<Route index element={<GeneralConfig />} />
					<Route path='sub-specialization' element={<SubSpecialization />} />
				</Route>
				<Route path='knowledge-base' element={<PageOutlet />}>
					<Route path='category' element={<Outlet />}>
						<Route index element={<KnowledgeBaseCategoryConfig />} />
						<Route path=':categoryId' element={<KnowledgeBaseCategorySegment />} />
						<Route path=':categoryId/:segmentId' element={<KnowledgeBaseList />} />
						<Route path=':categoryId/:segmentId/add' element={<KnowledgeBaseDetailsForm />} />
						<Route path=':categoryId/:segmentId/add/:qnaId' element={<KnowledgeBaseDetailsForm />} />
					</Route>
				</Route>
				<Route path='programmatic-seo' element={<ProgrammaticSeoForm />} />
				<Route
					path='consultant-config'
					element={<PageOutlet path='/consultant-config' previousLocationData={previousLocationData} />}
				>
					<Route index path='pre-consultant' element={<PreConsultantQAndA />} />
					<Route path='post-consultant' element={<PostConsultantQAndA />} />
					<Route path='create-question' element={<QAndAForm />} />
					<Route path='question-template-create-update' element={<QAndATemplateForm />} />

					<Route path='default-question-segment-create-update' element={<QAndASegmentForm />} />
					<Route path='default-question-list' element={<DefaultQuestionList />} />
					<Route path='create-default-question' element={<DefaultQAndAForm />} />
				</Route>
				<Route
					path='language-config'
					element={<PageOutlet path='/language-config' previousLocationData={previousLocationData} />}
				>
					<Route index path='language-listing' element={<LanguageList />} />
					<Route path='macro-listing' element={<MacroList />} />
				</Route>
				<Route
					path='role-management'
					element={<PageOutlet path='/role-management' previousLocationData={previousLocationData} />}
				>
					<Route index path='admin-user-list' element={<AdminUserList />} />
					<Route index path='admin-user-create-update' element={<AdminUserForm />} />
					<Route path='role-list' element={<AdminRoleList />} />
					<Route path='role-assign' element={<AdminRoleAssign />} />
				</Route>
				<Route path='booking' element={<PageOutlet />}>
					<Route index element={<BookingList />} />
					<Route path='booking-detail' element={<BookingDetail />} />
					<Route path='q-and-a-detail' element={<QAndADataDetail />} />
				</Route>
				<Route
					path='article-blog'
					element={<PageOutlet path='/article-blog' previousLocationData={previousLocationData} />}
				>
					<Route index element={<ArticlesList />} />
					<Route path='article-detail' element={<ArticleDetailView />} />
					<Route path='article-create-update' element={<ArticleCreateUpdateForm />} />
				</Route>
				<Route
					path='app-content'
					element={<PageOutlet path='/app-content' previousLocationData={previousLocationData} />}
				>
					<Route index element={<AppContent />} />
					<Route path='create/welcome' element={<WelcomeTabForm />} />
				</Route>

				<Route
					path='press-release-blog'
					element={<PageOutlet path='/press-release-blog' previousLocationData={previousLocationData} />}
				>
					<Route index element={<PressReleasedList />} />
					<Route path='press-release-detail' element={<PressReleasedDetailView />} />
					<Route path='press-release-create-update' element={<PressRealeasedBlogCreateUpdate />} />
				</Route>

				<Route path='content' element={<PageOutlet path='/content' previousLocationData={previousLocationData} />}>
					<Route index element={<ContentList />} />
					<Route path='content-detail' element={<ContentDetailView />} />
					<Route path='content-create-update' element={<ContentCreateUpdateForm />} />
				</Route>

				<Route
					path='quotes-management'
					element={<PageOutlet path='/quotes-management' previousLocationData={previousLocationData} />}
				>
					<Route index element={<QuotesList />} />
					<Route path='quotes-detail' element={<QuotesDetailView />} />
					<Route path='quotes-create-update' element={<QuotesCreateUpdateForm />} />
				</Route>
				<Route
					path='health-food'
					element={<PageOutlet path='/health-food' previousLocationData={previousLocationData} />}
				>
					<Route path='recipe-list' element={<RecipesList />} />
					<Route path='recipe-create-update' element={<RecipeCreateUpdateForm />} />
					<Route path='recipe-detail' element={<RecipeDetailView />} />
					<Route path='meal-list' element={<MealList />} />
					<Route path='meal-update' element={<MealCreateUpdateForm />} />
					<Route path='meal-detail' element={<MealDetailView />} />
					<Route path='diet-plan-list' element={<DietPlanList />} />
					<Route path='diet-plan-update' element={<DietPlanCreateUpdateForm />} />
					<Route path='diet-plan-detail' element={<DietPlanDetailView />} />
				</Route>
				<Route path='*' element={<PageNotFound />} />
			</Route>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
};

export default AppRoutes;
