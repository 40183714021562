import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
// import { canvasPreview } from "./CanvasPreview";
// import { useDebounceEffect } from "./useDebounceEffect";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch } from "react-redux";
import Tesseract from "tesseract.js";

import "react-image-crop/dist/ReactCrop.css";
import { CircularProgress } from "@mui/material";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./CanvasPreview";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 70,
      },
      4 / 2.5,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
function centerAspectCrop1(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 70,
      },
      3 / 3,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function centerAspectCrop2(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 70,
      },
      9 / 16,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageCrop({
  imgFile,
  setImgFile,
  open,
  setOpen,
  SetCroppedImg,
  loading
}) {

  const [isLoading, setIsLoading] = useState(false)
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState([]);
  const [crop1, setCrop1] = useState([]);
  const [crop2, setCrop2] = useState([]);
  const [completedCrop, setCompletedCrop] = useState([]);
  const [completedCrop1, setCompletedCrop1] = useState([]);
  const [completedCrop2, setCompletedCrop2] = useState([]);

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(4 / 2.5);
  const [aspect1, setAspect1] = useState(3 / 3);
  const [aspect2, setAspect2] = useState(9 / 16);
  const [croppedImage, setCroppedImage] = useState(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(3);
  const blobUrlRef = useRef("");
  const dispatch = useDispatch();
  const { createWorker } = Tesseract;

  async function doOCR(imageBuffer) {
    const worker = await createWorker();
    await worker.loadLanguage("por");
    await worker.initialize("por");
    await worker.loadLanguage("eng");
    await worker.initialize("eng");

    const data = await worker.recognize(imageBuffer);

    await worker.terminate();
  }

  useEffect(() => {
    if (imgFile) {
      setCrop([]);
      const readers = imgFile.map((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const imgData = reader.result?.toString();
          setImgSrc((prevImgSrc) => {
            const newImgSrc = [...prevImgSrc];
            newImgSrc.push(imgData);
            return newImgSrc;
          });
        };
        reader.readAsDataURL(file);
        return reader;
      });
      return () => {
        readers.forEach((reader) => reader.abort());
      };
    }
  }, [imgFile]);

  function handleOpenDialog() {
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
    setImgFile([]);
    setImgSrc([]);
  }

  function onImageLoad(e) {
    if (imgRef.current && crop) {
      const { width, height } = e.currentTarget;
      const newCrop = { ...crop };
      newCrop.width = width / 100;
      newCrop.height = height / 100;
      newCrop.x = width / 100;
      newCrop.y = height / 100;
      // setCrop([newCrop]);
      setCrop([centerAspectCrop(width, height, aspect)]);
    }
  }
  function onImageLoad1(e) {
    if (imgRef.current && crop) {
      const { width, height } = e.currentTarget;
      const newCrop = { ...crop };
      newCrop.width = width / 100;
      newCrop.height = height / 100;
      newCrop.x = width / 100;
      newCrop.y = height / 100;
      // setCrop([newCrop]);
      setCrop1([centerAspectCrop1(width, height, aspect1)]);
    }
  }
  function onImageLoad2(e) {
    if (imgRef.current && crop) {
      const { width, height } = e.currentTarget;
      const newCrop = { ...crop };
      newCrop.width = width / 100;
      newCrop.height = height / 100;
      newCrop.x = width / 100;
      newCrop.y = height / 100;
      // setCrop([newCrop]);
      setCrop2([centerAspectCrop2(width, height, aspect2)]);
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      setCroppedImage(blob);
      // setPreFile(blob);
    });
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        doOCR(previewCanvasRef.current.toDataURL());
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined);
  //   } else if (imgRef.current) {
  //     const { width, height } = imgRef.current;
  //     setAspect(16 / 9);
  //     setCrop(centerAspectCrop(width, height, 16 / 9));
  //   }
  // }

  function handleUpload() {
    setIsLoading(true);
    SetCroppedImg([])
    if (crop) {
      crop.forEach((crop, index) => {
        if (crop && imgRef.current) {
          const { naturalWidth, naturalHeight } = imgRef.current;
          const { x, y, width: cropWidth, height: cropHeight } = crop;
          const scaleX = naturalWidth / 100;
          const scaleY = naturalHeight / 100;
          const actualCrop = {
            x: x * scaleX,
            y: y * scaleY,
            width: cropWidth * scaleX,
            height: cropHeight * scaleY,
          };

          if (actualCrop.width < 16 || actualCrop.height < 9) {
            alert("Please upload an image with a larger aspect ratio.");
            return;
          }

          const canvas = document.createElement("canvas");
          canvas.width = actualCrop.width;
          canvas.height = actualCrop.height;
          const context = canvas.getContext("2d");

          const image = new Image();
          image.onload = () => {
            context.drawImage(
              image,
              actualCrop.x,
              actualCrop.y,
              actualCrop.width,
              actualCrop.height,
              0,
              0,
              actualCrop.width,
              actualCrop.height
            );
            canvas.toBlob((blob) => {
              if (blob) {
                setImgSrc([]);
                SetCroppedImg(prevState => [...prevState , { file: blob, type: "horizontal" }] );
                // SetCroppedImg(blob);
                setImgFile([]);
                setOpen(false);
                setIsLoading(false);
              }
            });
          };
          image.src = imgSrc[index];
        }
      });
    }

    // -----second image-----
    if (crop1) {
      crop1.forEach((crop, index) => {
        if (crop && imgRef.current) {
          const { naturalWidth, naturalHeight } = imgRef.current;
          const { x, y, width: cropWidth, height: cropHeight } = crop;
          const scaleX = naturalWidth / 100;
          const scaleY = naturalHeight / 100;
          const actualCrop = {
            x: x * scaleX,
            y: y * scaleY,
            width: cropWidth * scaleX,
            height: cropHeight * scaleY,
          };

          if (actualCrop.width < 16 || actualCrop.height < 9) {
            alert("Please upload an image with a larger aspect ratio.");
            return;
          }

          const canvas1 = document.createElement("canvas");
          canvas1.width = actualCrop.width;
          canvas1.height = actualCrop.height;
          const context = canvas1.getContext("2d");

          const image = new Image();
          image.onload = () => {
            context.drawImage(
              image,
              actualCrop.x,
              actualCrop.y,
              actualCrop.width,
              actualCrop.height,
              0,
              0,
              actualCrop.width,
              actualCrop.height
            );
            canvas1.toBlob((blob) => {
              if (blob) {
                setImgSrc([]);
                SetCroppedImg(prevState => [...prevState , { file: blob, type: "square" }] );
                // SetCroppedImg(blob);
                setImgFile([]);
                setOpen(false);
                setIsLoading(false);
              }
            });
          };
          image.src = imgSrc[index];
        }
      });
    }

    // -----third image-----
    if (crop2) {
      crop2.forEach((crop, index) => {
        if (crop && imgRef.current) {
          const { naturalWidth, naturalHeight } = imgRef.current;
          const { x, y, width: cropWidth, height: cropHeight } = crop;
          const scaleX = naturalWidth / 100;
          const scaleY = naturalHeight / 100;
          const actualCrop = {
            x: x * scaleX,
            y: y * scaleY,
            width: cropWidth * scaleX,
            height: cropHeight * scaleY,
          };

          if (actualCrop.width < 16 || actualCrop.height < 9) {
            alert("Please upload an image with a larger aspect ratio.");
            return;
          }

          const canvas2 = document.createElement("canvas");
          canvas2.width = actualCrop.width;
          canvas2.height = actualCrop.height;
          const context = canvas2.getContext("2d");

          const image = new Image();
          image.onload = () => {
            context.drawImage(
              image,
              actualCrop.x,
              actualCrop.y,
              actualCrop.width,
              actualCrop.height,
              0,
              0,
              actualCrop.width,
              actualCrop.height
            );
            canvas2.toBlob((blob) => {
              if (blob) {
                setImgSrc([]);
                SetCroppedImg(prevState => [...prevState , { file: blob, type: "vertical" }] );
                // SetCroppedImg(blob);
                setImgFile([]);
                setOpen(false);
                setIsLoading(false);
              }
            });
          };
          image.src = imgSrc[index];
        }
      });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="s"
      fullWidth={true}
    >
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "row" }}>
        {!!imgSrc.length && (
          <div style={{ marginRight: "10px" }}>
            {imgSrc.map((src, index) => (
              <ReactCrop
                style={{ width: "100%", height: "auto" }}
                key={index}
                crop={crop[index]}
                // locked
                resizable={false}
                dragCrop={true}
                onChange={(_, percentCrop) =>
                  setCrop((prevCrop) => {
                    const newCrop = [...prevCrop];
                    newCrop[index] = percentCrop;
                    return newCrop;
                  })
                }
                onComplete={(c) =>
                  setCompletedCrop((prevCompletedCrop) => {
                    const newCompletedCrop = [...prevCompletedCrop];
                    newCompletedCrop[index] = c;
                    return newCompletedCrop;
                  })
                }
                aspect={aspect}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={src}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            ))}
          </div>
        )}

        {!!imgSrc.length && (
          <div>
            {imgSrc.map((src, index) => (
              <ReactCrop
                style={{ width: "100%", height: "auto" }}
                key={index}
                crop={crop1[index]}
                // locked
                resizable={false}
                dragCrop={true}
                onChange={(_, percentCrop) =>
                  setCrop1((prevCrop) => {
                    const newCrop = [...prevCrop];
                    newCrop[index] = percentCrop;
                    return newCrop;
                  })
                }
                onComplete={(c) =>
                  setCompletedCrop1((prevCompletedCrop) => {
                    const newCompletedCrop = [...prevCompletedCrop];
                    newCompletedCrop[index] = c;
                    return newCompletedCrop;
                  })
                }
                aspect={aspect1}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={src}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad1}
                />
              </ReactCrop>
            ))}
          </div>
        )}
        {!!imgSrc.length && (
          <div style={{ marginLeft: "10px" }}>
            {imgSrc.map((src, index) => (
              <ReactCrop
                style={{ width: "100%", height: "auto" }}
                key={index}
                crop={crop2[index]}
                // locked
                resizable={false}
                dragCrop={true}
                onChange={(_, percentCrop) =>
                  setCrop2((prevCrop) => {
                    const newCrop = [...prevCrop];
                    newCrop[index] = percentCrop;
                    return newCrop;
                  })
                }
                onComplete={(c) =>
                  setCompletedCrop2((prevCompletedCrop) => {
                    const newCompletedCrop = [...prevCompletedCrop];
                    newCompletedCrop[index] = c;
                    return newCompletedCrop;
                  })
                }
                aspect={aspect2}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={src}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad2}
                />
              </ReactCrop>
            ))}

            {/* {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={imgRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
              </>
            )} */}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button onClick={handleUpload} disabled={isLoading}>
          {!isLoading ? (
            "Upload"
          ) : (
            <>
              &nbsp;
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
