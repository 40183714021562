import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import MDLoadingButton from "../../md-components/button/MDLoadingButton";
import {
  getData,
  postForgetPasswordReset,
} from "../../../store/slice/resetpassword/resetPasswordSlice";
import { get } from "lodash";
import { useCookies } from "react-cookie";
// import {
//   setAuthToken,
//   setIsLoggedIn,
// } from "../../../store/slice/auth/authentication/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
// import { expireTime } from "../../hooks/cookie";
import { isEmpty } from "lodash";

const ForgetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailUrl,setEmailUrl] = useState()
//   const loading = useSelector((state) => state.forgetPassword.loading);
  const theme = useTheme();
  const dispatch = useDispatch();

  const [, setCookie] = useCookies();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(location, "location");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    setEmailUrl(email)
    console.log(email);
  }, [location]);

  const store = useSelector(getData);
  const userData = get(store, "data", []);

  const schemaObj = Yup.object().shape({
    password: Yup.string().required(
      "password required"
    ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
       "passwords must match"
      )
      .required("enter confirm password"),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

//   useEffect(() => {
//     if (
//       get(state, "isPasswordVerified", null) === null ||
//       isEmpty(get(userData, "email", "")) === true
//     ) {
//       navigate("/recover/account");
//     }
//   }, [navigate, state, userData]);

  const handleSubmitPassword = async (data) => {
    if (!executeRecaptcha) {
      toast.error("You are not authorized");
      return;
    }
    const token = await executeRecaptcha("yourAction");

    const payload = {
      email: emailUrl,
      //   googleRecaptchaToken: token,
      password: data.password,
      scope: ["admin"],
    };

    const res = await dispatch(postForgetPasswordReset(payload))
      .unwrap()
      .then((result) => {
        if (
          get(result, "status", false) === true 
        ) {
        //   dispatch(setAuthToken(get(result, "data.token", "")));
          const data = {
            email: get(userData, "email"),
            token: get(result.data, "token"),
          };
        //   setCookie("consultant_data", data, {
        //     path: "/",
        //     expires: expireTime,
        //   });

          toast.success(result.message);
        //   dispatch(setIsLoggedIn(true));
        } else {

          toast.error(result.message);
        }
      })
      .catch((error) => {
        console.log("error Password verify", error);
      });
    return res;
  };

  return (
    <>
      <Box component="div" sx={{ width: "100%" }}>
        <Container maxWidth="xl">
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={schemaObj}
            onSubmit={(values) => {
              handleSubmitPassword(values);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    lg={8}
                    sx={{ mx: { md: "0", xs: "auto" } }}
                  >
                    <Typography variant="h2" align="center" mb={3}>
                      {"forgot_password"}
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel
                            error={
                              props.errors.password && props.touched.password
                            }
                            htmlFor="outlined-adornment-password"
                          >
                            {"password"}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            label={"password"}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={props.values.password}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            size="small"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            error={
                              props.errors.password && props.touched.password
                                ? true
                                : false
                            }
                          />
                          {props.errors.password && props.touched.password && (
                            <FormHelperText
                              error
                              id="outlined-adornment-password"
                            >
                              {props.errors.password}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel
                            error={
                              props.errors.confirmPassword &&
                              props.touched.confirmPassword
                            }
                            htmlFor="outlined-adornment-confirm-password"
                          >
                            {"confirm_password"}
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-confirm-password"
                            label={"confirm_password"}
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={props.values.confirmPassword}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            size="small"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            error={
                              props.errors.confirmPassword &&
                              props.touched.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {props.errors.confirmPassword &&
                            props.touched.confirmPassword && (
                              <FormHelperText
                                error
                                id="outlined-adornment-confirm-password"
                              >
                                {props.errors.confirmPassword}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <MDLoadingButton
                          variant="contained"
                          fullWidth
                          type="submit"
                        //   loading={props.isSubmitting && loading}
                        //   disabled={props.isSubmitting && loading}
                        >
                          change password
                        </MDLoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default ForgetPassword;
