import CardContent from '@mui/material/CardContent';

import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import KnowledgeBaseCategoryConfigList from './components/KnowledgeBaseCategoryConfigList';

const KnowledgeBaseCategoryConfig = () => {
	return (
		<MDCard>
			<MDCardHeader title='Knowledge Base Category Config' />
			<CardContent className='h-100'>
				<KnowledgeBaseCategoryConfigList />
			</CardContent>
		</MDCard>
	);
};

export default KnowledgeBaseCategoryConfig;
