import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { AvatarInnerButtonsWrapper } from "components/utility/file-picker/component/FilePickerComponents";
import { isEmpty } from "lodash";

const ASPECT_RATION = {
  horizontal: 4 / 2.5,
  square: 3 / 3,
  vertical: 9 / 16,
};

const ImageBox = ({ data, name, filePreviewSize, previewImageStyle, borderColor }) => {
  const [file, setFile] = useState({ type: "", url: "" });
  console.log(file, data);

  useEffect(() => {
    let url = null;

    if (data.file) {
      try {
        url = URL.createObjectURL(data.file);
      } catch (error) {}
      setFile({ type: data.type, url });
    } else {
      if (typeof data.url === "string") {
        setFile(data);
      }
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [data]);

  return (
    <Box
      position="relative"
      sx={{
        "&:hover > .avatar-hover-buttons": {
          display: "inline-flex",
          transition: "all 3000ms ease",
        },
      }}
    >
      <Avatar
        src={file?.url}
        alt={name + "_image"}
        className="avtar-image-crop"
        variant="rounded"
        sx={{
          width: filePreviewSize ? filePreviewSize : 96 * ASPECT_RATION[file.type],
          height: filePreviewSize ? filePreviewSize : 96,
          aspectRatio: "3/3",
          border: `2px solid ${borderColor}`,
          cursor: "pointer",
          previewImageStyle,
        }}
      />
      <AvatarInnerButtonsWrapper sx={{ top: "0" }} className="avatar-hover-buttons">
        <Button aria-label="view-file" size="small" className="min-w-auto p-1" component={"a"} href={file?.url} target="_blank">
          <VisibilityIcon sx={{ color: "grey.200" }} />
        </Button>
      </AvatarInnerButtonsWrapper>
    </Box>
  );
};

const VideoBox = ({ data, name, filePreviewSize, previewImageStyle, borderColor, fileLabel }) => {
  const [file, setFile] = useState("");

  useEffect(() => {
    let url = null;

    if (typeof data === "string") {
      setFile(data);
    } else {
      url = URL.createObjectURL(data);
      setFile(url);
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [data]);

  return (
    <ListItem
      disablePadding
      sx={{
        columnGap: 2,
        padding: "4px",
        borderRadius: "6px",
        flexWrap: "wrap",
      }}
      className="position-relative"
    >
      <ListItemIcon
        className="position-relative"
        sx={{
          "&:hover > .avatar-hover-buttons": {
            display: "inline-flex",
            transition: "all 3000ms ease",
          },
        }}
      >
        <Avatar
          src={file}
          alt={name + "_image"}
          className="avtar-image-crop"
          variant="rounded"
          sx={{
            width: filePreviewSize ? filePreviewSize : 128,
            height: filePreviewSize ? filePreviewSize : 96,
            aspectRatio: "4/3",
            border: `2px solid ${borderColor}`,
            cursor: "pointer",
            previewImageStyle,
          }}
        />
        <AvatarInnerButtonsWrapper className="avatar-hover-buttons">
          <Button aria-label="view-file" size="small" className="min-w-auto p-1" component={"a"} href={file} target="_blank">
            <VisibilityIcon sx={{ color: "grey.200" }} />
          </Button>
        </AvatarInnerButtonsWrapper>
      </ListItemIcon>

      {fileLabel ? (
        <ListItemText
          primary={file.split("/").pop()}
          primaryTypographyProps={{ fontSize: "14px" }}
          sx={{ wordWrap: "break-word" }}
        />
      ) : null}
    </ListItem>
  );
};

const FilePickerPreview = (props) => {
  const { fileLabel, filePreviewSize, value, name, theme, previewImageStyle, previewFileSize } = props;

  const { palette } = theme;
  const borderColor = palette.grey[200];

  console.log(value);
  return !isEmpty(value) && Array.isArray(value) && value?.length === 3 ? (
    <Grid item sm={previewFileSize ? previewFileSize : 12} xs={12}>
      <Box display="flex" gap={1} my={1}>
        {value.map((val) => (
          <ImageBox
            data={val}
            name={name}
            filePreviewSize={filePreviewSize}
            previewImageStyle={previewImageStyle}
            borderColor={borderColor}
            key={val.type}
          />
        ))}
      </Box>
    </Grid>
  ) : (
    <Grid item sm={previewFileSize ? previewFileSize : 12} xs={12}>
      {!isEmpty(value) && (
        <VideoBox
          data={value}
          name={name}
          filePreviewSize={filePreviewSize}
          previewImageStyle={previewImageStyle}
          borderColor={borderColor}
          fileLabel={fileLabel}
        />
      )}
    </Grid>
  );
};

export default FilePickerPreview;
