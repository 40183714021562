import React from 'react';
import { FieldArray } from 'formik';
import { map } from 'lodash';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import DescriptionForm from './DescriptionForm';
import { Autocomplete, Chip } from '@mui/material';
import { get } from 'lodash';

export default function ContentForm({ formProps, openIndex, setOpenIndex }) {
	return (
		<FieldArray name='content'>
			{() => (
				<Box>
					{map(formProps?.values?.content, (field, index) => {
						const title = `content[${index}].title`;
						const subTitle = `content[${index}].subTitle`;
						const description = `content[${index}].description`;
						const seoTitle = `content[${index}].seoTitle`;
						const seoDescription = `content[${index}].seoDescription`;
						const seoMetaTags = `content[${index}].seoMetaTags`;

						const errors = get(formProps, `errors.content[${index}]`, {});
						const touched = get(formProps, `touched.content[${index}]`, {});

						return (
							<React.Fragment key={index}>
								<Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
									<Typography variant='h6'>{field.languageName}</Typography>
									<IconButton onClick={() => setOpenIndex(index)}>
										<Box
											component={openIndex === index ? ExpandLessIcon : ExpandMoreIcon}
											color='inherit'
											sx={{ fontSize: '1.25rem' }}
										/>
									</IconButton>
								</Box>
								<Collapse in={openIndex === index}>
									<Grid container spacing={2} key={index} mb={3}>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Title'
												name={title}
												value={field.title}
												error={Boolean(errors.title && touched.title)}
												helperText={errors.title && touched.title ? errors.title : null}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Sub Title'
												name={subTitle}
												multiline
												rows={3}
												value={field.subTitle}
												error={Boolean(errors.subTitle && touched.subTitle)}
												helperText={errors.subTitle && touched.subTitle ? errors.subTitle : null}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<DescriptionForm formProps={formProps} value={field.description} name={description} />
										</Grid>

										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Seo Title'
												name={seoTitle}
												value={field.seoTitle}
												error={Boolean(errors.seoTitle && touched.seoTitle)}
												helperText={errors.seoTitle && touched.seoTitle ? errors.seoTitle : null}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label='Seo Description'
												name={seoDescription}
												value={field.seoDescription}
												error={Boolean(errors.seoDescription && touched.seoDescription)}
												helperText={
													errors.seoDescription && touched.seoDescription ? errors.seoDescription : null
												}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
											/>
										</Grid>
										<Grid item xs={12}>
											<Autocomplete
												options={[]}
												name={seoMetaTags}
												value={field.seoMetaTags}
												onChange={(e, value) => {
													formProps.setFieldValue(seoMetaTags, value);
												}}
												onBlur={formProps.handleBlur}
												multiple
												freeSolo
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip label={option} {...getTagProps({ index })} />
													))
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label={'Meta Tags'}
														error={errors.seoMetaTags && touched.seoMetaTags ? true : false}
														helperText={
															errors.seoMetaTags && touched.seoMetaTags ? errors.seoMetaTags : null
														}
													/>
												)}
											/>
										</Grid>
									</Grid>
								</Collapse>
							</React.Fragment>
						);
					})}
				</Box>
			)}
		</FieldArray>
	);
}
