import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  AddLanguage,
  AddLanguageMacro,
  GetLanguageConfigList,
  GetLanguageMacro,
  StatusUpdateLanguage,
  StatusUpdateLanguageMacro,
  ValueUpdateLanguageMacro,
} from "api/language-config/LanguageConfigAPI";

const initialState = {
  languageList: [],
  macroList: [],
  loading: false,
};

export const getLanguageConfigList = createAsyncThunk(
  "languageConfig/getLanguageConfigList",
  async () => {
    return await GetLanguageConfigList()
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const addLanguage = createAsyncThunk(
  "languageConfig/addLanguage",
  async (data) => {
    return await AddLanguage(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const statusUpdateLanguage = createAsyncThunk(
  "languageConfig/statusUpdateLanguage",
  async (data) => {
    return await StatusUpdateLanguage(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);

export const getLanguageMacro = createAsyncThunk(
  "languageConfig/getLanguageMacro",
  async (data) => {
    return await GetLanguageMacro(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const addLanguageMacro = createAsyncThunk(
  "languageConfig/addLanguageMacro",
  async (data) => {
    return await AddLanguageMacro(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const valueUpdateLanguageMacro = createAsyncThunk(
  "languageConfig/valueUpdateLanguageMacro",
  async (data) => {
    return await ValueUpdateLanguageMacro(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);
export const statusUpdateLanguageMacro = createAsyncThunk(
  "languageConfig/statusUpdateLanguageMacro",
  async (data) => {
    return await StatusUpdateLanguageMacro(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return response.data;
        }
      })
      .catch(function (error) {
        return { status: false, message: error.response.data.message };
      });
  }
);

export const LanguageConfigSlice = createSlice({
  name: "languageConfig",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getLanguageConfigList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLanguageConfigList.fulfilled, (state, action) => {
        state.languageList = get(action.payload, "status", false)
          ? action.payload.data
          : [];
        state.loading = false;
      })
      .addCase(getLanguageConfigList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addLanguage.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLanguage.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addLanguage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateLanguage.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusUpdateLanguage.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateLanguage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLanguageMacro.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLanguageMacro.fulfilled, (state, { payload }) => {
        state.macroList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getLanguageMacro.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addLanguageMacro.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLanguageMacro.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addLanguageMacro.rejected, (state) => {
        state.loading = false;
      })
      .addCase(valueUpdateLanguageMacro.pending, (state) => {
        state.loading = true;
      })
      .addCase(valueUpdateLanguageMacro.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(valueUpdateLanguageMacro.rejected, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateLanguageMacro.pending, (state) => {
        state.loading = true;
      })
      .addCase(statusUpdateLanguageMacro.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(statusUpdateLanguageMacro.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default LanguageConfigSlice.reducer;
