import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Formik } from 'formik';
import { get, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import MDButton from 'components/md-components/button/MDButton';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';

import TimeOutComponent from 'components/common/TimeOutComponent';
import { getQuestionsList } from 'store/slice/consultant-config/QAndASlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import { languageInitialValue } from 'components/common/LanguageTranslationForm';
import QuestionDetailAccordion from 'components/pages/cms/user-config/consultant-config/component/QuestionDetailAccordion';
import QandALanguageTranslationForm from './QandALanguageTranslationForm';
import SelectQuestion from './SelectQuestion';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';

const ValidationSchema = Yup.object().shape({
	sectionName: Yup.string().min(4).required('Segment name is required.'),
	languages: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().required('Value is required'),
		})
	),
	questions: Yup.array().of(Yup.object()),
});

const Form = ({ formProps, languageConfigList }) => {
	const [open, setOpen] = useState(false);

	const toggleModal = () => {
		setOpen((prev) => !prev);
	};

	return (
		<MDCard variant='outlined'>
			<Stack
				direction='row'
				justifyContent='space-between'
				spacing={2}
				className='px-3 py-2 border-bottom'
			>
				<Typography variant='body1' fontWeight={500}>
					sectionName
				</Typography>
			</Stack>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} container spacing={2}>
						<Grid item md={9} sm={8} xs={12}>
							<TextField
								label='Segment Name'
								name='sectionName'
								value={formProps.values.sectionName}
								onChange={formProps.handleChange}
								onBlur={formProps.handleBlur}
								error={formProps.errors.sectionName}
								helperText={formProps.touched.sectionName && formProps.errors.sectionName}
								fullWidth={true}
								size='small'
							/>
						</Grid>

						<Grid item md={3} sm={4} xs={12}>
							<MDButton
								label='Add Question'
								variant='contained'
								padding='4px 16px'
								fullWidth={true}
								onClick={toggleModal}
							/>
						</Grid>

						<Grid item md={9} sm={8} xs={12}>
							<QandALanguageTranslationForm
								formProps={formProps}
								languageConfigList={languageConfigList}
							/>
						</Grid>
						<SelectQuestion
							fieldValue={formProps.values.questions}
							setFieldValue={formProps.setFieldValue}
							open={open}
							closeModal={toggleModal}
						/>
					</Grid>
					<Grid item xs={12}>
						<Box>
							{map(formProps.values.questions, (customSectionQuestion, index) => (
								<Box key={`custom-section-${index}`}>
									<QuestionDetailAccordion
										sectionType='custom'
										sectionsIndex={0}
										questionIndex={index}
										fieldName='questions'
										questionData={customSectionQuestion.questionId}
										setFieldValue={formProps.setFieldValue}
										fieldValue={formProps.values.questions}
									/>
								</Box>
							))}
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</MDCard>
	);
};

export default function QAndASegmentForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const formType = location.state.formType;
	const rowData = location.state.rowData;

	const [isLoading, setIsLoading] = useState(false);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);

	const handleSubmit = async (values) => {
		if (get(values, 'questions', []).length === 0) {
			toast.error('Please add at least one question.');
			return;
		}
		const payloadData = {
			sectionName: values.sectionName,
			languages: values.languages,
			questions: map(values.questions, (que) => ({ questionId: que.questionId._id })),
		};

		try {
			setIsLoading(true);
			if (formType === 'create') {
				const res = await AxiosDefaultSetting({
					method: 'POST',
					url: '/admin/questions/template/default-question-segment-create',
					data: payloadData,
				});
				if (get(res, 'data.status', false)) {
					toast.success(get(res, 'data.message', 'Question created successfully.'));
					navigate('/consultant-config/default-question-list', {
						state: { index: 1 },
					});
				} else {
					toast.error(get(res, 'data.message', 'Something went wrong.'));
				}
			} else {
				const res = await AxiosDefaultSetting({
					method: 'PATCH',
					url: `/admin/questions/default-question-segment-update/${get(rowData, '_id', '')}`,
					data: payloadData,
				});

				if (get(res, 'data.status', false)) {
					toast.success(get(res, 'data.message', 'Question created successfully.'));
					navigate('/consultant-config/default-question-list', {
						state: { index: 1 },
					});
				} else {
					toast.error(get(res, 'data.message', 'Something went wrong.'));
				}
			}
			setIsLoading(false);
		} catch (error) {
			toast.error('Something went wrong.');
			setIsLoading(false);
		}
	};

	useEffect(() => {
		dispatch(getQuestionsList('default'));
		dispatch(getLanguageConfigList());
	}, [dispatch]);

	return (
		<MDCard>
			<MDCardHeader title={`${formType === 'create' ? 'Create' : 'Update'} Question Segment`} />
			<CardContent className='h-100 my-1'>
				<TimeOutComponent delay={2} isEmpty={isEmpty(languageConfigList)} px={1} pt={1}>
					<Formik
						initialValues={{
							sectionName: get(rowData, 'sectionName', ''),
							languages: languageInitialValue(
								languageConfigList,
								get(rowData, 'languages', false),
								'common'
							),
							questions: get(rowData, 'questions', []),
						}}
						validationSchema={ValidationSchema}
						onSubmit={handleSubmit}
					>
						{(formProps) => (
							<form onSubmit={formProps.handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Box>
											<Form
												formProps={formProps}
												languageConfigList={languageConfigList}
											/>
										</Box>
									</Grid>
									<Grid item xs={12} container spacing={2} className='mt-3 mb-3'>
										<Grid item sm={6} xs={12}>
											<MDLoadingButton
												type='reset'
												variant='outlined'
												padding='6px 24px'
												fullWidth={true}
												onClick={(_event) => {
													formProps.resetForm();
												}}
											>
												Reset
											</MDLoadingButton>
										</Grid>
										<Grid item sm={6} xs={12}>
											<MDLoadingButton
												type='submit'
												variant='contained'
												padding='6px 24px'
												fullWidth={true}
												loading={formProps.isSubmitting && isLoading}
											>
												Submit
											</MDLoadingButton>
										</Grid>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</TimeOutComponent>
			</CardContent>
		</MDCard>
	);
}
