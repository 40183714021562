import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAdminUsers,
  StatusUpdateAdminUser,
  DeleteAdminUser,
  CreateAdminUser,
  UpdateAdminUser,
} from "api/admin-user-management/AdminUserConfigAPI";
import { get } from "lodash";

const initialState = {
  list: [],
  loading: false,
};

export const getAdminUsers = createAsyncThunk(
  "adminUser/getAdminUsers",
  async () => {
    return await GetAdminUsers()
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const createAdminUser = createAsyncThunk(
  "adminUser/createAdminUser",
  async (data) => {
    return await CreateAdminUser(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const updateAdminUser = createAsyncThunk(
  "adminUser/updateAdminUser",
  async (data) => {
    return await UpdateAdminUser(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const statusUpdateAdminUser = createAsyncThunk(
  "adminUser/statusUpdateAdminUser",
  async (data) => {
    return await StatusUpdateAdminUser(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data", []);
        }
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);
export const deleteAdminUser = createAsyncThunk(
  "adminUser/deleteAdminUser",
  async (data) => {
    return await DeleteAdminUser(data)
      .then((result) => {
        if (get(result, "data.status", false)) {
          return get(result, "data.data", []);
        }
      })
      .catch((err) => {
        return { status: false, message: err.response.data.message };
      });
  }
);

const AdminUsers = createSlice({
  name: "adminUser",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAdminUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdminUsers.fulfilled, (state, { payload }) => {
      state.list = get(payload, "data", []);
      state.loading = false;
    });
    builder.addCase(getAdminUsers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdminUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAdminUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdminUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAdminUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateAdminUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusUpdateAdminUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateAdminUser.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAdminUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAdminUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAdminUser.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default AdminUsers.reducer;
