import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";

export default function FormCheckbox({ name, checked, onChange, label }) {
  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          control={<Checkbox name={name} checked={checked} value={checked} onChange={onChange} />}
          label={label}
          sx={{ mr: 0 }}
        />
      </FormGroup>
    </FormControl>
  );
}
