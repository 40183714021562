import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty, filter } from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteAdminRole,
  getAdminRoles,
  statusUpdateAdminRole,
} from "store/slice/role-management/AdminRoleSlice";
import MDSwitch from "components/md-components/switch/MDSwitch";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import DataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDButton from "components/md-components/button/MDButton";
import MDCard from "components/md-components/card/MDCard";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import AddRoleForm from "./AddRoleForm";

const AdminRoleList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.adminRoles.loading);
  const getRoles = useSelector((state) => state.adminRoles.list);
  const roleList = filter(getRoles, function (item) {
    return item.isDeleted !== true;
  });
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    rowData: [],
  });

  const handleDialogOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const handleStatusUpdate = useCallback(
    (event, params) => {
      setDialogData({ ...dialogData, id: params.id });
      const data = {
        id: params.row._id,
        status: event.target.checked,
      };
      dispatch(statusUpdateAdminRole(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getAdminRoles());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dialogData, dispatch]
  );
  const handleDelete = useCallback(
    ({ event, id }) => {
      const data = {
        id: id,
        isDeleted: event,
      };
      dispatch(deleteAdminRole(data))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getAdminRoles());
          }
        })
        .catch((err) => {
          console.log(err);
        });
      handleDeleteConfirmationClose();
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getAdminRoles());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        width: 240,
        editable: false,
      },
      {
        field: "description",
        headerName: "Description",
        width: 320,
        editable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: (params) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: params.row._id,
                    title: "Update Role",
                    rowData: params.row,
                    formType: "update",
                  });
                  handleDialogOpen();
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={params.row.status}
                onChange={(event) => {
                  handleStatusUpdate(event, params);
                }}
                inputProps={{ "aria-label": params.row.id, role: "switch" }}
                color="success"
                disabled={get(dialogData, "id", "") === params.id && isLoading}
              />
              <IconButton
                aria-label="delete-role"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: params.row._id,
                    title: "User Delete Confirmation",
                    rowData: params.row,
                    formType: "",
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      dialogData,
      handleDeleteConfirmation,
      handleDialogOpen,
      handleStatusUpdate,
      isLoading,
    ]
  );

  const CustomToolbar = () => {
    return (
      <DataGridToolbar align="right" divider={true}>
        <MDButton
          variant="contained"
          label={`Create New Role`}
          startIcon={<AddCircleOutlineIcon />}
          fontSize="14px"
          onClick={() => {
            setDialogData({
              ...dialogData,
              id: "",
              rowData: "",
              title: "Create Role",
              formType: "create",
            });
            handleDialogOpen();
          }}
        />
      </DataGridToolbar>
    );
  };

  return (
    <MDCard>
      <MDCardHeader title="Admin Roles List" />
      <CardContent className="h-100">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <MDDataGrid
              rows={!isEmpty(roleList) ? roleList : []}
              columns={columnsData}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
            <AddRoleForm
              open={open}
              onClose={handleDialogClose}
              dialogData={dialogData}
              handleDialogOpen={handleDialogOpen}
              handleDialogClose={handleDialogClose}
            />
            <DeleteConfirmDialog
              title="Admin Role Delete Confirmation"
              open={deleteConfirmation}
              handleClose={handleDeleteConfirmationClose}
              deleteAction={() => {
                handleDelete({ event: true, id: get(dialogData, "id", "") });
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default AdminRoleList;
