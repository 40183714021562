import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldArray, Formik, getIn } from "formik";
import { get, isEmpty, map, size } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import TimeOutComponent from "components/common/TimeOutComponent";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import MDRadio from "components/md-components/form/MDRadio";

import MDButton from "components/md-components/button/MDButton";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import { TransitionGroup } from "react-transition-group";
import { getLanguageList } from "store/slice/common/CommonDataSlice";
import { getDietaryPreferenceData } from "store/slice/common/GeneralConfigDataSlice";
import { createUpdateMeal } from "store/slice/health-food/MealSlice";

const MealCreateUpdateForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const mealDetail = get(location, "state.mealDetail", {});

  const languagesList = useSelector((state) => state.commonData.languageList);
  const dietaryPreferenceData = useSelector(
    (state) => state.generalConfigData.dietaryPreferenceData
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Meal Title is required"),
    description: Yup.string().required("Description is required"),
    language: Yup.object().shape().required("Language is required").nullable(),
    dietPreference: Yup.string().required("Dietary Preference is required"),
    additionalSupplementOrNutrition: Yup.boolean().required(
      "Dietary Preference is required"
    ),
    recipe: Yup.object().shape({
      recipe: Yup.string().required("Recipe is required"),
      amount: Yup.string().required("Amount is required"),
      size: Yup.string().required("Size is required"),
    }),
    supplementOrNutrition: Yup.array().of(
      Yup.object().shape({
        supplement: Yup.string().required("Supplement is required"),
        amount: Yup.string().required("Amount is required"),
        size: Yup.string().required("Size is required"),
      })
    ),
    rawFood: Yup.array().of(
      Yup.object().shape({
        food: Yup.string().required("Food is required"),
        amount: Yup.string().required("Amount is required"),
        size: Yup.string().required("Size is required"),
        nutritionValue: Yup.string().required("Nutrition value is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    let payloadData = {
      ...values,
      language: get(values, "language._id", ""),
      _id: get(mealDetail, "_id", ""),
    };

    dispatch(createUpdateMeal(payloadData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          toast.success(get(result, "message", "Meal updated successfully."));
          navigate("/health-food/meal-list", {
            state: { index: get(location, "state.index", 0) },
          });
        } else {
          toast.error(get(result, "message", "Something went wrong."));
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    dispatch(getDietaryPreferenceData());
    dispatch(getLanguageList());
  }, [dispatch]);

  return (
    <MDCard>
      <MDCardHeader title="Health Food Meal Update" />
      <CardContent>
        <TimeOutComponent delay={2} isEmpty={isEmpty(dietaryPreferenceData)}>
          <Formik
            initialValues={{
              title: get(mealDetail, "title", ""),
              language: get(mealDetail, "language", ""),
              description: get(mealDetail, "description", ""),
              dietPreference: get(mealDetail, "dietPreference", ""),
              additionalSupplementOrNutrition: get(
                mealDetail,
                "additionalSupplementOrNutrition",
                false
              ),
              recipe: {
                recipe: get(mealDetail, "recipe.recipe", ""),
                amount: get(mealDetail, "recipe.amount", ""),
                size: get(mealDetail, "recipe.size", ""),
              },
              supplementOrNutrition: get(mealDetail, "supplementOrNutrition", [
                {
                  supplement: "",
                  amount: "",
                  size: "",
                },
              ]),
              rawFood: get(mealDetail, "rawFood", [
                {
                  food: "",
                  amount: "",
                  size: "",
                  nutritionValue: "",
                },
              ]),
              nutritionValue: get(mealDetail, "nutritionValue", ""),
              status: get(mealDetail, "status", false),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                {console.log("formProps", formProps)}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        label="Title"
                        name="title"
                        value={formProps.values.title}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={
                          formProps.errors.title && formProps.touched.title
                            ? true
                            : false
                        }
                        helperText={
                          formProps.errors.title && formProps.touched.title
                            ? formProps.errors.title
                            : null
                        }
                        fullWidth={true}
                        multiline={true}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Autocomplete
                        options={languagesList ?? []}
                        name="language"
                        value={formProps.values.language}
                        getOptionLabel={(option) => option.name ?? ""}
                        isOptionEqualToValue={(option, value) => {
                          if (option._id === value._id || option === "") {
                            return true;
                          }
                        }}
                        onChange={(_e, value) => {
                          formProps.setFieldValue("language", value);
                        }}
                        onBlur={formProps.handleBlur}
                        disablePortal
                        autoHighlight
                        noOptionsText={"No Languages"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="language"
                            placeholder="Select Language"
                            error={
                              formProps.errors.language &&
                              formProps.touched.language
                                ? true
                                : false
                            }
                            helperText={
                              formProps.errors.language &&
                              formProps.touched.language
                                ? formProps.errors.language
                                : null
                            }
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        label="Description"
                        name="description"
                        value={formProps.values.description}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={
                          formProps.errors.description &&
                          formProps.touched.description
                            ? true
                            : false
                        }
                        helperText={
                          formProps.errors.description &&
                          formProps.touched.description
                            ? formProps.errors.description
                            : null
                        }
                        fullWidth={true}
                        multiline={true}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <FormControl
                        fullWidth
                        className="gap-2"
                        error={
                          formProps.errors.dietPreference &&
                          formProps.touched.dietPreference
                            ? true
                            : false
                        }
                      >
                        <FormLabel id="dietary-preference-group-label">
                          Dietary Preference
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="dietary-preference-group-label"
                          name="dietPreference"
                          value={formProps.values.dietPreference}
                          onChange={formProps.handleChange}
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          {map(dietaryPreferenceData, (data, index) => (
                            <FormControlLabel
                              key={index}
                              value={data?._id}
                              control={<MDRadio />}
                              label={data?.title}
                              className="ms-0"
                            />
                          ))}
                        </RadioGroup>
                        {formProps.errors.dietPreference &&
                        formProps.touched.dietPreference ? (
                          <FormHelperText>
                            {formProps.errors.dietPreference}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <FormControl fullWidth className="gap-2">
                        <FormLabel id="additional-supplement-or-nutrition-group-label">
                          Additional Supplement and Nutrition
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="additional-supplement-or-nutrition-group-label"
                          name="additionalSupplementOrNutrition"
                          value={
                            formProps.values.additionalSupplementOrNutrition
                          }
                          onChange={formProps.handleChange}
                          sx={{
                            marginLeft: 1,
                          }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<MDRadio />}
                            label="Yes"
                            className="ms-0"
                          />
                          <FormControlLabel
                            value={false}
                            control={<MDRadio />}
                            label="No"
                            className="ms-0"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" light={true} flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        className="mb-3"
                      >
                        Recipe
                      </Typography>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box>
                              <TextField
                                label="Recipe"
                                name="recipe.recipe"
                                value={formProps.values.recipe?.recipe}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={
                                  formProps.errors.recipe?.recipe &&
                                  formProps.touched.recipe?.recipe
                                    ? true
                                    : false
                                }
                                helperText={
                                  formProps.errors.recipe?.recipe &&
                                  formProps.touched.recipe?.recipe
                                    ? formProps.errors.recipe?.recipe
                                    : null
                                }
                                fullWidth={true}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                label="Amount"
                                name="recipe.amount"
                                value={formProps.values.recipe?.amount}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={
                                  formProps.errors.recipe?.amount &&
                                  formProps.touched.recipe?.amount
                                    ? true
                                    : false
                                }
                                helperText={
                                  formProps.errors.recipe?.amount &&
                                  formProps.touched.recipe?.amount
                                    ? formProps.errors.recipe?.amount
                                    : null
                                }
                                fullWidth={true}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <TextField
                                label="Size"
                                name="recipe.size"
                                value={formProps.values.recipe?.size}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                error={
                                  formProps.errors.recipe?.size &&
                                  formProps.touched.recipe?.size
                                    ? true
                                    : false
                                }
                                helperText={
                                  formProps.errors.recipe?.size &&
                                  formProps.touched.recipe?.size
                                    ? formProps.errors.recipe?.size
                                    : null
                                }
                                fullWidth={true}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" light={true} flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        className="mb-3"
                      >
                        Supplement Or Nutrition
                      </Typography>
                      <FieldArray name="supplementOrNutrition">
                        {({ remove, push }) => (
                          <Box>
                            <TransitionGroup>
                              {map(
                                formProps.values.supplementOrNutrition,
                                (supplementItem, supplementIndex) => {
                                  const supplement = `supplementOrNutrition[${supplementIndex}].supplement`;
                                  const errorSupplement = getIn(
                                    formProps.errors,
                                    supplement
                                  );
                                  const touchedSupplement = getIn(
                                    formProps.touched,
                                    supplement
                                  );

                                  const amount = `supplementOrNutrition[${supplementIndex}].amount`;
                                  const errorAmount = getIn(
                                    formProps.errors,
                                    amount
                                  );
                                  const touchedAmount = getIn(
                                    formProps.touched,
                                    amount
                                  );

                                  const fieldSize = `supplementOrNutrition[${supplementIndex}].size`;
                                  const errorSize = getIn(
                                    formProps.errors,
                                    fieldSize
                                  );
                                  const touchedSize = getIn(
                                    formProps.touched,
                                    fieldSize
                                  );

                                  const isLargeSupplementArray =
                                    size(
                                      get(
                                        formProps,
                                        "values.supplementOrNutrition",
                                        1
                                      )
                                    ) > 1;

                                  return (
                                    <Collapse key={supplementIndex}>
                                      <Box>
                                        {supplementIndex !== 0 ? (
                                          <Box>
                                            <Stack
                                              direction="row"
                                              justifyContent="flex-start"
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Divider
                                                orientation="horizontal"
                                                flexItem={true}
                                                light={true}
                                                sx={{
                                                  width: `calc(100% - 48px)`,
                                                  margin: "auto 0 !important",
                                                }}
                                              />
                                              {isLargeSupplementArray && (
                                                <IconButton
                                                  color="error"
                                                  onClick={() =>
                                                    remove(supplementIndex)
                                                  }
                                                >
                                                  <ClearOutlinedIcon />
                                                </IconButton>
                                              )}
                                            </Stack>
                                          </Box>
                                        ) : null}
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            <Box>
                                              <TextField
                                                label="Supplement"
                                                name={supplement}
                                                value={
                                                  supplementItem?.supplement
                                                }
                                                onChange={
                                                  formProps.handleChange
                                                }
                                                onBlur={formProps.handleBlur}
                                                error={
                                                  errorSupplement &&
                                                  touchedSupplement
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  errorSupplement &&
                                                  touchedSupplement
                                                    ? errorSupplement
                                                    : null
                                                }
                                                fullWidth={true}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Box>
                                              <TextField
                                                label="Amount"
                                                name={amount}
                                                value={supplementItem?.amount}
                                                onChange={
                                                  formProps.handleChange
                                                }
                                                onBlur={formProps.handleBlur}
                                                error={
                                                  errorAmount && touchedAmount
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  errorAmount && touchedAmount
                                                    ? errorAmount
                                                    : null
                                                }
                                                fullWidth={true}
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Box>
                                              <TextField
                                                label="Size"
                                                name={fieldSize}
                                                value={supplementItem?.size}
                                                onChange={
                                                  formProps.handleChange
                                                }
                                                onBlur={formProps.handleBlur}
                                                error={
                                                  errorSize && touchedSize
                                                    ? true
                                                    : false
                                                }
                                                helperText={
                                                  errorSize && touchedSize
                                                    ? errorSize
                                                    : null
                                                }
                                                fullWidth={true}
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Collapse>
                                  );
                                }
                              )}
                              <Box sx={{ mr: 2, mt: 2 }}>
                                <MDButton
                                  variant="outlined"
                                  onClick={() =>
                                    push({
                                      supplement: "",
                                      amount: "",
                                      size: "",
                                    })
                                  }
                                >
                                  Add Supplement
                                </MDButton>
                              </Box>
                            </TransitionGroup>
                          </Box>
                        )}
                      </FieldArray>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" light={true} flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight={500}
                        className="mb-3"
                      >
                        Raw Food
                      </Typography>
                      <Box>
                        <FieldArray name="rawFood">
                          {({ remove, push }) => (
                            <Box>
                              <TransitionGroup>
                                {map(
                                  formProps.values.rawFood,
                                  (rawFoodItem, rawFoodIndex) => {
                                    const food = `rawFood[${rawFoodIndex}].food`;
                                    const errorFood = getIn(
                                      formProps.errors,
                                      food
                                    );
                                    const touchedFood = getIn(
                                      formProps.touched,
                                      food
                                    );

                                    const amount = `rawFood[${rawFoodIndex}].amount`;
                                    const errorAmount = getIn(
                                      formProps.errors,
                                      amount
                                    );
                                    const touchedAmount = getIn(
                                      formProps.touched,
                                      amount
                                    );

                                    const fieldsize = `rawFood[${rawFoodIndex}].size`;
                                    const errorSize = getIn(
                                      formProps.errors,
                                      fieldsize
                                    );
                                    const touchedSize = getIn(
                                      formProps.touched,
                                      fieldsize
                                    );

                                    const nutritionValue = `rawFood[${rawFoodIndex}].nutritionValue`;
                                    const errorNutritionValue = getIn(
                                      formProps.errors,
                                      amount
                                    );
                                    const touchedNutritionValue = getIn(
                                      formProps.touched,
                                      amount
                                    );

                                    const isLargeRawFoodArray =
                                      size(
                                        get(formProps, "values.rawFood", 1)
                                      ) > 1;

                                    return (
                                      <Collapse key={rawFoodIndex}>
                                        <Box>
                                          {rawFoodIndex !== 0 ? (
                                            <Box>
                                              <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={1}
                                              >
                                                <Divider
                                                  orientation="horizontal"
                                                  flexItem={true}
                                                  light={true}
                                                  sx={{
                                                    width: `calc(100% - 48px)`,
                                                    margin: "auto 0 !important",
                                                  }}
                                                />
                                                {isLargeRawFoodArray && (
                                                  <IconButton
                                                    color="error"
                                                    onClick={() =>
                                                      remove(rawFoodIndex)
                                                    }
                                                  >
                                                    <ClearOutlinedIcon />
                                                  </IconButton>
                                                )}
                                              </Stack>
                                            </Box>
                                          ) : null}
                                          <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                              <Box>
                                                <TextField
                                                  label="Food"
                                                  name={food}
                                                  value={rawFoodItem?.food}
                                                  onChange={
                                                    formProps.handleChange
                                                  }
                                                  onBlur={formProps.handleBlur}
                                                  error={
                                                    errorFood && touchedFood
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errorFood && touchedFood
                                                      ? errorFood
                                                      : null
                                                  }
                                                  fullWidth={true}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Box>
                                                <TextField
                                                  label="Amount"
                                                  name={amount}
                                                  value={rawFoodItem?.amount}
                                                  onChange={
                                                    formProps.handleChange
                                                  }
                                                  onBlur={formProps.handleBlur}
                                                  error={
                                                    errorAmount && touchedAmount
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errorAmount && touchedAmount
                                                      ? touchedAmount
                                                      : null
                                                  }
                                                  fullWidth={true}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Box>
                                                <TextField
                                                  label="Size"
                                                  name={fieldsize}
                                                  value={rawFoodItem?.size}
                                                  onChange={
                                                    formProps.handleChange
                                                  }
                                                  onBlur={formProps.handleBlur}
                                                  error={
                                                    errorSize && touchedSize
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errorSize && touchedSize
                                                      ? errorSize
                                                      : null
                                                  }
                                                  fullWidth={true}
                                                />
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Box>
                                                <TextField
                                                  label="Nutrition Value"
                                                  name={nutritionValue}
                                                  value={
                                                    rawFoodItem?.nutritionValue
                                                  }
                                                  onChange={
                                                    formProps.handleChange
                                                  }
                                                  onBlur={formProps.handleBlur}
                                                  error={
                                                    errorNutritionValue &&
                                                    touchedNutritionValue
                                                      ? true
                                                      : false
                                                  }
                                                  helperText={
                                                    errorNutritionValue &&
                                                    touchedNutritionValue
                                                      ? errorNutritionValue
                                                      : null
                                                  }
                                                  fullWidth={true}
                                                />
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </Collapse>
                                    );
                                  }
                                )}
                                <Box sx={{ mr: 2, mt: 2 }}>
                                  <MDButton
                                    variant="outlined"
                                    onClick={() =>
                                      push({
                                        food: "",
                                        amount: "",
                                        size: "",
                                        nutritionValue: "",
                                      })
                                    }
                                  >
                                    Add Raw Food
                                  </MDButton>
                                </Box>
                              </TransitionGroup>
                            </Box>
                          )}
                        </FieldArray>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider orientation="horizontal" light={true} flexItem />
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        label="Nutrition Value"
                        name="nutritionValue"
                        value={formProps.values.nutritionValue}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        error={
                          formProps.errors.nutritionValue &&
                          formProps.touched.nutritionValue
                            ? true
                            : false
                        }
                        helperText={
                          formProps.errors.nutritionValue &&
                          formProps.touched.nutritionValue
                            ? formProps.errors.nutritionValue
                            : null
                        }
                        fullWidth={true}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mt={3} mb={2}>
                      <Stack direction="row" spacing={1}>
                        <MDLoadingButton
                          variant="outlined"
                          onClick={() => navigate("/health-food/meal-list")}
                          className="w-100"
                        >
                          Cancel
                        </MDLoadingButton>
                        <MDLoadingButton
                          variant="contained"
                          type="submit"
                          className="w-100"
                        >
                          Submit
                        </MDLoadingButton>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </TimeOutComponent>
      </CardContent>
    </MDCard>
  );
};

export default MealCreateUpdateForm;
