import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { get, isEmpty } from "lodash";
import moment from "moment";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import createMarkup from "components/hooks/createMarkup";
import MDButton from "components/md-components/button/MDButton";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import NoDataOverlay from "components/md-components/empty-overlay/NoDataOverlay";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";

import { getBookingDetail } from "store/slice/booking/BookingSlice";

const BookingDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.booking.loading);
  const bookingDetail = useSelector((state) => state.booking.bookingDetail);

  const [emptyData, setEmptyData] = useState(false);

  useEffect(() => {
    if (
      isEmpty(bookingDetail) ||
      get(location, "state.id", "") !== get(bookingDetail, "_id", "")
    ) {
      dispatch(getBookingDetail(location.state.id))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            if (isEmpty(get(result, "data", {}))) {
              setEmptyData(true);
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [bookingDetail, dispatch, location, location.state.id]);

  return (
    <>
      <MDCard className="w-100 h-100">
        <MDCardHeader title="Booking Detail" />
        <CardContent className="w-100 h-100">
          {isLoading ? (
            <DetailCardSkeleton
              disableHeaderButton={true}
              skeletons={3}
              cardProps={{ variant: "outlined" }}
            />
          ) : emptyData ? (
            <NoDataOverlay />
          ) : (
            <Stack direction="column" spacing={1}>
              <MDCard variant="outlined" className="w-100 rounded-0">
                <MDCardHeader
                  title="Booking Detail's"
                  title_size="1rem"
                  title_color="grey"
                  title_color_variant="700"
                />
                <CardContent>
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    sx={{ rowGap: 1.5, columnGap: 2 }}
                  >
                    <DetailWrapper>
                      <DetailTitle title="Booking ID" />
                      <DetailValue>
                        {get(bookingDetail, "bookingId", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Booking Status" />
                      <DetailValue>
                        {get(bookingDetail, "bookingStatus", false) ? (
                          <CheckCircleIcon
                            color="success"
                            className="align-middle"
                          />
                        ) : (
                          <CancelIcon color="error" className="align-middle" />
                        )}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Booked On" />
                      <DetailValue highlight="true">
                        {moment(get(bookingDetail, "createdAt", "NA"), [
                          "MM-DD-YYYY",
                          "YYYY-MM-DD",
                        ]).format("MM/DD/YYYY")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Consultation Date" />
                      <DetailValue highlight="true">
                        {moment(get(bookingDetail, "consultationDate", "NA"), [
                          "MM-DD-YYYY",
                          "YYYY-MM-DD",
                        ]).format("MM/DD/YYYY")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Consultation Time" />
                      <DetailValue highlight="true">
                        {get(bookingDetail, "startTime", "NA") +
                          " - " +
                          get(bookingDetail, "endTime", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Goal Type" />
                      <DetailValue highlight="true">
                        {get(bookingDetail, "goalType.title", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Service Type" />
                      <DetailValue highlight="true">
                        {get(bookingDetail, "serviceType", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Zip Code" />
                      <DetailValue>
                        {get(bookingDetail, "zipCode", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Payment Type" />
                      <DetailValue>
                        {get(bookingDetail, "paymentType", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Payment Status" />
                      <DetailValue
                        highlight="true"
                        color={
                          get(bookingDetail, "paymentStatus", "NA") === "UNPAID"
                            ? "warning"
                            : "success"
                        }
                      >
                        {get(bookingDetail, "paymentStatus", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="View Answered Q&A" />
                      <MDButton
                        variant="contained"
                        label="Q&A Detail"
                        onClick={() =>
                          navigate("/booking/q-and-a-detail", {
                            state: { id: get(bookingDetail, "_id", "") },
                          })
                        }
                      />
                    </DetailWrapper>
                  </Stack>
                </CardContent>
              </MDCard>
              <MDCard variant="outlined" className="w-100 rounded-0">
                <MDCardHeader
                  title="User Detail's"
                  title_size="1rem"
                  title_color="grey"
                  title_color_variant="700"
                />
                <CardContent>
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    sx={{ rowGap: 1.5, columnGap: 2 }}
                  >
                    <DetailWrapper>
                      <DetailTitle title="User Name" />
                      <DetailValue>
                        {get(bookingDetail, "userId.firstName", "NA") +
                          " " +
                          get(bookingDetail, "userId.lastName", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Gender" />
                      <DetailValue>
                        {get(bookingDetail, "userId.gender", "NA") === "M"
                          ? "Male"
                          : "Female"}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="E-mail" />
                      <DetailValue>
                        {get(bookingDetail, "userId.email", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                  </Stack>
                </CardContent>
              </MDCard>
              <MDCard variant="outlined" className="w-100 rounded-0">
                <MDCardHeader
                  title="Consultant Detail's"
                  title_size="1rem"
                  title_color="grey"
                  title_color_variant="700"
                />
                <CardContent>
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    sx={{ rowGap: 1.5, columnGap: 2 }}
                  >
                    <DetailWrapper>
                      <DetailTitle title="User Name" />
                      <DetailValue>
                        {get(bookingDetail, "consultantId.firstName", "NA") +
                          " " +
                          get(bookingDetail, "consultantId.lastName", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Date of Birth" />
                      <DetailValue>
                        {moment(get(bookingDetail, "consultantId.DOB", "NA"), [
                          "MM-DD-YYYY",
                          "YYYY-MM-DD",
                        ]).format("MM/DD/YYYY")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="E-mail" />
                      <DetailValue>
                        {get(bookingDetail, "consultantId.email", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Mobile No." />
                      <DetailValue>
                        {get(bookingDetail, "consultantId.code", "NA") +
                          get(bookingDetail, "consultantId.mobile", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                  </Stack>
                </CardContent>
              </MDCard>
              <MDCard variant="outlined" className="w-100 rounded-0">
                <MDCardHeader
                  title="Plan Detail's"
                  title_size="1rem"
                  title_color="grey"
                  title_color_variant="700"
                />
                <CardContent>
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    flexWrap="wrap"
                    justifyContent="space-between"
                    sx={{ rowGap: 1.5, columnGap: 2 }}
                  >
                    <DetailWrapper>
                      <DetailTitle title="Plan Name" />
                      <DetailValue>
                        {get(bookingDetail, "rawPlanData.planName", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Details" />
                      <DetailValue
                        dangerouslySetInnerHTML={createMarkup(
                          get(bookingDetail, "rawPlanData.details", "NA")
                        )}
                      />
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Session Type" />
                      <DetailValue>
                        {get(bookingDetail, "rawPlanData.sessionName", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Time Unit" />
                      <DetailValue highlight="true">
                        {get(bookingDetail, "rawPlanData.timeUnit", "NA")} Min.
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Amount" />
                      <DetailValue highlight="true">
                        {get(
                          bookingDetail,
                          "rawPlanData.currencyId.currencySymbol",
                          "NA"
                        ) +
                          " " +
                          get(bookingDetail, "rawPlanData.amount", "NA")}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Number Of Consultant" />
                      <DetailValue>
                        {get(
                          bookingDetail,
                          "rawPlanData.numberOfConsultant",
                          "NA"
                        )}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Diet Plan" />
                      <DetailValue>
                        {get(bookingDetail, "rawPlanData.dietPlan", false) ? (
                          <CheckCircleIcon
                            color="success"
                            className="align-middle"
                          />
                        ) : (
                          <CancelIcon color="error" className="align-middle" />
                        )}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Online Consultation" />
                      <DetailValue>
                        {get(
                          bookingDetail,
                          "rawPlanData.onlineConsultation",
                          false
                        ) ? (
                          <CheckCircleIcon
                            color="success"
                            className="align-middle"
                          />
                        ) : (
                          <CancelIcon color="error" className="align-middle" />
                        )}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Offline Consultation" />
                      <DetailValue>
                        {get(
                          bookingDetail,
                          "rawPlanData.offlineConsultation",
                          false
                        ) ? (
                          <CheckCircleIcon
                            color="success"
                            className="align-middle"
                          />
                        ) : (
                          <CancelIcon color="error" className="align-middle" />
                        )}
                      </DetailValue>
                    </DetailWrapper>
                    <DetailWrapper>
                      <DetailTitle title="Phone Consultation" />
                      <DetailValue>
                        {get(
                          bookingDetail,
                          "rawPlanData.phoneConsultation",
                          false
                        ) ? (
                          <CheckCircleIcon
                            color="success"
                            className="align-middle"
                          />
                        ) : (
                          <CancelIcon color="error" className="align-middle" />
                        )}
                      </DetailValue>
                    </DetailWrapper>
                  </Stack>
                </CardContent>
              </MDCard>
            </Stack>
          )}
        </CardContent>
      </MDCard>
    </>
  );
};

export default BookingDetail;
