import { useDispatch } from "react-redux";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Toolbar from "@mui/material/Toolbar";

import NavSection from "components/layout/NavSection";
import { logout } from "store/slice/auth/AuthSlice";

const DrawerFooter = () => {
  const dispatch = useDispatch();

  return (
    <Toolbar
      variant="dense"
      className="position-sticky bottom-0 left-0 right-0 zIndex-sticky mt-auto"
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "#07080C",
        border: "1px solid",
        transition: "all .4s cubic-bezier(0.16, 1, 0.3, 1)",
        borderImageSlice: 1,
        borderImageWidth: 1,
        minHeight: "auto",
        "&.MuiToolbar-root": { p: 1 },
      }}
    >
      <NavSection
        navConfig={[
          {
            title: "Logout",
            icon: LogoutOutlinedIcon,
            path: "",
          },
        ]}
        onClick={() => {
          dispatch(logout());
        }}
      />
    </Toolbar>
  );
};

export default DrawerFooter;
