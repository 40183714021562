import AxiosDefault from "services/AxiosDefaultSetting";

export const GetGoalType = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=GOAL_TYPE&userType=CUSTOMER&level=1`,
  });
  return response;
};

export const GetDietaryPreference = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/general-configuration/get?sectionName=DIETARY_PREFERENCE&userType=CUSTOMER&level=1`,
  });
  return response;
};

export const GetFoodAllergies = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `admin/general-configuration/get?sectionName=FOOD_ALLERGIES&userType=PRE_CONSULTANT&level=1`,
  });
  return response;
};

export const GetQuestionsList = async (type) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/questions/list/get?type=${type}`,
  });
  return response;
};
export const CreateQuestion = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/questions/create`,
    data: data,
  });
  return response;
};
export const UpdateQuestion = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/questions/update/${id}`,
    data: data,
  });
  return response;
};
export const StatusUpdateQuestion = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/questions/status-update/${id}`,
    data: data,
  });
  return response;
};
export const GetQuestionTemplateList = async (type) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/questions/template/get?type=${type}`,
  });
  return response;
};
export const CreateQuestionTemplate = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/questions/template/create`,
    data: data,
  });
  return response;
};
export const UpdateQuestionTemplate = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/questions/template-update/${id}`,
    data: data,
  });
  return response;
};
export const StatusUpdateQuestionTemplate = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/questions/template-status-update/${id}`,
    data: data,
  });
  return response;
};
