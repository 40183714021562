import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import CMDialog from "components/utility/dialog/CMDialog";
import urlToFile from "components/hooks/urlToFile";
import AvatarPicker from "components/utility/avatar-picker";
import { iconImageUpload } from "store/slice/user-config/GeneralConfigSlice";

const ImageUpload = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, handleClose, formProps, setIsRefresh, dialogData, setIconURL } = props;
  const [editor, setEditor] = useState(null);
  const [loading, setLoading] = React.useState(false);

  let avatarPicker = useRef();
  avatarPicker = (editor) => {
    setEditor(editor);
  };
  const handleImageSave = async () => {
    if (!loading) {
      setLoading(true);
      const canvas = editor.getImage().toDataURL();
      urlToFile(canvas, get(editor, "props.image.name", ""), get(editor, "props.image.type", "")).then(function (file) {
        setIsRefresh(false);
        dispatch(iconImageUpload({ file: file }))
          .unwrap()
          .then(async (result) => {
            if (get(result, "status", false)) {
              toast.success(result?.message);
              setLoading(false);
              setOpen(false);
              setIsRefresh(true);
              console.log("result :>> ", result);
              formProps.setFieldValue("icon", result.data.URL);
              setIconURL(result.data.URL);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("error", error);
            // toast.error("Uploaded file is too large");
          });
      });
    }
  };
  return (
    <>
      <CMDialog
        dialogTitle={"Upload Icon"}
        dividers={true}
        dialogOpen={open}
        maxWidth="md"
        dialogClose={handleClose}
        dialogContent={<AvatarPicker ref={avatarPicker} selectedImage={dialogData?.rowData?.icon || ""} />}
        dialogAction={
          <Button variant="contained" disabled={loading} onClick={handleImageSave}>
            {!loading ? (
              "Upload"
            ) : (
              <>
                &nbsp;
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              </>
            )}
          </Button>
        }
      ></CMDialog>
    </>
  );
};

export default ImageUpload;
