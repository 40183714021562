import React from "react";
import { useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { Formik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import { toast } from "react-toastify";

import { updateConsultantBio } from "store/slice/user-management/ConsultantProfileSlice";
import {
  getNonVerifiedConsultantUserDetail,
  getVerifiedConsultantUserDetail,
} from "store/slice/user-management/ConsultantUserSlice";
import MDDialog from "components/md-components/dialog/MDDialog";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";

const ProfessionalInfoForm = (props) => {
  const { title, dialogOpen, handleDialogClose, userDetails, userType } = props;
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const apiData = {
      consultantUserId: get(userDetails, "_id", ""),
      data: values,
    };
    dispatch(updateConsultantBio(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false)) {
          if (userType === "verified") {
            dispatch(
              getVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          } else {
            dispatch(
              getNonVerifiedConsultantUserDetail({
                consultantUserId: get(userDetails, "_id", ""),
              })
            );
          }
          handleDialogClose();
          toast.success(get(result, "message", "Bio Update Successfully"));
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={title}
      open={dialogOpen}
      close={handleDialogClose}
      aria-labelledby="basic-info-dialog"
      aria-describedby="basic-info-dialog"
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{ bio: get(userDetails, "bio", "") }}
        validationSchema={Yup.object().shape({
          bio: Yup.string()
            .min(10, "Too Short!")
            .max(500, "Too Long!")
            .required("Bio is Required"),
        })}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="bio"
                    label="Bio"
                    name="bio"
                    value={props.values.bio}
                    error={props.errors.bio && props.touched.bio ? true : false}
                    helperText={
                      props.errors.bio && props.touched.bio
                        ? props.errors.bio
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    size="small"
                    fullWidth={true}
                    multiline={true}
                    rows={3}
                    sx={{
                      "& .MuiFormHelperText-root": { mx: 1 },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                label="Submit"
                variant="contained"
                loading={props.isSubmitting}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default ProfessionalInfoForm;
