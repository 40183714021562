import { filter, get, isEmpty, map, omit, size, uniqBy } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import numToArray from "components/hooks/numToArray";
import MDChipCheckbox from "components/md-components/form/MDChipCheckbox";
import MDRadio from "components/md-components/form/MDRadio";

import { FormHelperText } from "@mui/material";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import {
  getFDCIngredientDetail,
  getFDCIngredientsList,
} from "store/slice/health-food/RecipeSlice";
import {
  FoodNutrientCalc,
  FoodTotalNutrientCalc,
} from "../utility/FoodNutrientCalc";

const RecipeFormIngredientSelection = ({ formProps }) => {
  const dispatch = useDispatch();

  const [searchFieldOpen, setSearchFieldOpen] = useState(false);
  const [searchOptions, setSearchOptions] = React.useState([]);

  const loading = searchFieldOpen && searchOptions.length === 0;
  const isGetFDCIngredientDetailLoading = useSelector(
    (state) => state.healthRecipe.getFDCIngredientDetailLoading
  );

  const handleIngredientSearch = (value) => {
    if (!isEmpty(value)) {
      dispatch(getFDCIngredientsList(value))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            setSearchOptions(get(result, "data", []));
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  const handleIngredientDetail = (formProps) => {
    if (isEmpty(get(formProps, "values.ingredientSearch.ingredient", {}))) {
      formProps.setFieldError(
        "ingredientSearch.ingredient",
        "Ingredient is required, Please select any one ingredient"
      );
      return;
    } else if (get(formProps, "values.ingredientSearch.volume.value", 0) <= 0) {
      formProps.setFieldError(
        "ingredientSearch.volume.value",
        "Volume value is required"
      );
      return;
    }

    let ingredientSearch = get(formProps, "values.ingredientSearch", {});
    let payloadData = get(
      formProps,
      "values.ingredientSearch.ingredient.fdc_id",
      ""
    );

    if (!isEmpty(payloadData)) {
      dispatch(getFDCIngredientDetail(payloadData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false)) {
            console.log("RESULT :>>>>",result);
            const foodNutrients = get(
              result,
              "data.ingredientData.foodNutrients",
              {}
            );

            let ingredientFat = undefined;
            let ingredientProtein = undefined;
            let ingredientCarbohydrate = undefined;

            map(foodNutrients, (item) => {
              if (
                get(item, "nutrient.number", "") === "204" &&
                get(item, "nutrient.name", "") === "Total lipid (fat)"
              ) {
                ingredientFat = get(item, "amount", 0);
              }
              if (
                get(item, "nutrient.number", "") === "203" &&
                get(item, "nutrient.name", "") === "Protein"
              ) {
                ingredientProtein = get(item, "amount", 0);
              }
              if (
                get(item, "nutrient.number", "") === "205" &&
                get(item, "nutrient.name", "") === "Carbohydrate, by difference"
              ) {
                ingredientCarbohydrate = get(item, "amount", 0);
              }
            });

            let ingredientMeasureType = get(
              ingredientSearch,
              "measureType",
              "volume"
            );
            let calcData = {};

            if (ingredientMeasureType === "volume") {
              calcData = {
                measureType: ingredientMeasureType,
                volumeValue: get(ingredientSearch, "volume.value", 0),
                volumeUnit: get(ingredientSearch, "volume.unit", "GM"),
              };
            } else if (ingredientMeasureType === "portion") {
              calcData = {
                measureType: ingredientMeasureType,
                portionValue: get(ingredientSearch, "portion.value", 0),
              };
            } else if (ingredientMeasureType === "tablespoon") {
              calcData = {
                measureType: ingredientMeasureType,
                tablespoonFull: get(ingredientSearch, "tablespoon.full", 0),
                tablespoonParcel: get(ingredientSearch, "tablespoon.parcel", 0),
              };
            }

            let totalFat = FoodNutrientCalc({
              ...calcData,
              amount: ingredientFat,
            });
            let totalProtein = FoodNutrientCalc({
              ...calcData,
              amount: ingredientProtein,
            });
            let totalCarbs = FoodNutrientCalc({
              ...calcData,
              amount: ingredientCarbohydrate,
            });

            const finalResult = uniqBy(
              [
                ...formProps.values.ingredients,
                omit(
                  {
                    ...ingredientSearch,
                    ingredientId: get(ingredientSearch, "ingredient._id", ""),
                    description: get(
                      ingredientSearch,
                      "ingredient.description",
                      ""
                    ),
                    fdc_id: get(ingredientSearch, "ingredient.fdc_id", ""),
                    fat: parseFloat(totalFat),
                    protein: parseFloat(totalProtein),
                    carbs: parseFloat(totalCarbs),
                    calories: 0,
                  },
                  "ingredient"
                ),
              ],
              "fdc_id"
            );

            formProps.setFieldValue("ingredients", finalResult);

            formProps.setFieldValue(
              "totalFat",
              parseFloat(
                FoodTotalNutrientCalc({
                  array: finalResult,
                  key: "fat",
                  operator: "add",
                })
              )
            );
            formProps.setFieldValue(
              "totalProtein",
              parseFloat(
                FoodTotalNutrientCalc({
                  array: finalResult,
                  key: "protein",
                  operator: "add",
                })
              )
            );
            formProps.setFieldValue(
              "totalCarbs",
              parseFloat(
                FoodTotalNutrientCalc({
                  array: finalResult,
                  key: "carbs",
                  operator: "add",
                })
              )
            );
            formProps.setFieldValue(
              "totalCalories",
              parseFloat(
                FoodTotalNutrientCalc({
                  array: finalResult,
                  key: "calories",
                  operator: "add",
                })
              )
            );

            formProps.setFieldValue(
              "ingredientSearch",
              formProps.initialValues.ingredientSearch
            );
            setSearchOptions([]);
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };

  const handleIngredientDelete = (formProps, fdc_id) => {
    const calcData = {
      array: formProps.values.ingredients,
      operator: "subtract",
      fdc_id: fdc_id,
    };

    formProps.setFieldValue(
      "totalFat",
      parseFloat(
        FoodTotalNutrientCalc({
          ...calcData,
          key: "fat",
          totalValue: formProps.values.totalFat,
        })
      )
    );
    formProps.setFieldValue(
      "totalProtein",
      parseFloat(
        FoodTotalNutrientCalc({
          ...calcData,
          key: "protein",
          totalValue: formProps.values.totalProtein,
        })
      )
    );
    formProps.setFieldValue(
      "totalCarbs",
      parseFloat(
        FoodTotalNutrientCalc({
          ...calcData,
          key: "carbs",
          totalValue: formProps.values.totalCarbs,
        })
      )
    );
    formProps.setFieldValue(
      "totalCalories",
      parseFloat(
        FoodTotalNutrientCalc({
          ...calcData,
          key: "calories",
          totalValue: formProps.values.totalCalories,
        })
      )
    );

    formProps.setFieldValue(
      "ingredients",
      filter(formProps.values.ingredients, (item) => item?.fdc_id !== fdc_id)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h6">Search Ingredients</Typography>
        </Box>
      </Grid>
      <Grid item md={9} sm={8} xs={12}>
        <Box>
          <Autocomplete
            open={searchFieldOpen}
            onOpen={() => {
              setSearchFieldOpen(true);
            }}
            onClose={() => {
              setSearchFieldOpen(false);
            }}
            loading={loading && searchFieldOpen}
            options={searchOptions}
            getOptionLabel={(option) => option?.description ?? ""}
            isOptionEqualToValue={(option, value) => {
              if (option?._id === value?._id || option === {}) {
                return true;
              }
            }}
            name="ingredientSearch.ingredient"
            value={formProps.values?.ingredientSearch?.ingredient}
            onChange={(_event, newValue) => {
              formProps.setFieldValue("ingredientSearch.ingredient", newValue);
            }}
            onBlur={formProps.handleBlur}
            size="small"
            noOptionsText={"No Ingredients"}
            renderOption={(props, option) => (
              <li {...props} key={option?.fdc_id}>
                {option?.description}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Ingredient"
                name="ingredientSearch.ingredient"
                onChange={(event) => {
                  if (size(event?.target?.value) > 2) {
                    handleIngredientSearch(event?.target?.value);
                  }
                }}
                error={
                  formProps.errors?.ingredientSearch?.ingredient &&
                  formProps.touched?.ingredientSearch?.ingredient
                    ? true
                    : false
                }
                helperText={
                  formProps.errors?.ingredientSearch?.ingredient &&
                  formProps.touched?.ingredientSearch?.ingredient
                    ? formProps.errors?.ingredientSearch?.ingredient
                    : null
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item md={3} sm={4} xs={12}>
        <FormControl>
          <FormControlLabel
            label="Ingredient Required"
            name="ingredientSearch.ingredientRequired"
            checked={formProps.values.ingredientSearch?.ingredientRequired}
            onChange={formProps.handleChange}
            control={<MDChipCheckbox fontSize="16px" />}
            className="ms-0 me-0"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box className="mb-2">
          <Typography variant="body1" fontSize={18} fontWeight={500}>
            Select Quantity
          </Typography>
        </Box>
        <FormControl fullWidth className="gap-2">
          <FormLabel id="measure-type-group-label" className="mb-1">
            Select Measurement Type
          </FormLabel>
          <RadioGroup
            row={true}
            aria-labelledby="measure-type-group-label"
            name="ingredientSearch.measureType"
            value={formProps.values.ingredientSearch?.measureType}
            onChange={formProps.handleChange}
            className="ms-2 gap-3"
          >
            <FormControlLabel
              value="volume"
              control={<MDRadio />}
              label="Volume"
              className="ms-0 me-0"
            />
            {/* <FormControlLabel
              value="portion"
              control={<MDRadio />}
              label="Portion"
              className="ms-0 me-0"
            />
            <FormControlLabel
              value="tablespoon"
              control={<MDRadio />}
              label="Tablespoon"
              className="ms-0 me-0"
            /> */}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {formProps.values.ingredientSearch?.measureType === "volume" ? (
          <React.Fragment>
            <Grid item sm={6} xs={12}>
              <TextField
                type="number"
                label="Volume"
                name="ingredientSearch.volume.value"
                value={formProps.values.ingredientSearch?.volume?.value}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                error={
                  formProps.errors.ingredientSearch?.volume?.value &&
                  formProps.touched.ingredientSearch?.volume?.value
                    ? true
                    : false
                }
                helperText={
                  formProps.errors.ingredientSearch?.volume?.value &&
                  formProps.touched.ingredientSearch?.volume?.value
                    ? formProps.errors.ingredientSearch?.volume?.value
                    : null
                }
                fullWidth={true}
                size="small"
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <Autocomplete
                options={["GM", "KG", "OZ"]}
                name="ingredientSearch.volume.unit"
                value={formProps.values.ingredientSearch?.volume?.unit}
                getOptionLabel={(option) => option.toLowerCase() ?? ""}
                isOptionEqualToValue={(option, value) => {
                  if (option === value || option === "GM") {
                    return true;
                  }
                }}
                onChange={(_e, value) => {
                  formProps.setFieldValue(
                    "ingredientSearch.volume.unit",
                    value
                  );
                }}
                onBlur={formProps.handleBlur}
                noOptionsText={"No Data"}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="ingredientSearch.volume.unit"
                    label="Unit"
                    error={
                      formProps.errors.ingredientSearch?.volume?.unit &&
                      formProps.touched.ingredientSearch?.volume?.unit
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.ingredientSearch?.volume?.unit &&
                      formProps.touched.ingredientSearch?.volume?.unit
                        ? formProps.errors.ingredientSearch?.volume?.unit
                        : null
                    }
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ) : formProps.values.ingredientSearch?.measureType === "portion" ? (
          <React.Fragment>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                options={[
                  { value: "small", label: "Small" },
                  { value: "medium", label: "Medium" },
                  { value: "large", label: "Large" },
                ]}
                name="ingredientSearch.portion.value"
                value={formProps.values.ingredientSearch?.portion?.value}
                getOptionLabel={(option) => option?.label ?? ""}
                isOptionEqualToValue={(option, value) => {
                  if (option?.value === value?.value || option === "") {
                    return true;
                  }
                }}
                onChange={(_e, value) => {
                  formProps.setFieldValue(
                    "ingredientSearch.portion.value",
                    value
                  );
                }}
                onBlur={formProps.handleBlur}
                noOptionsText={"No Data"}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Portion Value"
                    name="ingredientSearch.portion.value"
                    error={
                      formProps.errors.ingredientSearch?.portion?.value &&
                      formProps.touched.ingredientSearch?.portion?.value
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.ingredientSearch?.portion?.value &&
                      formProps.touched.ingredientSearch?.portion?.value
                        ? formProps.errors.ingredientSearch?.portion?.value
                        : null
                    }
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item sm={3} xs={12}>
              <Autocomplete
                options={numToArray(11).slice(1)}
                name="ingredientSearch.tablespoon.full"
                value={formProps.values.ingredientSearch?.tablespoon?.full}
                getOptionLabel={(option) => option ?? ""}
                isOptionEqualToValue={(option, value) => {
                  if (option === value || option === "") {
                    return true;
                  }
                }}
                onChange={(_e, value) => {
                  formProps.setFieldValue(
                    "ingredientSearch.tablespoon.full",
                    value
                  );
                }}
                onBlur={formProps.handleBlur}
                noOptionsText={"No Data"}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="ingredientSearch.tablespoon.full"
                    label="Full Tablespoon"
                    error={
                      formProps.errors.ingredientSearch?.tablespoon?.full &&
                      formProps.touched.ingredientSearch?.tablespoon?.full
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.ingredientSearch?.tablespoon?.full &&
                      formProps.touched.ingredientSearch?.tablespoon?.full
                        ? formProps.errors.ingredientSearch?.tablespoon?.full
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <Autocomplete
                options={[
                  { value: "", label: "None" },
                  { value: "1/4", label: "1/4 Tablespoon" },
                  { value: "2/4", label: "2/4 Tablespoon" },
                  { value: "3/4", label: "3/4 Tablespoon" },
                ]}
                getOptionLabel={(option) => option?.label ?? ""}
                isOptionEqualToValue={(option, value) => {
                  if (option?.value === value?.value || option === "") {
                    return true;
                  }
                }}
                name="ingredientSearch.tablespoon.parcel"
                value={formProps.values.ingredientSearch?.tablespoon?.parcel}
                onChange={(_e, value) => {
                  formProps.setFieldValue(
                    "ingredientSearch.tablespoon.parcel",
                    value
                  );
                }}
                onBlur={formProps.handleBlur}
                noOptionsText={"No Data"}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="ingredientSearch.tablespoon.parcel"
                    label="Parcel Tablespoon"
                    error={
                      formProps.errors.ingredientSearch?.tablespoon?.parcel &&
                      formProps.touched.ingredientSearch?.tablespoon?.parcel
                        ? true
                        : false
                    }
                    helperText={
                      formProps.errors.ingredientSearch?.tablespoon?.parcel &&
                      formProps.touched.ingredientSearch?.tablespoon?.parcel
                        ? formProps.errors.ingredientSearch?.tablespoon?.parcel
                        : null
                    }
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box className="mt-2">
          <MDLoadingButton
            type="button"
            label="Add"
            variant="outlined"
            size="small"
            loading={isGetFDCIngredientDetailLoading}
            disabled={isGetFDCIngredientDetailLoading}
            onClick={() => {
              handleIngredientDetail(formProps);
            }}
          />
        </Box>
      </Grid>
      {Boolean(
        formProps.errors.ingredients && formProps.touched.ingredients
      ) ? (
        <Grid item xs={12}>
          <FormHelperText error={true}>
            {formProps.errors.ingredients}
          </FormHelperText>
        </Grid>
      ) : null}
      {!isEmpty(formProps.values.ingredients) ? (
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Box className="mt-3 border pt-2 px-3 pb-3 rounded-1">
              <Typography
                variant="subtitle1"
                fontSize="18px"
                fontWeight={500}
                gutterBottom={true}
              >
                Ingredients Selected
              </Typography>
              <Box className="pt-2 d-flex flex-wrap gap-3">
                {map(formProps.values.ingredients, (ingredient, index) => {
                  return (
                    <Chip
                      key={index}
                      label={ingredient?.description}
                      variant="outlined"
                      color="primary"
                      onDelete={() => {
                        handleIngredientDelete(formProps, ingredient?.fdc_id);
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="mt-3 border pt-2 px-3 pb-3 rounded-1">
              <Typography
                variant="subtitle1"
                fontSize="18px"
                fontWeight={500}
                gutterBottom={true}
              >
                Ingredients Nutrients
              </Typography>
              <Box className="pt-2">
                <Stack direction="column" spacing={1}>
                  <DetailWrapper>
                    <DetailTitle title="Fat" />
                    <DetailValue
                      value={get(formProps, "values.totalFat", "NA")}
                    />
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailTitle title="Protein" />
                    <DetailValue
                      value={get(formProps, "values.totalProtein", "NA")}
                    />
                  </DetailWrapper>
                  <DetailWrapper>
                    <DetailTitle title="Carbohydrate" />
                    <DetailValue
                      value={get(formProps, "values.totalCarbs", "NA")}
                    />
                  </DetailWrapper>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default RecipeFormIngredientSelection;
