import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty, get, filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  deleteCountryCity,
  statusUpdateCountryCity,
} from "store/slice/country-profile/CountryCitiesSlice";
import { getCountryProfiles } from "store/slice/country-profile/CountryProfileSlice";

import MDCard from "components/md-components/card/MDCard";
import MDDataGridToolbar from "components/md-components/data-table/MDDataGridToolbar";
import MDDataGrid from "components/md-components/data-table/MDDataGrid";
import EmptyTableRowOverlay from "components/md-components/empty-overlay/EmptyTableRowOverlay";
import MDSwitch from "components/md-components/switch/MDSwitch";
import CityProfileForm from "components/pages/cms/country-profile/CityProfileForm";
import DeleteConfirmDialog from "components/common/DeleteConfirmDialog";
import MDCardHeader from "components/md-components/card/MDCardHeader";

const CitiesProfileList = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isCountryProfileLoading = useSelector(
    (state) => state.countryProfile.loading
  );
  const countryProfileList = useSelector((state) => state.countryProfile.list);
  const isLoading = useSelector((state) => state.countryCities.loading);
  const countryProfileId =
    !isEmpty(location.state) && location.state.countryProfileId;
  const countryId = !isEmpty(location.state) && location.state.countryId;
  const countryStateName = !isEmpty(location.state) && location.state.stateName;
  const localStoreCountryProfileId = localStorage.getItem("countryProfileId");
  const localStoreCountryId = localStorage.getItem("countryId");
  const localStoreStateName = localStorage.getItem("countryStateName");
  const localStoreCityName = localStorage.getItem("countryCityName");

  const filterCountryProfile = filter(
    countryProfileList,
    (item) =>
      get(item, "_id", "") ===
      (!isEmpty(countryProfileId)
        ? countryProfileId
        : localStoreCountryProfileId)
  )[0];

  const filterCountryState = filter(
    get(filterCountryProfile, "country.states", []),
    (item) =>
      get(item, "name", "") ===
      (!isEmpty(countryStateName) ? countryStateName : localStoreStateName)
  )[0];

  const [dialogData, setDialogData] = useState({
    title: "",
    formType: "",
    countryId: get(filterCountryProfile, "country._id", localStoreCountryId),
    rowData: [],
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmation(false);
  };

  const updateStatus = useCallback(
    (event, params) => {
      setDialogData({ ...dialogData, id: params.id });
      const apiData = {
        countryId: !isEmpty(countryId) ? countryId : localStoreCountryId,
        data: {
          name: !isEmpty(countryStateName) && countryStateName,
          cityName: params.row.name,
          status: event.target.checked,
        },
      };
      dispatch(statusUpdateCountryCity(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            dispatch(getCountryProfiles());
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [countryId, countryStateName, dialogData, dispatch, localStoreCountryId]
  );
  const handleDelete = useCallback(
    ({ event }) => {
      const apiData = {
        countryId: !isEmpty(countryId) ? countryId : localStoreCountryId,
        data: {
          name: !isEmpty(countryStateName) && countryStateName,
          cityName: get(dialogData, "rowData.name", localStoreCityName),
          isDeleted: event,
        },
      };
      dispatch(deleteCountryCity(apiData))
        .unwrap()
        .then((result) => {
          if (get(result, "status", false) === true) {
            toast.success(get(result, "message", "City delete successfully"));
            dispatch(getCountryProfiles());
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((err) => {
          throw err;
        });
      handleDeleteConfirmationClose();
    },
    [
      countryId,
      countryStateName,
      dialogData,
      dispatch,
      localStoreCityName,
      localStoreCountryId,
    ]
  );

  useEffect(() => {
    dispatch(getCountryProfiles());
  }, [dispatch]);

  const columnsData = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 180,
        editable: false,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        width: 240,
        renderCell: (params) => {
          return (
            <Box className="w-100 d-flex justify-content-center align-items-center gap-3">
              <IconButton
                aria-label="edit"
                color="primary"
                onClick={() => {
                  setDialogData({
                    data: params.row,
                    formType: "update",
                    rowData: params.row,
                  });
                  localStorage.setItem("cityName", params.row.name);
                  handleDialogOpen();
                }}
              >
                <EditIcon fontSize="14px" />
              </IconButton>
              <MDSwitch
                checked={params.row.status}
                onChange={(event) => {
                  updateStatus(event, params);
                }}
                inputProps={{ "aria-label": params.row.id, role: "switch" }}
                color="success"
                disabled={
                  get(dialogData, "id", "") === params.id &&
                  (isLoading || isCountryProfileLoading)
                }
              />
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    id: params.row._id,
                    title: "City Delete Confirmation",
                    rowData: params.row,
                  });
                  handleDeleteConfirmation();
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [
      dialogData,
      handleDeleteConfirmation,
      handleDialogOpen,
      isCountryProfileLoading,
      isLoading,
      updateStatus,
    ]
  );

  const CustomToolbar = () => {
    return <MDDataGridToolbar align="right" divider={true} />;
  };

  return (
    <MDCard>
      <MDCardHeader title="City Profile" />
      <CardContent className="h-100">
        <Grid container spacing={0} className="h-100">
          <Grid item xs={12}>
            <MDDataGrid
              rows={get(filterCountryState, "cities", [])}
              columns={columnsData}
              getRowId={(row) => row.name}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick={true}
              loading={isLoading || isCountryProfileLoading}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: EmptyTableRowOverlay,
                LoadingOverlay: LinearProgress,
              }}
              autoHeight
            />
            <CityProfileForm
              title="City"
              dialogData={dialogData}
              dialogOpen={dialogOpen}
              handleDialogOpen={handleDialogOpen}
              handleDialogClose={handleDialogClose}
            />
            <DeleteConfirmDialog
              title="City Delete Confirmation"
              open={deleteConfirmation}
              handleClose={handleDeleteConfirmationClose}
              deleteAction={() => {
                handleDelete({ event: true, id: get(dialogData, "id", "") });
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default CitiesProfileList;
