import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  StatusUpdateCountryState,
  UpdateCountryState,
  DeleteCountryState,
} from "api/country-profile/CountryProfileAPI";

const initialState = {
  loading: false,
};

export const updateCountryState = createAsyncThunk(
  "countryStates/updateCountryState",
  async (data) => {
    return await UpdateCountryState(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const statusUpdateCountryState = createAsyncThunk(
  "countryStates/statusUpdateCountryState",
  async (data) => {
    return await StatusUpdateCountryState(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error;
      });
  }
);
export const deleteCountryState = createAsyncThunk(
  "countryCities/deleteCountryState",
  async (data) => {
    return await DeleteCountryState(data)
      .then(function (response) {
        if (get(response, "data.status", false)) {
          return get(response, "data", []);
        }
      })
      .catch(function (error) {
        return error.response;
      });
  }
);

export const CountryStatesSlice = createSlice({
  name: "countryStates",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateCountryState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCountryState.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCountryState.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateCountryState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(statusUpdateCountryState.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(statusUpdateCountryState.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCountryState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCountryState.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCountryState.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default CountryStatesSlice.reducer;
