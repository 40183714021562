import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ArchiveDietPlan,
  CreateUpdateDietPlan,
  DeleteDietPlan,
  GetDietPlanDetails,
  GetDietPlanList,
} from "api/health-food/DietPlanAPIs";
import { get } from "lodash";

const initialState = {
  dietPlanList: [],
  dietPlanDetails: [],
  loading: false,
};

export const getDietPlanList = createAsyncThunk(
  "healthDietPlan/getDietPlanList",
  async (data) => {
    try {
      const response = await GetDietPlanList(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const getDietPlanDetails = createAsyncThunk(
  "healthDietPlan/getDietPlanDetails",
  async (data) => {
    try {
      const response = await GetDietPlanDetails(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const createUpdateDietPlan = createAsyncThunk(
  "healthDietPlan/createUpdateDietPlan",
  async (data) => {
    try {
      const response = await CreateUpdateDietPlan(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const archiveDietPlan = createAsyncThunk(
  "healthDietPlan/archiveDietPlan",
  async (data) => {
    try {
      const response = await ArchiveDietPlan(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);
export const deleteDietPlan = createAsyncThunk(
  "healthDietPlan/deleteDietPlan",
  async (data) => {
    try {
      const response = await DeleteDietPlan(data);
      return response.data;
    } catch (error) {
      return { status: false, message: error.response.data.message };
    }
  }
);

export const DietPlanSlice = createSlice({
  name: "healthDietPlan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDietPlanList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDietPlanList.fulfilled, (state, { payload }) => {
        state.dietPlanList = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getDietPlanList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDietPlanDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDietPlanDetails.fulfilled, (state, { payload }) => {
        state.mealDetails = get(payload, "data.list", []);
        state.loading = false;
      })
      .addCase(getDietPlanDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateDietPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUpdateDietPlan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUpdateDietPlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(archiveDietPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(archiveDietPlan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(archiveDietPlan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteDietPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDietPlan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteDietPlan.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default DietPlanSlice.reducer;
