import { useState } from 'react';
import { map } from 'lodash';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import TabPanel from 'components/md-components/tab/TabPanel';
import GeneralConfigList from './GeneralConfigList';

function a11yProps(index) {
	return {
		id: `general-config-tab-${index}`,
		'aria-controls': `general-config-tab-panel-${index}`,
	};
}

const OrgConfig = () => {
	const theme = useTheme();
	const { palette } = theme;

	const [dialogOpen, setDialogOpen] = useState(false);
	const [value, setValue] = useState(0);

	const handleChange = (_event, newValue) => {
		setValue(newValue);
	};

	const handleDialogOpen = () => {
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const tabs = [
		{
			title: 'Industry Type',
			dialogOpen: dialogOpen,
			handleDialogOpen: handleDialogOpen,
			handleDialogClose: handleDialogClose,
			sectionName: 'INDUSTRY_TYPE',
		},
		{
			title: 'Department Type',
			dialogOpen: dialogOpen,
			handleDialogOpen: handleDialogOpen,
			handleDialogClose: handleDialogClose,
			sectionName: 'DEPARTMENT_TYPE',
		},
		{
			title: 'Role Type',
			dialogOpen: dialogOpen,
			handleDialogOpen: handleDialogOpen,
			handleDialogClose: handleDialogClose,
			sectionName: 'ROLE_TYPE',
		},
	];

	return (
		<MDCard>
			<MDCardHeader title='Organization Config' />
			<CardContent className='h-100'>
				<Grid container spacing={0} className='h-100 align-content-start'>
					<Grid item xs={12}>
						<Box sx={{ bgcolor: palette.background.default }}>
							<Tabs
								value={value}
								onChange={handleChange}
								variant='scrollable'
								scrollButtons='auto'
								aria-label='signup config tabs'
							>
								{map(tabs, (item, index) => (
									<Tab label={item.title} {...a11yProps(index)} key={index} />
								))}
							</Tabs>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box>
							{map(tabs, (item, index) => (
								<TabPanel key={index} value={value} index={index}>
									<GeneralConfigList {...item} index={index} />
								</TabPanel>
							))}
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</MDCard>
	);
};

export default OrgConfig;
