import { isArray, map } from 'lodash';
import i18next from 'i18next';

const translate = (valueKey) => {
	if (isArray(valueKey)) {
		const arrayValue = map(valueKey, (item) => i18next.t(item));
		return arrayValue.join(' ');
	} else {
		return i18next.t(valueKey);
	}
};

export default translate;
