import AxiosDefault from "services/AxiosDefaultSetting";

export const GetAdminRoles = async () => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/admin/role-management/get`,
  });
  return response;
};

export const CreateAdminRole = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/role-management/create`,
    data: data,
  });
  return response;
};

export const UpdateAdminRole = async ({ id, data }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/role-management/update/${id}`,
    data: data,
  });
  return response;
};

export const StatusUpdateAdminRole = async ({ id, status }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/role-management/status-update/${id}`,
    data: { status: status },
  });
  return response;
};

export const DeleteAdminRole = async ({ id, isDeleted }) => {
  const response = await AxiosDefault({
    method: "PATCH",
    url: `/admin/role-management/delete-status-update/${id}`,
    data: { isDeleted: isDeleted },
  });
  return response;
};
