import { useState } from "react";
import { useSelector } from "react-redux";
import { get, map } from "lodash";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import toCapitalize from "components/hooks/toCapitalize";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";

import { ReactComponent as FacebookIcon } from "../../../../../assets/icons/Facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../../../assets/icons/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../../../../assets/icons/Linkedin.svg";
import { ReactComponent as SkypeIcon } from "../../../../../assets/icons/Skype.svg";
import { ReactComponent as TwitterIcon } from "../../../../../assets/icons/Twitter.svg";
import { ReactComponent as YahooIcon } from "../../../../../assets/icons/Yahoo.svg";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/Youtube.svg";

import SocialMediaInfoForm from "./SocialMediaInfoForm";

const ConsultantSocialMediaInfo = (props) => {
  const { userDetails, userType } = props;
  const { palette } = useTheme();
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const [dialogData, setDialogData] = useState({
    title: "",
    data: [],
    formType: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const socialMediaIcon = (item) => {
    switch (item.socialMediaChanel) {
      case "Facebook":
        return (
          <FacebookIcon width={24} height={24} fill={palette.primary[700]} />
        );
      case "Instagram":
        return (
          <InstagramIcon width={24} height={24} fill={palette.primary[700]} />
        );
      case "Twitter":
        return (
          <TwitterIcon width={24} height={24} fill={palette.primary[700]} />
        );
      case "LinkedIn":
        return (
          <LinkedinIcon width={24} height={24} fill={palette.primary[700]} />
        );
      case "Yahoo":
        return <YahooIcon width={24} height={24} fill={palette.primary[700]} />;
      case "YouTube":
        return (
          <YoutubeIcon width={24} height={24} fill={palette.primary[700]} />
        );
      case "Skype":
        return <SkypeIcon width={24} height={24} fill={palette.primary[700]} />;
      default:
        break;
    }
  };

  function removeHttp(url) {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  }

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header border-top">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            Social Media Information
          </Typography>
          <IconButton
            color="primary"
            onClick={() => {
              setDialogData({
                ...dialogData,
                title: "Social Media Information Create",
                data: [],
                formType: "create",
              });
              handleDialogOpen();
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Stack>
      </Box>
      <CardContent>
        <Stack direction={{ xs: "row" }} flexWrap="wrap" className="gap-3">
          {map(get(userDetails, "socialMediaInfo", []), (item, index) => (
            <Box key={index} className="border p-2 rounded-1">
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                spacing={1}
              >
                <Box>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems="flex-start"
                    spacing={{ sm: 2, xs: 1 }}
                  >
                    <Avatar
                      src={item.socialMediaChanel}
                      alt={item.socialMediaChanel}
                      variant="rounded"
                      sx={{ bgcolor: palette.primary[50] }}
                    >
                      {socialMediaIcon(item)}
                    </Avatar>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        className="lh-sm fw-medium"
                      >
                        {toCapitalize(item.socialMediaChanel)}
                      </Typography>
                      <Typography variant="subtitle1" className="lh-sm">
                        <a
                          href={item.chanelLink}
                          target="blank"
                          className="link"
                        >
                          {removeHttp(item.chanelLink)}
                        </a>
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box className="ms-3">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setDialogData({
                        ...dialogData,
                        title: "Social Media Information Update",
                        data: item,
                        formType: "update",
                      });
                      handleDialogOpen();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>
        <SocialMediaInfoForm
          dialogData={dialogData}
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          userDetails={userDetails}
          userType={userType}
        />
      </CardContent>
    </MDCard>
  );
};

export default ConsultantSocialMediaInfo;
