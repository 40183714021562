import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { get, map } from "lodash";

import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DetailWrapper, {
  DetailTitle,
  DetailValue,
} from "components/common/DetailWrapper";
import MDCard from "components/md-components/card/MDCard";
import DetailCardSkeleton from "components/md-components/skeleton/DetailCardSkeleton";
import { Button } from "@mui/material";
import Image from "components/utility/media/Image";
import fileURLGenerator from "components/hooks/urlGenerator";
import Lightbox from "components/utility/lightbox";
import ResidentialAddressForm from "../residential-address/ResidentialAddressForm";
const OfflineUserDetails = (props) => {
  const { userDetails, userType } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const isLoading = useSelector((state) => state.consultantUser.loading);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  console.log("userDetails :>> ", userDetails);

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxData, setLightBoxData] = useState([]);
  const handleOpenLightBox = (src, alt) => {
    setLightBoxOpen(true);
    setLightBoxData([
      {
        src: src,
        alt: alt,
        caption: [],
      },
    ]);
  };
  const handleCloseLightBox = () => {
    setLightBoxOpen(false);
    setLightBoxData([]);
  };

  return isLoading ? (
    <DetailCardSkeleton />
  ) : (
    <MDCard>
      <Box className="detail-box-header">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body1" className="detail-box-heading">
            Offline
          </Typography>
          {/* <IconButton color="primary" onClick={() => handleDialogOpen()}>
            <EditIcon />
          </IconButton>  */}
        </Stack>
      </Box>
      <CardContent>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={{ md: 2, xs: 1.5 }}
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "12px",
            }}
          >
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Address" colon={true} />
              <DetailValue value={get(userDetails, "addressLine", "")} />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="City" colon={true} />
              <DetailValue value={get(userDetails, "city", "")} />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Zip Code" colon={true} />
              <DetailValue value={get(userDetails, "zipCode", "")} />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="State" colon={true} />
              <DetailValue value={get(userDetails, "state", "")} />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Country" colon={true} />
              <DetailValue value={get(userDetails, "country", "")} />
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Proof of Identity" colon={true} />
              <DetailValue className="d-flex flex-wrap gap-2">
                <Chip
                  label={get(userDetails, "proofOfIdentity", "")}
                  variant="outlined"
                  color="primary"
                />
              </DetailValue>
            </DetailWrapper>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridRowGap: "8px",
            }}
          >
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Front Image" colon={true} />
              <DetailValue>
                <Button
                  sx={{ maxWidth: 180 }}
                  onClick={() => {
                    handleOpenLightBox(
                      get(userDetails, "identityFrontUrl", ""),
                      "identity_front_image"
                    );
                  }}
                  className="p-1"
                >
                  <Image
                    width="120px"
                    height="80px"
                    alt="identity_front_image"
                    src={get(userDetails, "identityFrontUrl", "")}
                  />
                </Button>
              </DetailValue>
            </DetailWrapper>
            <DetailWrapper fullWidth={true}>
              <DetailTitle title="Back Image" colon={true} />
              <DetailValue>
                <Button
                  sx={{ maxWidth: 180 }}
                  onClick={() => {
                    handleOpenLightBox(
                      get(userDetails, "identityBackUrl", ""),
                      "identity_front_image"
                    );
                  }}
                  className="p-1"
                >
                  <Image
                    width="120px"
                    height="80px"
                    alt="identity_back_image"
                    src={get(userDetails, "identityBackUrl", "")}
                  />
                </Button>
              </DetailValue>
            </DetailWrapper>
          </Box>
        </Stack>

        <Lightbox
          open={lightBoxOpen}
          handleClose={handleCloseLightBox}
          imageData={lightBoxData}
        />
        <ResidentialAddressForm
          title="Residential Address Update"
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          userDetails={userDetails}
          userType={userType}
        />
      </CardContent>
    </MDCard>
  );
};

export default OfflineUserDetails;
