import { matchPath, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";

import NavItem from "./NavItem";

const NavSection = ({ navConfig, ...other }) => {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: true }, pathname) : false;

  return (
    <Box {...other} className="scrollbar-none w-100">
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
};
export default NavSection;
