import React from "react";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";

const MuiAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 6,
  overflow: "auto",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const MDAccordion = (props) => {
  const { children, ...rest } = props;
  return (
    <MuiAccordion disableGutters elevation={0} square {...rest}>
      {children}
    </MuiAccordion>
  );
};

export default MDAccordion;
