import { uniqWith, isEqual } from "lodash";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import MDSwitch from "components/md-components/switch/MDSwitch";
import RichTextEditor from "components/utility/rich-text-editor/RichTextEditor";


const QuotesFormSEOSection = ({ formProps }) => {
  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        Post Type
      </Typography>
      <Box className="mb-3">
        <Grid container spacing={2}>
          {/* <Grid item xs={12} container columnGap={2}>
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.isPublished}
                      onChange={(e) =>
                        formProps.setFieldValue("isPublished", e.target.checked)
                      }
                    />
                  }
                  label="Publish"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.comments}
                      onChange={(e) =>
                        formProps.setFieldValue("comments", e.target.checked)
                      }
                    />
                  }
                  label="Comments"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.likeStatus}
                      onChange={(e) =>
                        formProps.setFieldValue("likeStatus", e.target.checked)
                      }
                    />
                  }
                  label="Likes"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item md={2.5} sm={5} xs={12}>
              <Box marginLeft={{ sm: 1 }}>
                <FormControlLabel
                  control={
                    <MDSwitch
                      checked={formProps.values.ratingStatus}
                      onChange={(e) =>
                        formProps.setFieldValue(
                          "ratingStatus",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Ratings"
                  labelPlacement="start"
                  className="w-100 justify-content-between ms-0"
                />
              </Box>
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Quote"
              name="quote"
              value={formProps.values.quote}
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={
                formProps.errors.quote &&
                formProps.touched.quote
                  ? true
                  : false
              }
              helperText={
                formProps.errors.quote &&
                formProps.touched.quote
                  ? formProps.errors.quote
                  : null
              }
              fullWidth={true}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Author"
              name="author"
              value={formProps.values.author}
              onChange={formProps.handleChange}
              onBlur={formProps.handleBlur}
              error={
                formProps.errors.author && formProps.touched.author
                  ? true
                  : false
              }
              helperText={
                formProps.errors.author && formProps.touched.author
                  ? formProps.errors.author
                  : null
              }
              fullWidth={true}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <RichTextEditor
                name={'description'}
                value={formProps.values.description}
                setFieldValue={formProps.setFieldValue}
                error={
                  formProps.errors.description && formProps.touched.description
                    ? true
                    : false
                }
                helperText={
                  formProps.errors.description && formProps.touched.description
                    ? formProps.errors.description
                    : null
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default QuotesFormSEOSection;
