import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import { useSpring, animated } from 'react-spring';

const StyledTreeItemRoot = styled(TreeItem)(({ theme, other }) => ({
	color: theme.palette.primary.contrastText,
	cursor: 'pointer',
	[`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		borderRadius: theme.spacing(0.75),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		fontWeight: theme.typography.fontWeightMedium,
		// flexDirection: "row-reverse",
		':hover': {
			backgroundColor: theme.palette.primary.main,
			transition: 'all .4s cubic-bezier(0.16, 1, 0.3, 1)',
		},
		'&.Mui-expanded': {
			fontWeight: theme.typography.fontWeightRegular,
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			transition: 'all .4s cubic-bezier(0.16, 1, 0.3, 1)',
			':hover': {
				backgroundColor: theme.palette.primary.dark,
				transition: 'all .4s cubic-bezier(0.16, 1, 0.3, 1)',
			},
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 500,
			color: 'inherit',
			paddingLeft: 0,
		},
		[`& .${treeItemClasses.iconContainer}`]: {
			width: other?.type === 'sub-menu' && 'auto',
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: theme.spacing(1.5),
		paddingLeft: theme.spacing(1),
		borderLeft: `1px dashed ${alpha(theme.palette.primary[50], 0.5)}`,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(1),
		},
	},
	[`& .${treeItemClasses.iconContainer}`]: {
		color: theme.palette.grey[300],
	},
}));

const TransitionComponent = (props) => {
	const style = useSpring({
		from: {
			opacity: 0,
			transform: 'translate3d(20px,0,0)',
		},
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
};

const MDTreeItem = (props) => {
	const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, to, ...other } = props;

	const navigation = useNavigate();

	return (
		<StyledTreeItemRoot
			label={
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						p: 0.5,
						pr: 0,
						color: 'grey.300',
					}}
				>
					<Box component={LabelIcon} color='#FFFFFF' sx={{ mr: 1, fontSize: '1.25rem' }} />
					<Typography variant='body2' sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
						{labelText}
					</Typography>
					<Typography variant='caption' color='inherit'>
						{labelInfo}
					</Typography>
				</Box>
			}
			onClick={() => {
				to !== '' && navigation(to);
				localStorage.setItem('selectedMenuId', props.nodeId);
			}}
			TransitionComponent={TransitionComponent}
			{...other}
		/>
	);
};

MDTreeItem.propTypes = {
	bgColor: PropTypes.string,
	color: PropTypes.string,
	labelIcon: PropTypes.elementType.isRequired,
	labelInfo: PropTypes.string,
	labelText: PropTypes.string.isRequired,
	to: PropTypes.string,
};
export default MDTreeItem;
