import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
	Autocomplete,
	Avatar,
	Box,
	CardContent,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import ImageUpload from 'components/common/ImageUpload';
import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import MDSwitch from 'components/md-components/switch/MDSwitch';
import { ErrorMessage, Formik, useFormik } from 'formik';
import { get, isEmpty, cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	getCountryListForCountryProfile,
	getCurrencyList,
	getDocumentList,
	getTimezoneList,
} from 'store/slice/common/CommonDataSlice';
import { countryProfileCreate, countryProfileUpdate } from 'store/slice/country-profile/CountryProfileSlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import * as Yup from 'yup';
import Policies from './default-language-text-editor/Policies';

const ValidationSchema = Yup.object().shape({
	country: Yup.object().required('Country is required.').nullable(),
	state: Yup.array().min(1, 'State is required.'),
	city: Yup.array().min(1, 'City is required.'),
	timezone: Yup.array().of(Yup.object()).min(1, 'Timezone is required.').nullable(),
	currency: Yup.object().shape().required('Currency is required').nullable(),
	language: Yup.array().min(1, 'Language is required.'),
	defaultLanguage: Yup.string().required('Please Select Default Language'),
	// monthly: Yup.boolean().required("Country active is required."),
	policies: Yup.array().of(
		Yup.object().shape({
			userType: Yup.array().of(
				Yup.object().shape({
					description: Yup.string().required('Description is required'),
				})
			),
		})
	),
	documents: Yup.array().min(1, 'Document is required.'),
});
const AddEditCompanyProfile = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const countryList = useSelector((state) => state.commonData.countryList);
	const currencyList = useSelector((state) => state.commonData.currencyList);
	const documentList = useSelector((state) => state.commonData.documentList);
	const [selectedLanguage, setSelectedLanguage] = useState([]);
	const isLoading = useSelector((state) => state.countryProfile.loading);
	const formType = !isEmpty(location.state) && get(location, 'state.formType', '');
	const countryProfile = get(location, 'state.countryProfile', null);

	const timezonesList = useSelector((state) => state.commonData.timezoneList);
	const languagesList = useSelector((state) => state.languageConfig.languageList);

	const [open, setOpen] = useState(false);
	const [isRefresh, setIsRefresh] = useState(true);
	const [iconURL, setIconURL] = useState('');
	const [countryTimeZone, setCountryTimeZone] = useState('');
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [timeZone, setTimeZone] = useState();
	const [toggleButton, setToggleButton] = useState(false);

	const [policies, setPolicies] = useState([
		{
			policyName: 'Terms and conditions [sign up]',
			userType: [],
		},
		{
			policyName: 'Data policy',
			userType: [],
		},
		{
			policyName: 'Privacy policy',
			userType: [],
		},
		{
			policyName: 'Appointment- Terms and conditions',
			userType: [],
		},
	]);

	const props = useFormik({
		validationSchema: ValidationSchema,
		initialValues: {
			icon: get(countryProfile, 'icon', ''),
			//   name: get(countryProfile, "name", ""),
			country: get(countryProfile, 'country', ''),
			state: get(countryProfile, 'states', []),
			city: get(countryProfile, 'cities', []),
			currency: get(countryProfile, 'currency', ''),
			timezone: get(countryProfile, 'country.timezone[0]', []),
			language: get(countryProfile, 'language', []),
			defaultLanguage: get(countryProfile, 'defaultLanguage', ''),
			policies: policies,
			monthly: get(countryProfile, 'membership.[0].status', false),
			yearly: get(countryProfile, 'membership.[1].status', false),
			contentAccess: get(countryProfile, 'endUser.contentAccess', false),
			endUserConsultationAccess: get(countryProfile, 'endUser.consultantAcess', false),
			contentManagement: get(countryProfile, 'consultantUser.contentManagement', false),
			consultantUserConsultationAccess: get(countryProfile, 'consultantUser.consultantAcess', false),
			online: get(countryProfile, 'consultantUser.online', false),
			offline: get(countryProfile, 'consultantUser.offline', false),
			phone: get(countryProfile, 'consultantUser.phone', false),
			monthlyAmount: get(countryProfile, 'membership.[0].amount'),
			yearlyAmount: get(countryProfile, 'membership.[1].amount'),
			documents: get(countryProfile, 'documents', []),
		},
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	const handleDialogOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (countryProfile !== null) {
			setStateList(get(countryProfile.country, 'states', []));
			let getCities = [];
			countryProfile.states.forEach((state) => {
				const getStates = countryProfile.country.states.find((obj) => obj.name === state.name);
				getCities.push(getStates.cities);
			});
			setCityList(getCities);
			setPolicies(countryProfile.policies);
			setPolicies((policies) => {
				const list = cloneDeep(policies);
				list.forEach((item, pIndex) => {
					const findPolicies = countryProfile.policies.find((e) => e.policyName === item.policyName);
					list[pIndex] = findPolicies;
				});
				props.setFieldValue('policies', list);
				return list;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryProfile]);

	useEffect(() => {
		dispatch(getLanguageConfigList());
		dispatch(getCountryListForCountryProfile());
		dispatch(getTimezoneList());
		dispatch(getCurrencyList());
		dispatch(getDocumentList());
		setTimeZone(timezonesList?.filter((item) => item?.zoneName === countryTimeZone));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, countryTimeZone]);

	useEffect(() => {
		if (selectedLanguage.length !== 0) {
			setPolicies((previousData) => {
				const list = cloneDeep(previousData);

				list.forEach((item, pIndex) => {
					let tempPolicies = [];
					selectedLanguage.forEach((lang) => {
						for (let index = 0; index <= 2; index++) {
							tempPolicies.push({ type: 'User Type ' + parseInt(index + 1) + ' ' + lang.title, description: '' });
						}
					});
					list[pIndex]['userType'] = tempPolicies;
				});
				props.setFieldValue('policies', list);
				return list;
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLanguage]);

	const handleCountryChange = (data) => {
		setCountryTimeZone(data?.timezone[0]?.zoneName);
		let countryList = get(data, 'states', []);
		setStateList([
			...countryList,
			{
				name: 'All of these',
			},
		]);
		setCityList([]);
	};

	const handleStateChange = (data) => {
		let newCityarr = [];
		data?.map((item) => newCityarr.push(...item?.cities));
		newCityarr.unshift({
			name: 'All of these',
		});
		setCityList(newCityarr);
	};

	const handleSubmit = (values) => {
		const payload = {
			icon: values?.icon,
			country: values?.country?._id,
			states: values?.state?.map((state) => ({ name: state.name })),
			cities: values?.city?.map((city) => ({ name: city.name })),
			timezone: values?.timezone?._id,
			currency: values?.currency?._id,
			language: values?.language.map((lang) => ({ title: lang.title })),
			defaultLanguage: values?.defaultLanguage,
			endUser: {
				contentAccess: values?.contentAccess || false,
				consultantAcess: values?.endUserConsultationAccess || false,
			},
			consultantUser: {
				contentManagement: values?.contentManagement || false,
				consultantAcess: values?.consultantUserConsultationAccess || false,
				online: values?.online || false,
				offline: values?.offline || false,
				phone: values?.phone || false,
			},
			membership: [
				{
					type: 'monthly',
					status: values?.monthly || false,
					amount: values?.monthlyAmount || '',
				},
				{
					type: 'yearly',
					status: values?.yearly || false,
					amount: values?.yearlyAmount || '',
				},
			],
			policies: values.policies,
			documents: values?.documents.map((e) => ({ name: e.name })),
		};
		if (formType === 'create') {
			dispatch(countryProfileCreate(payload))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						toast.success(get(result, 'message', 'Country Profile Created Successfully'));
						navigate('/country-profile');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (formType === 'update') {
			const id = get(countryProfile, '_id', '');
			const updateData = {
				id: id,
				data: payload,
			};
			if (id) {
				dispatch(countryProfileUpdate(updateData))
					.unwrap()
					.then((result) => {
						if (get(result, 'status', false) === true) {
							toast.success(get(result, 'message', 'Country Profile Update Successfully'));
							navigate('/country-profile');
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	};

	return (
		<MDCard>
			<MDCardHeader
				title={`${formType === 'update' ? 'Update ' : formType === 'create' ? 'Create ' : null} Country Profile`}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box sx={{ my: 1 }}>
							<Formik>
								{() => {
									return (
										<form onSubmit={props.handleSubmit}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<Box sx={{ mb: 2 }}>
														<Box
															sx={{
																position: 'relative',
																width: 'max-content',
																mx: 'auto',
															}}
														>
															<Avatar
																name='icon'
																//   variant="square"
																src={props.values.icon}
																alt={get(countryProfile, 'icon', '')}
																sx={{
																	width: 96,
																	height: 96,
																	bgcolor: 'primary.main',
																}}
															/>
															<IconButton
																onClick={handleDialogOpen}
																className='position-absolute right-0 bottom-0'
																sx={{
																	backgroundColor: 'common.white',
																	'&:hover': {
																		backgroundColor: 'grey.200',
																	},
																}}
															>
																<PhotoCamera />
															</IconButton>
														</Box>
														<ImageUpload
															formProps={props}
															open={open}
															setOpen={setOpen}
															handleClose={handleClose}
															setIsRefresh={setIsRefresh}
															dialogData={countryProfile}
															setIconURL={setIconURL}
														/>
													</Box>
												</Grid>
												<Grid item xs={6}>
													<Autocomplete
														getOptionLabel={(option) => (option.name ? option.name : '')}
														name='country'
														value={props.values.country}
														options={countryList}
														isOptionEqualToValue={(option, value) => {
															if (value === '' || option._id === value._id) {
																return true;
															}
														}}
														onChange={(e, value) => {
															props.setFieldValue('country', value);
															handleCountryChange(value);
															props.setFieldValue('timezone', value?.timezone[0]);
														}}
														// multiple
														autoHighlight
														disableClearable
														noOptionsText='No data'
														size='small'
														renderOption={(props, option) => (
															<Box
																component='li'
																sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
																{...props}
															>
																<Box component='span' mr={1}>
																	{option.flag}
																</Box>
																{option.name}
															</Box>
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																placeholder='Select country'
																name='country'
																inputProps={{
																	...params.inputProps,
																	autoComplete: 'country',
																}}
																error={
																	props.errors.country && props.touched.country ? true : false
																}
																helperText={
																	props.errors.country && props.touched.country
																		? props.errors.country
																		: null
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6}>
													<Autocomplete
														getOptionLabel={(option) => option.name ?? option}
														name='state'
														value={props.values.state}
														options={stateList}
														isOptionEqualToValue={(option, value) => {
															if (value === '' || option.name === value.name) {
																return true;
															}
														}}
														onChange={(e, value) => {
															if (value?.some((state) => state.name === 'All of these')) {
																const states = stateList.filter(
																	(state) => state.name !== 'All of these'
																);
																props.setFieldValue('state', states);
																handleStateChange(states);
																// props.setFieldValue("city", cityList);
															} else {
																props.setFieldValue('state', value);
																handleStateChange(value);
															}
														}}
														multiple
														autoHighlight
														disableClearable
														noOptionsText='No data'
														size='small'
														renderOption={(props, option) => (
															<Box
																component='li'
																sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
																{...props}
															>
																<Box component='span' mr={1}>
																	{option.flag}
																</Box>
																{option.name}
															</Box>
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																placeholder='Select state'
																name='state'
																inputProps={{
																	...params.inputProps,
																	autoComplete: 'new-password',
																}}
																error={props.errors.state && props.touched.state ? true : false}
																helperText={
																	props.errors.state && props.touched.state
																		? props.errors.state
																		: null
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6}>
													<Autocomplete
														getOptionLabel={(option) => (option.name ? option.name : '')}
														name='city'
														// value={props.values.state.length === stateList.length - 1 ? cityList : props.values.city}
														value={props.values.city}
														options={cityList}
														isOptionEqualToValue={(option, value) => {
															if (value === '' || option.name === value.name) {
																return true;
															}
														}}
														onChange={(e, value) => {
															if (value?.some((city) => city.name === 'All of these')) {
																const cities = cityList.filter(
																	(city) => city.name !== 'All of these'
																);
																props.setFieldValue('city', cities);
															} else {
																props.setFieldValue('city', value);
															}
														}}
														multiple
														autoHighlight
														disableClearable
														noOptionsText='No data'
														size='small'
														renderOption={(props, option) => (
															<Box
																component='li'
																sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
																{...props}
															>
																<Box component='span' mr={1}>
																	{option.flag}
																</Box>
																{option.name}
															</Box>
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																placeholder='Select City'
																name='city'
																inputProps={{
																	...params.inputProps,
																	autoComplete: 'city',
																}}
																error={props.errors.city && props.touched.city ? true : false}
																helperText={
																	props.errors.city && props.touched.city
																		? props.errors.city
																		: null
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6}>
													<Autocomplete
														fullWidth={true}
														autoHighlight={true}
														size='small'
														id='timezone-select'
														name='timezone'
														// value={props.values.timezone}
														options={timezonesList || []}
														getOptionLabel={(option) => {
															return option.zoneName ?? option;
														}}
														isOptionEqualToValue={(option, value) => {
															if (value === '' || option.zoneName === value) {
																return true;
															}
														}}
														// onChange={(_event, value) => {
														//   if (value) {
														//     props.setFieldValue("timezone", value);
														//   }
														// }}
														onChange={(e, value) => {
															console.log('value :>> ', value);
															props.setFieldValue('timezone', value);
														}}
														value={
															timeZone?.length === 0 || undefined
																? props.values.timezone
																: timeZone && timeZone[0]?.zoneName
																? timeZone[0]?.zoneName
																: ''
														}
														renderInput={(params) => (
															<TextField
																{...params}
																label='Timezone'
																inputProps={{
																	...params.inputProps,
																	autoComplete: 'new-password',
																}}
																error={
																	props.errors.timezone && props.touched.timezone ? true : false
																}
																helperText={
																	props.errors.timezone && props.touched.timezone
																		? props.errors.timezone
																		: null
																}
																onBlur={props.handleBlur}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6}>
													<Autocomplete
														getOptionLabel={(option) => {
															return !isEmpty(option) ? option.currency : '';
														}}
														options={currencyList}
														size='small'
														name='currency'
														value={props.values.currency}
														isOptionEqualToValue={(option, value) => {
															if (value === '' || option._id === value._id) {
																return true;
															}
														}}
														onChange={(e, value) => {
															props.setFieldValue('currency', value);
														}}
														disablePortal
														autoHighlight
														disableClearable
														noOptionsText='No data'
														renderOption={(props, option) => (
															<Box
																key={option._id}
																component='li'
																sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
																{...props}
															>
																<Box component='span' mr={1}>
																	{option.currency}
																</Box>
																{option.currencySymbol}
															</Box>
														)}
														renderInput={(params) => (
															<TextField
																{...params}
																label='Currency Type'
																name='currency'
																error={
																	props.errors.currency && props.touched.currency ? true : false
																}
																helperText={
																	props.errors.currency && props.touched.currency
																		? props.errors.currency
																		: null
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={6}>
													<Autocomplete
														options={languagesList || []}
														name='language'
														value={props.values.language}
														getOptionLabel={(option) => option?.title ?? ''}
														isOptionEqualToValue={(option, value) => {
															if (option?.title === value?.name || option === '') {
																return true;
															}
														}}
														onChange={(_e, value) => {
															props.setFieldValue('language', value);
															setSelectedLanguage(value);
														}}
														onBlur={props.handleBlur}
														disablePortal
														autoHighlight
														size='small'
														multiple
														noOptionsText={'No Languages'}
														renderInput={(params) => (
															<TextField
																{...params}
																label='Select Language'
																name='language'
																error={
																	props.errors.language && props.touched.language ? true : false
																}
																helperText={
																	props.errors.language && props.touched.language
																		? props.errors.language
																		: null
																}
															/>
														)}
													/>
												</Grid>
												<Grid item xs={12}>
													{props.values.language.length > 0 && (
														<FormControl>
															<FormLabel id='demo-row-radio-buttons-group-label'>
																Default Language
															</FormLabel>
															<RadioGroup
																row
																aria-labelledby='demo-row-radio-buttons-group-label'
																name='defaultLanguage'
																//   defaultValue='English'
																value={props.values.defaultLanguage}
																onChange={(event) => {
																	props.setFieldValue('defaultLanguage', event.target.value);
																}}
															>
																{props.values.language.length > 0 &&
																	props.values.language.map((item, index) => (
																		<FormControlLabel
																			value={item?.title}
																			control={<Radio />}
																			label={item?.title}
																		/>
																	))}
															</RadioGroup>
															<ErrorMessage
																component='p'
																className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-1nzahhd-MuiFormHelperText-root'
																name='defaultLanguage'
															/>
														</FormControl>
													)}
												</Grid>
												<Grid item xs={12}>
													<Autocomplete
														options={documentList || []}
														name='documents'
														value={props.values.documents}
														getOptionLabel={(option) => option?.name ?? ''}
														isOptionEqualToValue={(option, value) => {
															if (option?.name === value?.name || option === '') {
																return true;
															}
														}}
														onChange={(_e, value) => {
															props.setFieldValue('documents', value);
														}}
														onBlur={props.handleBlur}
														disablePortal
														autoHighlight
														size='small'
														multiple
														noOptionsText={'No Documents'}
														renderInput={(params) => (
															<TextField
																{...params}
																label='Select Documents'
																name='documents'
																error={
																	props.errors.documents && props.touched.documents
																		? true
																		: false
																}
																helperText={
																	props.errors.documents && props.touched.documents
																		? props.errors.documents
																		: null
																}
															/>
														)}
													/>
												</Grid>

												{/* -----For End User----- */}
												<Grid item xs={12}>
													<Divider orientation='horizontal' flexItem />
												</Grid>
												<Grid item xs={12}>
													<Stack direction='row' className='gap-1'>
														<Typography variant='h6' className='mb-2'>
															For End User/Mobile app side
														</Typography>
													</Stack>
												</Grid>
												<Grid item xs={6}>
													<FormControlLabel
														control={
															<MDSwitch
																checked={props.values.contentAccess}
																onChange={(e) =>
																	props.setFieldValue('contentAccess', e.target.checked)
																}
															/>
														}
														label='Content Access'
														labelPlacement='start'
													/>
												</Grid>
												<Grid item xs={6}>
													<FormControlLabel
														control={
															<MDSwitch
																checked={props.values.endUserConsultationAccess}
																onChange={(e) =>
																	props.setFieldValue(
																		'endUserConsultationAccess',
																		e.target.checked
																	)
																}
															/>
														}
														label='Consultation Access'
														labelPlacement='start'
													/>
												</Grid>

												{/* -----For consultant User----- */}

												<Grid item xs={12}>
													<Divider orientation='horizontal' flexItem />
												</Grid>
												<Grid item xs={12}>
													<Stack direction='row' className='gap-1'>
														<Typography variant='h6' className='mb-2'>
															For consultant User/Web app side
														</Typography>
													</Stack>
												</Grid>
												<Grid item xs={6}>
													<FormControlLabel
														control={
															<MDSwitch
																checked={props.values.contentManagement}
																onChange={(e) =>
																	props.setFieldValue('contentManagement', e.target.checked)
																}
															/>
														}
														label='Content Management'
														labelPlacement='start'
													/>
												</Grid>
												<Grid item xs={6}>
													<div className='d-blog'>
														<FormControlLabel
															control={
																<MDSwitch
																	checked={props.values.consultantUserConsultationAccess}
																	onChange={(e) =>
																		props.setFieldValue(
																			'consultantUserConsultationAccess',
																			e.target.checked
																		)
																	}
																/>
															}
															label='Consultation Access'
															labelPlacement='start'
														/>
													</div>
													<FormControlLabel
														control={
															<MDSwitch
																checked={props.values.online}
																onChange={(e) => props.setFieldValue('online', e.target.checked)}
															/>
														}
														label='Online'
														labelPlacement='start'
													/>
													<FormControlLabel
														control={
															<MDSwitch
																checked={props.values.offline}
																onChange={(e) => props.setFieldValue('offline', e.target.checked)}
															/>
														}
														label='Offline'
														labelPlacement='start'
													/>
													<FormControlLabel
														control={
															<MDSwitch
																checked={props.values.phone}
																onChange={(e) => props.setFieldValue('phone', e.target.checked)}
															/>
														}
														label='Phone'
														labelPlacement='start'
													/>
												</Grid>

												<Grid item xs={12}>
													<Policies
														props={props}
														toggleButton={toggleButton}
														setToggleButton={setToggleButton}
													/>
												</Grid>

												{/* -----Membership [end user]----- */}

												<Grid item xs={12}>
													<Divider orientation='horizontal' flexItem />
												</Grid>
												<Grid item xs={12}>
													<Stack direction='row' className='gap-1'>
														<Typography variant='h6' className='mb-2'>
															Membership
														</Typography>
													</Stack>
												</Grid>
												<Grid item xs={6}>
													<FormControl>
														<FormGroup>
															<FormControlLabel
																control={
																	<Checkbox
																		name='monthly'
																		checked={props.values.monthly}
																		value={props.values.monthly}
																		onChange={(e) => {
																			props.setFieldValue('monthly', e.target.checked);
																		}}
																		inputProps={{
																			'aria-label': 'monthly',
																		}}
																	/>
																}
																label='Monthly'
																sx={{ mr: 0 }}
															/>
														</FormGroup>
													</FormControl>
													<FormControl fullWidth>
														{/* <FormLabel>&nbsp;</FormLabel> */}
														<TextField
															size='small'
															label='Amount'
															name='monthlyAmount'
															value={props.values.monthlyAmount}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															inputProps={{ type: 'number' }}
															fullWidth={true}
															error={
																props.errors.monthlyAmount && props.touched.monthlyAmount
																	? true
																	: false
															}
															helperText={
																props.errors.monthlyAmount && props.touched.monthlyAmount
																	? props.errors.monthlyAmount
																	: null
															}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={6}>
													<FormControl>
														<FormGroup>
															<FormControlLabel
																control={
																	<Checkbox
																		name='yearly'
																		checked={props.values.yearly}
																		value={props.values.yearly}
																		onChange={(e) => {
																			props.setFieldValue('yearly', e.target.checked);
																		}}
																		inputProps={{
																			'aria-label': 'yearly',
																		}}
																	/>
																}
																label='Yearly'
																sx={{ mr: 0 }}
															/>
														</FormGroup>
													</FormControl>

													<FormControl fullWidth>
														{/* <FormLabel>&nbsp;</FormLabel> */}
														<TextField
															size='small'
															label='Amount'
															name='yearlyAmount'
															value={props.values.yearlyAmount}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															inputProps={{ type: 'number' }}
															fullWidth={true}
															error={
																props.errors.yearlyAmount && props.touched.yearlyAmount
																	? true
																	: false
															}
															helperText={
																props.errors.yearlyAmount && props.touched.yearlyAmount
																	? props.errors.yearlyAmount
																	: null
															}
														/>
													</FormControl>
												</Grid>

												<Grid item xs={12} container spacing={2} className='mt-3 mb-3'>
													<Grid item sm={6} xs={12}>
														<MDLoadingButton
															type='reset'
															variant='outlined'
															padding='6px 24px'
															fullWidth={true}
															onClick={(_event) => {
																props.resetForm();
															}}
														>
															Reset
														</MDLoadingButton>
													</Grid>
													<Grid item sm={6} xs={12}>
														<MDLoadingButton
															type='submit'
															variant='contained'
															padding='6px 24px'
															fullWidth={true}
															loading={props.isSubmitting && isLoading}
														>
															Submit
														</MDLoadingButton>
													</Grid>
												</Grid>
											</Grid>
										</form>
									);
								}}
							</Formik>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</MDCard>
	);
};

export default AddEditCompanyProfile;
