import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FeedIcon from '@mui/icons-material/Feed';

const MenuList = [
	{
		nodeId: '1',
		label: 'Dashboard',
		icon: DashboardOutlinedIcon,
		path: '/dashboard',
		type: 'main-menu',
		subMenu: [],
	},
	{
		nodeId: '2',
		label: 'Profile',
		icon: PersonOutlineOutlinedIcon,
		path: '',
		type: 'main-menu',
		subMenu: [],
	},
	{
		nodeId: '3',
		label: 'CMS',
		icon: SettingsApplicationsOutlinedIcon,
		path: '',
		type: 'sub-menu',
		subMenu: [
			{
				nodeId: '3-1',
				label: 'Country Profile',
				icon: EditLocationOutlinedIcon,
				path: '/country-profile',
				type: 'main-menu',
			},
			{
				nodeId: '3-2',
				label: 'Language Config',
				icon: TranslateIcon,
				path: '/language-config/language-listing',
				type: 'main-menu',
			},
			{
				nodeId: '3-3',
				label: 'Users Config',
				icon: ManageAccountsIcon,
				path: '',
				type: 'sub-menu',
				subMenu: [
					{
						nodeId: '3-3-1',
						label: 'Organization Config',
						icon: HowToRegOutlinedIcon,
						path: '/org-config',
						type: 'main-menu',
					},
					{
						nodeId: '3-3-2',
						label: 'SignUp Config',
						icon: HowToRegOutlinedIcon,
						path: '/general-config',
						type: 'main-menu',
					},
					{
						nodeId: '3-3-3',
						label: 'Consultant Config',
						icon: PostAddOutlinedIcon,
						path: '',
						type: 'sub-menu',
						subMenu: [
							{
								nodeId: '3-3-3-1',
								label: 'Pre Consultation Q&A',
								icon: SupportAgentOutlinedIcon,
								path: 'consultant-config/pre-consultant',
								type: 'main-menu',
							},
							{
								nodeId: '3-3-3-2',
								label: 'Default Consultation Q&A',
								icon: SupportAgentOutlinedIcon,
								path: 'consultant-config/default-question-list',
								type: 'main-menu',
							},
							// {
							//   nodeId: "10",
							//   label: "Post Consultation Q&A",
							//   icon: SupportAgentOutlinedIcon,
							//   path: "consultant-config/post-consultant",
							//   type: "main-menu",
							// },
						],
					},
				],
			},
			{
				nodeId: '3-4',
				label: 'Knowledge Base Config',
				icon: ManageAccountsIcon,
				path: '',
				type: 'sub-menu',
				subMenu: [
					{
						nodeId: '3-4-1',
						label: 'Knowledge Base Category',
						icon: HowToRegOutlinedIcon,
						path: '/knowledge-base/category',
						type: 'main-menu',
					},
				],
			},
			// {
			// 	nodeId: '3-5',
			// 	label: 'Programmatic Seo',
			// 	icon: TranslateIcon,
			// 	path: '/programmatic-seo',
			// 	type: 'main-menu',
			// },
		],
	},
	{
		nodeId: '4',
		label: 'User Management',
		icon: BadgeOutlinedIcon,
		path: '',
		type: 'sub-menu',
		subMenu: [
			{
				nodeId: '4-1',
				label: 'End Users',
				icon: ManageAccountsOutlinedIcon,
				path: '/user-management/end-user',
				type: 'main-menu',
			},
			{
				nodeId: '4-2',
				label: 'Consultant User',
				icon: ManageAccountsOutlinedIcon,
				path: '/user-management/consultant-user',
				type: 'main-menu',
			},
			{
				nodeId: '4-3',
				label: 'Consultant Verification',
				icon: VerifiedUserOutlinedIcon,
				path: '/user-management/consultant-verification-request',
				type: 'main-menu',
			},
			{
				nodeId: '4-4',
				label: 'Consultant Document Verification',
				icon: VerifiedUserOutlinedIcon,
				path: '/user-management/consultant-document-verification',
				type: 'main-menu',
			},
		],
	},
	{
		nodeId: '5',
		label: 'Role Management',
		icon: BadgeOutlinedIcon,
		path: '',
		type: 'sub-menu',
		subMenu: [
			{
				nodeId: '5-1',
				label: 'Admin Users List',
				icon: ManageAccountsOutlinedIcon,
				path: '/role-management/admin-user-list',
				type: 'main-menu',
			},
			{
				nodeId: '5-2',
				label: 'Admin Roles List',
				icon: ManageAccountsOutlinedIcon,
				path: '/role-management/role-list',
				type: 'main-menu',
			},
			// {
			//   nodeId: "19",
			//   label: "Admin Role Assign",
			//   icon: ManageAccountsOutlinedIcon,
			//   path: "/role-management/role-assign",
			//   type: "main-menu",
			// },
		],
	},
	{
		nodeId: '6',
		label: 'Booking',
		icon: EventAvailableOutlinedIcon,
		path: '/booking',
		type: 'main-menu',
	},
	// {
	//   nodeId: "21",
	//   label: "Article / Blog",
	//   icon: ArticleOutlinedIcon,
	//   path: "/article-blog",
	//   type: "main-menu",
	// },
	{
		nodeId: '7',
		label: 'Content Management',
		icon: NewspaperIcon,
		path: '',
		type: 'sub-menu',
		subMenu: [
			{
				nodeId: '7-1',
				label: 'Article / Blog',
				icon: ArticleOutlinedIcon,
				path: '/article-blog',
				type: 'main-menu',
			},
			{
				nodeId: '7-2',
				label: 'Press Release',
				icon: FeedIcon,
				path: '/press-release-blog',
				type: 'main-menu',
			},
			{
				nodeId: '7-3',
				label: 'Content',
				icon: ContentPasteIcon,
				path: '/content',
				type: 'main-menu',
			},
			{
				nodeId: '7-4',
				label: 'App',
				icon: ContentPasteIcon,
				path: '/app-content',
				type: 'main-menu',
			},
		],
	},
	{
		nodeId: '8',
		label: 'Quotes-Management',
		icon: ArticleOutlinedIcon,
		path: '/quotes-management',
		type: 'main-menu',
	},
];

export default MenuList;
