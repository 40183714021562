import React from "react";

const useFakeMutation = () => {
  return React.useCallback(
    (name, row) =>
      new Promise((resolve, reject) =>
        setTimeout(() => {
          if (row[name]?.trim() === "") {
            console.log("CHECK TRUE");
            reject(new Error(`${name} field can't be empty.`));
          } else {
            resolve({ ...row, [name]: row[name]?.toUpperCase() });
          }
        }, 200)
      ),
    []
  );
};

export default useFakeMutation;
