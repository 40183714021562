import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";

import { get, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  addLanguage,
  getLanguageConfigList,
} from "store/slice/language-config/LanguageConfigSlice";
import MDDialog from "components/md-components/dialog/MDDialog";
import MDLoadingButton from "components/md-components/button/MDLoadingButton";

const AddLanguageForm = (props) => {
  const { title, open, handleClose } = props;

  const dispatch = useDispatch();
  const LanguageList = useSelector(
    (state) => state.languageConfig.languageList
  );

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    displayTitle: Yup.string().required("Display title is required."),
  });

  const handleSubmit = (values) => {
    const apiData = { ...values, copyLanguageId: values.copyLanguageId._id };

    dispatch(addLanguage(apiData))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false) === true) {
          toast.success(get(result, "message", "Language added successfully"));
          dispatch(getLanguageConfigList());
          handleClose();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  return (
    <MDDialog
      title={title}
      open={open}
      close={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <Formik
        initialValues={{
          title: "",
          displayTitle: "",
          copyLanguageId: [],
          status: false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="d-flex flex-column overflow-auto"
          >
            <DialogContent dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Title"
                    name="title"
                    value={props.values.title}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.title && props.touched.title ? true : false
                    }
                    helperText={
                      props.errors.title && props.touched.title
                        ? props.errors.title
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Display Title"
                    name="displayTitle"
                    value={props.values.displayTitle}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.errors.displayTitle && props.touched.displayTitle
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.displayTitle && props.touched.displayTitle
                        ? props.errors.displayTitle
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth={true}
                    size="small"
                    id="select-language"
                    options={!isEmpty(LanguageList) ? LanguageList : []}
                    getOptionLabel={(option) => option.title ?? ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    name="copyLanguageId"
                    value={props.values.copyLanguageId}
                    onChange={(_event, newValue) => {
                      props.setFieldValue("copyLanguageId", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Copy Language Macro"
                        error={
                          props.errors.copyLanguageId &&
                          props.touched.copyLanguageId
                            ? true
                            : false
                        }
                        helperText={
                          props.errors.copyLanguageId &&
                          props.touched.copyLanguageId
                            ? props.errors.copyLanguageId
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="status"
                            checked={props.values.status}
                            value={props.values.status}
                            onChange={(e) => {
                              props.setFieldValue("status", e.target.checked);
                            }}
                            inputProps={{ "aria-label": "Status" }}
                          />
                        }
                        label="Status"
                        sx={{ mr: 0 }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDLoadingButton
                type="submit"
                variant="contained"
                padding="6px 24px"
                loading={props.isSubmitting}
              >
                Submit
              </MDLoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </MDDialog>
  );
};

export default AddLanguageForm;
