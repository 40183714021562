import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { get, omit } from "lodash";
import { toast } from "react-toastify";

import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import { createAdminUser } from "store/slice/role-management/AdminUserSlice";

import MDLoadingButton from "components/md-components/button/MDLoadingButton";
import DialogAvatarPicker from "components/utility/avatar-picker/DialogAvatarPicker";
import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import { getAdminRoles } from "store/slice/role-management/AdminRoleSlice";
import { Autocomplete } from "@mui/material";

const AdminUserForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getRoles = useSelector((state) => state.adminRoles.list);
  const formType = get(location, "state.formType", "");
  const userData = get(location, "state.userData", "");

  const isLoading = useSelector((state) => state.adminUsers.loading);

  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(
    get(userData, "profilePictureURL", "")
  );

  const handleAvatarDialogOpen = () => {
    setAvatarDialogOpen(true);
  };
  const handleAvatarDialogClose = () => {
    setAvatarDialogOpen(false);
  };

  const phoneRegExp = /^[0-9]{10}$/g;
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    firstName: Yup.string()
      .min(2, "First name is too Short!")
      .max(18, "First name is too Long!")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last name is too Short!")
      .max(18, "Last name is too Long!")
      .required("Last name is required"),
    role: Yup.object().shape().required("User role is required").nullable(),
    // role: Yup.string().required("User role is required"),
    dob: Yup.string().required("Date of birth is required"),
    email: Yup.string()
      .email("Invalid email id")
      .required("E-Mail ID is required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const handleSubmit = (values) => {
    console.log('values :>> ', values);
    let data = {}
    if (formType === "update") {
      data = {
        ...omit(values, "status", "isDeleted"),
        _id: get(userData, "_id", ""),
        role: values?.role?._id
      };
    } else {
      data = {
        ...values,
        role: values?.role?._id
      };
    }
    console.log('data :>> ', data);
    dispatch(createAdminUser(data))
      .unwrap()
      .then((result) => {
        if (get(result, "status", false) === true) {
          toast.success(get(result, "message", "User created successfully"));
          navigate("/role-management/admin-user-list");
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    dispatch(getAdminRoles());
  }, [dispatch]);


  return (
    <MDCard className="pb-4">
      <MDCardHeader
        title={`Admin User ${
          formType === "create" ? "Create" : "Detail's Update"
        }`}
      />
      <CardContent>
        <Formik
          initialValues={{
            file: get(userData, "image", ""),
            role: get(userData, "role", null),
            title: get(userData, "title", ""),
            firstName: get(userData, "firstName", ""),
            lastName: get(userData, "lastName", ""),
            dob: get(userData, "dob", ""),
            email: get(userData, "email", ""),
            mobile: get(userData, "mobile", ""),
            status: get(userData, "status", false),
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        position: "relative",
                        width: "max-content",
                        mx: "auto",
                      }}
                    >
                      <Avatar
                        src={imagePreview}
                        alt="User Profile Image"
                        sx={{ width: "96px", height: "96px" }}
                      />
                      <Box className="avatar-edit-btn">
                        <IconButton
                          color="primary"
                          aria-label="Edit Profile"
                          onClick={handleAvatarDialogOpen}
                        >
                          <CameraAltIcon sx={{ color: "primary.dark" }} />
                        </IconButton>
                      </Box>
                    </Box>
                    <DialogAvatarPicker
                      open={avatarDialogOpen}
                      handleClose={handleAvatarDialogClose}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                      name="file"
                      setFieldValue={props.setFieldValue}
                    />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  {/* <TextField
                    fullWidth={true}
                    size="small"
                    label="Role"
                    name="role"
                    value={props.values.role}
                    error={
                      props.errors.role && props.touched.role ? true : false
                    }
                    helperText={
                      props.errors.role && props.touched.role
                        ? props.errors.role
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  /> */}
                  <Autocomplete
                    options={getRoles || []}
                    name="role"
                    value={props.values.role}
                    getOptionLabel={(option) => option?.title ?? ""}
                    isOptionEqualToValue={(option, value) => {
                      if (option?._id === value?._id || option === "") {
                        return true;
                      }
                    }}
                    onChange={(_e, value) => {
                      props.setFieldValue("role", value);
                    }}
                    onBlur={props.handleBlur}
                    disablePortal
                    autoHighlight
                    size="small"
                    noOptionsText={"No Role"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Role"
                        name="role"
                        error={
                          props.errors.role && props.touched.role ? true : false
                        }
                        helperText={
                          props.errors.role && props.touched.role
                            ? props.errors.role
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Title"
                    name="title"
                    value={props.values.title}
                    error={
                      props.errors.title && props.touched.title ? true : false
                    }
                    helperText={
                      props.errors.title && props.touched.title
                        ? props.errors.title
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="First Name"
                    name="firstName"
                    value={props.values.firstName}
                    error={
                      props.errors.firstName && props.touched.firstName
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.firstName && props.touched.firstName
                        ? props.errors.firstName
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Last Name"
                    name="lastName"
                    value={props.values.lastName}
                    error={
                      props.errors.lastName && props.touched.lastName
                        ? true
                        : false
                    }
                    helperText={
                      props.errors.lastName && props.touched.lastName
                        ? props.errors.lastName
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <MobileDatePicker
                    label="Date Of Birth"
                    name="dob"
                    value={props.values.dob}
                    onChange={(newValue) => {
                      props.setFieldValue("dob", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth={true}
                        error={
                          props.errors.dob && props.touched.dob ? true : false
                        }
                        helperText={
                          props.errors.dob && props.touched.dob
                            ? props.errors.dob
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Email"
                    name="email"
                    value={props.values.email}
                    error={
                      props.errors.email && props.touched.email ? true : false
                    }
                    helperText={
                      props.errors.email && props.touched.email
                        ? props.errors.email
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth={true}
                    size="small"
                    label="Phone Number"
                    name="mobile"
                    value={props.values.mobile}
                    error={
                      props.errors.mobile && props.touched.mobile ? true : false
                    }
                    helperText={
                      props.errors.mobile && props.touched.mobile
                        ? props.errors.mobile
                        : null
                    }
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="status"
                            checked={props.values.status}
                            value={props.values.status}
                            onChange={(e) => {
                              props.setFieldValue("status", e.target.checked);
                            }}
                            inputProps={{ "aria-label": "Status" }}
                          />
                        }
                        label="Status"
                        sx={{ mr: 0 }}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box className="mt-3 text-center">
                    <MDLoadingButton
                      type="submit"
                      variant="contained"
                      padding="6px 24px"
                      loading={props.isSubmitting && isLoading}
                    >
                      Submit
                    </MDLoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CardContent>
    </MDCard>
  );
};

export default AdminUserForm;
