import AxiosDefault from 'services/AxiosDefaultSetting';

export const GetContentList = async (data) => {
	const response = await AxiosDefault({
		method: 'POST',
		url: `/admin/content/list`,
		data: data,
	});
	return response;
};

export const UpdateApproveContent = async ({ id, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/content/approve/${id}`,
		data: data,
	});
	return response;
};

export const ImageUploadContent = async (data) => {
	const response = await AxiosDefault({
		method: 'POST',
		url: `/admin/content/image/upload`,
		data: data,
		contentType: 'multipart/form-data',
	});
	return response;
};

export const CreateUpdateContent = async (data) => {
	const response = await AxiosDefault({
		method: 'POST',
		url: `/admin/content/add-update`,
		data: data,
	});
	return response;
};

export const PublishContent = async ({ id, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/content/publish/${id}`,
		data: data,
	});
	return response;
};

export const DeleteContent = async (id) => {
	const response = await AxiosDefault({
		method: 'DELETE',
		url: `/admin/content/delete/${id}`,
	});
	return response;
};

export const UpdateApproveArticle = async ({ id, data }) => {
	const response = await AxiosDefault({
		method: 'PATCH',
		url: `/admin/content/approve/${id}`,
		data: data,
	});
	return response;
};
