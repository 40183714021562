import axios from 'axios';
import cookie, { cookieSet } from 'components/hooks/cookie';

export function errorInterceptor() {
	axios.interceptors.response.use(null, (error) => {
		const { response } = error;
		if (!response) {
			return {};
		}

		if (response.status === 401) {
			try {
				cookie.remove('auth_token');
				cookie.remove('role_type');
				cookie.remove('selectedMenuId');
				localStorage.clear();
				sessionStorage.clear();
				cookieSet('auth_token', '');
				window.location.replace('/');
				window.location = '/';
			} catch (e) {
				return e;
			}
		}

		return response;
	});
}
