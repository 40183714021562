import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteConfirmDialog from 'components/common/DeleteConfirmDialog';
import MDButton from 'components/md-components/button/MDButton';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import DataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import AxiosDefaultSetting from 'services/AxiosDefaultSetting';
import { useNavigate } from 'react-router-dom';

const CustomToolbar = () => {
	const navigate = useNavigate();

	return (
		<DataGridToolbar align='right' divider={true} DisableToolbarColumnsButton={true}>
			<MDButton
				variant='contained'
				label={`Create New`}
				startIcon={<AddCircleOutlineIcon />}
				fontSize='14px'
				onClick={() => navigate('/app-content/create/welcome?sectionName=welcome')}
			/>
		</DataGridToolbar>
	);
};

const GeneralConfigList = () => {
	const navigate = useNavigate();
	const [state, setState] = useState([]);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);
	const [deleteId, setDeleteId] = useState('');

	const getData = async (pageNumber) => {
		setLoading(true);
		try {
			const res = await AxiosDefaultSetting({
				method: 'GET',
				url: `/admin/app-config/list?sectionName=welcome&offset=${pageNumber || 0}`,
			});
			if (res.status === 200) {
				const list = res.data.list;
				setState(list);
				setTotal(Math.ceil(res.data.total / 10));
			} else {
				toast.error('Data not get!');
			}
		} catch (error) {}
		setLoading(false);
	};
	useEffect(() => {
		getData();
	}, []);

	const handleDelete = async () => {
		setLoading(true);

		try {
			const response = await AxiosDefaultSetting({
				method: 'POST',
				url: `/admin/app-config/update`,
				data: { id: deleteId, content: { isDeleted: true } },
			});
			if (response.status === 200) {
				toast.success('Deleted successful');
			} else {
				toast.error('Something went wrong');
			}
		} catch (error) {}

		getData();
		setDeleteId('');
		setLoading(false);
	};

	const columnsData = [
		{
			field: 'title',
			headerName: 'Title',
			minWidth: 240,
			flex: 1,
			editable: false,
			sortable: false,
		},
		{
			field: 'updatedAt',
			headerName: 'Updated Date',
			type: 'date',
			width: 150,
			editable: false,
			sortable: false,
			valueGetter: ({ row }) => moment(get(row, 'updatedAt', 'NA'), ['MM-DD-YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY'),
		},
		{
			field: 'actions',
			headerName: 'Actions',
			headerAlign: 'center',
			width: 240,
			sortable: false,
			renderCell: ({ row }) => {
				console.log(row);
				return (
					<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
						<IconButton
							aria-label='edit'
							color='primary'
							onClick={() => navigate(`/app-content/create/welcome?sectionName=welcome`, { state: row })}
						>
							<EditIcon fontSize='14px' />
						</IconButton>

						<IconButton onClick={() => setDeleteId(row?._id)}>
							<DeleteIcon color='error' />
						</IconButton>
					</Box>
				);
			},
		},
	];

	return (
		<>
			<MDDataGrid
				rows={state}
				columns={columnsData}
				getRowId={(row) => row._id}
				pageSize={10}
				disableSelectionOnClick={true}
				loading={loading}
				onPageChange={(pageNumber) => getData(pageNumber)}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
				autoHeight
				rowCount={total}
			/>
			<DeleteConfirmDialog
				title='Delete Confirmation'
				open={Boolean(deleteId)}
				handleClose={() => setDeleteId('')}
				deleteAction={handleDelete}
			/>
		</>
	);
};

export default GeneralConfigList;
