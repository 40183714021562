import AxiosDefault from "services/AxiosDefaultSetting";

export const PostLogin = async (data) => {
  const response = await AxiosDefault({
    method: "POST",
    url: `/admin/auth/login`,
    data: data,
  });
  return response;
};
