import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import MDSwitch from 'components/md-components/switch/MDSwitch';
import MDDataGrid from 'components/md-components/data-table/MDDataGrid';
import MDDataGridToolbar from 'components/md-components/data-table/MDDataGridToolbar';
import MDButton from 'components/md-components/button/MDButton';
import EmptyTableRowOverlay from 'components/md-components/empty-overlay/EmptyTableRowOverlay';
import toCapitalize from 'components/hooks/toCapitalize';

import { getQuestionsList, statusUpdateQuestion } from 'store/slice/consultant-config/QAndASlice';
import MDDialog from 'components/md-components/dialog/MDDialog';
import QuestionDetail from 'components/pages/cms/user-config/consultant-config/component/QuestionDetail';

const CustomToolbar = () => {
	const navigate = useNavigate();

	return (
		<MDDataGridToolbar align='right' divider={true} ToolbarQuickFilter={true}>
			<MDButton
				variant='contained'
				label='Create New'
				startIcon={<AddCircleOutlineIcon />}
				fontSize='14px'
				onClick={() => {
					navigate('/consultant-config/create-default-question', {
						state: {
							formType: 'create',
							row: [],
						},
					});
				}}
			/>
		</MDDataGridToolbar>
	);
};

const QAndAList = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const questionsListData = useSelector((state) => state.QAndA.list);
	const isLoading = useSelector((state) => state.QAndA.loading);

	const [dialogData, setDialogData] = useState({
		id: '',
		title: '',
		rowData: [],
	});
	const [openQuestionDetail, setOpenQuestionDetail] = useState(false);

	const handleOpenQuestionDetail = useCallback(() => {
		setOpenQuestionDetail(true);
	}, []);
	const handleCloseQuestionDetail = () => {
		setOpenQuestionDetail(false);
	};

	const handleStatusUpdate = useCallback(
		(event, row) => {
			setDialogData({ ...dialogData, id: row._id });
			const apiData = {
				id: row._id,
				data: {
					status: event.target.checked,
				},
			};
			dispatch(statusUpdateQuestion(apiData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false) === true) {
						dispatch(getQuestionsList('default'));
					}
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[dialogData, dispatch]
	);

	useEffect(() => {
		dispatch(getQuestionsList('default'));
	}, [dispatch]);

	const columnsData = useMemo(
		() => [
			{
				field: 'questionTitle',
				headerName: 'Question Title',
				width: 540,
				editable: false,
			},
			{
				field: 'language',
				headerName: 'Language',
				width: 160,
				editable: false,
				headerAlign: 'center',
				align: 'center',
				valueGetter: ({ row }) => row?.language?.title,
			},
			{
				field: 'inputType',
				headerName: 'Input Type',
				width: 160,
				editable: false,
				headerAlign: 'center',
				align: 'center',
				valueGetter: ({ row }) => toCapitalize(row.inputType),
			},
			{
				field: 'actions',
				headerName: 'Actions',
				headerAlign: 'center',
				width: 240,
				renderCell: ({ row }) => {
					return (
						<Box className='w-100 d-flex justify-content-center align-items-center gap-3'>
							<IconButton
								aria-label='edit'
								color='primary'
								onClick={() => {
									setDialogData({ ...dialogData, id: row._id, rowData: row });
									handleOpenQuestionDetail();
								}}
							>
								<VisibilityIcon fontSize='14px' />
							</IconButton>
							<IconButton
								aria-label='edit'
								color='primary'
								onClick={() => {
									navigate('/consultant-config/create-default-question', {
										state: {
											formType: 'update',
											rowData: row,
										},
									});
								}}
							>
								<EditIcon fontSize='14px' />
							</IconButton>
							<MDSwitch
								checked={row.status ?? false}
								onChange={(event) => {
									handleStatusUpdate(event, row);
								}}
								inputProps={{
									'aria-label': `${row._id}-status`,
									role: 'switch',
								}}
								color='success'
								disabled={get(dialogData, 'id', '') === row._id && isLoading}
							/>
						</Box>
					);
				},
			},
		],
		[dialogData, handleOpenQuestionDetail, handleStatusUpdate, isLoading, navigate]
	);

	return (
		<>
			<MDDataGrid
				rows={!isEmpty(questionsListData) ? questionsListData : []}
				columns={columnsData}
				getRowId={(row) => row._id}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick={true}
				loading={isLoading}
				components={{
					Toolbar: CustomToolbar,
					NoRowsOverlay: EmptyTableRowOverlay,
					LoadingOverlay: LinearProgress,
				}}
				autoHeight
			/>
			<MDDialog
				title='Question Details'
				open={openQuestionDetail}
				close={handleCloseQuestionDetail}
				maxWidth='md'
				fullWidth={true}
				aria-labelledby='question-detail-view-dialog'
				aria-describedby='question-detail-view-dialog'
			>
				<DialogContent dividers={true}>
					<QuestionDetail
						questionIndex={get(dialogData, '_id', '')}
						questionData={get(dialogData, 'rowData', {})}
						questionTitle={true}
					/>
				</DialogContent>
				<DialogActions>
					<Box className='d-flex gap-3'>
						<MDButton
							label='Close'
							variant='contained'
							size='small'
							onClick={() => handleCloseQuestionDetail()}
						/>
					</Box>
				</DialogActions>
			</MDDialog>
		</>
	);
};

export default QAndAList;
