import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';

import MDCard from 'components/md-components/card/MDCard';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import GeneralConfigList from 'components/pages/cms/user-config/general-config/GeneralConfigList';

const SubSpecialization = () => {
	const location = useLocation();

	const [dialogOpen, setDialogOpen] = useState(false);
	const handleDialogOpen = () => {
		setDialogOpen(true);
	};
	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	return (
		<Box>
			<MDCard>
				<MDCardHeader title='Sub Specialization' />
				<CardContent>
					<Grid container spacing={0} className='h-100 align-content-start'>
						<Grid item xs={12}>
							<Box className='min-vh-100'>
								<GeneralConfigList
									title='Sub Specialization'
									dialogOpen={dialogOpen}
									handleDialogOpen={handleDialogOpen}
									handleDialogClose={handleDialogClose}
									subSpecializationField={true}
									parentId={get(location, 'state.id', '')}
									sectionName='SPECIALIZATION'
									level={2}
								/>
							</Box>
						</Grid>
					</Grid>
				</CardContent>
			</MDCard>
		</Box>
	);
};

export default SubSpecialization;
