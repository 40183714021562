import { get } from "lodash";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import MDCard from "components/md-components/card/MDCard";
import MDCardHeader from "components/md-components/card/MDCardHeader";
import TabPanel from "components/md-components/tab/TabPanel";
import QAndAList from "components/pages/cms/user-config/consultant-config/QAndAList";
import QAndATemplateList from "components/pages/cms/user-config/consultant-config/QAndATemplateList";

function a11yProps(index) {
  return {
    id: `consultant-config-tab-${index}`,
    "aria-controls": `consultant-config-tab-panel-${index}`,
  };
}

const PreConsultantQAndA = () => {
  const location = useLocation();
  const theme = useTheme();
  const { palette } = theme;

  const [value, setValue] = useState(get(location, "state.index", 0));
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <MDCard>
      <MDCardHeader title="Pre Consultant" />
      <CardContent className="h-100">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box sx={{ bgcolor: palette.background.default }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="signup config tabs"
              >
                <Tab label="Q & A" {...a11yProps(0)} />
                <Tab label="Template" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={value} index={0}>
              <QAndAList type="pre" />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <QAndATemplateList type="pre" />
            </TabPanel>
          </Grid>
        </Grid>
      </CardContent>
    </MDCard>
  );
};

export default PreConsultantQAndA;
