import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Formik } from 'formik';
import { get, isEmpty, map, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import MDLoadingButton from 'components/md-components/button/MDLoadingButton';
import MDCard from 'components/md-components/card/MDCard';

import TimeOutComponent from 'components/common/TimeOutComponent';
import MDCardHeader from 'components/md-components/card/MDCardHeader';
import { createQuestion, updateQuestion } from 'store/slice/consultant-config/QAndASlice';
import { getLanguageConfigList } from 'store/slice/language-config/LanguageConfigSlice';
import LanguageTranslationForm, { languageInitialValue } from 'components/common/LanguageTranslationForm';
import MultiSelectSpecialization, {
	getInitSpecialization,
	getInitSubSpecialization,
	getSpecializationSubmitData,
} from 'components/common/MultiSelectSpecialization';
import { getSpecializationData } from 'store/slice/common/GeneralConfigDataSlice';
import { ValidationSchema } from './ValidationSchema';
import RadioCheckboxForm from './RadioCheckboxForm';
import ScaleForm from './ScaleForm';
import SubQuestions from './SubQuestions';

const InputTypeList = [
	{ label: 'Text', value: 'text' },
	{ label: 'Radio', value: 'radio' },
	{ label: 'Checkbox', value: 'checkbox' },
	{ label: 'Scale', value: 'scale' },
];

const DefaultQAndAForm = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const formType = location.state.formType;
	const rowData = location.state.rowData;

	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.QAndA.loading);
	const languageConfigList = useSelector((state) => state.languageConfig.languageList);
	const specializationList = useSelector((state) => state.generalConfigData.specializationData);

	const handleSubmit = (values) => {
		let payloadData = {
			...values,
			categories: getSpecializationSubmitData(
				values,
				values.subCategories,
				'categories',
				'subCategories'
			),
		};

		if (values.inputType === 'text') {
			payloadData = omit(payloadData, 'options', 'maxValue', 'minValue', 'stepValue');
		} else if (payloadData.inputType === 'radio' || payloadData.inputType === 'checkbox') {
			payloadData = {
				...omit(payloadData, 'maxValue', 'minValue', 'stepValue'),
				options: map(payloadData.options, (option) => {
					if (option.subQuestionStatus === false) {
						return omit(option, 'subQuestionStatus', 'subQuestions', '_id');
					} else {
						return {
							label: option.label,
							value: option.value,
							languages: option.languages,
							subQuestionStatus: option.subQuestionStatus,
							subQuestions: map(option.subQuestions, (subQuestData) => {
								if (
									subQuestData.inputType === 'radio' ||
									subQuestData.inputType === 'checkbox'
								) {
									return {
										...omit(subQuestData, 'maxValue', 'minValue', 'stepValue', '_id'),
										options: map(subQuestData.options, (subOptions) => {
											return omit(subOptions, '_id');
										}),
									};
								} else if (subQuestData.inputType === 'scale') {
									return omit(subQuestData, 'options', '_id');
								} else if (subQuestData.inputType === 'text') {
									return omit(subQuestData, 'options', '_id');
								}
							}),
						};
					}
				}),
			};
		} else {
			payloadData = omit(payloadData, 'options');
		}
		payloadData.type = 'default';

		if (formType === 'create') {
			dispatch(createQuestion(payloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Question created successfully.'));
						navigate('/consultant-config/default-question-list', {
							state: { index: 0 },
						});
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch(() => {
					toast.error('Something went wrong.');
				});
		} else {
			const updatePayloadData = {
				id: get(rowData, '_id', ''),
				data: payloadData,
			};
			dispatch(updateQuestion(updatePayloadData))
				.unwrap()
				.then((result) => {
					if (get(result, 'status', false)) {
						toast.success(get(result, 'message', 'Question updated successfully.'));
						navigate('/consultant-config/default-question-list');
					} else {
						toast.error(get(result, 'message', 'Something went wrong.'));
					}
				})
				.catch(() => {
					toast.error('Something went wrong.');
				});
		}
	};

	useEffect(() => {
		dispatch(getLanguageConfigList());
		dispatch(getSpecializationData());
	}, [dispatch]);

	return (
		<MDCard>
			<MDCardHeader title='Create Question' />
			<CardContent>
				<TimeOutComponent delay={2} isEmpty={isEmpty(languageConfigList)} px={1} pt={1}>
					{specializationList.length > 0 && (
						<Formik
							initialValues={{
								questionTitle: get(rowData, 'questionTitle', ''),
								languages: languageInitialValue(
									languageConfigList,
									get(rowData, 'languages', false),
									'common'
								),
								categories: getInitSpecialization(rowData, specializationList, 'categories'),
								subCategories: getInitSubSpecialization(
									rowData,
									specializationList,
									'categories',
									'subCategories'
								),
								inputType: get(rowData, 'inputType', ''),
								options: get(rowData, 'options', [
									{
										label: '',
										value: '',
										languages: languageInitialValue(
											languageConfigList,
											get(rowData, 'languages', false),
											'common'
										),
										subQuestionStatus: false,
										subQuestions: [
											{
												questionTitle: '',
												inputType: '',
												languages: languageInitialValue(
													languageConfigList,
													get(rowData, 'languages', false),
													'common'
												),
												options: [
													{
														label: '',
														value: '',
														languages: languageInitialValue(
															languageConfigList,
															get(rowData, 'languages', false),
															'common'
														),
													},
												],
												minValue: undefined,
												maxValue: undefined,
												stepValue: undefined,
											},
										],
									},
								]),
								minValue: get(rowData, 'minValue', undefined),
								maxValue: get(rowData, 'maxValue', undefined),
								stepValue: get(rowData, 'stepValue', undefined),
							}}
							validationSchema={ValidationSchema}
							onSubmit={(values) => {
								handleSubmit(values);
							}}
						>
							{(formProps) => (
								<form onSubmit={formProps.handleSubmit}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												fullWidth={true}
												multiline={true}
												size='small'
												label='Question Title'
												name='questionTitle'
												value={formProps.values.questionTitle}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={
													formProps.errors.questionTitle &&
													formProps.touched.questionTitle
														? true
														: false
												}
												helperText={
													formProps.errors.questionTitle &&
													formProps.touched.questionTitle
														? formProps.errors.questionTitle
														: null
												}
											/>
										</Grid>

										<Grid item xs={12}>
											<LanguageTranslationForm
												fieldName='languages'
												formProps={formProps}
												languageConfigList={languageConfigList}
												inputField='text-input'
											/>
										</Grid>

										<Grid item xs={12}>
											<MultiSelectSpecialization
												specializations={specializationList}
												fieldName='categories'
												subFieldName='subCategories'
												formProps={formProps}
												initialData={rowData}
												isSubmit={false}
											/>
										</Grid>

										<Grid item xs={12}>
											<TextField
												size='small'
												fullWidth={true}
												select={true}
												id='input-type-select'
												label='Input Type'
												name='inputType'
												value={formProps.values.inputType}
												onChange={formProps.handleChange}
												onBlur={formProps.handleBlur}
												error={
													formProps.errors.inputType && formProps.touched.inputType
														? true
														: false
												}
												helperText={
													formProps.errors.inputType && formProps.touched.inputType
														? formProps.errors.inputType
														: null
												}
											>
												{map(InputTypeList, (option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</TextField>
										</Grid>

										{formProps.values.inputType === 'radio' ||
										formProps.values.inputType === 'checkbox' ? (
											<RadioCheckboxForm formProps={formProps} rowData={rowData} />
										) : null}

										{formProps.values.inputType === 'scale' ? (
											<ScaleForm formProps={formProps} />
										) : null}

										{map(formProps.values.options, (option, optionIndex) => {
											return option.subQuestionStatus &&
												formProps.values.inputType !== 'text' ? (
												<SubQuestions
													optionIndex={optionIndex}
													option={option}
													formProps={formProps}
													key={optionIndex}
												/>
											) : null;
										})}
										<Grid item xs={12} container spacing={2} className='mt-3 mb-3'>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='reset'
													variant='outlined'
													padding='6px 24px'
													fullWidth={true}
													onClick={(_event) => {
														formProps.resetForm();
													}}
												>
													Reset
												</MDLoadingButton>
											</Grid>
											<Grid item sm={6} xs={12}>
												<MDLoadingButton
													type='submit'
													variant='contained'
													padding='6px 24px'
													fullWidth={true}
													loading={formProps.isSubmitting && isLoading}
												>
													Submit
												</MDLoadingButton>
											</Grid>
										</Grid>
									</Grid>
								</form>
							)}
						</Formik>
					)}
				</TimeOutComponent>
			</CardContent>
		</MDCard>
	);
};

export default DefaultQAndAForm;
