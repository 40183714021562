import React from "react";
import Radio from "@mui/material/Radio";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const CustomRadio = styled(Radio)(({ other, theme }) => ({
  display: "none",
  "& + .MuiFormControlLabel-label": {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary[700],
    textAlign: other.align && other.align,
    padding: other.padding
      ? other.padding
      : other.variant === "option"
      ? "8px 16px"
      : "6px 12px",

    fontSize: other.fontSize
      ? other.fontSize
      : other.variant === "option"
      ? "16px"
      : "14px",
    fontWeight: other.fontWeight ? other.fontWeight : "500",
    width: other.width ? other.width : other.variant === "option" && "100%",
    marginBottom: other.marginbottom ? other.marginbottom : 0,
    border:
      other.variant === "option" &&
      `1px solid ${alpha(theme.palette.primary[700], 1)}}`,
    borderRadius: theme.spacing(0.75),
    transition: "all 300ms ease-in-out",
    "& + :hover": {
      backgroundColor:
        other.variant === "option"
          ? theme.palette.primary[100]
          : theme.palette.primary[100],
      color: theme.palette.primary[500],
      borderColor: theme.palette.primary.main,
    },
  },
  "&.MuiRadio-root.Mui-checked": {
    "& + .MuiFormControlLabel-label": {
      backgroundColor:
        other.variant === "option"
          ? theme.palette.primary[50]
          : theme.palette.primary.main,
      color:
        other.variant === "option"
          ? theme.palette.primary[700]
          : theme.palette.primary.contrastText,
      borderColor: other.variant === "option" && theme.palette.primary[700],
      borderWidth: "2px",
      fontWeight: 600,
      ":hover": {
        backgroundColor:
          other.variant === "option"
            ? alpha(theme.palette.primary[100], 0.75)
            : theme.palette.primary[500],
        color:
          other.variant === "option"
            ? theme.palette.primary[700]
            : theme.palette.primary.contrastText,
        borderColor: other.variant === "option" && theme.palette.primary[300],
      },
      animation:
        other.animated === "true" ? "flash 300ms ease alternate" : "none",
    },
  },
}));

const MDRadio = (props) => {
  const { name, value, sx, ...rest } = props;

  return (
    <>
      <CustomRadio
        name={name}
        value={value}
        sx={{ ...sx }}
        other={props}
        {...rest}
      />
    </>
  );
};

MDRadio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  sx: PropTypes.any,
  value: PropTypes.any,
};

export default MDRadio;
